import apolloClient from '../apolloClient';
import { STATUS_DRAFT } from '../constants/vehicleData/VDConstants';
import {
  AddGradesDocument,
  AddGradesMutation,
  AddGradesMutationVariables,
  DeleteModelDocument,
  DeleteModelMutation,
  DeleteModelMutationVariables,
  GetChangeLogModelsDocument,
  GetChangeLogModelsQuery,
  GetChangeLogModelsQueryVariables,
  GetGradesDocument,
  GetGradesQuery,
  GetGradesQueryVariables,
  GetModelsDocument,
  GetModelsQuery,
  GetModelsQueryVariables,
  GetSeriesSettingsDocument,
  GetSeriesSettingsQuery,
  GetSeriesSettingsQueryVariables,
  Model,
  ModelsResponse,
  RevertChangeLogModelsDocument,
  RevertChangeLogModelsMutation,
  RevertChangeLogModelsMutationVariables,
  SeriesGradesResponse,
  SeriesSetting,
  SortModelsDocument,
  SortModelsMutation,
  SortModelsMutationVariables,
  UpdateGradeDocument,
  UpdateGradeMutation,
  UpdateGradeMutationVariables,
  UpdateModelDocument,
  UpdateModelMutation,
  UpdateModelMutationVariables,
  UpdateSeriesSettingDocument,
  UpdateSeriesSettingMutation,
  UpdateSeriesSettingMutationVariables,
} from '../gql/generated';
import { RefItemLanguageMap } from '../models/category.model';
import { ChangeLogModelRequest, ChangeLogModelResponse } from '../models/changeLog.model';
import { RefItemResponse } from '../models/refItem.model';
import { ReviewChangeRequest } from '../models/review.model';
import { SeriesSettingsItem, SeriesSettingsRequest, SeriesSettingsResponse, SeriesSettingsReviewResponse } from '../models/seriesSettings.model';
import { Brand, Language } from '../models/user.model';
import { SortModel, VehicleDataVersionInfo, VehicleTeam } from '../models/vehicleData.model';
import { ModelLangMap, ModelsReviewResponse, VehicleModel, VehicleModelToyota } from '../models/vehicleModel.model';
import { modelsResponseToModel } from '../utils/modelsUtils';
import { seriesGradesResponseToSeriesGrades } from '../utils/refItemUtils';
import { seriesSettingToSeriesSettingsResponse } from '../utils/seriesSettingsUtils';
import { getStatusAndVersionGqlReq } from '../utils/vehicleDataUtils';
import API from '../webservices/api';

const RESOURCE_PATH = '/vehicle-models';
const INCLUDE_ALL = '&includeAll=y';
const MODELS_PATH = '/models';
const SERIES_SETTINGS_PATH = '/seriesSettings';

// Models
//
export const getModels = ({
  brand,
  team,
  series,
  year,
  version,
  includeAll,
  language,
}: {
  brand: string;
  team: VehicleTeam;
  series: string;
  year: string;
  version?: string;
  includeAll?: boolean;
  language?: Language;
}) =>
  apolloClient
    .query<GetModelsQuery, GetModelsQueryVariables>({
      query: GetModelsDocument,
      fetchPolicy: 'no-cache',
      variables: {
        seriesId: series,
        team,
        language: language ?? Language.EN,
        modelYear: Number(year),
        includeAll,
        ...getStatusAndVersionGqlReq(version),
      },
    })
    .then(res => {
      const { data } = res;
      return {
        ...res,
        data: modelsResponseToModel(data.models as ModelsResponse),
      };
    });

export const addModel = (brand: string, team: VehicleTeam, series: string, year: string, payload: VehicleModel<VehicleModelToyota>) => {
  return API.post<VehicleModel<VehicleModelToyota>>(`${RESOURCE_PATH}${MODELS_PATH}/${brand}/${team}/${series}/${year}/EN`, payload);
};

export const updateModel = (
  brand: string,
  team: VehicleTeam,
  seriesId: string,
  modelYear: string,
  payload: VehicleModel<VehicleModelToyota>,
  language?: Language,
  unlinkFromTMNA: boolean = false,
) =>
  apolloClient
    .mutate<UpdateModelMutation, UpdateModelMutationVariables>({
      mutation: UpdateModelDocument,
      fetchPolicy: 'no-cache',
      variables: {
        ...payload,
        seriesId,
        team,
        language: language ?? Language.EN,
        modelYear: Number(modelYear),
        brand,
        grade: payload.grade as any,
        fuelType: payload.fuelType as any,
        horsepower: payload.horsepower as string,
        description: payload.description as string,
        isHybrid: payload.isHybrid as boolean,
        drive: payload.drive as string,
        acceptChanges: payload.acceptChanges || unlinkFromTMNA,
        unlinkFromTMNA,
      },
    })
    .then(res => {
      const { data } = res;
      const models = modelsResponseToModel({
        models: [data?.updateModel as Model],
      });
      return {
        ...res,
        data: models[0],
      };
    });

export const deleteModel = (brand: string, team: VehicleTeam, seriesId: string, modelYear: string, language: string, modelId: string) =>
  apolloClient.mutate<DeleteModelMutation, DeleteModelMutationVariables>({
    mutation: DeleteModelDocument,
    fetchPolicy: 'no-cache',
    variables: {
      team,
      seriesId,
      modelYear: Number(modelYear),
      language,
      id: modelId,
    },
  });

export const sortModels = (brand: string, team: VehicleTeam, seriesId: string, modelYear: string, payload: SortModel[]) =>
  apolloClient
    .mutate<SortModelsMutation, SortModelsMutationVariables>({
      mutation: SortModelsDocument,
      fetchPolicy: 'no-cache',
      variables: {
        brand,
        team,
        seriesId,
        modelYear: Number(modelYear),
        sortList: payload,
      },
    })
    .then(res => ({
      ...res,
      data: res.data?.updateModelSort as SortModel[],
    }));

//Grades
//
const GRADES_PATH = '/grades';

export const getGrades = ({
  brand,
  team,
  series,
  year,
  version,
  includeAll,
  language,
}: {
  brand: string;
  team: VehicleTeam;
  series: string;
  year: string;
  version?: string;
  includeAll?: boolean;
  language?: Language;
}) =>
  apolloClient
    .query<GetGradesQuery, GetGradesQueryVariables>({
      query: GetGradesDocument,
      fetchPolicy: 'no-cache',
      variables: {
        seriesId: series,
        team,
        language: language ?? Language.EN,
        modelYear: Number(year),
        includeAll,
        ...getStatusAndVersionGqlReq(version),
      },
    })
    .then(res => {
      const { data } = res;
      return {
        ...res,
        data: seriesGradesResponseToSeriesGrades(data.seriesGrades as SeriesGradesResponse),
      };
    });

export const addGrades = (brand: string, team: VehicleTeam, seriesId: string, modelYear: string, payload: { [lang: string]: string }) =>
  apolloClient
    .mutate<AddGradesMutation, AddGradesMutationVariables>({
      mutation: AddGradesDocument,
      fetchPolicy: 'no-cache',
      variables: {
        team,
        seriesId,
        modelYear: Number(modelYear),
        language: Language.EN,
        gradeName: payload[Language.EN],
      },
    })
    .then(res => {
      const { data } = res;
      return {
        ...res,
        data: { [Language.EN]: seriesGradesResponseToSeriesGrades(data?.createSeriesGrade as SeriesGradesResponse) } as RefItemLanguageMap,
      };
    });

export const updateGrade = (brand: string, team: VehicleTeam, seriesId: string, modelYear: string, language: string, gradeId: string, gradeName: string) =>
  apolloClient.mutate<UpdateGradeMutation, UpdateGradeMutationVariables>({
    mutation: UpdateGradeDocument,
    fetchPolicy: 'no-cache',
    variables: {
      team,
      seriesId,
      modelYear: Number(modelYear),
      language,
      gradeName,
      gradeId,
    },
  });

//Changelog
export const getChangeLog = (brand: string, team: VehicleTeam, seriesId: string, modelYear: string, language: string, version?: string) =>
  apolloClient
    .query<GetChangeLogModelsQuery, GetChangeLogModelsQueryVariables>({
      query: GetChangeLogModelsDocument,
      fetchPolicy: 'no-cache',
      variables: {
        team,
        seriesId,
        modelYear: Number(modelYear),
        language,
        ...getStatusAndVersionGqlReq(version),
      },
    })
    .then(res => ({
      ...res,
      data: JSON.parse(res.data.modelsChanges?.body) as ChangeLogModelResponse[],
    }));

export const revertChangeLog = (brand: string, team: VehicleTeam, seriesId: string, modelYear: string, language: string, payload: ChangeLogModelRequest) =>
  apolloClient
    .mutate<RevertChangeLogModelsMutation, RevertChangeLogModelsMutationVariables>({
      mutation: RevertChangeLogModelsDocument,
      fetchPolicy: 'no-cache',
      variables: {
        brand,
        team,
        seriesId,
        modelYear: Number(modelYear),
        language,
        body: payload,
      },
    })
    .then(res => ({
      ...res,
      data: JSON.parse(res.data?.revertModelsChanges?.body) as VehicleModel<VehicleModelToyota>,
    }));

// Review
//
const REVIEW_PATH = '/review';
export const getReviewModels = (brand: string, team: VehicleTeam, series: string, year: string, version?: string) => {
  const params = !version || version === 'DRAFT' ? STATUS_DRAFT : `version=${version}`;
  return API.get<VehicleModel<VehicleModelToyota>[]>(`${RESOURCE_PATH}${REVIEW_PATH}${MODELS_PATH}/${brand}/${team}/${series}/${year}?${params}`);
};
export const getReviewModels2 = (brand: string, team: VehicleTeam, series: string, year: string, version?: string) => {
  const params = !version || version === 'DRAFT' ? 'status=DRAFT' : `version=${version}`;
  return API.get<ModelsReviewResponse[]>(`${RESOURCE_PATH}${REVIEW_PATH}${MODELS_PATH}/${brand}/${team}/${series}/${year}?${params}`);
};

export const updateReviewModels = (brand: string, team: VehicleTeam, series: string, year: string, version: string, payload: ReviewChangeRequest) => {
  const params = !version || version === 'DRAFT' ? 'status=DRAFT' : `version=${version}`;
  return API.put<ReviewChangeRequest>(`${RESOURCE_PATH}${REVIEW_PATH}${MODELS_PATH}/${brand}/${team}/${series}/${year}?${params}`, payload);
};

export const getReviewGrades = (brand: string, team: VehicleTeam, series: string, year: string, version?: string, includeAll?: boolean) => {
  let params = !version || version === 'DRAFT' ? STATUS_DRAFT : `version=${version}`;
  if (includeAll) {
    params += INCLUDE_ALL;
  }
  return API.get<RefItemResponse[]>(`${RESOURCE_PATH}${REVIEW_PATH}${GRADES_PATH}/${brand}/${team}/${series}/${year}?${params}`);
};

// Series Settings

export const getSeriesSettings = (brand: string, team: VehicleTeam, id: string, year: string, language: Language, version?: string, includeAll?: boolean) =>
  apolloClient
    .query<GetSeriesSettingsQuery, GetSeriesSettingsQueryVariables>({
      query: GetSeriesSettingsDocument,
      fetchPolicy: 'no-cache',
      variables: {
        team,
        seriesId: id,
        modelYear: Number(year),
        lang: language,
        includeAll,
        ...getStatusAndVersionGqlReq(version),
      },
    })
    .then(res => {
      const { data } = res;
      const seriesSettings: SeriesSettingsResponse[] =
        data.seriesSettings?.seriesSettings?.map(seriesSetting => seriesSettingToSeriesSettingsResponse(seriesSetting, brand as Brand)) ?? [];
      return { ...res, data: seriesSettings };
    });

export const addSeriesSetting = (brand: string, team: VehicleTeam, id: string, year: string, lang: string, payload: SeriesSettingsRequest) => {
  return API.post<SeriesSettingsResponse>(`${RESOURCE_PATH}${SERIES_SETTINGS_PATH}/${brand}/${team}/${id}/${year}/${lang}`, payload);
};

export const updateSeriesSetting = (
  brand: string,
  team: VehicleTeam,
  seriesId: string,
  modelYear: string,
  lang: string,
  payload: SeriesSettingsRequest,
  acceptChanges: boolean = false,
  unlinkFromTMNA: boolean = false,
) => {
  const body: any = payload;
  if (acceptChanges) {
    body.acceptChanges = true;
  }
  if (unlinkFromTMNA) {
    body.unlinkFromTMNA = true;
    body.acceptChanges = true;
  }

  return apolloClient
    .mutate<UpdateSeriesSettingMutation, UpdateSeriesSettingMutationVariables>({
      mutation: UpdateSeriesSettingDocument,
      fetchPolicy: 'no-cache',
      variables: {
        ...body,
        brand,
        team,
        modelYear: Number(modelYear),
        language: lang,
        seriesId,
        fuelTypes: Object.keys(body.fuelTypes),
        seriesCategories: Object.keys(body.seriesCategories),
      },
    })
    .then(res => {
      const { data } = res;
      return {
        ...res,
        data: seriesSettingToSeriesSettingsResponse(data?.updateSeriesSetting as SeriesSetting, brand as Brand),
      };
    });
};

export const deleteSeriesSetting = (brand: string, team: VehicleTeam, seriesId: string, year: string, lang: string, seriesSetting: SeriesSettingsItem) => {
  return API.delete(`${RESOURCE_PATH}${SERIES_SETTINGS_PATH}/${brand}/${team}/${seriesId}/${year}/${lang}/${seriesSetting.id}`);
};

export const deleteSubSeriesSetting = (brand: string, team: VehicleTeam, seriesId: string, year: string, lang: string, seriesSetting: SeriesSettingsItem) => {
  return API.delete(`${RESOURCE_PATH}${SERIES_SETTINGS_PATH}/${brand}/${team}/${seriesId}/${year}/${lang}/${seriesSetting.parentId}?subSeriesSettingId=${seriesSetting.id}`);
};

// Series Settings Review

/**
 * Retrieves the series settings data used in series settings review
 * @param brand
 * @param team
 * @param series
 * @param year
 * @param version
 * @returns
 */
export const getReviewSeriesSettings = (brand: string, team: VehicleTeam, series: string, year: string, version?: string) => {
  const params = !version || version === 'DRAFT' ? 'status=DRAFT' : `version=${version}`;
  return API.get<SeriesSettingsReviewResponse[]>(`${RESOURCE_PATH}${REVIEW_PATH}${SERIES_SETTINGS_PATH}/${brand}/${team}/${series}/${year}?${params}`);
};

// For BnP
export const getModelMaps = (brand: string, team: VehicleTeam, seriesId: string, year: string, versionMap: VehicleDataVersionInfo) => {
  return API.post<ModelLangMap>(`${RESOURCE_PATH}/model-maps/${brand}/${team}/${seriesId}/${year}`, {
    versionMap,
  });
};
