import React, { useCallback, useContext } from 'react';
import {
  Button,
  Checkbox,
  DateComponent,
  HeaderRow,
  IconTextButton,
  ModalBody,
  ModalFooter,
  MultiLineRTEditor,
  Notes,
  Popover,
  ScrollableContainer,
  Table,
  TableCell,
  TableRow,
} from 'vapi-ui-common';
import { ChangeLogChanges } from '../../../../components/ChangeLogTable';
import { AppliedChangesResponse, ChangeLogTypes, SYNCED_CHANGE_TYPES_MAP } from '../../../../models/changeLog.model';
import styles from './SyncTMNAChangesModal.module.scss';
import { SyncTMNAChangesModalBodyContext } from './SyncTMNAChangesModalBody';

const SyncTMNAChangesModalBodyChanges = () => {
  const { close, numberChecked, syncItems, appliedMap, changesMap, setAppliedMap } = useContext(SyncTMNAChangesModalBodyContext);

  const ignoreChanges = useCallback(() => close({ applied: {} }), [close]);

  const applyChanges = useCallback(() => {
    const appliedResponse: AppliedChangesResponse = { applied: {} };
    const appliedTypes = Object.keys(appliedMap.applied) as ChangeLogTypes[];
    appliedTypes.forEach(changeType => {
      const langMap = appliedMap.applied[changeType];
      if (langMap) {
        Object.keys(langMap).forEach(lang => {
          if (langMap[lang]) {
            // change accepted now update the original item and save
            if (!appliedResponse.applied[changeType]) {
              appliedResponse.applied[changeType] = {};
            }
            const change = changesMap.current.changes[changeType]![lang].changes;
            appliedResponse.applied[changeType]![lang] = change.after ?? '';
          }
        });
      }
    });
    close(appliedResponse);
  }, [close, appliedMap.applied, changesMap]);

  if (!syncItems.length) {
    return (
      <>
        <ModalBody className={styles.synced}>All row data is currently in sync with NAT</ModalBody>
        <ModalFooter>
        <Button variant="transparent" onClick={ignoreChanges}>
          Clear Sync Status
        </Button>
      </ModalFooter>
      </>
  )
  }

  return (
    <>
      <ModalBody>
        <ScrollableContainer style={{ overflow: 'scroll' }}>
          <Table fullWidth className={styles.aapTable}>
            <HeaderRow className={styles.stickyNav}>
              <TableCell>Apply?</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Language</TableCell>
              <TableCell>Change Type</TableCell>
              <TableCell>Changes</TableCell>
              <TableCell>Published Date</TableCell>
              <TableCell>Notes</TableCell>
            </HeaderRow>
            <tbody className={styles.appBody}>
              {syncItems.map(reviewItem => {
                const change = reviewItem.changes;
                const changeType = change.changeType;
                return (
                  <TableRow
                    key={reviewItem.uid}
                    onFillRowHeightChange={height => {
                      reviewItem.rowHeight = height;
                    }}
                  >
                    <TableCell center className={styles.checkBox}>
                      <Checkbox
                        id={`chbox-${reviewItem.uid}`}
                        checked={!!appliedMap.applied[changeType]?.[reviewItem.language]}
                        onChange={e => {
                          if (SYNCED_CHANGE_TYPES_MAP[changeType]) {
                            const entry = appliedMap.applied[changeType] ?? { [reviewItem.language]: false };
                            const isApplied = !entry[reviewItem.language];
                            Object.keys(entry).forEach(lang => {
                              entry[lang] = isApplied;
                            });

                            setAppliedMap({
                              ...appliedMap,
                              applied: {
                                ...appliedMap.applied,
                                [changeType]: entry,
                              },
                            });
                          } else {
                            setAppliedMap({
                              ...appliedMap,
                              applied: {
                                ...appliedMap.applied,
                                [changeType]: {
                                  ...appliedMap.applied[changeType],
                                  [reviewItem.language]: !appliedMap.applied[changeType]?.[reviewItem.language],
                                },
                              },
                            });
                          }
                        }}
                      />
                    </TableCell>
                    <TableCell large>
                      <MultiLineRTEditor value={reviewItem.description} disabled />
                    </TableCell>
                    <TableCell large>{reviewItem.language}</TableCell>
                    <TableCell large>{change.changeType}</TableCell>
                    <TableCell large>
                      <ChangeLogChanges changeItem={change} />
                    </TableCell>
                    <TableCell large>
                      <DateComponent format="MM/DD/YYYY hh:mm:ss A">{reviewItem.publishedDate}</DateComponent>
                    </TableCell>
                    <TableCell large>
                      {reviewItem.notes && (
                        <Popover
                          toggleElement={<IconTextButton smallIcon icon="circle" text="Notes" />}
                          popoverElement={<Notes readOnly notes={reviewItem.notes} />}
                          align="right"
                        />
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </tbody>
          </Table>
        </ScrollableContainer>
      </ModalBody>
      <ModalFooter>
        <Button variant="transparent" onClick={ignoreChanges}>
          Ignore Changes
        </Button>
        <Button variant="primary" onClick={applyChanges} disabled={numberChecked < 1}>
          {`Apply (${numberChecked})`}
        </Button>
      </ModalFooter>
    </>
  );
};

export default SyncTMNAChangesModalBodyChanges;
