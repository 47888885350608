import cx from 'clsx';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Input } from 'vapi-ui-common';
import useStores from '../../../../../../../../hooks/useStores';
import { SeriesCategories } from '../../../../../../../../models/vehicleData.model';
import { VehicleModelItem, VehicleModelToyota } from '../../../../../../../../models/vehicleModel.model';
import styles from '../../../../modelsModal.module.scss';
import Label from '../Label';

interface CabProps {
  vmId: string;
  category: SeriesCategories;
  model: VehicleModelItem<VehicleModelToyota>;
}

const Cab = observer(({ vmId, category, model }: CabProps) => {
  const {
    modelTabStore: { languages: checkboxLanguages, readOnly, getCab, setCab, getActiveLanguagesAmount, getLangVehicleModel },
    userStore: { langPermissions },
    teamStore: {
      team: { languages: teamLanguages },
    },
  } = useStores();

  if (category === SeriesCategories.TRUCKS_SUVS) {
    return (
      <div className={cx(getActiveLanguagesAmount() > 1 ? styles.long : '')}>
        <Label htmlFor={`amm-cab-${vmId}`} optional>
          Cab
        </Label>
        <div className={cx(styles.content)}>
          {teamLanguages.map(language => {
            if (checkboxLanguages[language].selected && langPermissions[language]?.canView) {
              let changedAttributes: string[] = [];
              if (langPermissions[language]?.canEdit) {
                changedAttributes = getLangVehicleModel(model, language).getVal('changedAttributes') ?? [];
              }
              return (
                <Input
                  id={`amm-cab-${vmId}-${language}`}
                  key={`amm-cab-${vmId}-${language}`}
                  value={getCab(vmId, language)}
                  disabled={!langPermissions[language]?.canEdit || readOnly}
                  onChange={e => setCab(vmId, language, e.currentTarget.value)}
                  placeholder={checkboxLanguages[language].description}
                  className={changedAttributes.includes('cab') ? styles.errorText : ''}
                />
              );
            }

            return null;
          })}
        </div>
      </div>
    );
  }

  return <></>;
});

export default Cab;
