import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef, useState } from 'react';
import ResizeDetector from 'react-resize-detector';
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';
import { Table, Thead } from '../../../../../../components/Table';
import RightTableSizer from '../../../../../../components/Table/components/RightTableSizer';
import StickyHeaderSection from '../../../../../../components/Table/components/StickyHeaderSection/StickyHeaderSection';
import useStores from '../../../../../../hooks/useStores';
import { ColorGradeLangItem, ColorItemLangMap, InteriorColorLangMap, InteriorItem } from '../../../../../../models/colors.model';
import { KeyValueType } from '../../../../../../models/common.model';
import { Language, LanguagePermissions } from '../../../../../../models/user.model';
import { VehicleModelItem, VehicleModelToyota } from '../../../../../../models/vehicleModel.model';
import {
  CheckboxCell,
  ColorsInteriorBodyRow,
  ColorsInteriorCellSpacer,
  ColorsInteriorHeaderCell,
  ColorsInteriorHeaderDetail,
  ColorsInteriorHeaderRow,
  ColorsInteriorModelCodeRow,
  ColorsInteriorTrimHeaderName,
} from '../ColorsInteriorTable/ColorsInteriorTable';
import styles from './ColorAccessibilityLangTable.module.scss';

type ColorAccessibilityTableProps = {
  filteredColors: ColorItemLangMap[];
  gradeItems: ColorGradeLangItem[];
  defaultLang: string;
  hasEnglishWritePerms: boolean;
  selectedInteriorColorMap?(item: InteriorColorLangMap): void;
  saveExteriorColorLangMap?(color: ColorItemLangMap, interior: InteriorItem): void;
  readOnly?: boolean;
  vehicleModels: VehicleModelItem<VehicleModelToyota>[];
  langPermissions: LanguagePermissions;
  allLangs: Language[];
};

const ColorAccessibilityLangTable = ({
  filteredColors,
  gradeItems,
  defaultLang,
  hasEnglishWritePerms,
  selectedInteriorColorMap = () => {},
  saveExteriorColorLangMap = () => {},
  readOnly,
  vehicleModels,
  langPermissions,
  allLangs,
}: ColorAccessibilityTableProps) => {
  const { tableSizeStore, userStore } = useStores();

  const ref = useRef<HTMLDivElement>(null);
  const [leftPosition, setLeftPosition] = useState(0);
  const [vehicleModelMap, setVehicleModelMap] = useState<KeyValueType<VehicleModelItem<VehicleModelToyota>>>({});

  const onResize = (width: number = 0, height: number = 0) => {
    tableSizeStore.tableRowHeight = height;
  };

  const getModelCodes = (gradeItem: ColorGradeLangItem) => {
    const modelIds: string[] = [];
    const modelCodesList: string[] = [];

    gradeItem.interiorItems.forEach(intMap => {
      const intClrRes = intMap.langs[defaultLang];
      Object.entries(intClrRes.modelApplicability).forEach(entry => {
        const [key, value] = entry;
        if (gradeItem.grade.id === key) {
          Object.keys(value).forEach(key => {
            if (!modelIds.includes(key)) {
              modelIds.push(key);
            }
          });
        }
      });
    });

    modelIds.forEach(modelId => {
      const model = vehicleModelMap[modelId];
      if (model) {
        let modelCode = model.getVal('code');
        if (userStore.isTdpr) {
          if (model.getVal('isTDPR') || model.getVal('isUSVI')) {
            modelCode = model.getVal('tdprCode') || modelCode;
          } else {
            modelCode = undefined;
          }
        }
        if (modelCode && !modelCodesList.includes(modelCode)) {
          modelCodesList.push(modelCode);
        }
      }
    });

    return modelCodesList.sort().map(function (code) {
      return ` (${code}) `;
    });
  };

  useEffect(() => {
    setLeftPosition(ref.current?.getBoundingClientRect().left || 0);
  }, [ref]);

  useEffect(() => {
    const vmMap: KeyValueType<VehicleModelItem<VehicleModelToyota>> = {};
    vehicleModels.forEach(model => {
      vmMap[model.id] = model;
    });
    setVehicleModelMap(vmMap);
  }, [vehicleModels]);

  const filteredGradeItems = () => {
    if (!userStore.isTdpr) {
      return gradeItems;
    }
    // if tdpr then we only want to use show grades that are applicable to at least 1 active tdpr model (where active means the model has isTDPR and/or isUSVI checked)
    return gradeItems.filter(gradeItem => {
      return !!gradeItem.interiorItems.find(interiorItem => {
        return !!Object.entries(interiorItem.defaultData.modelApplicability[gradeItem.grade.id]).find(([modelId, isSelected]) => {
          return isSelected && vehicleModelMap && vehicleModelMap[modelId] && (vehicleModelMap[modelId].getVal('isTDPR') || vehicleModelMap[modelId].getVal('isUSVI'));
        });
      });
    });
  };

  return (
    <ScrollSync>
      <RightTableSizer>
        <StickyHeaderSection hasFilters>
          <ScrollSyncPane group="horizontal">
            <div ref={ref} style={{ overflowX: 'auto' }}>
              <Table>
                <Thead>
                  <tr>
                    {filteredGradeItems().map(gradeItem => (
                      <React.Fragment key={gradeItem.uid}>
                        <ColorsInteriorHeaderCell colSpan={gradeItem.interiorItems.length}>
                          <div className={styles.header}>
                            <ColorsInteriorTrimHeaderName>{gradeItem.grade.value}</ColorsInteriorTrimHeaderName>
                            <ColorsInteriorModelCodeRow
                              modelCodes={getModelCodes(gradeItem)}
                              interiorItemsLength={gradeItem.interiorItems.length}
                              leftInititalPosition={leftPosition}
                            />
                            <ColorsInteriorHeaderRow>
                              {gradeItem.interiorItems.map((interiorMap, index) => {
                                const interior = interiorMap.langs[defaultLang];
                                let showRedText = false;
                                for (const lang of allLangs) {
                                  if (langPermissions[lang]?.canEdit) {
                                    const int = interiorMap.langs[lang];
                                    if (int && int.changedAttributes && int.changedAttributes.length > 0) {
                                      showRedText = true;
                                      break;
                                    }
                                  }
                                }
                                return (
                                  <ColorsInteriorHeaderDetail
                                    key={`${gradeItem.grade.id}${interior.code}`}
                                    interiorName={interior.name}
                                    interiorCode={interior.code}
                                    rejectNotes={interior.rejectNotes}
                                    isExtraCost={interior.isExtraCost}
                                    onClick={() => selectedInteriorColorMap(interiorMap)}
                                    showRedText={showRedText}
                                  />
                                );
                              })}
                            </ColorsInteriorHeaderRow>
                          </div>
                        </ColorsInteriorHeaderCell>
                        <ColorsInteriorCellSpacer />
                      </React.Fragment>
                    ))}
                  </tr>
                </Thead>
              </Table>
            </div>
          </ScrollSyncPane>
          <ResizeDetector handleHeight onResize={onResize} targetDomEl={ref === null ? undefined : (ref.current as HTMLElement)} />
        </StickyHeaderSection>
        <ScrollSyncPane group="horizontal">
          <div style={{ overflowX: 'scroll' }}>
            <Table>
              <tbody>
                {filteredColors.map(colorMap => {
                  const color = colorMap.langs[defaultLang];
                  return (
                    <ColorsInteriorBodyRow zebra key={color.uid}>
                      {filteredGradeItems().map(gradeItem => (
                        <React.Fragment key={`${color.uid}${gradeItem.uid}`}>
                          {color.interiorApplicability &&
                            color.interiorApplicability[gradeItem.grade.id] &&
                            color.interiorApplicability[gradeItem.grade.id].map(interior => (
                              <CheckboxCell
                                key={`${color.uid}${gradeItem.uid}${interior.interiorItem.id}`}
                                id={`chboxGrd${color.uid}${gradeItem.uid}${interior.interiorItem.id}`}
                                checked={interior.checked}
                                disabled={readOnly || !hasEnglishWritePerms}
                                onChange={() => {
                                  interior.checked = !interior.checked;
                                  saveExteriorColorLangMap(colorMap, interior);
                                }}
                              />
                            ))}
                          <ColorsInteriorCellSpacer />
                        </React.Fragment>
                      ))}
                    </ColorsInteriorBodyRow>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </ScrollSyncPane>
      </RightTableSizer>
    </ScrollSync>
  );
};

export default observer(ColorAccessibilityLangTable);
