import { observer } from "mobx-react-lite";
import React, { ReactNode, useCallback, useRef } from "react";
import ResizeDetector from "react-resize-detector";
import { ScrollSync, ScrollSyncPane } from "react-scroll-sync";
import { Table, Thead } from "../../../../components/Table";
import RightTableSizer from "../../../../components/Table/components/RightTableSizer";
import useStores from "../../../../hooks/useStores";
import styles from "./modelTable.module.scss";

type StickyTableProps = {
  renderHeaders: () => ReactNode;
  renderRows: () => ReactNode;
  className?: string;
  onTableHeaderHeightChange?: (height: number) => void;
  tableRowHeight?: number;
};

const StickyTable: React.FC<StickyTableProps> = ({
  renderHeaders,
  renderRows,
  className,
  onTableHeaderHeightChange,
  tableRowHeight,
}) => {
  const { tableSizeStore } = useStores();

  const ref = useRef<HTMLDivElement>(null);
  const tableRef = useRef<HTMLDivElement>(null);

  const onResize = useCallback(
    (width: number = 0, height: number = 0) => {
      const clientHeight = tableRef.current?.clientHeight ?? 0;
      tableSizeStore.scrollBarHeight = height - clientHeight;

      if (onTableHeaderHeightChange) {
        return onTableHeaderHeightChange(height);
      }

      tableSizeStore.tableRowHeight = height;
    },
    [tableSizeStore, onTableHeaderHeightChange]
  );

  return (
    <ScrollSync>
      <RightTableSizer className={className}>
        <div ref={ref} className={styles.headerSection}>
          <ScrollSyncPane group="horizontal">
            <div className={styles.scrollerDivHidden} ref={tableRef}>
              <Table style={{ height: `${tableRowHeight}px` }}>
                <Thead>{renderHeaders()}</Thead>
              </Table>
            </div>
          </ScrollSyncPane>

          <ResizeDetector handleHeight onResize={onResize} />
        </div>
        <ScrollSyncPane group="horizontal">
          <div className={styles.scrollerDiv}>
            <Table>
              <tbody>{renderRows()}</tbody>
            </Table>
          </div>
        </ScrollSyncPane>
      </RightTableSizer>
    </ScrollSync>
  );
};

export default observer(StickyTable);
