import { makeObservable, observable, override } from "mobx";
import {
  BnPChangeTypeMap,
  BnPReviewItem,
} from "../../../models/buildAndPrice.model";
import {
  ReviewChangeRequest,
  ReviewChangeTypeMap,
} from "../../../models/review.model";
import { VehicleTeam } from "../../../models/vehicleData.model";
import BaseReviewStore from "./baseReviewStore";

class BuildAndPriceReviewStore extends BaseReviewStore {
  reviewItems: BnPReviewItem[] = [];

  constructor() {
    // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
    super();

    makeObservable(this, {
      reviewItems: observable,
      reset: override,
    });
  }

  getMapPayload(map: BnPChangeTypeMap, team: VehicleTeam): ReviewChangeRequest {
    return {
      id: map.id,
      revId: map.revId,
      isAccepted: map.isApplied || team === VehicleTeam.AGENCY_TEAM,
      isApplied: map.isApplied,
      changeTypeId: "",
      rejectNotes: map.rejectNotes,
      itemKey: map.itemKey,
      categoryKey: map.categoryKey,
    };
  }

  isMapValid = (map: ReviewChangeTypeMap, team: VehicleTeam): boolean => {
    return (
      team === VehicleTeam.AGENCY_TEAM || map.isApplied || !!map.rejectNotes
    );
  };

  reset() {
    this.reviewItems = [];
  }
}

export default BuildAndPriceReviewStore;
