import { computed, observable, makeObservable } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import { ChangeLogBase, ChangeLogTypes } from './changeLog.model';

export class CommonLanguageItem {
  uid = '';
  id = '';
  revId = '';
  categoryId = '';
  category = '';
  subCategory = '';
  description = '';
  isInProgress = false;
  notes = '';
  lastPublishedVersion: undefined | number;
  lastPublishedDate = '';
  vehicles: CommonLanguageVehicle[] = [];

  constructor(item?: CommonLanguageResponse) {
    makeObservable(this, {
      category: observable,
      subCategory: observable,
      description: observable,
      isInProgress: observable,
      notes: observable,
      lastPublishedVersion: observable,
      lastPublishedDate: observable,
      vehicles: observable,
      isValid: computed
    });

    this.uid = uuidv4();
    if (item) {
      Object.assign(this, item);
    }
  }

  setCategory = (categoryId: string, category: string) => {
    this.categoryId = categoryId;
    this.category = category;
  };

  get isValid() {
    return !!(this.category && this.description && this.subCategory);
  }

  getPayload(): CommonLanguageRequest {
    const {
      id,
      revId,
      categoryId,
      category,
      subCategory,
      description,
      isInProgress,
      notes,
      vehicles,
    } = this;
    return {
      id,
      revId,
      categoryId,
      category,
      subCategory,
      description,
      isInProgress,
      notes,
      vehicles,
    };
  }
}

export interface CommonLanguageResponse {
  id: string;
  revId: string;
  categoryId?: string;
  category: string;
  subCategory: string;
  description: string;
  lastPublishedVersion?: number;
  lastPublishedDate?: string;
  publishedDate?: string;
  vehicles?: CommonLanguageVehicle[];
  notes: string;
}

export interface CommonLanguageRequest {
  id: string;
  revId: string;
  description: string;
  isInProgress: boolean;
  subCategory: string;
  notes: string;
  category: string;
  categoryId: string;
  vehicles: CommonLanguageVehicle[];
}

export interface CommonLanguageCategory {
  id: string;
  value: string;
}

export interface CommonLanguageChangeLogResponse {
  currentChangeCount?: number;
  lastResetDate?: string;
  changes: CommonLanguageChangeLogResponseItem[];
}

export interface CommonLanguageChangeLogResponseItem {
  id: string;
  revId: string;
  isInProgress: boolean;
  description: string;
  notes: string;
  changes: CommonLanguageChangeLogResponseChange[];
}

export interface CommonLanguageChangeLogResponseChange {
  before: string;
  beforeValue: string;
  after: string;
  afterValue: string;
  changeType: string;
  modelsAdded?: string[];
  modelsRemoved?: string[];
  modifiedDate: string;
  modifiedBy: string;
}

export interface CommonLanguageChangeLogItem extends ChangeLogBase {
  uid: string;
  id: string;
  revId: string;
  description: string;
  isInProgress: boolean;
  notes: string;
  changeType: ChangeLogTypes;
  before: string;
  beforeValue: string;
  after: string;
  afterValue: string;
  changes: CommonLanguageChangeLogChange[];
  modelsAdded?: string[];
  modelsRemoved?: string[];
  modifiedDate: string;
  modifiedBy: string;
  canRevert: boolean;
}

export interface CommonLanguageChangeLogChange {
  added?: boolean;
  removed?: boolean;
  value?: string;
}

export interface CommonLanguageVehicle {
  modelYear: string;
  seriesId: string;
  seriesName: string;
  group?: string;
}

export interface CommonLanguageVehicleChecklist {
  name: string;
  selected: boolean;
  items: Array<{ id: string; name: string; selected: boolean }>;
}
