import { observable, makeObservable } from 'mobx';
import { SeriesCategories } from '../../models/vehicleData.model';

class VehicleSeriesInfoStore {
  seriesName = '';
  seriesId = '';
  seriesGroup = '' as SeriesCategories;
  year = '';

  constructor() {
    makeObservable(this, {
      seriesName: observable,
      seriesId: observable,
      seriesGroup: observable,
      year: observable
    });
  }
}

export default VehicleSeriesInfoStore;
