import { observable, makeObservable } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import { cleanUpRte } from 'vapi-ui-common';
import { removeNulls } from '../utils';
import { ChangeLogBase, ChangeLogTypes } from './changeLog.model';
import { BaseReviewItem, IDValueType, KeyValueType } from './common.model';
import { RefItem } from './refItem.model';
import { ReviewChangeMap, ReviewChangeResponse, ReviewChangeTypeMap } from './review.model';

export class SpecItem {
  uid = '';
  id = '';
  revId = '';
  comLangId = '';
  modelsMap: KeyValueType<SpecsModel> = {};
  link = '';
  category = {} as RefItem;
  specType = {} as RefItem;
  description = '';
  isInProgress = false;
  isRequired = false;
  notes = '';
  rejectNotes = '';
  sortOrder: number | string = 0;
  compareFeatureId: string = '';
  changedAttributes: string[] = [];
  changedModelIds: string[] = [];
  isTDPR: boolean = false;
  isUSVI: boolean = false;
  fromTMNA: boolean = false;

  constructor(spec?: SpecResponse) {
    makeObservable(this, {
      comLangId: observable,
      modelsMap: observable,
      link: observable,
      category: observable,
      specType: observable,
      description: observable,
      isInProgress: observable,
      isRequired: observable,
      notes: observable,
      rejectNotes: observable,
      sortOrder: observable,
      compareFeatureId: observable,
      changedAttributes: observable,
      changedModelIds: observable,
      isTDPR: observable,
      isUSVI: observable,
      fromTMNA: observable
    });

    this.uid = uuidv4();
    if (spec) {
      Object.assign(this, removeNulls(spec));
      this.changedAttributes.forEach(attr => {
        if (attr.includes('modelApplicability#')) {
          const splt = attr.split('#');
          if (splt.length > 1) {
            this.changedModelIds.push(splt[1]);
          }
        }
      });
    }
  }

  getPayload = () => {
    const modelApplicability = {} as SpecModelApplicability;
    Object.values(this.modelsMap).forEach(mdl => {
      modelApplicability[mdl.id] = mdl.setting;
    });

    return {
      id: this.id,
      revId: this.revId,
      categoryId: this.category.id,
      specTypeId: this.specType.id,
      comLangId: this.comLangId,
      description: cleanUpRte(this.description),
      isInProgress: this.isInProgress,
      notes: this.notes,
      link: this.link,
      modelApplicability,
    } as SpecRequest;
  };

  isValid = () => {
    return !!(this.category.id && this.specType.id && this.description);
  };
}

export interface SpecResponse {
  id: string;
  revId: string;
  modelApplicability: SpecModelApplicability;
  categoryId: string;
  specTypeId: string | undefined;
  description: string;
  isInProgress: boolean;
  notes: string | undefined;
  comLangId?: string;
  changedAttributes?: string[];
  isTDPR?: boolean;
  isUSVI?: boolean;
  fromTMNA?: boolean;
}

export interface SpecRequest extends SpecResponse {}

type SpecModelApplicability = {
  [modelCode: string]: SpecSettings;
};

export type SpecsModel = {
  id: string; // modelid
  setting: SpecSettings; // value in settings cell
};

export class SpecsReviewItem extends BaseReviewItem {
  specType = {} as IDValueType;
  models: SpecsModel[] = [];
  modelApplicability: KeyValueType<SpecSettings> = {};

  constructor({
    spec,
    change,
    category,
    specType,
    models,
    changeTypeId,
  }: {
    spec?: SpecsReviewResponse;
    change?: ReviewChangeResponse;
    category?: RefItem;
    specType?: RefItem;
    models?: SpecsModel[];
    changeTypeId?: string;
  }) {
    super();

    makeObservable(this, {
      specType: observable,
      models: observable,
      modelApplicability: observable
    });

    this.uid = uuidv4();
    if (spec) {
      Object.assign(this, removeNulls(spec));
    }
    this.destructureReviewChangeResponse(change);
    this.createOtherChanges(spec);

    if (category) {
      this.category = category;
    }

    if (specType) {
      this.specType = specType;
    }

    if (models) {
      this.models = models;
    }

    if (changeTypeId) {
      this.changeTypeId = changeTypeId;
    }
  }
}

export interface SpecsReviewResponse {
  isHighlighted: string;
  notes: string;
  modelApplicability: SpecModelApplicability;
  link: string;
  revId: string;
  changes: KeyValueType<ReviewChangeResponse>;
  otherChanges?: KeyValueType<ReviewChangeResponse>;
  description: string;
  specTypeId: string;
  specType?: string;
  shortDescription: string;
  isDeleted: boolean;
  isAccepted: boolean;
  isApplied: boolean;
  isNewChange: boolean;
  id: string;
  isInProgress: boolean;
  categoryId: string;
  category?: string;
}

export interface SpecsReviewRequest {
  id: string;
  revId: string;
  isAccepted: boolean;
  isApplied: boolean;
  rejectNotes: string;
}

export enum SpecSettings {
  STANDARD = 'S',
  OPTIONAL = 'O',
  PACKAGE = 'P',
  UNDEFINED = '',
}

export interface SpecsReviewMap {
  [id: string]: SpecsChangeTypeMap;
}

export interface SpecsChangeTypeMap extends ReviewChangeTypeMap {
  category: ReviewChangeMap<string>;
  specType: ReviewChangeMap<string>;
  description: ReviewChangeMap<string>;
  modelApplicability: ReviewChangeMap<KeyValueType<SpecSettings>>;
}

export type SpecReviewType =
  | 'category'
  | 'specType'
  | 'description'
  | 'modelApplicability'
  | 'added'
  | 'deleted';

export class SpecsReviewChangeItem implements ChangeLogBase {
  id = '';
  revId = '';
  before = '';
  after = '';
  beforeValue = '';
  afterValue = '';
  modifiedDate = '';
  modifiedBy = '';
  changeType = '' as ChangeLogTypes;
}

export interface SpecsMap {
  specs: {
    [id: string]: SpecsLangMap;
  };
  order: string[];
}

export interface SpecsLangMap {
  langs: {
    [lang: string]: SpecItem;
  };
  data?: SpecResponse;
}
