import { computed, makeObservable, observable } from "mobx";
import { v4 as uuidv4 } from "uuid";
import { removeNulls } from "../utils";
import { KeyValueType } from "./common.model";
import {
  ReviewChangeMap,
  ReviewChangeResponse,
  ReviewChangeTypeMap,
} from "./review.model";
import { Brand, BRAND_TOYOTA } from "./user.model";

export class SeriesSettingsItem {
  uid = "";
  id = "";
  revId = "";
  name = "";
  fuelTypes = {} as TypesCatItems;
  seriesCategories = {} as TypesCatItems;
  seating = "";
  estimatedMileage = "";
  mpge = "";
  isPublishable = true;
  horsepower = "";
  startingMSRP = "";
  brand = "";
  isDeleted = false;
  subSeries = {} as SubSeriesItem;
  isNewSubSeries = false;
  parentId = "";
  parentRevId = "";
  sortOrder = 0;
  isSubSeries = false;
  isMpgeRequired = false;
  isRangeRequired = false;
  isFuelCell = false;
  changedAttributes: string[] = [];
  fromTMNA: boolean = false;
  range = "";

  constructor(brand: Brand, seriesSetting?: SeriesSettingsResponse) {
    makeObservable(this, {
      name: observable,
      fuelTypes: observable,
      seriesCategories: observable,
      seating: observable,
      estimatedMileage: observable,
      mpge: observable,
      isPublishable: observable,
      horsepower: observable,
      startingMSRP: observable,
      brand: observable,
      isDeleted: observable,
      subSeries: observable,
      isNewSubSeries: observable,
      parentId: observable,
      parentRevId: observable,
      sortOrder: observable,
      isSubSeries: observable,
      isMpgeRequired: observable,
      isRangeRequired: observable,
      isFuelCell: observable,
      changedAttributes: observable,
      fromTMNA: observable,
      range: observable,
      isValid: computed,
    });

    this.uid = uuidv4();
    this.brand = brand;
    if (seriesSetting) {
      Object.assign(this, removeNulls(seriesSetting));
    }
  }

  get isValid() {
    const isMpgeValid = this.isMpgeRequired ? this.mpge !== "" : true;
    const isRangeValid =
      this.isRangeRequired && this.brand === BRAND_TOYOTA
        ? this.range !== ""
        : true;
    const isEstimatedMileageValid =
      this.isRangeRequired || this.isFuelCell
        ? true
        : this.estimatedMileage !== "";
    const commonProps =
      this.name !== "" &&
      this.seating !== "" &&
      isEstimatedMileageValid &&
      isMpgeValid &&
      isRangeValid &&
      Object.keys(this.fuelTypes).length &&
      Object.keys(this.seriesCategories).length;

    return commonProps;
  }

  getPayloadToyota(): SeriesSettingsRequest {
    return {
      id: this.id,
      revId: this.revId,
      name: this.name,
      fuelTypes: this.fuelTypes,
      seriesCategories: this.seriesCategories,
      seating: this.seating,
      estimatedMileage: this.estimatedMileage ?? "",
      mpge: this.mpge ?? "",
      isPublishable: this.isPublishable,
      isDeleted: this.isDeleted,
      subSeries: this.subSeries,
      range: this.range ?? "",
    };
  }

  getSubSeriesPayloadToyota(): SeriesSettingsRequest {
    return {
      id: this.id,
      revId: this.revId,
      name: this.name,
      fuelTypes: this.fuelTypes,
      seriesCategories: this.seriesCategories,
      seating: this.seating,
      estimatedMileage: this.estimatedMileage ?? "",
      mpge: this.mpge ?? "",
      isPublishable: this.isPublishable,
      isDeleted: this.isDeleted,
      parentId: this.parentId,
      parentRevId: this.parentRevId,
      range: this.range ?? "",
    };
  }
}

export interface SeriesSettingsResponse {
  id: string;
  revId: string;
  name: string;
  fuelTypes: TypesCatItems;
  seriesCategories: TypesCatItems;
  seating: string;
  estimatedMileage: string;
  mpge: string;
  isPublishable: boolean;
  isDeleted?: boolean;
  horsepower?: string;
  startingMSRP?: string;
  subSeries?: SubSeriesItem;
  parentId?: string;
  parentRevId?: string;
  range?: string;
  changedAttributes?: string[];
  fromTMNA?: boolean;
}

export interface SeriesSettingsRequest extends SeriesSettingsResponse {}

export interface SeriesCategoryItem {
  id: string;
  name: string;
  revId: string;
}

export interface TypesCatChecklist {
  id: string;
  name: string;
  selected: boolean;
}

export type TypesCatItems = {
  [id: string]: boolean;
};

export type SubSeriesItem = {
  [seriesId: string]: SeriesSettingsItem;
};

export interface SeriesSettingsLangMap {
  [lang: string]: SeriesSettingsItem;
}

export interface SeriesSettingsMap {
  series: {
    [seriesId: string]: SeriesSettingsLangMap;
  };
  order: string[];
}

export interface SubSeriesSettingsReviewResponse extends SeriesSettingsItem {
  changes: KeyValueType<ReviewChangeResponse>;
}

export interface SeriesSettingsReviewResponse extends SeriesSettingsResponse {
  subSeries?: { [seriesId: string]: SubSeriesSettingsReviewResponse };
  changes: KeyValueType<ReviewChangeResponse>;
}

export interface SeriesSettingsReviewMap {
  [id: string]: SeriesSettingsChangeTypeMap;
}

export interface SeriesSettingsChangeTypeMap extends ReviewChangeTypeMap {
  parentId?: string;
  isPublishable: boolean;
  name: ReviewChangeMap<string>;
  fuelTypes: ReviewChangeMap<KeyValueType<boolean>>;
  seriesCategories: ReviewChangeMap<KeyValueType<boolean>>;
  seating: ReviewChangeMap<string>;
  estimatedMileage: ReviewChangeMap<string>;
  mpge: ReviewChangeMap<string>;
  range: ReviewChangeMap<string>;
}

export type SeriesSettingsReviewType =
  | "name"
  | "fuelTypes"
  | "seriesCategories"
  | "seating"
  | "estimatedMileage"
  | "mpge"
  | "range"
  | "added"
  | "deleted";
