import React, { useEffect, useState } from 'react';
import { Button, Popover } from 'vapi-ui-common';
import { VDStatus } from '../../constants/vehicleData/VDConstants';
import useStores from '../../hooks/useStores';
import { KeyValueType } from '../../models/common.model';
import { BRAND_ENGAGE, BRAND_TOYOTA, Language } from '../../models/user.model';
import { VehicleTeam } from '../../models/vehicleData.model';
import { getSeries } from '../../webservices/adminApi';
import { getVehicleData } from '../../webservices/vehicleAdminApi';
import SyncUpdatesPopoverContent from './SyncUpdatesPopoverContent';
import styles from './syncUpdatesPopover.module.scss';

interface ISyncUpdatesPopover {
  seriesId: string;
  year: string;
  syncUpdates: () => void;
  align?: 'right' | 'left';
}

const getDates = async (brand: string, team: VehicleTeam, seriesId: string, year: string, version: string, key: 'lastSyncDate' | 'lastPublishedDate') => {
  const dates: KeyValueType<string> = { [Language.EN]: '' };
  const enResponse = await getVehicleData(brand, team, seriesId, year, version, Language.EN);

  if (enResponse.data[key]) {
    dates[Language.EN] = enResponse.data[key]!;
  }

  let hasSourceUpdates = !!(enResponse.data.hasSourceUpdates && enResponse.data.hasSourceUpdates.toyota);
  return { dates, hasSourceUpdates, sourceVersion: enResponse.data.sourceVersion };
};

const SyncUpdatesPopover = ({ seriesId, year, syncUpdates, align = 'right' }: ISyncUpdatesPopover) => {
  const {
    userStore: { brand },
    teamStore: {
      team: { param: team },
    },
  } = useStores();

  const [lastSyncDates, setLastSyncDates] = useState<KeyValueType<string>>({});
  const [lastPublishedDates, setLastPublishedDates] = useState<KeyValueType<string>>({});
  const [show, setShow] = useState<boolean>(false);
  const [tooltipHover, setTooltipHover] = useState(false);

  useEffect(() => {
    (async () => {
      const { dates: syncDates, hasSourceUpdates, sourceVersion } = await getDates(brand, team, seriesId, year, VDStatus.DRAFT.toUpperCase(), 'lastSyncDate');

      const engageSeries = await getSeries(BRAND_ENGAGE);
      let toyotaSeriesId = '';

      for (const series of Object.values(engageSeries.data.series)) {
        if (series.id === seriesId && series.sourceBrand === BRAND_TOYOTA && series.sourceSeriesId) {
          toyotaSeriesId = series.sourceSeriesId;
          break;
        }
      }

      if (toyotaSeriesId && sourceVersion) {
        const { dates: publishedDates } = await getDates(BRAND_TOYOTA, team, toyotaSeriesId, year, VDStatus.PUBLISHED.toUpperCase(), 'lastPublishedDate');
        setLastPublishedDates(publishedDates);
      }

      setShow(hasSourceUpdates);
      setLastSyncDates(syncDates);
    })();
  }, [brand, team, seriesId, year, setLastPublishedDates, setLastSyncDates, setShow]);

  if (!show) {
    return <></>;
  }

  return (
    <Button variant="primary" onMouseOver={() => setTooltipHover(true)} onMouseOut={() => setTooltipHover(false)} onClick={syncUpdates}>
      Sync Updates
      {tooltipHover && (
        <Popover
          popoverElementClass={styles.popover}
          align={align}
          defaultOpen
          toggleElement={<></>}
          popoverElement={<SyncUpdatesPopoverContent lastSyncDates={lastSyncDates} lastPublishedDates={lastPublishedDates} />}
        />
      )}
    </Button>
  );
};

export default SyncUpdatesPopover;
