import apolloClient from '../apolloClient';
import { STATUS_DRAFT } from '../constants/vehicleData/VDConstants';
import {
  AddCategoriesFeatureDocument,
  AddCategoriesFeatureMutation,
  AddCategoriesFeatureMutationVariables,
  AddFeatureDocument,
  AddFeatureMutation,
  AddFeatureMutationVariables,
  AddSubCategoriesDocument,
  AddSubCategoriesMutation,
  AddSubCategoriesMutationVariables,
  DeleteFeatureDocument,
  DeleteFeatureMutation,
  DeleteFeatureMutationVariables,
  GetCategoriesByLangFeatureDocument,
  GetCategoriesByLangFeatureQuery,
  GetCategoriesByLangFeatureQueryVariables,
  GetChangeLogFeaturesDocument,
  GetChangeLogFeaturesQuery,
  GetChangeLogFeaturesQueryVariables,
  GetFeaturesDocument,
  GetFeaturesQuery,
  GetFeaturesQueryVariables,
  GetSubCategoriesByLangDocument,
  GetSubCategoriesByLangQuery,
  GetSubCategoriesByLangQueryVariables,
  RevertChangeLogFeaturesDocument,
  RevertChangeLogFeaturesMutation,
  RevertChangeLogFeaturesMutationVariables,
  UpdateCategoryFeatureDocument,
  UpdateCategoryFeatureMutation,
  UpdateCategoryFeatureMutationVariables,
  UpdateFeatureDocument,
  UpdateFeatureMutation,
  UpdateFeatureMutationVariables,
  UpdateSubCategoryDocument,
  UpdateSubCategoryMutation,
  UpdateSubCategoryMutationVariables,
  VehicleFeature as VehicleFeatureGQL,
  VehicleFeaturesResponse,
} from '../gql/generated';
import { CategoryLanguageMap, CategoryResponse } from '../models/category.model';
import { ChangeLogRequest, ChangeLogResponse } from '../models/changeLog.model';
import { FeatureRequest, FeatureResponse, FeatureSplitRequest, FeatureSplitResponse, FeaturesReviewRequest, FeaturesReviewResponse } from '../models/features.model';
import { ReviewChangeRequest } from '../models/review.model';
import { VehicleTeam } from '../models/vehicleData.model';
import { vehicleFeaturesResponseToFeatureResponse } from '../utils/featuresUtils';
import { getStatusAndVersionGqlReq } from '../utils/vehicleDataUtils';
import API from '../webservices/api';
import { CATEGORIES_PATH, getCategories as _getCategories } from './categoryApi';

const RESOURCE_PATH = '/vehicle-features';

// Models
const FEATURES_PATH = '/features';
export const getFeatures = (brand: string, team: VehicleTeam, seriesId: string, modelYear: string, language: string, version?: string) =>
  apolloClient
    .query<GetFeaturesQuery, GetFeaturesQueryVariables>({
      query: GetFeaturesDocument,
      fetchPolicy: 'no-cache',
      variables: {
        team,
        seriesId,
        modelYear: Number(modelYear),
        language,
        ...getStatusAndVersionGqlReq(version),
      },
    })
    .then(res => ({
      ...res,
      data: vehicleFeaturesResponseToFeatureResponse(res.data.vehicleFeatures as VehicleFeaturesResponse),
    }));

export const addFeature = (brand: string, team: VehicleTeam, seriesId: string, modelYear: string, payload: FeatureRequest, language: string) =>
  apolloClient
    .mutate<AddFeatureMutation, AddFeatureMutationVariables>({
      mutation: AddFeatureDocument,
      fetchPolicy: 'no-cache',
      variables: {
        team,
        seriesId,
        modelYear: Number(modelYear),
        language,
        id: payload.id,
        categoryId: payload.categoryId,
        subCategoryId: payload.subCategoryId as string,
        comLangId: payload.comLangId,
        description: payload.description,
        shortDescription: payload.shortDescription,
        link: payload.link,
        notes: payload.notes,
        isInProgress: payload.isInProgress,
        isHighlighted: payload.isHighlighted,
        modelApplicability: Object.entries(payload.modelApplicability).map(([key, value]) => ({ id: key, text: value })),
      },
    })
    .then(res => {
      const vehicleFeature: VehicleFeaturesResponse = {
        vehicleFeatures: [res.data?.createVehicleFeature as VehicleFeatureGQL],
      };

      return {
        ...res,
        data: vehicleFeaturesResponseToFeatureResponse(vehicleFeature)[0],
      };
    });

export const updateFeature = (
  brand: string,
  team: VehicleTeam,
  seriesId: string,
  modelYear: string,
  payload: FeatureRequest,
  language: string,
  acceptChanges: boolean = false,
  unlinkFromTMNA: boolean = false,
) =>
  apolloClient
    .mutate<UpdateFeatureMutation, UpdateFeatureMutationVariables>({
      mutation: UpdateFeatureDocument,
      fetchPolicy: 'no-cache',
      variables: {
        team,
        seriesId,
        modelYear: Number(modelYear),
        language,
        ...payload,
        subCategoryId: payload.subCategoryId as string,
        modelApplicability: Object.entries(payload.modelApplicability).map(([key, value]) => ({ id: key, text: value })),
        acceptChanges: acceptChanges || unlinkFromTMNA,
        unlinkFromTMNA,
      },
    })
    .then(res => {
      const vehicleFeature: VehicleFeaturesResponse = {
        vehicleFeatures: [res.data?.updateVehicleFeature as VehicleFeatureGQL],
      };

      return {
        ...res,
        data: vehicleFeaturesResponseToFeatureResponse(vehicleFeature)[0],
      };
    });

//
export const deleteFeature = (brand: string, team: VehicleTeam, seriesId: string, modelYear: string, language: string, featureId: string) =>
  apolloClient.mutate<DeleteFeatureMutation, DeleteFeatureMutationVariables>({
    mutation: DeleteFeatureDocument,
    fetchPolicy: 'no-cache',
    variables: {
      team,
      seriesId,
      modelYear: Number(modelYear),
      language,
      id: featureId,
    },
  });

export const importFromCommonLanguage = (brand: string, team: VehicleTeam, series: string, year: string, payload: string[]) => {
  return API.post<FeatureResponse[]>(`${RESOURCE_PATH}${FEATURES_PATH}/${brand}/${team}/${series}/${year}/import`, { commonLangIds: payload });
};

// ChangeLog
export const getChangeLog = (brand: string, team: VehicleTeam, seriesId: string, modelYear: string, language: string, version: string) =>
  apolloClient
    .query<GetChangeLogFeaturesQuery, GetChangeLogFeaturesQueryVariables>({
      query: GetChangeLogFeaturesDocument,
      fetchPolicy: 'no-cache',
      variables: {
        team,
        seriesId,
        modelYear: Number(modelYear),
        language,
        ...getStatusAndVersionGqlReq(version),
      },
    })
    .then(res => ({
      ...res,
      data: JSON.parse(res.data.vehicleFeaturesChanges?.body) as ChangeLogResponse[],
    }));

export const revertChange = (brand: string, team: VehicleTeam, seriesId: string, modelYear: string, language: string, payload: ChangeLogRequest) =>
  apolloClient
    .mutate<RevertChangeLogFeaturesMutation, RevertChangeLogFeaturesMutationVariables>({
      mutation: RevertChangeLogFeaturesDocument,
      fetchPolicy: 'no-cache',
      variables: {
        brand,
        team,
        seriesId,
        modelYear: Number(modelYear),
        language,
        body: payload,
      },
    })
    .then(res => ({
      ...res,
      data: JSON.parse(res.data?.revertVehicleFeaturesChanges?.body),
    }));

// Categories
export const getCategories = (brand: string, team: VehicleTeam, series: string, year: string, version?: string, includeAll?: boolean) => {
  return _getCategories(RESOURCE_PATH, brand, team, series, year, 'EN', version, includeAll);
};

export const getCategoriesByLang = (brand: string, team: VehicleTeam, seriesId: string, modelYear: string, language: string, version?: string, includeAll?: boolean) =>
  apolloClient
    .query<GetCategoriesByLangFeatureQuery, GetCategoriesByLangFeatureQueryVariables>({
      query: GetCategoriesByLangFeatureDocument,
      fetchPolicy: 'no-cache',
      variables: {
        team,
        seriesId,
        modelYear: Number(modelYear),
        language,
        includeAll,
        ...getStatusAndVersionGqlReq(version),
      },
    })
    .then(res => {
      const data = res.data.vehicleFeaturesCategories?.vehicleFeaturesCategories ?? [];
      return {
        ...res,
        data,
      };
    });

export const addCategories = (brand: string, team: VehicleTeam, seriesId: string, modelYear: string, payload: { [lang: string]: string }, id?: string) =>
  apolloClient
    .mutate<AddCategoriesFeatureMutation, AddCategoriesFeatureMutationVariables>({
      mutation: AddCategoriesFeatureDocument,
      fetchPolicy: 'no-cache',
      variables: {
        team,
        seriesId,
        modelYear: Number(modelYear),
        categories: Object.entries(payload).map(([key, value]) => ({
          language: key,
          name: value,
        })),
        id,
      },
    })
    .then(res => {
      const data: CategoryLanguageMap = {};
      res.data?.createVehicleFeatureCategory?.vehicleFeaturesLanguageCategories?.forEach(({ language, vehicleFeaturesCategories }) => {
        data[language] = vehicleFeaturesCategories ?? [];
      });

      return {
        ...res,
        data,
      };
    });

export const updateCategory = (brand: string, team: VehicleTeam, seriesId: string, modelYear: string, id: string, name: string, language: string) =>
  apolloClient.mutate<UpdateCategoryFeatureMutation, UpdateCategoryFeatureMutationVariables>({
    mutation: UpdateCategoryFeatureDocument,
    fetchPolicy: 'no-cache',
    variables: {
      team,
      seriesId,
      modelYear: Number(modelYear),
      language,
      id,
      name,
    },
  });

// Sub categories
const SUB_CATEGORIES_PATH = '/sub-categories';
export const getSubCategories = (brand: string, team: VehicleTeam, series: string, year: string, version?: string, includeAll?: boolean) => {
  let params = !version || version === 'DRAFT' ? STATUS_DRAFT : `version=${version}`;
  if (includeAll) {
    params += '&includeAll=y';
  }
  return API.get<CategoryResponse[]>(`${RESOURCE_PATH}${SUB_CATEGORIES_PATH}/${brand}/${team}/${series}/${year}/EN?${params}`);
};

export const getSubCategoriesByLang = (brand: string, team: VehicleTeam, seriesId: string, modelYear: string, language: string, version?: string, includeAll?: boolean) =>
  apolloClient
    .query<GetSubCategoriesByLangQuery, GetSubCategoriesByLangQueryVariables>({
      query: GetSubCategoriesByLangDocument,
      fetchPolicy: 'no-cache',
      variables: {
        team,
        seriesId,
        modelYear: Number(modelYear),
        language,
        includeAll,
        ...getStatusAndVersionGqlReq(version),
      },
    })
    .then(res => {
      const data = res.data.vehicleFeaturesSubCategories?.vehicleFeaturesSubCategories ?? [];
      return {
        ...res,
        data,
      };
    });

export const addSubCategories = (brand: string, team: VehicleTeam, seriesId: string, modelYear: string, payload: { [lang: string]: string }, id?: string) =>
  apolloClient
    .mutate<AddSubCategoriesMutation, AddSubCategoriesMutationVariables>({
      mutation: AddSubCategoriesDocument,
      fetchPolicy: 'no-cache',
      variables: {
        team,
        seriesId,
        modelYear: Number(modelYear),
        subCategories: Object.entries(payload).map(([key, value]) => ({
          language: key,
          name: value,
        })),
        id,
      },
    })
    .then(res => {
      const data: CategoryLanguageMap = {};
      res.data?.createVehicleFeatureSubCategory?.vehicleFeaturesLanguageSubCategories?.forEach(({ language, vehicleFeaturesSubCategories }) => {
        data[language] = vehicleFeaturesSubCategories ?? [];
      });

      return {
        ...res,
        data,
      };
    });

export const updateSubCategory = (brand: string, team: VehicleTeam, seriesId: string, modelYear: string, id: string, name: string, language: string) =>
  apolloClient.mutate<UpdateSubCategoryMutation, UpdateSubCategoryMutationVariables>({
    mutation: UpdateSubCategoryDocument,
    fetchPolicy: 'no-cache',
    variables: {
      team,
      seriesId,
      modelYear: Number(modelYear),
      language,
      id,
      name,
    },
  });

// Review
const REVIEW_PATH = '/review';
export const getReviewCategories = (brand: string, team: VehicleTeam, series: string, year: string, version?: string) => {
  const params = !version || version === 'DRAFT' ? STATUS_DRAFT : `version=${version}`;
  return API.get<CategoryResponse[]>(`${RESOURCE_PATH}${REVIEW_PATH}${CATEGORIES_PATH}/${brand}/${team}/${series}/${year}?${params}`);
};

export const getReviewSubCategories = (brand: string, team: VehicleTeam, series: string, year: string, version?: string) => {
  const params = !version || version === 'DRAFT' ? STATUS_DRAFT : `version=${version}`;
  return API.get<CategoryResponse[]>(`${RESOURCE_PATH}${REVIEW_PATH}${SUB_CATEGORIES_PATH}/${brand}/${team}/${series}/${year}?${params}`);
};

export const getReviewFeatures = (brand: string, team: VehicleTeam, series: string, year: string, version?: string) => {
  const params = !version || version === 'DRAFT' ? STATUS_DRAFT : `version=${version}`;
  return API.get<FeaturesReviewResponse[]>(`${RESOURCE_PATH}${REVIEW_PATH}${FEATURES_PATH}/${brand}/${team}/${series}/${year}?${params}`);
};

export const updateReviewFeature = (brand: string, team: VehicleTeam, series: string, year: string, version: string, payload: ReviewChangeRequest) => {
  const params = !version || version === 'DRAFT' ? STATUS_DRAFT : `version=${version}`;
  return API.put<FeaturesReviewRequest>(`${RESOURCE_PATH}${REVIEW_PATH}${FEATURES_PATH}/${brand}/${team}/${series}/${year}?${params}`, payload);
};

// Review Common Language
const REVIEW_CL_PATH = '/review-cl';
export const getReviewCLFeatures = (brand: string, team: VehicleTeam, series: string, year: string, version?: string) => {
  const params = !version || version === 'DRAFT' ? STATUS_DRAFT : `version=${version}`;
  return API.get<FeaturesReviewResponse[]>(`${RESOURCE_PATH}${REVIEW_CL_PATH}${FEATURES_PATH}/${brand}/${team}/${series}/${year}?${params}`);
};

export const updateReviewCLFeature = (brand: string, team: VehicleTeam, series: string, year: string, version: string, payload: ReviewChangeRequest) => {
  const params = !version || version === 'DRAFT' ? STATUS_DRAFT : `version=${version}`;
  return API.put<FeaturesReviewRequest>(`${RESOURCE_PATH}${REVIEW_CL_PATH}${FEATURES_PATH}/${brand}/${team}/${series}/${year}?${params}`, payload);
};

// Splits
const SPLITS_PATH = '/splits';
export const addFeatureSplit = (brand: string, team: VehicleTeam, series: string, year: string, lang: string, payload: FeatureSplitRequest) => {
  return API.post<FeatureSplitResponse>(`${RESOURCE_PATH}${FEATURES_PATH}${SPLITS_PATH}/${brand}/${team}/${series}/${year}/${lang.toUpperCase()}`, payload);
};

export const updateFeatureSplit = (brand: string, team: VehicleTeam, series: string, year: string, lang: string, payload: FeatureSplitRequest) => {
  return API.put<FeatureSplitResponse>(`${RESOURCE_PATH}${FEATURES_PATH}${SPLITS_PATH}/${brand}/${team}/${series}/${year}/${lang.toUpperCase()}`, payload);
};

export const deleteFeatureSplit = (brand: string, team: VehicleTeam, series: string, year: string, lang: string, featureId: string, splitId: string) => {
  return API.delete<FeatureSplitResponse>(`${RESOURCE_PATH}${FEATURES_PATH}${SPLITS_PATH}/${brand}/${team}/${series}/${year}/${lang.toUpperCase()}/${featureId}/${splitId}`);
};
