import { action, makeObservable, observable } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import { convertToRichTextObject } from 'vapi-ui-common';
import { CategoriesMap, CategoryLangMap, CategoryResponse } from '../../models/category.model';
import { IDValueType, KeyValueType } from '../../models/common.model';
import { CompareFeatureItem, CompareFeatureLangMap, CompareFeatureResponse, CompareFeaturesMap, CompareType, HighlightSortList } from '../../models/compareFeatures.model';
import { FeatureSettings } from '../../models/features.model';
import { RefItem, RefItemObject, RefItemResponse } from '../../models/refItem.model';
import { ISortList, VDSortableEntity } from '../../models/sort.model';
import { Language, LanguagePermissions } from '../../models/user.model';
import { VehicleDataVersionInfo, VehicleTeam } from '../../models/vehicleData.model';
import { isSortReverse, sortBy, toLowerCase } from '../../utils';
import { categoriesXForm } from '../../utils/categoryUtils';
import { compareFeatureItemXForm, highlightSortListTransform } from '../../utils/compareFeaturesUtils';
import parseLangWriteMap from '../../utils/languageUtils';
import { refItemsXForm } from '../../utils/refItemUtils';
import { sortCategoriesFunction } from '../../utils/sortUtils';
import { getSortList } from '../../webservices/vehicleAdminApi';
import { getCompareFeatures } from '../../webservices/vehicleCompareFeaturesApi';
import { getCategoriesByLang as getFeatureCategories, getSubCategoriesByLang as getFeatureSubCategories } from '../../webservices/vehicleFeaturesApi';
import { getCategoriesByLang as getSpecCategories, getSpecTypesByLang as getSpecTypes } from '../../webservices/vehicleSpecsApi';

class CompareFeaturesStore {
  compareFeatures: CompareFeatureItem[] = [];
  reverseSort = false;
  sortField = 'id';
  searchText = '';
  isInProgressFilter = false;
  isSyncUpdateFilter = false;
  isReviewNotesFilter = false;
  categoryFilters: string[] = [];
  subCategoryFilters: string[] = [];
  featureCategoriesSortList: ISortList = {} as ISortList;
  featureSubCategoriesSortList: ISortList = {} as ISortList;
  specCategoriesSortList: ISortList = {} as ISortList;
  specTypesSortList: ISortList = {} as ISortList;
  categoriesSortList: ISortList = {} as ISortList;
  subCategoriesSortList: ISortList = {} as ISortList;
  grades: RefItem[] = [];
  duplicateSpecCategories: IDValueType[] = [];
  duplicateSpecTypes: IDValueType[] = [];

  // langs
  langWriteMap: LanguagePermissions = {};
  allLangs: Language[] = [];
  editableLangs: Language[] = [];
  defaultLang: Language = Language.EN;
  gradeApplicabilityLang: Language = Language.EN;
  defaultEditLang: Language = Language.EN;
  fullEditPermissions: boolean = false;

  selectedLangsMap: KeyValueType<boolean> = {};

  filteredCompareFeatures: CompareFeatureItem[] = [];

  categoriesMap: CategoriesMap = { categories: {}, order: [] };
  featureCategoriesMap: CategoriesMap = { categories: {}, order: [] };
  featureSubCategoriesMap: CategoriesMap = { categories: {}, order: [] };

  subCategoriesMap: CategoriesMap = { categories: {}, order: [] };
  specCategoriesMap: CategoriesMap = { categories: {}, order: [] };
  specTypesMap: CategoriesMap = { categories: {}, order: [] };

  highlightSortList: HighlightSortList = {};

  compareFeatureLangMaps: CompareFeatureLangMap[] = [];
  filteredCompareFeatureLangMaps: CompareFeatureLangMap[] = [];
  rowHeightMap: KeyValueType<number> = {};

  fetchData = async (
    brand: string,
    team: VehicleTeam,
    seriesId: string,
    year: string,
    grades: RefItem[],
    langWriteMap: LanguagePermissions,
    versionInfo: VehicleDataVersionInfo,
  ) => {
    this.reset();
    this.grades = grades;
    const { allLangs, editableLangs, defaultLang, selectedLangsMap, modelApplicabilityLang, fullEditPermissions, defaultEditLang } = parseLangWriteMap(langWriteMap);
    this.langWriteMap = langWriteMap;
    this.allLangs = allLangs;
    this.editableLangs = editableLangs;
    this.defaultLang = defaultLang;
    this.selectedLangsMap = selectedLangsMap;
    this.gradeApplicabilityLang = modelApplicabilityLang;
    this.fullEditPermissions = fullEditPermissions;
    this.defaultEditLang = defaultEditLang;

    const promises: Promise<any>[] = [];
    this.allLangs.forEach(lang => {
      promises.push(
        getFeatureCategories(brand, team, seriesId, year, lang, versionInfo[lang]?.toString()),
        getFeatureSubCategories(brand, team, seriesId, year, lang, versionInfo[lang]?.toString()),
        getSpecCategories(brand, team, seriesId, year, lang, versionInfo[lang]?.toString()),
        getSpecTypes(brand, team, seriesId, year, lang, versionInfo[lang]?.toString()),
        getCompareFeatures(brand, team, seriesId, year, lang, versionInfo[lang]?.toString()),
      );
    });
    const responses = await Promise.all(promises);

    // fetch the sort order for the default lang (currently default is only EN)
    if (this.defaultLang && versionInfo[this.defaultLang]) {
      const sortResponses = await Promise.all([
        getSortList(brand, team, seriesId, year, VDSortableEntity.COMPARE_FEATURES_CATEGORIES, versionInfo[this.defaultLang]?.toString()),
        getSortList(brand, team, seriesId, year, VDSortableEntity.COMPARE_FEATURES_SUBCATEGORIES, versionInfo[this.defaultLang]?.toString()),
        getSortList(brand, team, seriesId, year, VDSortableEntity.FEATURES_CATEGORIES, versionInfo[this.defaultLang]?.toString()),
        getSortList(brand, team, seriesId, year, VDSortableEntity.FEATURES_SUBCATEGORIES, versionInfo[this.defaultLang]?.toString()),
        getSortList(brand, team, seriesId, year, VDSortableEntity.SPECS_CATEGORIES, versionInfo[this.defaultLang]?.toString()),
        getSortList(brand, team, seriesId, year, VDSortableEntity.SPECS_SPECTYPE, versionInfo[this.defaultLang]?.toString()),
      ]);
      this.categoriesSortList = sortResponses[0].data;
      this.subCategoriesSortList = sortResponses[1].data;
      this.featureCategoriesSortList = sortResponses[2].data;
      this.featureSubCategoriesSortList = sortResponses[3].data;
      this.specCategoriesSortList = sortResponses[4].data;
      this.specTypesSortList = sortResponses[5].data;
    }

    let index = 0;
    index = 0;
    for (const lang of this.allLangs) {
      this.updateFeatureCategoriesMap(lang, this.featureCategoriesMap, responses[index].data, this.featureCategoriesSortList);
      this.updateFeatureCategoriesMap(lang, this.featureSubCategoriesMap, responses[index + 1].data, this.featureSubCategoriesSortList);
      this.updateSpecCategoriesMap(lang, this.specCategoriesMap, responses[index + 2].data, this.specCategoriesSortList);
      this.updateSpecCategoriesMap(lang, this.specTypesMap, responses[index + 3].data, this.specTypesSortList);
      index += 5;
    }
    this.finishCategoriesSetUp();

    index = 0;
    const compareFeaturesMap: CompareFeaturesMap = {
      compareFeatures: {},
      order: [],
    };
    for (const lang of this.allLangs) {
      this.updateCompareFeaturesLangMap(lang, compareFeaturesMap, responses[index + 4].data.compareFeatures, grades);
      index += 5;
    }
    this.fillOutCompareFeaturesLangMap(compareFeaturesMap, grades);
    this.setCompareFeatureLangMaps(compareFeaturesMap);

    this.updateHighlightSortList(responses[4].data.doubleSortList ?? {});
  };

  updateHighlightSortList = (highlightSortList: HighlightSortList) => {
    this.highlightSortList = highlightSortListTransform(highlightSortList, this.getDefaultCompareFeatures(this.compareFeatureLangMaps), this.grades);
  };

  combineCategoryEntity = (list: IDValueType[], dataType: VDSortableEntity) => {
    const newList = list.slice();
    const duplicates = dataType === VDSortableEntity.COMPARE_FEATURES_CATEGORIES ? this.duplicateSpecCategories : this.duplicateSpecTypes;

    // go through each duplicate spec category and add it to the newList
    duplicates.forEach(specCategory => {
      const listLen = newList.length;
      for (let i = 0; i < listLen; i++) {
        if (newList[i].value === specCategory.value) {
          newList.splice(i + 1, 0, specCategory);
          break;
        }
      }
    });
    return newList;
  };

  updateFeatureCategoriesMap = (lang: Language, categoriesMap: CategoriesMap, data: CategoryResponse[], sortList?: ISortList) => {
    const categories: IDValueType<string>[] =
      sortList && !!Object.keys(sortList.sortList).length ? categoriesXForm(data, sortCategoriesFunction(sortList.sortList)) : categoriesXForm(data);
    this.categoriesHelper(lang, categoriesMap, categories);
  };

  updateSpecCategoriesMap = (lang: Language, categoriesMap: CategoriesMap, data: RefItemResponse<RefItemObject>[], sortList?: ISortList) => {
    const categories: IDValueType<string>[] =
      sortList && !!Object.keys(sortList.sortList).length ? refItemsXForm(data, sortCategoriesFunction(sortList.sortList)) : refItemsXForm(data);
    this.categoriesHelper(lang, categoriesMap, categories);
  };

  categoriesHelper = (lang: Language, categoriesMap: CategoriesMap, categories: IDValueType<string>[]) => {
    categories.forEach(cat => {
      if (!categoriesMap.categories[cat.id]) {
        categoriesMap.categories[cat.id] = {};
      }
      categoriesMap.categories[cat.id][lang] = cat;
      if (!categoriesMap.order.includes(cat.id)) {
        categoriesMap.order.push(cat.id);
      }
    });
  };

  finishCategoriesSetUp = () => {
    this.fillOutCategoriesMap(this.featureCategoriesMap);
    this.fillOutCategoriesMap(this.featureSubCategoriesMap);
    this.fillOutCategoriesMap(this.specCategoriesMap);
    this.fillOutCategoriesMap(this.specTypesMap);
    this.combineCategories();
    this.combineSubCategories();
  };

  /**
   * Ensure that every language has all possibly categories/subcategories.
   * @param langs
   * @param categoriesMap
   */
  fillOutCategoriesMap = (categoriesMap: CategoriesMap) => {
    Object.values(categoriesMap.categories).forEach(cat => {
      const defaultId = cat.EN ? cat.EN.id : Object.values(cat)[0].id;
      this.allLangs.forEach(lang => {
        if (!cat[lang]) {
          cat[lang] = new IDValueType<string>(defaultId, '');
        }
      });
    });
  };

  /**
   * Combine the feature and spec categories
   */
  combineCategories = () => {
    const featureCatsValueMap: { [value: string]: CategoryLangMap } = {};
    const featureCats: IDValueType<CategoryLangMap>[] = this.featureCategoriesMap.order.map(id => {
      const copied = JSON.parse(JSON.stringify(this.featureCategoriesMap.categories[id])) as CategoryLangMap;
      featureCatsValueMap[copied[this.defaultLang].value] = copied;
      return new IDValueType(copied[this.defaultLang].id, copied);
    });
    const specCats: IDValueType<CategoryLangMap>[] = [];

    this.specCategoriesMap.order.forEach(id => {
      const copied = JSON.parse(JSON.stringify(this.specCategoriesMap.categories[id])) as CategoryLangMap;

      const newSpecCat = new IDValueType(copied[this.defaultLang].id, copied);

      if (featureCatsValueMap[copied[this.defaultLang].value]) {
        this.duplicateSpecCategories.push(newSpecCat);
      } else {
        specCats.push(newSpecCat);
      }
    });
    const transformedCategories = [...featureCats, ...specCats].sort(sortCategoriesFunction(this.categoriesSortList.sortList));
    transformedCategories.forEach(cat => {
      this.categoriesMap.order.push(cat.id);
      this.categoriesMap.categories[cat.id] = cat.value;
    });
  };

  combineSubCategories = () => {
    const featureSubCatsValueMap: { [value: string]: CategoryLangMap } = {};
    const featureSubCats: IDValueType<CategoryLangMap>[] = this.featureSubCategoriesMap.order.map(id => {
      const copied = JSON.parse(JSON.stringify(this.featureSubCategoriesMap.categories[id])) as CategoryLangMap;
      featureSubCatsValueMap[copied[this.defaultLang].value] = copied;
      return new IDValueType(copied[this.defaultLang].id, copied);
    });
    const specTypes: IDValueType<CategoryLangMap>[] = [];

    this.specTypesMap.order.forEach(id => {
      const copied = JSON.parse(JSON.stringify(this.specTypesMap.categories[id])) as CategoryLangMap;

      const newSpecType = new IDValueType(copied[this.defaultLang].id, copied);

      if (featureSubCatsValueMap[copied[this.defaultLang].value]) {
        this.duplicateSpecTypes.push(newSpecType);
      } else {
        specTypes.push(newSpecType);
      }
    });
    const transformedSubCategories = [...featureSubCats, ...specTypes].sort(sortCategoriesFunction(this.subCategoriesSortList.sortList));
    transformedSubCategories.forEach(cat => {
      this.subCategoriesMap.order.push(cat.id);
      this.subCategoriesMap.categories[cat.id] = cat.value;
    });
  };

  getDefaultCategories = (categoriesMap: CategoriesMap) => {
    return this.getCategoriesForLang(this.defaultLang, categoriesMap);
  };

  getCategoriesForLang = (lang: string, categoriesMap: CategoriesMap) => {
    const cats: IDValueType<string>[] = [];
    categoriesMap.order.forEach(catId => {
      if (categoriesMap.categories[catId][lang]) {
        cats.push(categoriesMap.categories[catId][lang]);
      }
    });
    return cats;
  };

  getFeatureCategoriesForLang = (lang: string, categoriesMap: CategoriesMap) => {
    const cats: IDValueType<string>[] = [];
    categoriesMap.order.forEach(catId => {
      if (categoriesMap.categories[catId][lang]) {
        cats.push(categoriesMap.categories[catId][lang]);
      }
    });
    return cats;
  };

  updateCompareFeaturesLangMap = (lang: string, compareFeaturesMap: CompareFeaturesMap, data: CompareFeatureResponse[], grades: RefItem[]) => {
    const featureCats: IDValueType<string>[] = this.getCategoriesForLang(lang, this.featureCategoriesMap);
    const featureSubCats: IDValueType<string>[] = this.getCategoriesForLang(lang, this.featureSubCategoriesMap);
    const specCats: IDValueType<string>[] = this.getCategoriesForLang(lang, this.specCategoriesMap);
    const specTypes: IDValueType<string>[] = this.getCategoriesForLang(lang, this.specTypesMap);

    data.forEach(item => {
      const index = compareFeaturesMap.order.findIndex(id => id === item.id);
      const compareFeature = compareFeatureItemXForm(item, grades, featureCats, featureSubCats, specCats, specTypes, index >= 0 ? index : compareFeaturesMap.order.length);
      if (!compareFeaturesMap.compareFeatures[compareFeature.id]) {
        compareFeaturesMap.compareFeatures[compareFeature.id] = {
          data: item,
          langs: {},
        };
      }
      compareFeaturesMap.compareFeatures[compareFeature.id].langs[lang] = compareFeature;
      if (!compareFeaturesMap.order.includes(compareFeature.id)) {
        compareFeaturesMap.order.push(compareFeature.id);
      }
    });
  };

  fillOutCompareFeaturesLangMap = (compareFeaturesMap: CompareFeaturesMap, grades: RefItem[]) => {
    Object.keys(compareFeaturesMap.compareFeatures).forEach(compareFeatureId => {
      const compareFeatureLangMap = compareFeaturesMap.compareFeatures[compareFeatureId];
      const compareFeatureData: CompareFeatureResponse = compareFeatureLangMap.data ?? ({} as CompareFeatureResponse);
      const index = compareFeaturesMap.order.findIndex(id => id === compareFeatureId);

      this.allLangs.forEach(lang => {
        if (!compareFeatureLangMap.langs[lang]) {
          compareFeatureLangMap.langs[lang] = compareFeatureItemXForm(
            compareFeatureData,
            grades,
            this.getCategoriesForLang(lang, this.featureCategoriesMap),
            this.getCategoriesForLang(lang, this.featureSubCategoriesMap),
            this.getCategoriesForLang(lang, this.specCategoriesMap),
            this.getCategoriesForLang(lang, this.specTypesMap),
            index,
          );
          compareFeatureLangMap.langs[lang].revId = '';
          compareFeatureLangMap.langs[lang].description = '';
        }
      });
    });
  };

  setCompareFeatureLangMaps = (compareFeaturesMap: CompareFeaturesMap) => {
    const featureLangMaps: CompareFeatureLangMap[] = [];
    compareFeaturesMap.order.forEach(id => {
      const feature = compareFeaturesMap.compareFeatures[id];
      featureLangMaps.push(feature);
    });
    this.compareFeatureLangMaps = featureLangMaps;
    this.filteredCompareFeatureLangMaps = featureLangMaps;
  };

  getDefaultCompareFeatures = (compareFeatureLangMaps: CompareFeatureLangMap[]) => {
    return compareFeatureLangMaps.map(langMap => langMap.langs[this.defaultLang]);
  };

  getCompareFeaturesForLang = (lang: string) => {
    const features: CompareFeatureItem[] = [];
    this.compareFeatureLangMaps.forEach(langMap => {
      if (langMap.langs[lang]) {
        features.push(langMap.langs[lang]);
      }
    });
    return features;
  };

  getCompareFeaturesMap = () => {
    const featureMap: CompareFeaturesMap = { compareFeatures: {}, order: [] };
    this.compareFeatureLangMaps.forEach(langMap => {
      const id = langMap.langs[Object.keys(langMap.langs)[0]].id;
      featureMap.compareFeatures[id] = langMap;
      featureMap.order.push(id);
    });
    return featureMap;
  };

  addItem = (type: CompareType) => {
    const id = uuidv4();
    const newLangMap: CompareFeatureLangMap = { langs: {} };
    this.allLangs.forEach(lang => {
      const newItem = new CompareFeatureItem();
      newItem.id = id;
      newItem.compareType = type;
      this.grades.forEach(grade => {
        if (!newItem.gradeApplicability[grade.id]) {
          newItem.gradeApplicability[grade.id] = {
            highlighted: false,
            text: '',
            applicability: FeatureSettings.UNDEFINED,
          };
        }
      });
      newLangMap.langs[lang] = newItem;
    });

    this.compareFeatureLangMaps = [newLangMap, ...this.compareFeatureLangMaps];
    this.filteredCompareFeatureLangMaps = [newLangMap, ...this.filteredCompareFeatureLangMaps];
  };

  copyMap = (compareFeatureLangMap: CompareFeatureLangMap) => {
    const featureUid = compareFeatureLangMap.langs[this.defaultLang].uid;
    const unfilteredIndex = this.compareFeatureLangMaps.findIndex(item => item.langs[this.defaultLang].uid === featureUid) + 1;
    const filteredIndex = this.filteredCompareFeatureLangMaps.findIndex(item => item.langs[this.defaultLang].uid === featureUid) + 1;

    const newLangMap: CompareFeatureLangMap = { langs: {} };
    for (let lang of this.allLangs) {
      const compareFeature = compareFeatureLangMap.langs[lang];
      const compareFeatureCopy = new CompareFeatureItem();
      const { id, revId, uid, isValid, getPayload, ...rest } = compareFeature;
      Object.assign(compareFeatureCopy, rest);
      newLangMap.langs[lang] = compareFeatureCopy;
    }

    const langMaps = this.compareFeatureLangMaps.slice();
    langMaps.splice(unfilteredIndex, 0, newLangMap);
    this.compareFeatureLangMaps = langMaps;

    const filteredLangMaps = this.filteredCompareFeatureLangMaps.slice();
    filteredLangMaps.splice(filteredIndex, 0, newLangMap);
    this.filteredCompareFeatureLangMaps = filteredLangMaps;

    return newLangMap;
  };

  deleteItem = (uid: string) => {
    this.compareFeatureLangMaps = this.compareFeatureLangMaps.filter(item => item.langs[this.defaultLang].uid !== uid);
    this.filteredCompareFeatureLangMaps = this.filteredCompareFeatureLangMaps.filter(item => item.langs[this.defaultLang].uid !== uid);
  };

  onSort = (field: string, lang?: string) => {
    this.reverseSort = isSortReverse(this.sortField, field, this.reverseSort);
    this.sortField = field;
    let compareFeatures = lang ? this.getCompareFeaturesForLang(lang) : this.getDefaultCompareFeatures(this.compareFeatureLangMaps);
    const compareFeaturesMap = this.getCompareFeaturesMap();
    compareFeaturesMap.order = [];
    compareFeatures = compareFeatures.sort(sortBy(this.sortField, this.reverseSort));
    compareFeatures.forEach(feature => {
      compareFeaturesMap.order.push(feature.id);
    });
    this.setCompareFeatureLangMaps(compareFeaturesMap);
  };

  onFilter = (filterAction: () => void) => {
    filterAction();
    this.filteredCompareFeatureLangMaps = this.filterLangMaps();
  };

  resetFilters = () => {
    this.searchText = '';
    this.categoryFilters = [];
    this.subCategoryFilters = [];
    this.isInProgressFilter = false;
    this.isSyncUpdateFilter = false;
    this.isReviewNotesFilter = false;
    this.filteredCompareFeatureLangMaps = this.compareFeatureLangMaps.slice();
  };

  filterLangMaps = () => {
    const sorted = this.compareFeatureLangMaps.slice();
    const lowerSearchText = toLowerCase(this.searchText);
    return sorted.filter(compareFeatureLangMap => {
      let checked = false;
      let hasChangedAttributes = false;

      for (let lang of this.allLangs) {
        const compareFeature = compareFeatureLangMap.langs[lang];

        if (!checked) {
          checked = true;
          if (this.categoryFilters.length && !this.categoryFilters.includes(compareFeature.category.value)) {
            return false;
          }
          if (this.subCategoryFilters.length && !this.subCategoryFilters.includes(compareFeature.subCategory.value)) {
            return false;
          }
          if (this.isInProgressFilter && !compareFeature.isInProgress) {
            return false;
          }
          if (this.isReviewNotesFilter && compareFeature.rejectNotes.length === 0) {
            return false;
          }
        }

        if (compareFeature.changedAttributes.length) {
          hasChangedAttributes = true;
        }

        if (lowerSearchText) {
          const valuesToCheck: string[] = [
            compareFeature.category.value,
            compareFeature.subCategory.value,
            convertToRichTextObject(compareFeature.description).text,
            compareFeature.notes,
          ];
          for (let val of valuesToCheck) {
            if (toLowerCase(val).includes(lowerSearchText)) {
              return true;
            }
          }
        }
      }

      if (this.isSyncUpdateFilter && !hasChangedAttributes) {
        return false;
      }

      return !lowerSearchText;
    });
  };

  removeDuplicates = (arr: IDValueType[]) => {
    let set = new Set(arr);
    let value = set.values();
    return Array.from(value);
  };

  filtedSortFeatures = (type: string) => {
    if (type === 'category') {
      return this.removeDuplicates(this.filteredCompareFeatureLangMaps.map(langMap => langMap.langs[this.defaultLang].category));
    } else if (type === 'subCategory') {
      return this.removeDuplicates(this.filteredCompareFeatureLangMaps.map(langMap => langMap.langs[this.defaultLang].subCategory));
    } else {
      return [];
    }
  };

  updateSelectedLangs = (lang: string, isSelected: boolean) => {
    const selectedMap = JSON.parse(JSON.stringify(this.selectedLangsMap));
    if (selectedMap[lang] != null) {
      selectedMap[lang] = isSelected;
    }
    this.selectedLangsMap = selectedMap;
  };

  constructor() {
    makeObservable(this, {
      selectedLangsMap: observable,
      filteredCompareFeatures: observable,
      categoriesMap: observable,
      featureCategoriesMap: observable,
      featureSubCategoriesMap: observable,
      subCategoriesMap: observable,
      specCategoriesMap: observable,
      specTypesMap: observable,
      highlightSortList: observable,
      filteredCompareFeatureLangMaps: observable,
      rowHeightMap: observable,
      fetchData: action,
      updateHighlightSortList: action,
      resetFilters: action,
      reset: action,
    });
  }

  hasChangedAttributes() {
    return !!this.compareFeatureLangMaps.filter(langMap => !!this.editableLangs.filter(lang => !!langMap.langs[lang].changedAttributes.length).length).length;
  }

  reset() {
    this.reverseSort = false;
    this.sortField = 'id';
    this.searchText = '';
    this.isInProgressFilter = false;
    this.isSyncUpdateFilter = false;
    this.isReviewNotesFilter = false;
    this.categoryFilters = [];
    this.subCategoryFilters = [];

    this.compareFeatureLangMaps = [];
    this.filteredCompareFeatureLangMaps = [];
    this.categoriesMap = { categories: {}, order: [] };
    this.subCategoriesMap = { categories: {}, order: [] };
    this.featureCategoriesMap = { categories: {}, order: [] };
    this.featureSubCategoriesMap = { categories: {}, order: [] };
    this.specCategoriesMap = { categories: {}, order: [] };
    this.specTypesMap = { categories: {}, order: [] };
    this.rowHeightMap = {};
  }

  setRowHeight = (langMap: CompareFeatureLangMap, rowHeight: number) => {
    const key = langMap.langs[this.defaultLang].id;
    this.rowHeightMap[key] = rowHeight;
  };

  getRowHeight(langMap: CompareFeatureLangMap) {
    const key = langMap.langs[this.defaultLang].id;
    return this.rowHeightMap[key];
  }
}

export default CompareFeaturesStore;
