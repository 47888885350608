import React from 'react';

export interface ChecklistItem {
  name: string;
  selected?: boolean;
  items?: ChecklistItem[];
  hide?: boolean;
  [key: string]: any;
}

const hasSelected = (items: ChecklistItem[]): boolean => {
  return items.some(item => {
    return item.selected || (item.items && hasSelected(item.items));
  });
};

const useChecklist = () => {
  const [checklist, setChecklist] = React.useState([] as ChecklistItem[]);
  const [isAllSelected, setIsAllSelected] = React.useState(false);
  const [hasSelection, setHasSelection] = React.useState(false);

  const selectAll = (selected: boolean) => {
    selectChildren(checklist, selected);
    setChecklist([...checklist]);
  };

  const selectItem = (item: ChecklistItem, selected: boolean) => {
    item.selected = selected;
    if (item.items) {
      selectChildren(item.items, selected);
    }
    processSelection(checklist);
    setChecklist([...checklist]);
  };

  const selectChildren = (items: ChecklistItem[], selected: boolean) => {
    items.forEach(item => {
      item.selected = selected;
      if (item.items) {
        selectChildren(item.items, selected);
      }
    });
  };

  const processSelection = (items: ChecklistItem[]) => {
    items.forEach(item => {
      if (item.items) {
        item.selected = item.items.filter(item => !item.selected).length === 0;
        processSelection(item.items);
      }
    });
  };

  React.useEffect(() => {
    setIsAllSelected(checklist.filter(item => !item.selected).length === 0);
    setHasSelection(hasSelected(checklist));
  }, [setIsAllSelected, checklist]);

  return {
    checklist,
    setChecklist,
    selectItem,
    selectAll,
    isAllSelected,
    hasSelection,
  };
};

export default useChecklist;
