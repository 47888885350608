import { observer } from 'mobx-react-lite';
import React from 'react';
import IconTextButton from '../../../../../components/IconTextButton';
import { HeaderCell, HeaderRow, Thead } from '../../../../../components/Table';
import { Brand, BRAND_LEXUS } from '../../../../../models/user.model';

type SeriesSettingsHeaderRowProps = {
  brand: Brand;
  toggleAddSeries: (add: boolean) => void;
  readOnly?: boolean;
  canAddNewSeriesSettings: boolean;
};

const SeriesSettingsHeaderRow: React.FC<SeriesSettingsHeaderRowProps> = ({
  brand,
  toggleAddSeries,
  readOnly,
  canAddNewSeriesSettings,
}) => {
  return (
    <Thead>
      <HeaderRow>
        <HeaderCell>Code Red</HeaderCell>
        <HeaderCell>Series Name</HeaderCell>
        <HeaderCell>Fuel Type</HeaderCell>
        <HeaderCell>Series Category</HeaderCell>
        {brand === BRAND_LEXUS && <HeaderCell>Starting Msrp</HeaderCell>}
        <HeaderCell>Seating</HeaderCell>
        <HeaderCell>Estimated Mileage</HeaderCell>
        {brand === BRAND_LEXUS && <HeaderCell>Horse Power</HeaderCell>}
        <HeaderCell>MPGe</HeaderCell>
        <HeaderCell align="right">
          {!readOnly && canAddNewSeriesSettings && (
            <IconTextButton
              icon="plus"
              text="Add Series"
              smallIcon
              onClick={() => {
                toggleAddSeries(true);
              }}
            />
          )}
        </HeaderCell>
      </HeaderRow>
    </Thead>
  );
};

export default observer(SeriesSettingsHeaderRow);
