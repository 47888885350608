import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import IconTextButton from '../../../../../../components/IconTextButton';
import { Table } from '../../../../../../components/Table';
import { Brand } from '../../../../../../models/user.model';
import { VehicleModelItem, VehicleModelToyota } from '../../../../../../models/vehicleModel.model';
import { getSortModels } from '../../../../../../utils/vehicleDataUtils';
import styles from '../../modelsModal.module.scss';
import { ModelsModalVMProps } from '../../ModelsProps';
import ModelsModalEditPanel from './ModelsModalEditPanel';
import ModelsModalRow from './ModelsModalRow';

const ModelsModalTable = ({
  brand,
  seriesGroup,
  seriesName,
  vehicleModels,
  grades,
  fuelTypes,
  onAddModel,
  onUpdateModel,
  onDeleteModel,
  onSortModels = () => void 0,
  onAddGrade,
  onUpdateGrade,
  onShow,
  canAddDelete,
  canEditGoLiveDate,
  readOnly,
  seriesSettings,
  canAddFromDropdown,
  compareModel,
  editableLanguages,
}: ModelsModalVMProps) => {
  const [showAddModel, setShowAddModel] = useState(false);

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return undefined;
    }

    const [removed] = vehicleModels.splice(result.source.index, 1);
    vehicleModels.splice(result.destination.index, 0, removed);
    vehicleModels.forEach((item, index) => (item.sortOrder = index));
    onSortModels(getSortModels(vehicleModels));

    return vehicleModels;
  };

  return (
    <section>
      <DragDropContext onDragEnd={onDragEnd}>
        <Table fullWidth className={styles.table}>
          <tbody className={styles.tbodyActionBar}>
            <tr>
              <td>Code Red</td>
              <td colSpan={3}></td>
              <td>Go Live Date</td>
              <td>Katashiki</td>
              <td className={styles.addModelColumn}>
                {!readOnly && canAddDelete && <IconTextButton icon="plus" text="Add Model" smallIcon onClick={() => setShowAddModel(true)} />}
              </td>
            </tr>
          </tbody>

          <Droppable droppableId="modelsmodaldroppable">
            {provided => (
              <tbody ref={provided.innerRef} className={styles.tbodyMain} {...provided.droppableProps}>
                {showAddModel && (
                  <ModelsModalEditPanel
                    brand={brand}
                    seriesName={seriesName}
                    model={new VehicleModelItem<VehicleModelToyota>(brand as Brand)}
                    grades={grades}
                    fuelTypes={fuelTypes}
                    category={seriesGroup}
                    onClose={() => setShowAddModel(false)}
                    onSave={onAddModel}
                    onAddGrade={!canAddFromDropdown ? undefined : onAddGrade}
                    onUpdateGrade={onUpdateGrade}
                    seriesSettings={seriesSettings}
                    compareModel={compareModel}
                  />
                )}

                {vehicleModels.map((model, index) => (
                  <ModelsModalRow
                    key={model.uid}
                    brand={brand}
                    index={index}
                    readOnly={readOnly}
                    category={seriesGroup}
                    model={model}
                    grades={grades}
                    fuelTypes={fuelTypes}
                    onShow={onShow}
                    onSave={onUpdateModel}
                    onCopy={onAddModel}
                    onDelete={onDeleteModel}
                    onAddGrade={!canAddFromDropdown ? undefined : onAddGrade}
                    onUpdateGrade={onUpdateGrade}
                    canAddDelete={canAddDelete}
                    seriesName={seriesName}
                    seriesSettings={seriesSettings}
                    canEditGoLiveDate={canEditGoLiveDate}
                    compareModel={compareModel}
                    editableLanguages={editableLanguages}
                  />
                ))}
                {provided.placeholder}
              </tbody>
            )}
          </Droppable>
        </Table>
      </DragDropContext>
    </section>
  );
};

export default observer(ModelsModalTable);
