import React, { useCallback, useContext } from 'react';
import { Button, ModalBody, ModalFooter } from 'vapi-ui-common';
import { entityTypeToNameMap } from './SyncTMNAChangesModal';
import { SyncTMNAChangesModalBodyContext } from './SyncTMNAChangesModalBody';

const SyncTMNAChangesModalBodyDelete = () => {
  const { entityType, close } = useContext(SyncTMNAChangesModalBodyContext);

  const keepAndUnlinkItem = useCallback(() => close(undefined, false, true), [close]);

  const deleteItem = useCallback(() => close(undefined, true, false), [close]);

  return (
    <>
      <ModalBody>
        <div>{`Would you like to delete this ${entityTypeToNameMap[entityType] ?? entityType}?`}</div>
      </ModalBody>
      <ModalFooter>
        <Button variant="transparent" onClick={keepAndUnlinkItem}>
          Keep
        </Button>
        <Button variant="primary" onClick={deleteItem}>
          Delete
        </Button>
      </ModalFooter>
    </>
  );
};

export default SyncTMNAChangesModalBodyDelete;
