import { AxiosResponse } from 'axios';
import { action, makeObservable, observable } from 'mobx';
import { IDValueType } from '../../models/common.model';
import { FuelTypeResponse } from '../../models/fuelType.model';
import { RefItem, RefItemResponse } from '../../models/refItem.model';
import { Brand, Language, LanguagePermissions } from '../../models/user.model';
import { SortModel, VehicleDataVersionInfo, VehicleTeam } from '../../models/vehicleData.model';
import { VehicleModel, VehicleModelItem, VehicleModelLocalStorageItemType, VehicleModelLocalStorageType, VehicleModelToyota } from '../../models/vehicleModel.model';
import { transformResponseModels } from '../../utils/modelsUtils';
import { getFuelTypes } from '../../webservices/adminApi';
import { getGrades, getModels } from '../../webservices/vehicleModelsApi';

type AxiosDataLangResponse = {
  vehicleModelsResponse: {
    [language in Language]?: AxiosResponse<VehicleModel<VehicleModelToyota>[]>;
  };
  gradesResponse: {
    [language in Language]?: AxiosResponse<RefItemResponse[]>;
  };
};
type VehicleModelLangMap = {
  [language in Language]: VehicleModelItem<VehicleModelToyota>;
};

export type VehicleModelsLangMap = {
  [key: string]: VehicleModelLangMap;
};

export type DataLang = {
  vehicleModels: VehicleModelsLangMap;
  grades: {
    [language in Language]?: RefItem[];
  };
  fuelTypes: {
    [language in Language]?: IDValueType[];
  };
  sortedGrades: {
    [language in Language]?: RefItem[];
  };
};

const getDefaultDataLang = (): DataLang => ({
  vehicleModels: {},
  grades: { EN: [], ES: [] },
  fuelTypes: { EN: [], ES: [] },
  sortedGrades: { EN: [], ES: [] },
});

class VehicleModelsStore {
  _localStorageKey = 'vehicleModelsLocalStore';
  isReviewNotesFilter: boolean = false;
  isSyncUpdateFilter = false;
  defaultLanguage: Language = Language.EN;
  dataLang: DataLang = getDefaultDataLang();
  userLanguages: Language[] = [];
  languagePermissions: LanguagePermissions = {};
  vehicleModels: VehicleModelItem<VehicleModelToyota>[] = [];
  grades: RefItem[] = [];
  sortedGrades: RefItem[] = [];
  fuelTypes: IDValueType[] = [];
  filteredVehicleModels: VehicleModelItem<VehicleModelToyota>[] = [];

  fetchDataLang = async ({
    brand,
    team,
    series,
    year,
    versionInfo,
    language,
  }: {
    brand: string;
    team: VehicleTeam;
    series: string;
    year: string;
    versionInfo: VehicleDataVersionInfo;
    language: Language;
  }) => {
    const responses = await Promise.all([
      getModels({
        brand,
        team,
        series,
        year,
        version: versionInfo[language]?.toString(),
        language,
      }),
      getGrades({
        brand,
        team,
        series,
        year,
        version: versionInfo[language]?.toString(),
        language,
      }),
    ]);

    return {
      vehicleModelsResponse: { [language]: responses[0] },
      gradesResponse: { [language]: responses[1] },
    };
  };

  setLanguagePermissions = (languagePermissions: LanguagePermissions, defaultLanguage: Language) => {
    this.languagePermissions = languagePermissions;
    this.defaultLanguage = defaultLanguage;

    const languages = Object.keys(languagePermissions ?? {});
    this.userLanguages = languages.reduce((acc: Language[], lang: string) => {
      const language = lang as Language;

      if (!languagePermissions[language]?.canView) {
        return acc;
      }

      return [...acc, language];
    }, []);
  };

  getVehicleModelsAsArray = () => {
    return Object.values(this.dataLang.vehicleModels).map(vehicleModelLangMap => vehicleModelLangMap[this.defaultLanguage]);
  };

  setDataLang = (brand: Brand, fuelTypesResponse: FuelTypeResponse, team: VehicleTeam, { vehicleModelsResponse, gradesResponse }: AxiosDataLangResponse) => {
    this.dataLang = this.userLanguages.reduce((acc, language) => {
      const { grades, vehicleModels, fuelTypes, sortedGrades } = transformResponseModels(
        brand,
        vehicleModelsResponse[language]?.data,
        gradesResponse[language]?.data,
        fuelTypesResponse,
        this.getLocalStorage(team),
      );

      vehicleModels.forEach(vehicleModel => {
        acc.vehicleModels[vehicleModel.id] = {
          ...acc.vehicleModels[vehicleModel.id],
          [language]: vehicleModel,
        };
      });

      return {
        ...acc,
        grades: {
          ...acc.grades,
          [language]: grades,
        },
        fuelTypes: {
          ...acc.fuelTypes,
          [language]: fuelTypes,
        },
        sortedGrades: {
          ...acc.sortedGrades,
          [language]: sortedGrades,
        },
      };
    }, getDefaultDataLang());
  };

  fetchData = async ({
    brand,
    team,
    series,
    year,
    versionInfo,
    languagePermissions,
    defaultLanguage,
  }: {
    brand: string;
    team: VehicleTeam;
    series: string;
    year: string;
    versionInfo: VehicleDataVersionInfo;
    languagePermissions: LanguagePermissions;
    defaultLanguage: Language;
  }) => {
    this.reset();
    this.setLanguagePermissions(languagePermissions, defaultLanguage);
    const vehicleModelsAndGrades = this.userLanguages.map((language: Language) =>
      this.fetchDataLang({
        brand,
        team,
        series,
        year,
        versionInfo,
        language: language as Language,
      }),
    );

    const responses = await Promise.all(vehicleModelsAndGrades);
    const fuelTypesResponse = await getFuelTypes(brand);
    const axiosDataLangResponse: AxiosDataLangResponse = responses.reduce(
      (acc, axiosResponse) => ({
        vehicleModelsResponse: {
          ...acc.vehicleModelsResponse,
          ...axiosResponse.vehicleModelsResponse,
        },
        gradesResponse: {
          ...acc.gradesResponse,
          ...axiosResponse.gradesResponse,
        },
      }),
      { vehicleModelsResponse: {}, gradesResponse: {} },
    );

    this.setDataLang(brand as Brand, fuelTypesResponse.data, team, axiosDataLangResponse);

    const { grades, fuelTypes, sortedGrades } = this.dataLang;
    this.grades = grades[this.defaultLanguage] ?? [];
    this.vehicleModels = this.getVehicleModelsAsArray();
    this.fuelTypes = fuelTypes[this.defaultLanguage] ?? [];
    this.sortedGrades = sortedGrades[this.defaultLanguage] ?? [];
    this.isReviewNotesFilter = false;
    this.isSyncUpdateFilter = false;
    this.filteredVehicleModels = this.filterVehicleModels();
  };

  onFilter = (filterAction: () => void) => {
    filterAction();
    this.filteredVehicleModels = this.filterVehicleModels();
  };

  filterVehicleModels = () => {
    let vehicleModels = Object.values(this.dataLang.vehicleModels);

    vehicleModels = vehicleModels.filter((vehicleModelLangMap: VehicleModelLangMap) => {
      let hasChangedAttributes = false;
      let hasRejectNotes = false;

      for (let language of this.userLanguages) {
        const vehicleModel = vehicleModelLangMap[language];

        if (vehicleModel.getVal('rejectNotes')?.length) {
          hasRejectNotes = true;
        }

        if (vehicleModel.getVal('changedAttributes')?.length) {
          hasChangedAttributes = true;
        }
      }

      if (this.isReviewNotesFilter && !hasRejectNotes) {
        return false;
      }

      if (this.isSyncUpdateFilter && !hasChangedAttributes) {
        return false;
      }

      return true;
    });

    return vehicleModels.map(vehicleModelLangMap => vehicleModelLangMap[this.defaultLanguage]);
  };

  setModels = (vModels: VehicleModelItem<VehicleModelToyota> | VehicleModelItem<VehicleModelToyota>[]) => {
    let models = this.dataLang.vehicleModels;

    if (!Array.isArray(vModels)) {
      models[vModels.id] = {
        ...models[vModels.id],
        [this.defaultLanguage]: vModels,
      };
    } else {
      models = vModels.reduce(
        (acc, vModel) => ({
          ...acc,
          [vModel.id]: {
            ...models[vModel.id],
            [this.defaultLanguage]: vModel,
          },
        }),
        {},
      );
    }

    this.dataLang = {
      ...this.dataLang,
      vehicleModels: models,
    };

    this.vehicleModels = this.getVehicleModelsAsArray();
    this.filteredVehicleModels = this.filterVehicleModels();
  };

  setModelForLang = (vModel: VehicleModelItem<VehicleModelToyota>, language: Language) => {
    let models = this.dataLang.vehicleModels;

    models[vModel.id] = {
      ...models[vModel.id],
      [language]: vModel,
    };

    this.dataLang = {
      ...this.dataLang,
      vehicleModels: models,
    };

    this.vehicleModels = this.getVehicleModelsAsArray();
    this.filteredVehicleModels = this.filterVehicleModels();
  };

  reset = () => {
    this.isReviewNotesFilter = false;
    this.isSyncUpdateFilter = false;
    this.vehicleModels = [];
    this.grades = [];
    this.fuelTypes = [];
    this.userLanguages = [];
    this.languagePermissions = {};
    this.dataLang = getDefaultDataLang();
  };

  getLocalStorage = (team: string): VehicleModelLocalStorageItemType => {
    const lsItem = localStorage.getItem(this._localStorageKey);
    return (lsItem ? JSON.parse(lsItem)[team] : {}) as VehicleModelLocalStorageItemType;
  };

  setLocalStorage = (team: string) => {
    const localStoreVal = {} as VehicleModelLocalStorageItemType;
    this.getVehicleModelsAsArray().forEach(item => (localStoreVal[item.id] = { show: item.show }));
    const storeItem: VehicleModelLocalStorageType = { [team]: localStoreVal };

    localStorage.setItem(this._localStorageKey, JSON.stringify(storeItem));
  };

  getGradeById = (id: string) => {
    return this.dataLang.grades[this.defaultLanguage]?.find(x => x.id === id || x.value === id)?.value || 'Missing Grade';
  };

  getSortPayload = (): SortModel[] => {
    return (
      this.getVehicleModelsAsArray().map(({ id, revId, sortOrder }) => ({
        id,
        revId,
        sortOrder,
      })) ?? []
    );
  };

  constructor() {
    makeObservable(this, {
      defaultLanguage: observable,
      dataLang: observable,
      userLanguages: observable,
      languagePermissions: observable,
      vehicleModels: observable,
      grades: observable,
      sortedGrades: observable,
      fuelTypes: observable,
      filteredVehicleModels: observable,
      fetchData: action,
      reset: action,
    });
  }
}

export default VehicleModelsStore;
