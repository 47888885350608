import { action, makeObservable, observable } from "mobx";
import { v4 as uuidv4 } from "uuid";
import { FuelTypeItemResponse } from "../../models/fuelType.model";
import {
  SeriesCategoryItem,
  SeriesSettingsItem,
  SeriesSettingsLangMap,
  SeriesSettingsMap,
  SeriesSettingsResponse,
} from "../../models/seriesSettings.model";
import { Brand, Language } from "../../models/user.model";
import {
  VehicleDataVersionInfo,
  VehicleTeam,
} from "../../models/vehicleData.model";
import { sortBy } from "../../utils";
import { seriesSettingsXForm } from "../../utils/seriesSettingsUtils";
import { getFuelTypes, getSeriesCategories } from "../../webservices/adminApi";
import { getSeriesSettings } from "../../webservices/vehicleModelsApi";

class SeriesSettingsStore {
  reverseSort = false;
  sortField = "sortOrder";
  // langs
  allLangs: Language[] = [];
  defaultLang: Language = Language.EN;

  seriesSettingsLangMaps: SeriesSettingsLangMap[] = [];
  deletedSeriesSettingsLangMaps: SeriesSettingsLangMap[] = [];
  fuelTypesList: FuelTypeItemResponse[] = [];
  seriesCategoriesList: SeriesCategoryItem[] = [];
  codeRedFuelTypes: { [index: string]: boolean } = {};

  fetchData = async (
    brand: Brand,
    team: VehicleTeam,
    seriesId: string,
    year: string,
    defaultLanguage: Language,
    allLanguages: Language[],
    versionInfo: VehicleDataVersionInfo
  ) => {
    this.allLangs = allLanguages;
    this.defaultLang = defaultLanguage;

    const promises: Promise<any>[] = [
      getFuelTypes(brand),
      getSeriesCategories(brand, true),
    ];
    this.allLangs.forEach((lang) => {
      promises.push(
        getSeriesSettings(
          brand,
          team,
          seriesId,
          year,
          lang,
          versionInfo[lang]?.toString(),
          true
        )
      );
    });
    const responses = await Promise.all(promises);

    const seriesSettingsMap: SeriesSettingsMap = { series: {}, order: [] };
    const deletedSeriesSettingsMap: SeriesSettingsMap = {
      series: {},
      order: [],
    };
    let index = 2;
    for (const lang of this.allLangs) {
      const deletedSeries = (
        responses[index].data as SeriesSettingsResponse[]
      ).filter((val) => {
        return val.isDeleted;
      });
      responses[index].data = (
        responses[index].data as SeriesSettingsResponse[]
      ).filter((val) => {
        return !val.isDeleted;
      });

      if (index === 2) {
        this.codeRedFuelTypes = {};
        (responses[index].data as SeriesSettingsResponse[]).forEach((x) => {
          if (!x.isPublishable) {
            Object.keys(x.fuelTypes).forEach((fuelType: string) => {
              this.codeRedFuelTypes[fuelType] = true;
            });
          }
          // sub-series
          if (x.subSeries) {
            Object.values(x.subSeries).forEach((subSeries) => {
              if (!subSeries.isDeleted && !subSeries.isPublishable) {
                Object.keys(subSeries.fuelTypes).forEach((fuelType: string) => {
                  this.codeRedFuelTypes[fuelType] = true;
                });
              }
            });
          }
        });
      }
      const { sortedSeriesSettings } = seriesSettingsXForm(
        responses[index],
        brand,
        true
      );
      responses[index].data = deletedSeries;
      const { sortedSeriesSettings: deletedSettings } = seriesSettingsXForm(
        responses[index],
        brand
      );
      sortedSeriesSettings.forEach((settings) => {
        if (!seriesSettingsMap.series[settings.id]) {
          seriesSettingsMap.order.push(settings.id);
          seriesSettingsMap.series[settings.id] = {};
        }
        seriesSettingsMap.series[settings.id][lang] = settings;
      });
      deletedSettings.forEach((settings) => {
        if (!deletedSeriesSettingsMap.series[settings.id]) {
          deletedSeriesSettingsMap.order.push(settings.id);
          deletedSeriesSettingsMap.series[settings.id] = {};
        }
        deletedSeriesSettingsMap.series[settings.id][lang] = settings;
      });
      index += 1;
    }

    this.seriesSettingsLangMaps = seriesSettingsMap.order.map((id) => {
      return seriesSettingsMap.series[id];
    });
    this.deletedSeriesSettingsLangMaps = deletedSeriesSettingsMap.order.map(
      (id) => {
        return deletedSeriesSettingsMap.series[id];
      }
    );
    this.fuelTypesList = responses[0].data.fuelTypes;
    this.seriesCategoriesList = responses[1].data;
  };

  addEmptySubSeries = (
    brand: Brand,
    parentSettingsMap: SeriesSettingsLangMap
  ) => {
    const parentId = parentSettingsMap[this.defaultLang].id;
    const parentIndex = this.getDefaultSeriesSettings(
      this.seriesSettingsLangMaps
    ).indexOf(parentSettingsMap[this.defaultLang]);
    const newSubSeriesMap: SeriesSettingsLangMap = {};
    const id = uuidv4();
    for (const lang of Object.keys(parentSettingsMap)) {
      const parentRevId = parentSettingsMap[lang].revId;
      parentSettingsMap[lang].sortOrder = parentIndex;
      const subSeries = new SeriesSettingsItem(brand);
      subSeries.id = id;
      subSeries.isNewSubSeries = true;
      subSeries.isSubSeries = true;
      subSeries.parentId = parentId;
      subSeries.parentRevId = parentRevId;
      subSeries.sortOrder = parentIndex + 1;
      newSubSeriesMap[lang] = subSeries;
    }
    this.seriesSettingsLangMaps = [
      newSubSeriesMap,
      ...this.seriesSettingsLangMaps,
    ];
    const seriesSettingsMap = this.getSeriesSettingsMap(
      this.seriesSettingsLangMaps
    );

    const defaultSorted = this.getDefaultSeriesSettings(
      this.seriesSettingsLangMaps
    )
      .slice()
      .sort(sortBy(this.sortField, this.reverseSort))
      .map((settings) => seriesSettingsMap.series[settings.id]);
    this.seriesSettingsLangMaps = defaultSorted;
  };

  removeSetting = (setting: SeriesSettingsLangMap) => {
    const defaultSettings = this.getDefaultSeriesSettings(
      this.seriesSettingsLangMaps
    );
    const ind = defaultSettings.indexOf(setting[this.defaultLang]);
    defaultSettings.splice(ind, 1);
    const seriesSettingsMap = this.getSeriesSettingsMap(
      this.seriesSettingsLangMaps
    );
    this.seriesSettingsLangMaps = [];
    this.seriesSettingsLangMaps = defaultSettings.map(
      (settings) => seriesSettingsMap.series[settings.id]
    );
  };

  getDefaultSeriesSettings = (langMaps: SeriesSettingsLangMap[]) =>
    langMaps.map((langMap) => langMap[this.defaultLang]);

  getSeriesSettingsMap = (langMaps: SeriesSettingsLangMap[]) => {
    const seriesSettingsMap: SeriesSettingsMap = { series: {}, order: [] };
    langMaps.forEach((langMap) => {
      const id = langMap[this.defaultLang].id;
      seriesSettingsMap.order.push(id);
      seriesSettingsMap.series[id] = langMap;
    });
    return seriesSettingsMap;
  };

  constructor() {
    makeObservable(this, {
      seriesSettingsLangMaps: observable,
      deletedSeriesSettingsLangMaps: observable,
      fuelTypesList: observable,
      seriesCategoriesList: observable,
      codeRedFuelTypes: observable,
      fetchData: action,
      reset: action,
    });
  }

  reset() {
    this.seriesSettingsLangMaps = [];
    this.deletedSeriesSettingsLangMaps = [];
    this.fuelTypesList = [];
    this.seriesCategoriesList = [];
    this.codeRedFuelTypes = {};
  }
}

export default SeriesSettingsStore;
