import { computed, makeObservable, observable, override } from "mobx";
import { convertToRichTextObject } from "vapi-ui-common";
import { InteriorColorsReviewMap } from "../../../models/colors.model";
import { sortBy, toLowerCase } from "../../../utils";
import BaseColorsReviewStore from "./baseColorsReviewStore";

class ColorsInteriorReviewStore extends BaseColorsReviewStore {
  map: InteriorColorsReviewMap = {};

  constructor() {
    // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
    super();

    makeObservable(this, {
      map: observable,
      filteredReviewItems: computed,
      reset: override,
    });
  }

  get filteredReviewItems() {
    const items = Object.values(this.map)
      .slice()
      .sort(
        sortBy(this.sortField, this.reverse, this.sortField === "modifiedDate")
      );
    const lowerSearchText = toLowerCase(this.searchText);
    return items.filter((color) => {
      if (this.isInProgressFilter && !color.isInProgress) {
        return false;
      }
      if (this.isExtraCostColorFilter && !color.isExtraCost) {
        return false;
      }
      if (lowerSearchText) {
        const valuesToCheck: string[] = [
          convertToRichTextObject(color.name.before).text,
          convertToRichTextObject(color.name.after).text,
          color.rejectNotes,
        ];
        for (let val of valuesToCheck) {
          if (toLowerCase(val).includes(lowerSearchText)) {
            return true;
          }
        }
      }

      return !lowerSearchText;
    });
  }

  reset() {
    super.reset();
    this.map = {};
  }
}

export default ColorsInteriorReviewStore;
