import { action, computed, observable, makeObservable } from "mobx";
import {
  BRAND_LEXUS,
  BRAND_TDPR,
  LanguagePermissions,
  UserGroups,
  UserInfo,
  UserModules,
  UserVDPermissions,
} from "../models/user.model";
import { SeriesCategories, VehicleTeam } from "../models/vehicleData.model";

export class UserStore {
  brand = "";
  groups = "" as UserGroups;
  modules = {
    CommonLanguage: {},
    ProductTeam: {},
    AgencyTeam: {},
    Disclaimers: {},
    SeriesManager: {},
    SeriesSelections: {},
    Spanish: {},
  } as UserModules;
  series: string[] = [];
  objectId = "";
  langPermissions = {} as LanguagePermissions;
  teamModule = {} as UserVDPermissions;

  setTeamModule = (team: VehicleTeam) => {
    switch (team) {
      case VehicleTeam.AGENCY_SPANISH:
      case VehicleTeam.AGENCY_TEAM: {
        this.teamModule = this.modules.AgencyTeam;
        break;
      }
      case VehicleTeam.PRODUCT_TEAM: {
        this.teamModule = this.modules.ProductTeam;
        break;
      }
    }
  };

  setUserInfo = (userInfo: UserInfo) => {
    // VAPI-1355 temporary solution for Lexus data
    if (userInfo.brand === BRAND_LEXUS) {
      makeReadOnly(userInfo);
    } else if (userInfo.brand === BRAND_TDPR) {
      setTDPRPermissions(userInfo);
    }
    Object.assign(this, userInfo);
  };

  constructor() {
    makeObservable(this, {
      brand: observable,
      groups: observable,
      modules: observable,
      series: observable,
      objectId: observable,
      langPermissions: observable,
      teamModule: observable,
      setUserInfo: action,
      isTdpr: computed
    });
  }

  get isTdpr() {
    return this.brand.toLowerCase() === BRAND_TDPR;
  }
}

const makeReadOnly = (userInfo: UserInfo) => {
  userInfo.modules.CommonLanguage = { canView: true };
  userInfo.modules.Disclaimers = { canView: true };
  userInfo.modules.ProductTeam = {
    canView: true,
    series: {
      [SeriesCategories.LARGE_SEDANS_VANS]: { canView: true },
      [SeriesCategories.SEDANS_SPORTS_CARS]: { canView: true },
      [SeriesCategories.CROSSOVERS]: { canView: true },
      [SeriesCategories.TRUCKS_SUVS]: { canView: true },
    },
  };
  userInfo.modules.AgencyTeam = {
    canView: true,
    series: {
      [SeriesCategories.LARGE_SEDANS_VANS]: { canView: true },
      [SeriesCategories.SEDANS_SPORTS_CARS]: { canView: true },
      [SeriesCategories.CROSSOVERS]: { canView: true },
      [SeriesCategories.TRUCKS_SUVS]: { canView: true },
    },
  };
  userInfo.modules.SeriesManager = { canView: true };
  userInfo.langPermissions = {
    EN: { canView: true },
  };
};

const setTDPRPermissions = (userInfo: UserInfo) => {
  userInfo.modules.CommonLanguage = { canView: false };
  userInfo.modules.Disclaimers = { canView: false };
  userInfo.modules.ProductTeam = {
    canView: false,
    series: {
      [SeriesCategories.LARGE_SEDANS_VANS]: { canView: false },
      [SeriesCategories.SEDANS_SPORTS_CARS]: { canView: false },
      [SeriesCategories.CROSSOVERS]: { canView: false },
      [SeriesCategories.TRUCKS_SUVS]: { canView: false },
    },
  };
};

export default UserStore;
