import { observable, makeObservable } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import { removeNulls } from '../utils';
import { RefItem } from './refItem.model';

export class ColorFamilyItem {
  uid = '';
  id = '';
  revId = '';
  interiorColorsMap: { [id: string]: CFInteriorColorItem } = {};
  exteriorColorsMap: { [id: string]: CFExteriorColorItem } = {};
  hexCode = '';
  name = '';

  constructor(colorFamily?: ColorFamilyResponse) {
    makeObservable(this, {
      hexCode: observable,
      name: observable
    });

    this.uid = uuidv4();
    if (colorFamily) {
      Object.assign(this, removeNulls(colorFamily));
    }
  }

  canDelete = () => {
    return (
      !Object.keys(this.interiorColorsMap).length && !Object.keys(this.exteriorColorsMap).length
    );
  };

  isValid = () => {
    return this.name !== '' && this.hexCode !== '';
  };

  getPayload = (): ColorFamilyRequest => {
    return {
      id: this.id,
      revId: this.revId,
      name: this.name,
      hexCode: this.hexCode,
    };
  };
}
export interface ColorFamilyResponse {
  id: string;
  revId: string;
  name: string;
  hexCode: string;
}
export interface ColorFamilyRequest extends ColorFamilyResponse {}

export class CFColorItem {
  uid = '';
  id = '';
  revId = '';
  colorFamilyNames = '';
  code = '';
  name = '';
  colorFamilies = [] as ColorFamilyItem[];
  material = {} as RefItem;

  isValid = () => {
    return false;
  };

  getPayload = (): any => {
    return {};
  };

  constructor() {
    makeObservable(this, {
      colorFamilyNames: observable,
      code: observable,
      name: observable,
      colorFamilies: observable,
      material: observable
    });
  }

  setColorFamilyNames() {
    const colorFamilyNames: string[] = [];

    this.colorFamilies.forEach(fam => {
      colorFamilyNames.push(fam.name);
    });

    this.colorFamilyNames = colorFamilyNames.join();
  }
}

export class CFInteriorColorItem extends CFColorItem {
  constructor(interiorColor?: CFInteriorColorResponse) {
    super();
    this.uid = uuidv4();
    if (interiorColor) {
      Object.assign(this, removeNulls(interiorColor));
    }
  }

  isValid = () => {
    return !!(this.name && this.code && this.material.id && this.colorFamilies.length);
  };

  getPayload = (): CFInteriorColorRequest => {
    const colorFamilyIds: { [colorFamilyId: string]: boolean } = {};
    this.colorFamilies.forEach(fam => {
      colorFamilyIds[fam.id] = true;
    });
    return {
      id: this.id,
      revId: this.revId,
      name: this.name,
      code: this.code,
      materialId: this.material.id,
      colorFamilyIds,
    };
  };
}

export class CFExteriorColorItem extends CFColorItem {
  constructor(exteriorColor?: CFExteriorColorResponse) {
    super();
    this.uid = uuidv4();
    if (exteriorColor) {
      Object.assign(this, removeNulls(exteriorColor));
    }
  }

  isValid = () => {
    return !!(this.name && this.code && this.colorFamilies.length);
  };

  getPayload = (): CFExteriorColorRequest => {
    const colorFamilyIds: { [colorFamilyId: string]: boolean } = {};
    this.colorFamilies.forEach(fam => {
      colorFamilyIds[fam.id] = true;
    });
    return {
      id: this.id,
      revId: this.revId,
      name: this.name,
      code: this.code,
      colorFamilyIds,
    };
  };
}

export interface CFColorResponse {
  id: string;
  revId: string;
  name: string;
  code: string;
  colorFamilyIds: { [colorFamilyId: string]: boolean };
}

export interface CFInteriorColorResponse extends CFColorResponse {
  materialId: string;
}

export interface CFInteriorColorRequest extends CFInteriorColorResponse {}

export interface CFExteriorColorResponse extends CFColorResponse {}

export interface CFExteriorColorRequest extends CFExteriorColorResponse {}
