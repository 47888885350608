import { observer } from 'mobx-react-lite';
import React from 'react';
import { ActionBar, ActionBarSection, SearchInput } from 'vapi-ui-common';
import useStores from '../../hooks/useStores';
import { ChangeLogItem } from '../../models/changeLog.model';
import { ChangeLogHeaderRow, ChangeLogRow } from '../ChangeLogTable';
import Spinner from '../Spinner';
import { Table } from '../Table';

export interface IChangeLog {
  module: 'Feature' | 'Option' | 'Colors' | 'Compare Features' | 'Spec';
  isLoaded: boolean;
  handleOnRevert: (item: ChangeLogItem) => Promise<void>;
}

const ChangeLog = ({ module, isLoaded, handleOnRevert }: IChangeLog) => {
  const { changeLogStore } = useStores();

  return !isLoaded ? (
    <Spinner />
  ) : (
    <>
      <ActionBar data-testid="changeLog-actionBar">
        <ActionBarSection>
          <SearchInput value={changeLogStore.searchText} onSearch={text => (changeLogStore.searchText = text)} />
        </ActionBarSection>
      </ActionBar>
      <Table fullWidth>
        <ChangeLogHeaderRow onSort={changeLogStore.setSort} />
        <tbody>
          {changeLogStore.filteredLogs.map(item => (
            <ChangeLogRow key={item.changeLogId} item={item} module={module} onRevert={handleOnRevert} />
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default observer(ChangeLog);
