import { observer } from "mobx-react-lite";
import React, { ReactNode, useRef } from "react";
import ResizeDetector from "react-resize-detector";
import { ModelCodeHeaderRow, Table, Thead } from "../../../../components/Table";
import useStores from "../../../../hooks/useStores";
import styles from "./leftTable.module.scss";

/*
 Generic table for left side of
 model table on product team
 and agency tabs
*/
type LeftTableProps = {
  viewModelCodes?: boolean;
  renderHeaderRow?: () => ReactNode;
  renderRows?: () => ReactNode;
  children?: ReactNode;
  className?: string;
};

const LeftTable: React.FC<LeftTableProps> = ({
  viewModelCodes,
  renderHeaderRow,
  renderRows,
  children,
  className,
}) => {
  const { tableSizeStore } = useStores();

  const ref = useRef<HTMLDivElement>(null);

  const onResize = (width: number = 0) => {
    tableSizeStore.tableWidth = width;
  };

  return (
    <div ref={ref} className={className}>
      <Table className={styles.featureTable}>
        {renderHeaderRow && (
          <Thead>
            <>
              {renderHeaderRow()}
              {viewModelCodes && (
                <ModelCodeHeaderRow className={styles.modelHeaderRow}>
                  <th></th>
                </ModelCodeHeaderRow>
              )}
            </>
          </Thead>
        )}
        {renderRows && <tbody>{renderRows()}</tbody>}
        {children}
      </Table>

      <ResizeDetector
        handleWidth
        onResize={onResize}
        targetDomEl={ref === null ? undefined : (ref.current as HTMLElement)}
      />
    </div>
  );
};

export default observer(LeftTable);
