import { AxiosResponse } from 'axios';
import { action, makeObservable, observable } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import { convertToRichTextObject } from 'vapi-ui-common';
import { RefItemsMap } from '../../models/category.model';
import { IDValueType, KeyValueType } from '../../models/common.model';
import { CompareFeatureDocumentResponse, CompareFeatureItem, CompareFeatureResponse, CompareFeaturesMap, CompareType } from '../../models/compareFeatures.model';
import { RefItem, RefItemObject, RefItemResponse } from '../../models/refItem.model';
import { ISortList, VDSortableEntity } from '../../models/sort.model';
import { SpecItem, SpecResponse, SpecsLangMap, SpecsMap } from '../../models/specs.model';
import { Language, LanguagePermissions } from '../../models/user.model';
import { VehicleDataVersionInfo, VehicleTeam } from '../../models/vehicleData.model';
import { VehicleModel, VehicleModelItem, VehicleModelPropsToyota, VehicleModelToyota } from '../../models/vehicleModel.model';
import { isSortReverse, sortBy, toLowerCase } from '../../utils';
import { compareFeatureItemXForm } from '../../utils/compareFeaturesUtils';
import parseLangWriteMap from '../../utils/languageUtils';
import { refItemsXForm } from '../../utils/refItemUtils';
import { sortCategoriesFunction } from '../../utils/sortUtils';
import { mapEmptySpecModels, specItemXForm } from '../../utils/specsUtils';
import { getSortList } from '../../webservices/vehicleAdminApi';
import { getCompareFeatures } from '../../webservices/vehicleCompareFeaturesApi';
import { getCategoriesByLang, getSpecTypesByLang, getVehicleSpecs } from '../../webservices/vehicleSpecsApi';

class SpecsStore {
  specs: SpecItem[] = [];
  reverseSort = false;
  sortField = 'id';
  searchText = '';
  isInProgressFilter = false;
  isSyncUpdateFilter = false;
  isReviewNotesFilter = false;
  specTypeFilters: string[] = [];
  categoryFilters: string[] = [];
  categoriesSortList: ISortList = {} as ISortList;
  specTypesSortList: ISortList = {} as ISortList;
  compareFeaturesMap: CompareFeaturesMap = { compareFeatures: {}, order: [] };
  specLangMaps: SpecsLangMap[] = [];
  // langs
  langWriteMap: LanguagePermissions = {};
  allLangs: Language[] = [];
  editableLangs: Language[] = [];
  defaultLang: Language = Language.EN;
  modelApplicabilityLang: Language = Language.EN;
  fullEditPermissions: boolean = false;

  selectedLangsMap: KeyValueType<boolean> = {};
  filteredSpecLangMaps: SpecsLangMap[] = [];
  categoriesMap: RefItemsMap = { categories: {}, order: [] };
  specTypeMap: RefItemsMap = { categories: {}, order: [] };
  viewModelCodes = false;
  rowHeightMap: KeyValueType<number> = {};

  fetchData = async (
    brand: string,
    team: VehicleTeam,
    seriesId: string,
    year: string,
    vehicleModels: VehicleModelItem<VehicleModel<VehicleModelPropsToyota>>[],
    grades: RefItem[],
    langWriteMap: LanguagePermissions,
    versionInfo: VehicleDataVersionInfo,
  ) => {
    this.reset();
    const { allLangs, editableLangs, defaultLang, selectedLangsMap, fullEditPermissions, modelApplicabilityLang } = parseLangWriteMap(langWriteMap);
    this.langWriteMap = langWriteMap;
    this.allLangs = allLangs;
    this.editableLangs = editableLangs;
    this.defaultLang = defaultLang;
    this.selectedLangsMap = selectedLangsMap;
    this.fullEditPermissions = fullEditPermissions;
    this.modelApplicabilityLang = modelApplicabilityLang;

    const promises: Promise<any>[] = [];
    this.allLangs.forEach(lang => {
      const upperLang = lang.toUpperCase();
      promises.push(
        getCategoriesByLang(brand, team, seriesId, year, upperLang, versionInfo[lang]?.toString()),
        getSpecTypesByLang(brand, team, seriesId, year, upperLang, versionInfo[lang]?.toString()),
        getVehicleSpecs(brand, team, seriesId, year, lang, versionInfo[lang]?.toString()),
        getCompareFeatures(brand, team, seriesId, year, lang, versionInfo[lang]?.toString()),
      );
    });
    const responses = await Promise.all(promises);

    // fetch the sort order for the default lang (currently default is only EN)
    if (this.defaultLang && versionInfo[this.defaultLang]) {
      const sortResponses = await Promise.all([
        getSortList(brand, team, seriesId, year, VDSortableEntity.SPECS_CATEGORIES, versionInfo[this.defaultLang]?.toString()),
        getSortList(brand, team, seriesId, year, VDSortableEntity.SPECS_SPECTYPE, versionInfo[this.defaultLang]?.toString()),
      ]);
      this.categoriesSortList = sortResponses[0].data;
      this.specTypesSortList = sortResponses[1].data;
    }
    let index = 0;
    for (const lang of this.allLangs) {
      this.updateCategoriesLangMap(lang, this.categoriesMap, responses[index].data, this.categoriesSortList);
      this.updateCategoriesLangMap(lang, this.specTypeMap, responses[index + 1].data, this.specTypesSortList);
      index += 4;
    }
    this.fillOutCategoriesMap(this.categoriesMap);
    this.fillOutCategoriesMap(this.specTypeMap);

    index = 0;
    for (const lang of this.allLangs) {
      const cats: RefItem[] = this.getCategoriesForLang(lang, this.categoriesMap);
      const specTypes: RefItem[] = this.getCategoriesForLang(lang, this.specTypeMap);
      const data = (responses[3 + index] as AxiosResponse<CompareFeatureDocumentResponse>).data;
      data.compareFeatures.forEach(compareFeature => {
        if (compareFeature.compareType === CompareType.Spec && !!compareFeature.parentId) {
          if (!this.compareFeaturesMap.compareFeatures[compareFeature.parentId]) {
            this.compareFeaturesMap.compareFeatures[compareFeature.parentId] = {
              langs: {},
              data: compareFeature,
            };
          }
          this.compareFeaturesMap.compareFeatures[compareFeature.parentId].langs[lang] = compareFeatureItemXForm(compareFeature, grades, [], [], cats, specTypes, 0);
        }
      });
      index += 4;
    }
    this.fillOutCompareFeaturesLangMap(this.compareFeaturesMap, grades);

    index = 0;
    const specsMap: SpecsMap = { specs: {}, order: [] };
    for (const lang of this.allLangs) {
      this.updateSpecsLangMap(lang, specsMap, responses[index + 2].data, vehicleModels);
      index += 4;
    }
    this.fillOutSpecsLangMap(specsMap, vehicleModels);
    this.setSpecLangMaps(specsMap);
  };

  updateCategoriesLangMap = (lang: string, refItemsMap: RefItemsMap, data: RefItemResponse<RefItemObject>[], sortList?: ISortList) => {
    const useSortList = sortList && !!Object.keys(sortList.sortList).length; // if sortlist check list
    const categories: RefItem[] = // not sure if this should be a IDValue or a RefItem
      useSortList && sortList
        ? refItemsXForm(data, sortCategoriesFunction(sortList.sortList)) //transform with sort
        : refItemsXForm(data); // without
    categories.forEach(cat => {
      // go  through cat
      if (!refItemsMap.categories[cat.id]) {
        // if catmap.catergories doesnt have the cat id
        refItemsMap.categories[cat.id] = {}; // set the id but to an empty obj
      }
      refItemsMap.categories[cat.id][lang] = cat; // set to id (where did lang comefrom what does the obj struc look like)
      if (!refItemsMap.order.includes(cat.id)) {
        refItemsMap.order.push(cat.id); // order for sorting ? add cat id if not exisitng
      }
    });
  };

  fillOutCategoriesMap = (refItemsMap: RefItemsMap) => {
    Object.values(refItemsMap.categories).forEach(cat => {
      const defaultId = cat.EN ? cat.EN.id : Object.values(cat)[0].id;
      this.allLangs.forEach(lang => {
        if (!cat[lang]) {
          cat[lang] = new RefItem({
            id: defaultId,
            name: '',
            isDeleted: false,
            isRequired: false,
          });
        }
      });
    });
  };

  getCategoriesForLang = (lang: string, refItemsMap: RefItemsMap) => {
    const cats: RefItem[] = [];
    Object.keys(refItemsMap.categories).forEach(catId => {
      if (refItemsMap.categories[catId][lang]) {
        cats.push(refItemsMap.categories[catId][lang]);
      }
    });
    return cats;
  };

  getCompareFeatureMapForLang = (lang: string) => {
    const compareFeatureMap: KeyValueType<CompareFeatureItem> = {};
    Object.values(this.compareFeaturesMap.compareFeatures).forEach(langMap => {
      const item = langMap.langs[lang];
      compareFeatureMap[item.parentId] = item;
    });
    return compareFeatureMap;
  };

  fillOutSpecsLangMap = (specsMap: SpecsMap, vehicleModels: VehicleModelItem<VehicleModel<VehicleModelPropsToyota>>[]) => {
    Object.keys(specsMap.specs).forEach(specId => {
      const specLangMap = specsMap.specs[specId];
      const specData: SpecResponse = specLangMap.data ?? ({} as SpecResponse);
      const index = specsMap.order.findIndex(id => id === specId);

      this.allLangs.forEach(lang => {
        if (!specLangMap.langs[lang]) {
          specLangMap.langs[lang] = specItemXForm(
            specData,
            vehicleModels,
            this.getCategoriesForLang(lang, this.categoriesMap),
            this.getCategoriesForLang(lang, this.specTypeMap),
            index,
            this.getCompareFeatureMapForLang(lang),
          );
          specLangMap.langs[lang].revId = '';
          specLangMap.langs[lang].description = '';
        }
      });
    });
  };

  fillOutCompareFeaturesLangMap = (compareFeaturesMap: CompareFeaturesMap, grades: RefItem[]) => {
    Object.values(compareFeaturesMap.compareFeatures).forEach(compareFeatureLangMap => {
      const compareFeatureData = compareFeatureLangMap.data ?? ({} as CompareFeatureResponse);
      this.allLangs.forEach(lang => {
        if (!compareFeatureLangMap.langs[lang]) {
          const cats: IDValueType<string>[] = this.getCategoriesForLang(lang, this.categoriesMap);
          const specTypes: IDValueType<string>[] = this.getCategoriesForLang(lang, this.specTypeMap);
          compareFeatureLangMap.langs[lang] = compareFeatureItemXForm(compareFeatureData, grades, [], [], cats, specTypes, 0);
        }
      });
    });
  };

  updateSpecsLangMap = (lang: string, specsMap: SpecsMap, data: SpecResponse[], vehicleModels: VehicleModelItem<VehicleModel<VehicleModelPropsToyota>>[]) => {
    const cats: RefItem[] = this.getCategoriesForLang(lang, this.categoriesMap);
    const specTypes: RefItem[] = this.getCategoriesForLang(lang, this.specTypeMap);

    const compareFeatureMap: KeyValueType<CompareFeatureItem> = this.getCompareFeatureMapForLang(lang);

    data.forEach(item => {
      const index = specsMap.order.findIndex(id => id === item.id);
      const spec = specItemXForm(item, vehicleModels, cats as RefItem[], specTypes as RefItem[], index >= 0 ? index : specsMap.order.length, compareFeatureMap);
      if (!specsMap.specs[spec.id]) {
        specsMap.specs[spec.id] = { data: item, langs: {} };
      }
      specsMap.specs[spec.id].langs[lang] = spec;
      if (!specsMap.order.includes(spec.id)) {
        specsMap.order.push(spec.id);
      }
    });
  };

  setSpecLangMaps = (specsMap: SpecsMap) => {
    const specLangMaps: SpecsLangMap[] = [];
    specsMap.order.forEach(id => {
      const spec = specsMap.specs[id];
      specLangMaps.push(spec);
    });
    this.specLangMaps = specLangMaps;
    this.filteredSpecLangMaps = specLangMaps;
  };

  getDefaultCategories = (categoriesMap: RefItemsMap) => {
    const lang = this.defaultLang;
    const cats: RefItem[] = [];
    Object.keys(categoriesMap.categories).forEach(catId => {
      if (categoriesMap.categories[catId][lang]) {
        cats.push(categoriesMap.categories[catId][lang]);
      }
    });
    return cats;
  };

  getDefaultSpecs = (specLangMaps: SpecsLangMap[]) => {
    return specLangMaps.map(langMap => langMap.langs[this.defaultLang]);
  };

  getCommonLanguageIds = () => {
    return this.specs.filter(item => !!item.comLangId).map(item => item.comLangId);
  };

  addItem = (vehicleModels: VehicleModelItem<VehicleModelToyota>[]) => {
    const id = uuidv4();
    const newLangMap: SpecsLangMap = { langs: {} };
    this.allLangs.forEach(lang => {
      const newItem = new SpecItem();
      newItem.modelsMap = mapEmptySpecModels(vehicleModels);
      newItem.id = id;
      newLangMap.langs[lang] = newItem;
    });

    this.specLangMaps = [newLangMap, ...this.specLangMaps];
    this.filteredSpecLangMaps = [newLangMap, ...this.filteredSpecLangMaps];
  };

  updateSelectedLangs = (lang: string, isSelected: boolean) => {
    const selectedMap = JSON.parse(JSON.stringify(this.selectedLangsMap));
    if (selectedMap[lang] != null) {
      selectedMap[lang] = isSelected;
    }
    this.selectedLangsMap = selectedMap;
  };

  copyMap = (specLangMap: SpecsLangMap, vehicleModels: VehicleModelItem<VehicleModel<VehicleModelPropsToyota>>[]) => {
    const specUid = specLangMap.langs[this.defaultLang].uid;
    const unfilteredIndex = this.specLangMaps.findIndex(item => item.langs[this.defaultLang].uid === specUid) + 1;
    const filteredIndex = this.filteredSpecLangMaps.findIndex(item => item.langs[this.defaultLang].uid === specUid) + 1;

    const newLangMap: SpecsLangMap = { langs: {} };
    for (let lang of this.allLangs) {
      const feature = specLangMap.langs[lang];
      const featureCopy = new SpecItem();
      const { id, revId, uid, isValid, getPayload, modelsMap, ...rest } = feature;
      Object.assign(featureCopy, rest);
      featureCopy.modelsMap = mapEmptySpecModels(vehicleModels);
      newLangMap.langs[lang] = featureCopy;
    }

    const langMaps = this.specLangMaps.slice();
    langMaps.splice(unfilteredIndex, 0, newLangMap);
    this.specLangMaps = langMaps;

    const filteredLangMaps = this.filteredSpecLangMaps.slice();
    filteredLangMaps.splice(filteredIndex, 0, newLangMap);
    this.filteredSpecLangMaps = filteredLangMaps;

    return newLangMap;
  };

  deleteItem = (uid: string) => {
    this.specLangMaps = this.specLangMaps.filter(item => item.langs[this.allLangs[0]].uid !== uid);
    this.filteredSpecLangMaps = this.filteredSpecLangMaps.filter(item => item.langs[this.allLangs[0]].uid !== uid);
  };

  getSpecsForLang = (lang: string) => {
    const specs: SpecItem[] = [];
    this.specLangMaps.forEach(langMap => {
      if (langMap.langs[lang]) {
        specs.push(langMap.langs[lang]);
      }
    });
    return specs;
  };

  getSpecsMap = () => {
    const specMap: SpecsMap = { specs: {}, order: [] };
    this.specLangMaps.forEach(langMap => {
      const id = langMap.langs[Object.keys(langMap.langs)[0]].id;
      specMap.specs[id] = langMap;
      specMap.order.push(id);
    });
    return specMap;
  };

  onSort = (field: string, lang?: string) => {
    this.reverseSort = isSortReverse(this.sortField, field, this.reverseSort);
    this.sortField = field;
    let specs = lang ? this.getSpecsForLang(lang) : this.getDefaultSpecs(this.specLangMaps);
    specs = specs.sort(sortBy(this.sortField, this.reverseSort));
    const specsMap = this.getSpecsMap();
    specsMap.order = [];
    specs.forEach(spec => {
      specsMap.order.push(spec.id);
    });
    this.setSpecLangMaps(specsMap);
  };

  onFilter = (filterAction: () => void) => {
    filterAction();
    this.filteredSpecLangMaps = this.filterLangMaps();
  };

  resetFilters = () => {
    this.searchText = '';
    this.categoryFilters = [];
    this.isInProgressFilter = false;
    this.isSyncUpdateFilter = false;
    this.filteredSpecLangMaps = this.filterLangMaps();
  };

  filterLangMaps = () => {
    const sorted = this.specLangMaps.slice();
    const lowerSearchText = toLowerCase(this.searchText);
    return sorted.filter(specLangMap => {
      let checked = false;
      let hasChangedAttributes = false;

      for (let lang of this.allLangs) {
        const spec = specLangMap.langs[lang];
        if (!checked) {
          checked = true;
          if (this.categoryFilters.length && !this.categoryFilters.includes(spec.category.value)) {
            return false;
          }
          if (this.specTypeFilters.length && !this.specTypeFilters.includes(spec.specType.value)) {
            return false;
          }
          if (this.isInProgressFilter && !spec.isInProgress) {
            return false;
          }
          if (this.isReviewNotesFilter && spec.rejectNotes.length === 0) {
            return false;
          }
        }

        if (spec.changedAttributes.length) {
          hasChangedAttributes = true;
        }

        if (lowerSearchText) {
          const valuesToCheck: string[] = [spec.category.value, spec.specType.value, convertToRichTextObject(spec.description).text, spec.notes];
          for (let val of valuesToCheck) {
            if (toLowerCase(val).includes(lowerSearchText)) {
              return true;
            }
          }
        }
      }

      if (this.isSyncUpdateFilter && !hasChangedAttributes) {
        return false;
      }

      return !lowerSearchText;
    });
  };

  constructor() {
    makeObservable(this, {
      selectedLangsMap: observable,
      filteredSpecLangMaps: observable,
      categoriesMap: observable,
      specTypeMap: observable,
      viewModelCodes: observable,
      rowHeightMap: observable,
      fetchData: action,
      resetFilters: action,
      reset: action,
    });
  }

  hasChangedAttributes() {
    return !!this.specLangMaps.filter(
      langMap =>
        !!this.editableLangs.filter(lang => {
          let hasChange = false;
          for (const change of langMap.langs[lang].changedAttributes) {
            if (change !== 'new' && change !== 'delete') {
              hasChange = true;
              break;
            }
          }
          return hasChange;
        }).length,
    ).length;
  }

  reset() {
    this.specs = [];
    this.reverseSort = false;
    this.sortField = 'id';
    this.searchText = '';
    this.isInProgressFilter = false;
    this.isSyncUpdateFilter = false;
    this.isReviewNotesFilter = false;
    this.specTypeFilters = [];
    this.categoryFilters = [];
    this.viewModelCodes = false;
    this.compareFeaturesMap = { compareFeatures: {}, order: [] };
    this.categoriesMap = { categories: {}, order: [] };
    this.specTypeMap = { categories: {}, order: [] };
    this.specLangMaps = [];
    this.filteredSpecLangMaps = [];
    this.allLangs = [];
    this.selectedLangsMap = {};
    this.rowHeightMap = {};
  }

  setRowHeight = (langMap: SpecsLangMap, rowHeight: number) => {
    const key = langMap.langs[this.defaultLang].id;
    this.rowHeightMap[key] = rowHeight;
  };

  getRowHeight(langMap: SpecsLangMap) {
    const key = langMap.langs[this.defaultLang].id;
    return this.rowHeightMap[key];
  }
}

export default SpecsStore;
