import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { ActionButton, Checkbox } from 'vapi-ui-common';
import { TableRowBase } from '../../../../../../components/Table/components/TableRow/TableRow';
import { FuelTypeItemResponse } from '../../../../../../models/fuelType.model';
import { SeriesCategoryItem, SeriesSettingsLangMap } from '../../../../../../models/seriesSettings.model';
import { Brand, Language } from '../../../../../../models/user.model';
import SyncTdPRButton from '../../../../components/SyncTdPRButton/SyncTdPRButton';
import styles from '../../seriesSettings.module.scss';
import SeriesSettingsEditPanel from './SeriesSettingsEditPanel';

interface SeriesSettingsRowProps {
  brand: Brand;
  index: number;
  saveSeriesSetting?: (seriesSettingsLangMap: SeriesSettingsLangMap) => void;
  onDelete?: (seriesSetting: SeriesSettingsLangMap) => void;
  saveSubSeriesSetting?: (seriesSettingsLangMap: SeriesSettingsLangMap) => void;
  onDeleteSubSeriesSetting?: (seriesSetting: SeriesSettingsLangMap) => void;
  readOnly?: boolean;
  fuelTypesList: FuelTypeItemResponse[];
  seriesCategoriesList: SeriesCategoryItem[];
  canDeleteSetting: boolean;
  seriesSettingsLangMap: SeriesSettingsLangMap;
  defaultLang: Language;
  languages: Language[];
  compareSettings: (settings: SeriesSettingsLangMap) => void;
  canAddNewSeriesSettings: boolean;
}

const SeriesSettingsRow = ({
  brand,
  index,
  fuelTypesList,
  saveSeriesSetting = () => {},
  onDelete = () => void 0,
  saveSubSeriesSetting = () => {},
  onDeleteSubSeriesSetting = () => void 0,
  readOnly,
  seriesCategoriesList,
  canDeleteSetting,
  seriesSettingsLangMap,
  defaultLang,
  languages,
  compareSettings,
  canAddNewSeriesSettings,
}: SeriesSettingsRowProps) => {
  const defaultSeriesSettings = seriesSettingsLangMap[defaultLang];
  const [isEditOpen, setIsEditOpen] = useState(defaultSeriesSettings.isNewSubSeries);
  const [showMpge, setShowMpge] = useState(false);
  const [showIcons, setShowIcons] = useState(false);
  const [codeRed, setCodeRed] = useState(!defaultSeriesSettings.isPublishable);

  const handleOnDelete = () => {
    if (defaultSeriesSettings.parentId) {
      onDeleteSubSeriesSetting(seriesSettingsLangMap);
    } else {
      onDelete(seriesSettingsLangMap);
    }
  };

  const handleOnEdit = () => {
    setIsEditOpen(true);
  };

  const getNamesString = () => {
    const names = [] as string[];
    Object.values(seriesSettingsLangMap).forEach(item => {
      names.push(item.name);
    });
    return names.join(' | ');
  };

  const getFuelTypesString = () => {
    const fuelNames = [] as string[];
    Object.keys(defaultSeriesSettings.fuelTypes).forEach(type => {
      fuelTypesList.forEach(item => {
        if (item.id === type) {
          fuelNames.push(item.type);
        }
      });
    });
    return fuelNames.join(', ') || '--';
  };

  const getSeriesCategoriesString = () => {
    const categories = [] as string[];
    Object.keys(defaultSeriesSettings.seriesCategories).forEach(type => {
      seriesCategoriesList.forEach(item => {
        if (item.id === type) {
          categories.push(item.name);
        }
      });
    });
    return categories.join(', ') || '--';
  };

  const checkFuelTypeForMpge = () => {
    const fuelNames = [] as string[];
    Object.keys(defaultSeriesSettings.fuelTypes).forEach(type => {
      fuelTypesList.forEach(item => {
        if (item.id === type) {
          fuelNames.push(item.type);
        }
      });
    });
    setShowMpge(fuelNames.includes('Plug-in Hybrid') || fuelNames.includes('Fuel Cell'));
    return showMpge ? defaultSeriesSettings.mpge : '--';
  };

  const getRangeOrMileage = () => {
    const mileage = defaultSeriesSettings.estimatedMileage;
    const range = defaultSeriesSettings.range;
    const fuels = [] as string[];
    Object.keys(defaultSeriesSettings.fuelTypes).forEach(type => {
      fuelTypesList.forEach(item => {
        if (item.id === type) {
          fuels.push(item.type);
        }
      });
    });
    return (fuels.includes('Battery Electric') ? range : mileage) || '--';
  };

  const showSync = defaultSeriesSettings.fromTMNA && !readOnly;

  const changedAttributes = () => {
    const changed: string[] = [];
    languages.forEach(lang => {
      const settings = seriesSettingsLangMap[lang];
      if (settings) {
        changed.push(...settings.changedAttributes);
      }
    });
    return changed;
  };

  return !isEditOpen ? (
    <Draggable draggableId={defaultSeriesSettings.uid} index={index}>
      {(provided, snapshot) => (
        <TableRowBase
          zebra
          onMouseEnter={() => setShowIcons(true)}
          onMouseLeave={() => setShowIcons(false)}
          className={styles.seriesSettingRowCollapsed}
          innerRef={provided.innerRef}
          {...provided.draggableProps}
          style={provided.draggableProps.style}
        >
          <td>
            <Checkbox
              id={`CodeRed-${defaultSeriesSettings.uid}`}
              checked={codeRed}
              disabled
              className={styles.isPublishableChbox}
              onChange={e => {
                setCodeRed(e.currentTarget.checked);
                for (const lang of Object.keys(seriesSettingsLangMap)) {
                  seriesSettingsLangMap[lang].isPublishable = !e.currentTarget.checked;
                }
                if (defaultSeriesSettings.isSubSeries) {
                  saveSubSeriesSetting(seriesSettingsLangMap);
                } else {
                  saveSeriesSetting(seriesSettingsLangMap);
                }
              }}
            ></Checkbox>
          </td>
          <td>
            <div className={styles.seriesName}>
              <span>
                {getNamesString()}
                {defaultSeriesSettings.isSubSeries ? '(Sub Series)' : ''}
              </span>
              {showSync && (
                <SyncTdPRButton
                  id={defaultSeriesSettings.id}
                  onClick={() => {
                    compareSettings(seriesSettingsLangMap);
                  }}
                  changedAttributes={changedAttributes()}
                />
              )}
            </div>
          </td>
          <td>{getFuelTypesString()}</td>
          <td>{getSeriesCategoriesString()}</td>
          <td>{defaultSeriesSettings.seating || '--'}</td>
          <td>{getRangeOrMileage()}</td>
          <td>{checkFuelTypeForMpge()}</td>
          <td className={styles.ctaCol}>
            {!readOnly && showIcons && (
              <>
                {canDeleteSetting && <ActionButton icon="trash" onClick={() => handleOnDelete()} />}
                <ActionButton icon="edit" onClick={() => handleOnEdit()} />
              </>
            )}
          </td>
        </TableRowBase>
      )}
    </Draggable>
  ) : (
    <SeriesSettingsEditPanel
      brand={brand}
      fuelTypesList={fuelTypesList}
      onClose={() => setIsEditOpen(false)}
      saveSeriesSetting={saveSeriesSetting}
      saveSubSeriesSetting={saveSubSeriesSetting}
      seriesCategoriesList={seriesCategoriesList}
      seriesSettingsLangMap={seriesSettingsLangMap}
      defaultLang={defaultLang}
      compareSettings={compareSettings}
      canAddNewSeriesSettings={canAddNewSeriesSettings}
      readOnly={readOnly}
    />
  );
};

export default observer(SeriesSettingsRow);
