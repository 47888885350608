import cx from 'clsx';
import React from 'react';
import styles from './table.module.scss';

export interface TableProps extends React.HTMLAttributes<HTMLTableElement> {
  fixed?: boolean;
  fullWidth?: boolean;
}

export const Table: React.FC<TableProps> = ({ fixed, fullWidth, children, className, ...rest }) => (
  <table data-testid="table" className={cx(styles.table, fullWidth && styles.fullWidth, fixed && styles.fixed, className)} {...rest}>
    {children}
  </table>
);

export const HorizontalScrollTable: React.FC<React.HTMLAttributes<HTMLTableElement>> = ({ children, className, ...rest }) => (
  <div data-testid="horizontalScrollTable" className={styles.horizontalScrolLTable}>
    <Table>{children}</Table>
  </div>
);

export const Thead: React.FC<React.HTMLAttributes<HTMLTableSectionElement>> = ({ children, ...rest }) => (
  <thead data-testid="thead" className={styles.thead} {...rest}>
    {children}
  </thead>
);

export const HeaderRow: React.FC<React.HTMLAttributes<HTMLTableRowElement>> = ({ children, ...rest }) => (
  <tr data-testid="headerRow" className={styles.headerRow} {...rest}>
    {children}
  </tr>
);

// Left table TH elements
interface LeftTableTHProps extends React.ThHTMLAttributes<HTMLTableHeaderCellElement> {
  onClick?: () => void;
}

export const LeftTableTH: React.FC<LeftTableTHProps> = ({ children, className, onClick, ...rest }) => (
  <th data-testid="leftTableTH" className={cx(styles.leftTableTH, onClick && styles.clickable, className)} {...rest}>
    {onClick && <HeaderButton onClick={() => onClick()}>{children}</HeaderButton>}
    {!onClick && children}
  </th>
);

export const HeaderButton: React.FC<React.ButtonHTMLAttributes<HTMLButtonElement>> = props => <button data-testid="headerButton" className={styles.headerButton} {...props} />;

export const ModelCodeHeaderRow: React.FC<React.HTMLAttributes<HTMLTableRowElement>> = ({ children, className }) => (
  <tr data-testid="modelCodeHeaderRow" className={cx(styles.modelCodeHeaderRow, className)}>
    {children}
  </tr>
);

export const TwoTableWrapper: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({ children, ...rest }) => (
  <div data-testid="twoTableWrapper" className={styles.twoTableWrapper} {...rest}>
    {children}
  </div>
);
