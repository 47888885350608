import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { ActionBar } from 'vapi-ui-common';
import Spinner from '../../../../../components/Spinner/Spinner';
import useStores from '../../../../../hooks/useStores';
import { bnpReviewXform } from '../../../../../utils/bnpUtils';
import { getReviewItems } from '../../../../../webservices/vehicleBnPApi';
import ActionBarFiltersSection from '../../../components/ActionBarFiltersSection';
import { ProductDataControllerProps } from '../../../models/controllers.model';
import BnPReviewTable from '../components/BnPReviewTable';

const BnPReview = ({ seriesId, year, version = '' }: ProductDataControllerProps) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const {
    teamStore,
    userStore: { brand },
    bnpReviewStore,
  } = useStores();

  useEffect(() => {
    bnpReviewStore.reset();
    (async () => {
      try {
        const responses = await Promise.all([
          getReviewItems(brand, teamStore.team.param, seriesId, year, version),
        ]);
        bnpReviewStore.reviewItems = bnpReviewXform(responses[0].data).sort((a, b) => {
          return a.sortOrder - b.sortOrder;
        });
      } catch (err) {
        console.log(err);
        toast.error('Error loading BnP review data');
      }
      setIsLoaded(true);
    })();
  }, [bnpReviewStore, brand, seriesId, teamStore, version, year]);

  return !isLoaded ? (
    <Spinner />
  ) : (
    <>
      <ActionBar>
        <ActionBarFiltersSection
          readOnly
          searchText={bnpReviewStore.searchText}
          onSearchTextChange={text => (bnpReviewStore.searchText = text)}
          buttons={[]}
          hideFilter
          renderFilter={onClose => <></>}
        />
      </ActionBar>
      <BnPReviewTable />
    </>
  );
};

export default BnPReview;
