import { observer } from 'mobx-react-lite';
import React from 'react';
import { ChangeLogBeforeAfter } from '../../../../components/ChangeLogTable/ChangeLogChanges/ChangeLogChanges';
import Checkbox from '../../../../components/Checkbox/Checkbox';
import {
  HeaderCell,
  HeaderRow,
  TableRow,
  Thead,
  TwoTableWrapper,
} from '../../../../components/Table';
import useStores from '../../../../hooks/useStores';
import { KeyValueType } from '../../../../models/common.model';
import { VehicleTeam } from '../../../../models/vehicleData.model';
import { ModelsChangeTypeMap } from '../../../../models/vehicleModel.model';
import modelsReviewStore from '../../../../stores/vehicleData/review/modelReviewStore';
import LeftTable from '../../components/LeftTable';
import AddedDeletedCell from '../../components/tableCells/AddedDeletedCell';
import { ApplyCell } from '../../components/tableCells/ApplyCell';
import VDTableCell from '../../components/tableCells/VDTableCell';
import styles from './modelsModal.module.scss';

interface IModelsReviewTable {
  handleOnApplyChange: (item: ModelsChangeTypeMap, value: boolean) => void;
  handleOnNotesChange: (item: ModelsChangeTypeMap, value: string) => void;
  codeRedFuelTypes: KeyValueType<true>;
}

const ModelsReviewTable = ({
  handleOnApplyChange,
  handleOnNotesChange,
  codeRedFuelTypes,
}: IModelsReviewTable) => {
  const { teamStore } = useStores();

  return (
    <TwoTableWrapper>
      <LeftTable>
        <Thead>
          <HeaderRow style={{ height: '81' }}>
            <HeaderCell>Apply & Accept?</HeaderCell>
            <HeaderCell>Code Red?</HeaderCell>
            <HeaderCell>Model Code</HeaderCell>
            <HeaderCell>Go Live Date</HeaderCell>
            <HeaderCell>Grade</HeaderCell>
            <HeaderCell>Fuel Type</HeaderCell>
            <HeaderCell>Bed</HeaderCell>
            <HeaderCell>Cab</HeaderCell>
            <HeaderCell>Trim Title</HeaderCell>
            <HeaderCell>Trim Description</HeaderCell>
            <HeaderCell>Drive</HeaderCell>
            <HeaderCell>Motor/Engine</HeaderCell>
            <HeaderCell>Transmission</HeaderCell>
            <HeaderCell>Horsepower</HeaderCell>
            <HeaderCell>
              Added / <br /> Deleted?
            </HeaderCell>
          </HeaderRow>
        </Thead>
        <tbody>
          {modelsReviewStore.filteredReviewItems.map(reviewMap => {
            return (
              <TableRow key={reviewMap.id}>
                {/* apply */}
                <ApplyCell
                  id={reviewMap.id}
                  applyChecked={reviewMap.isApplied}
                  onApplyChecked={isApplied => {
                    handleOnApplyChange(reviewMap, isApplied);
                  }}
                  notes={reviewMap.rejectNotes}
                  handleOnNotesChange={notes => handleOnNotesChange(reviewMap, notes)}
                  hideRejectNotes={teamStore.team.param === VehicleTeam.AGENCY_TEAM}
                />

                {/* code red */}
                <VDTableCell colType="dropdown" className={styles.tableCellHeight}>
                  <Checkbox
                    id={`donotpublish-${reviewMap.id}`}
                    className={styles.isPublishableChbox}
                    disabled
                    defaultChecked={
                      reviewMap.isNotPublishable || !!codeRedFuelTypes[reviewMap.fuelType.after]
                    }
                  />
                </VDTableCell>
                {/* code */}
                <VDTableCell colType="dropdown" className={styles.tableCellHeight}>
                  <ChangeLogBeforeAfter
                    before={reviewMap.code.before}
                    after={reviewMap.code.after}
                    styleAfter={reviewMap.code.hasChanged}
                  />
                </VDTableCell>
                {/* goLiveDate */}
                <VDTableCell colType="dropdown" className={styles.tableCellHeight}>
                  <ChangeLogBeforeAfter
                    before={reviewMap.goLiveDate.before}
                    after={reviewMap.goLiveDate.after}
                    styleAfter={reviewMap.goLiveDate.hasChanged}
                  />
                </VDTableCell>
                {/* grade */}
                <VDTableCell colType="dropdown" className={styles.tableCellHeight}>
                  <ChangeLogBeforeAfter
                    before={reviewMap.grade.before}
                    after={reviewMap.grade.after}
                    styleAfter={reviewMap.grade.hasChanged}
                  />
                </VDTableCell>
                {/* fuel type */}
                <VDTableCell colType="dropdown" className={styles.tableCellHeight}>
                  <ChangeLogBeforeAfter
                    before={reviewMap.fuelType.before}
                    after={reviewMap.fuelType.after}
                    styleAfter={reviewMap.fuelType.hasChanged}
                  />
                </VDTableCell>
                {/* bed */}
                <VDTableCell colType="dropdown" className={styles.tableCellHeight}>
                  <ChangeLogBeforeAfter
                    before={reviewMap.bed.before}
                    after={reviewMap.bed.after}
                    styleAfter={reviewMap.bed.hasChanged}
                  />
                </VDTableCell>
                {/* cab */}
                <VDTableCell colType="dropdown" className={styles.tableCellHeight}>
                  <ChangeLogBeforeAfter
                    before={reviewMap.cab.before}
                    after={reviewMap.cab.after}
                    styleAfter={reviewMap.cab.hasChanged}
                  />
                </VDTableCell>
                {/* trim title */}
                <VDTableCell colType="dropdown" className={styles.tableCellHeight}>
                  <ChangeLogBeforeAfter
                    before={reviewMap.trimTitle.before}
                    after={reviewMap.trimTitle.after}
                    styleAfter={reviewMap.trimTitle.hasChanged}
                  />
                </VDTableCell>
                {/* Trim Description */}
                <VDTableCell colType="dropdown" className={styles.tableCellHeight}>
                  <ChangeLogBeforeAfter
                    before={reviewMap.description.before}
                    after={reviewMap.description.after}
                    styleAfter={reviewMap.description.hasChanged}
                  />
                </VDTableCell>
                {/* drive */}
                <VDTableCell colType="dropdown" className={styles.tableCellHeight}>
                  <ChangeLogBeforeAfter
                    before={reviewMap.drive.before}
                    after={reviewMap.drive.after}
                    styleAfter={reviewMap.drive.hasChanged}
                  />
                </VDTableCell>
                {/* engine */}
                <VDTableCell colType="dropdown" className={styles.tableCellHeight}>
                  <ChangeLogBeforeAfter
                    before={reviewMap.engine.before}
                    after={reviewMap.engine.after}
                    styleAfter={reviewMap.engine.hasChanged}
                  />
                </VDTableCell>
                {/* Transmission */}
                <VDTableCell colType="dropdown" className={styles.tableCellHeight}>
                  <ChangeLogBeforeAfter
                    before={reviewMap.transmission.before}
                    after={reviewMap.transmission.after}
                    styleAfter={reviewMap.transmission.hasChanged}
                  />
                </VDTableCell>
                {/* horsepower */}
                <VDTableCell colType="dropdown" className={styles.tableCellHeight}>
                  <ChangeLogBeforeAfter
                    before={reviewMap.horsepower.before}
                    after={reviewMap.horsepower.after}
                    styleAfter={reviewMap.horsepower.hasChanged}
                  />
                </VDTableCell>
                <AddedDeletedCell isNew={reviewMap.isNew} isDeleted={reviewMap.isDeleted} />
              </TableRow>
            );
          })}
        </tbody>
      </LeftTable>
    </TwoTableWrapper>
  );
};

export default observer(ModelsReviewTable);
