import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Spinner from "../../../../components/Spinner";
import { uiBlock } from "../../../../components/UiBlocker/uiBlock";
import useStores from "../../../../hooks/useStores";
import {
  OptionsChangeTypeMap,
  OptionsReviewResponse,
} from "../../../../models/options.model";
import ActionBarVehicleData from "../../../../routes/vehicleData/components/ActionBarVehicleData";
import { ProductDataControllerProps } from "../../../../routes/vehicleData/models/controllers.model";
import { categoriesXForm } from "../../../../utils/categoryUtils";
import { handleErrorResponse } from "../../../../utils/errorHandlingUtils";
import { optionsReviewItemXForm } from "../../../../utils/optionsUtils";
import {
  getReviewCategories,
  getReviewOptions,
} from "../../../../webservices/vehicleOptionsApi";
import OptionsFilters from "./components/Filters";
import OptionsReviewTable from "./components/OptionsReviewTable";

const OptionsReview = ({
  seriesId,
  year,
  version = "",
  vehicleModels,
  changeLogLink = "",
}: ProductDataControllerProps) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const {
    optionsReviewStore,
    teamStore,
    userStore: { brand },
  } = useStores();

  useEffect(() => {
    optionsReviewStore.reset();

    (async () => {
      try {
        const responses = await Promise.all([
          getReviewCategories(
            brand,
            teamStore.team.param,
            seriesId,
            year,
            version
          ),
          getReviewOptions<OptionsReviewResponse>(
            brand,
            teamStore.team.param,
            seriesId,
            year,
            version
          ),
        ]);

        optionsReviewStore.categories = categoriesXForm(responses[0].data);
        const { map } = optionsReviewItemXForm(
          responses[1].data,
          vehicleModels,
          optionsReviewStore.categories
        );
        optionsReviewStore.map = map;
      } catch {
        toast.error("Error loading Options review data");
      }
      setIsLoaded(true);
    })();
  }, [
    optionsReviewStore,
    vehicleModels,
    brand,
    seriesId,
    teamStore,
    version,
    year,
  ]);

  const handleOnMapApplyChange = async (
    map: OptionsChangeTypeMap,
    value: boolean
  ) => {
    map.isApplied = value;
    saveMap(map);
  };

  const handleOnMapNotesChange = async (
    map: OptionsChangeTypeMap,
    value: string
  ) => {
    map.rejectNotes = value;
    saveMap(map);
  };

  const saveMap = async (map: OptionsChangeTypeMap) => {
    try {
      if (optionsReviewStore.isMapValid(map, teamStore.team.param)) {
        uiBlock.start();
        /* const response = await updateReviewOption(
          brand,
          teamStore.team.param,
          seriesId,
          year,
          version,
          optionsReviewStore.getMapPayload(map, teamStore.team.param)
        );
        map.revId = response.data.revId; */
        toast.success("Successfully updated option");
      } else {
        toast.error("Please fill in the required fields for the option");
      }
    } catch (e) {
      handleErrorResponse(e, "Error updating option");
    }

    uiBlock.stop();
  };

  return !isLoaded ? (
    <Spinner />
  ) : (
    <>
      <ActionBarVehicleData
        readOnly
        toggleViewModelCodes={() =>
          (optionsReviewStore.viewModelCodes =
            !optionsReviewStore.viewModelCodes)
        }
        viewModelCodes={optionsReviewStore.viewModelCodes}
        searchText={optionsReviewStore.searchText}
        onSearchTextChange={(text) => (optionsReviewStore.searchText = text)}
        renderFilter={(onClose) => (
          <OptionsFilters
            onClose={onClose}
            categories={optionsReviewStore.categories.map((item) => item.value)}
            categoryFilters={optionsReviewStore.categoryFilters}
            setCategoryFilters={(categoryFilters) =>
              (optionsReviewStore.categoryFilters = categoryFilters)
            }
            isInProgressFilter={optionsReviewStore.isInProgressFilter}
            setIsInProgressFilter={(value) =>
              (optionsReviewStore.isInProgressFilter = value)
            }
          />
        )}
      />

      <OptionsReviewTable
        vehicleModels={vehicleModels}
        handleOnApplyChange={handleOnMapApplyChange}
        handleOnNotesChange={handleOnMapNotesChange}
      />
    </>
  );
};

export default observer(OptionsReview);
