import cx from 'clsx';
import React from 'react';
import useDebounce from '../../hooks/useDebounce';
import styles from './input.module.scss';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  onDebounce?: () => void;
  required?: boolean;
}

const Input = React.forwardRef((props: InputProps, ref?: React.Ref<HTMLInputElement>) => {
  const { className, onChange, onDebounce, onBlur, required, value, ...rest } = props;
  const { debounce } = useDebounce();

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event);
    }
    if (onDebounce) {
      debounce(() => {
        onDebounce();
      });
    }
  };

  const handleOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    event.currentTarget.value = event.currentTarget.value.trim();
    if (onBlur) {
      onBlur(event);
    }
  };

  const hasError = () => {
    if (required && !value) {
      return true;
    }
    return false;
  };

  const getCssClass = () => {
    return cx(styles.input, hasError() && styles.error, className);
  };

  return (
    <>
      <input data-testid="input" ref={ref} value={value} type="text" onChange={handleOnChange} onBlur={handleOnBlur} className={getCssClass()} {...rest} />
    </>
  );
});

export default Input;
