import React, { ReactElement } from 'react';
import { RadioProps } from './Radio';

interface RadioGroupProps {
  name: string;
  selectedValue?: string;
  onChange?: (value: string) => void;
  children?: ReactElement<RadioProps>[];
  className?: string;
}

export const RadioGroup = ({
  name,
  selectedValue,
  onChange = () => {},
  children = [] as ReactElement[], 
  className,
}: RadioGroupProps) => {
  return (
    <section className={className}>
      { children.map(child =>
        React.cloneElement(child, {name, selectedValue, onChange})
      )}
    </section>
  );
};
