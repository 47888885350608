import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef, useState } from 'react';
import ResizeDetector from 'react-resize-detector';
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';
import { Table, TableCell, Thead } from '../../../../../../components/Table';
import RightTableSizer from '../../../../../../components/Table/components/RightTableSizer';
import StickyHeaderSection from '../../../../../../components/Table/components/StickyHeaderSection/StickyHeaderSection';
import useStores from '../../../../../../hooks/useStores';
import { ColorGradeItem, ExteriorColorsChangeTypeMap, InteriorColorResponse } from '../../../../../../models/colors.model';
import { VehicleModelItem, VehicleModelToyota } from '../../../../../../models/vehicleModel.model';
import {
  ColorsInteriorBodyRow,
  ColorsInteriorCellSpacer,
  ColorsInteriorHeaderCell,
  ColorsInteriorHeaderDetail,
  ColorsInteriorHeaderRow,
  ColorsInteriorModelCodeRow,
  ColorsInteriorTrimHeaderName,
} from '../ColorsInteriorTable/ColorsInteriorTable';
import styles from './colorAccessibilityTable.module.scss';

type ColorAccessibilityTableProps = {
  maps: ExteriorColorsChangeTypeMap[];
  gradeItems: ColorGradeItem[];
  selectedInteriorColor?(item: InteriorColorResponse): void;
  vehicleModels?: VehicleModelItem<VehicleModelToyota>[];
};

const ColorAccessibilityTable = ({ maps, gradeItems, selectedInteriorColor = () => {}, vehicleModels }: ColorAccessibilityTableProps) => {
  const { tableSizeStore } = useStores();

  const ref = useRef<HTMLDivElement>(null);
  const [leftPosition, setLeftPosition] = useState(0);

  const onResize = (width: number = 0, height: number = 0) => {
    tableSizeStore.tableRowHeight = height;
  };

  const getModelCodes = (gradeItem: ColorGradeItem) => {
    const modelIds: string[] = [];
    const modelCodesList: string[] = [];

    gradeItem.interiorItems.forEach(item => {
      Object.entries(item.modelApplicability).forEach(entry => {
        const [key, value] = entry;
        if (gradeItem.grade.id === key) {
          Object.keys(value).forEach(key => {
            if (!modelIds.includes(key)) {
              modelIds.push(key);
            }
          });
        }
      });
    });

    modelIds.forEach(modelId => {
      const findModel = vehicleModels?.find(model => model.id === modelId && !modelCodesList.includes(model.getVal('code')));
      modelCodesList.push(findModel && findModel.getVal('code'));
    });

    return modelCodesList.map(function (code) {
      return ` (${code}) `;
    });
  };

  useEffect(() => {
    setLeftPosition(ref.current?.getBoundingClientRect().left || 0);
  }, [ref]);

  return (
    <ScrollSync>
      <RightTableSizer>
        <StickyHeaderSection hasFilters>
          <ScrollSyncPane group="horizontal">
            <div ref={ref} style={{ overflowX: 'auto' }}>
              <Table>
                <Thead>
                  <tr>
                    {gradeItems.map(gradeItem => (
                      <React.Fragment key={gradeItem.uid}>
                        <ColorsInteriorHeaderCell colSpan={gradeItem.interiorItems.length}>
                          <ColorsInteriorTrimHeaderName>{gradeItem.grade.value}</ColorsInteriorTrimHeaderName>
                          <ColorsInteriorModelCodeRow
                            modelCodes={getModelCodes(gradeItem)}
                            interiorItemsLength={gradeItem.interiorItems.length}
                            leftInititalPosition={leftPosition}
                          />
                          <ColorsInteriorHeaderRow>
                            {gradeItem.interiorItems.map((interior, index) => (
                              <ColorsInteriorHeaderDetail
                                key={`${gradeItem.grade.id}${interior.code}`}
                                interiorName={interior.name}
                                interiorCode={interior.code}
                                rejectNotes={interior.rejectNotes}
                                isExtraCost={interior.isExtraCost}
                                onClick={() => selectedInteriorColor(interior)}
                              />
                            ))}
                          </ColorsInteriorHeaderRow>
                        </ColorsInteriorHeaderCell>
                        <ColorsInteriorCellSpacer />
                      </React.Fragment>
                    ))}
                  </tr>
                </Thead>
              </Table>
            </div>
          </ScrollSyncPane>
          <ResizeDetector handleHeight onResize={onResize} targetDomEl={ref === null ? undefined : (ref.current as HTMLElement)} />
        </StickyHeaderSection>
        <ScrollSyncPane group="horizontal">
          <div style={{ overflowX: 'scroll' }}>
            <Table>
              <tbody>
                {maps.map(colorMap => (
                  <ColorsInteriorBodyRow zebra key={colorMap.id}>
                    {gradeItems.map(gradeItem => (
                      <React.Fragment key={`${colorMap.id}${gradeItem.uid}`}>
                        {gradeItem.interiorItems.map(interiorItem => {
                          const beforeVal: true | undefined =
                            colorMap.colorApplicability.before[gradeItem.grade.id] && colorMap.colorApplicability.before[gradeItem.grade.id][interiorItem.id];
                          const afterVal: true | undefined =
                            colorMap.colorApplicability.after[gradeItem.grade.id] && colorMap.colorApplicability.after[gradeItem.grade.id][interiorItem.id];
                          let className = '';
                          if (colorMap.colorApplicability.hasChanged) {
                            if (colorMap.isDeleted) {
                              className = styles.changeRemoved;
                            } else if (colorMap.isNew || afterVal !== beforeVal) {
                              className = styles.changeAdded;
                            }
                          }
                          return (
                            <TableCell colType="applicability" center>
                              <div className={className}>{afterVal ? 'Applicable' : 'N/A'}</div>
                            </TableCell>
                          );
                        })}
                        <ColorsInteriorCellSpacer />
                      </React.Fragment>
                    ))}
                  </ColorsInteriorBodyRow>
                ))}
              </tbody>
            </Table>
          </div>
        </ScrollSyncPane>
      </RightTableSizer>
    </ScrollSync>
  );
};

export default observer(ColorAccessibilityTable);
