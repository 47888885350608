import { AxiosResponse } from 'axios';
import { action, computed, observable, makeObservable } from 'mobx';
import { convertToRichTextObject } from 'vapi-ui-common';
import { BnPCategories, BnPCategoriesMap } from '../../models/buildAndPrice.model';
import { KeyValueType } from '../../models/common.model';
import { RefItem } from '../../models/refItem.model';
import { Language, LanguagePermissions } from '../../models/user.model';
import { VehicleDataVersionInfo, VehicleTeam } from '../../models/vehicleData.model';
import { ModelLangMap } from '../../models/vehicleModel.model';
import { toLowerCase } from '../../utils';
import parseLangWriteMap from '../../utils/languageUtils';
import { bnpItemMapXForm } from '../../utils/vehicleDataUtils';
import { getItems } from '../../webservices/vehicleBnPApi';
import { getModelMaps } from '../../webservices/vehicleModelsApi';

export class BnPStore {
  // langs
  langWriteMap: LanguagePermissions = {};
  allLangs: Language[] = [];
  editableLangs: Language[] = [];
  defaultLang: Language = Language.EN;
  defaultEditLang: Language = Language.EN;
  fullEditPermissions: boolean = false;

  selectedLangsMap: KeyValueType<boolean> = {};
  modelLangMap: ModelLangMap = {};

  data: BnPCategories[] = [];
  categories: string[] = [];
  searchText = '';
  categoryFilters: string[] = [];
  viewModelCodes = false;
  isInProgressFilter = false;

  fetchData = async (
    brand: string,
    team: VehicleTeam,
    seriesId: string,
    year: string,
    grades: RefItem[],
    langWriteMap: LanguagePermissions,
    versionInfo: VehicleDataVersionInfo
  ) => {
    this.reset();
    const {
      allLangs,
      editableLangs,
      defaultLang,
      selectedLangsMap,
      fullEditPermissions,
      defaultEditLang,
    } = parseLangWriteMap(langWriteMap);
    this.langWriteMap = langWriteMap;
    this.allLangs = allLangs;
    this.editableLangs = editableLangs;
    this.defaultLang = defaultLang;
    this.selectedLangsMap = selectedLangsMap;
    this.fullEditPermissions = fullEditPermissions;
    this.defaultEditLang = defaultEditLang;

    const promises: Promise<AxiosResponse<any>>[] = [];
    promises.push(getModelMaps(brand, team, seriesId, year, versionInfo));
    this.allLangs.forEach(lang => {
      const upperLang = lang.toUpperCase();
      promises.push(
        getItems(brand, team, seriesId, year, upperLang, versionInfo[lang]?.toString())
      );
    });
    const responses = await Promise.all(promises);
    this.modelLangMap = responses[0].data;

    const bnpCatMap: BnPCategoriesMap = {};
    let index = 1;
    for (const lang of this.allLangs) {
      bnpItemMapXForm(responses[index].data, bnpCatMap, this.modelLangMap, lang, grades);
      index += 1;
    }

    this.data = Object.values(bnpCatMap).map(cat => {
      return {
        ...cat,
        langMaps: Object.values(cat.categoryItemMap),
        categoryItemMap: {},
        items: [],
      };
    });
  };

  constructor() {
    makeObservable(this, {
      selectedLangsMap: observable,
      modelLangMap: observable,
      data: observable,
      categories: observable,
      searchText: observable,
      categoryFilters: observable,
      viewModelCodes: observable,
      isInProgressFilter: observable,
      fetchData: action,
      filteredData: computed,
      reset: action
    });
  }

  get filteredData() {
    const lowerSearchText = toLowerCase(this.searchText);
    return this.data.filter(category => {
      if (
        toLowerCase(category.name).includes(lowerSearchText) ||
        toLowerCase(category.notes).includes(lowerSearchText)
      ) {
        return true;
      }
      for (const langMap of category.langMaps) {
        for (const lang of this.allLangs) {
          const item = langMap[lang];
          if (lowerSearchText) {
            const valuesToCheck: string[] = [
              item.categoryValue,
              item.label,
              convertToRichTextObject(item.description).text,
            ];
            for (const val of valuesToCheck) {
              if (toLowerCase(val).includes(lowerSearchText)) {
                return true;
              }
            }
          }
        }
      }
      return !lowerSearchText;
    });
  }

  updateSelectedLangs = (lang: string, isSelected: boolean) => {
    const selectedMap = JSON.parse(JSON.stringify(this.selectedLangsMap));
    if (selectedMap[lang] != null) {
      selectedMap[lang] = isSelected;
    }
    this.selectedLangsMap = selectedMap;
  };

  reset() {
    this.data = [];
    this.categories = [];
    this.searchText = '';
    this.categoryFilters = [];
    this.viewModelCodes = false;
    this.isInProgressFilter = false;
    this.modelLangMap = {};
    this.selectedLangsMap = {};
  }
}
