import cx from 'clsx';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Input } from 'vapi-ui-common';
import useStores from '../../../../../../../../hooks/useStores';
import { VehicleModelItem, VehicleModelToyota } from '../../../../../../../../models/vehicleModel.model';
import styles from '../../../../modelsModal.module.scss';
import Label from '../Label';

interface DriveProps {
  vmId: string;
  model: VehicleModelItem<VehicleModelToyota>;
}

const Drive = observer(({ vmId, model }: DriveProps) => {
  const {
    modelTabStore: { languages: checkboxLanguages, readOnly, getDrive, setDrive, getActiveLanguagesAmount, getLangVehicleModel },
    userStore: { langPermissions },
    teamStore: {
      team: { languages: teamLanguages },
    },
  } = useStores();

  return (
    <div className={cx(getActiveLanguagesAmount() > 1 ? styles.long : '')}>
      <Label htmlFor={`amm-drive-${vmId}`}>Drive</Label>
      <div className={cx(styles.content)}>
        {teamLanguages.map(language => {
          if (checkboxLanguages[language].selected && langPermissions[language]?.canView) {
            let changedAttributes: string[] = [];
            if (langPermissions[language]?.canEdit) {
              changedAttributes = getLangVehicleModel(model, language).getVal('changedAttributes') ?? [];
            }
            return (
              <Input
                required
                id={`amm-drive-${vmId}-${language}`}
                key={`amm-drive-${vmId}-${language}`}
                value={getDrive(vmId, language)}
                disabled={!langPermissions[language]?.canEdit || readOnly}
                onChange={e => setDrive(vmId, language, e.currentTarget.value)}
                placeholder={checkboxLanguages[language].description}
                className={changedAttributes.includes('drive') ? styles.errorText : ''}
              />
            );
          }

          return null;
        })}
      </div>
    </div>
  );
});

export default Drive;
