import { SeriesCategories } from './vehicleData.model';

export type Brand = 'toyota' | 'lexus' | 'tdpr' | 'engage';
export const BRAND_TOYOTA: Brand = 'toyota';
export const BRAND_LEXUS: Brand = 'lexus';
export const BRAND_TDPR: Brand = 'tdpr';
export const BRAND_ENGAGE: Brand = 'engage';
export const VALID_BRANDS: Brand[] = [BRAND_TOYOTA, BRAND_LEXUS, BRAND_TDPR, BRAND_ENGAGE];

export enum Language {
  EN = 'EN',
  ES = 'ES',
}

export interface UserInfo {
  brand: string;
  groups: UserGroups;
  langPermissions: LanguagePermissions;
  modules: UserModules;
  series: string[];
  objectId: string;
}

export type UserGroups =
  | 'VAPI_toyota_user'
  | 'VAPI_pr_user'
  | 'VAPI_common_language_editor'
  | 'VAPI_product_team_editor'
  | 'VAPI_product_team_reviewer'
  | 'VAPI_product_team_read_only'
  | 'VAPI_agency_team_editor'
  | 'VAPI_lcv_team'
  | 'VAPI_sc_team'
  | 'VAPI_cuv_team'
  | 'VAPI_tsuv_team'
  | 'VAPI_spanish_editor';

export type UserModules = {
  CommonLanguage: UserPermissions;
  ProductTeam: UserVDPermissions;
  AgencyTeam: UserVDPermissions;
  Disclaimers: UserPermissions;
  SeriesManager: UserPermissions;
  SeriesSelections: UserPermissions;
  Spanish: UserPermissions;
};

export interface UserVDPermissions extends UserPermissions {
  series: {
    [SeriesCategories.LARGE_SEDANS_VANS]: UserPermissions;
    [SeriesCategories.SEDANS_SPORTS_CARS]: UserPermissions;
    [SeriesCategories.CROSSOVERS]: UserPermissions;
    [SeriesCategories.TRUCKS_SUVS]: UserPermissions;
  };
}

export type LanguagePermissions = {
  [k in Language]?: UserPermissions;
};

export type UserPermissions = {
  canView?: boolean;
  canViewDraft?: boolean;
  canEdit?: boolean;
  canReview?: boolean;
};
