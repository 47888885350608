import { STATUS_DRAFT, VDStatus } from '../constants/vehicleData/VDConstants';
import {
  BnPAddSplitRequest,
  BnPApplicabilityRequest,
  BnpCategory,
  BnPDeleteSplitRequest,
  BnPDescriptionRequest,
  BnPGradeDetailRequest,
  BnPItemsReviewResponse,
  BnPNameRequest,
  BnPReviewRequest,
} from '../models/buildAndPrice.model';
import { ChangeLogRequest, ChangeLogResponse } from '../models/changeLog.model';
import { ReviewChangeRequest } from '../models/review.model';
import { VehicleTeam } from '../models/vehicleData.model';
import API from '../webservices/api';

const RESOURCE_PATH = '/vehicle-bnp';
const BNP_PATH = '/bnp';
const REVIEW_PATH = '/review';

// BnP
export const getItems = (
  brand: string,
  team: VehicleTeam,
  series: string,
  year: string,
  lang: string,
  version?: string
) => {
  const params =
    version && version !== VDStatus.DRAFT.toUpperCase() ? `version=${version}` : STATUS_DRAFT;
  return API.get(
    `${RESOURCE_PATH}${BNP_PATH}/${brand}/${team}/${series}/${year}/${lang}?${params}`
  );
};

export const updateName = (
  brand: string,
  team: VehicleTeam,
  series: string,
  year: string,
  lang: string,
  payload: BnPNameRequest
) => {
  return API.put(
    `${RESOURCE_PATH}${BNP_PATH}/${brand}/${team}/${series}/${year}/${lang}/name`,
    payload
  );
};

export const updateDescription = (
  brand: string,
  team: VehicleTeam,
  series: string,
  year: string,
  language: string,
  payload: BnPDescriptionRequest | BnPGradeDetailRequest,
  acceptChanges: boolean = false
) => {
  return API.put(
    `${RESOURCE_PATH}${BNP_PATH}/${brand}/${team}/${series}/${year}/${language}/description`,
    { ...payload, acceptChanges }
  );
};

export const updateGradeDetail = (
  brand: string,
  team: VehicleTeam,
  series: string,
  year: string,
  language: string,
  payload: BnPGradeDetailRequest
) => {
  return API.put(
    `${RESOURCE_PATH}${BNP_PATH}/${brand}/${team}/${series}/${year}/${language}/titlecopy`,
    payload
  );
};

export const updateApplicability = (
  brand: string,
  team: VehicleTeam,
  series: string,
  year: string,
  language: string,
  payload: BnPApplicabilityRequest
) => {
  return API.put(
    `${RESOURCE_PATH}${BNP_PATH}/${brand}/${team}/${series}/${year}/${language}/applicability`,
    payload
  );
};

// Splits
export const addCategorySplit = (
  brand: string,
  team: VehicleTeam,
  series: string,
  year: string,
  language: string,
  payload: BnPAddSplitRequest
) => {
  return API.put<BnpCategory>(
    `${RESOURCE_PATH}${BNP_PATH}/${brand}/${team}/${series}/${year}/${language}/add-split`,
    payload
  );
};

export const deleteCategorySplit = (
  brand: string,
  team: VehicleTeam,
  series: string,
  year: string,
  language: string,
  payload: BnPDeleteSplitRequest
) => {
  return API.put(
    `${RESOURCE_PATH}${BNP_PATH}/${brand}/${team}/${series}/${year}/${language}/delete-split`,
    payload
  );
};

// ChangeLog
export const getChangeLog = (
  brand: string,
  team: VehicleTeam,
  series: string,
  year: string,
  language: string,
  version?: string
) => {
  const params = !version || version === 'DRAFT' ? STATUS_DRAFT : `version=${version}`;
  return API.get<ChangeLogResponse[]>(
    `${RESOURCE_PATH}${BNP_PATH}/${brand}/${team}/${series}/${year}/${language}/change-log?${params}`
  );
};

export const revertChange = (
  brand: string,
  team: VehicleTeam,
  series: string,
  year: string,
  language: string,
  payload: ChangeLogRequest
) => {
  return API.put(
    `${RESOURCE_PATH}${BNP_PATH}/${brand}/${team}/${series}/${year}/${language}/change-log/revert`,
    payload
  );
};

// Review
export const getReviewItems = (
  brand: string,
  team: VehicleTeam,
  series: string,
  year: string,
  version?: string
) => {
  const params = !version || version === 'DRAFT' ? STATUS_DRAFT : `version=${version}`;
  return API.get<BnPItemsReviewResponse[]>(
    `${RESOURCE_PATH}${REVIEW_PATH}${BNP_PATH}/${brand}/${team}/${series}/${year}?${params}`
  );
};

export const updateReviewBnP = (
  brand: string,
  team: VehicleTeam,
  series: string,
  year: string,
  version: string,
  payload: ReviewChangeRequest
) => {
  const params = !version || version === 'DRAFT' ? STATUS_DRAFT : `version=${version}`;
  return API.put<BnPReviewRequest>(
    `${RESOURCE_PATH}${REVIEW_PATH}${BNP_PATH}/${brand}/${team}/${series}/${year}?${params}`,
    payload
  );
};
