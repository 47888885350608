import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { toast } from 'react-toastify';
import ChangeLogInner from '../../../../components/ChangeLogInner';
import useStores from '../../../../hooks/useStores';
import { ChangeLogItem, ChangeLogLangMap, ChangeLogResponse, ChangeLogTypes } from '../../../../models/changeLog.model';
import { KeyValueType } from '../../../../models/common.model';
import { Language } from '../../../../models/user.model';
import { changeLogHandleAddedTypes, changeLogHandleDeleteTypes, changeLogIdMapper, changeLogModelApplicabilityMapper } from '../../../../utils/changeLogUtils';
import { handleErrorResponse } from '../../../../utils/errorHandlingUtils';
import { filterOutUnsuableChanges } from '../../../../utils/gradeApplicabilityUtils';
import { getCategoriesByLang, getChangeLog, revertChange } from '../../../../webservices/vehicleOptionsApi';

interface OptionsChangeLogProps {
  seriesId: string;
  year: string;
  readOnly?: boolean;
  version: string;
}

const OptionsChangeLog = ({ seriesId, year, readOnly, version }: OptionsChangeLogProps) => {
  const {
    userStore: { brand },
    teamStore,
    changeLogStore,
    optionsStore,
    vehicleModelsStore,
  } = useStores();

  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    changeLogStore.reset();

    (async () => {
      setIsLoaded(false);

      try {
        const selectedLangs: KeyValueType<boolean> = {};
        teamStore.team.changeLogLanguages.forEach(lang => {
          selectedLangs[lang] = true;
        });
        changeLogStore.selectedLangsMap = selectedLangs;

        const promises: Promise<any>[] = [];
        for (const lang of teamStore.team.changeLogLanguages) {
          const l = lang.toUpperCase();
          promises.push(
            getChangeLog(brand, teamStore.team.param, seriesId, year, version, l),
            getCategoriesByLang(brand, teamStore.team.param, seriesId, year, l, version, true), // fetch the delete records as well
          );
        }
        const responses = await Promise.all(promises);
        let index = 0;
        const changeLogLangMap: ChangeLogLangMap = {};
        for (const lang of teamStore.team.changeLogLanguages) {
          const baseIndex = index * 2;
          const logs = (responses[baseIndex].data as ChangeLogResponse[]).map(item => new ChangeLogItem(item, !readOnly, lang)).filter(filterOutUnsuableChanges);

          optionsStore.updateCategoriesLangMap(lang, optionsStore.categoriesMap, responses[baseIndex + 1].data);

          changeLogIdMapper(optionsStore.getCategoriesForLang(lang, optionsStore.categoriesMap), logs, ChangeLogTypes.CATEGORY);
          changeLogHandleDeleteTypes(logs, ChangeLogTypes.OPTION_DELETED);
          changeLogHandleAddedTypes(logs, ChangeLogTypes.OPTION_ADDED);
          changeLogModelApplicabilityMapper(vehicleModelsStore.vehicleModels, logs);
          changeLogLangMap[lang] = logs;

          index += 1;
        }

        changeLogStore.changeLogLangMap = changeLogLangMap;
      } catch (e) {
        toast.error('Error loading options change log');
      }

      setIsLoaded(true);
    })();
  }, [brand, changeLogStore, readOnly, seriesId, teamStore, year, optionsStore, version, vehicleModelsStore]);

  const handleOnRevert = async (item: ChangeLogItem) => {
    setIsLoaded(false);
    try {
      const changeLogLangMap: ChangeLogLangMap = JSON.parse(JSON.stringify(changeLogStore.changeLogLangMap));
      const language = item.language ?? Language.EN;
      await trackPromise(revertChange(brand, teamStore.team.param, seriesId, year, language, item.payload));
      const response = await getChangeLog(brand, teamStore.team.param, seriesId, year, version, language);
      const logs = response.data.map(item => new ChangeLogItem(item, !readOnly, language));
      changeLogIdMapper(optionsStore.getCategoriesForLang(language, optionsStore.categoriesMap), logs, ChangeLogTypes.CATEGORY);
      changeLogHandleAddedTypes(logs, ChangeLogTypes.OPTION_ADDED);
      changeLogHandleDeleteTypes(logs, ChangeLogTypes.OPTION_DELETED);
      changeLogModelApplicabilityMapper(vehicleModelsStore.vehicleModels, logs);
      changeLogLangMap[language] = logs;
      changeLogStore.changeLogLangMap = changeLogLangMap;

      toast.success('Change log reverted');
    } catch (e) {
      handleErrorResponse(e, 'Error reverting change log');
    }
    setIsLoaded(true);
  };
  return <ChangeLogInner module={'Option'} isLoaded={isLoaded} handleOnRevert={handleOnRevert} />;
};

export default observer(OptionsChangeLog);
