import {
  faArrowsSplitUpAndLeft,
  faChevronDown,
  faChevronUp,
  faCircle,
  faCopy,
  faDownload,
  faFlag,
  faPen,
  faPlus,
  faRandom,
  faThumbsUp,
  faTimesCircle,
  faTrash,
  faUndo,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'clsx';
import React from 'react';
import styles from './iconTextButton.module.scss';

// think about just passing through icon name directly rather than mapping,
// and matching class to same icon name. Switch will then become unnecessary.
const renderIcon = (icon: string, className: string = '') => {
  switch (icon) {
    case 'flag': {
      return <FontAwesomeIcon className={styles.iconFlag} icon={faFlag} />;
    }
    case 'plus': {
      return <FontAwesomeIcon className={styles.iconPlus} icon={faPlus} />;
    }
    case 'undo': {
      return <FontAwesomeIcon className={styles.iconUndo} icon={faUndo} />;
    }
    case 'circle': {
      return <FontAwesomeIcon className={styles.iconCircle} icon={faCircle} />;
    }
    case 'redCircle': {
      return <FontAwesomeIcon className={styles.redIconCircle} icon={faCircle} />;
    }
    case 'purpleCircle': {
      return <FontAwesomeIcon className={styles.purpleIconCircle} icon={faCircle} />;
    }
    case 'random': {
      return <FontAwesomeIcon className={styles.iconRandom} icon={faRandom} />;
    }
    case 'remove': {
      return <FontAwesomeIcon className={styles.iconRemove} icon={faTimesCircle} />;
    }
    case 'trash': {
      return <FontAwesomeIcon className={styles.iconTrash} icon={faTrash} />;
    }
    case 'edit': {
      return <FontAwesomeIcon className={cx(styles.iconEdit, className)} icon={faPen} />;
    }
    case 'duplicate': {
      return <FontAwesomeIcon className={styles.iconDuplicate} icon={faCopy} />;
    }
    case 'download': {
      return <FontAwesomeIcon className={styles.iconDownload} icon={faDownload} />;
    }
    case 'thumbsUp': {
      return <FontAwesomeIcon className={cx(styles.iconDownload, className)} icon={faThumbsUp} />;
    }

    case 'chevronDown': {
      return (
        <FontAwesomeIcon className={cx(styles.iconChevronDown, className)} icon={faChevronDown} />
      );
    }
    case 'chevronUp': {
      return <FontAwesomeIcon className={cx(styles.iconChevronUp, className)} icon={faChevronUp} />;
    }
    case 'split': {
      return (
        <FontAwesomeIcon
          className={cx(styles.iconSplit, className)}
          icon={faArrowsSplitUpAndLeft}
          rotation={90}
        />
      );
    }
    default: {
      return null;
    }
  }
};

interface IconTextButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  icon:
    | 'flag'
    | 'plus'
    | 'undo'
    | 'circle'
    | 'redCircle'
    | 'purpleCircle'
    | 'remove'
    | 'edit'
    | 'duplicate'
    | 'download'
    | 'random'
    | 'thumbsUp'
    | 'trash'
    | 'chevronDown'
    | 'chevronUp'
    | 'split';
  text: string;
  smallIcon?: boolean;
  iconClassName?: string;
  textClassName?: string;
}

const IconTextButton = (props: IconTextButtonProps) => {
  const { className, icon, text, smallIcon, iconClassName, textClassName, ...rest } = props;

  return (
    <button className={cx([styles.button, className])} {...rest}>
      {icon && (
        <span
          className={cx([
            {
              [styles.icon]: true,
              [styles.smallIcon]: smallIcon,
            },
          ])}
        >
          {renderIcon(icon, iconClassName)}
        </span>
      )}
      <span className={cx(textClassName)}>{text}</span>
    </button>
  );
};

export default IconTextButton;
