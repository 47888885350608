import React from 'react';
import { Slide, ToastContainer } from 'react-toastify';
import styles from './toast.module.scss';

export const Toast = () => (
  <section className={styles.toast} data-testid="toast">
    <ToastContainer closeButton={<CloseToastButton />} draggable={false} position="top-center" hideProgressBar transition={Slide} />
  </section>
);

const CloseToastButton = ({ closeToast }: any) => <button className={styles.toastClose} onClick={closeToast} />;
