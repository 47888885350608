import { action, makeObservable, observable } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import {
  ColorGradeLangItem,
  ColorItem,
  ColorItemLangMap,
  ColorItemMap,
  ExteriorColorMap,
  ExteriorColorResponse,
  InteriorColorMap,
  InteriorColorResponse,
  InteriorItem,
} from '../../models/colors.model';
import { KeyValueType } from '../../models/common.model';
import { RefItem } from '../../models/refItem.model';
import { Language, LanguagePermissions } from '../../models/user.model';
import { VehicleDataVersionInfo, VehicleTeam } from '../../models/vehicleData.model';
import { isSortReverse, sortBy, toLowerCase } from '../../utils';
import { colorExteriorLangXform, colorGradeLangXform } from '../../utils/colorUtils';
import parseLangWriteMap from '../../utils/languageUtils';
import { getExteriorColors, getInteriorColors } from '../../webservices/vehicleColorsApi';

class ColorsStore {
  colorItemLangMaps: ColorItemLangMap[] = [];
  reverseSort = false;
  sortField = 'id';
  searchText = '';
  isInProgressFilter = false;
  isSyncUpdateFilter = false;
  isReviewNotesFilter = false;
  isExtraCostColorFilter = false;
  // langs
  langWriteMap: LanguagePermissions = {};
  allLangs: Language[] = [];
  editableLangs: Language[] = [];
  defaultLang: Language = Language.EN;
  modelApplicabilityLang: Language = Language.EN;
  hasEnglishWritePerms: boolean = false;

  selectedLangsMap: KeyValueType<boolean> = {};
  viewModelCodes = false;
  filteredColorItemLangMaps: ColorItemLangMap[] = [];
  colorGradeLangItems: ColorGradeLangItem[] = [];

  fetchData = async (
    brand: string,
    team: VehicleTeam,
    seriesId: string,
    year: string,
    grades: RefItem[],
    langWriteMap: LanguagePermissions,
    versionInfo: VehicleDataVersionInfo,
  ) => {
    this.reset();
    const { allLangs, editableLangs, defaultLang, selectedLangsMap, modelApplicabilityLang, hasEnglishWritePerms } = parseLangWriteMap(langWriteMap);
    this.langWriteMap = langWriteMap;
    this.allLangs = allLangs;
    this.editableLangs = editableLangs;
    this.defaultLang = defaultLang;
    this.selectedLangsMap = selectedLangsMap;
    this.hasEnglishWritePerms = hasEnglishWritePerms;
    this.modelApplicabilityLang = modelApplicabilityLang;

    const promises: Promise<any>[] = [];
    this.allLangs.forEach(lang => {
      promises.push(
        getInteriorColors(brand, team, seriesId, year, lang, versionInfo[lang]?.toString()),
        getExteriorColors(brand, team, seriesId, year, lang, versionInfo[lang]?.toString()),
      );
    });
    const responses = await Promise.all(promises);

    const interiorColorMap: InteriorColorMap = { colors: {} };
    const exteriorColorsMap: ExteriorColorMap = { colors: {} };

    let index = 0;
    for (const lang of this.allLangs) {
      this.createInteriorColorLangMap(lang, responses[index].data, interiorColorMap);
      this.createExteriorColorMap(lang, responses[index + 1].data, exteriorColorsMap);
      index += 2;
    }
    this.finishInteriorColorMap(interiorColorMap);
    this.finishExteriorColorMap(exteriorColorsMap);

    this.colorGradeLangItems = colorGradeLangXform(Object.values(interiorColorMap.colors), grades);

    this.setColorMaps(colorExteriorLangXform(Object.values(exteriorColorsMap.colors), this.colorGradeLangItems));
  };

  createInteriorColorLangMap = (lang: string, data: InteriorColorResponse[], interiorColorMap: InteriorColorMap) => {
    data.forEach(color => {
      if (!interiorColorMap.colors[color.id]) {
        interiorColorMap.colors[color.id] = { langs: {}, defaultData: color };
      }
      interiorColorMap.colors[color.id].langs[lang] = color;
    });
  };

  finishInteriorColorMap = (interiorColorMap: InteriorColorMap) => {
    Object.keys(interiorColorMap.colors).forEach(colorId => {
      this.allLangs.forEach(lang => {
        if (!interiorColorMap.colors[colorId].langs[lang]) {
          const defaultData = interiorColorMap.colors[colorId].defaultData;
          interiorColorMap.colors[colorId].langs[lang] = {
            id: colorId,
            revId: '',
            name: '',
            code: defaultData.code,
            rejectNotes: defaultData.rejectNotes,
            isExtraCost: defaultData.isExtraCost,
            modelApplicability: defaultData.modelApplicability,
          };
        }
      });
    });
  };

  createExteriorColorMap = (lang: string, data: ExteriorColorResponse[], exteriorColorsMap: ExteriorColorMap) => {
    data.forEach(color => {
      if (!exteriorColorsMap.colors[color.id]) {
        exteriorColorsMap.colors[color.id] = { langs: {}, defaultData: color };
      }
      exteriorColorsMap.colors[color.id].langs[lang] = color;
    });
  };

  finishExteriorColorMap = (exteriorColorsMap: ExteriorColorMap) => {
    Object.keys(exteriorColorsMap.colors).forEach(colorId => {
      this.allLangs.forEach(lang => {
        if (!exteriorColorsMap.colors[colorId].langs[lang]) {
          const defaultData = exteriorColorsMap.colors[colorId].defaultData;
          exteriorColorsMap.colors[colorId].langs[lang] = {
            id: defaultData.id,
            revId: '',
            name: '',
            code: defaultData.code,
            hexCode: defaultData.hexCode,
            colorApplicability: defaultData.colorApplicability,
            isExtraCost: defaultData.isExtraCost,
            isInProgress: defaultData.isInProgress,
            notes: defaultData.notes,
          };
        }
      });
    });
  };

  setColorMaps = (colorItemMap: ColorItemMap) => {
    const colorLangMaps: ColorItemLangMap[] = colorItemMap.order.map(id => colorItemMap.colors[id]);
    this.colorItemLangMaps = colorLangMaps;
    this.filteredColorItemLangMaps = colorLangMaps;
  };

  addItem = () => {
    const newLangMap: ColorItemLangMap = { langs: {} };
    const id = uuidv4();
    this.allLangs.forEach(lang => {
      const newItem = new ColorItem();
      newItem.id = id;
      if (this.colorGradeLangItems.length) {
        this.colorGradeLangItems.forEach(gradeItem => {
          newItem.interiorApplicability[gradeItem.grade.id] = gradeItem.interiorItems.map(intItem => new InteriorItem(gradeItem.grade, intItem.langs[lang], false));
        });
      }
      newLangMap.langs[lang] = newItem;
    });

    this.colorItemLangMaps = [newLangMap, ...this.colorItemLangMaps];
    this.filteredColorItemLangMaps = [newLangMap, ...this.filteredColorItemLangMaps];
  };

  deleteItem = (uid: string) => {
    this.colorItemLangMaps = this.colorItemLangMaps.filter(langMap => langMap.langs[this.defaultLang].uid !== uid);
    this.filteredColorItemLangMaps = this.filteredColorItemLangMaps.filter(langMap => langMap.langs[this.defaultLang].uid !== uid);
  };

  getDefaultColorItems = (colorItemLangMap: ColorItemLangMap[]) => {
    return colorItemLangMap.map(langMap => langMap.langs[this.defaultLang]);
  };

  getColorItemMap = () => {
    const colorItemMap: ColorItemMap = { colors: {}, order: [] };
    this.colorItemLangMaps.forEach(langMap => {
      const id = langMap.langs[this.defaultLang].id;
      colorItemMap.colors[id] = langMap;
      colorItemMap.order.push(id);
    });
    return colorItemMap;
  };

  onSort = (field: string) => {
    this.reverseSort = isSortReverse(this.sortField, field, this.reverseSort);
    this.sortField = field;
    let colors = this.getDefaultColorItems(this.colorItemLangMaps);
    colors = colors.sort(sortBy(this.sortField, this.reverseSort));
    const colorsMap = this.getColorItemMap();
    colorsMap.order = [];
    colors.forEach(color => {
      colorsMap.order.push(color.id);
    });
    this.setColorMaps(colorsMap);
  };

  onFilter = (filterAction: () => void) => {
    filterAction();
    this.filteredColorItemLangMaps = this.filterLangMaps();
  };

  filterLangMaps = () => {
    const lowerSearchText = toLowerCase(this.searchText);
    return this.colorItemLangMaps.slice().filter(featureLangMap => {
      let checked = false;
      let hasChangedAttributes = false;

      for (let lang of this.allLangs) {
        const color = featureLangMap.langs[lang];
        if (!checked) {
          checked = true;
          if (this.isInProgressFilter && !color.isInProgress) {
            return false;
          }
          if (this.isReviewNotesFilter && color.rejectNotes.length === 0) {
            return false;
          }
          if (this.isExtraCostColorFilter && !color.isExtraCost) {
            return false;
          }
        }

        if (color.changedAttributes.length) {
          hasChangedAttributes = true;
        }

        if (lowerSearchText) {
          const valuesToCheck: string[] = [color.code, color.hexCode, color.name, color.notes];
          for (let val of valuesToCheck) {
            if (toLowerCase(val).includes(lowerSearchText)) {
              return true;
            }
          }
        }
      }

      if (this.isSyncUpdateFilter && !hasChangedAttributes) {
        return false;
      }

      return !lowerSearchText;
    });
  };

  updateSelectedLangs = (lang: string, isSelected: boolean) => {
    const selectedMap = JSON.parse(JSON.stringify(this.selectedLangsMap));
    if (selectedMap[lang] != null) {
      selectedMap[lang] = isSelected;
    }
    this.selectedLangsMap = selectedMap;
  };

  selectedLangs = () => {
    return this.allLangs.filter(lang => this.selectedLangsMap[lang]);
  };

  resetFilters = () => {
    this.searchText = '';
    this.isInProgressFilter = false;
    this.isSyncUpdateFilter = false;
    this.isExtraCostColorFilter = false;
    this.isReviewNotesFilter = false;
    this.filteredColorItemLangMaps = this.colorItemLangMaps.slice();
  };

  constructor() {
    makeObservable(this, {
      selectedLangsMap: observable,
      viewModelCodes: observable,
      filteredColorItemLangMaps: observable,
      colorGradeLangItems: observable,
      fetchData: action,
      resetFilters: action,
      reset: action,
    });
  }

  hasChangedAttributes() {
    return !!this.colorItemLangMaps.filter(langMap => !!this.editableLangs.filter(lang => !!langMap.langs[lang].changedAttributes.length).length).length;
  }

  reset() {
    this.reverseSort = false;
    this.sortField = 'id';
    this.searchText = '';
    this.isInProgressFilter = false;
    this.isSyncUpdateFilter = false;
    this.isExtraCostColorFilter = false;
    this.isReviewNotesFilter = false;
    this.viewModelCodes = false;
    this.colorItemLangMaps = [];
    this.filteredColorItemLangMaps = [];
    this.colorGradeLangItems = [];
    this.selectedLangsMap = {};
    this.allLangs = [];
  }
}

export default ColorsStore;
