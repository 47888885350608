import { action, makeObservable, observable, override } from "mobx";
import BaseReviewStore from "./baseReviewStore";

class BaseColorsReviewStore extends BaseReviewStore {
  isExtraCostColorFilter = false;

  setSort = (sortField: string) => {
    this.reverse = this.sortField !== sortField ? false : !this.reverse;
    this.sortField = sortField;
  };

  constructor() {
    // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
    super();

    makeObservable(this, {
      isExtraCostColorFilter: observable,
      setSort: action,
      reset: override,
    });
  }

  reset() {
    super.reset();
    this.isExtraCostColorFilter = false;
  }
}

export default BaseColorsReviewStore;
