import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, DropdownList } from 'vapi-ui-common';
import Header from '../../../../components/Header';
import SecondaryHeader from '../../../../components/SecondaryHeader';
import Spinner from '../../../../components/Spinner';
import { TabPanel } from '../../../../components/Tabs/Tabs';
import Wayfinding from '../../../../components/Wayfinding';
import useQuery from '../../../../hooks/useQuery';
import useStores from '../../../../hooks/useStores';
import { IDValueType } from '../../../../models/common.model';
import { RefItem } from '../../../../models/refItem.model';
import { Brand, Language } from '../../../../models/user.model';
import { SeriesInfo, VDTab, VehicleDataVersionInfo, VehicleTeam } from '../../../../models/vehicleData.model';
import { VehicleModelItem, VehicleModelLocalStorageItemType, VehicleModelToyota } from '../../../../models/vehicleModel.model';
import BnPChangeLog from '../../../../routes/vehicleData/tabModules/bp/BnPChangeLog';
import ColorsChangeLogController from '../../../../routes/vehicleData/tabModules/colors/ColorsChangeLog';
import CompareFeaturesChangeLog from '../../../../routes/vehicleData/tabModules/compareFeatures/CompareFeaturesChangeLog';
import FeaturesChangeLog from '../../../../routes/vehicleData/tabModules/features/FeaturesChangeLog';
import OptionsChangeLog from '../../../../routes/vehicleData/tabModules/options/OptionsChangeLog';
import SpecsChangeLog from '../../../../routes/vehicleData/tabModules/specs/SpecsChangeLog';
import { transformResponseModels } from '../../../../utils/modelsUtils';
import { getFuelTypes, getSeries } from '../../../../webservices/adminApi';
import { getTeamVersions } from '../../../../webservices/vehicleAdminApi';
import { getGrades, getModels } from '../../../../webservices/vehicleModelsApi';
import ModelsChangeLog from '../../tabModules/models/ModelsChangeLog';
import styles from './ChangeLog.module.scss';

const handleVersionChange = async (
  brand: string,
  teamParam: VehicleTeam,
  seriesId: string,
  year: string,
  language: Language,
  selectedVersion: string,
  setVehicleModels: (fuelTypes: IDValueType[], grades: RefItem[], vehicleModels: VehicleModelItem<VehicleModelToyota>[]) => void,
  setCurrentLog: (selectedVersion: string) => void,
  setCurrentVersionInfo: (versionInfo: VehicleDataVersionInfo) => void,
  localStorageValue: VehicleModelLocalStorageItemType,
) => {
  const responses = await Promise.all([
    getModels({
      brand,
      team: teamParam,
      series: seriesId,
      year,
      version: selectedVersion,
      includeAll: true,
      language,
    }),
    getGrades({
      brand,
      team: teamParam,
      series: seriesId,
      year,
      version: selectedVersion,
      includeAll: true,
      language,
    }),
    getFuelTypes(brand),
  ]); // fetch the delete records as well);

  const { fuelTypes, grades, vehicleModels } = transformResponseModels(brand as Brand, responses[0].data, responses[1].data, responses[2].data, localStorageValue);
  setCurrentVersionInfo({ [language]: selectedVersion });
  setCurrentLog(selectedVersion);
  setVehicleModels(fuelTypes, grades, vehicleModels);
};

const ChangeLog = () => {
  const { userStore, vehicleModelsStore, vehicleSeriesInfoStore, teamStore } = useStores();

  const { brand } = userStore;
  const { team, seriesId, year, versionInfo } = useParams<{
    team: string;
    seriesId: string;
    year: string;
    versionInfo: string;
  }>();

  const history = useHistory();
  const location = useLocation();

  const query = useQuery();
  const tab = query.get('tab') || 'Features';

  const returnParam = query.get('return');

  const [selectedTab, setSelectedTab] = useState(decodeURIComponent(tab));
  const [isLoaded, setIsLoaded] = useState(false);
  const [seriesInfo, setSeriesInfo] = useState({} as SeriesInfo);
  const [isVersionLoaded, setIsVersionLoaded] = useState(false);
  const [logs, setLogs] = useState<string[]>([]);
  const [currentLog, setCurrentLog] = useState('');
  const [readOnly, setReadOnly] = useState(true);
  const [teamParam, setTeamParam] = useState('' as VehicleTeam);
  const [defaultLang, setDefaultLang] = useState('' as Language);
  const [currentVersionInfo, setCurrentVersionInfo] = useState<VehicleDataVersionInfo>({} as VehicleDataVersionInfo);
  let exitChangeLog = '';
  const tabs = teamStore.team.tabs;

  if (returnParam === 'published' && versionInfo) {
    exitChangeLog = `/vehicleData/${returnParam}/${team}/${seriesId}/${year}/${versionInfo}?team=${team}&tab=${encodeURIComponent(selectedTab)}`;
  } else if (returnParam === 'draft' && versionInfo) {
    exitChangeLog = `/vehicleData/${returnParam}/${team}/${seriesId}/${year}/${versionInfo}?team=${team}&tab=${encodeURIComponent(selectedTab)}`;
  }

  useEffect(() => {
    setIsVersionLoaded(false);
    (async () => {
      try {
        if (teamParam && defaultLang) {
          // fetch the versions for the default language
          const versionsResponse = await getTeamVersions(brand, teamParam, seriesId, year, defaultLang);

          const versions = versionsResponse.data;
          const versionsList: string[] = [];

          versions.forEach(item => {
            versionsList.push(item.version);
          });

          setLogs(versionsList);

          const isSubmitted = versions[0].isSubmitted;
          if (isSubmitted) {
            setReadOnly(isSubmitted);
          }

          await handleVersionChange(
            brand,
            teamParam,
            seriesId,
            year,
            defaultLang,
            versionsList[0],
            (fuelTypes, grades, vehicleModels) => {
              vehicleModelsStore.fuelTypes = fuelTypes;
              vehicleModelsStore.grades = grades;
              vehicleModelsStore.vehicleModels = vehicleModels;
            },
            version => setCurrentLog(version),
            versionInfo => setCurrentVersionInfo(versionInfo),
            vehicleModelsStore.getLocalStorage(teamParam),
          );
        }
      } catch (error) {
        toast.error('Error loading versions');
      }
      setIsVersionLoaded(true);
    })();
  }, [vehicleModelsStore, brand, seriesId, year, teamParam, defaultLang, readOnly]);

  useEffect(() => {
    teamStore.setTeam(team as VehicleTeam, brand, userStore.langPermissions);
    userStore.setTeamModule(team as VehicleTeam);
    setTeamParam(teamStore.team.param);
    setDefaultLang(teamStore.team.defaultLanguage);

    (async () => {
      try {
        const responses = await Promise.all([getSeries(brand)]);

        const series = responses[0].data.series[seriesId];
        if (series) {
          setSeriesInfo(series);

          vehicleSeriesInfoStore.seriesId = seriesId;
          vehicleSeriesInfoStore.seriesName = series.name;
          vehicleSeriesInfoStore.seriesGroup = series.group;
          vehicleSeriesInfoStore.year = year;

          // check if user can edit a change log language
          let canEditLanguage = false;
          teamStore.team.changeLogLanguages.forEach(lang => {
            if (teamStore.team.langPermissions[lang]?.canEdit) {
              canEditLanguage = true;
            }
          });

          // set readOnly permissions by group
          if (canEditLanguage) {
            setReadOnly(!userStore.teamModule.series[vehicleSeriesInfoStore.seriesGroup].canEdit);
          }

          setSelectedTab(decodeURIComponent(tab));
        }
      } catch (e: any) {
        if (!e.response || !e.response.data.message.includes('exist')) {
          toast.error('Failed loading change log data');
        }
      }
      setIsLoaded(true);
    })();
  }, [brand, currentLog, tab, seriesId, year, team, teamStore, userStore, vehicleSeriesInfoStore, vehicleModelsStore]);

  return !isLoaded ? (
    <Spinner />
  ) : (
    <>
      <Header moduleTitle={teamStore.team.pageTitle} moduleSubTitle="Change Log" />
      <Wayfinding year={year} seriesName={seriesInfo.name} to={exitChangeLog} />
      <SecondaryHeader
        tabs={tabs.filter(tab => tab.name !== VDTab.SERIES_SETTINGS).map(tab => tab.name)}
        selectedTab={selectedTab}
        setSelectedTab={tab => {
          setSelectedTab(tab);
          history.push(`${location.pathname}?team=${team}&tab=${encodeURIComponent(tab)}&return=${returnParam}`);
        }}
        renderButtons={() => (
          <>
            <NavLink to={exitChangeLog}>
              <Button variant="transparent">Exit Change Log</Button>
            </NavLink>
            <DropdownList
              className={styles.dropdownList}
              value={currentLog}
              list={logs}
              onSelect={(item: string) =>
                handleVersionChange(
                  brand,
                  teamStore.team.param,
                  seriesId,
                  year,
                  defaultLang,
                  item,
                  (fuelTypes, grades, vehicleModels) => {
                    vehicleModelsStore.fuelTypes = fuelTypes;
                    vehicleModelsStore.grades = grades;
                    vehicleModelsStore.vehicleModels = vehicleModels;
                  },
                  version => setCurrentLog(version),
                  versionInfo => setCurrentVersionInfo(versionInfo),
                  vehicleModelsStore.getLocalStorage(team),
                )
              }
            />
          </>
        )}
      />

      {!isVersionLoaded ? (
        <Spinner />
      ) : (
        <>
          <TabPanel tab={VDTab.MODELS} selected={selectedTab === VDTab.MODELS}>
            <ModelsChangeLog seriesId={seriesId} year={year} version={currentLog} versionInfo={currentVersionInfo} readOnly={readOnly || !isNaN(Number(currentLog))} />
          </TabPanel>
          <TabPanel tab={VDTab.FEATURES} selected={selectedTab === VDTab.FEATURES}>
            <FeaturesChangeLog seriesId={seriesId} year={year} version={currentLog} readOnly={readOnly || !isNaN(Number(currentLog))} />
          </TabPanel>
          <TabPanel tab={VDTab.COMPARE_FEATURES} selected={selectedTab === VDTab.COMPARE_FEATURES}>
            <CompareFeaturesChangeLog seriesId={seriesId} year={year} version={currentLog} readOnly={readOnly || !isNaN(Number(currentLog))} />
          </TabPanel>
          <TabPanel tab={VDTab.OPTIONS} selected={selectedTab === VDTab.OPTIONS}>
            <OptionsChangeLog seriesId={seriesId} year={year} version={currentLog} readOnly={readOnly || !isNaN(Number(currentLog))} />
          </TabPanel>
          <TabPanel tab={VDTab.SPECS} selected={selectedTab === VDTab.SPECS}>
            <SpecsChangeLog seriesId={seriesId} year={year} version={currentLog} readOnly={readOnly || !isNaN(Number(currentLog))} />
          </TabPanel>
          {teamStore.team.allowBnP && (
            <TabPanel tab={VDTab.BNP} selected={selectedTab === VDTab.BNP}>
              <BnPChangeLog seriesId={seriesId} year={year} version={currentLog} readOnly={readOnly || !isNaN(Number(currentLog))} />
            </TabPanel>
          )}
          <TabPanel tab={VDTab.COLORS} selected={selectedTab === VDTab.COLORS}>
            <ColorsChangeLogController seriesId={seriesId} year={year} version={currentLog} readOnly={readOnly || !isNaN(Number(currentLog))} />
          </TabPanel>
        </>
      )}
    </>
  );
};

export default observer(ChangeLog);
