import { observer } from 'mobx-react-lite';
import React, { createContext, useRef } from 'react';
import { TableRow } from '../../../../../../components/Table';
import useStores from '../../../../../../hooks/useStores';
import {
  BnPCategories,
  BnPCategoryItem,
  BnPCategoryItemLangMap,
  BnpCategorySplit,
} from '../../../../../../models/buildAndPrice.model';
import { Language } from '../../../../../../models/user.model';
import SplitModelAplicabilityModal, {
  SplitModelApplicabilityModalRefProps,
} from '../SplitModelApplicabilityModal';
import styles from './buildAndPriceRow.module.scss';
import BuildAndPriceRowItem from './BuildAndPriceRowItem';

interface BuildAndPriceRowItemContextProps {
  langMap: BnPCategoryItemLangMap;
  readOnly: boolean;
  categories: BnPCategories;
  onUpdateDescription: (data: {
    item: BnPCategoryItem;
    lang: string;
    acceptChanges?: boolean;
    split?: BnpCategorySplit;
  }) => void;
  onUpdateGradeDetail: (item: BnPCategoryItem, lang: string) => void;
  onUpdateName: (categories: BnPCategories) => void;
  splitModelAplicabilityModalRef: React.MutableRefObject<
    SplitModelApplicabilityModalRefProps | undefined
  >;
  index: number;
  selectedLangs: Language[];
}

export const BuildAndPriceRowItemContext = createContext<BuildAndPriceRowItemContextProps | null>(
  null
);

interface BuildAndPriceRowProps {
  categories: BnPCategories;
  onUpdateDescription: (data: {
    item: BnPCategoryItem;
    lang: string;
    acceptChanges?: boolean;
    split?: BnpCategorySplit;
  }) => void;
  onUpdateGradeDetail: (item: BnPCategoryItem, lang: string) => void;
  onUpdateName: (categories: BnPCategories) => void;
  readOnly?: boolean;
  selectedLangs: Language[];
}

const BuildAndPriceRow = ({
  categories,
  onUpdateDescription,
  onUpdateGradeDetail,
  onUpdateName,
  readOnly = false,
  selectedLangs,
}: BuildAndPriceRowProps) => {
  const splitModelAplicabilityModalRef = useRef<SplitModelApplicabilityModalRefProps>();
  const {
    bnpStore: { defaultLang },
  } = useStores();

  return (
    <>
      {categories.langMaps.map((langMap, index) => {
        const defaultItem = langMap[defaultLang];

        return (
          <BuildAndPriceRowItemContext.Provider
            key={`${defaultItem.categoryId}-BuildAndPriceRowItemContext`}
            value={{
              readOnly,
              langMap,
              categories,
              onUpdateDescription,
              onUpdateGradeDetail,
              onUpdateName,
              splitModelAplicabilityModalRef,
              index,
              selectedLangs,
            }}
          >
            {[
              <BuildAndPriceRowItem key={`${defaultItem.categoryId}-BuildAndPriceRowItem`} />,
              ...defaultItem.splits.map(split => (
                <BuildAndPriceRowItem key={`${defaultItem.categoryId}-${split.id}`} split={split} />
              )),
            ]}
          </BuildAndPriceRowItemContext.Provider>
        );
      })}

      {/* Separator */}
      <TableRow className={styles.grayRow}>
        <td colSpan={2 + selectedLangs.length}></td>
      </TableRow>

      <SplitModelAplicabilityModal ref={splitModelAplicabilityModalRef} />
    </>
  );
};

export default observer(BuildAndPriceRow);
