import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import Checkbox from '../../../../../../../components/Checkbox';
import NotesPopover from '../../../../../../../components/NotesPopover';
import { TableCell } from '../../../../../../../components/Table';
import useStores from '../../../../../../../hooks/useStores';
import { BuildAndPriceRowItemContext } from '../BuildAndPriceRow';
import styles from '../buildAndPriceRow.module.scss';

interface NameCellProps {
  rowSpan: number;
}

const NameCell = ({ rowSpan }: NameCellProps) => {
  const context = useContext(BuildAndPriceRowItemContext);
  const {
    bnpStore: { fullEditPermissions, defaultLang },
  } = useStores();

  if (!context) {
    return null;
  }

  const { categories, readOnly, langMap, onUpdateName } = context;
  const defaultItem = langMap[defaultLang];

  const handleOnIsInProgress = () => {
    categories.isInProgress = !categories.isInProgress;
    onUpdateName(categories);
  };

  const handleOnNotesChange = (notes: string) => {
    if (categories.notes !== notes) {
      categories.notes = notes;
      onUpdateName(categories);
    }
  };

  return (
    <TableCell colType="bpName" className={styles.name} rowSpan={rowSpan}>
      {defaultItem.label}

      {/* Notes */}
      <div className={styles.notesContainer}>
        <NotesPopover
          readOnly={readOnly || !fullEditPermissions}
          notes={categories.notes}
          handleUpdateItem={handleOnNotesChange}
          align="left"
        />
      </div>

      {/* Is In Progress */}
      <div className={styles.statusContainer}>
        <Checkbox
          id={`chboxCatProgress${defaultItem.name}`}
          disabled={readOnly || !fullEditPermissions}
          defaultChecked={categories.isInProgress}
          onChange={handleOnIsInProgress}
        >
          <span className={styles.chkboxLabel}>In Progress</span>
        </Checkbox>
      </div>
    </TableCell>
  );
};

export default observer(NameCell);
