import { observer } from 'mobx-react-lite';
import React from 'react';
import Checkbox from '../../../../../../components/Checkbox';
import inputStyles from '../../../../../../components/Input/input.module.scss';
import { HeaderButton, HeaderRow, LeftTableTH, Thead } from '../../../../../../components/Table';
import useStores from '../../../../../../hooks/useStores';
import { VehicleTeam } from '../../../../../../models/vehicleData.model';
import styles from './featureHeaderRow.module.scss';

type FeatureHeaderRowProps = {
  showDescriptionToggles?: boolean;
  viewModelCodes: boolean;
  allowLinks: boolean;
  showSpecLinkModal: boolean;
  switchShortLongDescription?: boolean;
  readOnly?: boolean;
  onSort?: (field: string, lang?: string) => void;
  sortMode: boolean;
  languages: string[];
  team: string;
};

const FeatureHeaderRow: React.FC<FeatureHeaderRowProps> = ({
  showDescriptionToggles,
  allowLinks,
  showSpecLinkModal,
  switchShortLongDescription,
  readOnly = false,
  onSort = () => {},
  sortMode,
  languages,
  team,
}) => {
  const {
    featuresStore,
    tableSizeStore: { tableRowHeight },
    teamStore,
  } = useStores();

  const getDescriptionHeaders = () => {
    if (!languages.length) {
      return <HeaderButton onClick={() => onSort('description')}>Description</HeaderButton>;
    }
    return languages.map(lang => {
      return <LeftTableTH style={{ padding: 0 }} key={`LeftTableTH-${lang}-Description`}>{`${lang} Description`}</LeftTableTH>;
    });
  };

  return (
    <Thead>
      <HeaderRow
        style={{
          height: `${tableRowHeight}px`,
        }}
      >
        {featuresStore.hasChangedAttributes() && teamStore.team.showAcceptChanges && <LeftTableTH className={styles.category}>Accept Changes</LeftTableTH>}
        {!readOnly && team !== VehicleTeam.AGENCY_SPANISH && <LeftTableTH></LeftTableTH>}
        {sortMode && <LeftTableTH className={inputStyles.smallInputHeader}>#</LeftTableTH>}
        <LeftTableTH className={styles.category} onClick={() => onSort('category.value')}>
          Category
        </LeftTableTH>
        <LeftTableTH className={styles.category} onClick={() => onSort('subCategory.value')}>
          Sub Category
        </LeftTableTH>
        {allowLinks && <LeftTableTH></LeftTableTH>}
        {getDescriptionHeaders()}
        <LeftTableTH className={styles.description} colSpan={4}>
          <span style={{ whiteSpace: 'nowrap' }}>
            {showDescriptionToggles && (
              <>
                <Checkbox
                  id={`requiredDescriptionChbox`}
                  className={styles.checkbox}
                  checked={featuresStore.showRequiredDescription}
                  onChange={e => (featuresStore.showRequiredDescription = e.currentTarget.checked)}
                >
                  <span className={styles.checkboxLabel}>{switchShortLongDescription ? 'Short' : 'Long'}</span>
                </Checkbox>
                <Checkbox
                  id={`optionalDescriptionChbox`}
                  className={styles.checkbox}
                  checked={featuresStore.showOptionalDescription}
                  onChange={e => (featuresStore.showOptionalDescription = e.currentTarget.checked)}
                >
                  <span className={styles.checkboxLabel}>{switchShortLongDescription ? 'Long' : 'Short'}</span>
                </Checkbox>
              </>
            )}
          </span>
        </LeftTableTH>
      </HeaderRow>
    </Thead>
  );
};

export default observer(FeatureHeaderRow);
