import { action, computed, makeObservable, observable, override } from "mobx";
import { KeyValueType } from "../../../models/common.model";
import { FuelTypeItemResponse } from "../../../models/fuelType.model";
import { SeriesCategoryResponse } from "../../../models/seriesCategories.model";
import {
  SeriesSettingsReviewMap,
  SeriesSettingsReviewResponse,
} from "../../../models/seriesSettings.model";
import { Brand } from "../../../models/user.model";
import { VehicleTeam } from "../../../models/vehicleData.model";
import { toLowerCase } from "../../../utils";
import { seriesSettingsReviewXForm } from "../../../utils/seriesSettingsUtils";
import {
  getFuelTypes,
  getSeriesCategories,
} from "../../../webservices/adminApi";
import { getReviewSeriesSettings } from "../../../webservices/vehicleModelsApi";
import BaseReviewStore from "./baseReviewStore";

class SeriesSettingsReviewStore extends BaseReviewStore {
  fuelTypesMap: KeyValueType<string> = {};
  codeRedFuelTypes: KeyValueType<true> = {}; // map of fuelType name to true
  map: SeriesSettingsReviewMap = {};
  seriesCategoriesMap: KeyValueType<string> = {};

  fetchData = async (
    brand: Brand,
    team: VehicleTeam,
    seriesId: string,
    year: string,
    version?: string,
    getFullData: boolean = false
  ) => {
    const promises: Promise<any>[] = [
      getFuelTypes(brand),
      getReviewSeriesSettings(brand, team, seriesId, year, version),
    ];
    if (getFullData) {
      promises.push(getSeriesCategories(brand, true));
    }
    const responses = await Promise.all(promises);

    this.fuelTypesMap = {};
    (responses[0].data.fuelTypes as FuelTypeItemResponse[]).forEach(
      (fuelType) => {
        this.fuelTypesMap[fuelType.id] = fuelType.type;
      }
    );

    this.codeRedFuelTypes = {};
    (responses[1].data as SeriesSettingsReviewResponse[]).forEach((x) => {
      if (!x.isPublishable) {
        Object.keys(x.fuelTypes).forEach((fuelTypeId: string) => {
          this.codeRedFuelTypes[this.fuelTypesMap[fuelTypeId]] = true;
        });
      }
    });

    if (getFullData) {
      const seriesCategories = responses[2].data as SeriesCategoryResponse[];
      this.seriesCategoriesMap = {};
      seriesCategories.forEach((seriesCategory) => {
        this.seriesCategoriesMap[seriesCategory.id] = seriesCategory.name;
      });
      this.map = seriesSettingsReviewXForm(
        responses[1].data,
        this.fuelTypesMap,
        this.seriesCategoriesMap
      );
    }
  };

  constructor() {
    // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
    super();

    makeObservable(this, {
      fuelTypesMap: observable,
      codeRedFuelTypes: observable,
      map: observable,
      seriesCategoriesMap: observable,
      fetchData: action,
      filteredReviewItems: computed,
      reset: override,
    });
  }

  get filteredReviewItems() {
    const items = Object.values(this.map).slice();

    const lowerSearchText = toLowerCase(this.searchText);
    return items.filter((seriesSettings) => {
      if (lowerSearchText) {
        const valuesToCheck: string[] = [
          seriesSettings.name.before,
          seriesSettings.name.after,
        ];
        valuesToCheck.push(...Object.keys(seriesSettings.fuelTypes.before));
        valuesToCheck.push(...Object.keys(seriesSettings.fuelTypes.after));
        valuesToCheck.push(
          ...Object.keys(seriesSettings.seriesCategories.before)
        );
        valuesToCheck.push(
          ...Object.keys(seriesSettings.seriesCategories.after)
        );
        console.log(valuesToCheck);
        for (let val of valuesToCheck) {
          if (toLowerCase(val).includes(lowerSearchText)) {
            return true;
          }
        }
      }

      return !lowerSearchText;
    });
  }

  reset = () => {
    this.searchText = "";
    this.fuelTypesMap = {};
    this.codeRedFuelTypes = {};
    this.map = {};
    this.seriesCategoriesMap = {};
  };
}

export default SeriesSettingsReviewStore;
