import { computed, makeObservable, observable } from "mobx";
import { v4 as uuidv4 } from "uuid";
import { cleanUpRte } from "vapi-ui-common";
import { removeNulls } from "../utils";
import { ChangeLogTypes } from "./changeLog.model";
import { BaseReviewItem, IDValueType, KeyValueType } from "./common.model";
import {
  ReviewChangeMap,
  ReviewChangeResponse,
  ReviewChangeTypeMap,
} from "./review.model";
import { VehicleTeam } from "./vehicleData.model";

export class FeatureItem {
  uid = "";
  id = "";
  revId = "";
  switchShortLongDescription = false;
  comLangId = "";
  modelsMap: KeyValueType<FeatureModel> = {};
  gradeApplicability = {} as GradeApplicability;
  link = "";
  category = {} as IDValueType;
  subCategory = {} as IDValueType;
  description = "";
  shortDescription = "";
  longDescription = "";
  isInProgress = false;
  isHighlighted = false;
  notes = "";
  rejectNotes = "";
  splits: FeatureSplitItem[] = [];
  tooltip = false;
  sortOrder: string | number = 0;
  compareFeatureId: string = "";
  changedAttributes: string[] = [];
  changedModelIds: string[] = [];
  fromTMNA: boolean = false;

  constructor(feature?: FeatureResponse) {
    makeObservable(this, {
      comLangId: observable,
      modelsMap: observable,
      gradeApplicability: observable,
      link: observable,
      category: observable,
      subCategory: observable,
      description: observable,
      shortDescription: observable,
      longDescription: observable,
      isInProgress: observable,
      isHighlighted: observable,
      notes: observable,
      rejectNotes: observable,
      splits: observable,
      tooltip: observable,
      sortOrder: observable,
      compareFeatureId: observable,
      changedAttributes: observable,
      changedModelIds: observable,
      fromTMNA: observable,
      splitCount: computed,
    });

    this.uid = uuidv4();
    if (feature) {
      Object.assign(this, removeNulls(feature));
      this.changedAttributes.forEach((attr) => {
        if (attr.includes("modelApplicability#")) {
          const splt = attr.split("#");
          if (splt.length > 1) {
            this.changedModelIds.push(splt[1]);
          }
        }
      });
    }
  }

  get splitCount() {
    return this.splits.length;
  }

  getPayload = () => {
    const modelApplicability = {} as FeatureModelApplicability;
    Object.values(this.modelsMap).forEach((mdl) => {
      modelApplicability[mdl.id] = mdl.setting;
    });

    const gradeApplicability = JSON.parse(
      JSON.stringify(this.gradeApplicability)
    ) as GradeApplicability;

    Object.values(gradeApplicability).forEach((gradeApp) => {
      Object.values(gradeApp.applicabilityText).forEach((appText) => {
        const curAppText = appText;
        curAppText.text = curAppText.text === "" ? null : curAppText.text;
      });
    });

    return {
      id: this.id,
      revId: this.revId,
      categoryId: this.category.id,
      subCategoryId: this.subCategory.id,
      comLangId: this.comLangId,
      description: cleanUpRte(this.description),
      shortDescription: cleanUpRte(this.shortDescription),
      longDescription: cleanUpRte(this.longDescription),
      isInProgress: this.isInProgress,
      isHighlighted: this.isHighlighted,
      notes: this.notes,
      link: this.link,
      tooltip: this.tooltip,
      modelApplicability,
      gradeApplicability: this.gradeApplicability,
    } as FeatureRequest;
  };

  isFeatureValid = () =>
    !!(
      this.category.id &&
      this.category.value &&
      (this.shortDescription || this.longDescription || this.description)
    );

  areSplitsValid = () =>
    !this.splits.length ||
    !this.splits.filter((item) => !item.description).length;

  isValid = (team: string) => {
    if (team === VehicleTeam.AGENCY_TEAM) {
      return this.isFeatureValid() && this.areSplitsValid();
    } else {
      return (
        !!(this.category.id && this.subCategory.id && this.description) &&
        this.areSplitsValid()
      );
    }
  };
}

export class FeatureSplitItem {
  id = "";
  uid = "";
  name = "";
  featureId = "";
  featureRevId = "";
  notes = "";
  description = "";
  shortDescription = "";
  longDescription = "";
  isHighlighted = false;

  constructor(split?: FeatureSplitResponse) {
    makeObservable(this, {
      notes: observable,
      description: observable,
      shortDescription: observable,
      longDescription: observable,
      isHighlighted: observable,
    });

    this.uid = uuidv4();
    if (split) {
      Object.assign(this, removeNulls(split));
    }
  }

  isValid = () => {
    return !!this.description;
  };

  getPayload = () => {
    return {
      id: this.id,
      featureId: this.featureId,
      featureRevId: this.featureRevId,
      description: this.description,
      shortDescription: this.shortDescription,
      longDescription: this.longDescription,
      isHighlighted: this.isHighlighted,
      notes: this.notes,
    } as FeatureSplitRequest;
  };
}

export interface FeatureResponse {
  id: string;
  revId: string;
  modelApplicability: FeatureModelApplicability;
  gradeApplicability: GradeApplicability;
  categoryId: string;
  subCategoryId: string | undefined;
  description: string; // also referred to as long description in agency page
  shortDescription: string;
  longDescription: string;
  isInProgress: boolean;
  isHighlighted: boolean;
  notes: string | undefined;
  comLangId?: string;
  splits?: FeatureSplitResponse[];
  changedAttributes?: string[];
  fromTMNA?: boolean;
  link: string;
}

export interface FeatureRequest extends FeatureResponse {}

export enum FeatureSettings {
  STANDARD = "S",
  OPTIONAL = "O",
  PACKAGE = "P",
  UNDEFINED = "",
}

export interface FeatureSplitRequest extends FeatureSplitResponse {}

export type FeatureSplitResponse = {
  id: string;
  featureId: string;
  featureRevId: string;
  notes?: string;
  description: string;
  shortDescription?: string; // unclear if feature splits have short descriptions
  longDescription?: string;
  isHighlighted?: boolean;
};

export type FeatureModelApplicability = {
  [modelCode: string]: FeatureSettings;
};

export type FeatureModel = {
  id: string;
  setting: FeatureSettings;
};

export type GradeApplicability = {
  [modelCode: string]: GradeApplicabilityItem;
};
export type GradeApplicabilityItem = {
  highlighted: boolean;
  isComparable: boolean;
  applicabilityText: GradeApplicabilityText;
};
export type GradeApplicabilityText = {
  [key: string]: {
    text: string | null;
    availability: string;
  };
};

/**
 * Features Review Item
 */
export class FeaturesReviewItem extends BaseReviewItem {
  subCategory = {} as IDValueType;
  models: FeatureModel[] = [];
  modelApplicability: KeyValueType<FeatureSettings> = {};
  gradeApplicability = {} as GradeApplicability;

  constructor(feature?: FeaturesReviewResponse, change?: ReviewChangeResponse) {
    super();

    makeObservable(this, {
      subCategory: observable,
      models: observable,
      modelApplicability: observable,
      gradeApplicability: observable,
    });

    this.uid = uuidv4();
    if (feature) {
      Object.assign(this, removeNulls(feature));
    }
    this.destructureReviewChangeResponse(change);
    this.createOtherChanges(feature);
  }
}
export interface FeaturesReviewResponse {
  isHighlighted: string;
  notes: string;
  modelApplicability: FeatureModelApplicability;
  gradeApplicability: GradeApplicability;
  link: string;
  revId: string;
  changes: KeyValueType<ReviewChangeResponse>;
  otherChanges?: KeyValueType<ReviewChangeResponse>;
  description: string;
  subCategoryId: string;
  subCategory?: string;
  shortDescription: string;
  isDeleted: boolean;
  isAccepted: boolean;
  isApplied: boolean;
  id: string;
  isInProgress: boolean;
  categoryId: string;
  category?: string;
}

export interface FeaturesReviewRequest {
  id: string;
  revId: string;
  changeType: ChangeLogTypes;
  isAccepted: boolean;
  isApplied: boolean;
  rejectNotes: string;
}

export interface FeaturesReviewMap {
  [id: string]: FeaturesChangeTypeMap;
}

export interface FeaturesChangeTypeMap extends ReviewChangeTypeMap {
  category: ReviewChangeMap<string>;
  subCategory: ReviewChangeMap<string>;
  description: ReviewChangeMap<string>;
  modelApplicability: ReviewChangeMap<KeyValueType<FeatureSettings>>;
}

export type FeatureReviewType =
  | "category"
  | "subCategory"
  | "description"
  | "modelApplicability"
  | "added"
  | "deleted";

export interface FeaturesMap {
  features: {
    [id: string]: FeatureLangMap;
  };
  order: string[];
}

export interface FeatureLangMap {
  langs: {
    [lang: string]: FeatureItem;
  };
  data?: FeatureResponse;
}
