import cx from 'clsx';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';
import { RichTextCell } from 'vapi-ui-common';
import IconTextButton from '../../../../../../components/IconTextButton';
import inputStyles from '../../../../../../components/Input/input.module.scss';
import { TableCell, TableRowWithShadow } from '../../../../../../components/Table';
import TableDragIcon from '../../../../../../components/Table/components/TableDragIcon';
import useStores from '../../../../../../hooks/useStores';
import { ColorItemLangMap } from '../../../../../../models/colors.model';
import { tokensXForm } from '../../../../../../utils/disclaimersUtils';
import { handleOnLangMapSortNumberUpdate } from '../../../../../../utils/sortUtils';
import SyncTdPRButton from '../../../../components/SyncTdPRButton/SyncTdPRButton';
import CenteredTextAreaCell from '../../../../components/tableCells/CenteredTextAreaCell';
import DeleteCell from '../../../../components/tableCells/DeleteCell';
import FlagsCell from '../../../../components/tableCells/FlagsCell';
import { IColorsFormRow } from './IColorsFormRow';
import styles from './colorsFormRow.module.scss';

interface Props extends IColorsFormRow {
  index: number;
  colorMap: ColorItemLangMap;
  draggableProvided: DraggableProvided;
}

const ColorsFormRow: React.FC<Props> = ({
  index,
  colorMap,
  sortMode,
  saveExteriorColorLangMap,
  deleteColorItem,
  showHexCode = false,
  draggableProvided,
  readOnly,
  brand,
  compareExteriorColor,
}) => {
  const {
    colorsStore,
    disclaimersStore: { tokens },
    teamStore,
  } = useStores();

  const disclaimerTokens = tokensXForm(tokens);
  const defaultColor = colorMap.langs[colorsStore.defaultLang];
  const hasEnglishWritePerms = !!colorsStore.langWriteMap.EN?.canEdit;
  const selectedLangs = colorsStore.selectedLangs();
  const index2 = colorsStore.filteredColorItemLangMaps.findIndex(x => x.langs[colorsStore.defaultLang].id === defaultColor.id);

  const handleOnNotesChange = (notes: string) => {
    let shouldUpdate = false;
    colorsStore.editableLangs.forEach(lang => {
      if (colorMap.langs[lang].notes !== notes) {
        colorMap.langs[lang].notes = notes;
        shouldUpdate = true;
      }
    });
    if (shouldUpdate) {
      saveExteriorColorLangMap(colorMap);
    }
  };

  const handleOnCodeChange = (code: string) => {
    let shouldUpdate = false;
    colorsStore.editableLangs.forEach(lang => {
      if (colorMap.langs[lang].code !== code.toUpperCase()) {
        colorMap.langs[lang].code = code.toUpperCase();
        shouldUpdate = true;
      }
    });
    if (shouldUpdate) {
      saveExteriorColorLangMap(colorMap);
    }
  };

  const handleOnHexCodeChange = (hexCode: string) => {
    let shouldUpdate = false;
    colorsStore.editableLangs.forEach(lang => {
      if (colorMap.langs[lang].hexCode !== hexCode) {
        colorMap.langs[lang].hexCode = hexCode;
        shouldUpdate = true;
      }
    });
    if (shouldUpdate) {
      saveExteriorColorLangMap(colorMap);
    }
  };

  const handleOnExtraCostChange = (msrp: string) => {
    let shouldUpdate = false;
    colorsStore.editableLangs.forEach(lang => {
      if (colorMap.langs[lang].isExtraCost !== msrp) {
        colorMap.langs[lang].isExtraCost = msrp;
        shouldUpdate = true;
      }
    });
    if (shouldUpdate) {
      saveExteriorColorLangMap(colorMap);
    }
  };

  const handleToggleInProgress = () => {
    colorsStore.editableLangs.forEach(lang => {
      colorMap.langs[lang].isInProgress = !colorMap.langs[lang].isInProgress;
    });
    saveExteriorColorLangMap(colorMap);
  };

  const handleOnNameChange = (name: string, lang: string) => {
    if (name !== colorMap.langs[lang].name) {
      colorMap.langs[lang].name = name;
      saveExteriorColorLangMap(colorMap, undefined, lang);
    }
  };

  const changedAttributes = () => {
    const changed: string[] = [];
    colorsStore.editableLangs.forEach(lang => {
      const color = colorMap.langs[lang];
      if (color) {
        changed.push(...color.changedAttributes);
      }
    });
    return changed;
  };

  const isAcceptChangesVisible = !readOnly && !!colorsStore.editableLangs.filter(lang => !!colorMap.langs[lang].changedAttributes.length).length;

  const showSync = defaultColor.fromTMNA && !readOnly;

  return (
    <TableRowWithShadow innerRef={draggableProvided.innerRef} {...draggableProvided.draggableProps}>
      {!readOnly && sortMode && (
        <>
          <TableCell {...draggableProvided.dragHandleProps} border center>
            <TableDragIcon />
          </TableCell>
          <TableCell border center>
            <input
              className={cx(inputStyles.input, inputStyles.smallInput)}
              value={defaultColor.sortOrder}
              onBlur={newIndex => {
                handleOnLangMapSortNumberUpdate(colorsStore.filteredColorItemLangMaps, newIndex.target.value, index2);
                colorsStore.filteredColorItemLangMaps = colorsStore.filteredColorItemLangMaps.slice();
              }}
              onChange={e => (defaultColor.sortOrder = parseInt(e.currentTarget.value, 10) > 0 ? parseInt(e.currentTarget.value, 10) : '')}
            />
          </TableCell>
        </>
      )}
      {!readOnly && !sortMode && teamStore.team.allowAddDeleteData && <DeleteCell description={defaultColor.name} deleteItem={() => deleteColorItem(colorMap)} itemType="Color" />}

      {teamStore.team.showAcceptChanges && isAcceptChangesVisible && (
        <TableCell>
          <div id={`acceptChanges-${defaultColor.id}`} className={styles.acceptChangesThumbsUp}>
            <IconTextButton
              id={`acceptChanges-checkbox-${defaultColor.id}`}
              icon="thumbsUp"
              text=""
              onClick={() => {
                saveExteriorColorLangMap(colorMap, undefined, undefined, true);
              }}
            />
          </div>
        </TableCell>
      )}
      {teamStore.team.showAcceptChanges && !isAcceptChangesVisible && colorsStore.hasChangedAttributes() && <TableCell></TableCell>}
      <div>
        {showSync && (
          <SyncTdPRButton
            changedAttributes={changedAttributes()}
            id={defaultColor.id}
            onClick={() => {
              compareExteriorColor(colorMap);
            }}
          />
        )}
        <CenteredTextAreaCell
          className={defaultColor.changedAttributes.includes('code') ? cx(styles.codeCellStyles, styles.errorText) : styles.codeCellStyles}
          required
          disabled={readOnly || !hasEnglishWritePerms}
          size="xsmall"
          value={defaultColor.code}
          onBlurCallback={value => handleOnCodeChange(value.trim())}
          singleLine={true}
        />
      </div>
      {selectedLangs.map(lang => {
        const color = colorMap.langs[lang];
        if (teamStore.team.allowDisclaimerTokens) {
          return (
            <RichTextCell
              required={!color.name && !!colorsStore.langWriteMap[lang]?.canEdit}
              colType="description"
              disabled={readOnly || !colorsStore.langWriteMap[lang]?.canEdit}
              value={color.name}
              onBlur={value => handleOnNameChange(value, lang)}
              suggestionTypes={disclaimerTokens}
              cellStyles={cx(styles.colorDescriptionBox, color.changedAttributes.includes('name') ? styles.errorText : '')}
              singleLine
            />
          );
        }
        return (
          <CenteredTextAreaCell
            required={!color.name && !!colorsStore.langWriteMap[lang]?.canEdit}
            disabled={readOnly || !colorsStore.langWriteMap[lang]?.canEdit}
            size="medium"
            value={color.name}
            onBlurCallback={value => handleOnNameChange(value, lang)}
            singleLine
            className={color.changedAttributes.includes('name') ? styles.errorText : ''}
          />
        );
      })}

      {showHexCode && (
        <CenteredTextAreaCell
          required
          disabled={readOnly || !hasEnglishWritePerms}
          size="small"
          value={defaultColor.hexCode}
          onBlurCallback={value => handleOnHexCodeChange(value.trim())}
          singleLine={true}
          allowEmpty={true}
          className={defaultColor.changedAttributes.includes('hexCode') ? styles.errorText : ''}
        />
      )}
      <FlagsCell
        disabled={readOnly || !hasEnglishWritePerms}
        index={index}
        notes={defaultColor.notes}
        rejectNotes={defaultColor.rejectNotes}
        highlighted={false}
        inProgress={defaultColor.isInProgress}
        toggleInProgress={handleToggleInProgress}
        displayHighlighted={false}
        toggleHighlighted={() => {}}
        onNotesChange={handleOnNotesChange}
        msrp={defaultColor.isExtraCost}
        onExtraCostChange={handleOnExtraCostChange}
      />
    </TableRowWithShadow>
  );
};

export default observer(ColorsFormRow);
