import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { Button } from "vapi-ui-common";
import Input from "../../../../../../../components/Input";
import styles from "./customPopover.module.scss";

type PopoverProps = {
  closePopover?: () => void;
  onUpdate: (idx: string) => void;
  link: string;
  readOnly?: boolean;
  popoverType?: "hyperlink" | "logos";
};

const CustomPopover: React.FC<PopoverProps> = ({
  closePopover,
  onUpdate,
  link,
  readOnly,
  popoverType = "hyperlink",
}) => {
  const isHyperlink = popoverType === "hyperlink";
  const [value, setValue] = useState(link);
  return (
    <section className={styles.root}>
      <header className={styles.header}>
        {isHyperlink ? "Hyperlink" : "Logos"}
      </header>
      {readOnly && <div>{value}</div>}
      {!readOnly && (
        <>
          <div className={styles.body}>
            {isHyperlink ? (
              <Input
                type="text"
                value={value}
                className={styles.textInput}
                onChange={(event) => setValue(event.target.value.trim())}
              />
            ) : (
              <textarea
                className={styles.textInput}
                onChange={(e) => setValue(e.currentTarget.value)}
                value={value}
                rows={8}
                style={{ width: 400, whiteSpace: "normal" }}
              />
            )}
          </div>
          <footer className={styles.footer}>
            <Button
              variant="transparent"
              onClick={() => {
                closePopover && closePopover();
              }}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                onUpdate && onUpdate(value.trim());
                closePopover && closePopover();
              }}
            >
              Save
            </Button>
          </footer>
        </>
      )}
    </section>
  );
};

export default observer(CustomPopover);
