import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { toast } from 'react-toastify';
import ChangeLogInner from '../../../../components/ChangeLogInner';
import useStores from '../../../../hooks/useStores';
import { ChangeLogItem, ChangeLogLangMap, ChangeLogResponse, ChangeLogTypes, SYNCED_CHANGE_TYPES_MAP } from '../../../../models/changeLog.model';
import { KeyValueType } from '../../../../models/common.model';
import { Language } from '../../../../models/user.model';
import { changeLogHandleDeleteTypes, changeLogIdMapper, changeLogModelApplicabilityMapper } from '../../../../utils/changeLogUtils';
import { filterOutUnsuableChanges } from '../../../../utils/gradeApplicabilityUtils';
import { getCategoriesByLang, getChangeLog, getSpecTypesByLang, revertChange } from '../../../../webservices/vehicleSpecsApi';

interface SpecsChangeLogProps {
  seriesId: string;
  year: string;
  readOnly?: boolean;
  version: string;
}

const SpecsChangeLog = ({ seriesId, year, readOnly, version }: SpecsChangeLogProps) => {
  const {
    userStore: { brand },
    teamStore,
    changeLogStore,
    specsStore,
    vehicleModelsStore,
  } = useStores();

  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    changeLogStore.reset();

    (async () => {
      setIsLoaded(false);

      try {
        const selectedLangs: KeyValueType<boolean> = {};
        teamStore.team.changeLogLanguages.forEach(lang => {
          selectedLangs[lang] = true;
        });
        changeLogStore.selectedLangsMap = selectedLangs;

        const promises: Promise<any>[] = [];
        for (const lang of teamStore.team.changeLogLanguages) {
          const l = lang.toUpperCase();
          promises.push(
            getChangeLog(brand, teamStore.team.param, seriesId, year, l, version),
            getCategoriesByLang(brand, teamStore.team.param, seriesId, year, l, version), // fetch deleted records as well
            getSpecTypesByLang(brand, teamStore.team.param, seriesId, year, l, version), // fetch deleted records as well
          );
        }

        const responses = await Promise.all(promises);
        let index = 0;
        const changeLogLangMap: ChangeLogLangMap = {};
        for (const lang of teamStore.team.changeLogLanguages) {
          const baseIndex = index * 3;
          const logs = (responses[baseIndex].data as ChangeLogResponse[])
            .map(item => {
              const changeLogItem = new ChangeLogItem(item, !readOnly, lang);
              if (
                changeLogItem.changeType !== ChangeLogTypes.MODEL_APPLICABILITY &&
                SYNCED_CHANGE_TYPES_MAP[changeLogItem.changeType] &&
                teamStore.team.changeLogLanguages.length > 1
              ) {
                changeLogItem.canRevert = false;
              }
              return changeLogItem;
            })
            .filter(filterOutUnsuableChanges);

          specsStore.updateCategoriesLangMap(lang, specsStore.categoriesMap, responses[baseIndex + 1].data);
          specsStore.updateCategoriesLangMap(lang, specsStore.specTypeMap, responses[baseIndex + 2].data);

          changeLogIdMapper(specsStore.getCategoriesForLang(lang, specsStore.categoriesMap), logs, ChangeLogTypes.CATEGORY);
          changeLogIdMapper(specsStore.getCategoriesForLang(lang, specsStore.specTypeMap), logs, ChangeLogTypes.SPEC_TYPE);

          changeLogHandleDeleteTypes(logs, ChangeLogTypes.SPEC_DELETED);
          changeLogModelApplicabilityMapper(vehicleModelsStore.vehicleModels, logs);
          changeLogLangMap[lang] = logs;

          index += 1;
        }

        changeLogStore.changeLogLangMap = changeLogLangMap;
      } catch (e) {
        toast.error('Error loading specs change log');
      }
      setIsLoaded(true);
    })();
  }, [brand, changeLogStore, readOnly, seriesId, teamStore, year, specsStore, version, vehicleModelsStore]);

  const handleOnRevert = async (item: ChangeLogItem) => {
    setIsLoaded(false);
    try {
      const changeLogLangMap: ChangeLogLangMap = JSON.parse(JSON.stringify(changeLogStore.changeLogLangMap));
      const lang = item.language ?? Language.EN;
      await trackPromise(revertChange(brand, teamStore.team.param, seriesId, year, lang, item.payload));
      const response = await getChangeLog(brand, teamStore.team.param, seriesId, year, lang, version);
      const logs = response.data.map(item => new ChangeLogItem(item, !readOnly, lang)).filter(filterOutUnsuableChanges);
      changeLogIdMapper(specsStore.getCategoriesForLang(lang, specsStore.categoriesMap), logs, ChangeLogTypes.CATEGORY);
      changeLogIdMapper(specsStore.getCategoriesForLang(lang, specsStore.specTypeMap), logs, ChangeLogTypes.SPEC_TYPE);
      changeLogHandleDeleteTypes(logs, ChangeLogTypes.SPEC_DELETED);
      changeLogModelApplicabilityMapper(vehicleModelsStore.vehicleModels, logs);
      changeLogLangMap[lang] = logs;
      changeLogStore.changeLogLangMap = changeLogLangMap;

      toast.success('Change log reverted');
    } catch {
      toast.error('Error reverting change log');
    }
    setIsLoaded(true);
  };

  return <ChangeLogInner module={'Spec'} isLoaded={isLoaded} handleOnRevert={handleOnRevert} />;
};

export default observer(SpecsChangeLog);
