import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ActionBar, Spinner } from "vapi-ui-common";
import { uiBlock } from "../../../../components/UiBlocker/uiBlock";
import useStores from "../../../../hooks/useStores";
import { Brand } from "../../../../models/user.model";
import { ModelsChangeTypeMap } from "../../../../models/vehicleModel.model";
import modelsReviewStore from "../../../../stores/vehicleData/review/modelReviewStore";
import { handleErrorResponse } from "../../../../utils/errorHandlingUtils";
import { modelsReviewXform } from "../../../../utils/modelsUtils";
import { processVDVersion } from "../../../../utils/vehicleDataUtils";
import { getReviewModels2 } from "../../../../webservices/vehicleModelsApi";
import ActionBarFiltersSection from "../../components/ActionBarFiltersSection";
import { ProductDataControllerProps } from "../../models/controllers.model";
import ModelsReviewTable from "./ModelsReviewTable";

const ModelsReview = ({
  seriesId,
  year,
  version = "",
  vehicleModels,
}: ProductDataControllerProps) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const {
    teamStore,
    userStore: { brand },
    seriesSettingsReviewStore,
  } = useStores();

  useEffect(() => {
    seriesSettingsReviewStore.reset();
    modelsReviewStore.reset();
    (async () => {
      try {
        await seriesSettingsReviewStore.fetchData(
          brand as Brand,
          teamStore.team.param,
          seriesId,
          year,
          processVDVersion(version)
        );
        const response = await getReviewModels2(
          brand,
          teamStore.team.param,
          seriesId,
          year,
          version
        );
        const { map } = modelsReviewXform(
          response.data,
          seriesSettingsReviewStore.fuelTypesMap
        );
        modelsReviewStore.filterOutCodeRed = !teamStore.team.allowCodeRed;
        modelsReviewStore.map = map;
      } catch (err) {
        console.log(err);
        toast.error("Error loading Models review data");
      }
      setIsLoaded(true);
    })();
  }, [
    brand,
    seriesId,
    teamStore,
    version,
    year,
    vehicleModels,
    seriesSettingsReviewStore,
  ]);

  const handleOnMapNotesChange = async (
    map: ModelsChangeTypeMap,
    value: string
  ) => {
    map.rejectNotes = value;
    saveMap(map);
  };

  const handleOnMapApplyChange = async (
    map: ModelsChangeTypeMap,
    value: boolean
  ) => {
    map.isApplied = value;
    saveMap(map);
  };

  const saveMap = async (map: ModelsChangeTypeMap) => {
    try {
      if (modelsReviewStore.isMapValid(map, teamStore.team.param)) {
        uiBlock.start();
        /* const response = await updateReviewModels(
          brand,
          teamStore.team.param,
          seriesId,
          year,
          version,
          modelsReviewStore.getMapPayload(map, teamStore.team.param)
        );
        map.revId = response.data.revId; */
        toast.success("Successfully updated model");
      } else {
        toast.error("Please add reject notes or apply the changes.");
      }
    } catch (e) {
      handleErrorResponse(e, "Error updating review");
    }

    uiBlock.stop();
  };

  return !isLoaded ? (
    <Spinner />
  ) : (
    <>
      <ActionBar>
        <ActionBarFiltersSection
          readOnly
          searchText={modelsReviewStore.searchText}
          onSearchTextChange={(text) => (modelsReviewStore.searchText = text)}
          buttons={[]}
          hideFilter
          renderFilter={(onClose) => <></>}
        />
      </ActionBar>

      {/* // review table  */}
      <ModelsReviewTable
        handleOnApplyChange={handleOnMapApplyChange}
        handleOnNotesChange={handleOnMapNotesChange}
        codeRedFuelTypes={seriesSettingsReviewStore.codeRedFuelTypes}
      />
    </>
  );
};

export default observer(ModelsReview);
