import { observable, makeObservable } from 'mobx';

class TableSizeStore {
  tableWidth = 0;
  tableRowHeight = 0;
  scrollBarHeight = 0;

  constructor() {
    makeObservable(this, {
      tableWidth: observable,
      tableRowHeight: observable,
      scrollBarHeight: observable
    });
  }
}

export default TableSizeStore;
