import { AxiosResponse } from 'axios';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { toast } from 'react-toastify';
import ChangeLogInner from '../../../../components/ChangeLogInner';
import useStores from '../../../../hooks/useStores';
import {
  ChangeLogItem,
  ChangeLogLangMap,
  ChangeLogRequest,
  ChangeLogResponse,
} from '../../../../models/changeLog.model';
import { KeyValueType } from '../../../../models/common.model';
import { Language } from '../../../../models/user.model';
import { VehicleTeam } from '../../../../models/vehicleData.model';
import {
  getChangeLog as getBnpChangeLog,
  revertChange as revertBnpChange,
} from '../../../../webservices/vehicleBnPApi';

interface BnPChangeLogProps {
  seriesId: string;
  year: string;
  readOnly?: boolean;
  version: string;
}

const BnPChangeLog = ({ seriesId, year, readOnly, version }: BnPChangeLogProps) => {
  const {
    userStore: { brand },
    teamStore,
    changeLogStore,
  } = useStores();

  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    changeLogStore.reset();

    (async () => {
      setIsLoaded(false);

      try {
        const selectedLangs: KeyValueType<boolean> = {};
        teamStore.team.changeLogLanguages.forEach(lang => {
          selectedLangs[lang] = true;
        });
        changeLogStore.selectedLangsMap = selectedLangs;

        const promises: Promise<AxiosResponse<any>>[] = [];
        for (const lang of teamStore.team.changeLogLanguages) {
          promises.push(
            getBnpChangeLog(brand, teamStore.team.param, seriesId, year, lang, version)
          );
        }
        const responses = await Promise.all(promises);

        let index = 0;
        const changeLogLangMap: ChangeLogLangMap = {};
        for (const lang of teamStore.team.changeLogLanguages) {
          const logs = (responses[index].data as ChangeLogResponse[]).map(
            item =>
              new ChangeLogItem(
                item,
                !readOnly && teamStore.team.param !== VehicleTeam.PRODUCT_TEAM,
                lang
              )
          );
          changeLogLangMap[lang] = logs;
          index += 1;
        }

        changeLogStore.changeLogLangMap = changeLogLangMap;
      } catch (e) {
        toast.error('Error loading features change log');
      }

      setIsLoaded(true);
    })();
  }, [brand, changeLogStore, readOnly, seriesId, teamStore, year, version]);

  const handleOnRevert = async (item: ChangeLogItem) => {
    const language = item.language ?? Language.EN;
    try {
      const request: ChangeLogRequest = {
        ...item.payload,
        category: item.category,
        name: item.name,
        to: item.payload.to == null ? '' : item.payload.to,
      };

      await trackPromise(
        revertBnpChange(brand, teamStore.team.param, seriesId, year, language, request)
      );
      const response = await getBnpChangeLog(
        brand,
        teamStore.team.param,
        seriesId,
        year,
        language,
        version
      );
      const logs = response.data.map(
        item =>
          new ChangeLogItem(
            item,
            !readOnly && teamStore.team.param !== VehicleTeam.PRODUCT_TEAM,
            language
          )
      );
      changeLogStore.changeLogLangMap = { ...changeLogStore.changeLogLangMap, [language]: logs };

      toast.success('Change log reverted');
    } catch {
      toast.error('Error reverting change log');
    }
  };
  return <ChangeLogInner module={'Feature'} isLoaded={isLoaded} handleOnRevert={handleOnRevert} />;
};

export default observer(BnPChangeLog);
