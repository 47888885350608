import React, { useCallback, useContext } from 'react';
import { Button, ModalBody, ModalFooter } from 'vapi-ui-common';
import { entityTypeToNameMap } from './SyncTMNAChangesModal';
import { SyncTMNAChangesModalBodyContext } from './SyncTMNAChangesModalBody';

const SyncTMNAChangesModalBodyNew = () => {
  const { entityType, close } = useContext(SyncTMNAChangesModalBodyContext);

  const deleteItem = useCallback(() => close(undefined, true, false), [close]);

  const ignoreChanges = useCallback(() => close({ applied: {} }), [close]);

  return (
    <>
      <ModalBody>
        <div>{`Would you like to add this ${entityTypeToNameMap[entityType] ?? entityType}?`}</div>
      </ModalBody>
      <ModalFooter>
        <Button variant="transparent" onClick={deleteItem}>
          Delete
        </Button>
        <Button variant="primary" onClick={ignoreChanges}>
          Add
        </Button>
      </ModalFooter>
    </>
  );
};

export default SyncTMNAChangesModalBodyNew;
