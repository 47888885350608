import cx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { toast } from 'react-toastify';
import Header from '../../../../components/Header';
import { uiBlock } from '../../../../components/UiBlocker/uiBlock';
import { VD_PUBLISH_TYPE } from '../../../../constants/vehicleData/VDConstants';
import useRouteParams from '../../../../hooks/useRouteParams';
import useStores from '../../../../hooks/useStores';
import { Language } from '../../../../models/user.model';
import { DashboardDetailYear, VehicleTeam } from '../../../../models/vehicleData.model';
import { handleErrorResponse } from '../../../../utils/errorHandlingUtils';
import { dashboardXForm } from '../../../../utils/vehicleDataUtils';
import { getUserSeries, updateUserSeries } from '../../../../webservices/adminApi';
import { deleteDraft, duplicateVehicleData, editVehicleData, getDashboardDetails, getDocumentLink, publishDraft, uploadDocument } from '../../../../webservices/vehicleAdminApi';
import ActionBarSeriesManager from '../../components/ActionBarSeriesManager';
import VehicleTable from './components/VehicleTable';
import VehicleTableRow from './components/VehicleTableRow';
import styles from './components/VehicleTableRow/vehicleTableRow.module.scss';

interface VehicleDashboardProps {
  onShowSeriesManager: () => void;
}

const VehicleDashboard = observer(({ onShowSeriesManager }: VehicleDashboardProps) => {
  const {
    userStore: { brand, objectId, teamModule, modules },
    vehicleDataStore,
    teamStore,
  } = useStores();
  const { team } = useRouteParams();

  const [canEditLanguage, setCanEditLanguage] = useState(false);

  useEffect(() => {
    vehicleDataStore.searchText = '';
    teamStore.team.languages.forEach(lang => {
      if (teamStore.team.langPermissions[lang]?.canEdit) {
        setCanEditLanguage(true);
      }
    });
  }, [vehicleDataStore, teamStore]);

  const handleDuplicateItem = async (seriesId: string, fromModelYear: number, toModelYear: number) => {
    try {
      let draftCreated = false;
      for (const lang of teamStore.team.languages) {
        if (teamStore.team.langPermissions[lang]?.canEdit) {
          await trackPromise(
            duplicateVehicleData(brand, teamStore.team.param, {
              seriesId,
              fromModelYear,
              toModelYear,
              lang,
            }),
          );
          draftCreated = true;
        }
      }
      if (draftCreated) {
        vehicleDataStore.dashboardSeries.forEach(series => {
          let version = 1;
          if (series.id === seriesId) {
            series.modelYears.forEach(seriesModelYear => {
              if (seriesModelYear.year === toModelYear) {
                version = seriesModelYear.version + 1;
              }
            });
            series.modelYears.push({
              year: toModelYear,
              version,
              isDraft: true,
              datePublished: '',
            });
          }
        });
        vehicleDataStore.resetDraftMap();
        toast.success('Successfully duplicated model year');
      }
    } catch (e) {
      handleErrorResponse(e, 'Error adding item');
    }
  };

  const handleDeleteItem = async (seriesId: string, modelYear: number) => {
    try {
      let draftDeleted = false;
      for (const lang of teamStore.team.languages) {
        if (teamStore.team.langPermissions[lang]?.canEdit) {
          await trackPromise(
            deleteDraft(brand, teamStore.team.param, {
              seriesId,
              modelYear,
              lang,
            }),
          );
          draftDeleted = true;
        }
      }
      if (draftDeleted) {
        vehicleDataStore.dashboardSeries.forEach(series => {
          if (series.id === seriesId) {
            const draft = series.modelYears.find(item => item.isDraft && item.year === modelYear);
            series.modelYears = series.modelYears.reduce((modelYears: DashboardDetailYear[], item: DashboardDetailYear) => {
              if (item.year === modelYear) {
                if (item.isDraft) {
                  return modelYears;
                }

                return [...modelYears, { ...item, outOfSync: draft?.outOfSync }];
              }

              return [...modelYears, item];
            }, []);
          }
        });
        vehicleDataStore.resetDraftMap();
        toast.success('Successfully deleted draft');
      }
    } catch (e) {
      handleErrorResponse(e, 'Error deleting draft');
    }
  };

  const handleOnRemoveSeries = async (seriesId: string) => {
    try {
      const seriesArray = vehicleDataStore.userSeries.filter(item => item.id !== seriesId).map(item => item.id);
      await updateUserSeries(brand, objectId, seriesArray);
      const updatedUserSeries = await getUserSeries(brand, objectId);
      vehicleDataStore.userSeries = updatedUserSeries.data.seriesSelections;
      const response = await getDashboardDetails(brand, teamStore.team.param);
      vehicleDataStore.dashboardSeries = dashboardXForm(response.data, vehicleDataStore.series, teamModule);
    } catch (e) {
      handleErrorResponse(e, 'Error removing series');
    }
  };

  const handleOnCreateDraftFromPublished = async (seriesId: string, modelYear: number) => {
    try {
      let draftCreated = false;
      for (const lang of teamStore.team.languages) {
        if (teamStore.team.langPermissions[lang as Language]?.canEdit) {
          await trackPromise(
            editVehicleData(brand, teamStore.team.param, {
              seriesId,
              modelYear,
              lang,
            }),
          );
          draftCreated = true;
        }
      }

      if (draftCreated) {
        vehicleDataStore.dashboardSeries.forEach(series => {
          let version = 1;
          let createdDate = '';
          let spanishVersion;
          let sourceLang;
          let sourceVersion;
          let itemIndex = 0;
          let outOfSync = false;
          if (series.id === seriesId) {
            series.modelYears.forEach((seriesModelYear, index) => {
              if (seriesModelYear.year === modelYear) {
                version = seriesModelYear.version + 1;
                spanishVersion = seriesModelYear.spanishVersion ? seriesModelYear.spanishVersion + 1 : undefined;
                createdDate = seriesModelYear.createdDate || '';
                sourceLang = seriesModelYear.sourceLang;
                sourceVersion = seriesModelYear.sourceVersion;
                itemIndex = index;
                outOfSync = !!seriesModelYear.outOfSync;
                seriesModelYear.outOfSync = false;
              }
            });
            series.modelYears.splice(itemIndex, 0, {
              year: modelYear,
              version,
              spanishVersion,
              isDraft: true,
              datePublished: '',
              createdDate,
              sourceLang,
              sourceVersion,
              outOfSync,
            });
          }
        });
        vehicleDataStore.resetDraftMap();
        toast.success('Successfully created draft');
      }
    } catch (e) {
      handleErrorResponse(e, 'Error creating draft from published version');
    }
  };

  const handleOnPreview = async (seriesId: string, modelYear: number) => {
    try {
      for (const lang of teamStore.team.languages) {
        if (teamStore.team.langPermissions[lang]?.canEdit) {
          const payload = {
            seriesId,
            modelYear,
            lang,
            publishType: VD_PUBLISH_TYPE.PREVIEW,
          };
          await trackPromise(publishDraft(brand, teamStore.team.param, payload));
        }
      }

      const dashboardResponse = await getDashboardDetails(brand, teamStore.team.param);
      vehicleDataStore.dashboardSeries = dashboardXForm(dashboardResponse.data, vehicleDataStore.series, teamModule, teamStore.team.param);
      toast.success('Successfully published preview');
    } catch (e) {
      handleErrorResponse(e, 'Error publishing preview');
    }
  };

  const handleOnPublish = async (seriesId: string, modelYear: number) => {
    try {
      for (const lang of teamStore.team.languages) {
        if (teamStore.team.langPermissions[lang]?.canEdit) {
          await trackPromise(
            publishDraft(brand, teamStore.team.param, {
              seriesId,
              modelYear,
              lang,
              publishType: VD_PUBLISH_TYPE.PUBLISH,
            }),
          );
        }
      }

      const dashboardResponse = await getDashboardDetails(brand, teamStore.team.param);
      vehicleDataStore.dashboardSeries = dashboardXForm(dashboardResponse.data, vehicleDataStore.series, teamModule, teamStore.team.param);
      toast.success('Successfully published preview');
      toast.success('Successfully published');
    } catch (e) {
      handleErrorResponse(e, 'Error publishing');
    }
  };

  const handleOnDownload = async (item: DashboardDetailYear, seriesId: string, seriesName: string) => {
    try {
      const version = item.englishVersion || 0;
      const modelYear = item.year;
      const lang = 'EN';
      const fileName = `${seriesName}-${modelYear}-version-${version}`;
      const getDocLink = await trackPromise(getDocumentLink(brand, { seriesId, modelYear, version, lang }));
      const json = JSON.stringify(getDocLink.data, undefined, 4);
      const blob = new Blob([json], { type: 'application/json' });
      const href = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = href;
      link.download = `${fileName}.json`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (e) {
      handleErrorResponse(e, 'Error downloading document');
    }
  };

  const handleOnUpload = async (seriesId: string, modelYear: number, resultJSON?: object) => {
    try {
      uiBlock.start();
      if (resultJSON) {
        if (vehicleDataStore.spanishDraftMap[seriesId][modelYear]) {
          await deleteDraft(brand, teamStore.team.param, {
            seriesId,
            modelYear,
            lang: 'ES',
          });
        }
        await uploadDocument(brand, 'ES', resultJSON);
      }

      const dashboardResponse = await getDashboardDetails(brand, teamStore.team.param);
      vehicleDataStore.dashboardSeries = dashboardXForm(dashboardResponse.data, vehicleDataStore.series, teamModule, teamStore.team.param);
      toast.success('Successfully uploaded file');
    } catch (e) {
      handleErrorResponse(e, 'Error uploading document');
    }
    uiBlock.stop();
  };

  return (
    <>
      <Header moduleTitle={teamStore.team.pageTitle} moduleSubTitle="Dashboard" />
      <ActionBarSeriesManager onShowSeriesManager={onShowSeriesManager} />
      {vehicleDataStore.filteredDashboardData.map(series => (
        <VehicleTable
          key={series.seriesName}
          seriesId={series.id}
          series={series.seriesName}
          data={series.modelYears}
          useNewStatus={teamStore.team.useNewDashboardStatus}
          canAddYear={teamStore.team.allowAddDeleteSeries && series.permissions.canEdit && canEditLanguage}
          onDuplicateItem={handleDuplicateItem}
          onRemoveSeries={handleOnRemoveSeries}
          isSpanish={team === VehicleTeam.AGENCY_SPANISH}
          renderRows={() => (
            <>
              {!(series.modelYears && series.modelYears.length) ? (
                <tr>
                  <td className={styles.tableText}>
                    <span className={styles.content}>No data avaliable.</span>
                  </td>
                  <td className={styles.tableText}></td>
                  <td className={styles.tableText}></td>
                  <td className={styles.tableText}></td>
                  <td className={styles.tableText}></td>
                  {team === VehicleTeam.AGENCY_SPANISH && process.env.REACT_APP_MULTI_LANG === 'true' && <td className={styles.tableText}></td>}
                  {team === VehicleTeam.AGENCY_SPANISH && process.env.REACT_APP_MULTI_LANG === 'true' && <td className={styles.tableText}></td>}
                  <td className={cx(styles.tableText)}></td>
                </tr>
              ) : (
                series.modelYears &&
                series.modelYears.map(item => (
                  <VehicleTableRow
                    key={`${series.id}#${item.year}#${item.version ?? item.spanishVersion ?? 'DRAFT'}`}
                    item={item}
                    seriesId={series.id}
                    seriesName={series.seriesName}
                    team={teamStore.team.name}
                    teamLanguages={teamStore.team.languages}
                    canEdit={series.permissions.canEdit && canEditLanguage}
                    onDeleteItem={handleDeleteItem}
                    onCreateDraft={handleOnCreateDraftFromPublished}
                    onPreview={handleOnPreview}
                    onPublish={handleOnPublish}
                    useNewStatus={teamStore.team.useNewDashboardStatus}
                    allowPreviewPublishSeries={teamStore.team.allowPreviewPublishSeries}
                    showModelDetails={teamStore.team.showModelDetailsForPublish}
                    isSpanish={team === VehicleTeam.AGENCY_SPANISH}
                    onDownload={handleOnDownload}
                    onFileUpload={handleOnUpload}
                    canEditSpanish={modules.Spanish.canEdit}
                    hasSpanishDraft={vehicleDataStore.spanishDraftMap[series.id][item.year] && process.env.REACT_APP_MULTI_LANG === 'true'}
                    brand={brand}
                  />
                ))
              )}
            </>
          )}
        />
      ))}
    </>
  );
});

export default VehicleDashboard;
