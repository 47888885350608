import cx from 'clsx';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';
import { areRichTextValuesDifferent, convertToRichTextObject } from 'vapi-ui-common';
import DropdownEditItem from '../../../../../../components/DropdownEdit/DropdownEditItem';
import inputStyles from '../../../../../../components/Input/input.module.scss';
import { TableCell, TableRowWithShadow } from '../../../../../../components/Table';
import TableDragIcon from '../../../../../../components/Table/components/TableDragIcon';
import { useCategoriesMapSort } from '../../../../../../hooks/useLanguageMapSort';
import useStores from '../../../../../../hooks/useStores';
import { IDValueType } from '../../../../../../models/common.model';
import { CompareFeatureLangMap, CompareType } from '../../../../../../models/compareFeatures.model';
import { Language } from '../../../../../../models/user.model';
import RichTextCell from '../../../../../../routes/vehicleData/components/tableCells/RichTextCell';
import { handleOnLangMapSortNumberUpdate } from '../../../../../../utils/sortUtils';
import SyncTdPRButton from '../../../../components/SyncTdPRButton/SyncTdPRButton';
import ContextMenuCell from '../../../../components/tableCells/ContextMenuCell';
import DropdownEditorCell from '../../../../components/tableCells/DropdownEditorCell';
import FlagsCell from '../../../../components/tableCells/FlagsCell';
import { ICompareFeatureRow } from './ICompareFeatureRow';
import styles from './compareFeatureRow.module.scss';

interface CompareFeatureRowProps extends ICompareFeatureRow {
  index: number;
  draggableProvided: DraggableProvided;
  compareFeatureLangMap: CompareFeatureLangMap;
}

const CompareFeatureRow = ({
  index,
  featureCategoriesMap,
  featureSubCategoriesMap,
  specTypesMap,
  specCategoriesMap,
  compareFeatureLangMap,
  saveCompareFeatureLangMap,
  deleteCompareFeatureLangMap,
  copyCompareFeatureLangMap,
  unlinkCompareFeatureItem,
  draggableProvided,
  readOnly,
  sortMode,
  disclaimerTokens,
  compareFeature,
}: CompareFeatureRowProps) => {
  const { teamStore, compareFeatureStore } = useStores();
  const defaultCompareFeature = compareFeatureLangMap.langs[compareFeatureStore.defaultLang];

  const categoriesMap = defaultCompareFeature.compareType === CompareType.Feature ? featureCategoriesMap : specCategoriesMap;
  const subCategoriesMap = defaultCompareFeature.compareType === CompareType.Feature ? featureSubCategoriesMap : specTypesMap;

  const { sortedList: sortedCat } = useCategoriesMapSort(categoriesMap, Language.EN);
  const { sortedList: sortedSubCat } = useCategoriesMapSort(subCategoriesMap, Language.EN);

  const selectedLangs = compareFeatureStore.allLangs.filter(lang => compareFeatureStore.selectedLangsMap[lang]);
  const index2 = compareFeatureStore.filteredCompareFeatureLangMaps.findIndex(x => x.langs[compareFeatureStore.defaultLang].id === defaultCompareFeature.id);
  const hasEnglishWritePerms = !!compareFeatureStore.langWriteMap.EN?.canEdit;
  const fullEditPermissions = compareFeatureStore.fullEditPermissions;

  /** Compare Feature change handlers */

  const handleOnCategorySelect = (category: IDValueType<string>, compareChangeMessageRequest: boolean, lang: string) => {
    const currentCategory = compareFeatureLangMap.langs[lang].category;
    if (category.value && (currentCategory.value !== category.value || currentCategory.id !== category.id)) {
      compareFeatureLangMap.langs[lang].category = category;
      saveCompareFeatureLangMap({ compareFeatureLangMap, compareChangeMessageRequest, lang });
    }
  };

  const handleOnSubCategorySelect = (subCategory: IDValueType<string>, compareChangeMessageRequest: boolean, lang: string) => {
    const currentSubCategory = compareFeatureLangMap.langs[lang].subCategory;
    if (currentSubCategory.value !== subCategory.value || currentSubCategory.id !== subCategory.id) {
      compareFeatureLangMap.langs[lang].subCategory = subCategory;
      saveCompareFeatureLangMap({ compareFeatureLangMap, compareChangeMessageRequest, lang });
    }
  };

  const handleOnDescriptionChange = (value: string, lang: string) => {
    const compareFeature = compareFeatureLangMap.langs[lang];
    if (areRichTextValuesDifferent(compareFeature.description, value)) {
      compareFeature.description = value;
      saveCompareFeatureLangMap({ compareFeatureLangMap, lang });
    }
  };

  const handleInProgressChange = () => {
    compareFeatureStore.editableLangs.forEach(lang => {
      compareFeatureLangMap.langs[lang].isInProgress = !compareFeatureLangMap.langs[lang].isInProgress;
    });
    saveCompareFeatureLangMap({ compareFeatureLangMap });
  };

  const handleOnNotesChange = (value: string) => {
    let shouldUpdate = false;
    compareFeatureStore.editableLangs.forEach(lang => {
      if (compareFeatureLangMap.langs[lang].notes !== value) {
        compareFeatureLangMap.langs[lang].notes = value;
        shouldUpdate = true;
      }
    });
    if (shouldUpdate) {
      saveCompareFeatureLangMap({ compareFeatureLangMap });
    }
  };

  const changedAttributes = () => {
    const changed: string[] = [];
    compareFeatureStore.editableLangs.forEach(lang => {
      const cFeature = compareFeatureLangMap.langs[lang];
      // console.log(feature.description, '>>>', JSON.parse(JSON.stringify(feature)));

      if (cFeature) {
        changed.push(...cFeature.changedAttributes);
      }
    });
    return changed;
  };

  const showSync = !readOnly && defaultCompareFeature.fromTMNA;

  return (
    <>
      <TableRowWithShadow
        onFillRowHeightChange={(rowHeight: number, border: number) => {
          compareFeatureStore.setRowHeight(compareFeatureLangMap, rowHeight - border);
        }}
        innerRef={draggableProvided.innerRef}
        {...draggableProvided.draggableProps}
        className={styles.tableRow}
      >
        {!readOnly && sortMode && (
          <>
            <TableCell {...draggableProvided.dragHandleProps} border center>
              <TableDragIcon />
            </TableCell>
            <TableCell border center>
              <input
                className={cx(inputStyles.input, inputStyles.smallInput)}
                value={defaultCompareFeature.sortOrder}
                onBlur={newIndex => {
                  handleOnLangMapSortNumberUpdate(compareFeatureStore.filteredCompareFeatureLangMaps, newIndex.target.value, index2);
                  compareFeatureStore.filteredCompareFeatureLangMaps = compareFeatureStore.filteredCompareFeatureLangMaps.slice();
                }}
                onChange={e => (defaultCompareFeature.sortOrder = parseInt(e.currentTarget.value, 10) > 0 ? parseInt(e.currentTarget.value, 10) : '')}
              />
            </TableCell>
          </>
        )}
        {!readOnly && !sortMode && teamStore.team.allowAddDeleteData && (
          <ContextMenuCell
            deleteText={defaultCompareFeature.parentId ? 'Remove Compare Feature' : 'Delete'}
            itemType="Compare Feature"
            description={convertToRichTextObject(defaultCompareFeature.description).text}
            deleteItem={() => deleteCompareFeatureLangMap(compareFeatureLangMap)}
            copyItem={() => copyCompareFeatureLangMap(compareFeatureLangMap)}
          />
        )}

        {!readOnly && (
          <TableCell noSpan>
            {showSync && (
              <SyncTdPRButton
                selectedLangs={selectedLangs}
                id={defaultCompareFeature.id}
                changedAttributes={changedAttributes()}
                onClick={() => {
                  compareFeature(compareFeatureLangMap);
                }}
                className={styles.natButton}
              />
            )}
          </TableCell>
        )}

        {!fullEditPermissions && !readOnly && <TableCell />}
        <TableCell className={styles.categoryColumn} spanClass={styles.flexColumn}>
          {selectedLangs.map(lang => {
            const currentCategory = compareFeatureLangMap.langs[lang].category;
            const currentCategoryValue: string = currentCategory.value;
            return (
              <DropdownEditorCell
                key={`DropdownEditorCell-${currentCategory.id}`}
                error={(() => {
                  if (!compareFeatureStore.langWriteMap[lang]?.canEdit || currentCategoryValue || !fullEditPermissions) {
                    return false;
                  }
                  return true;
                })()}
                disabled={readOnly || !compareFeatureStore.langWriteMap[lang]?.canEdit || !fullEditPermissions}
                value={currentCategoryValue}
                renderList={onClose => (
                  <>
                    {sortedCat?.map(item => {
                      const category = item.category;

                      return (
                        <DropdownEditItem
                          key={category.id}
                          value={category.value}
                          isSelected={currentCategoryValue === category.value}
                          onClose={() => onClose()}
                          onSelect={() => {
                            handleOnCategorySelect(category, currentCategoryValue !== category.value && !!defaultCompareFeature.parentId, lang);
                            onClose();
                          }}
                        />
                      );
                    })}
                  </>
                )}
              />
            );
          })}
        </TableCell>

        <TableCell className={styles.categoryColumn} spanClass={styles.flexColumn}>
          {selectedLangs.map(lang => {
            const currentSubCategory = compareFeatureLangMap.langs[lang].subCategory;
            const currentSubCategoryValue: string = currentSubCategory.value;
            return (
              <DropdownEditorCell
                key={`DropdownEditorCell-${currentSubCategory.id}`}
                error={(() => {
                  if (!compareFeatureStore.langWriteMap[lang]?.canEdit || currentSubCategoryValue || !fullEditPermissions) {
                    return false;
                  }
                  return true;
                })()}
                disabled={readOnly || !compareFeatureStore.langWriteMap[lang]?.canEdit || !fullEditPermissions}
                value={currentSubCategoryValue}
                renderList={onClose => (
                  <>
                    {sortedSubCat?.map(item => {
                      const subCategory = item.category;

                      return (
                        <DropdownEditItem
                          key={subCategory.id}
                          value={subCategory.value}
                          isSelected={currentSubCategoryValue === subCategory.value}
                          onClose={() => onClose()}
                          onSelect={() => {
                            handleOnSubCategorySelect(subCategory, currentSubCategoryValue !== subCategory.value && !!defaultCompareFeature.parentId, lang);
                            onClose();
                          }}
                        />
                      );
                    })}
                  </>
                )}
              />
            );
          })}
        </TableCell>

        {selectedLangs.map(lang => {
          const compareFeature = compareFeatureLangMap.langs[lang];
          return (
            <RichTextCell
              key={`RichTextCell-${compareFeature.id}`}
              required={!compareFeature.description || compareFeature.changedAttributes.includes('description')}
              colType="description"
              suggestionTypes={disclaimerTokens}
              disabled={readOnly || !compareFeatureStore.langWriteMap[lang]?.canEdit}
              value={compareFeature.description}
              onBlur={value => {
                handleOnDescriptionChange(value, lang);
              }}
            />
          );
        })}

        <FlagsCell
          allowGradeApplicability={teamStore.team.allowGradeApplicability}
          disabled={readOnly || !hasEnglishWritePerms}
          index={`${index}-flagcell`}
          notes={defaultCompareFeature.notes}
          rejectNotes={defaultCompareFeature.rejectNotes}
          highlighted={false}
          inProgress={defaultCompareFeature.isInProgress}
          toggleInProgress={() => {
            if (hasEnglishWritePerms) {
              handleInProgressChange();
            }
          }}
          onNotesChange={notes => handleOnNotesChange(notes)}
          toggleCompareArrow={() => {}}
          allowCompareFeature={teamStore.team.allowCompareFeatures}
          allowUnlinkDeleteModal
          unlinkCompareFeatureItem={shouldDelete => {
            unlinkCompareFeatureItem(compareFeatureLangMap, shouldDelete);
          }}
          isCompareHighlighted={!!defaultCompareFeature.parentId}
          type={CompareType.Feature ? CompareType.Feature : CompareType.Spec}
          description={convertToRichTextObject(defaultCompareFeature.description).text}
        />
      </TableRowWithShadow>
    </>
  );
};

export default observer(CompareFeatureRow);
