import { observer } from 'mobx-react-lite';
import React from 'react';
import { TableCell, TableRow } from '../../../../../../../components/Table';
import { BRAND_TDPR } from '../../../../../../../models/user.model';
import { VehicleModelItem, VehicleModelToyota } from '../../../../../../../models/vehicleModel.model';
import styles from './confirmPublishModalTable.module.scss';

interface ConfirmPublishModalTableRowProps {
  brand: string;
  vehicleModel: VehicleModelItem<VehicleModelToyota>;
  isCodeRed?: boolean | undefined;
  isLive?: boolean;
  isFutureLive: boolean;
  noGoLiveDate: boolean;
  goLiveDate: string;
}

const ConfirmPublishModalTableRow = observer(({ brand, vehicleModel, isFutureLive, isLive, isCodeRed, noGoLiveDate, goLiveDate }: ConfirmPublishModalTableRowProps) => {
  return (
    <TableRow zebra>
      <TableCell center className={styles.tableText}>
        {vehicleModel.getVal(brand === BRAND_TDPR ? 'tdprCode' : 'code')}
      </TableCell>
      <TableCell className={styles.tableText}>{vehicleModel.getVal('description')}</TableCell>
      {/* Models with no live date set */}
      {noGoLiveDate && (
        <TableCell className={styles.tableText}>
          <span className={styles.goLive}>No Live Date Set</span>
        </TableCell>
      )}
      {/* Models with go live date in future */}
      {isFutureLive && (
        <TableCell className={styles.tableText}>
          <span className={styles.goLive}>Go Live: {goLiveDate}</span>
        </TableCell>
      )}
      {/* Models that are live */}
      {isLive && (
        <TableCell className={styles.tableText}>
          <span className={styles.isLive}>LIVE</span>
        </TableCell>
      )}
      {/* Models with code red */}
      {isCodeRed && (
        <TableCell className={styles.tableText}>
          <span className={styles.codeRed}>Code Red</span>
        </TableCell>
      )}
    </TableRow>
  );
});

export default ConfirmPublishModalTableRow;
