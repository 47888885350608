import React from 'react';
import { ModalHeader } from 'vapi-ui-common';
import useSyncTMNAChanges from '../../../../hooks/useSyncTMNAChanges';
import { AppliedChangesResponse, ChangeLogTypes } from '../../../../models/changeLog.model';
import { ColorGradeLangItem } from '../../../../models/colors.model';
import { IDValueType, KeyValueType } from '../../../../models/common.model';
import { FuelTypeItemResponse } from '../../../../models/fuelType.model';
import { RefItem } from '../../../../models/refItem.model';
import { ReviewSyncItem } from '../../../../models/review.model';
import { VehicleModelItem, VehicleModelToyota } from '../../../../models/vehicleModel.model';
import SyncTMNAChangesModalBody, { SyncTMNAChangesModalBodyContext } from './SyncTMNAChangesModalBody';

export type EntityType = 'series' | 'models' | 'features' | 'specs' | 'options' | 'exteriorColors' | 'interiorColors' | 'compareFeatures';

export interface AppliedMap {
  applied: {
    [k in ChangeLogTypes]?: {
      [lang: string]: boolean;
    };
  };
}

export interface ChangesMap {
  changes: {
    [k in ChangeLogTypes]?: {
      [lang: string]: ReviewSyncItem;
    };
  };
}

export const entityTypeToNameMap: KeyValueType<string> = {
  series: 'Series Setting',
  models: 'Model',
  features: 'Feature',
  specs: 'Specs',
  options: 'Option',
  exteriorColors: 'Exterior Color',
  interiorColors: 'Interior Color',
  compareFeatures: 'Compare Feature',
};

interface ISyncTMNAChangesModal {
  brand: string;
  team: string;
  seriesId: string;
  year: string;
  entityType: EntityType;
  itemId: string;
  isNew: boolean;
  isDelete: boolean;
  close: (response?: AppliedChangesResponse, shouldDelete?: boolean, unlinkFromTMNA?: boolean) => void;
  parentSeriesId?: string;
  fuelTypes?: IDValueType[];
  vehicleModels?: VehicleModelItem<VehicleModelToyota>[];
  grades?: RefItem[];
  colorGradeLangItems?: ColorGradeLangItem[];
  fuelTypeList?: FuelTypeItemResponse[];
  sourceVersion?: number;
}

const SyncTMNAChangesModal = ({
  brand,
  team,
  seriesId,
  year,
  entityType,
  itemId,
  close,
  isNew,
  isDelete,
  parentSeriesId = '',
  fuelTypes = [],
  vehicleModels = [],
  grades = [],
  colorGradeLangItems = [],
  fuelTypeList = [],
  sourceVersion,
}: ISyncTMNAChangesModal) => {
  const { syncItems, numberChecked, isLoaded, appliedMap, changesMap, setAppliedMap } = useSyncTMNAChanges({
    isNew,
    isDelete,
    sourceVersion,
    brand,
    team,
    seriesId,
    year,
    entityType,
    itemId,
    parentSeriesId,
    colorGradeLangItems,
    vehicleModels,
    fuelTypes,
    grades,
    fuelTypeList,
    close,
  });

  return (
    <>
      <ModalHeader onClose={() => close()}>Sync Changes from TMNA</ModalHeader>
      <SyncTMNAChangesModalBodyContext.Provider
        value={{
          entityType,
          syncItems,
          close,
          numberChecked,
          isNew,
          isDelete,
          isLoaded,
          appliedMap,
          changesMap,
          setAppliedMap,
        }}
      >
        <SyncTMNAChangesModalBody />
      </SyncTMNAChangesModalBodyContext.Provider>
    </>
  );
};

export default SyncTMNAChangesModal;
