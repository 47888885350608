import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Spinner from "../../components/Spinner";
import useRouteParams from "../../hooks/useRouteParams";
import useStores from "../../hooks/useStores";
import { dashboardXForm } from "../../utils/vehicleDataUtils";
import { getSeries, getUserSeries } from "../../webservices/adminApi";
import { getDashboardDetails } from "../../webservices/vehicleAdminApi";
import SeriesManager from "./modules/SeriesManager";
import VehicleDashboard from "./modules/VehicleDashboard";

const VehicleData = observer(() => {
  const { userStore, vehicleDataStore, teamStore } = useStores();

  const { brand, objectId } = userStore;

  const [isLoaded, setIsLoaded] = useState(false);
  const [showSeriesManager, setShowSeriesManager] = useState(true);
  const { team } = useRouteParams();

  useEffect(() => {
    teamStore.setTeam(team, brand, userStore.langPermissions);
    userStore.setTeamModule(team);

    (async () => {
      setIsLoaded(false);
      vehicleDataStore.reset();

      try {
        const allSeriesResponse = await getSeries(brand);
        vehicleDataStore.series = allSeriesResponse.data.series;

        const userSeriesResponse = await getUserSeries(brand, objectId);
        vehicleDataStore.userSeries = userSeriesResponse.data.seriesSelections;

        const dashboardResponse = await getDashboardDetails(
          brand,
          teamStore.team.param
        );
        vehicleDataStore.dashboardSeries = dashboardXForm(
          dashboardResponse.data,
          vehicleDataStore.series,
          userStore.teamModule,
          teamStore.team.param
        );

        if (vehicleDataStore.dashboardSeries.length) {
          setShowSeriesManager(false);
        } else {
          setShowSeriesManager(true);
        }
      } catch (e) {
        toast.error("Error loading vehicle data");
      }

      setIsLoaded(true);
    })();
  }, [teamStore, userStore, vehicleDataStore, brand, objectId, team]);

  return isLoaded ? (
    showSeriesManager ? (
      <SeriesManager onShowSeriesManager={() => setShowSeriesManager(false)} />
    ) : (
      <VehicleDashboard
        onShowSeriesManager={() => setShowSeriesManager(true)}
      />
    )
  ) : (
    <Spinner />
  );
});

export default VehicleData;
