import React, { ReactNode } from "react";
import Tabs from "../Tabs";
import styles from "./secondaryHeader.module.scss";

type SecondaryHeaderProps = {
  tabs: string[];
  selectedTab: string;
  setSelectedTab: (tab: string) => void;
  isTabDisabled?: (tab: string) => boolean;
  renderButtons: () => ReactNode;
};

const SecondaryHeader: React.FC<SecondaryHeaderProps> = ({
  tabs,
  selectedTab,
  setSelectedTab,
  isTabDisabled,
  renderButtons,
}) => {
  return (
    <div className={styles.root}>
      <Tabs
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        isTabDisabled={isTabDisabled}
        tabs={tabs}
      />
      <div className={styles.buttons}>
        {renderButtons()}
      </div>
    </div>
  );
};

export default SecondaryHeader;
