import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { BnPReviewRowContext } from '..';
import { TableRow } from '../../../../../../../components/Table';
import { uiBlock } from '../../../../../../../components/UiBlocker/uiBlock';
import useStores from '../../../../../../../hooks/useStores';
import {
  BnpCategorySplitReview,
  BnPChangeTypeMap,
} from '../../../../../../../models/buildAndPrice.model';
import { VehicleTeam } from '../../../../../../../models/vehicleData.model';
import { handleErrorResponse } from '../../../../../../../utils/errorHandlingUtils';
import { updateReviewBnP } from '../../../../../../../webservices/vehicleBnPApi';
import { ApplyCell } from '../../../../../components/tableCells/ApplyCell';
import ApplicableModelCell from './ApplicableModelCell';
import CategoryCell from './CategoryCell';
import GradeOrDescriptionCell from './GradeOrDescriptionCell';
import NameCell from './NameCell';

interface BuildAndPriceRowProps {
  split?: BnpCategorySplitReview;
  showName?: boolean;
}

const BuildAndPriceRow = ({ split, showName = false }: BuildAndPriceRowProps) => {
  const {
    bnpReviewStore,
    userStore: { brand },
  } = useStores();

  const { team, seriesId, year, version } = useParams<{
    team: VehicleTeam;
    seriesId: string;
    year: string;
    version: string;
  }>();

  const context = useContext(BnPReviewRowContext);

  if (!context) {
    return null;
  }

  const { index, rowSpan, category } = context;

  const saveMap = async (map: BnPChangeTypeMap) => {
    try {
      if (bnpReviewStore.isMapValid(map, team)) {
        uiBlock.start();
        const response = await updateReviewBnP(
          brand,
          team,
          seriesId,
          year,
          version,
          bnpReviewStore.getMapPayload(map, team)
        );
        map.revId = response.data.revId;
        toast.success('Successfully updated bnp');
      } else {
        toast.error('Please fill in the required fields for the bnb');
      }
    } catch (e) {
      handleErrorResponse(e, 'Error updating bnp');
    }

    uiBlock.stop();
  };

  const handleOnMapApplyChange = async (map: BnPChangeTypeMap, value: boolean) => {
    map.isApplied = value;
    saveMap(map);
  };

  const handleOnMapNotesChange = async (map: BnPChangeTypeMap, value: string) => {
    map.rejectNotes = value;
    saveMap(map);
  };

  return (
    <TableRow zebra>
      {index === 0 && (!split || showName) && <NameCell rowSpan={rowSpan} />}

      <ApplyCell
        id={category.id}
        applyChecked={category.isApplied}
        onApplyChecked={isApplied => handleOnMapApplyChange(category, isApplied)}
        notes={category.rejectNotes}
        handleOnNotesChange={notes => handleOnMapNotesChange(category, notes)}
        hideRejectNotes={team === VehicleTeam.AGENCY_TEAM}
      />

      <CategoryCell />

      <GradeOrDescriptionCell split={split} />

      <ApplicableModelCell split={split} />
    </TableRow>
  );
};

export default observer(BuildAndPriceRow);
