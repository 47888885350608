import { observable, makeObservable } from "mobx";

class PublishedStore {
  selectedTab = 'Features';

  constructor() {
    makeObservable(this, {
      selectedTab: observable
    });
  }
}

export default PublishedStore;
