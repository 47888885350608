import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { uiBlock } from "vapi-ui-common";
import { ChangeLogBeforeAfter } from "../../../../components/ChangeLogTable/ChangeLogChanges/ChangeLogChanges";
import Spinner from "../../../../components/Spinner";
import {
  HeaderCell,
  HeaderRow,
  TableRow,
  Thead,
  TwoTableWrapper,
} from "../../../../components/Table";
import useStores from "../../../../hooks/useStores";
import { CompareFeaturesChangeTypeMap } from "../../../../models/compareFeatures.model";
import { VehicleTeam } from "../../../../models/vehicleData.model";
import LeftTable from "../../../../routes/vehicleData/components/LeftTable";
import FlagsCell from "../../../../routes/vehicleData/components/tableCells/FlagsCell";
import VDTableCell from "../../../../routes/vehicleData/components/tableCells/VDTableCell";
import { ProductDataControllerProps } from "../../../../routes/vehicleData/models/controllers.model";
import { handleErrorResponse } from "../../../../utils/errorHandlingUtils";
import { getReviewCompareFeatures } from "../../../../webservices/vehicleCompareFeaturesApi";
import {
  getReviewCategories as getReviewFeatureCategories,
  getReviewSubCategories as getReviewFeatureSubCategories,
} from "../../../../webservices/vehicleFeaturesApi";
import {
  getReviewCategories as getReviewSpecCategories,
  getReviewSpecTypes,
} from "../../../../webservices/vehicleSpecsApi";
import GradeApplicabilityReviewCell from "../../components/GradeTable/components/GradeApplicabilityReviewCell/GradeApplicabilityReviewCell";
import GradeTable from "../../components/GradeTable/GradeTable";
import { ApplyCell } from "../../components/tableCells/ApplyCell";

const CompareFeaturesReview = ({
  seriesId,
  year,
  version = "",
  grades,
}: ProductDataControllerProps) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const {
    teamStore,
    userStore: { brand },
    tableSizeStore: { tableRowHeight },
    compareFeaturesReviewStore,
  } = useStores();

  useEffect(() => {
    compareFeaturesReviewStore.reset();

    (async () => {
      try {
        const responses = await Promise.all([
          getReviewFeatureCategories(
            brand,
            teamStore.team.param,
            seriesId,
            year,
            version
          ),
          getReviewFeatureSubCategories(
            brand,
            teamStore.team.param,
            seriesId,
            year,
            version
          ),
          getReviewSpecCategories(
            brand,
            teamStore.team.param,
            seriesId,
            year,
            version
          ),
          getReviewSpecTypes(
            brand,
            teamStore.team.param,
            seriesId,
            year,
            version
          ),
          getReviewCompareFeatures(
            brand,
            teamStore.team.param,
            seriesId,
            year,
            version
          ),
        ]);
        compareFeaturesReviewStore.grades = grades || [];
        compareFeaturesReviewStore.setCategories(
          responses[0].data,
          responses[2].data
        );
        compareFeaturesReviewStore.setSubCategories(
          responses[1].data,
          responses[3].data
        );
        compareFeaturesReviewStore.setMap(responses[4].data);
      } catch (err) {
        console.log(err);
        toast.error("Error loading Features review data");
      }
      setIsLoaded(true);
    })();
  }, [
    compareFeaturesReviewStore,
    brand,
    seriesId,
    teamStore,
    version,
    year,
    grades,
  ]);

  const handleOnApplyChange = async (
    map: CompareFeaturesChangeTypeMap,
    value: boolean
  ) => {
    map.isApplied = value;
    saveItem(map);
  };

  const handleOnNotesChange = async (
    map: CompareFeaturesChangeTypeMap,
    value: string
  ) => {
    map.rejectNotes = value;
    saveItem(map);
  };

  const saveItem = async (map: CompareFeaturesChangeTypeMap) => {
    try {
      if (compareFeaturesReviewStore.isMapValid(map, teamStore.team.param)) {
        uiBlock.start();
        /* const response = await updateReviewCompareFeature(
          brand,
          teamStore.team.param,
          seriesId,
          year,
          version,
          compareFeaturesReviewStore.getMapPayload(map, teamStore.team.param)
        );
        map.revId = response.data.revId; */
        toast.success("Successfully updated compare feature");
      } else {
        toast.error(
          "Please fill in the required fields for the compare feature"
        );
      }
    } catch (e) {
      handleErrorResponse(e, "Error updating feature");
    }

    uiBlock.stop();
  };

  const renderCells = (map: CompareFeaturesChangeTypeMap, idx: number) => {
    return grades!.map((grade) => {
      const gradeId = grade.id;
      return (
        <GradeApplicabilityReviewCell
          gradeId={gradeId}
          map={map.gradeApplicability}
          isNew={map.isNew}
          rowHeight={map.rowHeight ?? 0}
          isDeleted={map.isDeleted}
        />
      );
    });
  };

  return !isLoaded ? (
    <Spinner />
  ) : (
    <>
      <TwoTableWrapper>
        <LeftTable>
          <Thead>
            <HeaderRow style={{ height: `${tableRowHeight}px` }}>
              <HeaderCell>Apply & Accept?</HeaderCell>
              <HeaderCell>Category</HeaderCell>
              <HeaderCell>Sub Category</HeaderCell>
              <HeaderCell>Description</HeaderCell>
              <HeaderCell />
            </HeaderRow>
          </Thead>
          <tbody>
            {Object.values(compareFeaturesReviewStore.map).map(
              (reviewMap, index) => {
                return (
                  <TableRow
                    key={index}
                    onFillRowHeightChange={(height) => {
                      reviewMap.rowHeight = height;
                    }}
                  >
                    <ApplyCell
                      id={reviewMap.id}
                      applyChecked={reviewMap.isApplied}
                      onApplyChecked={(isApplied) => {
                        handleOnApplyChange(reviewMap, isApplied);
                      }}
                      notes={reviewMap.rejectNotes}
                      handleOnNotesChange={(notes) =>
                        handleOnNotesChange(reviewMap, notes)
                      }
                      hideRejectNotes={
                        teamStore.team.param === VehicleTeam.AGENCY_TEAM
                      }
                    />
                    <VDTableCell colType="dropdown">
                      <ChangeLogBeforeAfter
                        before={reviewMap.category.before}
                        after={reviewMap.category.after}
                        styleAfter={reviewMap.category.hasChanged}
                      />
                    </VDTableCell>
                    <VDTableCell colType="dropdown">
                      <ChangeLogBeforeAfter
                        before={reviewMap.subCategory.before}
                        after={reviewMap.subCategory.after}
                        styleAfter={reviewMap.subCategory.hasChanged}
                      />
                    </VDTableCell>
                    <VDTableCell colType="dropdown">
                      <ChangeLogBeforeAfter
                        before={reviewMap.description.before}
                        after={reviewMap.description.after}
                        styleAfter={reviewMap.description.hasChanged}
                      />
                    </VDTableCell>
                    <FlagsCell
                      index={0}
                      disabled
                      highlighted={false}
                      inProgress={reviewMap.isInProgress}
                      notes={reviewMap.notes}
                      onNotesChange={(notes) =>
                        handleOnNotesChange(reviewMap, notes)
                      }
                    />
                  </TableRow>
                );
              }
            )}
          </tbody>
        </LeftTable>
        <GradeTable
          grades={grades!}
          highlightSortMode={false}
          gradeToggledArr={[]}
          onSelectGrade={(grade) => {}}
          headerStyle={{ top: 0 }}
          renderRows={() => (
            <>
              {Object.values(compareFeaturesReviewStore.map).map(
                (reviewMap, idx) => (
                  <React.Fragment key={reviewMap.id}>
                    <TableRow
                      style={{
                        display: "flex",
                        height: reviewMap.rowHeight ? reviewMap.rowHeight : "",
                      }}
                    >
                      {renderCells(reviewMap, idx)}
                    </TableRow>
                  </React.Fragment>
                )
              )}
            </>
          )}
        />
      </TwoTableWrapper>
    </>
  );
};

export default observer(CompareFeaturesReview);
