import React, { HTMLAttributes } from 'react';
import styles from './changeLogList.module.scss';

const ChangeLogList = (props: HTMLAttributes<HTMLUListElement>) => {
  return (
    <ul className={styles.changeLogList} {...props}/>
  );
};

export default ChangeLogList;
