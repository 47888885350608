// import DisclaimersPublished from "../routes/disclaimers/modules/Published";
import Help from "../routes/help";
// import SeriesCategories from "../routes/seriesCategories";
import ChangeLog from "../routes/vehicleData/modules/ChangeLog";
import Draft from "../routes/vehicleData/modules/Draft/Draft";
import Published from "../routes/vehicleData/modules/Published";
import Review from "../routes/vehicleData/modules/Review";
import VehicleData from "../routes/vehicleData/VehicleData";

export interface AppRoute {
  path: string;
  text: string;
  hide?: boolean;
  component: any; // make this required when there are more components
  childRoutes?: Array<AppRoute>;
}

export const VehicleDataRoutes: Array<AppRoute> = [
  {
    path: "/vehicleData/:team",
    text: "",
    component: VehicleData,
    childRoutes: [
      {
        path: "/vehicleData/draft/:team/:seriesId/:year/:versionInfo",
        text: "Draft",
        hide: true,
        component: Draft,
      },
      {
        path: "/vehicleData/published/:team/:seriesId/:year/:versionInfo",
        text: "Published",
        hide: true,
        component: Published,
      },
      {
        path: "/vehicleData/changeLog/:team/:seriesId/:year/:versionInfo",
        text: "Change Log",
        hide: true,
        component: ChangeLog,
      },
      {
        path: "/vehicleData/review/:team/:seriesId/:year/:version",
        text: "Review",
        hide: true,
        component: Review,
      },
    ],
  },
];

export const DisclaimerRoutes: Array<AppRoute> = [
  {
    path: "/disclaimers/published",
    text: "Disclaimers",
    component: Help,
    childRoutes: [],
  },
];

export const SeriesCategoriesRoutes: AppRoute[] = [
  {
    path: "/seriesCategories",
    text: "Series Categories",
    component: Help,
  },
];

export const RestOfRoutes: Array<AppRoute> = [
  { path: "/help", text: "Help", component: Help },
];
