import cx from 'clsx';
import React, { useEffect, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { toast } from 'react-toastify';
import { Button, Modal } from 'vapi-ui-common';
import Checkbox from '../../../../components/Checkbox';
import Header from '../../../../components/Header';
import useChecklist from '../../../../hooks/useChecklist';
import useStores from '../../../../hooks/useStores';
import { Series, SeriesCategories, SeriesManagerVehicleChecklist, SeriesManagerVehicleChecklistItem, UserSeries, VehicleCategories } from '../../../../models/vehicleData.model';
import { dashboardXForm, getEditableVehicleGroups } from '../../../../utils/vehicleDataUtils';
import { addSeries, updateSeries, updateUserSeries } from '../../../../webservices/adminApi';
import { getDashboardDetails } from '../../../../webservices/vehicleAdminApi';
import AddVehicleModal from '../../components/AddVehicleModal';
import EditSeriesName from '../../components/EditSeriesName/EditSeriesName';
import image from '../SeriesManager/car_jelly.png';
import styles from './seriesManager.module.scss';

interface SeriesManagerProps {
  onShowSeriesManager: () => void;
}

const carImage = image;

const createChecklist = (series: Series, userSeries: UserSeries[]) => {
  const vehicleModels = Object.keys(series).map(key => ({
    ...series[key],
    id: key,
  }));
  const vehiclesChecklist: SeriesManagerVehicleChecklist[] = [];

  vehicleModels.forEach(model => {
    // fetch/add category;
    let category = vehiclesChecklist.filter(listItem => listItem.name === model.group)[0];
    if (!category) {
      category = {
        name: model.group as string,
        items: [],
      };
      vehiclesChecklist.push(category);
    }
    // fetch/add series;
    if (!category.items.filter(item => item.name === model.group).length) {
      category.items.push({
        id: model.id,
        name: model.name,
        selected: userSeries.filter(item => item.id === model.id).length > 0,
        carImage: carImage,
        revId: model.revId,
        group: model.group,
      });
    }
  });

  return vehiclesChecklist;
};

const SeriesManager = ({ onShowSeriesManager }: SeriesManagerProps) => {
  const {
    userStore: { brand, objectId, modules, teamModule },
    vehicleDataStore,
    teamStore,
  } = useStores();

  const { checklist, setChecklist, selectItem, selectAll } = useChecklist();

  const [tabIndex, setTabIndex] = useState(0);
  const [openAddVehicleModal, setAddVehicleModal] = useState(false);
  const canEdit = modules.SeriesSelections.canEdit;
  const canAddSeries = modules.SeriesManager.canEdit;
  const canEditSeries = teamStore.team.allowEditSereisName && Object.values(modules.SeriesManager).length > 0;

  // const { team } = useRouteParams();

  useEffect(() => {
    setChecklist(createChecklist(vehicleDataStore.series, vehicleDataStore.userSeries));
  }, [setChecklist, vehicleDataStore]);

  const handleOnSave = async () => {
    const selectedSeries: string[] = [];
    checklist.forEach(({ items = [] }) => {
      items.forEach(item => {
        if (item.selected) {
          selectedSeries.push(item.id);
        }
      });
    });

    try {
      await trackPromise(updateUserSeries(brand, objectId, selectedSeries));
      vehicleDataStore.userSeries = selectedSeries.map(item => {
        const userSeriesItem = vehicleDataStore.userSeries.filter(ser => ser.id === item);
        const seriesItem = vehicleDataStore.series[Object.keys(vehicleDataStore.series).filter(seriesId => seriesId === item)[0]];
        return userSeriesItem.length ? { ...userSeriesItem[0] } : { id: item, revId: '', name: seriesItem.name };
      });

      const dashboardResponse = await trackPromise(getDashboardDetails(brand, teamStore.team.param));
      vehicleDataStore.dashboardSeries = dashboardXForm(dashboardResponse.data, vehicleDataStore.series, teamModule);

      if (selectedSeries.length) {
        onShowSeriesManager();
      } else {
        // Error
      }
    } catch (e) {
      toast.error('Error saving user series');
    }
  };

  const handleOnAddSeries = async (name: string, group: VehicleCategories) => {
    try {
      const response = await trackPromise(addSeries(brand, { name, group }));
      Object.assign(vehicleDataStore.series, response.data.series);
      setChecklist(createChecklist(vehicleDataStore.series, vehicleDataStore.userSeries));
    } catch (e) {
      toast.error('Error adding series');
    }
  };

  const handleOnUpdateSeries = async (name: string, item: SeriesManagerVehicleChecklistItem) => {
    try {
      const { id, revId, group } = item;
      const response = await trackPromise(
        updateSeries(brand, {
          name,
          id,
          revId,
          group: group as SeriesCategories,
        }),
      );
      const series = response.data.series[id];
      item.revId = series.revId;
      item.name = series.name;

      setChecklist([...checklist]);

      Object.assign(vehicleDataStore.series, response.data.series);

      vehicleDataStore.dashboardSeries.forEach(series => {
        if (series.id === item.id) {
          series.seriesName = item.name;
        }
      });
    } catch (e) {
      toast.error('Error editing series name');
    }
  };

  return (
    <>
      <Header moduleTitle="" moduleSubTitle={`${teamStore.team.pageTitle} Data`}>
        {vehicleDataStore.dashboardSeries.length > 0 && (
          <Button variant="transparent" onClick={() => onShowSeriesManager()}>
            Back to Dashboard
          </Button>
        )}
      </Header>
      <h1 className={styles.heading}>Which series(s) will you be managing?</h1>

      <>
        <div className={styles.seriesManagerContainer}>
          {checklist.map((category, index) => (
            // Category
            <div className={styles.categoriesContainer} key={category.name}>
              <button
                className={cx(styles.categories, {
                  [styles.selected]: tabIndex === index,
                  [styles.long]: category.name === 'Crossover Utility Vehicles',
                  [styles.trucks]: category.name === 'Trucks/SUVs',
                })}
                onClick={() => setTabIndex(index)}
              >
                {category.name}
              </button>
            </div>
          ))}

          {checklist.map((category, index) => (
            <div className={cx({ [styles.tilesContainer]: tabIndex === index })} key={category.name}>
              <div className={styles.tilesWrapper}>
                {tabIndex === index &&
                  category.items &&
                  category.items.map(item => (
                    // Vehicle Checkbox
                    <div key={item.name} className={styles.tile}>
                      <img src={item.carImage} alt={carImage} className={styles.carJellyImage} />
                      {canEditSeries ? (
                        <EditSeriesName
                          checkboxID={`chbox${item.name}`}
                          isChecked={item.selected}
                          item={item as SeriesManagerVehicleChecklistItem}
                          selectItem={selectItem}
                          onUpdateSeries={handleOnUpdateSeries}
                        ></EditSeriesName>
                      ) : (
                        <Checkbox
                          id={`chbox${item.name}`}
                          className={styles.checkbox}
                          checked={item.selected}
                          disabled={!canEdit}
                          onChange={e => selectItem(item, e.currentTarget.checked)}
                        >
                          {item.name}
                        </Checkbox>
                      )}
                    </div>
                  ))}
              </div>
            </div>
          ))}
        </div>
        {/* Selections */}
        <div className={styles.selections}>
          <label htmlFor="selections" className={styles.selectionsLabel}>
            Your Selections:
          </label>
          <span className={styles.list}>
            {checklist.map(category => (
              <span key={category.name}>
                {category.items &&
                  category.items.map(item => (
                    <span key={item.name}>
                      {item.selected && (
                        <Button variant="selection" className={styles.selectionCta} disabled={!canEdit} onClick={() => selectItem(item, false)}>
                          <div className={styles.selectionCtaText} title={item.name}>
                            {item.name}
                          </div>
                        </Button>
                      )}
                    </span>
                  ))}
              </span>
            ))}
          </span>
          {/* Clear All */}
          {canEdit && (
            <Button variant="transparent" onClick={() => selectAll(false)}>
              Clear All
            </Button>
          )}
        </div>
        {/* Save Vehicle(s) */}
        {canEdit && (
          <div className={styles.saveVehicles}>
            <Button variant="primary" onClick={handleOnSave}>
              Save Vehicle(s)
            </Button>
          </div>
        )}
        <div className={styles.seperator}></div>
        {/* Add Vehicle */}
        <div className={styles.addVehicle}>
          {teamStore.team.allowAddDeleteSeries && canAddSeries && (
            <Button variant="primary" onClick={() => setAddVehicleModal(true)}>
              Add Vehicle
            </Button>
          )}
          <Modal open={openAddVehicleModal} onClose={() => setAddVehicleModal(false)}>
            <AddVehicleModal close={() => setAddVehicleModal(false)} onAddSeries={handleOnAddSeries} groups={getEditableVehicleGroups(teamModule, brand)} />
          </Modal>
        </div>
      </>
    </>
  );
};

export default SeriesManager;
