import apolloClient from '../apolloClient';
import { STATUS_DRAFT } from '../constants/vehicleData/VDConstants';
import {
  AddCompareFeatureDocument,
  AddCompareFeatureMutation,
  AddCompareFeatureMutationVariables,
  DeleteCompareFeatureDocument,
  DeleteCompareFeatureMutation,
  DeleteCompareFeatureMutationVariables,
  GetChangeLogCompareFeaturesDocument,
  GetChangeLogCompareFeaturesQuery,
  GetChangeLogCompareFeaturesQueryVariables,
  GetCompareFeaturesDocument,
  GetCompareFeaturesQuery,
  GetCompareFeaturesQueryVariables,
  RevertChangeLogCompareFeaturesDocument,
  RevertChangeLogCompareFeaturesMutation,
  RevertChangeLogCompareFeaturesMutationVariables,
  UpdateCompareFeatureDocument,
  UpdateCompareFeatureMutation,
  UpdateCompareFeatureMutationVariables,
  UpdateDoubleSortListDocument,
  UpdateDoubleSortListMutation,
  UpdateDoubleSortListMutationVariables,
  VehicleCompareDoubleSortList as VehicleCompareDoubleSortListGQL,
  VehicleCompareFeature as VehicleCompareFeatureGQL,
  VehicleFeaturesCompareResponse,
} from '../gql/generated';
import { ChangeLogRequest, ChangeLogResponse } from '../models/changeLog.model';
import { CompareFeatureRequest, CompareFeaturesReviewResponse, CompareGradeApplicabilityMap, CompareType, IDoubleSortList } from '../models/compareFeatures.model';
import { RefItem } from '../models/refItem.model';
import { ReviewChangeRequest } from '../models/review.model';
import { VehicleTeam } from '../models/vehicleData.model';
import {
  vehicleCompareDoubleSortListGQLToHighlightSortList,
  vehicleCompareFeatureGQLToCompareFeatureResponse,
  vehicleFeaturesCompareResponseToFeatureResponse,
} from '../utils/compareFeaturesUtils';
import { getStatusAndVersionGqlReq } from '../utils/vehicleDataUtils';
import API from '../webservices/api';

const RESOURCE_PATH = '/vehicle-features';

export const getCompareFeatures = (brand: string, team: VehicleTeam, seriesId: string, modelYear: string, language: string, version?: string) =>
  apolloClient
    .query<GetCompareFeaturesQuery, GetCompareFeaturesQueryVariables>({
      query: GetCompareFeaturesDocument,
      fetchPolicy: 'no-cache',
      variables: {
        team,
        seriesId,
        modelYear: Number(modelYear),
        language,
        ...getStatusAndVersionGqlReq(version),
      },
    })
    .then(res => ({
      ...res,
      data: vehicleFeaturesCompareResponseToFeatureResponse(res.data.vehicleFeaturesCompare as VehicleFeaturesCompareResponse),
    }));

export const addCompareFeature = (brand: string, team: VehicleTeam, seriesId: string, modelYear: string, language: string, payload: CompareFeatureRequest) =>
  apolloClient
    .mutate<AddCompareFeatureMutation, AddCompareFeatureMutationVariables>({
      mutation: AddCompareFeatureDocument,
      fetchPolicy: 'no-cache',
      variables: {
        team,
        seriesId,
        modelYear: Number(modelYear),
        language,
        ...payload,
        compareType: payload.compareType as any,
        gradeApplicability: Object.entries(payload.gradeApplicability).map(([key, value]) => ({
          ...value,
          id: key,
          applicability: value.applicability as string,
        })),
      },
    })
    .then(res => ({
      ...res,
      data: vehicleCompareFeatureGQLToCompareFeatureResponse(res.data?.createVehicleFeatureCompare as VehicleCompareFeatureGQL),
    }));

export const updateCompareFeature = (
  brand: string,
  team: VehicleTeam,
  seriesId: string,
  modelYear: string,
  language: string,
  payload: CompareFeatureRequest,
  acceptChanges: boolean = false,
  unlinkFromTMNA: boolean = false,
) =>
  apolloClient
    .mutate<UpdateCompareFeatureMutation, UpdateCompareFeatureMutationVariables>({
      mutation: UpdateCompareFeatureDocument,
      fetchPolicy: 'no-cache',
      variables: {
        team,
        seriesId,
        modelYear: Number(modelYear),
        language,
        ...payload,
        compareType: payload.compareType as any,
        gradeApplicability: Object.entries(payload.gradeApplicability).map(([key, value]) => ({
          ...value,
          id: key,
          applicability: value.applicability as string,
        })),
        acceptChanges: acceptChanges || unlinkFromTMNA,
        unlinkFromTMNA,
      },
    })
    .then(res => ({
      ...res,
      data: vehicleCompareFeatureGQLToCompareFeatureResponse(res.data?.updateVehicleFeatureCompare as VehicleCompareFeatureGQL),
    }));

export const deleteCompareFeature = (brand: string, team: VehicleTeam, seriesId: string, modelYear: string, language: string, compareFeatureId: string) =>
  apolloClient.mutate<DeleteCompareFeatureMutation, DeleteCompareFeatureMutationVariables>({
    mutation: DeleteCompareFeatureDocument,
    fetchPolicy: 'no-cache',
    variables: {
      team,
      seriesId,
      modelYear: Number(modelYear),
      language,
      id: compareFeatureId,
    },
  });

// ChangeLog
export const getChangeLog = (brand: string, team: VehicleTeam, seriesId: string, modelYear: string, language: string, version: string) =>
  apolloClient
    .query<GetChangeLogCompareFeaturesQuery, GetChangeLogCompareFeaturesQueryVariables>({
      query: GetChangeLogCompareFeaturesDocument,
      fetchPolicy: 'no-cache',
      variables: {
        team,
        seriesId,
        modelYear: Number(modelYear),
        language,
        ...getStatusAndVersionGqlReq(version),
      },
    })
    .then(res => ({
      ...res,
      data: JSON.parse(res.data.vehicleFeaturesCompareChanges?.body) as ChangeLogResponse[],
    }));

export const revertChange = (brand: string, team: VehicleTeam, seriesId: string, modelYear: string, language: string, payload: ChangeLogRequest) =>
  apolloClient
    .mutate<RevertChangeLogCompareFeaturesMutation, RevertChangeLogCompareFeaturesMutationVariables>({
      mutation: RevertChangeLogCompareFeaturesDocument,
      fetchPolicy: 'no-cache',
      variables: {
        brand,
        team,
        seriesId,
        modelYear: Number(modelYear),
        language,
        body: payload,
      },
    })
    .then(res => ({
      ...res,
      data: JSON.parse(res.data?.revertVehicleFeaturesCompareChanges?.body),
    }));

export const updateDoubleSortList = (brand: string, team: string, seriesId: string, modelYear: string, language: string, payload: IDoubleSortList) =>
  apolloClient
    .mutate<UpdateDoubleSortListMutation, UpdateDoubleSortListMutationVariables>({
      mutation: UpdateDoubleSortListDocument,
      fetchPolicy: 'no-cache',
      variables: {
        team,
        seriesId,
        modelYear: Number(modelYear),
        language,
        doubleSortList: Object.entries(payload.doubleSortList).map(([dId, list]) => ({
          id: dId,
          sortList: Object.entries(list.sortList).map(([id, position]) => ({ id, position })),
        })),
      },
    })
    .then(res => ({
      ...res,
      data: vehicleCompareDoubleSortListGQLToHighlightSortList(res.data?.updateVehicleFeatureHighlightSortList?.doubleSortList as VehicleCompareDoubleSortListGQL[]),
    }));

export const addCompareFeatureFromParent = (
  brand: string,
  team: VehicleTeam,
  seriesId: string,
  year: string,
  lang: string,
  parentId: string,
  compareType: CompareType,
  description: string,
  categoryId: string,
  subCategoryId: string,
  grades: RefItem[],
  id?: string,
) => {
  const gradeApplicability: CompareGradeApplicabilityMap = {};
  grades.forEach(grade => {
    gradeApplicability[grade.id] = {
      highlighted: false,
      text: null,
      applicability: null,
    };
  });
  const request: CompareFeatureRequest = {
    id: id ?? '',
    revId: '',
    compareType,
    parentId,
    description,
    categoryId,
    subCategoryId,
    isInProgress: false,
    notes: '',
    gradeApplicability: {},
  };
  return addCompareFeature(brand, team, seriesId, year, lang, request);
};

// Review
const REVIEW_PATH = '/review';
const COMPARE_REVIEW_PATH = '/compareFeatures';

export const getReviewCompareFeatures = (brand: string, team: VehicleTeam, series: string, year: string, version?: string) => {
  const params = !version || version === 'DRAFT' ? STATUS_DRAFT : `version=${version}`;
  return API.get<CompareFeaturesReviewResponse[]>(`${RESOURCE_PATH}${REVIEW_PATH}${COMPARE_REVIEW_PATH}/${brand}/${team}/${series}/${year}?${params}`);
};

export const updateReviewCompareFeature = (brand: string, team: VehicleTeam, series: string, year: string, version: string, payload: ReviewChangeRequest) => {
  const params = !version || version === 'DRAFT' ? STATUS_DRAFT : `version=${version}`;
  return API.put<CompareFeaturesReviewResponse>(`${RESOURCE_PATH}${REVIEW_PATH}${COMPARE_REVIEW_PATH}/${brand}/${team}/${series}/${year}?${params}`, payload);
};
