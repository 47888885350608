import { observer } from 'mobx-react-lite';
import React from 'react';
import inputStyles from '../../../../../../components/Input/input.module.scss';
import { HeaderRow, LeftTableTH, Thead } from '../../../../../../components/Table';
import useStores from '../../../../../../hooks/useStores';

type Props = {
  readOnly?: boolean;
  showHexCode?: boolean;
  onSort?: (field: string) => void;
  sortMode: boolean;
  languages: string[];
};

const ColorsTabHeaderRow: React.FC<Props> = ({
  readOnly = false,
  showHexCode = false,
  onSort = () => {},
  sortMode,
  languages,
}) => {
  const {
    tableSizeStore: { tableRowHeight },
    colorsStore,
    teamStore,
  } = useStores();

  return (
    <Thead>
      <HeaderRow style={{ height: `${tableRowHeight}px` }}>
        {colorsStore.hasChangedAttributes() && teamStore.team.showAcceptChanges && (
          <LeftTableTH>Accept Changes</LeftTableTH>
        )}
        {!readOnly && <LeftTableTH>&nbsp;</LeftTableTH>}
        {sortMode && <LeftTableTH className={inputStyles.smallInputHeader}>#</LeftTableTH>}
        <LeftTableTH onClick={() => onSort('code')}>Code</LeftTableTH>

        {languages.map(lang => (
          <LeftTableTH
            key={`LeftTableTH-Exterior-${lang}`}
            onClick={() => onSort('name')}
            data-testid={`LeftTableTH-Exterior-${lang}`}
          >{`${lang} Exterior`}</LeftTableTH>
        ))}

        {showHexCode && (
          <LeftTableTH onClick={() => onSort('hexCode')} colSpan={2}>
            Hex Code
          </LeftTableTH>
        )}
      </HeaderRow>
    </Thead>
  );
};

export default observer(ColorsTabHeaderRow);
