import apolloClient from '../apolloClient';
import { STATUS_DRAFT } from '../constants/vehicleData/VDConstants';
import {
  AddExteriorColorDocument,
  AddExteriorColorMutation,
  AddExteriorColorMutationVariables,
  AddInteriorColorDocument,
  AddInteriorColorMutation,
  AddInteriorColorMutationVariables,
  DeleteExteriorColorDocument,
  DeleteExteriorColorMutation,
  DeleteExteriorColorMutationVariables,
  DeleteInteriorColorDocument,
  DeleteInteriorColorMutation,
  DeleteInteriorColorMutationVariables,
  GetChangeLogColorsDocument,
  GetChangeLogColorsQuery,
  GetChangeLogColorsQueryVariables,
  GetExteriorColorsDocument,
  GetExteriorColorsQuery,
  GetExteriorColorsQueryVariables,
  GetInteriorColorsDocument,
  GetInteriorColorsQuery,
  GetInteriorColorsQueryVariables,
  RevertChangeLogColorsDocument,
  RevertChangeLogColorsMutation,
  RevertChangeLogColorsMutationVariables,
  UpdateExteriorColorDocument,
  UpdateExteriorColorMutation,
  UpdateExteriorColorMutationVariables,
  UpdateInteriorColorDocument,
  UpdateInteriorColorMutation,
  UpdateInteriorColorMutationVariables,
  VehicleColorInt as VehicleColorIntGQL,
} from '../gql/generated';
import { ChangeLogRequest, ChangeLogResponse } from '../models/changeLog.model';
import {
  CFExteriorColorRequest,
  CFExteriorColorResponse,
  CFInteriorColorRequest,
  CFInteriorColorResponse,
  ColorFamilyRequest,
  ColorFamilyResponse,
} from '../models/colorFamilies.model';
import { ExteriorColorRequest, ExteriorColorResponse, InteriorColorRequest, InteriorColorType, InteriorColorTypeResponse } from '../models/colors.model';
import { GroupItem, InteriorGroupRequest, VDExteriorColorLexusRequest } from '../models/colorsLexus.model';
import { RefItem, RefItemResponse } from '../models/refItem.model';
import { ReviewChangeRequest } from '../models/review.model';
import { VehicleTeam } from '../models/vehicleData.model';
import { vehicleColorIntGQLToInteriorColorResponse } from '../utils/colorUtils';
import { getStatusAndVersionGqlReq } from '../utils/vehicleDataUtils';
import API from '../webservices/api';

const RESOURCE_PATH = '/vehicle-colors';

// Exterior Colors
const EXTERIOR_COLOR_PATH = '/ext-colors';
export const getExteriorColors = (brand: string, team: VehicleTeam, seriesId: string, modelYear: string, language: string, version?: string) =>
  apolloClient
    .query<GetExteriorColorsQuery, GetExteriorColorsQueryVariables>({
      query: GetExteriorColorsDocument,
      fetchPolicy: 'no-cache',
      variables: {
        team,
        seriesId,
        modelYear: Number(modelYear),
        language,
        ...getStatusAndVersionGqlReq(version),
      },
    })
    .then(res => ({
      ...res,
      data: res.data.vehicleColorsExt?.vehicleColorsExt,
    }));

export const addExteriorColor = (
  brand: string,
  team: VehicleTeam,
  seriesId: string,
  modelYear: string,
  language: string,
  payload: ExteriorColorRequest | VDExteriorColorLexusRequest,
) =>
  apolloClient
    .mutate<AddExteriorColorMutation, AddExteriorColorMutationVariables>({
      mutation: AddExteriorColorDocument,
      fetchPolicy: 'no-cache',
      variables: {
        team,
        seriesId,
        modelYear: Number(modelYear),
        language,
        ...payload,
      },
    })
    .then(res => ({
      ...res,
      data: res.data?.createVehicleColorExt as ExteriorColorResponse,
    }));

export const updateExteriorColor = (
  brand: string,
  team: VehicleTeam,
  seriesId: string,
  modelYear: string,
  language: string,
  payload: ExteriorColorRequest | VDExteriorColorLexusRequest,
  acceptChanges: boolean = false,
  unlinkFromTMNA: boolean = false,
) =>
  apolloClient
    .mutate<UpdateExteriorColorMutation, UpdateExteriorColorMutationVariables>({
      mutation: UpdateExteriorColorDocument,
      fetchPolicy: 'no-cache',
      variables: {
        team,
        seriesId,
        modelYear: Number(modelYear),
        language,
        ...payload,
        acceptChanges: acceptChanges || unlinkFromTMNA,
        unlinkFromTMNA,
      },
    })
    .then(res => ({
      ...res,
      data: res.data?.updateVehicleColorExt as ExteriorColorResponse,
    }));

export const deleteExteriorColor = (brand: string, team: VehicleTeam, seriesId: string, modelYear: string, language: string, colorId: string) =>
  apolloClient.mutate<DeleteExteriorColorMutation, DeleteExteriorColorMutationVariables>({
    mutation: DeleteExteriorColorDocument,
    fetchPolicy: 'no-cache',
    variables: {
      team,
      seriesId,
      modelYear: Number(modelYear),
      language,
      id: colorId,
    },
  });

// Interior Colors
const INTERIOR_COLOR_PATH = '/int-colors';
export const getInteriorColors = (brand: string, team: VehicleTeam, seriesId: string, modelYear: string, language: string, version?: string, includeAll?: boolean) =>
  apolloClient
    .query<GetInteriorColorsQuery, GetInteriorColorsQueryVariables>({
      query: GetInteriorColorsDocument,
      fetchPolicy: 'no-cache',
      variables: {
        team,
        seriesId,
        modelYear: Number(modelYear),
        language,
        includeAll,
        ...getStatusAndVersionGqlReq(version),
      },
    })
    .then(res => ({
      ...res,
      data: res.data.vehicleColorsInt?.vehicleColorsInt?.map(cInt => vehicleColorIntGQLToInteriorColorResponse(cInt)),
    }));

export const addInteriorColor = (brand: string, team: VehicleTeam, seriesId: string, modelYear: string, language: string, payload: InteriorColorRequest) =>
  apolloClient
    .mutate<AddInteriorColorMutation, AddInteriorColorMutationVariables>({
      mutation: AddInteriorColorDocument,
      fetchPolicy: 'no-cache',
      variables: {
        team,
        seriesId,
        modelYear: Number(modelYear),
        language,
        ...payload,
        modelApplicability: Object.entries(payload.modelApplicability).map(([gId, mApp]) => ({
          id: gId,
          colorApplicability: Object.entries(mApp).map(([mId, isApplicable]) => ({ id: mId, isApplicable })),
        })),
      },
    })
    .then(res => ({
      ...res,
      data: vehicleColorIntGQLToInteriorColorResponse(res.data?.createVehicleColorInt as VehicleColorIntGQL),
    }));

export const updateInteriorColor = (
  brand: string,
  team: VehicleTeam,
  seriesId: string,
  modelYear: string,
  language: string,
  payload: InteriorColorRequest,
  acceptChanges: boolean = false,
  unlinkFromTMNA: boolean = false,
) =>
  apolloClient
    .mutate<UpdateInteriorColorMutation, UpdateInteriorColorMutationVariables>({
      mutation: UpdateInteriorColorDocument,
      fetchPolicy: 'no-cache',
      variables: {
        team,
        seriesId,
        modelYear: Number(modelYear),
        language,
        ...payload,
        acceptChanges: acceptChanges || unlinkFromTMNA,
        unlinkFromTMNA,
        modelApplicability: Object.entries(payload.modelApplicability).map(([gId, mApp]) => ({
          id: gId,
          colorApplicability: Object.entries(mApp).map(([mId, isApplicable]) => ({ id: mId, isApplicable })),
        })),
      },
    })
    .then(res => ({
      ...res,
      data: vehicleColorIntGQLToInteriorColorResponse(res.data?.updateVehicleColorInt as VehicleColorIntGQL),
    }));

export const deleteInteriorColor = (brand: string, team: VehicleTeam, seriesId: string, modelYear: string, language: string, colorId: string) =>
  apolloClient.mutate<DeleteInteriorColorMutation, DeleteInteriorColorMutationVariables>({
    mutation: DeleteInteriorColorDocument,
    fetchPolicy: 'no-cache',
    variables: {
      team,
      seriesId,
      modelYear: Number(modelYear),
      language,
      id: colorId,
    },
  });

// Interior Color Types
const INTERIOR_TYPES_PATH = '/int-color-types';
export const getInteriorColorTypes = (brand: string, team: VehicleTeam, series: string, year: string, version?: string, includeAll?: boolean) => {
  let params = version ? `version=${version}` : STATUS_DRAFT;
  if (includeAll) {
    params += '&includeAll=y';
  }
  return API.get<InteriorColorTypeResponse>(`${RESOURCE_PATH}${INTERIOR_TYPES_PATH}/${brand}/${team}/${series}/${year}?${params}`);
};

export const addInteriorColorType = (brand: string, team: VehicleTeam, series: string, year: string, payload: string[], intType?: InteriorColorType) => {
  const params = intType ? `?intType=${intType}` : '';
  return API.post<InteriorColorTypeResponse>(`${RESOURCE_PATH}${INTERIOR_TYPES_PATH}/${brand}/${team}/${series}/${year}${params}`, { intColorTypes: payload });
};

export const updateInteriorColorType = (brand: string, team: VehicleTeam, series: string, year: string, id: string, name: string, intType?: InteriorColorType) => {
  const params = intType ? `?intType=${intType}` : '';
  return API.put(`${RESOURCE_PATH}${INTERIOR_TYPES_PATH}/${brand}/${team}/${series}/${year}/${id}${params}`, { name });
};

// ChangeLog
export const getChangeLog = (brand: string, team: VehicleTeam, seriesId: string, modelYear: string, language: string, version: string) =>
  apolloClient
    .query<GetChangeLogColorsQuery, GetChangeLogColorsQueryVariables>({
      query: GetChangeLogColorsDocument,
      fetchPolicy: 'no-cache',
      variables: {
        team,
        seriesId,
        modelYear: Number(modelYear),
        language,
        ...getStatusAndVersionGqlReq(version),
      },
    })
    .then(res => ({
      ...res,
      data: JSON.parse(res.data.vehicleColorsChanges?.body) as ChangeLogResponse[],
    }));

export const revertChange = (brand: string, team: VehicleTeam, seriesId: string, modelYear: string, language: string, payload: ChangeLogRequest) =>
  apolloClient
    .mutate<RevertChangeLogColorsMutation, RevertChangeLogColorsMutationVariables>({
      mutation: RevertChangeLogColorsDocument,
      fetchPolicy: 'no-cache',
      variables: {
        brand,
        team,
        seriesId,
        modelYear: Number(modelYear),
        language,
        body: payload,
      },
    })
    .then(res => ({
      ...res,
      data: JSON.parse(res.data?.revertVehicleColorsChanges?.body),
    }));

// Review
const REVIEW_PATH = '/review';
export const getReviewInteriorColors = <InteriorReviewResponse>(brand: string, team: VehicleTeam, series: string, year: string, version?: string) => {
  const params = !version || version === 'DRAFT' ? STATUS_DRAFT : `version=${version}`;
  return API.get<InteriorReviewResponse[]>(`${RESOURCE_PATH}${REVIEW_PATH}${INTERIOR_COLOR_PATH}/${brand}/${team}/${series}/${year}?${params}`);
};

export const getReviewExteriorColors = <ExteriorReviewResponse>(brand: string, team: VehicleTeam, series: string, year: string, version?: string) => {
  const params = !version || version === 'DRAFT' ? STATUS_DRAFT : `version=${version}`;
  return API.get<ExteriorReviewResponse[]>(`${RESOURCE_PATH}${REVIEW_PATH}${EXTERIOR_COLOR_PATH}/${brand}/${team}/${series}/${year}?${params}`);
};

export const getReviewInteriorColorTypes = (brand: string, team: VehicleTeam, series: string, year: string, version?: string) => {
  const params = !version || version === 'DRAFT' ? STATUS_DRAFT : `version=${version}`;
  return API.get<InteriorColorTypeResponse>(`${RESOURCE_PATH}${REVIEW_PATH}${INTERIOR_TYPES_PATH}/${brand}/${team}/${series}/${year}?${params}`);
};

export const updateReviewInteriorColors = (brand: string, team: VehicleTeam, series: string, year: string, version: string, payload: ReviewChangeRequest) => {
  const params = !version || version === 'DRAFT' ? STATUS_DRAFT : `version=${version}`;
  return API.put(`${RESOURCE_PATH}${REVIEW_PATH}${INTERIOR_COLOR_PATH}/${brand}/${team}/${series}/${year}?${params}`, payload);
};

export const updateReviewExteriorColors = (brand: string, team: VehicleTeam, series: string, year: string, version: string, payload: ReviewChangeRequest) => {
  const params = !version || version === 'DRAFT' ? STATUS_DRAFT : `version=${version}`;
  return API.put(`${RESOURCE_PATH}${REVIEW_PATH}${EXTERIOR_COLOR_PATH}/${brand}/${team}/${series}/${year}?${params}`, payload);
};

// Interior Color Groups
const INTERIOR_COLOR_GROUPS_PATH = '/groups';
export const addInteriorColorGroup = (brand: string, team: VehicleTeam, seriesId: string, year: string, payload: InteriorGroupRequest) => {
  return API.post<GroupItem>(`${RESOURCE_PATH}${INTERIOR_COLOR_GROUPS_PATH}/${brand}/${team}/${seriesId}/${year}`, payload);
};

export const updateInteriorColorGroup = (brand: string, team: VehicleTeam, seriesId: string, year: string, payload: InteriorGroupRequest) => {
  return API.put<GroupItem>(`${RESOURCE_PATH}${INTERIOR_COLOR_GROUPS_PATH}/${brand}/${team}/${seriesId}/${year}`, payload);
};

export const deleteInteriorColorGroup = (brand: string, team: VehicleTeam, series: string, year: string, groupId: string) => {
  return API.delete(`${RESOURCE_PATH}${INTERIOR_COLOR_GROUPS_PATH}/${brand}/${team}/${series}/${year}/${groupId}`);
};

const COLOR_FAMILY_PATH = '/color-families';
export const getColorFamilies = (brand: string) => {
  return API.get<ColorFamilyResponse[]>(`${RESOURCE_PATH}${COLOR_FAMILY_PATH}/${brand}`);
};

export const addColorFamily = (brand: string, payload: ColorFamilyRequest) => {
  return API.post<ColorFamilyResponse>(`${RESOURCE_PATH}${COLOR_FAMILY_PATH}/${brand}`, payload);
};

export const updateColorFamily = (brand: string, payload: ColorFamilyRequest) => {
  return API.put<ColorFamilyResponse>(`${RESOURCE_PATH}${COLOR_FAMILY_PATH}/${brand}`, payload);
};

export const deleteColorFamily = (brand: string, id: string) => {
  return API.delete(`${RESOURCE_PATH}${COLOR_FAMILY_PATH}/${brand}/${id}`);
};

export const publishColorFamilyDraft = (brand: string) => {
  return API.post(`${RESOURCE_PATH}${COLOR_FAMILY_PATH}/publish/${brand}`);
};

const MATERIALS_PATH = '/materials';

export const getMaterials = (brand: string, version?: string) => {
  let params = !version || version === 'DRAFT' ? STATUS_DRAFT : `version=${version}`;
  return API.get<RefItemResponse[]>(`${RESOURCE_PATH}${MATERIALS_PATH}/${brand}?${params}`);
};

export const addMaterials = (brand: string, payload: string[]) => {
  return API.post<RefItemResponse[]>(`${RESOURCE_PATH}${MATERIALS_PATH}/${brand}`, {
    materials: payload,
  });
};

export const updateMaterials = (brand: string, payload: RefItem) => {
  return API.put<RefItemResponse>(`${RESOURCE_PATH}${MATERIALS_PATH}/${brand}/${payload.id}`, {
    name: payload.value,
  });
};

const CF_INTERIOR_COLOR_PATH = '/cf-int-colors';

export const getCFInteriorColors = (brand: string, version?: string) => {
  let params = !version || version === 'DRAFT' ? STATUS_DRAFT : `version=${version}`;
  return API.get<CFInteriorColorResponse[]>(`${RESOURCE_PATH}${CF_INTERIOR_COLOR_PATH}/${brand}?${params}`);
};

export const addCFInteriorColor = (brand: string, payload: CFInteriorColorRequest) => {
  return API.post<CFInteriorColorResponse>(`${RESOURCE_PATH}${CF_INTERIOR_COLOR_PATH}/${brand}`, payload);
};

export const updateCFInteriorColor = (brand: string, payload: CFInteriorColorRequest) => {
  return API.put<CFInteriorColorResponse>(`${RESOURCE_PATH}${CF_INTERIOR_COLOR_PATH}/${brand}`, payload);
};

export const deleteCFInteriorColor = (brand: string, id: string) => {
  return API.delete(`${RESOURCE_PATH}${CF_INTERIOR_COLOR_PATH}/${brand}/${id}`);
};

const CF_EXTERIOR_COLOR_PATH = '/cf-ext-colors';

export const getCFExteriorColors = (brand: string, version?: string) => {
  let params = !version || version === 'DRAFT' ? STATUS_DRAFT : `version=${version}`;
  return API.get<CFExteriorColorResponse[]>(`${RESOURCE_PATH}${CF_EXTERIOR_COLOR_PATH}/${brand}?${params}`);
};

export const addCFExteriorColor = (brand: string, payload: CFExteriorColorRequest) => {
  return API.post<CFExteriorColorResponse>(`${RESOURCE_PATH}${CF_EXTERIOR_COLOR_PATH}/${brand}`, payload);
};

export const updateCFExteriorColor = (brand: string, payload: CFExteriorColorRequest) => {
  return API.put<CFExteriorColorResponse>(`${RESOURCE_PATH}${CF_EXTERIOR_COLOR_PATH}/${brand}`, payload);
};

export const deleteCFExteriorColor = (brand: string, id: string) => {
  return API.delete(`${RESOURCE_PATH}${CF_EXTERIOR_COLOR_PATH}/${brand}/${id}`);
};
