import { SeriesGradesResponse } from "../gql/generated";
import { IDValueType } from "../models/common.model";
import { RefItem, RefItemResponse } from "../models/refItem.model";

export const seriesGradesResponseToSeriesGrades = (
  gradesResponse: SeriesGradesResponse
): RefItemResponse[] =>
  gradesResponse.seriesGrades?.map((grade) => ({
    id: grade.id,
    object: {
      id: grade.id,
      name: grade.name,
      isDeleted: grade.isDeleted,
      isRequired: grade.isRequired as boolean,
    },
  })) ?? [];

export const refItemsXForm = (
  refItems: RefItemResponse[],
  sortFunction?: (item1: IDValueType, item2: IDValueType) => number
): RefItem[] => {
  const returnArray = refItems
    ? refItems.map((ref) => new RefItem(ref.object))
    : [];
  if (sortFunction) {
    returnArray.sort(sortFunction);
  }
  return returnArray;
};

export const isSpecRefRequired = (
  isSpecRequired: boolean,
  currentItem: RefItem,
  otherItem?: RefItem
) => {
  return (
    isSpecRequired &&
    currentItem.isRequired &&
    (!otherItem || otherItem.value !== "Weights/Capacities")
  );
};
