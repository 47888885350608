import { createContext, useContext } from 'react';
import ChangeLogStore from '../stores/changeLogStore';
import { CommonLanguageStore } from '../stores/commonLanguageStore';
import { DisclaimersStore } from '../stores/disclaimersStore';
import ResizeDetectorStore from '../stores/resizeDetectorStore';
import UserStore from '../stores/userStore';
import { BnPStore } from '../stores/vehicleData/buildAndPriceTabStore';
import ColorsStore from '../stores/vehicleData/colorsStore';
import CompareFeaturesStore from '../stores/vehicleData/compareFeaturesStore';
import DraftStore from '../stores/vehicleData/draftStore';
import FeaturesStore from '../stores/vehicleData/featuresStore';
import ModelTabStore from '../stores/vehicleData/modelTabStore';
import OptionsStore from '../stores/vehicleData/optionsStore';
import PublishedStore from '../stores/vehicleData/publishedStore';
import BuildAndPriceReviewStore from '../stores/vehicleData/review/buildAndPriceReviewStore';
import ColorsExteriorReviewStore from '../stores/vehicleData/review/colorsExteriorReviewStore';
import ColorsInteriorReviewStore from '../stores/vehicleData/review/colorsInteriorReviewStore';
import ColorsReviewStore from '../stores/vehicleData/review/colorsReviewStore';
import CompareFeaturesReviewStore from '../stores/vehicleData/review/compareFeaturesReviewStore';
import FeaturesReviewCLStore from '../stores/vehicleData/review/featuresReviewCLStore';
import FeaturesReviewStore from '../stores/vehicleData/review/featuresReviewStore';
import OptionsReviewStore from '../stores/vehicleData/review/optionsReviewStore';
import ReviewStore from '../stores/vehicleData/review/reviewStore';
import SeriesSettingsReviewStore from '../stores/vehicleData/review/seriesSettingsReviewStore';
import SpecsReviewCLStore from '../stores/vehicleData/review/specsReviewCLStore';
import SpecsReviewStore from '../stores/vehicleData/review/specsReviewStore';
import SeriesSettingsStore from '../stores/vehicleData/seriesSettingsStore';
import SpecsStore from '../stores/vehicleData/specsStore';
import TableSizeStore from '../stores/vehicleData/tableSizeStore';
import { TeamStore } from '../stores/vehicleData/teamStore';
import { VehicleDataStoreTest } from '../stores/vehicleData/vehicleDataStore';
import VehicleModelsStore from '../stores/vehicleData/vehicleModelsStore';
import VehicleSeriesInfoStore from '../stores/vehicleData/vehicleSeriesInfoStore';

const storesContext = createContext({
  resizeDetectorStore: new ResizeDetectorStore(),
  tableSizeStore: new TableSizeStore(),

  userStore: new UserStore(),
  disclaimersStore: new DisclaimersStore(),
  draftStore: new DraftStore(),
  commonLanguageStore: new CommonLanguageStore(),
  colorsStore: new ColorsStore(),

  // vehicle data
  vehicleDataStore: new VehicleDataStoreTest(),
  vehicleModelsStore: new VehicleModelsStore(),
  seriesSettingsStore: new SeriesSettingsStore(),
  teamStore: new TeamStore(),
  publishedStore: new PublishedStore(),
  vehicleSeriesInfoStore: new VehicleSeriesInfoStore(),
  bnpStore: new BnPStore(),
  changeLogStore: new ChangeLogStore(),
  compareFeatureStore: new CompareFeaturesStore(),
  featuresStore: new FeaturesStore(),
  modelTabStore: new ModelTabStore(),
  optionsStore: new OptionsStore(),
  specsStore: new SpecsStore(),

  reviewStore: new ReviewStore(),
  bnpReviewStore: new BuildAndPriceReviewStore(),
  colorsReviewStore: new ColorsReviewStore(),
  colorsExteriorReviewStore: new ColorsExteriorReviewStore(),
  colorsInteriorReviewStore: new ColorsInteriorReviewStore(),
  compareFeaturesReviewStore: new CompareFeaturesReviewStore(),
  featuresReviewStore: new FeaturesReviewStore(),
  featuresReviewCLStore: new FeaturesReviewCLStore(),
  seriesSettingsReviewStore: new SeriesSettingsReviewStore(),
  optionsReviewStore: new OptionsReviewStore(),
  specsReviewStore: new SpecsReviewStore(),
  specsReviewCLStore: new SpecsReviewCLStore(),
});

const useStores = () => useContext(storesContext);

export default useStores;
