import { KeyValueType } from '../models/common.model';
import { CommonLanguageChangeLogResponse, CommonLanguageRequest, CommonLanguageResponse } from '../models/commonLanguage.model';
import API from '../webservices/api';

const RESOURCE_PATH = '/common-language';

// Categories
//
const CATEGORIES_PATH = '/categories';
export const getCategories = (brand: string) => {
  return API.get<{ categories: KeyValueType }>(`${RESOURCE_PATH}${CATEGORIES_PATH}/${brand}`);
};

export const addCategories = (brand: string, data: string[]) => {
  return API.post<{ categories: KeyValueType }>(`${RESOURCE_PATH}${CATEGORIES_PATH}/${brand}`, {
    categories: data,
  });
};

export const updateCategory = (brand: string, id: string, name: string) => {
  return API.put<{ categories: KeyValueType }>(`${RESOURCE_PATH}${CATEGORIES_PATH}/${brand}/${id}`, { name });
};

// Specs
//
const SPECS_PATH = '/specs';
export const getSpecs = (brand: string, status: string, seriesId?: string, year?: string) => {
  // unused ??
  return API.get<CommonLanguageResponse[]>(`${RESOURCE_PATH}${SPECS_PATH}/${brand}?status=${status}${seriesId ? `&seriesId=${seriesId}&modelYear=${year}` : ''}`);
};

export const addSpec = (brand: string, data: CommonLanguageRequest) => {
  return API.post<CommonLanguageResponse>(`${RESOURCE_PATH}${SPECS_PATH}/${brand}`, data);
};

export const updateSpec = (brand: string, data: CommonLanguageRequest) => {
  return API.put<CommonLanguageResponse>(`${RESOURCE_PATH}${SPECS_PATH}/${brand}`, data);
};

export const deleteSpec = (brand: string, id: string, revId: string) => {
  return API.delete(`${RESOURCE_PATH}${SPECS_PATH}/${brand}`, { data: { id, revId } });
};

export const archiveSpec = (brand: string, data: { id: string; revId: string }) => {
  return API.post(`${RESOURCE_PATH}${SPECS_PATH}/${brand}/archive`, data);
};

// Change Log
//
const CHANGE_LOG_PATH = '/change-log';
export const getChangeLog = (brand: string, version: string) => {
  const params = version.toUpperCase() === 'DRAFT' ? `status=${version.toUpperCase()}` : `version=${version}`;
  return API.get<CommonLanguageChangeLogResponse>(`${RESOURCE_PATH}${CHANGE_LOG_PATH}/${brand}?${params}`);
};

export const revertChange = (
  brand: string,
  data: {
    id: string;
    revId: string;
    before: string;
    after: string;
    changeType: string;
  },
) => {
  return API.post(`${RESOURCE_PATH}${CHANGE_LOG_PATH}/${brand}/revert`, data);
};

// Versions
//
export const getCommonLanguageVersions = (brand: string) => {
  return API.get<string[]>(`${RESOURCE_PATH}/versions/${brand}`);
};

// Models
//
export const getModels = (brand: string) => {
  return API.get(`/vehicle-admin/data/${brand}/available-models`);
};

// Draft
//
export const resetDraft = (brand: string) => {
  return API.post(`${RESOURCE_PATH}/draft/${brand}/reset`);
};

export const publishDraft = (brand: string) => {
  return API.post(`${RESOURCE_PATH}/draft/${brand}/publish`);
};
