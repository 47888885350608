import React, { ButtonHTMLAttributes } from 'react';
import styles from './sideMenu.module.scss';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface SideMenuIconProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  icon?: IconDefinition;
}

const SideMenuIcon = ({ className, icon, children, ...rest }: SideMenuIconProps) => {
  return (
    <button className={styles.sideMenuIcon} {...rest}>
      {icon ? <FontAwesomeIcon icon={icon} /> : null}
      {children && <span className={styles.sideMenuChildren}>{children}</span>}
    </button>
  );
};

export default SideMenuIcon;
