import { computed, observable, makeObservable } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import { ChangeLogBase, ChangeLogTypes } from './changeLog.model';

export class DisclaimersItem {
  uid = '';
  id = '';
  revId = '';
  token = '';
  description = '';
  notes = '';
  lastPublishedVersion: number | undefined;
  publishedDate = '';
  lastPublishedDate = '';
  vehicles: DisclaimersVehicle[] = [];

  constructor(disclaimer?: DisclaimersResponse) {
    makeObservable(this, {
      token: observable,
      description: observable,
      notes: observable,
      lastPublishedVersion: observable,
      publishedDate: observable,
      lastPublishedDate: observable,
      vehicles: observable,
      isValid: computed
    });

    this.uid = uuidv4();
    if (disclaimer) {
      Object.assign(this, disclaimer);
    }
  }

  get isValid() {
    return this.description !== '' && this.token !== '';
  }

  getPayload(): DisclaimersRequest {
    const { id, revId, token, description, notes } = this; // vehicles
    return { id, revId, token, description, notes }; // vehicles
  }
}

export interface DisclaimersResponse {
  id: string;
  revId: string;
  token: string;
  description: string;
  notes: string;
  lastPublishedVersion?: number;
  publishedDate?: string;
  lastPublishedDate?: string;
  // vehicles?: DisclaimersVehicle[];
}

export interface DisclaimersRequest {
  id: string;
  revId: string;
  token: string;
  description: string;
  notes: string;
  // vehicles: DisclaimersVehicle[];
}

export interface DisclaimersConfig {
  readOnly?: boolean;
  isArchived?: boolean;
}

export interface DisclaimersChangeLogResponse {
  currentChangeCount?: number;
  lastResetDate?: string;
  changes: DisclaimersChangeLogResponseItem[];
}

export interface DisclaimersChangeLogResponseItem {
  id: string;
  revId: string;
  description: string;
  notes: string;
  changes: DisclaimersChangeLogResponseChange[];
  token: string;
}

export interface DisclaimersChangeLogResponseChange {
  before: string;
  beforeValue: string;
  after: string;
  afterValue: string;
  changeType: string;
  modelsAdded?: string[];
  modelsRemoved?: string[];
  modifiedDate: string;
  modifiedBy: string;
}

export interface DisclaimersChangeLogItem extends ChangeLogBase {
  uid: string;
  id: string;
  revId: string;
  description: string;
  notes: string;
  changeType: ChangeLogTypes;
  before: string;
  beforeValue: string;
  after: string;
  afterValue: string;
  changes: DisclaimersChangeLogChange[];
  modelsAdded?: string[];
  modelsRemoved?: string[];
  modifiedDate: string;
  modifiedBy: string;
  canRevert: boolean;
  token?: string;
}

export interface DisclaimersChangeLogChange {
  added?: boolean;
  removed?: boolean;
  value?: string;
}

export interface DisclaimersVehicle {
  modelYear: string;
  seriesId: string;
  seriesName: string;
  group?: string;
}

export interface DisclaimersVehicleChecklist {
  name: string;
  selected: boolean;
  modelYear: string;
  items: Array<{ id: string; name: string; selected: boolean; year: string }>;
}
