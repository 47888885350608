import apolloClient from '../apolloClient';
import { STATUS_DRAFT } from '../constants/vehicleData/VDConstants';
import {
  AddCategoriesOptionDocument,
  AddCategoriesOptionMutation,
  AddCategoriesOptionMutationVariables,
  AddOptionDocument,
  AddOptionMutation,
  AddOptionMutationVariables,
  DeleteOptionDocument,
  DeleteOptionMutation,
  DeleteOptionMutationVariables,
  GetChangeLogOptionsDocument,
  GetChangeLogOptionsQuery,
  GetChangeLogOptionsQueryVariables,
  GetOptionCategoriesByLangDocument,
  GetOptionCategoriesByLangQuery,
  GetOptionCategoriesByLangQueryVariables,
  GetOptionsDocument,
  GetOptionsQuery,
  GetOptionsQueryVariables,
  RevertChangeLogOptionsDocument,
  RevertChangeLogOptionsMutation,
  RevertChangeLogOptionsMutationVariables,
  UpdateCategoryOptionDocument,
  UpdateCategoryOptionMutation,
  UpdateCategoryOptionMutationVariables,
  UpdateOptionDocument,
  UpdateOptionMutation,
  UpdateOptionMutationVariables,
} from '../gql/generated';
import { CategoryLanguageMap, CategoryResponse } from '../models/category.model';
import { ChangeLogRequest, ChangeLogResponse } from '../models/changeLog.model';
import { OptionRequest, OptionResponse, OptionsTabType } from '../models/options.model';
import { ReviewChangeRequest } from '../models/review.model';
import { VehicleTeam } from '../models/vehicleData.model';
import { getStatusAndVersionGqlReq } from '../utils/vehicleDataUtils';
import API from '../webservices/api';
import { CATEGORIES_PATH, getCategories as _getCategories } from './categoryApi';

const RESOURCE_PATH = '/vehicle-options';

// Options
const OPTIONS_PATH = '/options';
export const getOptions = (brand: string, team: VehicleTeam, seriesId: string, modelYear: string, language: string, version?: string) =>
  apolloClient
    .query<GetOptionsQuery, GetOptionsQueryVariables>({
      query: GetOptionsDocument,
      fetchPolicy: 'no-cache',
      variables: {
        team,
        seriesId,
        modelYear: Number(modelYear),
        language,
        ...getStatusAndVersionGqlReq(version),
      },
    })
    .then(res => ({
      ...res,
      data:
        res.data?.vehicleOptions?.vehicleOptions?.map(vOpt => ({
          ...vOpt,
          modelApplicability: vOpt.modelApplicability?.reduce((map, mApp) => ({ ...map, [mApp.id]: mApp.text }), {}),
        })) ?? [],
    }));

export const addOption = (brand: string, team: VehicleTeam, seriesId: string, modelYear: string, payload: OptionRequest, language: string) =>
  apolloClient
    .mutate<AddOptionMutation, AddOptionMutationVariables>({
      mutation: AddOptionDocument,
      fetchPolicy: 'no-cache',
      variables: {
        team,
        seriesId,
        modelYear: Number(modelYear),
        language,
        ...payload,
        modelApplicability: Object.entries(payload.modelApplicability ?? {}).map(([key, value]) => ({ id: key, text: value })),
      },
    })
    .then(res => ({
      ...res,
      data: {
        ...res.data?.createVehicleOption,
        modelApplicability: res.data?.createVehicleOption?.modelApplicability?.reduce((map, mApp) => ({ ...map, [mApp.id]: mApp.text }), {}),
      } as OptionResponse,
    }));

export const updateOption = (
  brand: string,
  team: VehicleTeam,
  seriesId: string,
  modelYear: string,
  payload: OptionRequest,
  language: string,
  acceptChanges: boolean = false,
  unlinkFromTMNA: boolean = false,
) =>
  apolloClient
    .mutate<UpdateOptionMutation, UpdateOptionMutationVariables>({
      mutation: UpdateOptionDocument,
      fetchPolicy: 'no-cache',
      variables: {
        team,
        seriesId,
        modelYear: Number(modelYear),
        language,
        ...payload,
        modelApplicability: Object.entries(payload.modelApplicability ?? {}).map(([key, value]) => ({ id: key, text: value })),
        acceptChanges: acceptChanges || unlinkFromTMNA,
        unlinkFromTMNA,
      },
    })
    .then(res => ({
      ...res,
      data: {
        ...res.data?.updateVehicleOption,
        modelApplicability: res.data?.updateVehicleOption?.modelApplicability?.reduce((map, mApp) => ({ ...map, [mApp.id]: mApp.text }), {}),
      } as OptionResponse,
    }));

export const deleteOption = (brand: string, team: VehicleTeam, seriesId: string, modelYear: string, language: string, optionsId: string) =>
  apolloClient.mutate<DeleteOptionMutation, DeleteOptionMutationVariables>({
    mutation: DeleteOptionDocument,
    fetchPolicy: 'no-cache',
    variables: {
      team,
      seriesId,
      modelYear: Number(modelYear),
      language,
      id: optionsId,
    },
  });

// Specs
const SPECS_PATH = '/specs';
export const getSpecs = (brand: string, team: VehicleTeam, series: string, year: string, optionsTab: OptionsTabType) => {
  return API.get(`${RESOURCE_PATH}${OPTIONS_PATH}${SPECS_PATH}/${brand}/${team}/${series}/${year}${optionsTab}`);
};

export const deleteSpec = (brand: string, team: VehicleTeam, series: string, year: string, parentId: string, specId: string, optionsTab: OptionsTabType) => {
  return API.delete(`${RESOURCE_PATH}${OPTIONS_PATH}${SPECS_PATH}/${brand}/${team}/${series}/${year}/${optionsTab}/${parentId}/${specId}`);
};

// Varieties
const VARIETIES_PATH = '/varieties';
export const deleteVariety = (brand: string, team: VehicleTeam, series: string, year: string, parentId: string, varietyId: string, optionsTab: OptionsTabType) => {
  return API.delete(`${RESOURCE_PATH}${OPTIONS_PATH}${VARIETIES_PATH}/${brand}/${team}/${series}/${year}/${optionsTab}/${parentId}/${varietyId}`);
};

// ChangeLog
export const getChangeLog = (brand: string, team: VehicleTeam, seriesId: string, modelYear: string, version: string, language: string) =>
  apolloClient
    .query<GetChangeLogOptionsQuery, GetChangeLogOptionsQueryVariables>({
      query: GetChangeLogOptionsDocument,
      fetchPolicy: 'no-cache',
      variables: {
        team,
        seriesId,
        modelYear: Number(modelYear),
        language,
        ...getStatusAndVersionGqlReq(version),
      },
    })
    .then(res => ({
      ...res,
      data: JSON.parse(res.data.vehicleOptionsChanges?.body) as ChangeLogResponse[],
    }));

export const revertChange = (brand: string, team: VehicleTeam, seriesId: string, modelYear: string, language: string, payload: ChangeLogRequest) =>
  apolloClient
    .mutate<RevertChangeLogOptionsMutation, RevertChangeLogOptionsMutationVariables>({
      mutation: RevertChangeLogOptionsDocument,
      fetchPolicy: 'no-cache',
      variables: {
        brand,
        team,
        seriesId,
        modelYear: Number(modelYear),
        language,
        body: payload,
      },
    })
    .then(res => ({
      ...res,
      data: JSON.parse(res.data?.revertVehicleOptionsChanges?.body),
    }));

// Categories
export const getCategories = (brand: string, team: VehicleTeam, series: string, year: string, version?: string, includeAll?: boolean) =>
  _getCategories(RESOURCE_PATH, brand, team, series, year, 'EN', version, includeAll); // this might not be the right way of handling it

export const getCategoriesByLang = (brand: string, team: VehicleTeam, seriesId: string, modelYear: string, language: string, version?: string, includeAll?: boolean) =>
  apolloClient
    .query<GetOptionCategoriesByLangQuery, GetOptionCategoriesByLangQueryVariables>({
      query: GetOptionCategoriesByLangDocument,
      fetchPolicy: 'no-cache',
      variables: {
        team,
        seriesId,
        modelYear: Number(modelYear),
        language,
        includeAll,
        ...getStatusAndVersionGqlReq(version),
      },
    })
    .then(res => ({
      ...res,
      data: res.data.vehicleOptionsCategories?.vehicleOptionsCategories ?? [],
    }));

export const addCategories = (brand: string, team: VehicleTeam, seriesId: string, modelYear: string, payload: { [lang: string]: string }, id?: string) =>
  apolloClient
    .mutate<AddCategoriesOptionMutation, AddCategoriesOptionMutationVariables>({
      mutation: AddCategoriesOptionDocument,
      fetchPolicy: 'no-cache',
      variables: {
        team,
        seriesId,
        modelYear: Number(modelYear),
        categories: Object.entries(payload).map(([key, value]) => ({
          language: key,
          name: value,
        })),
        id,
      },
    })
    .then(res => ({
      ...res,
      data: res.data?.createVehicleOptionCategory?.vehicleOptionsLanguageCategories?.reduce(
        (map, { language, vehicleOptionsCategories }) => ({
          ...map,
          [language]: vehicleOptionsCategories ?? [],
        }),
        {},
      ) as CategoryLanguageMap,
    }));

export const updateCategory = (brand: string, team: VehicleTeam, seriesId: string, modelYear: string, id: string, name: string, language: string) =>
  apolloClient.mutate<UpdateCategoryOptionMutation, UpdateCategoryOptionMutationVariables>({
    mutation: UpdateCategoryOptionDocument,
    fetchPolicy: 'no-cache',
    variables: {
      team,
      seriesId,
      modelYear: Number(modelYear),
      language,
      id,
      name,
    },
  });

// Review
const REVIEW_PATH = '/review';
export const getReviewCategories = (brand: string, team: VehicleTeam, series: string, year: string, version?: string) => {
  const params = !version || version === 'DRAFT' ? STATUS_DRAFT : `version=${version}`;
  return API.get<CategoryResponse[]>(`${RESOURCE_PATH}${REVIEW_PATH}${CATEGORIES_PATH}/${brand}/${team}/${series}/${year}?${params}`);
};

export const getReviewOptions = <OptionsReviewResponseUnion>(brand: string, team: VehicleTeam, series: string, year: string, version = 'DRAFT', optionsTab?: OptionsTabType) => {
  const params: string[] = [];
  params.push(version === 'DRAFT' ? STATUS_DRAFT : `version=${version}`);
  if (optionsTab) {
    params.push(`optionsTab=${optionsTab}`);
  }
  return API.get<OptionsReviewResponseUnion[]>(`${RESOURCE_PATH}${REVIEW_PATH}${OPTIONS_PATH}/${brand}/${team}/${series}/${year}?${params.join('&')}`);
};

export const updateReviewOption = (brand: string, team: VehicleTeam, series: string, year: string, version: string, payload: ReviewChangeRequest, optionsTab?: OptionsTabType) => {
  const params: string[] = [];
  params.push(version === 'DRAFT' ? STATUS_DRAFT : `version=${version}`);
  if (optionsTab) {
    params.push(`optionsTab=${optionsTab}`);
  }
  return API.put<ReviewChangeRequest>(`${RESOURCE_PATH}${REVIEW_PATH}${OPTIONS_PATH}/${brand}/${team}/${series}/${year}?${params.join('&')}`, payload);
};

export const updateReviewOptionSpec = (
  brand: string,
  team: VehicleTeam,
  series: string,
  year: string,
  version: string,
  payload: ReviewChangeRequest,
  optionsTab: OptionsTabType,
) => {
  const params: string[] = [];
  params.push(version === 'DRAFT' ? STATUS_DRAFT : `version=${version}`);
  if (optionsTab) {
    params.push(`optionsTab=${optionsTab}`);
  }
  return API.put<ReviewChangeRequest>(`${RESOURCE_PATH}${REVIEW_PATH}${OPTIONS_PATH}/specs/${brand}/${team}/${series}/${year}/${optionsTab}?${params.join('&')}`, payload);
};

export const updateReviewOptionVariety = (
  brand: string,
  team: VehicleTeam,
  series: string,
  year: string,
  version: string,
  payload: ReviewChangeRequest,
  optionsTab: OptionsTabType,
) => {
  const params: string[] = [];
  params.push(version === 'DRAFT' ? STATUS_DRAFT : `version=${version}`);
  if (optionsTab) {
    params.push(`optionsTab=${optionsTab}`);
  }
  return API.put<ReviewChangeRequest>(`${RESOURCE_PATH}${REVIEW_PATH}${OPTIONS_PATH}/varieties/${brand}/${team}/${series}/${year}/${optionsTab}?${params.join('&')}`, payload);
};
