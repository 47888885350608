import { action, computed, observable, observe, makeObservable } from 'mobx';
import {
  Series,
  SeriesInfoItem,
  SpanishDraftMap,
  UserSeries,
} from '../../models/vehicleData.model';

export class VehicleDataStoreTest {
  dashboardSeries: SeriesInfoItem[] = [];
  dashboardSearchText = '';
  series = {} as Series;
  userSeries: UserSeries[] = [];
  searchText = '';
  spanishDraftMap: SpanishDraftMap = {};

  constructor() {
    makeObservable(this, {
      dashboardSeries: observable,
      dashboardSearchText: observable,
      series: observable,
      userSeries: observable,
      searchText: observable,
      spanishDraftMap: observable,
      filteredDashboardData: computed,
      reset: action
    });

    observe(this, change => {
      if (change.name === 'dashboardSeries') {
        this.resetDraftMap();
      }
    });
  }

  resetDraftMap = () => {
    let spanishDraftMap = {} as SpanishDraftMap;
    for (const series of this.dashboardSeries) {
      spanishDraftMap[series.id] = {};
      series.modelYears.forEach(year => {
        if (!spanishDraftMap[series.id][year.year]) {
          spanishDraftMap[series.id][year.year] = false;
        }
        if (year.isDraft) {
          spanishDraftMap[series.id][year.year] = true;
        }
      });
    }
    this.spanishDraftMap = spanishDraftMap;
  }

  addDraft = (seriesId: string, modelYear: number) => {
    this.dashboardSeries = this.dashboardSeries.map(item =>
      item.id === seriesId
        ? {
            ...item,
            modelYears: [
              ...item.modelYears,
              {
                year: modelYear,
                isDraft: true,
                datePublished: '',
                version: 1,
              },
            ],
          }
        : item
    );
  };

  deleteDraft = (seriesId: string, modelYear: number) => {
    this.dashboardSeries = this.dashboardSeries.map(item =>
      item.id === seriesId
        ? {
            ...item,
            modelYears: item.modelYears.filter(modelObj => modelObj.year !== modelYear),
          }
        : item
    );
  };

  get filteredDashboardData() {
    const loweredSearchText = this.searchText.toLowerCase();
    return this.dashboardSeries.filter(
      item => item.seriesName.toLowerCase().indexOf(loweredSearchText) !== -1
    );
  }

  reset() {
    this.dashboardSeries = [];
    this.dashboardSearchText = '';
    this.series = {} as Series;
    this.userSeries = [];
    this.searchText = '';
  }
}
