import cx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { Button, ModalBody, ModalFooter, ModalHeader, SingleLineRTEditor, SuggestionType } from 'vapi-ui-common';
import Checkbox from '../../../../components/Checkbox';
import IconTextButton from '../../../../components/IconTextButton';
import Input from '../../../../components/Input';
import useChecklist, { ChecklistItem } from '../../../../hooks/useChecklist';
import { IntColorModelApplicability, InteriorColorLangItemMap } from '../../../../models/colors.model';
import { RefItem } from '../../../../models/refItem.model';
import { BRAND_TDPR } from '../../../../models/user.model';
import { VehicleModelItem, VehicleModelToyota } from '../../../../models/vehicleModel.model';
import { gradesWithModelCodeXform } from '../../../../utils/colorUtils';
import SyncTdPRButton from '../SyncTdPRButton/SyncTdPRButton';
import styles from './addEditInteriorColorModal.module.scss';

interface AddEditInteriorColorModalProps {
  onSaveMap: (colorMap: InteriorColorLangItemMap, addIntColor: boolean, acceptChanges?: boolean) => Promise<void>;
  onDeleteMap: (color: InteriorColorLangItemMap) => void;
  close: () => void;
  addIntColor: boolean;
  vehicleModels: VehicleModelItem<VehicleModelToyota>[];
  grades: RefItem[];
  allLangs: string[];
  editableLangs: string[];
  defaultLang: string;
  hasEnglishWritePerms: boolean;
  selectedInteriorColorMap?: InteriorColorLangItemMap;
  readOnly?: boolean;
  disclaimerTokens?: SuggestionType[];
  allowDisclaimerTokens?: boolean;
  compareInteriorColor: (color: InteriorColorLangItemMap) => void;
  brand: string;
  showAcceptChanges: boolean;
}

const AddEditInteriorColorModal = ({
  onSaveMap,
  onDeleteMap,
  close,
  addIntColor,
  vehicleModels,
  grades,
  allowDisclaimerTokens,
  disclaimerTokens,
  selectedInteriorColorMap,
  readOnly,
  editableLangs,
  allLangs,
  defaultLang,
  hasEnglishWritePerms,
  compareInteriorColor,
  brand,
  showAcceptChanges,
}: AddEditInteriorColorModalProps) => {
  const [acceptChanges, setAcceptChanges] = useState(false);
  const { checklist, setChecklist, isAllSelected, selectAll, selectItem } = useChecklist();

  const defaultInteriorColorItem = selectedInteriorColorMap ? selectedInteriorColorMap.langs[defaultLang] : undefined;

  const [selectType, setSelectType] = useState(true);
  const [selectedGrade, setSelectedGrade] = useState('');

  const handleOnDelete = () => {
    if (selectedInteriorColorMap) {
      onDeleteMap(selectedInteriorColorMap);
      close();
    }
  };

  const handleName = (name: string, lang: string) => {
    if (selectedInteriorColorMap) {
      selectedInteriorColorMap.langs[lang].name = name;
    }
  };

  const handleMsrp = (msrp: string) => {
    if (selectedInteriorColorMap) {
      editableLangs.forEach(lang => {
        selectedInteriorColorMap.langs[lang].isExtraCost = msrp;
      });
    }
  };

  const handleModelCode = (code: string) => {
    if (selectedInteriorColorMap) {
      editableLangs.forEach(lang => {
        selectedInteriorColorMap.langs[lang].code = code;
      });
    }
  };

  useEffect(() => {
    const defColor = selectedInteriorColorMap ? selectedInteriorColorMap.langs[defaultLang] : undefined;
    const modelApplicability = defColor?.modelApplicability || ({} as IntColorModelApplicability);

    const gradeList = gradesWithModelCodeXform(vehicleModels, grades, brand);

    if (Object.keys(modelApplicability).length && defColor?.id !== '') {
      gradeList.forEach(grade => {
        Object.entries(modelApplicability).forEach(entry => {
          const [key, value] = entry;
          if (key === grade.id) {
            grade.selected = Object.keys(value).length === grade.items.length;
            grade.items.forEach(item => {
              Object.keys(value).forEach(val => {
                if (item.id === val) {
                  item.selected = true;
                }
              });
            });
          }
        });
      });
    }

    setChecklist(gradeList);
  }, [setChecklist, grades, vehicleModels, selectedInteriorColorMap, defaultLang, brand]);

  const handleModelApplicability = () => {
    if (selectedInteriorColorMap) {
      const modAppObj = {} as IntColorModelApplicability;

      checklist.forEach(grade => {
        if (grade.selected || grade.items?.filter(selected => selected.selected).length) {
          modAppObj[grade.id] = {};
          if (grade.items) {
            grade.items.forEach(model => {
              if (model.selected) {
                modAppObj[grade.id][model.id] = true;
              }
            });
          }
        }
      });
      editableLangs.forEach(lang => {
        selectedInteriorColorMap.langs[lang].modelApplicability = modAppObj;
      });
    }
  };

  const isAcceptChangesVisible = !readOnly && !!editableLangs.filter(lang => !!selectedInteriorColorMap?.langs[lang].changedAttributes.length).length && showAcceptChanges;

  const handleOnSaveMap = () => {
    if (selectedInteriorColorMap) {
      onSaveMap(selectedInteriorColorMap, addIntColor, isAcceptChangesVisible && acceptChanges);
      close();
    }
  };

  const handleOnSelectGrade = (grade: string) => {
    setSelectedGrade(grade === selectedGrade ? '' : grade);
  };

  const changedAttributes = () => {
    const changed: string[] = [];
    if (selectedInteriorColorMap) {
      editableLangs.forEach(lang => {
        const color = selectedInteriorColorMap.langs[lang];
        if (color) {
          changed.push(...color.changedAttributes);
        }
      });
    }
    return changed;
  };

  const showGrade = (grade: ChecklistItem) => {
    if (!grade.items?.length) {
      return false;
    }
    return brand !== BRAND_TDPR || !!grade.items?.find(model => !model.hide);
  };

  const showSync = !readOnly && !!defaultInteriorColorItem?.fromTMNA;

  return (
    <>
      <ModalHeader onClose={close}>{addIntColor ? 'Add' : 'Edit'} Interior Color</ModalHeader>
      <ModalBody>
        {selectType && (
          <div className={styles.dropdownWrapper}>
            {showSync && selectedInteriorColorMap && (
              <SyncTdPRButton
                changedAttributes={changedAttributes()}
                id={defaultInteriorColorItem?.id || ''}
                onClick={() => {
                  compareInteriorColor(selectedInteriorColorMap);
                  close();
                }}
              />
            )}
            <div className={styles.nameInputModelCodeContainer}>
              <div className={styles.nameFieldContainer}>
                {allLangs.map(lang => {
                  const colorItem = selectedInteriorColorMap ? selectedInteriorColorMap.langs[lang] : undefined;
                  if (allowDisclaimerTokens) {
                    return (
                      <SingleLineRTEditor
                        disabled={readOnly || !editableLangs.includes(lang)}
                        value={colorItem ? colorItem.name : ''}
                        onBlur={value => handleName(value.trim(), lang)}
                        suggestionTypes={disclaimerTokens}
                        editorStyles={cx(
                          styles.name,
                          !editableLangs.includes(lang) && styles.disabledColorRTE,
                          colorItem && colorItem.changedAttributes.includes('name') ? styles.errorTextRte : '',
                        )}
                        placeholder="Name"
                        forceBorder
                      />
                    );
                  }
                  return (
                    <Input
                      className={cx(styles.name, colorItem && colorItem.changedAttributes.includes('name') ? styles.errorText : '')}
                      defaultValue={colorItem ? colorItem.name : ''}
                      onChange={e => handleName(e.currentTarget.value.trim(), lang)}
                      name="name"
                      disabled={readOnly}
                      placeholder="Name"
                    />
                  );
                })}
              </div>

              <Input
                className={cx(styles.modelCode, defaultInteriorColorItem && defaultInteriorColorItem.changedAttributes.includes('code') ? styles.errorText : '')}
                defaultValue={defaultInteriorColorItem ? defaultInteriorColorItem.code : ''}
                onChange={e => handleModelCode(e.currentTarget.value.toUpperCase().trim())}
                name="code"
                disabled={readOnly || !hasEnglishWritePerms}
                placeholder="Interior ID"
              />
            </div>

            <span className={cx(styles.currencyInput, allLangs.length > 1 && styles.multiLangCurrencyInput)}>
              <Input
                className={cx(styles.msrp, defaultInteriorColorItem && defaultInteriorColorItem.changedAttributes.includes('isExtraCost') ? styles.errorText : '')}
                defaultValue={defaultInteriorColorItem ? defaultInteriorColorItem.isExtraCost : ''}
                onChange={e => handleMsrp(e.currentTarget.value.trim())}
                name="msrp"
                disabled={readOnly || !hasEnglishWritePerms}
                placeholder="MSRP-Optional"
              />
            </span>

            {isAcceptChangesVisible && (
              <div className={cx([styles.acceptChanges])}>
                <Checkbox
                  id={`acceptChanges-${defaultInteriorColorItem?.id}`}
                  checked={acceptChanges}
                  onChange={e => {
                    setAcceptChanges(!!e.target.checked);
                  }}
                >
                  <span className={styles.checkboxLabel}>Accept Changes</span>
                </Checkbox>
              </div>
            )}
          </div>
        )}

        {!selectType && (
          <>
            <Checkbox
              id="chboxGrdAll"
              className={styles.all}
              checked={isAllSelected}
              onChange={e => {
                selectAll(e.currentTarget.checked);
                handleModelApplicability();
              }}
            >
              <span>All Models</span>
            </Checkbox>
            {checklist &&
              checklist.map(
                item =>
                  showGrade(item) && (
                    <div key={item.name} className={styles.treeMenu}>
                      <div
                        onClick={() => {
                          handleOnSelectGrade(item.name);
                        }}
                        className={cx(styles.down, {
                          [styles.up]: selectedGrade === item.name,
                        })}
                      >
                        <Checkbox
                          id={`chboxGrd${item.name}`}
                          checked={item.selected}
                          onChange={e => {
                            selectItem(item, e.currentTarget.checked);
                            handleModelApplicability();
                          }}
                        >
                          {item.name}
                        </Checkbox>
                      </div>

                      {selectedGrade === item.name &&
                        item.items?.map(
                          model =>
                            !model.hide && (
                              <div key={model.name} className={styles.menuItems}>
                                <Checkbox
                                  id={`chbox${model.name}`}
                                  checked={model.selected}
                                  onChange={e => {
                                    selectItem(model, e.currentTarget.checked);
                                    handleModelApplicability();
                                  }}
                                >
                                  {model.name}
                                </Checkbox>
                              </div>
                            ),
                        )}
                    </div>
                  ),
              )}
          </>
        )}

        {defaultInteriorColorItem && defaultInteriorColorItem.rejectNotes ? (
          <div className={styles.rejectNotes}>
            <IconTextButton icon="purpleCircle" smallIcon text="Reject Notes" disabled />
            <div className={styles.rejectNotesText}>{defaultInteriorColorItem.rejectNotes}</div>
          </div>
        ) : null}
      </ModalBody>
      <ModalFooter>
        {selectType && (
          <>
            <Button
              variant="transparent"
              onClick={() => close()}
              className={cx({
                [styles.cancelCta]: !addIntColor,
              })}
            >
              Cancel
            </Button>
            {!addIntColor && hasEnglishWritePerms && (
              <Button
                variant="primary"
                onClick={() => handleOnDelete()}
                disabled={defaultInteriorColorItem && (defaultInteriorColorItem.name === '' || defaultInteriorColorItem.code === '')}
              >
                Delete
              </Button>
            )}
            {hasEnglishWritePerms ? (
              <Button
                variant="primary"
                onClick={() => setSelectType(false)}
                disabled={defaultInteriorColorItem && (defaultInteriorColorItem.name === '' || defaultInteriorColorItem.code === '')}
              >
                Next
              </Button>
            ) : (
              <Button
                variant="primary"
                onClick={() => handleOnSaveMap()}
                disabled={(() => {
                  for (const lang of editableLangs) {
                    if (selectedInteriorColorMap && (selectedInteriorColorMap.langs[lang].name === '' || selectedInteriorColorMap.langs[lang].code === '')) {
                      return true;
                    }
                  }
                  return false;
                })()}
              >
                Save
              </Button>
            )}
          </>
        )}

        {!selectType && (
          <>
            <Button variant="transparent" onClick={() => setSelectType(true)}>
              Back
            </Button>
            <Button
              variant="primary"
              onClick={() => handleOnSaveMap()}
              disabled={
                checklist.filter(item => {
                  return item.selected || item.items?.some(code => code.selected);
                }).length === 0
              }
            >
              {addIntColor ? 'Add' : 'Save'} Color
            </Button>
          </>
        )}
      </ModalFooter>
    </>
  );
};

export default observer(AddEditInteriorColorModal);
