import { observer } from 'mobx-react-lite';
import React from 'react';
import inputStyles from '../../../../../../components/Input/input.module.scss';
import { HeaderButton, HeaderRow, LeftTableTH, Thead } from '../../../../../../components/Table';
import useStores from '../../../../../../hooks/useStores';
import styles from './compareFeatureHeaderRow.module.scss';

type CompareFeatureHeaderRowProps = {
  readOnly?: boolean;
  onSort?: (field: string) => void;
  sortMode: boolean;
};

const CompareFeatureHeaderRow: React.FC<CompareFeatureHeaderRowProps> = ({ readOnly = false, onSort = () => {}, sortMode }) => {
  const {
    tableSizeStore: { tableRowHeight },
    compareFeatureStore,
    teamStore,
  } = useStores();

  const selectedLangs = compareFeatureStore.allLangs.filter(lang => compareFeatureStore.selectedLangsMap[lang]);

  return (
    <Thead>
      <HeaderRow
        style={{
          height: `${tableRowHeight}px`,
        }}
      >
        {!readOnly && sortMode && <LeftTableTH className={inputStyles.smallInputHeader}>#</LeftTableTH>}
        {!readOnly && !sortMode && teamStore.team.allowAddDeleteData && <LeftTableTH>&nbsp;</LeftTableTH>}
        {!readOnly && <LeftTableTH></LeftTableTH>}

        <LeftTableTH className={styles.category} onClick={() => onSort('category.value')}>
          Category
        </LeftTableTH>
        <LeftTableTH className={styles.category} onClick={() => onSort('subCategory.value')}>
          Sub Category
        </LeftTableTH>

        {selectedLangs.map(lang => (
          <LeftTableTH key={`HeaderRow-Description-${lang}`} className={styles.description}>
            <span style={{ whiteSpace: 'nowrap' }}>
              <HeaderButton onClick={() => onSort('description')} data-testid={`HeaderButton-Description-${lang}`}>{`${lang} Description`}</HeaderButton>
            </span>
          </LeftTableTH>
        ))}

        <LeftTableTH />
      </HeaderRow>
    </Thead>
  );
};

export default observer(CompareFeatureHeaderRow);
