import { computed, makeObservable, observable } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import { cleanUpRte } from 'vapi-ui-common';
import { removeNulls } from '../utils';
import { ChangeLogBase } from './changeLog.model';
import { IDValueType, KeyValueType } from './common.model';
import { ReviewChangeBaseItem, ReviewChangeMap, ReviewChangeRequest, ReviewChangeResponse, ReviewChangeTypeMap } from './review.model';

export type OptionModel = {
  id: string;
  setting: string;
};

export enum OptionSettings {
  STANDARD = 'S',
  OPTIONAL = 'O',
  PACKAGE = 'P',
  UNDEFINED = '',
}

export type OptionsTabType = 'packages' | 'options';

export class OptionItem {
  uid = '';
  id = '';
  revId: string | undefined;
  notes = '';
  models: OptionModel[] = [];
  // modelsMap: KeyValueType<FeatureModel> = {};
  category = {} as IDValueType;
  link = '';
  isInProgress = false;
  description = '';
  code = '';
  name = '';
  rejectNotes = '';
  splits = [];
  isExtraCost = '';
  sortOrder: string | number = 0;
  changedAttributes: string[] = [];
  changedModelIds: string[] = [];
  fromTMNA: boolean = false;

  constructor(option?: OptionResponse) {
    makeObservable(this, {
      notes: observable,
      models: observable,
      category: observable,
      link: observable,
      isInProgress: observable,
      description: observable,
      code: observable,
      name: observable,
      rejectNotes: observable,
      splits: observable,
      isExtraCost: observable,
      sortOrder: observable,
      changedAttributes: observable,
      changedModelIds: observable,
      splitCount: computed,
      fromTMNA: observable,
    });

    this.uid = uuidv4();
    if (option) {
      Object.assign(this, removeNulls(option));
      if (Array.isArray(this.changedAttributes)) {
        this.changedAttributes.forEach(attr => {
          if (attr.includes('modelApplicability#')) {
            const splt = attr.split('#');
            if (splt.length > 1) {
              this.changedModelIds.push(splt[1]);
            }
          }
        });
      }
    }
  }

  get splitCount() {
    return this.splits.length;
  }

  getPayload = () => {
    const modelApplicability = this.models.reduce((retObj, model) => {
      if (model.setting) {
        retObj[model.id] = model.setting;
      }
      return retObj;
    }, {} as OptionModelApplicability);

    const { id, revId, category, description, isInProgress, notes, code, name, link, isExtraCost } = this;

    return {
      id,
      revId,
      categoryId: category.id,
      description: cleanUpRte(description),
      isInProgress,
      notes,
      code,
      name: cleanUpRte(name),
      link,
      modelApplicability,
      isExtraCost,
    } as OptionRequest;
  };

  isValid = () => {
    return !!(this.category.id && this.code && this.name);
  };
}

export interface OptionResponse {
  id: string;
  revId: string;
  categoryId: string;
  name: string;
  code: string;
  description: string;
  notes: string | undefined;
  link: string | undefined;
  isInProgress: boolean;
  modelApplicability?: OptionModelApplicability;
  isExtraCost: string;
  changedAttributes?: string[];
  fromTMNA?: boolean;
}

type OptionModelApplicability = {
  [modelCode: string]: string;
};

export interface OptionRequest extends OptionResponse {}

/**
 * Options Review Item
 */
export class OptionsReviewItem {
  uid = uuidv4();
  id = '';
  revId = '';
  category = {} as IDValueType;
  description = '';
  changes = {} as ChangeLogBase;
  otherChanges: ReviewChangeBaseItem[] = [];
  changeTypeId = '';
  code = '';
  isAccepted = true;
  isApplied = true;
  isNewChange = false;
  rejectNotes = '';
  models: OptionModel[] = [];
  name = '';
  notes = '';
  isInProgress = false;
  isHighlighted = false;
  rowHeight = undefined as number | undefined;
  isExtraCost = '';

  constructor(option?: OptionsReviewResponse, change?: ReviewChangeResponse) {
    makeObservable(this, {
      uid: observable,
      id: observable,
      revId: observable,
      category: observable,
      description: observable,
      changes: observable,
      otherChanges: observable,
      changeTypeId: observable,
      code: observable,
      isAccepted: observable,
      isApplied: observable,
      isNewChange: observable,
      rejectNotes: observable,
      models: observable,
      name: observable,
      notes: observable,
      isInProgress: observable,
      isHighlighted: observable,
      rowHeight: observable,
      isExtraCost: observable,
    });

    this.uid = uuidv4();
    if (option) {
      const { changes, ...rest } = option;
      Object.assign(this, removeNulls(rest));
    }

    if (change) {
      const { after, before, changeTypeId, changeType, ...rest } = change;
      Object.assign(this, removeNulls(rest));
      Object.assign(this.changes, removeNulls({ after, before, changeTypeId, changeType }));
    }

    if (option?.otherChanges) {
      this.otherChanges = Object.entries(option.otherChanges).map(([changeTypeId, otherChange]) => {
        return new ReviewChangeBaseItem(otherChange, changeTypeId);
      });
    }
  }

  getPayload = (): ReviewChangeRequest => {
    return {
      id: this.id,
      revId: this.revId,
      isAccepted: this.isAccepted,
      isApplied: this.isApplied,
      changeTypeId: this.changeTypeId,
      rejectNotes: this.rejectNotes,
    };
  };

  isValid = (): boolean => {
    return this.isAccepted || !!this.rejectNotes;
  };
}

export interface OptionsReviewResponse {
  isHighlighted: string;
  name: string;
  notes: string;
  code: string;
  modelApplicability: OptionModelApplicability;
  link: string;
  revId: string;
  changes: KeyValueType<ReviewChangeResponse>;
  otherChanges?: KeyValueType<ReviewChangeResponse>;
  description: string;
  shortDescription: string;
  isDeleted: boolean;
  isAccepted: boolean;
  isApplied: boolean;
  id: string;
  isInProgress: boolean;
  categoryId: string;
  isExtraCost: string;
}

export interface OptionsReviewMap {
  [id: string]: OptionsChangeTypeMap;
}

export interface OptionsChangeTypeMap extends ReviewChangeTypeMap {
  category: ReviewChangeMap<string>;
  name: ReviewChangeMap<string>;
  description: ReviewChangeMap<string>;
  code: ReviewChangeMap<string>;
  isExtraCost: ReviewChangeMap<string>;
  modelApplicability: ReviewChangeMap<KeyValueType<string>>;
}

export type OptionReviewType = 'category' | 'name' | 'description' | 'code' | 'isExtraCost' | 'modelApplicability' | 'added' | 'deleted';

export interface OptionsMap {
  options: {
    [id: string]: OptionLangMap;
  };
  order: string[];
}

export interface OptionLangMap {
  langs: {
    [lang: string]: OptionItem;
  };
  data?: OptionResponse;
}
