import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { HTMLAttributes } from 'react';
import { DateComponent } from 'vapi-ui-common';
import { DashboardDetailYear, VDStatus } from '../../../../../../models/vehicleData.model';
import styles from './statusIndicator.module.scss';

const Container = (props: HTMLAttributes<HTMLDivElement>) => <div className={styles.container} {...props} />;

const Status = (props: HTMLAttributes<HTMLSpanElement>) => <span className={styles.status} {...props} />;

const Date = (props: HTMLAttributes<HTMLSpanElement>) => <span className={styles.date} {...props} />;

const ParsedDate = ({ value }: { value: string }) => <DateComponent format="MM/DD/YY">{value}</DateComponent>;

interface StatusIndicatorProps {
  item: DashboardDetailYear;
  isSpanish?: boolean;
}

const StatusIndicator = ({ item, isSpanish }: StatusIndicatorProps) => {
  const outOfSync = isSpanish && item.outOfSync ? '(out of sync)' : '';

  const getStatusCaseCertified = ({ spanishVersion, publishDownstreamVersion, skippedLivePublish, previewDownstreamVersion }: DashboardDetailYear) => {
    if (publishDownstreamVersion) {
      if (skippedLivePublish) return 'Published (Prelive)';
      return 'Published (Live)';
    }

    if (previewDownstreamVersion) {
      return 'Published (Preview)';
    }

    return 'Certified';
  };

  if (item.isDraft && !item.vdStatus) {
    return (
      <Container>
        <FontAwesomeIcon className={styles.draft} icon={faCircle} />
        <Status>Draft {outOfSync}</Status>
        {item.createdDate && (
          <Date>
            {' '}
            — <ParsedDate value={item.createdDate} />
          </Date>
        )}
      </Container>
    );
  } else if (item.vdStatus) {
    switch (item.vdStatus.currentStatus) {
      case VDStatus.SUBMITTED: {
        return (
          <Container>
            <FontAwesomeIcon className={styles.submitted} icon={faCircle} />
            <Status>Submitted</Status>
            <Date>
              {' '}
              — <ParsedDate value={item.vdStatus.modifiedDate} />
            </Date>
          </Container>
        );
      }
      case VDStatus.APPROVED: {
        return (
          <Container>
            <FontAwesomeIcon className={styles.approved} icon={faCircle} />
            <Status>Approved</Status>
            <Date>
              {' '}
              — <ParsedDate value={item.vdStatus.modifiedDate} />
            </Date>
          </Container>
        );
      }
      case VDStatus.REJECTED: {
        return (
          <Container>
            <FontAwesomeIcon className={styles.draft} icon={faCircle} />
            <Status>Updates Requested</Status>
            <Date>
              {' '}
              — Last Edited <ParsedDate value={item.vdStatus.modifiedDate} />
            </Date>
          </Container>
        );
      }
      case VDStatus.PUBLISHED: {
        return (
          <Container>
            <FontAwesomeIcon className={styles.published} icon={faCircle} />
            <Status>Published (Live)</Status>
            <Date>
              {' '}
              — <ParsedDate value={item.vdStatus.modifiedDate} />
            </Date>
          </Container>
        );
      }
      case VDStatus.CERTIFIED: {
        return (
          <Container>
            <FontAwesomeIcon className={styles.approved} icon={faCircle} />
            <Status>
              {getStatusCaseCertified(item)} {outOfSync}
            </Status>
            <Date>
              {' '}
              — <ParsedDate value={item.vdStatus.modifiedDate} />
            </Date>
          </Container>
        );
      }
      default: {
        return null;
      }
    }
  } else {
    return null;
  }
};

export default StatusIndicator;
