import apolloClient from '../apolloClient';
import { STATUS_DRAFT } from '../constants/vehicleData/VDConstants';
import {
  DeleteDraftDocument,
  DeleteDraftMutation,
  DeleteDraftMutationVariables,
  DuplicateVehicleDataDocument,
  DuplicateVehicleDataMutation,
  DuplicateVehicleDataMutationVariables,
  EditVehicleDataDocument,
  EditVehicleDataMutation,
  EditVehicleDataMutationVariables,
  GetDashboardDocument,
  GetDashboardQuery,
  GetDashboardQueryVariables,
  GetSortListDocument,
  GetSortListQuery,
  GetSortListQueryVariables,
  GetVehicleDataDocument,
  GetVehicleDataQuery,
  GetVehicleDataQueryVariables,
  PublishDraftDocument,
  PublishDraftMutation,
  PublishDraftMutationVariables,
  ResetDraftCountDocument,
  ResetDraftCountQuery,
  ResetDraftCountQueryVariables,
  ResetDraftDocument,
  ResetDraftMutation,
  ResetDraftMutationVariables,
  SubmitDraftDocument,
  SubmitDraftMutation,
  SubmitDraftMutationVariables,
  SyncUpdatesDocument,
  SyncUpdatesMutation,
  SyncUpdatesMutationVariables,
  UpdateSortListDocument,
  UpdateSortListMutation,
  UpdateSortListMutationVariables,
  VehicleCompareDocument,
  VehicleCompareQuery,
  VehicleCompareQueryVariables,
  VehicleDocument as VehicleDocumentGQL,
  VehicleVersionsDocument,
  VehicleVersionsQuery,
  VehicleVersionsQueryVariables,
} from '../gql/generated';
import { CommonLanguageVehicle } from '../models/commonLanguage.model';
import { TMNASyncReviewResponse } from '../models/review.model';
import { ISortList, VDSortableEntity } from '../models/sort.model';
import { Language } from '../models/user.model';
import { AdminVehicleData, VehicleTeam, VersionResponse } from '../models/vehicleData.model';
import { getStatusAndVersionGqlReq } from '../utils/vehicleDataUtils';
import API from '../webservices/api';

const RESOURCE_PATH = '/vehicle-admin';

// Data
//
const DATA_PATH = '/data';
export const getDashboardDetails = (brand: string, team: VehicleTeam) =>
  apolloClient.query<GetDashboardQuery, GetDashboardQueryVariables>({
    query: GetDashboardDocument,
    fetchPolicy: 'no-cache',
    variables: {
      brand,
      team,
      lang: Language.EN,
    },
  });

export const getVehicleData = (
  brand: string,
  team: VehicleTeam,
  seriesId: string,
  modelYear: string,
  version?: string,
  language: Language = Language.EN, // TODO remove this and update references accordingly
) =>
  apolloClient
    .query<GetVehicleDataQuery, GetVehicleDataQueryVariables>({
      query: GetVehicleDataDocument,
      fetchPolicy: 'no-cache',
      variables: {
        team,
        seriesId,
        modelYear: Number(modelYear),
        language,
        ...getStatusAndVersionGqlReq(version),
      },
    })
    .then(res => {
      const vDoc = res.data.vehicleDocument ?? ({} as VehicleDocumentGQL);
      return {
        ...res,
        data: {
          status: vDoc.status,
          seriesId: vDoc.seriesId,
          modelYear: vDoc.modelYear,
          isPendingVDReview: vDoc.isPendingVDReview,
          isPendingCLReview: vDoc.isPendingCLReview,
          isSubmitted: vDoc.isSubmitted,
          version: vDoc.version as any,
          lastPublishedVersion: vDoc.lastPublishedVersion as number,
          lastPublishedDate: vDoc.lastPublishedDate as string,
          lastSyncDate: vDoc.lastSyncDate as string,
          sourceVersion: vDoc.sourceVersion,
          hasSourceUpdates: vDoc.hasSourceUpdates?.reduce((map, { lang: brand, hasUpdates }) => ({ ...map, [brand]: hasUpdates }), {}),
        } as AdminVehicleData,
      };
    });

export const duplicateVehicleData = (
  brand: string,
  team: VehicleTeam,
  payload: {
    seriesId: string;
    fromModelYear: number;
    toModelYear: number;
    lang: string;
  },
) =>
  apolloClient
    .mutate<DuplicateVehicleDataMutation, DuplicateVehicleDataMutationVariables>({
      mutation: DuplicateVehicleDataDocument,
      fetchPolicy: 'no-cache',
      variables: {
        brand,
        team,
        seriesId: payload.seriesId,
        fromModelYear: payload.fromModelYear,
        toModelYear: payload.toModelYear,
        language: payload.lang ?? Language.EN,
      },
    })
    .then(res => ({ ...res, data: res.data?.duplicateVehicleDocument }));

export const editVehicleData = (brand: string, team: VehicleTeam, payload: { seriesId: string; modelYear: number; lang: string }) =>
  apolloClient
    .mutate<EditVehicleDataMutation, EditVehicleDataMutationVariables>({
      mutation: EditVehicleDataDocument,
      fetchPolicy: 'no-cache',
      variables: {
        brand,
        team,
        seriesId: payload.seriesId,
        modelYear: payload.modelYear,
        language: payload.lang,
      },
    })
    .then(res => ({ ...res, data: res.data?.updateVehicleDocument }));

export const deleteDraft = (brand: string, team: VehicleTeam, payload: { seriesId: string; modelYear: number; lang: string }) =>
  apolloClient
    .mutate<DeleteDraftMutation, DeleteDraftMutationVariables>({
      mutation: DeleteDraftDocument,
      fetchPolicy: 'no-cache',
      variables: {
        brand,
        team,
        seriesId: payload.seriesId,
        modelYear: payload.modelYear,
        language: payload.lang,
      },
    })
    .then(res => ({ ...res, data: res.data?.deleteVehicleDocument }));

export const getAvailableModels = (brand: string, team: VehicleTeam = VehicleTeam.PRODUCT_TEAM) => {
  return API.get<CommonLanguageVehicle[]>(`${RESOURCE_PATH}${DATA_PATH}/${brand}/${team}/available-models`);
};

// Publish Draft
export const publishDraft = (
  brand: string,
  team: string,
  payload: {
    seriesId: string;
    modelYear: number;
    lang?: string;
    publishType: string;
  },
) =>
  apolloClient.mutate<PublishDraftMutation, PublishDraftMutationVariables>({
    mutation: PublishDraftDocument,
    fetchPolicy: 'no-cache',
    variables: {
      brand,
      seriesId: payload.seriesId,
      modelYear: Number(payload.modelYear),
      language: payload.lang ?? Language.EN,
      publishType: payload.publishType,
      republish: false,
    },
  });

export const submitDraft = (brand: string, team: string, payload: { seriesId: string; modelYear: number; langs: string[] }) =>
  apolloClient.mutate<SubmitDraftMutation, SubmitDraftMutationVariables>({
    mutation: SubmitDraftDocument,
    fetchPolicy: 'no-cache',
    variables: {
      brand,
      seriesId: payload.seriesId,
      modelYear: Number(payload.modelYear),
      language: Language.EN,
    },
  });

// Reset Draft
export const resetDraft = (brand: string, team: string, seriesId: string, modelYear: number, langs: { langs: string[] }) =>
  apolloClient.mutate<ResetDraftMutation, ResetDraftMutationVariables>({
    mutation: ResetDraftDocument,
    fetchPolicy: 'no-cache',
    variables: {
      team,
      seriesId,
      modelYear,
      language: Language.EN,
    },
  });

export const getChangesCount = (brand: string, team: string, seriesId: string, modelYear: number) =>
  apolloClient
    .query<ResetDraftCountQuery, ResetDraftCountQueryVariables>({
      query: ResetDraftCountDocument,
      fetchPolicy: 'no-cache',
      variables: {
        team,
        seriesId,
        modelYear,
      },
    })
    .then(res => ({ ...res, data: res.data.vehicleDocumentResetDraftCount }));

// Accept Changes for Vehicle Data review
export const acceptChangesForVDReview = (brand: string, team: string, version: string, payload: { seriesId: string; modelYear: number }) => {
  const params = !version || version === 'DRAFT' ? STATUS_DRAFT : `version=${version}`;
  return API.post(`${RESOURCE_PATH}${DATA_PATH}/${brand}/${team}/review/submit?${params}`, payload);
};

// Accept Changes for Common Language review
export const acceptChangesForCLReview = (brand: string, team: string, version: string, seriesId: string, modelYear: number) => {
  const params = !version || version === 'DRAFT' ? STATUS_DRAFT : `version=${version}`;
  return API.post(`${RESOURCE_PATH}${DATA_PATH}/${brand}/${team}/${seriesId}/${modelYear}/review-cl/submit?${params}`);
};

// Versions
//
export const getTeamVersions = (brand: string, team: string, seriesId: string, modelYear: string, language: string) =>
  apolloClient
    .query<VehicleVersionsQuery, VehicleVersionsQueryVariables>({
      query: VehicleVersionsDocument,
      fetchPolicy: 'no-cache',
      variables: {
        team,
        seriesId,
        modelYear: Number(modelYear),
        language,
      },
    })
    .then(res => ({
      ...res,
      data: res.data.vehicleVersions as VersionResponse[],
    }));

// Sorting
export const getSortList = (brand: string, team: string, seriesId: string, modelYear: string, dataType: VDSortableEntity, version?: string) =>
  apolloClient
    .query<GetSortListQuery, GetSortListQueryVariables>({
      query: GetSortListDocument,
      fetchPolicy: 'no-cache',
      variables: {
        team,
        seriesId,
        modelYear: Number(modelYear),
        dataType,
        ...getStatusAndVersionGqlReq(version),
      },
    })
    .then(res => {
      const sortList = res.data.sortList?.sortList?.reduce((map, item) => ({ ...map, [item.id]: item.position }), {}) ?? {};

      return {
        ...res,
        data: { sortList },
      };
    });

export const updateSortList = (brand: string, team: string, seriesId: string, modelYear: string, dataType: VDSortableEntity, payload: ISortList) =>
  apolloClient
    .mutate<UpdateSortListMutation, UpdateSortListMutationVariables>({
      mutation: UpdateSortListDocument,
      fetchPolicy: 'no-cache',
      variables: {
        team,
        seriesId,
        modelYear: Number(modelYear),
        dataType,
        sortList: Object.entries(payload.sortList).map(([key, value]) => ({
          id: key,
          position: value,
        })),
      },
    })
    .then(res => {
      const sortList = res.data?.updateSortList?.sortList?.reduce((map, item) => ({ ...map, [item.id]: item.position }), {}) ?? {};

      return {
        ...res,
        data: { sortList },
      };
    });

export const getDocumentLink = (
  brand: string,
  payload: {
    seriesId: string;
    modelYear: number;
    version: number;
    lang: string;
  },
) => {
  return API.post(`${RESOURCE_PATH}/data/${brand}/${VehicleTeam.AGENCY_TEAM}/download`, payload);
};

export const uploadDocument = (brand: string, lang: string, resultJSON: object) => {
  return API.post(`${RESOURCE_PATH}/data/${brand}/${VehicleTeam.AGENCY_TEAM}/upload/${lang}`, resultJSON);
};

export const syncSpanishUpdates = (brand: string, team: string, seriesId: string, modelYear: string) =>
  apolloClient
    .mutate<SyncUpdatesMutation, SyncUpdatesMutationVariables>({
      mutation: SyncUpdatesDocument,
      fetchPolicy: 'no-cache',
      variables: {
        team,
        seriesId,
        modelYear: Number(modelYear),
      },
    })
    .then(res => ({ ...res, data: JSON.parse(res.data?.vehicleSync?.body) }));

export const compareItem = (brand: string, team: string, seriesId: string, modelYear: string, entityType: string, itemId: string, parentSeriesId: string = '') =>
  apolloClient
    .query<VehicleCompareQuery, VehicleCompareQueryVariables>({
      query: VehicleCompareDocument,
      fetchPolicy: 'no-cache',
      variables: {
        team,
        seriesId,
        modelYear: Number(modelYear),
        entityType,
        itemId,
        parentSeriesId,
      },
    })
    .then(res => ({
      ...res,
      data: {
        langs: {
          EN: JSON.parse(res.data?.vehicleCompare?.body),
        },
      } as TMNASyncReviewResponse,
    }));
