import { AxiosResponse } from 'axios';
import { action, makeObservable, observable } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import { convertToRichTextObject } from 'vapi-ui-common';
import { CategoriesMap, CategoryResponse } from '../../models/category.model';
import { IDValueType, KeyValueType } from '../../models/common.model';
import { CompareFeatureDocumentResponse, CompareFeatureItem, CompareFeatureResponse, CompareFeaturesMap, CompareType } from '../../models/compareFeatures.model';
import { FeatureItem, FeatureLangMap, FeatureResponse, FeaturesMap } from '../../models/features.model';
import { RefItem } from '../../models/refItem.model';
import { ISortList, VDSortableEntity } from '../../models/sort.model';
import { Language, LanguagePermissions } from '../../models/user.model';
import { VehicleDataVersionInfo, VehicleTeam } from '../../models/vehicleData.model';
import { VehicleModel, VehicleModelItem, VehicleModelPropsToyota, VehicleModelToyota } from '../../models/vehicleModel.model';
import { isSortReverse, sortBy, toLowerCase } from '../../utils';
import { categoriesXForm } from '../../utils/categoryUtils';
import { compareFeatureItemXForm } from '../../utils/compareFeaturesUtils';
import { featureItemXForm, mapEmptyFeatureModels } from '../../utils/featuresUtils';
import parseLangWriteMap from '../../utils/languageUtils';
import { sortCategoriesFunction } from '../../utils/sortUtils';
import { getSortList } from '../../webservices/vehicleAdminApi';
import { getCompareFeatures } from '../../webservices/vehicleCompareFeaturesApi';
import { getCategoriesByLang, getFeatures, getSubCategoriesByLang } from '../../webservices/vehicleFeaturesApi';

class FeaturesStore {
  features: FeatureItem[] = [];
  reverseSort = false;
  sortField = 'id';
  searchText = '';
  isInProgressFilter = false;
  isSyncUpdateFilter = false;
  isHighlightedFilter = false;
  allowCompareFeatures = false;
  isReviewNotesFilter = false;
  categoryFilters: string[] = [];
  categoriesSortList: ISortList = {} as ISortList;
  subCategoriesSortList: ISortList = {} as ISortList;
  compareFeaturesMap: CompareFeaturesMap = { compareFeatures: {}, order: [] };
  featureLangMaps: FeatureLangMap[] = [];
  // langs
  langWriteMap: LanguagePermissions = {};
  allLangs: Language[] = [];
  editableLangs: Language[] = [];
  defaultLang: Language = Language.EN;
  modelApplicabilityLang: Language = Language.EN;
  fullEditPermissions: boolean = false;

  selectedLangsMap: KeyValueType<boolean> = {};
  filteredFeatureLangMaps: FeatureLangMap[] = [];

  categoriesMap: CategoriesMap = { categories: {}, order: [] };
  subCategoriesMap: CategoriesMap = { categories: {}, order: [] };
  viewModelCodes = false;
  showRequiredDescription = true;
  showOptionalDescription = false;
  avaliableGrades: string[] = [];
  rowHeightMap: KeyValueType<number> = {};

  fetchData = async (
    brand: string,
    team: VehicleTeam,
    seriesId: string,
    year: string,
    vehicleModels: VehicleModelItem<VehicleModel<VehicleModelPropsToyota>>[],
    switchShortLongDescription: boolean,
    grades: RefItem[],
    langWriteMap: LanguagePermissions,
    versionInfo: VehicleDataVersionInfo,
  ) => {
    this.reset();
    const { allLangs, editableLangs, defaultLang, selectedLangsMap, fullEditPermissions, modelApplicabilityLang } = parseLangWriteMap(langWriteMap);
    this.langWriteMap = langWriteMap;
    this.allLangs = allLangs;
    this.editableLangs = editableLangs;
    this.defaultLang = defaultLang;
    this.selectedLangsMap = selectedLangsMap;
    this.fullEditPermissions = fullEditPermissions;
    this.modelApplicabilityLang = modelApplicabilityLang;

    const promises: Promise<any>[] = [];
    this.allLangs.forEach(lang => {
      promises.push(
        getCategoriesByLang(brand, team, seriesId, year, lang, versionInfo[lang]?.toString()),
        getSubCategoriesByLang(brand, team, seriesId, year, lang, versionInfo[lang]?.toString()),
        getFeatures(brand, team, seriesId, year, lang, versionInfo[lang]?.toString()),
        getCompareFeatures(brand, team, seriesId, year, lang, versionInfo[lang]?.toString()),
      );
    });
    const responses = await Promise.all(promises);
    this.showRequiredDescription = true;
    this.showOptionalDescription = false;

    // fetch the sort order for the default lang (currently default is only EN)
    if (this.defaultLang && versionInfo[this.defaultLang]) {
      const sortResponses = await Promise.all([
        getSortList(brand, team, seriesId, year, VDSortableEntity.FEATURES_CATEGORIES, versionInfo[this.defaultLang]?.toString()),
        getSortList(brand, team, seriesId, year, VDSortableEntity.FEATURES_SUBCATEGORIES, versionInfo[this.defaultLang]?.toString()),
      ]);
      this.categoriesMap = { categories: {}, order: [] };
      this.categoriesSortList = sortResponses[0].data;

      this.subCategoriesMap = { categories: {}, order: [] };
      this.subCategoriesSortList = sortResponses[1].data;
    }

    let index = 0;
    for (const lang of this.allLangs) {
      this.updateCategoriesLangMap(lang, this.categoriesMap, responses[index].data, this.categoriesSortList);
      this.updateCategoriesLangMap(lang, this.subCategoriesMap, responses[index + 1].data, this.subCategoriesSortList);
      index += 4;
    }
    this.fillOutCategoriesMap(this.categoriesMap);
    this.fillOutCategoriesMap(this.subCategoriesMap);

    index = 0;
    for (const lang of this.allLangs) {
      const cats: IDValueType<string>[] = this.getCategoriesForLang(lang, this.categoriesMap);
      const subCats: IDValueType<string>[] = this.getCategoriesForLang(lang, this.subCategoriesMap);

      const data = (responses[index + 3] as AxiosResponse<CompareFeatureDocumentResponse>).data;
      data.compareFeatures.forEach(compareFeature => {
        if (compareFeature.compareType === CompareType.Feature && !!compareFeature.parentId) {
          if (!this.compareFeaturesMap.compareFeatures[compareFeature.parentId]) {
            this.compareFeaturesMap.compareFeatures[compareFeature.parentId] = {
              langs: {},
              data: compareFeature,
            };
          }
          this.compareFeaturesMap.compareFeatures[compareFeature.parentId].langs[lang] = compareFeatureItemXForm(compareFeature, grades, cats, subCats, [], [], 0);
        }
      });
      index += 4;
    }
    this.fillOutCompareFeaturesLangMap(this.compareFeaturesMap, grades);

    index = 0;
    const featuresMap: FeaturesMap = { features: {}, order: [] };
    for (const lang of this.allLangs) {
      this.updateFeaturesLangMap(lang, featuresMap, responses[index + 2].data, vehicleModels, switchShortLongDescription);
      index += 4;
    }
    this.fillOutFeaturesLangMap(featuresMap, vehicleModels, switchShortLongDescription);
    this.setFeatureLangMaps(featuresMap);
  };

  updateCategoriesLangMap = (lang: Language, categoriesMap: CategoriesMap, data: CategoryResponse[], sortList?: ISortList) => {
    const useSortList = sortList && !!Object.keys(sortList.sortList).length;
    const categories: IDValueType<string>[] = useSortList && sortList ? categoriesXForm(data, sortCategoriesFunction(sortList.sortList)) : categoriesXForm(data);
    categories.forEach(cat => {
      if (!categoriesMap.categories[cat.id]) {
        categoriesMap.categories[cat.id] = {};
      }
      categoriesMap.categories[cat.id][lang] = cat;
      if (!categoriesMap.order.includes(cat.id)) {
        categoriesMap.order.push(cat.id);
      }
    });
  };

  /**
   * Ensure that every language has all possibly categories/subcategories.
   * @param langs
   * @param categoriesMap
   */
  fillOutCategoriesMap = (categoriesMap: CategoriesMap) => {
    Object.values(categoriesMap.categories).forEach(cat => {
      const defaultId = cat.EN ? cat.EN.id : Object.values(cat)[0].id;
      this.allLangs.forEach(lang => {
        if (!cat[lang]) {
          cat[lang] = new IDValueType<string>(defaultId, '');
        }
      });
    });
  };

  getCategoriesForLang = (lang: string, categoriesMap: CategoriesMap) => {
    const cats: IDValueType<string>[] = [];
    Object.keys(categoriesMap.categories).forEach(catId => {
      if (categoriesMap.categories[catId][lang]) {
        cats.push(categoriesMap.categories[catId][lang]);
      }
    });
    return cats;
  };

  getDefaultCategories = (categoriesMap: CategoriesMap) => {
    const lang = this.defaultLang;
    const cats: IDValueType<string>[] = [];
    Object.keys(categoriesMap.categories).forEach(catId => {
      if (categoriesMap.categories[catId][lang]) {
        cats.push(categoriesMap.categories[catId][lang]);
      }
    });
    return cats;
  };

  updateFeaturesLangMap = (
    lang: string,
    featuresMap: FeaturesMap,
    data: FeatureResponse[],
    vehicleModels: VehicleModelItem<VehicleModel<VehicleModelPropsToyota>>[],
    switchShortLongDescription: boolean,
  ) => {
    const cats: IDValueType<string>[] = this.getCategoriesForLang(lang, this.categoriesMap);
    const subCats: IDValueType<string>[] = this.getCategoriesForLang(lang, this.subCategoriesMap);

    const compareFeatureMap: KeyValueType<CompareFeatureItem> = this.getCompareFeatureMapForLang(lang);

    data.forEach(item => {
      const index = featuresMap.order.findIndex(id => id === item.id);
      const feature = featureItemXForm(item, vehicleModels, cats, subCats, switchShortLongDescription, index >= 0 ? index : featuresMap.order.length, compareFeatureMap);
      if (!featuresMap.features[feature.id]) {
        featuresMap.features[feature.id] = { data: item, langs: {} };
      }
      featuresMap.features[feature.id].langs[lang] = feature;
      if (!featuresMap.order.includes(feature.id)) {
        featuresMap.order.push(feature.id);
      }
    });
  };

  fillOutFeaturesLangMap = (featuresMap: FeaturesMap, vehicleModels: VehicleModelItem<VehicleModel<VehicleModelPropsToyota>>[], switchShortLongDescription: boolean) => {
    Object.keys(featuresMap.features).forEach(featureId => {
      const featureLangMap = featuresMap.features[featureId];
      const featureData: FeatureResponse = featureLangMap.data ?? ({} as FeatureResponse);
      const index = featuresMap.order.findIndex(id => id === featureId);

      this.allLangs.forEach(lang => {
        if (!featureLangMap.langs[lang]) {
          featureLangMap.langs[lang] = featureItemXForm(
            featureData,
            vehicleModels,
            this.getCategoriesForLang(lang, this.categoriesMap),
            this.getCategoriesForLang(lang, this.subCategoriesMap),
            switchShortLongDescription,
            index,
            this.getCompareFeatureMapForLang(lang),
          );
          featureLangMap.langs[lang].revId = '';
          featureLangMap.langs[lang].description = '';
          featureLangMap.langs[lang].longDescription = '';
          featureLangMap.langs[lang].shortDescription = '';
        }
      });
    });
  };

  setFeatureLangMaps = (featuresMap: FeaturesMap) => {
    const featureLangMaps: FeatureLangMap[] = [];
    featuresMap.order.forEach(id => {
      const feature = featuresMap.features[id];
      featureLangMaps.push(feature);
    });
    this.featureLangMaps = featureLangMaps;
    this.filteredFeatureLangMaps = featureLangMaps;
  };

  getDefaultFeatures = (featureLangMaps: FeatureLangMap[]) => {
    return featureLangMaps.map(langMap => langMap.langs[this.defaultLang]);
  };

  getFeaturesForLang = (lang: string) => {
    const features: FeatureItem[] = [];
    this.featureLangMaps.forEach(langMap => {
      if (langMap.langs[lang]) {
        features.push(langMap.langs[lang]);
      }
    });
    return features;
  };

  getFeaturesMap = () => {
    const featureMap: FeaturesMap = { features: {}, order: [] };
    this.featureLangMaps.forEach(langMap => {
      const id = langMap.langs[Object.keys(langMap.langs)[0]].id;
      featureMap.features[id] = langMap;
      featureMap.order.push(id);
    });
    return featureMap;
  };

  getCompareFeatureMapForLang = (lang: string) => {
    const compareFeatureMap: KeyValueType<CompareFeatureItem> = {};
    Object.values(this.compareFeaturesMap.compareFeatures).forEach(langMap => {
      const item = langMap.langs[lang];
      compareFeatureMap[item.parentId] = item;
    });
    return compareFeatureMap;
  };

  fillOutCompareFeaturesLangMap = (compareFeaturesMap: CompareFeaturesMap, grades: RefItem[]) => {
    Object.values(compareFeaturesMap.compareFeatures).forEach(compareFeatureLangMap => {
      const compareFeatureData = compareFeatureLangMap.data ?? ({} as CompareFeatureResponse);
      this.allLangs.forEach(lang => {
        if (!compareFeatureLangMap.langs[lang]) {
          const cats: IDValueType<string>[] = this.getCategoriesForLang(lang, this.categoriesMap);
          const subCats: IDValueType<string>[] = this.getCategoriesForLang(lang, this.subCategoriesMap);
          compareFeatureLangMap.langs[lang] = compareFeatureItemXForm(compareFeatureData, grades, cats, subCats, [], [], 0);
        }
      });
    });
  };

  getIsHighlighted = (feature: FeatureItem) => {
    return feature.gradeApplicability && Object.keys(feature.gradeApplicability).length > 0;
  };

  getCommonLanguageIds = () => {
    return this.featureLangMaps.filter(map => !!map.langs[this.defaultLang].comLangId).map(map => map.langs[this.defaultLang].comLangId);
  };

  updateSelectedLangs = (lang: string, isSelected: boolean) => {
    const selectedMap = JSON.parse(JSON.stringify(this.selectedLangsMap));
    if (selectedMap[lang] != null) {
      selectedMap[lang] = isSelected;
    }
    this.selectedLangsMap = selectedMap;
  };

  addItem = (vehicleModels: VehicleModelItem<VehicleModelToyota>[], switchShortLongDescription: boolean) => {
    const id = uuidv4();
    const newLangMap: FeatureLangMap = { langs: {} };
    this.allLangs.forEach(lang => {
      const newItem = new FeatureItem();
      newItem.switchShortLongDescription = switchShortLongDescription;
      newItem.modelsMap = mapEmptyFeatureModels(vehicleModels);
      newItem.gradeApplicability = {};
      newItem.id = id;
      newLangMap.langs[lang] = newItem;
    });

    this.featureLangMaps = [newLangMap, ...this.featureLangMaps];
    this.filteredFeatureLangMaps = [newLangMap, ...this.filteredFeatureLangMaps];
  };

  copyMap = (featureLangMap: FeatureLangMap, vehicleModels: VehicleModelItem<VehicleModel<VehicleModelPropsToyota>>[]) => {
    const featureUid = featureLangMap.langs[this.defaultLang].uid;
    const unfilteredIndex = this.featureLangMaps.findIndex(item => item.langs[this.defaultLang].uid === featureUid) + 1;
    const filteredIndex = this.filteredFeatureLangMaps.findIndex(item => item.langs[this.defaultLang].uid === featureUid) + 1;

    const newLangMap: FeatureLangMap = { langs: {} };
    for (let lang of this.allLangs) {
      const feature = featureLangMap.langs[lang];
      const featureCopy = new FeatureItem();
      const { id, revId, uid, isValid, getPayload, modelsMap, ...rest } = feature;
      Object.assign(featureCopy, rest);
      featureCopy.modelsMap = mapEmptyFeatureModels(vehicleModels);
      newLangMap.langs[lang] = featureCopy;
    }

    const langMaps = this.featureLangMaps.slice();
    langMaps.splice(unfilteredIndex, 0, newLangMap);
    this.featureLangMaps = langMaps;

    const filteredLangMaps = this.filteredFeatureLangMaps.slice();
    filteredLangMaps.splice(filteredIndex, 0, newLangMap);
    this.filteredFeatureLangMaps = filteredLangMaps;

    return newLangMap;
  };

  deleteItem = (uid: string) => {
    this.featureLangMaps = this.featureLangMaps.filter(item => item.langs[this.defaultLang].uid !== uid);
    this.filteredFeatureLangMaps = this.filteredFeatureLangMaps.filter(item => item.langs[this.defaultLang].uid !== uid);
  };

  onSort = (field: string, lang?: string) => {
    this.reverseSort = isSortReverse(this.sortField, field, this.reverseSort);
    this.sortField = field;
    let features = lang ? this.getFeaturesForLang(lang) : this.getDefaultFeatures(this.featureLangMaps);
    features = features.sort(sortBy(this.sortField, this.reverseSort));
    const featuresMap = this.getFeaturesMap();
    featuresMap.order = [];
    features.forEach(feature => {
      featuresMap.order.push(feature.id);
    });
    this.setFeatureLangMaps(featuresMap);
  };

  onFilter = (filterAction: () => void) => {
    filterAction();
    this.filteredFeatureLangMaps = this.filterLangMaps();
  };

  resetFilters = () => {
    this.searchText = '';
    this.categoryFilters = [];
    this.isInProgressFilter = false;
    this.isSyncUpdateFilter = false;
    this.isHighlightedFilter = false;
    this.isReviewNotesFilter = false;
    this.filteredFeatureLangMaps = this.featureLangMaps.slice();
  };

  filterLangMaps = () => {
    const sorted = this.featureLangMaps.slice();
    const lowerSearchText = toLowerCase(this.searchText);
    return sorted.filter(featureLangMap => {
      let checked = false;
      let hasChangedAttributes = false;

      for (let lang of this.allLangs) {
        const feature = featureLangMap.langs[lang];
        if (!checked) {
          checked = true;
          if (this.categoryFilters.length && !this.categoryFilters.includes(feature.category.value)) {
            return false;
          }
          if (this.isInProgressFilter && !feature.isInProgress) {
            return false;
          }
          if (this.isHighlightedFilter && (this.allowCompareFeatures ? this.getIsHighlighted(feature) : feature.isHighlighted)) {
            return false;
          }
          if (this.isReviewNotesFilter && feature.rejectNotes.length === 0) {
            return false;
          }
        }

        if (feature.changedAttributes.length) {
          hasChangedAttributes = true;
        }

        if (lowerSearchText) {
          const valuesToCheck: string[] = [
            feature.category.value,
            feature.subCategory.value,
            convertToRichTextObject(feature.description).text,
            feature.link,
            convertToRichTextObject(feature.shortDescription).text,
            feature.notes,
          ];
          for (let val of valuesToCheck) {
            if (toLowerCase(val).includes(lowerSearchText)) {
              return true;
            }
          }
        }
      }

      if (this.isSyncUpdateFilter && !hasChangedAttributes) {
        return false;
      }

      return !lowerSearchText;
    });
  };

  constructor() {
    makeObservable(this, {
      selectedLangsMap: observable,
      filteredFeatureLangMaps: observable,
      categoriesMap: observable,
      subCategoriesMap: observable,
      viewModelCodes: observable,
      showRequiredDescription: observable,
      showOptionalDescription: observable,
      avaliableGrades: observable,
      rowHeightMap: observable,
      fetchData: action,
      resetFilters: action,
      reset: action,
      setRowHeight: action,
    });
  }

  hasChangedAttributes() {
    return !!this.featureLangMaps.filter(
      langMap =>
        !!this.editableLangs.filter(lang => {
          let hasChange = false;
          for (const change of langMap.langs[lang].changedAttributes) {
            if (change !== 'new' && change !== 'delete') {
              hasChange = true;
              break;
            }
          }
          return hasChange;
        }).length,
    ).length;
  }

  reset() {
    this.allowCompareFeatures = false;
    this.reverseSort = false;
    this.sortField = 'id';
    this.searchText = '';
    this.isInProgressFilter = false;
    this.isSyncUpdateFilter = false;
    this.isHighlightedFilter = false;
    this.isReviewNotesFilter = false;
    this.categoryFilters = [];
    this.viewModelCodes = false;
    this.showRequiredDescription = true;
    this.showOptionalDescription = false;
    this.compareFeaturesMap = { compareFeatures: {}, order: [] };
    this.categoriesMap = { categories: {}, order: [] };
    this.subCategoriesMap = { categories: {}, order: [] };
    this.featureLangMaps = [];
    this.filteredFeatureLangMaps = [];
    this.allLangs = [];
    this.selectedLangsMap = {};
    this.avaliableGrades = [];
    this.rowHeightMap = {};
  }

  setRowHeight = (langMap: FeatureLangMap, rowHeight: number) => {
    const key = langMap.langs[this.defaultLang].id;
    this.rowHeightMap[key] = rowHeight;
  };

  getRowHeight(langMap: FeatureLangMap) {
    const key = langMap.langs[this.defaultLang].id;
    return this.rowHeightMap[key];
  }
}

export default FeaturesStore;
