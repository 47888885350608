import React, { useState } from 'react';
import {
  Button,
  FilterTab,
  FilterTabColumn,
  FilterTabColumns,
  FilterTabFooter,
} from 'vapi-ui-common';
import Checkbox from '../../../../../../components/Checkbox';
import useStores from '../../../../../../hooks/useStores';
import { VehicleTeam } from '../../../../../../models/vehicleData.model';

type Props = {
  isInProgressFilter?: boolean;
  setIsInProgressFilter?: (value: boolean) => void;
  isSyncUpdateFilter?: boolean;
  setIsSyncUpdateFilter?: (value: boolean) => void;
  isExtraCostColorFilter?: boolean;
  setIsExtraCostColorFilter?: (value: boolean) => void;
  isReviewPage?: boolean;
  onClose: () => void;
  isReviewNotesFilter?: boolean;
  setIsReviewNotesFilter?: (value: boolean) => void;
  isPublished?: boolean;
};
const Filters: React.FC<Props> = ({
  isInProgressFilter,
  setIsInProgressFilter,
  isSyncUpdateFilter,
  setIsSyncUpdateFilter,
  isExtraCostColorFilter,
  setIsExtraCostColorFilter,
  onClose,
  isReviewNotesFilter,
  setIsReviewNotesFilter,
  isPublished,
}) => {
  const [isExtraCostColor, setIsExtraCostColor] = useState(isExtraCostColorFilter || false);
  const [isInProgress, setIsInProgress] = useState(isInProgressFilter || false);
  const [isSyncUpdate, setIsSyncUpdate] = useState(isSyncUpdateFilter || false);
  const [isReviewNotes, setIsReviewNotes] = useState(isReviewNotesFilter || false);

  const {
    teamStore: {
      team: { name },
    },
  } = useStores();

  const applyFilters = () => {
    setIsExtraCostColorFilter && setIsExtraCostColorFilter(isExtraCostColor);
    setIsInProgressFilter && setIsInProgressFilter(isInProgress);
    setIsSyncUpdateFilter && setIsSyncUpdateFilter(isSyncUpdate);
    setIsReviewNotesFilter && setIsReviewNotesFilter(isReviewNotes);
    onClose();
  };

  return (
    <FilterTab>
      <FilterTabColumns>
        {setIsExtraCostColorFilter && (
          <FilterTabColumn>
            <Checkbox
              id="extraCostColor"
              defaultChecked={isExtraCostColor}
              onChange={e => setIsExtraCostColor(e.currentTarget.checked)}
            >
              Extra Cost Color
            </Checkbox>
          </FilterTabColumn>
        )}
        {setIsInProgressFilter && (
          <FilterTabColumn>
            <Checkbox
              id="InProgress"
              defaultChecked={isInProgress}
              onChange={e => setIsInProgress(e.currentTarget.checked)}
            >
              In Progress
            </Checkbox>
          </FilterTabColumn>
        )}
        {setIsReviewNotesFilter && (
          <FilterTabColumn>
            <Checkbox
              id="ReviewNotes"
              defaultChecked={isReviewNotes}
              onChange={e => setIsReviewNotes(e.currentTarget.checked)}
            >
              Review Notes
            </Checkbox>
          </FilterTabColumn>
        )}
        {VehicleTeam.AGENCY_SPANISH === name && setIsSyncUpdateFilter && !isPublished && (
          <FilterTabColumn>
            <Checkbox
              id="SyncUpdates"
              checked={isSyncUpdate}
              onChange={e => setIsSyncUpdate(e.currentTarget.checked)}
            >
              Sync Updates
            </Checkbox>
          </FilterTabColumn>
        )}
      </FilterTabColumns>
      <FilterTabFooter>
        <Button variant="primary" onClick={() => applyFilters()}>
          Apply Filters
        </Button>
      </FilterTabFooter>
    </FilterTab>
  );
};

export default Filters;
