import cx from 'clsx';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Checkbox } from 'vapi-ui-common';
import IconTextButton from '../../../../../../../components/IconTextButton';
import Input from '../../../../../../../components/Input';
import { TableRow } from '../../../../../../../components/Table';
import useChecklist from '../../../../../../../hooks/useChecklist';
import useStores from '../../../../../../../hooks/useStores';
import { KeyValueType } from '../../../../../../../models/common.model';
import { FuelTypeItemResponse } from '../../../../../../../models/fuelType.model';
import { SeriesCategoryItem, SeriesSettingsLangMap, TypesCatItems } from '../../../../../../../models/seriesSettings.model';
import { Brand } from '../../../../../../../models/user.model';
import SyncTdPRButton from '../../../../../components/SyncTdPRButton/SyncTdPRButton';
import styles from '../../../seriesSettings.module.scss';
import Label from './Label';
import FuelTypeSelectionComponent from './components/FuelTypeSelectionComponent/FuelTypeSelectionComponent';
import SeriesTypeSelectionComponent from './components/SeriesTypeSelectionComponent/SeriesTypeSelectionComponent';

interface SettingsEditPanelProps {
  seriesCategoriesList: SeriesCategoryItem[];
  fuelTypesList: FuelTypeItemResponse[];
  onClose: () => void;
  saveSeriesSetting?: (seriesSetting: SeriesSettingsLangMap) => void;
  saveSubSeriesSetting?: (seriesSetting: SeriesSettingsLangMap) => void;
  brand: Brand;
  seriesSettingsLangMap: SeriesSettingsLangMap;
  defaultLang: string;
  compareSettings?: (settings: SeriesSettingsLangMap) => void;
  canAddNewSeriesSettings: boolean;
  readOnly?: boolean;
}

const getNameMap = (seriesSettingsLangMap: SeriesSettingsLangMap) => {
  const nameMap: KeyValueType<string> = {};
  Object.keys(seriesSettingsLangMap).forEach(lang => {
    nameMap[lang] = seriesSettingsLangMap[lang].name;
  });
  return nameMap;
};

const SettingsEditPanel = ({
  seriesCategoriesList,
  fuelTypesList,
  onClose,
  saveSeriesSetting = () => {},
  saveSubSeriesSetting = () => {},
  brand,
  seriesSettingsLangMap,
  defaultLang,
  compareSettings,
  canAddNewSeriesSettings,
  readOnly,
}: SettingsEditPanelProps) => {
  const languages = Object.keys(seriesSettingsLangMap);
  const defaultSettings = seriesSettingsLangMap[defaultLang];
  const { seriesSettingsStore } = useStores();
  const { checklist, setChecklist, selectItem } = useChecklist();
  const { checklist: catChecklist, setChecklist: setCategoriesChecklist } = useChecklist();
  const [disableMpge, setDisableMpge] = useState(true);
  const [nameMap, setNameMap] = useState(getNameMap(seriesSettingsLangMap));
  const [startingMSRP] = useState(defaultSettings.startingMSRP);
  const [seating, setSeating] = useState(defaultSettings.seating);
  const [horsepower] = useState(defaultSettings.horsepower);
  const [estimatedMileage, setEstimatedMileage] = useState(defaultSettings.estimatedMileage);
  const [mpge, setMpge] = useState(defaultSettings.mpge);
  const [codeRed, setCodeRed] = useState(!defaultSettings.isPublishable);
  const [range, setRange] = useState(defaultSettings.range);
  const [showRange, setShowRange] = useState(false);
  const [isFuelCell, setIsFuelCell] = useState(false);

  useEffect(() => {
    setChecklist(
      fuelTypesList.map(type => ({
        id: type.id,
        name: type.type,
        selected: Object.keys(defaultSettings.fuelTypes).some(fuelType => fuelType === type.id),
      })),
    );
  }, [setChecklist, fuelTypesList, defaultSettings]);

  useEffect(() => {
    setCategoriesChecklist(
      seriesCategoriesList.map(category => ({
        id: category.id,
        name: category.name,
        selected: Object.keys(defaultSettings.seriesCategories).some(cat => cat === category.id),
      })),
    );
  }, [seriesCategoriesList, setCategoriesChecklist, defaultSettings]);

  useEffect(() => {
    const fuelNames = [] as string[];
    checklist.forEach(item => {
      if (item.selected) {
        fuelNames.push(item.name);
      }
    });
    const isMpgeRequired = fuelNames.includes('Plug-in Hybrid');
    const isRangeRequired = fuelNames.includes('Battery Electric');
    const isFuelCell = fuelNames.includes('Fuel Cell');
    for (const lang of Object.keys(seriesSettingsLangMap)) {
      const settings = seriesSettingsLangMap[lang];
      settings.isMpgeRequired = isMpgeRequired;
      settings.isRangeRequired = isRangeRequired;
      settings.isFuelCell = isFuelCell;
    }
    setShowRange(isRangeRequired);
    setDisableMpge(!isMpgeRequired);
    setIsFuelCell(isFuelCell);
  }, [checklist, defaultSettings, seriesSettingsLangMap]);

  const handleOnSave = () => {
    let isValid = true;
    let hasChangedAttributes = false;

    for (const lang of languages) {
      const settings = seriesSettingsLangMap[lang];
      const defSettings = Object.assign({}, seriesSettingsLangMap[lang]);
      let hasChanges = false;

      settings.fuelTypes = {} as TypesCatItems;
      checklist.forEach(item => {
        if (item.selected) {
          settings.fuelTypes[item.id] = true;
          if (!defSettings.fuelTypes[item.id]) {
            hasChanges = true;
          }
        }
      });

      if (Object.keys(defSettings.fuelTypes).length !== Object.keys(settings.fuelTypes).length) {
        hasChanges = true;
      }

      settings.seriesCategories = {} as TypesCatItems;
      catChecklist.forEach(item => {
        if (item.selected) {
          settings.seriesCategories[item.id] = true;
          if (!defSettings.seriesCategories[item.id]) {
            hasChanges = true;
          }
        }
      });

      if (Object.keys(defSettings.seriesCategories).length !== Object.keys(settings.seriesCategories).length) {
        hasChanges = true;
      }

      // settings.name = nameMap[lang];

      if (defSettings.seating !== seating) {
        settings.seating = seating;
        hasChanges = true;
      }

      if (defSettings.estimatedMileage !== estimatedMileage) {
        settings.estimatedMileage = estimatedMileage;
        hasChanges = true;
      }

      if (defSettings.mpge !== mpge) {
        settings.mpge = mpge;
        hasChanges = true;
      }

      if (defSettings.horsepower !== horsepower) {
        settings.horsepower = horsepower;
        hasChanges = true;
      }

      if (defSettings.startingMSRP !== startingMSRP) {
        settings.startingMSRP = startingMSRP;
        hasChanges = true;
      }

      if (defSettings.range !== range) {
        settings.range = range;
        hasChanges = true;
      }

      // settings.isPublishable = !codeRed;

      if (!settings.isValid) {
        isValid = false;
      }

      if (hasChanges) {
        hasChangedAttributes = hasChanges;
      }
    }

    if (!hasChangedAttributes) {
      toast.error('Nothing to update!');
      return;
    }

    if (isValid) {
      save();
    } else {
      toast.error('Please fill in the required fields');
    }
  };

  const save = () => {
    if ((defaultSettings.isNewSubSeries && defaultSettings.revId === '') || (defaultSettings.parentId && defaultSettings.revId !== '')) {
      saveSubSeriesSetting(seriesSettingsLangMap);
    } else {
      saveSeriesSetting(seriesSettingsLangMap);
    }
    onClose();
  };

  const handleOnClose = () => {
    if ((defaultSettings.isNewSubSeries || defaultSettings.isSubSeries) && defaultSettings.revId === '') {
      seriesSettingsStore.removeSetting(seriesSettingsLangMap);
    }
    onClose();
  };

  const changedAttributes = () => {
    const changed: string[] = [];
    languages.forEach(lang => {
      const settings = seriesSettingsLangMap[lang];
      if (settings) {
        changed.push(...settings.changedAttributes);
      }
    });
    return changed;
  };

  const showSync = defaultSettings.fromTMNA && !readOnly;

  return (
    <>
      <TableRow
        zebra
        hoverShadow
        className={cx({
          [styles.subSeriesRow]: defaultSettings.isNewSubSeries || defaultSettings.isSubSeries,
          [styles.seriesSettingRow]: true,
          [styles.seriesSettingRowTdPR]: showSync,
        })}
      >
        <td>
          <Checkbox
            id={`CodeRed-${defaultSettings.uid}`}
            checked={codeRed}
            onChange={e => setCodeRed(e.currentTarget.checked)}
            className={styles.isPublishableChbox}
            disabled
          ></Checkbox>
        </td>
        <td>
          <div className={cx({ [styles.seriesNameEdit]: showSync })}>
            {showSync && compareSettings && (
              <SyncTdPRButton
                id={defaultSettings.id}
                onClick={() => {
                  if (seriesSettingsLangMap) {
                    compareSettings(seriesSettingsLangMap);
                  }
                  onClose();
                }}
                changedAttributes={changedAttributes()}
              />
            )}
          </div>
          <Label htmlFor={`amm-name-${defaultSettings.uid}-${languages[0]}`}>{defaultSettings.isSubSeries ? 'Sub Series Name' : 'Series Name'}</Label>
          {languages.map(lang => {
            const settingsName = nameMap[lang];
            return (
              <Input
                key={`amm-name-${defaultSettings.uid}-${lang}`}
                id={`amm-name-${defaultSettings.uid}-${lang}`}
                defaultValue={settingsName}
                className={seriesSettingsLangMap[lang].changedAttributes.includes('name') ? styles.errorText : ''}
                onChange={e => {
                  setNameMap({ ...nameMap, [lang]: e.currentTarget.value.trim() });
                }}
                disabled
              />
            );
          })}
        </td>
        <td>
          <Label htmlFor={`amm-fueltype-${defaultSettings.uid}`}>Fuel Type</Label>
          <FuelTypeSelectionComponent checklist={checklist} selectItem={selectItem} />
        </td>
        <td>
          <Label htmlFor={`amm-category-${defaultSettings.uid}`}>Series Category(s)</Label>
          <SeriesTypeSelectionComponent catChecklist={catChecklist} selectItem={selectItem} />
        </td>
        <td>
          <Label htmlFor={`amm-seating-${defaultSettings.uid}`}>Seating</Label>
          <Input
            id={`amm-seating-${defaultSettings.uid}`}
            defaultValue={seating}
            className={defaultSettings.changedAttributes.includes('seating') ? styles.errorText : ''}
            onChange={e => setSeating(e.currentTarget.value.trim())}
          />
        </td>
        {showRange && (
          <td>
            <Label htmlFor={`amm-range-${defaultSettings.uid}`}>Range</Label>
            <Input
              id={`amm-range-${defaultSettings.uid}`}
              defaultValue={range}
              className={defaultSettings.changedAttributes.includes('range') ? styles.errorText : ''}
              onChange={e => setRange(e.currentTarget.value.trim())}
            />
          </td>
        )}
        {!showRange && (
          <td>
            <Label htmlFor={`amm-mil-${defaultSettings.uid}`}>Estimated Mileage</Label>
            <Input
              id={`amm-mil-${defaultSettings.uid}`}
              placeholder={`City/Highway/Combined`}
              defaultValue={estimatedMileage}
              className={defaultSettings.changedAttributes.includes('estimatedMileage') ? styles.errorText : ''}
              onChange={e => setEstimatedMileage(e.currentTarget.value.trim())}
              disabled={isFuelCell}
            />
          </td>
        )}
        <td>
          <Label htmlFor={`amm-mpge-${defaultSettings.uid}`}>MPGe</Label>
          <Input
            id={`amm-mpge-${defaultSettings.uid}`}
            defaultValue={mpge}
            className={defaultSettings.changedAttributes.includes('mpge') ? styles.errorText : ''}
            onChange={e => setMpge(e.currentTarget.value.trim())}
            disabled={disableMpge && !isFuelCell}
          />
        </td>
        <td>
          {!defaultSettings.isSubSeries && canAddNewSeriesSettings && (
            <IconTextButton
              icon="plus"
              text="Add Sub Series"
              smallIcon
              onClick={() => seriesSettingsStore.addEmptySubSeries(brand, seriesSettingsLangMap)}
              className={styles.iconTextButtonSubSeries}
            />
          )}
          <Button variant="transparent" onClick={handleOnClose}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              handleOnSave();
            }}
          >
            Save {defaultSettings.isNewSubSeries || defaultSettings.isSubSeries ? 'Sub' : ''} Series
          </Button>
        </td>
      </TableRow>
    </>
  );
};

export default SettingsEditPanel;
