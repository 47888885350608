import { observer } from 'mobx-react-lite';
import React from 'react';
import { IDValueType } from '../../../../../../../models/common.model';
import { RefItem } from '../../../../../../../models/refItem.model';
import { SeriesSettingsItem } from '../../../../../../../models/seriesSettings.model';
import { Language } from '../../../../../../../models/user.model';
import { SeriesCategories } from '../../../../../../../models/vehicleData.model';
import { /* Brand, */ VehicleModelItem, VehicleModelToyota } from '../../../../../../../models/vehicleModel.model';
import ModelsEditPanelToyota from './ModelsEditPanelToyota';

interface ModelsEditPanelProps {
  brand: string;
  seriesName: string;
  model: VehicleModelItem<VehicleModelToyota>;
  grades: RefItem[];
  fuelTypes: IDValueType[];
  category: SeriesCategories;
  onClose: () => void;
  isCopy?: boolean;
  onSave?: (model: VehicleModelItem<VehicleModelToyota>, language?: Language) => void;
  onAddGrade?: (grade: string) => void;
  onUpdateGrade?: (gradeId: string, gradeValue: string) => void;
  seriesSettings: SeriesSettingsItem[];
  rowClass?: string;
  readOnly?: boolean;
  compareModel: (model: VehicleModelItem<VehicleModelToyota>) => void;
}

const ModelsEditPanel = ({ brand, compareModel, ...rest }: ModelsEditPanelProps) => {
  return <ModelsEditPanelToyota brand={brand} compareModel={compareModel} {...rest} />;
};

export default observer(ModelsEditPanel);
