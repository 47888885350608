import { ChangeLogModelItem, ChangeLogModelResponse } from '../../../../../models/changeLog.model';
import { Language } from '../../../../../models/user.model';
import { VehicleTeam } from '../../../../../models/vehicleData.model';
import { sortBy } from '../../../../../utils';
import { getChangeLog } from '../../../../../webservices/vehicleModelsApi';

export const getVehicleModelChangeLogs = async (brand: string, team: VehicleTeam, seriesId: string, year: string, languages: Language[], version?: string) => {
  const logs: ChangeLogModelItem[] = [];
  const promises: Promise<any>[] = [];
  for (const lang of languages) {
    promises.push(getChangeLog(brand, team, seriesId, year, lang, version));
  }
  const responses = await Promise.all(promises);
  let index = 0;
  for (const lang of languages) {
    const changeLogs = responses[index].data.map((item: ChangeLogModelResponse) => new ChangeLogModelItem(item, version || '', brand, lang));

    logs.push(...changeLogs);
    index += 1;
  }

  return logs.slice().sort(sortBy('modifiedDate', true, true));
};
