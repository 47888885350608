import { observable, action, makeObservable } from "mobx";

class ResizeDetectorStore {
  mainContentWidth = 0;
  windowWidth = 0;

  setValues = (mainContentWidth: number, windowWidth: number) => {
    this.mainContentWidth = mainContentWidth;
    this.windowWidth = windowWidth
  };

  constructor() {
    makeObservable(this, {
      mainContentWidth: observable,
      windowWidth: observable,
      setValues: action
    });
  }
}

export default ResizeDetectorStore;
