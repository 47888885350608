import React, { MutableRefObject, createContext, useContext } from 'react';
import { Spinner } from 'vapi-ui-common';
import { AppliedChangesResponse } from '../../../../models/changeLog.model';
import { ReviewSyncItem } from '../../../../models/review.model';
import { AppliedMap, ChangesMap, EntityType } from './SyncTMNAChangesModal';
import SyncTMNAChangesModalBodyChanges from './SyncTMNAChangesModalBodyChanges';
import SyncTMNAChangesModalBodyDelete from './SyncTMNAChangesModalBodyDelete';
import SyncTMNAChangesModalBodyNew from './SyncTMNAChangesModalBodyNew';

interface SyncTMNAChangesModalBodyContextProps {
  entityType: EntityType;
  isNew: boolean;
  isDelete: boolean;
  close: (response?: AppliedChangesResponse, shouldDelete?: boolean, unlinkFromTMNA?: boolean) => void;
  syncItems: ReviewSyncItem[];
  numberChecked: number;
  isLoaded: boolean;
  changesMap: MutableRefObject<ChangesMap>;
  appliedMap: AppliedMap;
  setAppliedMap: (appliedMap: AppliedMap) => void;
}

const defaultContext: SyncTMNAChangesModalBodyContextProps = {
  entityType: '' as EntityType,
  isNew: false,
  isDelete: false,
  close: () => undefined,
  syncItems: [],
  numberChecked: -1,
  isLoaded: false,
  changesMap: {} as MutableRefObject<ChangesMap>,
  appliedMap: { applied: {} },
  setAppliedMap: () => undefined,
};

export const SyncTMNAChangesModalBodyContext = createContext<SyncTMNAChangesModalBodyContextProps>(defaultContext);

const SyncTMNAChangesModalBody = () => {
  const { isLoaded, isNew, isDelete } = useContext(SyncTMNAChangesModalBodyContext);

  if (!isLoaded) {
    return <Spinner short />;
  }

  if (isNew) {
    return <SyncTMNAChangesModalBodyNew />;
  }

  if (isDelete) {
    return <SyncTMNAChangesModalBodyDelete />;
  }

  return <SyncTMNAChangesModalBodyChanges />;
};

export default SyncTMNAChangesModalBody;
