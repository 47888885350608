import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ActionBar } from "vapi-ui-common";
import Spinner from "../../../../components/Spinner";
import { uiBlock } from "../../../../components/UiBlocker/uiBlock";
import useStores from "../../../../hooks/useStores";
import { FeaturesChangeTypeMap } from "../../../../models/features.model";
import { BRAND_TOYOTA } from "../../../../models/user.model";
import ActionBarFiltersSection from "../../../../routes/vehicleData/components/ActionBarFiltersSection";
import { ProductDataControllerProps } from "../../../../routes/vehicleData/models/controllers.model";
import FeatureFilters from "../../../../routes/vehicleData/tabModules/features/components/FeatureFilters";
import { categoriesXForm } from "../../../../utils/categoryUtils";
import { handleErrorResponse } from "../../../../utils/errorHandlingUtils";
import { featuresReviewXForm } from "../../../../utils/featuresUtils";
import {
  getReviewCategories,
  getReviewFeatures,
  getReviewSubCategories,
} from "../../../../webservices/vehicleFeaturesApi";
import FeaturesReviewTable from "./FeaturesReviewTable";

const FeaturesReview = ({
  seriesId,
  year,
  version = "",
  vehicleModels,
}: ProductDataControllerProps) => {
  const [isLoaded, setIsLoaded] = useState(false);

  const {
    featuresReviewStore,
    teamStore,
    userStore: { brand },
  } = useStores();

  useEffect(() => {
    featuresReviewStore.reset();

    (async () => {
      try {
        const responses = await Promise.all([
          getReviewCategories(
            brand,
            teamStore.team.param,
            seriesId,
            year,
            version
          ),
          getReviewSubCategories(
            brand,
            teamStore.team.param,
            seriesId,
            year,
            version
          ),
          getReviewFeatures(
            brand,
            teamStore.team.param,
            seriesId,
            year,
            version
          ),
        ]);

        featuresReviewStore.categories = categoriesXForm(responses[0].data);
        featuresReviewStore.subCategories = categoriesXForm(responses[1].data);
        const { map } = featuresReviewXForm(
          responses[2].data,
          vehicleModels,
          featuresReviewStore.categories,
          featuresReviewStore.subCategories
        );
        featuresReviewStore.map = map;
      } catch (err) {
        console.log(err);
        toast.error("Error loading Features review data");
      }
      setIsLoaded(true);
    })();
  }, [
    featuresReviewStore,
    brand,
    seriesId,
    teamStore,
    version,
    year,
    vehicleModels,
  ]);

  const handleOnMapApplyChange = async (
    map: FeaturesChangeTypeMap,
    value: boolean
  ) => {
    map.isApplied = value;
    saveMap(map);
  };

  const handleOnMapNotesChange = async (
    map: FeaturesChangeTypeMap,
    value: string
  ) => {
    map.rejectNotes = value;
    saveMap(map);
  };

  const saveMap = async (map: FeaturesChangeTypeMap) => {
    try {
      if (featuresReviewStore.isMapValid(map, teamStore.team.param)) {
        uiBlock.start();
        /* const response = await updateReviewFeature(
          brand,
          teamStore.team.param,
          seriesId,
          year,
          version,
          featuresReviewStore.getMapPayload(map, teamStore.team.param)
        );
        map.revId = response.data.revId; */
        toast.success("Successfully updated feature");
      } else {
        toast.error("Please fill in the required fields for the feature");
      }
    } catch (e) {
      handleErrorResponse(e, "Error updating feature");
    }

    uiBlock.stop();
  };

  return !isLoaded ? (
    <Spinner />
  ) : (
    <>
      <ActionBar>
        <ActionBarFiltersSection
          readOnly
          searchText={featuresReviewStore.searchText}
          onSearchTextChange={(text) => (featuresReviewStore.searchText = text)}
          toggleViewModelCodes={() =>
            (featuresReviewStore.viewModelCodes =
              !featuresReviewStore.viewModelCodes)
          }
          viewModelCodes={featuresReviewStore.viewModelCodes}
          buttons={[]}
          renderFilter={(onClose) => (
            <FeatureFilters
              onClose={onClose}
              categories={featuresReviewStore.categories.map(
                (item) => item.value
              )}
              categoryFilters={featuresReviewStore.categoryFilters}
              setCategoryFilters={(categoryFilters) =>
                (featuresReviewStore.categoryFilters = categoryFilters)
              }
              isInProgressFilter={featuresReviewStore.isInProgressFilter}
              isHighlightedFilter={featuresReviewStore.isHighlightedFilter}
              setIsInProgressFilter={(value) =>
                (featuresReviewStore.isInProgressFilter = value)
              }
              setIsHighlightedFilter={
                brand === BRAND_TOYOTA
                  ? undefined
                  : (value) => (featuresReviewStore.isHighlightedFilter = value)
              }
            />
          )}
        />
      </ActionBar>
      <FeaturesReviewTable
        vehicleModels={vehicleModels}
        handleOnApplyChange={handleOnMapApplyChange}
        handleOnNotesChange={handleOnMapNotesChange}
      />
    </>
  );
};

export default observer(FeaturesReview);
