import cx from 'clsx';
import React from 'react';
import {
  CenteredTextArea,
  CenteredTextAreaProps,
  TokenizedTextArea,
  TokenizedTextAreaProps,
} from 'vapi-ui-common';
import { TableCell } from '../../../../../components/Table';
import styles from './centeredTextAreaCell.module.scss';

interface CenteredTextAreaCellProps extends CenteredTextAreaProps, TokenizedTextAreaProps {
  className?: string;
  value?: string;
  allowTokens?: boolean;
  border?: boolean;
  required?: boolean;
}

const CenteredTextAreaCell: React.FC<CenteredTextAreaCellProps> = ({
  value,
  className,
  onChange,
  onBlurCallback,
  size,
  disabled,
  required,
  singleLine,
  allowTokens,
  tokens,
  allowEmpty,
  border = false,
}) => (
  <TableCell
    colType={size === 'xsmall' || size === 'small' ? 'dropdown' : 'description'}
    className={cx(styles.root, className)}
    border={border}
  >
    {!allowTokens && (
      <CenteredTextArea
        required={required}
        disabled={disabled}
        value={value}
        onChange={onChange}
        onBlurCallback={onBlurCallback}
        size={size}
        allowEmpty={allowEmpty}
        useInput={singleLine}
      />
    )}
    {allowTokens && (
      <TokenizedTextArea
        disabled={disabled}
        value={value}
        onBlurCallback={onBlurCallback}
        size={size}
        allowEmpty={allowEmpty}
        tokens={tokens}
        singleLine={singleLine}
      />
    )}
  </TableCell>
);

export default CenteredTextAreaCell;
