import { ChecklistItem } from 'vapi-ui-common/dist/src/hooks/useChecklist/useChecklist';
import { VehicleColorInt as VehicleColorIntGQL } from '../gql/generated';
import { ChangeLogTypes, ColorApplicability } from '../models/changeLog.model';
import { CFExteriorColorItem, CFExteriorColorResponse, CFInteriorColorItem, CFInteriorColorResponse, ColorFamilyItem, ColorFamilyResponse } from '../models/colorFamilies.model';
import {
  ColorApplicabilityReviewMap,
  ColorGradeItem,
  ColorGradeLangItem,
  ColorItem,
  ColorItemMap,
  ColorsReviewItem,
  ColorsReviewResponse,
  ExteriorColorLangMap,
  ExteriorColorReviewType,
  ExteriorColorsChangeTypeMap,
  ExteriorColorsReviewMap,
  IntColorGradesList,
  IntColorModelApplicability,
  IntColorModelCodes,
  InteriorColorItem,
  InteriorColorLangMap,
  InteriorColorResponse,
  InteriorColorReviewType,
  InteriorColorsChangeTypeMap,
  InteriorColorsReviewMap,
  InteriorItem,
} from '../models/colors.model';
import { InteriorGroupItem } from '../models/colorsLexus.model';
import { KeyValueType } from '../models/common.model';
import { RefItem, RefItemResponse } from '../models/refItem.model';
import { ReviewSyncItem } from '../models/review.model';
import { BRAND_TDPR } from '../models/user.model';
import { VehicleModelItem, VehicleModelToyota } from '../models/vehicleModel.model';
import { refItemsXForm } from './refItemUtils';

export const vehicleColorIntGQLToInteriorColorResponse = (cInt: VehicleColorIntGQL): InteriorColorResponse => {
  const modelApplicability =
    cInt?.modelApplicability.reduce(
      (mMap, mApp) => ({
        ...mMap,
        [mApp.id]:
          mApp.colorApplicability?.reduce((cMap, cApp) => {
            if (!cApp) return cMap;
            return {
              ...cMap,
              [cApp.id]: cApp.isApplicable,
            };
          }, {}) ?? {},
      }),
      {},
    ) ?? {};

  return { ...cInt, modelApplicability } as InteriorColorResponse;
};

// Color Data
export const colorGradeXform = (interiorColorResp: ColorsReviewResponse[], vehicleModels: VehicleModelItem<VehicleModelToyota>[], grades: RefItem[]) => {
  const gradeItems: ColorGradeItem[] = [];

  interiorColorResp.forEach(item => {
    item.modelApplicability &&
      Object.keys(item.modelApplicability).forEach(gradeId => {
        let gItem = gradeItems.find(gradeItem => gradeItem.grade.id === gradeId);
        if (!gItem) {
          const gradeRefItem = grades.find(gRefItem => gRefItem.id === gradeId);
          if (gradeRefItem) {
            gItem = new ColorGradeItem(gradeRefItem);
            gradeItems.push(gItem);
          }
        }

        if (gItem) {
          gItem.interiorItems.push(item);
        }
      });
  });

  const sortedGradeItems = gradeItems.sort((a, b) => {
    const aItem = vehicleModels.find(item => item.getVal('grade').id === a.grade);
    const bItem = vehicleModels.find(item => item.getVal('grade').id === b.grade);
    const aSortOrder = aItem ? aItem.sortOrder : 0;
    const bSortOrder = bItem ? bItem.sortOrder : 0;
    return aSortOrder - bSortOrder;
  });

  return sortedGradeItems;
};

// Color Data
export const colorGradeLangXform = (interiorColorResp: InteriorColorLangMap[], grades: RefItem[]) => {
  const gradeItems: ColorGradeLangItem[] = grades.map(grade => new ColorGradeLangItem(grade));

  interiorColorResp.forEach(langMap => {
    const defaultLang = Object.keys(langMap.langs)[0];
    const item = langMap.langs[defaultLang];
    if (item.modelApplicability) {
      Object.keys(item.modelApplicability).forEach(gradeId => {
        let gItem = gradeItems.find(gradeItem => gradeItem.grade.id === gradeId);
        if (gItem) {
          gItem.interiorItems.push(langMap);
        }
      });
    }
  });

  return gradeItems;
};

export const colorExteriorLangXform = (extColorLangMap: ExteriorColorLangMap[], colorGradeLangItems: ColorGradeLangItem[]) => {
  const colorItemMap: ColorItemMap = { colors: {}, order: [] };

  extColorLangMap.forEach((langMap, index) => {
    Object.keys(langMap.langs).forEach(lang => {
      const extClrRes = langMap.langs[lang];
      if (!colorItemMap.colors[extClrRes.id]) {
        colorItemMap.colors[extClrRes.id] = { langs: {} };
      }
      const newItem = new ColorItem(extClrRes);
      colorGradeLangItems.forEach(gradeItem => {
        newItem.interiorApplicability[gradeItem.grade.id] = gradeItem.interiorItems.map(intItemMap => new InteriorItem(gradeItem.grade, intItemMap.langs[lang], false));
      });
      if (extClrRes.colorApplicability && extClrRes.colorApplicability.length) {
        extClrRes.colorApplicability.forEach(app => {
          newItem.interiorApplicability[app.grade].forEach(itm => {
            if (itm.interiorItem.id === app.interiorColorId && itm.grade.id === app.grade) {
              itm.checked = true;
            }
          });
        });
      }
      newItem.sortOrder = index + 1;
      colorItemMap.colors[extClrRes.id].langs[lang] = newItem;
      if (!colorItemMap.order.includes(extClrRes.id)) {
        colorItemMap.order.push(extClrRes.id);
      }
    });
  });
  return colorItemMap;
};

// adds model codes under grades for the checklist modal
export const gradesWithModelCodeXform = (vehicleModels: VehicleModelItem<VehicleModelToyota>[], availableGrades: RefItem[], brand: string) => {
  const gradesChecklist: IntColorGradesList[] = [];
  const finalChecklist: IntColorGradesList[] = [];
  const map = new Map();

  availableGrades.forEach(grd => {
    const models = vehicleModels.filter(mdl => mdl.getVal('grade').id === grd.id);
    const modelcodes: IntColorModelCodes[] = [];

    models.forEach(model => {
      let modelCode = model.getVal('code');
      let hide = false;
      if (brand === BRAND_TDPR) {
        modelCode = model.getVal('tdprCode') || modelCode;
        hide = !model.getVal('isUSVI') && !model.getVal('isTDPR');
      }
      if (!modelcodes.some(mc => mc.name === modelCode)) {
        modelcodes.push({
          id: model.id,
          name: modelCode,
          selected: false,
          hide,
        });
      }

      const checklist: IntColorGradesList = {
        id: grd.id,
        name: grd.value,
        selected: false,
        items: modelcodes,
      };
      gradesChecklist.push(checklist);
    });
  });

  for (const item of gradesChecklist) {
    if (!map.has(item.id)) {
      map.set(item.id, true);
      finalChecklist.push({
        id: item.id,
        name: item.name,
        selected: item.selected,
        items: item.items,
      });
    }
  }

  return finalChecklist;
};

export const interiorColorItemXForm = (item: InteriorColorResponse) => {
  return new InteriorColorItem(item);
};

export const colorsExteriorReviewItemXForm = (items: ColorsReviewResponse[], colorGradeItems: ColorGradeItem[], grades: RefItem[]) => {
  const colorItems: ColorsReviewItem[] = [];
  const map: ExteriorColorsReviewMap = {};

  items.forEach(item => {
    let isApplied = true;
    let rejectNotes = '';
    if (!map[item.id]) {
      map[item.id] = {
        id: item.id,
        revId: item.revId,
        isApplied: false,
        isNew: false,
        isDeleted: false,
        rejectNotes: item.rejectNotes,
        notes: item.notes,
        isInProgress: item.isInProgress,
        hexCode: item.hexCode || '',
        name: {
          before: '',
          after: item.name,
          hasChanged: false,
        },
        code: {
          before: '',
          after: item.code,
          hasChanged: false,
        },
        isExtraCost: {
          before: '',
          after: item.isExtraCost,
          hasChanged: false,
        },
        colorApplicability: {
          before: {},
          after: fillColorApplicability(item.colorApplicability),
          hasChanged: false,
        },
      } as ExteriorColorsChangeTypeMap;
    }
    Object.entries(item.changes).forEach(([key, change]) => {
      const colorItem = new ColorsReviewItem(item, change);
      colorItem.changeTypeId = key;
      colorRevieItemXform(item, colorItem, colorGradeItems, grades);
      colorItems.push(colorItem);

      isApplied = isApplied && change.isApplied;
      rejectNotes = change.rejectNotes || rejectNotes;
      const changeLogKey = getExteriorKeyFromChangeLogType(change.changeType);
      if (changeLogKey === 'deleted') {
        map[item.id].isDeleted = true;
        map[item.id].name.before = map[item.id].name.after;
        map[item.id].name.after = '';
        map[item.id].name.hasChanged = true;

        map[item.id].code.before = map[item.id].code.after;
        map[item.id].code.after = '';
        map[item.id].code.hasChanged = true;

        map[item.id].isExtraCost.before = map[item.id].isExtraCost.after;
        map[item.id].isExtraCost.after = '';
        map[item.id].isExtraCost.hasChanged = true;

        map[item.id].colorApplicability.hasChanged = true;
      } else if (changeLogKey === 'added') {
        map[item.id].name.hasChanged = true;
        map[item.id].code.hasChanged = true;
        map[item.id].isExtraCost.hasChanged = true;
        map[item.id].colorApplicability.hasChanged = true;
        map[item.id].isNew = true;
      } else if (changeLogKey === 'colorApplicability') {
        map[item.id].colorApplicability.before = fillColorApplicability(colorItem.changes.before);
        map[item.id].colorApplicability.after = fillColorApplicability(colorItem.changes.after);
        map[item.id][changeLogKey].hasChanged = true;
      } else if (changeLogKey) {
        map[item.id][changeLogKey].before = colorItem.changes.before;
        map[item.id][changeLogKey].after = colorItem.changes.after;
        map[item.id][changeLogKey].hasChanged = true;
      }
    });
    map[item.id].isApplied = isApplied;
    map[item.id].rejectNotes = rejectNotes;
  });

  return { colorItems, map };
};

const fillColorApplicability = (colorApplicabilities: any) => {
  const app: ColorApplicabilityReviewMap = {};
  const b = colorApplicabilities as ColorApplicability[];
  b.forEach(colorApp => {
    if (!app[colorApp.grade]) {
      app[colorApp.grade] = {};
    }
    app[colorApp.grade][colorApp.interiorColorId] = true;
  });
  return app;
};

const getExteriorKeyFromChangeLogType = (type: ChangeLogTypes): ExteriorColorReviewType | undefined => {
  switch (type) {
    case ChangeLogTypes.EXT_COLOR_NAME:
      return 'name';
    case ChangeLogTypes.EXT_COLOR_CODE:
      return 'code';
    case ChangeLogTypes.EXT_COLOR_EXTRA_COST:
      return 'isExtraCost';
    case ChangeLogTypes.EXT_COLOR_APPLICABILITY:
      return 'colorApplicability';
    case ChangeLogTypes.EXT_COLOR_ADDED:
      return 'added';
    case ChangeLogTypes.EXT_COLOR_DELETED:
      return 'deleted';
  }
  return undefined;
};

export const colorsInteriorReviewItemXForm = (
  items: ColorsReviewResponse[],
  colorGradeItems: ColorGradeItem[],
  grades: RefItem[],
  vehicleModels: VehicleModelItem<VehicleModelToyota>[],
) => {
  const colorItems: ColorsReviewItem[] = [];
  const map: InteriorColorsReviewMap = {};
  const modelIdToCodeMap: KeyValueType<string> = {};
  vehicleModels.forEach(model => {
    modelIdToCodeMap[model.id] = model.getVal('code');
  });

  items.forEach(item => {
    let isApplied = true;
    let rejectNotes = '';
    if (!map[item.id] && Object.entries(item.changes).length) {
      map[item.id] = {
        id: item.id,
        revId: item.revId,
        isApplied: false,
        isNew: false,
        isDeleted: false,
        rejectNotes: item.rejectNotes,
        notes: item.notes,
        isInProgress: item.isInProgress,
        isExtraCost: item.isExtraCost || '',
        name: {
          before: '',
          after: item.name,
          hasChanged: false,
        },
        code: {
          before: '',
          after: item.code,
          hasChanged: false,
        },
        modelApplicability: {
          before: {},
          after: fillModelApplicability(item.modelApplicability, modelIdToCodeMap),
          hasChanged: false,
        },
      } as InteriorColorsChangeTypeMap;
    }
    Object.entries(item.changes).forEach(([key, change]) => {
      const colorItem = new ColorsReviewItem(item, change);
      colorItem.changeTypeId = key;
      colorRevieItemXform(item, colorItem, colorGradeItems, grades);
      colorItems.push(colorItem);

      isApplied = isApplied && change.isApplied;
      rejectNotes = change.rejectNotes || rejectNotes;
      const changeLogKey = getInteriorKeyFromChangeLogType(change.changeType);
      if (changeLogKey === 'deleted') {
        map[item.id].isDeleted = true;
        map[item.id].name.before = map[item.id].name.after;
        map[item.id].name.after = '';
        map[item.id].name.hasChanged = true;

        map[item.id].code.before = map[item.id].code.after;
        map[item.id].code.after = '';
        map[item.id].code.hasChanged = true;

        map[item.id].modelApplicability.before = map[item.id].modelApplicability.after;
        map[item.id].modelApplicability.after = {};
        map[item.id].modelApplicability.hasChanged = true;
      } else if (changeLogKey === 'added') {
        map[item.id].name.hasChanged = true;
        map[item.id].code.hasChanged = true;
        map[item.id].modelApplicability.hasChanged = true;
        map[item.id].isNew = true;
      } else if (changeLogKey === 'modelApplicability') {
        map[item.id].modelApplicability.before = fillModelApplicability(colorItem.changes.before, modelIdToCodeMap);
        map[item.id].modelApplicability.after = fillModelApplicability(colorItem.changes.after, modelIdToCodeMap);
        map[item.id].modelApplicability.hasChanged = true;
      } else if (changeLogKey) {
        map[item.id][changeLogKey].before = colorItem.changes.before;
        map[item.id][changeLogKey].after = colorItem.changes.after;
        map[item.id][changeLogKey].hasChanged = true;
      }
    });
    if (Object.entries(item.changes).length) {
      map[item.id].isApplied = isApplied;
      map[item.id].rejectNotes = rejectNotes;
    }
  });

  return { map, colorItems };
};

const fillModelApplicability = (modelApplicability: any, modelIdToCodeMap: KeyValueType<string>) => {
  const app: KeyValueType<boolean> = {};
  const modApp = modelApplicability as IntColorModelApplicability;
  Object.values(modApp).forEach(modelIdMap => {
    Object.keys(modelIdMap).forEach(modelId => {
      if (modelIdMap[modelId] && modelIdToCodeMap[modelId]) {
        app[modelIdToCodeMap[modelId]] = true;
      }
    });
  });
  return app;
};

const getInteriorKeyFromChangeLogType = (type: ChangeLogTypes): InteriorColorReviewType | undefined => {
  switch (type) {
    case ChangeLogTypes.INT_COLOR_NAME:
      return 'name';
    case ChangeLogTypes.INT_COLOR_CODE:
      return 'code';
    case ChangeLogTypes.INT_COLOR_MODEL_APPLICABILITY:
      return 'modelApplicability';
    case ChangeLogTypes.INT_COLOR_ADDED:
      return 'added';
    case ChangeLogTypes.INT_COLOR_DELETED:
      return 'deleted';
  }
  return undefined;
};

const colorRevieItemXform = (item: ColorsReviewResponse, colorItem: ColorsReviewItem, colorGradeItems: ColorGradeItem[], grades: RefItem[]) => {
  // fill in all applicabilty model whether selected or not
  colorGradeItems.forEach(gradeItem => {
    colorItem.interiorApplicability[gradeItem.grade.id] = gradeItem.interiorItems.map(intItem => new InteriorItem(gradeItem.grade, intItem, false));
  });

  // see if they are selected
  if (colorItem.interiorApplicability && item.colorApplicability && item.colorApplicability.length) {
    item.colorApplicability.forEach(app => {
      if (colorItem.interiorApplicability[app.grade]) {
        colorItem.interiorApplicability[app.grade].forEach(itm => {
          if (itm.interiorItem.id === app.interiorColorId && itm.grade.id === app.grade) {
            itm.checked = true;
          }
        });
      }
    });
  }

  reviewColorApplicabilityMapper(colorItem, colorGradeItems, ChangeLogTypes.EXT_COLOR_APPLICABILITY, grades);
};

export const reviewColorApplicabilityMapper = (
  reviewItem: ColorsReviewItem | ReviewSyncItem,
  colorGradeItems: ColorGradeItem[],
  changeLogType: ChangeLogTypes,
  grades: RefItem[],
) => {
  if (reviewItem.changes.changeType === changeLogType) {
    const beforeArray = reviewItem.changes.before;
    const afterArray = reviewItem.changes.after;

    processBeforeAfterArrays(beforeArray, afterArray, reviewItem.changes.extColorAppBefore, colorGradeItems, grades);
    processBeforeAfterArrays(afterArray, beforeArray, reviewItem.changes.extColorAppAfter, colorGradeItems, grades);
  }
};

const processBeforeAfterArrays = (targetArray: string, compareArray: string, destinationArray: string[], colorGradeItems: ColorGradeItem[], grades: RefItem[]) => {
  if (Array.isArray(targetArray) && targetArray.length > 0) {
    targetArray.forEach((targetItem: ColorApplicability) => {
      if (Array.isArray(compareArray)) {
        const index = compareArray.findIndex(compareItem => targetItem.grade === compareItem.grade && targetItem.interiorColorId === compareItem.interiorColorId);
        if (index === -1) {
          processInteriorColor(colorGradeItems, targetItem, grades, destinationArray);
        }
      }
    });
  }
};

const processInteriorColor = (colorGradeItems: ColorGradeItem[], targetItem: ColorApplicability, grades: RefItem[], destinationArray: string[]) => {
  let intColor: InteriorColorResponse | undefined;

  colorGradeItems.forEach(color => {
    color.interiorItems.forEach(intItem => {
      if (intItem.id === targetItem.interiorColorId) {
        intColor = intItem;
      }
    });
  });
  if (intColor) {
    // get grade
    const gradeValue = grades.find(grade => grade.id === targetItem.grade)?.value || targetItem.grade;
    destinationArray.push(`${gradeValue} / ${intColor.name} `);
  }
};

export const reassignModelCodes = (selection: ChecklistItem[], groups: InteriorGroupItem[]) => {
  const interiorGroups = selection.map(item => groups.find(grp => grp.id === item.id) as InteriorGroupItem);
  selection.forEach(item => {
    interiorGroups.forEach(group => {
      if (item.id === group.id && item.items) {
        item.items.forEach(modelCode => {
          if (group.models[modelCode.id] && typeof modelCode.selected === 'boolean') {
            group.models[modelCode.id] = modelCode.selected;
          }
        });
      }
    });
  });

  return interiorGroups;
};

export const colorFamilyXform = (
  colorFamilyResp: ColorFamilyResponse[],
  materialsResp: RefItemResponse[],
  interiorColorsResp: CFInteriorColorResponse[],
  exteriorColorsResp: CFExteriorColorResponse[],
) => {
  const materials = refItemsXForm(materialsResp);
  const colorFamilies: ColorFamilyItem[] = [];
  const clrFamMap: { [id: string]: ColorFamilyItem } = {};
  colorFamilyResp.forEach(resp => {
    const colorFamily = new ColorFamilyItem(resp);
    colorFamilies.push(colorFamily);
    clrFamMap[resp.id] = colorFamily;
  });
  const interiorColors = interiorColorsResp.map(intColor => {
    const item = new CFInteriorColorItem(intColor);
    materials.forEach(material => {
      if (material.id === intColor.materialId) {
        item.material = material;
      }
    });
    Object.keys(intColor.colorFamilyIds ?? {}).forEach(famId => {
      if (clrFamMap[famId]) {
        item.colorFamilies.push(clrFamMap[famId]);
        clrFamMap[famId].interiorColorsMap[item.id] = item;
      }
    });
    item.setColorFamilyNames();
    return item;
  });
  const exteriorColors = exteriorColorsResp.map(extColor => {
    const item = new CFExteriorColorItem(extColor);
    Object.keys(extColor.colorFamilyIds ?? {}).forEach(famId => {
      if (clrFamMap[famId]) {
        item.colorFamilies.push(clrFamMap[famId]);
        clrFamMap[famId].exteriorColorsMap[item.id] = item;
      }
    });
    item.setColorFamilyNames();
    return item;
  });
  return { colorFamilies, interiorColors, materials, exteriorColors };
};
