import { processRteForChangeLog } from 'vapi-ui-common';
import { ChangeLogTypes } from '../models/changeLog.model';
import { ColorsReviewResponse } from '../models/colors.model';
import { IDValueType, KeyValueType } from '../models/common.model';
import { CompareFeaturesReviewResponse } from '../models/compareFeatures.model';
import { FeatureSettings, FeaturesReviewResponse } from '../models/features.model';
import { OptionsReviewResponse } from '../models/options.model';
import { ReviewChangeBaseItem } from '../models/review.model';
import { SpecSettings, SpecsReviewResponse } from '../models/specs.model';
import { ModelsReviewResponse } from '../models/vehicleModel.model';

export const updateRevIdById = (id: string, revId: string, items: any[]) => {
  items.forEach(item => {
    if (item.id === id) {
      item.revId = revId;
    }
  });
};

export const handleOtherChangesForReview = (otherChanges: ReviewChangeBaseItem[], objects: { type: ChangeLogTypes; refItems: IDValueType<any>[] }[]) => {
  otherChanges.forEach(otherChange => {
    objects.forEach(obj => {
      if (otherChange.changes.changeType === obj.type) {
        otherChange.changes.beforeValue = obj.refItems.filter(refItem => refItem.id === otherChange.changes.before)[0]?.value;
        otherChange.changes.afterValue = obj.refItems.filter(refItem => refItem.id === otherChange.changes.after)[0]?.value;
      }
    });
  });
};

export const handleOtherChangesForColorsLexusReview = (otherChanges: ReviewChangeBaseItem[], objects: { type: ChangeLogTypes; refItems: IDValueType<any>[] }[]) => {
  otherChanges.forEach(otherChange => {
    objects.forEach(obj => {
      if (otherChange.changes.changeType === obj.type) {
        otherChange.changes.before = obj.refItems.filter(refItem => refItem.id === otherChange.changes.before)[0]?.value;
        otherChange.changes.after = obj.refItems.filter(refItem => refItem.id === otherChange.changes.after)[0]?.value;
      }
    });
  });
};

export const hasNewChange = (
  data: ModelsReviewResponse[] | FeaturesReviewResponse[] | SpecsReviewResponse[] | OptionsReviewResponse[] | ColorsReviewResponse[] | CompareFeaturesReviewResponse[],
) => {
  let newChanges = false;
  data.forEach((item: ModelsReviewResponse | FeaturesReviewResponse | SpecsReviewResponse | OptionsReviewResponse | ColorsReviewResponse | CompareFeaturesReviewResponse) => {
    item.changes &&
      Object.values(item.changes).forEach(change => {
        if (change.isNewChange) {
          newChanges = true;
          return newChanges;
        }
      });
  });

  return newChanges;
};

export const getDefaultChangeTypeMap = (item: FeaturesReviewResponse | SpecsReviewResponse, app: KeyValueType<FeatureSettings | SpecSettings>) => {
  return {
    id: item.id,
    revId: item.revId,
    isApplied: false,
    isDeleted: false,
    description: {
      before: '',
      after: processRteForChangeLog(item.description),
      hasChanged: false,
    },
    modelApplicability: { before: {}, after: app, hasChanged: false },
    isNew: false,
    rejectNotes: '',
    notes: item.notes || '',
    isInProgress: item.isInProgress,
  };
};
