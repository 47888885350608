import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Modal, ReviewModal } from 'vapi-ui-common';
import Header from '../../../../components/Header';
import SecondaryHeader from '../../../../components/SecondaryHeader';
import Spinner from '../../../../components/Spinner';
import { TabPanel } from '../../../../components/Tabs/Tabs';
import Wayfinding from '../../../../components/Wayfinding';
import useQuery from '../../../../hooks/useQuery';
import useStores from '../../../../hooks/useStores';
import { OptionsReviewResponse } from '../../../../models/options.model';
import { Brand } from '../../../../models/user.model';
import { VDTab, VehicleDataVersionInfo, VehicleTeam } from '../../../../models/vehicleData.model';
import modelsReviewStore from '../../../../stores/vehicleData/review/modelReviewStore';
import { handleErrorResponse } from '../../../../utils/errorHandlingUtils';
import { transformResponseModels } from '../../../../utils/modelsUtils';
import { hasNewChange } from '../../../../utils/reviewUtils';
import { getFuelTypes, getSeries } from '../../../../webservices/adminApi';
/* import {
  acceptChangesForCLReview,
  acceptChangesForVDReview,
} from '../../../../webservices/vehicleAdminApi'; */
import { getGrades, getModels, getReviewGrades, getReviewModels } from '../../../../webservices/vehicleModelsApi';
import BnPReview from '../../tabModules/bp/BnPReview';
import ColorsReview from '../../tabModules/colors/ColorsReview';
import CompareFeaturesReview from '../../tabModules/compareFeatures/CompareFeaturesReview';
import FeaturesReviewController from '../../tabModules/features/FeaturesReviewController';
import ModelsReview from '../../tabModules/models/ModelsReview';
import OptionsReview from '../../tabModules/options/OptionsReview';
import SeriesSettingsReview from '../../tabModules/seriesSettings/SeriesSettingsReview';
import SpecsReviewController from '../../tabModules/specs/SpecsReviewController';
import styles from './Review.module.scss';

const Review = () => {
  const {
    reviewStore,
    vehicleModelsStore,
    vehicleSeriesInfoStore,
    featuresReviewStore,
    specsReviewStore,
    optionsReviewStore,
    teamStore,
    colorsReviewStore,
    colorsExteriorReviewStore,
    colorsInteriorReviewStore,
    userStore: { brand, objectId, langPermissions },
    compareFeaturesReviewStore,
  } = useStores();

  const history = useHistory();
  const location = useLocation();
  const query = useQuery();
  const tab = query.get('tab');
  if (tab) {
    reviewStore.selectedTab = tab;
  }

  const [isLoaded, setIsLoaded] = useState(false);
  const [oldTeam, setOldTeam] = useState<string>();
  const [disableModels, setDisableModels] = useState(false);
  const [disableFeatures, setDisableFeatures] = useState(false);
  const [disableSpecs, setDisableSpecs] = useState(false);
  const [disableOptions, setDisableOptions] = useState(false);
  const [disableColors, setDisableColors] = useState(false);
  const [disableCompareFeature, setDisableCompareFeature] = useState(false);

  const { team, seriesId, year, version } = useParams<{
    team: string;
    seriesId: string;
    year: string;
    version: string;
  }>();
  const teamParam = team as VehicleTeam;
  const teamQueryParam = query.get('team') as VehicleTeam;
  const vehicleDataVersionInfo: VehicleDataVersionInfo = {} as VehicleDataVersionInfo;

  // set page
  useEffect(() => {
    teamStore.setTeam(teamParam, brand, langPermissions);
  }, [teamStore, team, teamParam, brand, langPermissions]);

  // load route specific data
  useEffect(() => {
    (async () => {
      try {
        const responses = await Promise.all([
          getSeries(brand),
          teamQueryParam === VehicleTeam.COMMON_LANGUAGE_TEAM
            ? getModels({
                brand,
                team: teamParam,
                series: seriesId,
                year,
                version,
              })
            : getReviewModels(brand, teamParam, seriesId, year, version),
          teamQueryParam === VehicleTeam.COMMON_LANGUAGE_TEAM
            ? getGrades({
                brand,
                team: teamParam,
                series: seriesId,
                year,
                version,
              })
            : getReviewGrades(brand, teamParam, seriesId, year, version),
          getFuelTypes(brand),
        ]);

        const series = responses[0].data.series[seriesId];
        if (series) {
          vehicleSeriesInfoStore.seriesId = seriesId;
          vehicleSeriesInfoStore.seriesName = series.name;
          vehicleSeriesInfoStore.seriesGroup = series.group;
          vehicleSeriesInfoStore.year = year;
        }

        const { grades, vehicleModels, fuelTypes, sortedGrades } = transformResponseModels(
          brand as Brand,
          responses[1].data,
          responses[2].data,
          responses[3].data,
          vehicleModelsStore.getLocalStorage(teamParam),
        );
        vehicleModelsStore.sortedGrades = sortedGrades;
        vehicleModelsStore.grades = grades;
        vehicleModelsStore.vehicleModels = vehicleModels;
        vehicleModelsStore.fuelTypes = fuelTypes;

        const props = {
          brand,
          team: teamStore.team.param,
          series: seriesId,
          year,
          version,
          vehicleModels: vehicleModelsStore.vehicleModels,
        };
        modelsReviewStore.reset();
        featuresReviewStore.reset();
        specsReviewStore.reset();
        optionsReviewStore.reset();
        colorsReviewStore.reset();
        colorsExteriorReviewStore.reset();
        colorsInteriorReviewStore.reset();
        compareFeaturesReviewStore.reset();
        if (teamQueryParam !== VehicleTeam.COMMON_LANGUAGE_TEAM) {
          const allTabsData = await reviewStore.fetchData(props);
          setDisableModels(hasNewChange(allTabsData.models));
          setDisableFeatures(hasNewChange(allTabsData.features));
          setDisableSpecs(hasNewChange(allTabsData.specs));
          setDisableOptions(hasNewChange(allTabsData.options as OptionsReviewResponse[]));
          setDisableColors(hasNewChange(allTabsData.extColors) || hasNewChange(allTabsData.intColors));
          setDisableCompareFeature(allTabsData.compareFeatures.length > 0);
        }
        // set hideAccept if reviewing CL or PT data
        if (teamQueryParam === VehicleTeam.PRODUCT_TEAM || teamQueryParam === VehicleTeam.COMMON_LANGUAGE_TEAM) {
          reviewStore.hideAccept = true;
        } else {
          reviewStore.hideAccept = false;
        }
      } catch (e) {
        toast.error('Failed loading published data');
      }
      setIsLoaded(true);
    })();
  }, [
    vehicleSeriesInfoStore,
    vehicleModelsStore,
    brand,
    objectId,
    seriesId,
    year,
    version,
    reviewStore,
    teamParam,
    teamQueryParam,
    teamStore.team.param,
    specsReviewStore,
    featuresReviewStore,
    optionsReviewStore,
    colorsReviewStore,
    colorsExteriorReviewStore,
    colorsInteriorReviewStore,
    compareFeaturesReviewStore,
  ]);

  // if user navigates to/from different draftPage (e.g PDT Draft to ADT Draft)
  // switch selectedTab back to Features. Otherwise tab selection in PDT Draft
  // will impact on ADT draft, and etc...
  useEffect(() => {
    if (oldTeam !== team) {
      reviewStore.selectedTab = 'Features';
      setOldTeam(team);
    }
  }, [oldTeam, team, reviewStore]);

  const moduleTitle = teamStore.team.pageTitle;
  const wayfindingTo = `/vehicleData/${teamStore.team.name}`;
  const tabs = teamQueryParam === VehicleTeam.COMMON_LANGUAGE_TEAM ? teamStore.team.reviewCLTabs : teamStore.team.reviewTabs;
  const [showReviewModal, setShowReviewModal] = useState(false);

  const handleAcceptChanges = async () => {
    try {
      /*  if (teamQueryParam === VehicleTeam.COMMON_LANGUAGE_TEAM) {
        await trackPromise(
          acceptChangesForCLReview(brand, teamParam, version, seriesId, parseInt(year))
        );
      } else {
        await trackPromise(
          acceptChangesForVDReview(brand, teamParam, version, {
            seriesId,
            modelYear: parseInt(year),
          })
        );
      } */
      toast.success('The changes have been reviewed and accepted.');
      history.push(`/vehicleData/${teamStore.team.name}`);
    } catch (e) {
      handleErrorResponse(e, 'Error accepting changes in review.');
    }
  };

  const showBnP = () => !!teamStore.team.reviewTabs.find(tab => tab.name === VDTab.BNP);

  return isLoaded ? (
    <>
      <div className={styles.header}>
        <Header moduleTitle={moduleTitle} moduleSubTitle="Review">
          <b>You are in Review Mode</b>
        </Header>
      </div>
      <Wayfinding year={year} seriesName={vehicleSeriesInfoStore.seriesName} to={wayfindingTo} />
      <SecondaryHeader
        tabs={tabs.map(tab => tab.name)}
        selectedTab={reviewStore.selectedTab}
        setSelectedTab={tab => {
          reviewStore.selectedTab = tab;
          const encodedTab = encodeURIComponent(tab);
          history.push(`${location.pathname}?team=${teamQueryParam}&tab=${encodedTab}`);
        }}
        isTabDisabled={tab => {
          //if tab has no valid items then return true else return  false
          if (tab === VDTab.MODELS) {
            return !disableModels;
          }
          if (tab === VDTab.FEATURES) {
            return !disableFeatures;
          }
          if (tab === VDTab.SPECS) {
            return !disableSpecs;
          }
          if (tab === VDTab.OPTIONS) {
            return !disableOptions;
          }
          if (tab === VDTab.COLORS) {
            return !disableColors;
          }
          if (tab === VDTab.COMPARE_FEATURES) {
            return !disableCompareFeature;
          }
          return false;
        }}
        renderButtons={() => (
          <>
            <Button variant="primary" onClick={() => setShowReviewModal(true)}>
              Complete Review
            </Button>
            <Modal open={showReviewModal} onClose={() => setShowReviewModal(false)}>
              <ReviewModal close={() => setShowReviewModal(false)} submitData={handleAcceptChanges} />
            </Modal>
          </>
        )}
      />
      <>
        <TabPanel tab={VDTab.SERIES_SETTINGS} selected={reviewStore.selectedTab === VDTab.SERIES_SETTINGS}>
          <SeriesSettingsReview
            team={teamParam}
            seriesId={seriesId}
            year={year}
            version={version}
            versionInfo={vehicleDataVersionInfo}
            vehicleModels={vehicleModelsStore.vehicleModels}
          />
        </TabPanel>
        <TabPanel tab={VDTab.MODELS} selected={reviewStore.selectedTab === VDTab.MODELS}>
          <ModelsReview team={teamParam} seriesId={seriesId} year={year} version={version} versionInfo={vehicleDataVersionInfo} vehicleModels={vehicleModelsStore.vehicleModels} />
        </TabPanel>
        <TabPanel tab={VDTab.FEATURES} selected={reviewStore.selectedTab === VDTab.FEATURES}>
          <FeaturesReviewController
            team={teamParam}
            seriesId={seriesId}
            year={year}
            version={version}
            versionInfo={vehicleDataVersionInfo}
            vehicleModels={vehicleModelsStore.vehicleModels}
          />
        </TabPanel>
        <TabPanel tab={VDTab.OPTIONS} selected={reviewStore.selectedTab === VDTab.OPTIONS}>
          <OptionsReview team={teamParam} seriesId={seriesId} year={year} version={version} versionInfo={vehicleDataVersionInfo} vehicleModels={vehicleModelsStore.vehicleModels} />
        </TabPanel>
        <TabPanel tab={VDTab.SPECS} selected={reviewStore.selectedTab === VDTab.SPECS}>
          <SpecsReviewController
            team={teamParam}
            seriesId={seriesId}
            year={year}
            version={version}
            versionInfo={vehicleDataVersionInfo}
            vehicleModels={vehicleModelsStore.vehicleModels}
          />
        </TabPanel>
        <TabPanel tab={VDTab.COLORS} selected={reviewStore.selectedTab === VDTab.COLORS}>
          <ColorsReview team={teamParam} seriesId={seriesId} year={year} version={version} versionInfo={vehicleDataVersionInfo} vehicleModels={vehicleModelsStore.vehicleModels} />
        </TabPanel>
        {teamStore.team.allowCompareFeatures && (
          <TabPanel tab={VDTab.COMPARE_FEATURES} selected={reviewStore.selectedTab === VDTab.COMPARE_FEATURES}>
            <CompareFeaturesReview
              team={teamParam}
              seriesId={seriesId}
              year={year}
              version={version}
              versionInfo={vehicleDataVersionInfo}
              vehicleModels={vehicleModelsStore.vehicleModels}
              grades={vehicleModelsStore.sortedGrades}
            />
          </TabPanel>
        )}
        {showBnP() && (
          <TabPanel tab={VDTab.BNP} selected={reviewStore.selectedTab === VDTab.BNP}>
            <BnPReview
              team={teamParam}
              seriesId={seriesId}
              year={year}
              version={version}
              versionInfo={vehicleDataVersionInfo}
              vehicleModels={vehicleModelsStore.vehicleModels}
              grades={vehicleModelsStore.sortedGrades}
            />
          </TabPanel>
        )}
      </>
    </>
  ) : (
    <Spinner />
  );
};

export default observer(Review);
