import React from 'react';
import IconTextButton from '../../components/IconTextButton';
import Notes from '../../components/Notes';
import Popover from '../../components/Popover';

type Props = {
  notes: string;
  readOnly?: boolean;
  handleUpdateItem: (notes: string) => void;
  textButtonClassName?: string;
  popoverClass?: string;
  align?: 'right' | 'left';
  defaultOpen?: boolean;
  icon?: 'circle' | 'purpleCircle';
  iconText?: string;
  toggleElement?: JSX.Element; // Overrides default toggle Element
  title?: string;
  left?: number;
  attachTo?: Element;
};

const NotesPopover: React.FC<Props> = ({
  notes,
  readOnly,
  popoverClass,
  handleUpdateItem,
  textButtonClassName,
  align = 'right',
  defaultOpen = false,
  icon = 'circle',
  iconText = 'Notes',
  toggleElement,
  title = 'Notes',
  left = undefined,
  attachTo,
}) => {
  if (notes && readOnly) {
    return (
      <Popover
        popoverClass={popoverClass}
        align={align}
        defaultOpen={defaultOpen}
        toggleElement={
          toggleElement ? (
            toggleElement
          ) : (
            <IconTextButton smallIcon icon={icon} text={iconText} className={textButtonClassName} />
          )
        }
        popoverElement={<Notes readOnly notes={notes} />}
        attachTo={attachTo}
      />
    );
  } else if (readOnly) {
    return <></>;
  }
  return (
    <Popover
      popoverClass={popoverClass}
      align={align}
      defaultOpen={defaultOpen}
      left={left}
      toggleElement={
        toggleElement ? (
          toggleElement
        ) : (
          <IconTextButton
            className={textButtonClassName}
            smallIcon
            icon={notes ? icon ?? 'circle' : 'plus'}
            text={iconText}
          />
        )
      }
      popoverElement={
        <Notes
          notes={notes}
          onUpdate={notes => {
            handleUpdateItem(notes);
          }}
          title={title}
        />
      }
      attachTo={attachTo}
    />
  );
};

export default NotesPopover;
