import React, { useCallback } from "react";
import { BrowserRouter as Router, Redirect, Route } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { v4 as uuidv4 } from "uuid";
import SideMenu from "./components/SideMenu";
import PostSpinner from "./components/Spinner/PostSpinner";
import { Toast } from "./components/Toast";
import UiBlocker from "./components/UiBlocker";
import useStores from "./hooks/useStores";
import {
  AppRoute,
  DisclaimerRoutes,
  RestOfRoutes,
  SeriesCategoriesRoutes,
  VehicleDataRoutes,
} from "./models/routes.model";

type RouteBuilderProps = {
  routes: AppRoute[];
};

const RouteBuilder = ({ routes }: RouteBuilderProps) => {
  return (
    <>
      {routes.map((route: AppRoute) => (
        <React.Fragment key={uuidv4()}>
          <Route path={route.path} exact component={route.component} />
          {route.childRoutes &&
            route.childRoutes.map((childRoute: AppRoute) => (
              <Route
                key={uuidv4()}
                exact
                path={childRoute.path}
                component={childRoute.component}
              />
            ))}
        </React.Fragment>
      ))}
    </>
  );
};

const App = () => {
  const {
    userStore: { modules },
  } = useStores();

  const getDefaultRedirect = useCallback(() => {
    if (modules.AgencyTeam.canView) {
      return <Redirect to="/vehicleData/agency-team" />;
    }

    if (modules.Disclaimers.canView) {
      return <Redirect to="/disclaimers/published" />;
    }

    return <Redirect to="/reports" />;
  }, [modules.AgencyTeam.canView, modules.Disclaimers.canView]);

  return (
    <Router>
      <Toast />
      <UiBlocker>
        <SideMenu>
          <PostSpinner>
            <Route path="/" exact render={getDefaultRedirect} />
            <RouteBuilder routes={VehicleDataRoutes} />
            <RouteBuilder routes={DisclaimerRoutes} />
            <RouteBuilder routes={SeriesCategoriesRoutes} />
            <RouteBuilder routes={RestOfRoutes} />
          </PostSpinner>
        </SideMenu>
      </UiBlocker>
    </Router>
  );
};

export default App;
