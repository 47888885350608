import { faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { NavLink } from 'react-router-dom';
import styles from './wayfinding.module.scss';

type Props = {
  to: string;
  year: string;
  seriesName: string;
  showModels?: boolean;
};

const Wayfinding: React.FC<Props> = ({ to, year, seriesName }) => {
  return (
    <div className={styles.root}>
      <NavLink to={to} className={styles.back}>
        <FontAwesomeIcon icon={faLongArrowAltLeft} className={styles.leftArrow} /> Back
      </NavLink>
      <span className={styles.line} />
      <div className={styles.model}>
        <span>{year}</span> <span className={styles.series}>{seriesName}</span>
      </div>
    </div>
  );
};

export default Wayfinding;
