import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  JSON: any;
};

export type BodyJson = {
  __typename?: 'BodyJSON';
  body: Scalars['JSON'];
};

export type CertifyVehicleDocumentInput = {
  brand: Scalars['String'];
  language: Scalars['String'];
  modelYear: Scalars['Int'];
  seriesId: Scalars['String'];
};

export type CreateSeriesGradeInput = {
  gradeId?: InputMaybe<Scalars['String']>;
  gradeName: Scalars['String'];
  language: Scalars['String'];
  modelYear: Scalars['Int'];
  seriesId: Scalars['String'];
  team: Scalars['String'];
};

export type CreateVehicleColorExtInput = {
  code: Scalars['String'];
  colorApplicability?: InputMaybe<Array<VehicleColoColorApplicabilityInput>>;
  hexCode?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  isExtraCost: Scalars['String'];
  isInProgress: Scalars['Boolean'];
  language: Scalars['String'];
  modelYear: Scalars['Int'];
  name: Scalars['String'];
  notes?: InputMaybe<Scalars['String']>;
  seriesId: Scalars['String'];
  team: Scalars['String'];
};

export type CreateVehicleColorIntInput = {
  code: Scalars['String'];
  gradeApplicability?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  id: Scalars['ID'];
  isExtraCost: Scalars['String'];
  language: Scalars['String'];
  modelApplicability?: InputMaybe<Array<VehicleColoModelApplicabilityInput>>;
  modelYear: Scalars['Int'];
  name: Scalars['String'];
  seriesId: Scalars['String'];
  team: Scalars['String'];
};

export type CreateVehicleDocumentInput = {
  brand: Scalars['String'];
  language: Scalars['String'];
  modelYear: Scalars['Int'];
  seriesId: Scalars['String'];
  team: Scalars['String'];
};

export type CreateVehicleFeatureCategoryInput = {
  categories: Array<VehicleFeatureCategoryInput>;
  id?: InputMaybe<Scalars['String']>;
  modelYear: Scalars['Int'];
  seriesId: Scalars['String'];
  team: Scalars['String'];
};

export type CreateVehicleFeatureCategoryResponse = {
  __typename?: 'CreateVehicleFeatureCategoryResponse';
  vehicleFeaturesLanguageCategories?: Maybe<Array<VehicleFeatureLanguageCategory>>;
};

export type CreateVehicleFeatureCompareInput = {
  categoryId: Scalars['String'];
  compareType?: InputMaybe<VehicleCompareType>;
  description: Scalars['String'];
  gradeApplicability?: InputMaybe<Array<VehicleFeatureGradeApplicabilityInput>>;
  id?: InputMaybe<Scalars['String']>;
  isInProgress?: InputMaybe<Scalars['Boolean']>;
  language: Scalars['String'];
  modelYear: Scalars['Int'];
  notes?: InputMaybe<Scalars['String']>;
  parentId?: InputMaybe<Scalars['String']>;
  seriesId: Scalars['String'];
  subCategoryId?: InputMaybe<Scalars['String']>;
  team: Scalars['String'];
};

export type CreateVehicleFeatureInput = {
  categoryId: Scalars['String'];
  comLangId?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']>;
  isInProgress?: InputMaybe<Scalars['Boolean']>;
  language: Scalars['String'];
  link?: InputMaybe<Scalars['String']>;
  modelApplicability?: InputMaybe<Array<VehicleFeatureModelApplicabilityInput>>;
  modelYear: Scalars['Int'];
  notes?: InputMaybe<Scalars['String']>;
  seriesId: Scalars['String'];
  shortDescription?: InputMaybe<Scalars['String']>;
  subCategoryId: Scalars['String'];
  team: Scalars['String'];
};

export type CreateVehicleFeatureSubCategoryInput = {
  id?: InputMaybe<Scalars['String']>;
  modelYear: Scalars['Int'];
  seriesId: Scalars['String'];
  subCategories: Array<VehicleFeatureSubCategoryInput>;
  team: Scalars['String'];
};

export type CreateVehicleFeatureSubCategoryResponse = {
  __typename?: 'CreateVehicleFeatureSubCategoryResponse';
  vehicleFeaturesLanguageSubCategories?: Maybe<Array<VehicleFeatureLanguageSubCategory>>;
};

export type CreateVehicleOptionCategoryInput = {
  categories: Array<VehicleOptionCategoryInput>;
  id?: InputMaybe<Scalars['String']>;
  modelYear: Scalars['Int'];
  seriesId: Scalars['String'];
  team: Scalars['String'];
};

export type CreateVehicleOptionCategoryResponse = {
  __typename?: 'CreateVehicleOptionCategoryResponse';
  vehicleOptionsLanguageCategories?: Maybe<Array<VehicleOptionLanguageCategory>>;
};

export type CreateVehicleOptionInput = {
  categoryId: Scalars['String'];
  code: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  isExtraCost?: InputMaybe<Scalars['String']>;
  isInProgress?: InputMaybe<Scalars['Boolean']>;
  language: Scalars['String'];
  link?: InputMaybe<Scalars['String']>;
  modelApplicability?: InputMaybe<Array<VehicleOptionModelApplicabilityInput>>;
  modelYear: Scalars['Int'];
  name: Scalars['String'];
  notes?: InputMaybe<Scalars['String']>;
  seriesId: Scalars['String'];
  team: Scalars['String'];
};

export type CreateVehicleSpecCategoryInput = {
  categories: Array<VehicleSpecCategoryInput>;
  id?: InputMaybe<Scalars['String']>;
  modelYear: Scalars['Int'];
  seriesId: Scalars['String'];
  team: Scalars['String'];
};

export type CreateVehicleSpecCategoryResponse = {
  __typename?: 'CreateVehicleSpecCategoryResponse';
  vehicleSpecsLanguageCategories?: Maybe<Array<VehicleSpecLanguageCategory>>;
};

export type CreateVehicleSpecInput = {
  categoryId: Scalars['String'];
  comLangId?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  isInProgress?: InputMaybe<Scalars['Boolean']>;
  language: Scalars['String'];
  link?: InputMaybe<Scalars['String']>;
  modelApplicability?: InputMaybe<Array<VehicleSpecModelApplicabilityInput>>;
  modelYear: Scalars['Int'];
  notes?: InputMaybe<Scalars['String']>;
  seriesId: Scalars['String'];
  shortDescription?: InputMaybe<Scalars['String']>;
  specTypeId: Scalars['String'];
  team: Scalars['String'];
};

export type CreateVehicleSpecSpecType = {
  __typename?: 'CreateVehicleSpecSpecType';
  vehicleSpecsLanguageSpecTypes?: Maybe<Array<VehicleSpecLanguageSpecType>>;
};

export type CreateVehicleSpecSpecTypeInput = {
  id?: InputMaybe<Scalars['String']>;
  modelYear: Scalars['Int'];
  seriesId: Scalars['String'];
  specTypes: Array<VehicleSpecSpecTypeInput>;
  team: Scalars['String'];
};

export enum CurrentStatus {
  Approved = 'Approved',
  Certified = 'Certified',
  Published = 'Published',
  Rejected = 'Rejected',
  Submitted = 'Submitted'
}

export type DashboardResponse = {
  __typename?: 'DashboardResponse';
  series?: Maybe<Array<VehicleSeries>>;
};

export type DeleteModelInput = {
  id: Scalars['ID'];
  language: Scalars['String'];
  modelYear: Scalars['Int'];
  seriesId: Scalars['String'];
  team: Scalars['String'];
};

export type DeleteModelResponse = {
  __typename?: 'DeleteModelResponse';
  id: Scalars['ID'];
  message?: Maybe<Scalars['String']>;
};

export type DeleteVehicleColorInput = {
  id: Scalars['ID'];
  language: Scalars['String'];
  modelYear: Scalars['Int'];
  seriesId: Scalars['String'];
  team: Scalars['String'];
};

export type DeleteVehicleColorResponse = {
  __typename?: 'DeleteVehicleColorResponse';
  id: Scalars['ID'];
  message?: Maybe<Scalars['String']>;
};

export type DeleteVehicleDocumentInput = {
  brand: Scalars['String'];
  language: Scalars['String'];
  modelYear: Scalars['Int'];
  seriesId: Scalars['String'];
  team: Scalars['String'];
};

export type DeleteVehicleDocumentResponse = {
  __typename?: 'DeleteVehicleDocumentResponse';
  message?: Maybe<Scalars['String']>;
};

export type DeleteVehicleFeatureCompareInput = {
  id: Scalars['ID'];
  language: Scalars['String'];
  modelYear: Scalars['Int'];
  seriesId: Scalars['String'];
  team: Scalars['String'];
};

export type DeleteVehicleFeatureCompareResponse = {
  __typename?: 'DeleteVehicleFeatureCompareResponse';
  id: Scalars['ID'];
  message?: Maybe<Scalars['String']>;
};

export type DeleteVehicleFeatureInput = {
  id: Scalars['ID'];
  language: Scalars['String'];
  modelYear: Scalars['Int'];
  seriesId: Scalars['String'];
  team: Scalars['String'];
};

export type DeleteVehicleFeatureResponse = {
  __typename?: 'DeleteVehicleFeatureResponse';
  id: Scalars['ID'];
  message?: Maybe<Scalars['String']>;
};

export type DeleteVehicleOptionInput = {
  id: Scalars['ID'];
  language: Scalars['String'];
  modelYear: Scalars['Int'];
  seriesId: Scalars['String'];
  team: Scalars['String'];
};

export type DeleteVehicleOptionResponse = {
  __typename?: 'DeleteVehicleOptionResponse';
  id: Scalars['ID'];
  message?: Maybe<Scalars['String']>;
};

export type DeleteVehicleSpecInput = {
  id: Scalars['ID'];
  language: Scalars['String'];
  modelYear: Scalars['Int'];
  seriesId: Scalars['String'];
  team: Scalars['String'];
};

export type DeleteVehicleSpecResponse = {
  __typename?: 'DeleteVehicleSpecResponse';
  id: Scalars['ID'];
  message?: Maybe<Scalars['String']>;
};

export type DuplicateVehicleDocumentInput = {
  brand: Scalars['String'];
  fromModelYear: Scalars['Int'];
  language: Scalars['String'];
  seriesId: Scalars['String'];
  team: Scalars['String'];
  toModelYear: Scalars['Int'];
};

export type FeatureSplit = {
  __typename?: 'FeatureSplit';
  description: Scalars['String'];
  id: Scalars['String'];
  isDeleted: Scalars['Boolean'];
  isHighlighted: Scalars['Boolean'];
  longDescription?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  splitNumber: Scalars['Int'];
};

export type FuelType = {
  __typename?: 'FuelType';
  id: Scalars['ID'];
  type: Scalars['String'];
};

export type FuelTypesResponse = {
  __typename?: 'FuelTypesResponse';
  fuelTypes?: Maybe<Array<FuelType>>;
};

export type GradeApplicability = {
  __typename?: 'GradeApplicability';
  applicabilityText?: Maybe<Array<GradeApplicabilityText>>;
  highlighted: Scalars['Boolean'];
  id: Scalars['ID'];
};

export type GradeApplicabilityText = {
  __typename?: 'GradeApplicabilityText';
  availability: Scalars['String'];
  id: Scalars['ID'];
  text: Scalars['String'];
};

export type INode = {
  id: Scalars['ID'];
  rev: Scalars['ID'];
};

export type Model = {
  __typename?: 'Model';
  bed?: Maybe<Scalars['String']>;
  cab?: Maybe<Scalars['String']>;
  changedAttributes?: Maybe<Array<Scalars['String']>>;
  code: Scalars['String'];
  description: Scalars['String'];
  drive: Scalars['String'];
  engine: Scalars['String'];
  fromTMNA?: Maybe<Scalars['Boolean']>;
  fuelType: Scalars['String'];
  goLiveDate?: Maybe<Scalars['String']>;
  grade: Scalars['String'];
  gradeValue?: Maybe<Scalars['String']>;
  horsepower: Scalars['String'];
  id: Scalars['String'];
  isDeleted: Scalars['Boolean'];
  isHybrid: Scalars['Boolean'];
  isNotPublishable: Scalars['Boolean'];
  isTDPR?: Maybe<Scalars['Boolean']>;
  isUSVI?: Maybe<Scalars['Boolean']>;
  katashiki?: Maybe<Scalars['String']>;
  rejectNotes?: Maybe<Scalars['String']>;
  revId: Scalars['String'];
  seating?: Maybe<Scalars['String']>;
  sortOrder?: Maybe<Scalars['Int']>;
  tdprCode?: Maybe<Scalars['String']>;
  transmission: Scalars['String'];
  trimTitle: Scalars['String'];
};

export type ModelApplicability = {
  __typename?: 'ModelApplicability';
  id: Scalars['ID'];
  text?: Maybe<Scalars['String']>;
};

export type ModelsResponse = {
  __typename?: 'ModelsResponse';
  models?: Maybe<Array<Model>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  _?: Maybe<Scalars['Boolean']>;
  certifyVehicleDocument?: Maybe<Scalars['String']>;
  createSeriesGrade?: Maybe<SeriesGradesResponse>;
  createVehicleColorExt?: Maybe<VehicleColorExt>;
  createVehicleColorInt?: Maybe<VehicleColorInt>;
  createVehicleDocument?: Maybe<VehicleDocument>;
  createVehicleFeature?: Maybe<VehicleFeature>;
  createVehicleFeatureCategory?: Maybe<CreateVehicleFeatureCategoryResponse>;
  createVehicleFeatureCompare?: Maybe<VehicleCompareFeature>;
  createVehicleFeatureSubCategory?: Maybe<CreateVehicleFeatureSubCategoryResponse>;
  createVehicleOption?: Maybe<VehicleOption>;
  createVehicleOptionCategory?: Maybe<CreateVehicleOptionCategoryResponse>;
  createVehicleSpec?: Maybe<VehicleSpec>;
  createVehicleSpecCategory?: Maybe<CreateVehicleSpecCategoryResponse>;
  createVehicleSpecSpecType?: Maybe<CreateVehicleSpecSpecType>;
  deleteModel?: Maybe<DeleteModelResponse>;
  deleteVehicleColorExt?: Maybe<DeleteVehicleColorResponse>;
  deleteVehicleColorInt?: Maybe<DeleteVehicleColorResponse>;
  deleteVehicleDocument?: Maybe<DeleteVehicleDocumentResponse>;
  deleteVehicleFeature?: Maybe<DeleteVehicleFeatureResponse>;
  deleteVehicleFeatureCompare?: Maybe<DeleteVehicleFeatureCompareResponse>;
  deleteVehicleOption?: Maybe<DeleteVehicleOptionResponse>;
  deleteVehicleSpec?: Maybe<DeleteVehicleSpecResponse>;
  duplicateVehicleDocument?: Maybe<VehicleDocument>;
  publishVehicleDocument?: Maybe<Scalars['String']>;
  resetVehicleDocumentDraft?: Maybe<Scalars['String']>;
  revertModelsChanges: BodyJson;
  revertVehicleColorsChanges: BodyJson;
  revertVehicleFeaturesChanges: BodyJson;
  revertVehicleFeaturesCompareChanges: BodyJson;
  revertVehicleOptionsChanges: BodyJson;
  revertVehicleSpecsChanges: BodyJson;
  updateModel?: Maybe<Model>;
  updateModelSort: Array<SortListItem>;
  updateSeriesGrade?: Maybe<SeriesGrade>;
  updateSeriesSelections?: Maybe<Array<Maybe<Scalars['String']>>>;
  updateSeriesSetting?: Maybe<UpdateSeriesSettingResponse>;
  updateSortList?: Maybe<SortList>;
  updateVehicleColorExt?: Maybe<VehicleColorExt>;
  updateVehicleColorInt?: Maybe<VehicleColorInt>;
  updateVehicleDocument?: Maybe<VehicleDocument>;
  updateVehicleFeature?: Maybe<VehicleFeature>;
  updateVehicleFeatureCategory?: Maybe<UpdateVehicleFeatureCategoryResponse>;
  updateVehicleFeatureCompare?: Maybe<VehicleCompareFeature>;
  updateVehicleFeatureHighlightSortList?: Maybe<UpdateVehicleFeatureHighlightSortListResponse>;
  updateVehicleFeatureSubCategory?: Maybe<UpdateVehicleFeatureSubCategoryResponse>;
  updateVehicleOption?: Maybe<VehicleOption>;
  updateVehicleOptionCategory?: Maybe<UpdateVehicleOptionCategoryResponse>;
  updateVehicleSpec?: Maybe<VehicleSpec>;
  updateVehicleSpecCategory?: Maybe<VehicleSpecCategory>;
  updateVehicleSpecSpecType?: Maybe<VehicleSpecSpecType>;
  vehicleSync?: Maybe<BodyJson>;
};


export type MutationCertifyVehicleDocumentArgs = {
  input: CertifyVehicleDocumentInput;
};


export type MutationCreateSeriesGradeArgs = {
  input: CreateSeriesGradeInput;
};


export type MutationCreateVehicleColorExtArgs = {
  input: CreateVehicleColorExtInput;
};


export type MutationCreateVehicleColorIntArgs = {
  input: CreateVehicleColorIntInput;
};


export type MutationCreateVehicleDocumentArgs = {
  input: CreateVehicleDocumentInput;
};


export type MutationCreateVehicleFeatureArgs = {
  input: CreateVehicleFeatureInput;
};


export type MutationCreateVehicleFeatureCategoryArgs = {
  input: CreateVehicleFeatureCategoryInput;
};


export type MutationCreateVehicleFeatureCompareArgs = {
  input: CreateVehicleFeatureCompareInput;
};


export type MutationCreateVehicleFeatureSubCategoryArgs = {
  input: CreateVehicleFeatureSubCategoryInput;
};


export type MutationCreateVehicleOptionArgs = {
  input: CreateVehicleOptionInput;
};


export type MutationCreateVehicleOptionCategoryArgs = {
  input: CreateVehicleOptionCategoryInput;
};


export type MutationCreateVehicleSpecArgs = {
  input: CreateVehicleSpecInput;
};


export type MutationCreateVehicleSpecCategoryArgs = {
  input: CreateVehicleSpecCategoryInput;
};


export type MutationCreateVehicleSpecSpecTypeArgs = {
  input: CreateVehicleSpecSpecTypeInput;
};


export type MutationDeleteModelArgs = {
  input: DeleteModelInput;
};


export type MutationDeleteVehicleColorExtArgs = {
  input: DeleteVehicleColorInput;
};


export type MutationDeleteVehicleColorIntArgs = {
  input: DeleteVehicleColorInput;
};


export type MutationDeleteVehicleDocumentArgs = {
  input: DeleteVehicleDocumentInput;
};


export type MutationDeleteVehicleFeatureArgs = {
  input: DeleteVehicleFeatureInput;
};


export type MutationDeleteVehicleFeatureCompareArgs = {
  input: DeleteVehicleFeatureCompareInput;
};


export type MutationDeleteVehicleOptionArgs = {
  input: DeleteVehicleOptionInput;
};


export type MutationDeleteVehicleSpecArgs = {
  input: DeleteVehicleSpecInput;
};


export type MutationDuplicateVehicleDocumentArgs = {
  input: DuplicateVehicleDocumentInput;
};


export type MutationPublishVehicleDocumentArgs = {
  input: PublishVehicleDocumentInput;
};


export type MutationResetVehicleDocumentDraftArgs = {
  input: ResetVehicleDocumentDraftInput;
};


export type MutationRevertModelsChangesArgs = {
  input?: InputMaybe<RevertModelsChanges>;
};


export type MutationRevertVehicleColorsChangesArgs = {
  input?: InputMaybe<RevertVehicleColorsChanges>;
};


export type MutationRevertVehicleFeaturesChangesArgs = {
  input?: InputMaybe<RevertVehicleFeaturesChanges>;
};


export type MutationRevertVehicleFeaturesCompareChangesArgs = {
  input?: InputMaybe<RevertVehicleFeaturesCompareChanges>;
};


export type MutationRevertVehicleOptionsChangesArgs = {
  input?: InputMaybe<RevertVehicleOptionsChanges>;
};


export type MutationRevertVehicleSpecsChangesArgs = {
  input?: InputMaybe<RevertVehicleSpecsChanges>;
};


export type MutationUpdateModelArgs = {
  input: UpdateModelInput;
};


export type MutationUpdateModelSortArgs = {
  input: UpdateModelSortInput;
};


export type MutationUpdateSeriesGradeArgs = {
  input: UpdateSeriesGradeInput;
};


export type MutationUpdateSeriesSelectionsArgs = {
  input: UpdateSeriesSelectionsInput;
};


export type MutationUpdateSeriesSettingArgs = {
  input: UpdateSeriesSettingInput;
};


export type MutationUpdateSortListArgs = {
  input: UpdateSortListInput;
};


export type MutationUpdateVehicleColorExtArgs = {
  input: UpdateVehicleColorExtInput;
};


export type MutationUpdateVehicleColorIntArgs = {
  input: UpdateVehicleColorIntInput;
};


export type MutationUpdateVehicleDocumentArgs = {
  input: UpdateVehicleDocumentInput;
};


export type MutationUpdateVehicleFeatureArgs = {
  input: UpdateVehicleFeatureInput;
};


export type MutationUpdateVehicleFeatureCategoryArgs = {
  input: UpdateVehicleFeatureCategoryInput;
};


export type MutationUpdateVehicleFeatureCompareArgs = {
  input: UpdateVehicleFeatureCompareInput;
};


export type MutationUpdateVehicleFeatureHighlightSortListArgs = {
  input: UpdateVehicleFeatureHighlightSortListInput;
};


export type MutationUpdateVehicleFeatureSubCategoryArgs = {
  input: UpdateVehicleFeatureSubCategoryInput;
};


export type MutationUpdateVehicleOptionArgs = {
  input: UpdateVehicleOptionInput;
};


export type MutationUpdateVehicleOptionCategoryArgs = {
  input: UpdateVehicleOptionCategoryInput;
};


export type MutationUpdateVehicleSpecArgs = {
  input: UpdateVehicleSpecInput;
};


export type MutationUpdateVehicleSpecCategoryArgs = {
  input: UpdateVehicleSpecCategoryInput;
};


export type MutationUpdateVehicleSpecSpecTypeArgs = {
  input: UpdateVehicleSpecSpecTypeInput;
};


export type MutationVehicleSyncArgs = {
  input: VehicleSyncInput;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type PublishVehicleDocumentInput = {
  brand: Scalars['String'];
  language: Scalars['String'];
  modelYear: Scalars['Int'];
  publishType: Scalars['String'];
  republish: Scalars['Boolean'];
  seriesId: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  _?: Maybe<Scalars['Boolean']>;
  dashboard?: Maybe<DashboardResponse>;
  disclaimersTokens: Array<Maybe<Scalars['String']>>;
  fuelTypes?: Maybe<FuelTypesResponse>;
  models?: Maybe<ModelsResponse>;
  modelsChanges?: Maybe<BodyJson>;
  series?: Maybe<Array<SeriesItem>>;
  seriesCategories?: Maybe<SeriesCategoriesResponse>;
  seriesGrades?: Maybe<SeriesGradesResponse>;
  seriesSelections?: Maybe<Array<SeriesItem>>;
  seriesSettings?: Maybe<SeriesSettingsResponse>;
  sortList?: Maybe<SortList>;
  vehicleColorsChanges?: Maybe<BodyJson>;
  vehicleColorsExt?: Maybe<VehicleColorsExtResponse>;
  vehicleColorsInt?: Maybe<VehicleColorsIntResponse>;
  vehicleCompare?: Maybe<BodyJson>;
  vehicleDocument?: Maybe<VehicleDocument>;
  vehicleDocumentResetDraftCount: Scalars['Int'];
  vehicleFeatures?: Maybe<VehicleFeaturesResponse>;
  vehicleFeaturesCategories?: Maybe<VehicleFeaturesCategoriesResponse>;
  vehicleFeaturesChanges?: Maybe<BodyJson>;
  vehicleFeaturesCompare?: Maybe<VehicleFeaturesCompareResponse>;
  vehicleFeaturesCompareChanges?: Maybe<BodyJson>;
  vehicleFeaturesSubCategories?: Maybe<VehicleFeaturesSubCategoriesResponse>;
  vehicleOptions?: Maybe<VehicleOptionsResponse>;
  vehicleOptionsCategories?: Maybe<VehicleOptionsCategoriesResponse>;
  vehicleOptionsChanges?: Maybe<BodyJson>;
  vehicleSpecs?: Maybe<VehicleSpecsResponse>;
  vehicleSpecsCategories?: Maybe<VehicleSpecsCategoriesResponse>;
  vehicleSpecsChanges?: Maybe<BodyJson>;
  vehicleSpecsSpecTypes?: Maybe<VehicleSpecsSpecTypesResponse>;
  vehicleVersions?: Maybe<Array<VehicleVersion>>;
};


export type QueryDashboardArgs = {
  brand: Scalars['String'];
  lang?: InputMaybe<Scalars['String']>;
  team: Scalars['String'];
};


export type QueryDisclaimersTokensArgs = {
  brand: Scalars['String'];
};


export type QueryFuelTypesArgs = {
  brand: Scalars['String'];
};


export type QueryModelsArgs = {
  includeAll?: InputMaybe<Scalars['Boolean']>;
  language: Scalars['String'];
  modelYear: Scalars['Int'];
  seriesId: Scalars['ID'];
  status?: InputMaybe<Scalars['String']>;
  team: Scalars['String'];
  version?: InputMaybe<Scalars['Int']>;
};


export type QueryModelsChangesArgs = {
  language: Scalars['String'];
  modelYear: Scalars['Int'];
  seriesId: Scalars['ID'];
  status?: InputMaybe<Scalars['String']>;
  team: Scalars['String'];
  version?: InputMaybe<Scalars['Int']>;
};


export type QuerySeriesArgs = {
  brand: Scalars['String'];
};


export type QuerySeriesCategoriesArgs = {
  brand: Scalars['String'];
  getLatestVersion?: InputMaybe<Scalars['Boolean']>;
};


export type QuerySeriesGradesArgs = {
  includeAll?: InputMaybe<Scalars['Boolean']>;
  language: Scalars['String'];
  modelYear: Scalars['Int'];
  seriesId: Scalars['ID'];
  status?: InputMaybe<Scalars['String']>;
  team: Scalars['String'];
  version?: InputMaybe<Scalars['Int']>;
};


export type QuerySeriesSelectionsArgs = {
  brand: Scalars['String'];
  objectId: Scalars['ID'];
};


export type QuerySeriesSettingsArgs = {
  includeAll?: InputMaybe<Scalars['Boolean']>;
  language: Scalars['String'];
  modelYear: Scalars['Int'];
  seriesId: Scalars['ID'];
  status?: InputMaybe<Scalars['String']>;
  team: Scalars['String'];
  version?: InputMaybe<Scalars['Int']>;
};


export type QuerySortListArgs = {
  dataType: Scalars['String'];
  modelYear: Scalars['Int'];
  seriesId: Scalars['ID'];
  status?: InputMaybe<Scalars['String']>;
  team: Scalars['String'];
  version?: InputMaybe<Scalars['Int']>;
};


export type QueryVehicleColorsChangesArgs = {
  language: Scalars['String'];
  modelYear: Scalars['Int'];
  seriesId: Scalars['ID'];
  status?: InputMaybe<Scalars['String']>;
  team: Scalars['String'];
  version?: InputMaybe<Scalars['Int']>;
};


export type QueryVehicleColorsExtArgs = {
  language: Scalars['String'];
  modelYear: Scalars['Int'];
  seriesId: Scalars['ID'];
  status?: InputMaybe<Scalars['String']>;
  team: Scalars['String'];
  version?: InputMaybe<Scalars['Int']>;
};


export type QueryVehicleColorsIntArgs = {
  includeAll?: InputMaybe<Scalars['Boolean']>;
  language: Scalars['String'];
  modelYear: Scalars['Int'];
  seriesId: Scalars['ID'];
  status?: InputMaybe<Scalars['String']>;
  team: Scalars['String'];
  version?: InputMaybe<Scalars['Int']>;
};


export type QueryVehicleCompareArgs = {
  entityType: Scalars['String'];
  itemId: Scalars['String'];
  modelYear: Scalars['Int'];
  parentSeriesId?: InputMaybe<Scalars['String']>;
  seriesId: Scalars['ID'];
  team: Scalars['String'];
};


export type QueryVehicleDocumentArgs = {
  language: Scalars['String'];
  modelYear: Scalars['Int'];
  seriesId: Scalars['ID'];
  status?: InputMaybe<Scalars['String']>;
  team: Scalars['String'];
  version?: InputMaybe<Scalars['Int']>;
};


export type QueryVehicleDocumentResetDraftCountArgs = {
  modelYear: Scalars['Int'];
  seriesId: Scalars['ID'];
  team: Scalars['String'];
};


export type QueryVehicleFeaturesArgs = {
  language: Scalars['String'];
  modelYear: Scalars['Int'];
  seriesId: Scalars['ID'];
  status?: InputMaybe<Scalars['String']>;
  team: Scalars['String'];
  version?: InputMaybe<Scalars['Int']>;
};


export type QueryVehicleFeaturesCategoriesArgs = {
  includeAll?: InputMaybe<Scalars['Boolean']>;
  language: Scalars['String'];
  modelYear: Scalars['Int'];
  seriesId: Scalars['ID'];
  status?: InputMaybe<Scalars['String']>;
  team: Scalars['String'];
  version?: InputMaybe<Scalars['Int']>;
};


export type QueryVehicleFeaturesChangesArgs = {
  language: Scalars['String'];
  modelYear: Scalars['Int'];
  seriesId: Scalars['ID'];
  status?: InputMaybe<Scalars['String']>;
  team: Scalars['String'];
  version?: InputMaybe<Scalars['Int']>;
};


export type QueryVehicleFeaturesCompareArgs = {
  language: Scalars['String'];
  modelYear: Scalars['Int'];
  seriesId: Scalars['ID'];
  status?: InputMaybe<Scalars['String']>;
  team: Scalars['String'];
  version?: InputMaybe<Scalars['Int']>;
};


export type QueryVehicleFeaturesCompareChangesArgs = {
  language: Scalars['String'];
  modelYear: Scalars['Int'];
  seriesId: Scalars['ID'];
  status?: InputMaybe<Scalars['String']>;
  team: Scalars['String'];
  version?: InputMaybe<Scalars['Int']>;
};


export type QueryVehicleFeaturesSubCategoriesArgs = {
  includeAll?: InputMaybe<Scalars['Boolean']>;
  language: Scalars['String'];
  modelYear: Scalars['Int'];
  seriesId: Scalars['ID'];
  status?: InputMaybe<Scalars['String']>;
  team: Scalars['String'];
  version?: InputMaybe<Scalars['Int']>;
};


export type QueryVehicleOptionsArgs = {
  language: Scalars['String'];
  modelYear: Scalars['Int'];
  seriesId: Scalars['ID'];
  status?: InputMaybe<Scalars['String']>;
  team: Scalars['String'];
  version?: InputMaybe<Scalars['Int']>;
};


export type QueryVehicleOptionsCategoriesArgs = {
  includeAll?: InputMaybe<Scalars['Boolean']>;
  language: Scalars['String'];
  modelYear: Scalars['Int'];
  seriesId: Scalars['ID'];
  status?: InputMaybe<Scalars['String']>;
  team: Scalars['String'];
  version?: InputMaybe<Scalars['Int']>;
};


export type QueryVehicleOptionsChangesArgs = {
  language: Scalars['String'];
  modelYear: Scalars['Int'];
  seriesId: Scalars['ID'];
  status?: InputMaybe<Scalars['String']>;
  team: Scalars['String'];
  version?: InputMaybe<Scalars['Int']>;
};


export type QueryVehicleSpecsArgs = {
  language: Scalars['String'];
  modelYear: Scalars['Int'];
  seriesId: Scalars['ID'];
  status?: InputMaybe<Scalars['String']>;
  team: Scalars['String'];
  version?: InputMaybe<Scalars['Int']>;
};


export type QueryVehicleSpecsCategoriesArgs = {
  includeAll?: InputMaybe<Scalars['Boolean']>;
  language: Scalars['String'];
  modelYear: Scalars['Int'];
  seriesId: Scalars['ID'];
  status?: InputMaybe<Scalars['String']>;
  team: Scalars['String'];
  version?: InputMaybe<Scalars['Int']>;
};


export type QueryVehicleSpecsChangesArgs = {
  language: Scalars['String'];
  modelYear: Scalars['Int'];
  seriesId: Scalars['ID'];
  status?: InputMaybe<Scalars['String']>;
  team: Scalars['String'];
  version?: InputMaybe<Scalars['Int']>;
};


export type QueryVehicleSpecsSpecTypesArgs = {
  includeAll?: InputMaybe<Scalars['Boolean']>;
  language: Scalars['String'];
  modelYear: Scalars['Int'];
  seriesId: Scalars['ID'];
  status?: InputMaybe<Scalars['String']>;
  team: Scalars['String'];
  version?: InputMaybe<Scalars['Int']>;
};


export type QueryVehicleVersionsArgs = {
  language: Scalars['String'];
  modelYear: Scalars['Int'];
  seriesId: Scalars['ID'];
  team: Scalars['String'];
};

export type ResetVehicleDocumentDraftInput = {
  language: Scalars['String'];
  modelYear: Scalars['Int'];
  seriesId: Scalars['String'];
  team: Scalars['String'];
};

export type RevertModelsChanges = {
  body: Scalars['JSON'];
  brand: Scalars['String'];
  language: Scalars['String'];
  modelYear: Scalars['Int'];
  seriesId: Scalars['String'];
  team: Scalars['String'];
};

export type RevertVehicleColorsChanges = {
  body: Scalars['JSON'];
  brand: Scalars['String'];
  language: Scalars['String'];
  modelYear: Scalars['Int'];
  seriesId: Scalars['String'];
  team: Scalars['String'];
};

export type RevertVehicleFeaturesChanges = {
  body: Scalars['JSON'];
  brand: Scalars['String'];
  language: Scalars['String'];
  modelYear: Scalars['Int'];
  seriesId: Scalars['String'];
  team: Scalars['String'];
};

export type RevertVehicleFeaturesCompareChanges = {
  body: Scalars['JSON'];
  brand: Scalars['String'];
  language: Scalars['String'];
  modelYear: Scalars['Int'];
  seriesId: Scalars['String'];
  team: Scalars['String'];
};

export type RevertVehicleOptionsChanges = {
  body: Scalars['JSON'];
  brand: Scalars['String'];
  language: Scalars['String'];
  modelYear: Scalars['Int'];
  seriesId: Scalars['String'];
  team: Scalars['String'];
};

export type RevertVehicleSpecsChanges = {
  body: Scalars['JSON'];
  brand: Scalars['String'];
  language: Scalars['String'];
  modelYear: Scalars['Int'];
  seriesId: Scalars['String'];
  team: Scalars['String'];
};

export type SeriesCategoriesResponse = {
  __typename?: 'SeriesCategoriesResponse';
  seriesCategories?: Maybe<Array<SeriesCategory>>;
};

export type SeriesCategory = {
  __typename?: 'SeriesCategory';
  id: Scalars['ID'];
  name: Scalars['String'];
  nameES?: Maybe<Scalars['String']>;
  revId: Scalars['ID'];
  series?: Maybe<Array<SeriesCategorySeries>>;
  seriesId: Scalars['String'];
};

export type SeriesCategorySeries = {
  __typename?: 'SeriesCategorySeries';
  isPublishedToDownstream: Scalars['Boolean'];
  seriesName: Scalars['String'];
  seriesYear: Scalars['String'];
};

export type SeriesGrade = {
  __typename?: 'SeriesGrade';
  id: Scalars['ID'];
  isDeleted: Scalars['Boolean'];
  isRequired?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
};

export type SeriesGradesResponse = {
  __typename?: 'SeriesGradesResponse';
  seriesGrades?: Maybe<Array<SeriesGrade>>;
};

export type SeriesItem = {
  __typename?: 'SeriesItem';
  group: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  revId: Scalars['String'];
  sourceBrand?: Maybe<Scalars['String']>;
  sourceSeriesId?: Maybe<Scalars['ID']>;
};

export type SeriesModelYear = {
  __typename?: 'SeriesModelYear';
  versions?: Maybe<Array<SeriesModelYearVersionResponse>>;
  year: Scalars['Int'];
};

export type SeriesModelYearVersion = {
  __typename?: 'SeriesModelYearVersion';
  createdDate?: Maybe<Scalars['String']>;
  datePublished: Scalars['String'];
  isDraft: Scalars['Boolean'];
  isPendingCLReview?: Maybe<Scalars['Boolean']>;
  isPendingVDReview?: Maybe<Scalars['Boolean']>;
  isSubmitted?: Maybe<Scalars['Boolean']>;
  previewDownstreamDate?: Maybe<Scalars['String']>;
  previewDownstreamVersion?: Maybe<Scalars['Int']>;
  publishDownstreamDate?: Maybe<Scalars['String']>;
  publishDownstreamVersion?: Maybe<Scalars['Int']>;
  skippedLivePublish?: Maybe<Scalars['Boolean']>;
  vdStatus?: Maybe<StatusItem>;
  version?: Maybe<Scalars['Int']>;
  year: Scalars['Int'];
};

export type SeriesModelYearVersionResponse = SeriesModelYearVersion | SeriesModelYearVersionSpanish;

export type SeriesModelYearVersionSpanish = {
  __typename?: 'SeriesModelYearVersionSpanish';
  englishPublishedDate: Scalars['String'];
  englishVersion: Scalars['Int'];
  hasSourceUpdates?: Maybe<Array<SourceUpdate>>;
  isDraft: Scalars['Boolean'];
  key: Scalars['String'];
  outOfSync?: Maybe<Scalars['Boolean']>;
  previewDownstreamDate?: Maybe<Scalars['String']>;
  previewDownstreamVersion?: Maybe<Scalars['Int']>;
  publishDownstreamDate?: Maybe<Scalars['String']>;
  publishDownstreamVersion?: Maybe<Scalars['Int']>;
  skippedLivePublish?: Maybe<Scalars['Boolean']>;
  sourceLang?: Maybe<Scalars['String']>;
  sourceVersion?: Maybe<Scalars['Int']>;
  spanishPublishedDate?: Maybe<Scalars['String']>;
  spanishVersion?: Maybe<Scalars['Int']>;
  vdStatus?: Maybe<StatusItem>;
  year: Scalars['Int'];
};

export type SeriesSetting = {
  __typename?: 'SeriesSetting';
  changedAttributes?: Maybe<Array<Scalars['String']>>;
  dph?: Maybe<Scalars['String']>;
  estimatedMileage?: Maybe<Scalars['String']>;
  fromTMNA?: Maybe<Scalars['Boolean']>;
  fuelTypes: Array<Scalars['ID']>;
  horsepower?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPublishable: Scalars['Boolean'];
  mpge?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  range?: Maybe<Scalars['String']>;
  revId: Scalars['String'];
  seating?: Maybe<Scalars['String']>;
  seriesCategories: Array<Scalars['ID']>;
  seriesId: Scalars['String'];
  seriesNumberId?: Maybe<Scalars['Int']>;
  startingMSRP?: Maybe<Scalars['String']>;
  subSeries?: Maybe<Array<SeriesSubSetting>>;
};

export type SeriesSettingsResponse = {
  __typename?: 'SeriesSettingsResponse';
  seriesSettings?: Maybe<Array<SeriesSetting>>;
};

export type SeriesSubSetting = {
  __typename?: 'SeriesSubSetting';
  changedAttributes?: Maybe<Array<Scalars['String']>>;
  dph?: Maybe<Scalars['String']>;
  estimatedMileage?: Maybe<Scalars['String']>;
  fromTMNA?: Maybe<Scalars['Boolean']>;
  fuelTypes: Array<Scalars['ID']>;
  horsepower?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPublishable: Scalars['Boolean'];
  mpge?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  parentId: Scalars['ID'];
  range?: Maybe<Scalars['String']>;
  revId: Scalars['String'];
  seating?: Maybe<Scalars['String']>;
  seriesCategories: Array<Scalars['ID']>;
  seriesId: Scalars['String'];
  seriesNumberId?: Maybe<Scalars['Int']>;
  startingMSRP?: Maybe<Scalars['String']>;
};

export type SortItem = {
  __typename?: 'SortItem';
  id: Scalars['ID'];
  position: Scalars['Int'];
};

export type SortItemInput = {
  id: Scalars['ID'];
  position: Scalars['Int'];
};

export type SortList = {
  __typename?: 'SortList';
  sortList?: Maybe<Array<SortItem>>;
};

export type SortListItem = {
  __typename?: 'SortListItem';
  id: Scalars['ID'];
  revId: Scalars['String'];
  sortOrder: Scalars['Int'];
};

export type SortListItemInput = {
  id: Scalars['ID'];
  revId: Scalars['String'];
  sortOrder: Scalars['Int'];
};

export type SourceUpdate = {
  __typename?: 'SourceUpdate';
  hasUpdates: Scalars['Boolean'];
  lang: Scalars['String'];
};

export type StatusItem = {
  __typename?: 'StatusItem';
  currentStatus?: Maybe<CurrentStatus>;
  modifiedBy?: Maybe<Scalars['String']>;
  modifiedDate?: Maybe<Scalars['String']>;
};

export type UpdateModelInput = {
  acceptChanges?: InputMaybe<Scalars['Boolean']>;
  bed?: InputMaybe<Scalars['String']>;
  brand: Scalars['String'];
  cab?: InputMaybe<Scalars['String']>;
  code: Scalars['String'];
  description: Scalars['String'];
  drive: Scalars['String'];
  engine: Scalars['String'];
  fuelType: Scalars['String'];
  goLiveDate?: InputMaybe<Scalars['String']>;
  grade: Scalars['String'];
  horsepower: Scalars['String'];
  id: Scalars['ID'];
  isHybrid: Scalars['Boolean'];
  isNotPublishable: Scalars['Boolean'];
  isTDPR?: InputMaybe<Scalars['Boolean']>;
  isUSVI?: InputMaybe<Scalars['Boolean']>;
  katashiki?: InputMaybe<Scalars['String']>;
  language: Scalars['String'];
  modelYear: Scalars['Int'];
  revId: Scalars['ID'];
  seating?: InputMaybe<Scalars['String']>;
  seriesId: Scalars['String'];
  tdprCode?: InputMaybe<Scalars['String']>;
  team: Scalars['String'];
  transmission: Scalars['String'];
  trimTitle: Scalars['String'];
  unlinkFromTMNA?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateModelSortInput = {
  brand: Scalars['String'];
  modelYear: Scalars['Int'];
  seriesId: Scalars['String'];
  sortList: Array<SortListItemInput>;
  team: Scalars['String'];
};

export type UpdateSeriesGradeInput = {
  gradeId: Scalars['String'];
  gradeName: Scalars['String'];
  language: Scalars['String'];
  modelYear: Scalars['Int'];
  seriesId: Scalars['String'];
  team: Scalars['String'];
};

export type UpdateSeriesSelectionsInput = {
  brand?: InputMaybe<Scalars['String']>;
  objectId: Scalars['ID'];
  seriesSelections?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type UpdateSeriesSettingInput = {
  acceptChanges?: InputMaybe<Scalars['Boolean']>;
  brand: Scalars['String'];
  estimatedMileage?: InputMaybe<Scalars['String']>;
  fuelTypes: Array<Scalars['ID']>;
  id: Scalars['ID'];
  isDeleted: Scalars['Boolean'];
  isPublishable?: InputMaybe<Scalars['Boolean']>;
  language: Scalars['String'];
  modelYear: Scalars['Int'];
  mpge?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  parentId?: InputMaybe<Scalars['String']>;
  parentRevId?: InputMaybe<Scalars['String']>;
  range?: InputMaybe<Scalars['String']>;
  revId: Scalars['ID'];
  seating: Scalars['String'];
  seriesCategories: Array<Scalars['ID']>;
  seriesId: Scalars['String'];
  team: Scalars['String'];
  unlinkFromTMNA?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateSeriesSettingResponse = SeriesSetting | SeriesSubSetting;

export type UpdateSortListInput = {
  dataType: Scalars['String'];
  modelYear: Scalars['Int'];
  seriesId: Scalars['String'];
  sortList: Array<InputMaybe<SortItemInput>>;
  team: Scalars['String'];
};

export type UpdateVehicleColorExtInput = {
  acceptChanges?: InputMaybe<Scalars['Boolean']>;
  code: Scalars['String'];
  colorApplicability?: InputMaybe<Array<VehicleColoColorApplicabilityInput>>;
  hexCode?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  isExtraCost: Scalars['String'];
  isInProgress: Scalars['Boolean'];
  language: Scalars['String'];
  modelYear: Scalars['Int'];
  name: Scalars['String'];
  notes?: InputMaybe<Scalars['String']>;
  revId: Scalars['String'];
  seriesId: Scalars['String'];
  team: Scalars['String'];
  unlinkFromTMNA?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateVehicleColorIntInput = {
  acceptChanges?: InputMaybe<Scalars['Boolean']>;
  code: Scalars['String'];
  gradeApplicability?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  id: Scalars['ID'];
  isExtraCost: Scalars['String'];
  language: Scalars['String'];
  modelApplicability?: InputMaybe<Array<VehicleColoModelApplicabilityInput>>;
  modelYear: Scalars['Int'];
  name: Scalars['String'];
  revId: Scalars['String'];
  seriesId: Scalars['String'];
  team: Scalars['String'];
  unlinkFromTMNA?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateVehicleDocumentInput = {
  brand: Scalars['String'];
  language: Scalars['String'];
  modelYear: Scalars['Int'];
  seriesId: Scalars['String'];
  team: Scalars['String'];
};

export type UpdateVehicleFeatureCategoryInput = {
  id: Scalars['ID'];
  language: Scalars['String'];
  modelYear: Scalars['Int'];
  name: Scalars['String'];
  seriesId: Scalars['String'];
  team: Scalars['String'];
};

export type UpdateVehicleFeatureCategoryResponse = {
  __typename?: 'UpdateVehicleFeatureCategoryResponse';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type UpdateVehicleFeatureCompareInput = {
  acceptChanges?: InputMaybe<Scalars['Boolean']>;
  categoryId: Scalars['String'];
  compareType?: InputMaybe<VehicleCompareType>;
  description: Scalars['String'];
  gradeApplicability?: InputMaybe<Array<VehicleFeatureGradeApplicabilityInput>>;
  id: Scalars['String'];
  isInProgress?: InputMaybe<Scalars['Boolean']>;
  language: Scalars['String'];
  modelYear: Scalars['Int'];
  notes?: InputMaybe<Scalars['String']>;
  parentId?: InputMaybe<Scalars['String']>;
  revId: Scalars['String'];
  seriesId: Scalars['String'];
  subCategoryId?: InputMaybe<Scalars['String']>;
  team: Scalars['String'];
  unlinkFromTMNA?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateVehicleFeatureHighlightSortListDoubleSortItemInput = {
  id: Scalars['String'];
  sortList?: InputMaybe<Array<InputMaybe<UpdateVehicleFeatureHighlightSortListSortItemInput>>>;
};

export type UpdateVehicleFeatureHighlightSortListInput = {
  doubleSortList: Array<UpdateVehicleFeatureHighlightSortListDoubleSortItemInput>;
  language: Scalars['String'];
  modelYear: Scalars['Int'];
  seriesId: Scalars['String'];
  team: Scalars['String'];
};

export type UpdateVehicleFeatureHighlightSortListResponse = {
  __typename?: 'UpdateVehicleFeatureHighlightSortListResponse';
  doubleSortList?: Maybe<Array<VehicleCompareDoubleSortList>>;
};

export type UpdateVehicleFeatureHighlightSortListSortItemInput = {
  id: Scalars['String'];
  position: Scalars['Int'];
};

export type UpdateVehicleFeatureInput = {
  acceptChanges?: InputMaybe<Scalars['Boolean']>;
  categoryId: Scalars['String'];
  comLangId?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  id: Scalars['ID'];
  isHighlighted?: InputMaybe<Scalars['Boolean']>;
  isInProgress?: InputMaybe<Scalars['Boolean']>;
  language: Scalars['String'];
  link?: InputMaybe<Scalars['String']>;
  modelApplicability?: InputMaybe<Array<VehicleFeatureModelApplicabilityInput>>;
  modelYear: Scalars['Int'];
  notes?: InputMaybe<Scalars['String']>;
  revId: Scalars['String'];
  seriesId: Scalars['String'];
  shortDescription?: InputMaybe<Scalars['String']>;
  subCategoryId: Scalars['String'];
  team: Scalars['String'];
  unlinkFromTMNA?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateVehicleFeatureSubCategoryInput = {
  id: Scalars['ID'];
  language: Scalars['String'];
  modelYear: Scalars['Int'];
  name: Scalars['String'];
  seriesId: Scalars['String'];
  team: Scalars['String'];
};

export type UpdateVehicleFeatureSubCategoryResponse = {
  __typename?: 'UpdateVehicleFeatureSubCategoryResponse';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type UpdateVehicleOptionCategoryInput = {
  id: Scalars['ID'];
  language: Scalars['String'];
  modelYear: Scalars['Int'];
  name: Scalars['String'];
  seriesId: Scalars['String'];
  team: Scalars['String'];
};

export type UpdateVehicleOptionCategoryResponse = {
  __typename?: 'UpdateVehicleOptionCategoryResponse';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type UpdateVehicleOptionInput = {
  acceptChanges?: InputMaybe<Scalars['Boolean']>;
  categoryId: Scalars['String'];
  code: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  isExtraCost?: InputMaybe<Scalars['String']>;
  isInProgress?: InputMaybe<Scalars['Boolean']>;
  language: Scalars['String'];
  link?: InputMaybe<Scalars['String']>;
  modelApplicability?: InputMaybe<Array<VehicleOptionModelApplicabilityInput>>;
  modelYear: Scalars['Int'];
  name: Scalars['String'];
  notes?: InputMaybe<Scalars['String']>;
  revId: Scalars['String'];
  seriesId: Scalars['String'];
  team: Scalars['String'];
  unlinkFromTMNA?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateVehicleSpecCategoryInput = {
  id: Scalars['ID'];
  language: Scalars['String'];
  modelYear: Scalars['Int'];
  name: Scalars['String'];
  seriesId: Scalars['String'];
  team: Scalars['String'];
};

export type UpdateVehicleSpecInput = {
  acceptChanges?: InputMaybe<Scalars['Boolean']>;
  categoryId: Scalars['String'];
  comLangId?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  id: Scalars['ID'];
  isInProgress?: InputMaybe<Scalars['Boolean']>;
  language: Scalars['String'];
  link?: InputMaybe<Scalars['String']>;
  modelApplicability?: InputMaybe<Array<VehicleFeatureModelApplicabilityInput>>;
  modelYear: Scalars['Int'];
  notes?: InputMaybe<Scalars['String']>;
  revId: Scalars['String'];
  seriesId: Scalars['String'];
  shortDescription?: InputMaybe<Scalars['String']>;
  specTypeId: Scalars['String'];
  team: Scalars['String'];
  unlinkFromTMNA?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateVehicleSpecSpecTypeInput = {
  id: Scalars['ID'];
  language: Scalars['String'];
  modelYear: Scalars['Int'];
  name: Scalars['String'];
  seriesId: Scalars['String'];
  team: Scalars['String'];
};

export type VehicleColoColorApplicability = {
  __typename?: 'VehicleColoColorApplicability';
  grade?: Maybe<Scalars['String']>;
  interiorColorId?: Maybe<Scalars['String']>;
};

export type VehicleColoColorApplicabilityInput = {
  grade?: InputMaybe<Scalars['String']>;
  interiorColorId?: InputMaybe<Scalars['String']>;
};

export type VehicleColoModelApplicability = {
  __typename?: 'VehicleColoModelApplicability';
  colorApplicability?: Maybe<Array<Maybe<VehicleColoModelApplicabilityColor>>>;
  id: Scalars['ID'];
};

export type VehicleColoModelApplicabilityColor = {
  __typename?: 'VehicleColoModelApplicabilityColor';
  id: Scalars['ID'];
  isApplicable: Scalars['Boolean'];
};

export type VehicleColoModelApplicabilityColorInput = {
  id: Scalars['ID'];
  isApplicable: Scalars['Boolean'];
};

export type VehicleColoModelApplicabilityInput = {
  colorApplicability?: InputMaybe<Array<InputMaybe<VehicleColoModelApplicabilityColorInput>>>;
  id: Scalars['ID'];
};

export type VehicleColoTrimApplicability = {
  __typename?: 'VehicleColoTrimApplicability';
  id: Scalars['ID'];
  isApplicable: Scalars['Boolean'];
};

export type VehicleColorExt = {
  __typename?: 'VehicleColorExt';
  changedAttributes?: Maybe<Array<Scalars['String']>>;
  code: Scalars['String'];
  colorApplicability: Array<VehicleColoColorApplicability>;
  fromTMNA?: Maybe<Scalars['Boolean']>;
  hexCode?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  isExtraCost?: Maybe<Scalars['String']>;
  isInProgress: Scalars['Boolean'];
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  rejectNotes?: Maybe<Scalars['String']>;
  revId: Scalars['String'];
  trimApplicability?: Maybe<Array<VehicleColoTrimApplicability>>;
};

export type VehicleColorInt = {
  __typename?: 'VehicleColorInt';
  changedAttributes?: Maybe<Array<Maybe<Scalars['String']>>>;
  code: Scalars['String'];
  fromTMNA?: Maybe<Scalars['Boolean']>;
  gradeApplicability?: Maybe<Array<Scalars['String']>>;
  id: Scalars['ID'];
  interiorType?: Maybe<Scalars['String']>;
  interiorTypeId?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isExtraCost?: Maybe<Scalars['String']>;
  modelApplicability: Array<VehicleColoModelApplicability>;
  name: Scalars['String'];
  rejectNotes?: Maybe<Scalars['String']>;
  revId: Scalars['String'];
  roofTopColor?: Maybe<Scalars['String']>;
};

export type VehicleColorsExtResponse = {
  __typename?: 'VehicleColorsExtResponse';
  vehicleColorsExt?: Maybe<Array<VehicleColorExt>>;
};

export type VehicleColorsIntResponse = {
  __typename?: 'VehicleColorsIntResponse';
  vehicleColorsInt?: Maybe<Array<VehicleColorInt>>;
};

export type VehicleCompareDoubleSortItem = {
  __typename?: 'VehicleCompareDoubleSortItem';
  id: Scalars['ID'];
  position: Scalars['Int'];
};

export type VehicleCompareDoubleSortList = {
  __typename?: 'VehicleCompareDoubleSortList';
  id: Scalars['ID'];
  sortList: Array<VehicleCompareDoubleSortItem>;
};

export type VehicleCompareFeature = {
  __typename?: 'VehicleCompareFeature';
  category?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['String']>;
  changedAttributes?: Maybe<Array<Maybe<Scalars['String']>>>;
  compareType?: Maybe<VehicleCompareType>;
  description: Scalars['String'];
  fromTMNA?: Maybe<Scalars['Boolean']>;
  gradeApplicability?: Maybe<Array<Maybe<VehicleCompareFeatureGradeApplicability>>>;
  id: Scalars['ID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  isInProgress: Scalars['Boolean'];
  notes?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['String']>;
  rejectNotes?: Maybe<Scalars['String']>;
  revId: Scalars['String'];
  subCategory?: Maybe<Scalars['String']>;
  subCategoryId?: Maybe<Scalars['String']>;
};

export type VehicleCompareFeatureGradeApplicability = {
  __typename?: 'VehicleCompareFeatureGradeApplicability';
  applicability: Scalars['String'];
  highlighted: Scalars['Boolean'];
  id: Scalars['ID'];
  text?: Maybe<Scalars['String']>;
};

export enum VehicleCompareType {
  Feature = 'feature',
  Spec = 'spec'
}

export type VehicleDocument = {
  __typename?: 'VehicleDocument';
  createdBy?: Maybe<Scalars['String']>;
  createdDate: Scalars['String'];
  hasSourceUpdates?: Maybe<Array<SourceUpdate>>;
  isPendingCLReview: Scalars['Boolean'];
  isPendingVDReview: Scalars['Boolean'];
  isSubmitted: Scalars['Boolean'];
  lastModifiedBy?: Maybe<Scalars['String']>;
  lastModifiedDate?: Maybe<Scalars['String']>;
  lastPublishedDate?: Maybe<Scalars['String']>;
  lastPublishedVersion?: Maybe<Scalars['Int']>;
  lastSyncDate?: Maybe<Scalars['String']>;
  modelYear: Scalars['Int'];
  seriesId: Scalars['String'];
  sourceLang?: Maybe<Scalars['String']>;
  sourceVersion?: Maybe<Scalars['Int']>;
  status: Scalars['String'];
  vdStatus?: Maybe<StatusItem>;
  version?: Maybe<Scalars['Int']>;
};

export type VehicleFeature = {
  __typename?: 'VehicleFeature';
  category?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['String']>;
  changedAttributes?: Maybe<Array<Scalars['String']>>;
  comLangId?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  fromTMNA?: Maybe<Scalars['Boolean']>;
  gradeApplicability?: Maybe<Array<GradeApplicability>>;
  id: Scalars['String'];
  isDeleted: Scalars['Boolean'];
  isHighlighted: Scalars['Boolean'];
  isInProgress: Scalars['Boolean'];
  link?: Maybe<Scalars['String']>;
  longDescription?: Maybe<Scalars['String']>;
  modelApplicability?: Maybe<Array<ModelApplicability>>;
  notes?: Maybe<Scalars['String']>;
  rejectNotes?: Maybe<Scalars['String']>;
  revId: Scalars['String'];
  shortDescription?: Maybe<Scalars['String']>;
  splits?: Maybe<Array<FeatureSplit>>;
  subCategory?: Maybe<Scalars['String']>;
  subCategoryId?: Maybe<Scalars['String']>;
  tooltip?: Maybe<Scalars['String']>;
};

export type VehicleFeatureCategoryInput = {
  language: Scalars['String'];
  name: Scalars['String'];
};

export type VehicleFeatureGradeApplicabilityInput = {
  applicability: Scalars['String'];
  highlighted: Scalars['Boolean'];
  id: Scalars['ID'];
  text?: InputMaybe<Scalars['String']>;
};

export type VehicleFeatureLanguageCategory = {
  __typename?: 'VehicleFeatureLanguageCategory';
  language: Scalars['String'];
  vehicleFeaturesCategories?: Maybe<Array<VehicleFeaturesCategory>>;
};

export type VehicleFeatureLanguageSubCategory = {
  __typename?: 'VehicleFeatureLanguageSubCategory';
  language: Scalars['String'];
  vehicleFeaturesSubCategories?: Maybe<Array<VehicleFeaturesSubCategory>>;
};

export type VehicleFeatureModelApplicabilityInput = {
  id: Scalars['ID'];
  text?: InputMaybe<Scalars['String']>;
};

export type VehicleFeatureSubCategoryInput = {
  language: Scalars['String'];
  name: Scalars['String'];
};

export type VehicleFeaturesCategoriesResponse = {
  __typename?: 'VehicleFeaturesCategoriesResponse';
  vehicleFeaturesCategories?: Maybe<Array<VehicleFeaturesCategory>>;
};

export type VehicleFeaturesCategory = {
  __typename?: 'VehicleFeaturesCategory';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type VehicleFeaturesCompareResponse = {
  __typename?: 'VehicleFeaturesCompareResponse';
  compareFeatures?: Maybe<Array<VehicleCompareFeature>>;
  doubleSortList?: Maybe<Array<VehicleCompareDoubleSortList>>;
};

export type VehicleFeaturesResponse = {
  __typename?: 'VehicleFeaturesResponse';
  vehicleFeatures?: Maybe<Array<VehicleFeature>>;
};

export type VehicleFeaturesSubCategoriesResponse = {
  __typename?: 'VehicleFeaturesSubCategoriesResponse';
  vehicleFeaturesSubCategories?: Maybe<Array<VehicleFeaturesSubCategory>>;
};

export type VehicleFeaturesSubCategory = {
  __typename?: 'VehicleFeaturesSubCategory';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type VehicleOption = {
  __typename?: 'VehicleOption';
  category?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['String']>;
  changedAttributes?: Maybe<Array<Maybe<Scalars['String']>>>;
  code: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  fromTMNA?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  isDeleted: Scalars['Boolean'];
  isExtraCost?: Maybe<Scalars['String']>;
  isInProgress: Scalars['Boolean'];
  link?: Maybe<Scalars['String']>;
  modelApplicability?: Maybe<Array<ModelApplicability>>;
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  rejectNotes?: Maybe<Scalars['String']>;
  revId: Scalars['String'];
};

export type VehicleOptionCategoryInput = {
  language: Scalars['String'];
  name: Scalars['String'];
};

export type VehicleOptionLanguageCategory = {
  __typename?: 'VehicleOptionLanguageCategory';
  language: Scalars['String'];
  vehicleOptionsCategories?: Maybe<Array<VehicleOptionsCategory>>;
};

export type VehicleOptionModelApplicabilityInput = {
  id: Scalars['ID'];
  text?: InputMaybe<Scalars['String']>;
};

export type VehicleOptionsCategoriesResponse = {
  __typename?: 'VehicleOptionsCategoriesResponse';
  vehicleOptionsCategories?: Maybe<Array<VehicleOptionsCategory>>;
};

export type VehicleOptionsCategory = {
  __typename?: 'VehicleOptionsCategory';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type VehicleOptionsResponse = {
  __typename?: 'VehicleOptionsResponse';
  vehicleOptions?: Maybe<Array<VehicleOption>>;
};

export type VehicleSeries = {
  __typename?: 'VehicleSeries';
  name: Scalars['String'];
  seriesId: Scalars['ID'];
  years?: Maybe<Array<Maybe<SeriesModelYear>>>;
};

export type VehicleSpec = {
  __typename?: 'VehicleSpec';
  category?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['String']>;
  changedAttributes?: Maybe<Array<Scalars['String']>>;
  comLangId?: Maybe<Scalars['String']>;
  compareFeatureId?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  fromTMNA?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  isDeleted: Scalars['Boolean'];
  isInProgress?: Maybe<Scalars['Boolean']>;
  isRequired?: Maybe<Scalars['Boolean']>;
  isTDPR?: Maybe<Scalars['Boolean']>;
  isUSVI?: Maybe<Scalars['Boolean']>;
  link?: Maybe<Scalars['String']>;
  modelApplicability?: Maybe<Array<ModelApplicability>>;
  notes?: Maybe<Scalars['String']>;
  rejectNotes?: Maybe<Scalars['String']>;
  revId: Scalars['String'];
  specType?: Maybe<Scalars['String']>;
  specTypeId?: Maybe<Scalars['String']>;
};

export type VehicleSpecCategory = {
  __typename?: 'VehicleSpecCategory';
  id: Scalars['ID'];
  isDeleted?: Maybe<Scalars['Boolean']>;
  isRequired?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
};

export type VehicleSpecCategoryInput = {
  language: Scalars['String'];
  name: Scalars['String'];
};

export type VehicleSpecLanguageCategory = {
  __typename?: 'VehicleSpecLanguageCategory';
  language: Scalars['String'];
  vehicleSpecsCategories?: Maybe<Array<VehicleSpecCategory>>;
};

export type VehicleSpecLanguageSpecType = {
  __typename?: 'VehicleSpecLanguageSpecType';
  language: Scalars['String'];
  vehicleSpecsSpecTypes?: Maybe<Array<VehicleSpecSpecType>>;
};

export type VehicleSpecModelApplicabilityInput = {
  id: Scalars['ID'];
  text?: InputMaybe<Scalars['String']>;
};

export type VehicleSpecSpecType = {
  __typename?: 'VehicleSpecSpecType';
  id: Scalars['ID'];
  isRequired?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
};

export type VehicleSpecSpecTypeInput = {
  language: Scalars['String'];
  name: Scalars['String'];
};

export type VehicleSpecsCategoriesResponse = {
  __typename?: 'VehicleSpecsCategoriesResponse';
  vehicleSpecsCategories?: Maybe<Array<VehicleSpecCategory>>;
};

export type VehicleSpecsResponse = {
  __typename?: 'VehicleSpecsResponse';
  vehicleSpecs?: Maybe<Array<VehicleSpec>>;
};

export type VehicleSpecsSpecTypesResponse = {
  __typename?: 'VehicleSpecsSpecTypesResponse';
  vehicleSpecsSpecTypes?: Maybe<Array<VehicleSpecSpecType>>;
};

export type VehicleSyncInput = {
  modelYear: Scalars['Int'];
  seriesId: Scalars['String'];
  team: Scalars['String'];
};

export type VehicleVersion = {
  __typename?: 'VehicleVersion';
  isSubmitted?: Maybe<Scalars['Boolean']>;
  lastResetDate?: Maybe<Scalars['String']>;
  modelYear: Scalars['Int'];
  publishedBy?: Maybe<Scalars['String']>;
  publishedDate?: Maybe<Scalars['String']>;
  seriesId: Scalars['String'];
  version: Scalars['String'];
};

export type AddCategoriesFeatureMutationVariables = Exact<{
  team: Scalars['String'];
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  categories: Array<VehicleFeatureCategoryInput> | VehicleFeatureCategoryInput;
  id?: InputMaybe<Scalars['String']>;
}>;


export type AddCategoriesFeatureMutation = { __typename?: 'Mutation', createVehicleFeatureCategory?: { __typename?: 'CreateVehicleFeatureCategoryResponse', vehicleFeaturesLanguageCategories?: Array<{ __typename?: 'VehicleFeatureLanguageCategory', language: string, vehicleFeaturesCategories?: Array<{ __typename?: 'VehicleFeaturesCategory', id: string, name: string }> | null }> | null } | null };

export type AddCategoriesOptionMutationVariables = Exact<{
  team: Scalars['String'];
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  categories: Array<VehicleOptionCategoryInput> | VehicleOptionCategoryInput;
  id?: InputMaybe<Scalars['String']>;
}>;


export type AddCategoriesOptionMutation = { __typename?: 'Mutation', createVehicleOptionCategory?: { __typename?: 'CreateVehicleOptionCategoryResponse', vehicleOptionsLanguageCategories?: Array<{ __typename?: 'VehicleOptionLanguageCategory', language: string, vehicleOptionsCategories?: Array<{ __typename?: 'VehicleOptionsCategory', id: string, name: string }> | null }> | null } | null };

export type AddCategoriesSpecMutationVariables = Exact<{
  team: Scalars['String'];
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  categories: Array<VehicleSpecCategoryInput> | VehicleSpecCategoryInput;
  id?: InputMaybe<Scalars['String']>;
}>;


export type AddCategoriesSpecMutation = { __typename?: 'Mutation', createVehicleSpecCategory?: { __typename?: 'CreateVehicleSpecCategoryResponse', vehicleSpecsLanguageCategories?: Array<{ __typename?: 'VehicleSpecLanguageCategory', language: string, vehicleSpecsCategories?: Array<{ __typename?: 'VehicleSpecCategory', id: string, name: string, isRequired?: boolean | null, isDeleted?: boolean | null }> | null }> | null } | null };

export type VehicleFeaturesCategoryFieldsFragment = { __typename?: 'VehicleFeaturesCategory', id: string, name: string };

export type VehicleLanguageFeatureCategoryFieldsFragment = { __typename?: 'VehicleFeatureLanguageCategory', language: string, vehicleFeaturesCategories?: Array<{ __typename?: 'VehicleFeaturesCategory', id: string, name: string }> | null };

export type VehicleSpecCategoryFieldsFragment = { __typename?: 'VehicleSpecCategory', id: string, name: string, isRequired?: boolean | null, isDeleted?: boolean | null };

export type VehicleSpecLanguageCategoryFieldsFragment = { __typename?: 'VehicleSpecLanguageCategory', language: string, vehicleSpecsCategories?: Array<{ __typename?: 'VehicleSpecCategory', id: string, name: string, isRequired?: boolean | null, isDeleted?: boolean | null }> | null };

export type VehicleOptionsCategoryFieldsFragment = { __typename?: 'VehicleOptionsCategory', id: string, name: string };

export type VehicleOptionLanguageCategoryFieldsFragment = { __typename?: 'VehicleOptionLanguageCategory', language: string, vehicleOptionsCategories?: Array<{ __typename?: 'VehicleOptionsCategory', id: string, name: string }> | null };

export type GetCategoriesByLangFeatureQueryVariables = Exact<{
  team: Scalars['String'];
  seriesId: Scalars['ID'];
  modelYear: Scalars['Int'];
  language: Scalars['String'];
  status?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['Int']>;
  includeAll?: InputMaybe<Scalars['Boolean']>;
}>;


export type GetCategoriesByLangFeatureQuery = { __typename?: 'Query', vehicleFeaturesCategories?: { __typename?: 'VehicleFeaturesCategoriesResponse', vehicleFeaturesCategories?: Array<{ __typename?: 'VehicleFeaturesCategory', id: string, name: string }> | null } | null };

export type UpdateCategoryFeatureMutationVariables = Exact<{
  team: Scalars['String'];
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  language: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
}>;


export type UpdateCategoryFeatureMutation = { __typename?: 'Mutation', updateVehicleFeatureCategory?: { __typename?: 'UpdateVehicleFeatureCategoryResponse', id: string, name: string } | null };

export type UpdateCategoryOptionMutationVariables = Exact<{
  team: Scalars['String'];
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  language: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
}>;


export type UpdateCategoryOptionMutation = { __typename?: 'Mutation', updateVehicleOptionCategory?: { __typename?: 'UpdateVehicleOptionCategoryResponse', id: string, name: string } | null };

export type UpdateCategorySpecMutationVariables = Exact<{
  team: Scalars['String'];
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  language: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
}>;


export type UpdateCategorySpecMutation = { __typename?: 'Mutation', updateVehicleSpecCategory?: { __typename?: 'VehicleSpecCategory', id: string, name: string, isRequired?: boolean | null, isDeleted?: boolean | null } | null };

export type AddExteriorColorMutationVariables = Exact<{
  team: Scalars['String'];
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  language: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  code: Scalars['String'];
  hexCode?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  isExtraCost: Scalars['String'];
  isInProgress: Scalars['Boolean'];
  colorApplicability?: InputMaybe<Array<VehicleColoColorApplicabilityInput> | VehicleColoColorApplicabilityInput>;
}>;


export type AddExteriorColorMutation = { __typename?: 'Mutation', createVehicleColorExt?: { __typename?: 'VehicleColorExt', id: string, revId: string, code: string, hexCode?: string | null, name: string, isExtraCost?: string | null, isDeleted?: boolean | null, isInProgress: boolean, notes?: string | null, rejectNotes?: string | null, changedAttributes?: Array<string> | null, fromTMNA?: boolean | null, colorApplicability: Array<{ __typename?: 'VehicleColoColorApplicability', interiorColorId?: string | null, grade?: string | null }>, trimApplicability?: Array<{ __typename?: 'VehicleColoTrimApplicability', id: string, isApplicable: boolean }> | null } | null };

export type AddInteriorColorMutationVariables = Exact<{
  team: Scalars['String'];
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  language: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  code: Scalars['String'];
  isExtraCost: Scalars['String'];
  gradeApplicability?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  modelApplicability?: InputMaybe<Array<VehicleColoModelApplicabilityInput> | VehicleColoModelApplicabilityInput>;
}>;


export type AddInteriorColorMutation = { __typename?: 'Mutation', createVehicleColorInt?: { __typename?: 'VehicleColorInt', id: string, revId: string, name: string, code: string, gradeApplicability?: Array<string> | null, isExtraCost?: string | null, isDeleted?: boolean | null, rejectNotes?: string | null, interiorType?: string | null, interiorTypeId?: string | null, roofTopColor?: string | null, changedAttributes?: Array<string | null> | null, fromTMNA?: boolean | null, modelApplicability: Array<{ __typename?: 'VehicleColoModelApplicability', id: string, colorApplicability?: Array<{ __typename?: 'VehicleColoModelApplicabilityColor', id: string, isApplicable: boolean } | null> | null }> } | null };

export type DeleteExteriorColorMutationVariables = Exact<{
  team: Scalars['String'];
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  language: Scalars['String'];
  id: Scalars['ID'];
}>;


export type DeleteExteriorColorMutation = { __typename?: 'Mutation', deleteVehicleColorExt?: { __typename?: 'DeleteVehicleColorResponse', id: string, message?: string | null } | null };

export type DeleteInteriorColorMutationVariables = Exact<{
  team: Scalars['String'];
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  language: Scalars['String'];
  id: Scalars['ID'];
}>;


export type DeleteInteriorColorMutation = { __typename?: 'Mutation', deleteVehicleColorInt?: { __typename?: 'DeleteVehicleColorResponse', id: string, message?: string | null } | null };

export type VehicleColoColorApplicabilityFieldsFragment = { __typename?: 'VehicleColoColorApplicability', interiorColorId?: string | null, grade?: string | null };

export type VehicleColoTrimApplicabilityFieldsFragment = { __typename?: 'VehicleColoTrimApplicability', id: string, isApplicable: boolean };

export type VehicleColorExtFieldsFragment = { __typename?: 'VehicleColorExt', id: string, revId: string, code: string, hexCode?: string | null, name: string, isExtraCost?: string | null, isDeleted?: boolean | null, isInProgress: boolean, notes?: string | null, rejectNotes?: string | null, changedAttributes?: Array<string> | null, fromTMNA?: boolean | null, colorApplicability: Array<{ __typename?: 'VehicleColoColorApplicability', interiorColorId?: string | null, grade?: string | null }>, trimApplicability?: Array<{ __typename?: 'VehicleColoTrimApplicability', id: string, isApplicable: boolean }> | null };

export type VehicleColoModelApplicabilityColorFieldsFragment = { __typename?: 'VehicleColoModelApplicabilityColor', id: string, isApplicable: boolean };

export type VehicleColoModelApplicabilityFieldsFragment = { __typename?: 'VehicleColoModelApplicability', id: string, colorApplicability?: Array<{ __typename?: 'VehicleColoModelApplicabilityColor', id: string, isApplicable: boolean } | null> | null };

export type VehicleColorIntFieldsFragment = { __typename?: 'VehicleColorInt', id: string, revId: string, name: string, code: string, gradeApplicability?: Array<string> | null, isExtraCost?: string | null, isDeleted?: boolean | null, rejectNotes?: string | null, interiorType?: string | null, interiorTypeId?: string | null, roofTopColor?: string | null, changedAttributes?: Array<string | null> | null, fromTMNA?: boolean | null, modelApplicability: Array<{ __typename?: 'VehicleColoModelApplicability', id: string, colorApplicability?: Array<{ __typename?: 'VehicleColoModelApplicabilityColor', id: string, isApplicable: boolean } | null> | null }> };

export type GetChangeLogColorsQueryVariables = Exact<{
  team: Scalars['String'];
  seriesId: Scalars['ID'];
  modelYear: Scalars['Int'];
  language: Scalars['String'];
  status?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['Int']>;
}>;


export type GetChangeLogColorsQuery = { __typename?: 'Query', vehicleColorsChanges?: { __typename?: 'BodyJSON', body: any } | null };

export type GetExteriorColorsQueryVariables = Exact<{
  team: Scalars['String'];
  seriesId: Scalars['ID'];
  modelYear: Scalars['Int'];
  language: Scalars['String'];
  status?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['Int']>;
}>;


export type GetExteriorColorsQuery = { __typename?: 'Query', vehicleColorsExt?: { __typename?: 'VehicleColorsExtResponse', vehicleColorsExt?: Array<{ __typename?: 'VehicleColorExt', id: string, revId: string, code: string, hexCode?: string | null, name: string, isExtraCost?: string | null, isDeleted?: boolean | null, isInProgress: boolean, notes?: string | null, rejectNotes?: string | null, changedAttributes?: Array<string> | null, fromTMNA?: boolean | null, colorApplicability: Array<{ __typename?: 'VehicleColoColorApplicability', interiorColorId?: string | null, grade?: string | null }>, trimApplicability?: Array<{ __typename?: 'VehicleColoTrimApplicability', id: string, isApplicable: boolean }> | null }> | null } | null };

export type GetInteriorColorsQueryVariables = Exact<{
  team: Scalars['String'];
  seriesId: Scalars['ID'];
  modelYear: Scalars['Int'];
  language: Scalars['String'];
  status?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['Int']>;
  includeAll?: InputMaybe<Scalars['Boolean']>;
}>;


export type GetInteriorColorsQuery = { __typename?: 'Query', vehicleColorsInt?: { __typename?: 'VehicleColorsIntResponse', vehicleColorsInt?: Array<{ __typename?: 'VehicleColorInt', id: string, revId: string, name: string, code: string, gradeApplicability?: Array<string> | null, isExtraCost?: string | null, isDeleted?: boolean | null, rejectNotes?: string | null, interiorType?: string | null, interiorTypeId?: string | null, roofTopColor?: string | null, changedAttributes?: Array<string | null> | null, fromTMNA?: boolean | null, modelApplicability: Array<{ __typename?: 'VehicleColoModelApplicability', id: string, colorApplicability?: Array<{ __typename?: 'VehicleColoModelApplicabilityColor', id: string, isApplicable: boolean } | null> | null }> }> | null } | null };

export type RevertChangeLogColorsMutationVariables = Exact<{
  brand: Scalars['String'];
  team: Scalars['String'];
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  language: Scalars['String'];
  body: Scalars['JSON'];
}>;


export type RevertChangeLogColorsMutation = { __typename?: 'Mutation', revertVehicleColorsChanges: { __typename?: 'BodyJSON', body: any } };

export type UpdateExteriorColorMutationVariables = Exact<{
  team: Scalars['String'];
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  language: Scalars['String'];
  id: Scalars['ID'];
  revId: Scalars['String'];
  name: Scalars['String'];
  code: Scalars['String'];
  hexCode?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  isExtraCost: Scalars['String'];
  isInProgress: Scalars['Boolean'];
  colorApplicability?: InputMaybe<Array<VehicleColoColorApplicabilityInput> | VehicleColoColorApplicabilityInput>;
  acceptChanges?: InputMaybe<Scalars['Boolean']>;
  unlinkFromTMNA?: InputMaybe<Scalars['Boolean']>;
}>;


export type UpdateExteriorColorMutation = { __typename?: 'Mutation', updateVehicleColorExt?: { __typename?: 'VehicleColorExt', id: string, revId: string, code: string, hexCode?: string | null, name: string, isExtraCost?: string | null, isDeleted?: boolean | null, isInProgress: boolean, notes?: string | null, rejectNotes?: string | null, changedAttributes?: Array<string> | null, fromTMNA?: boolean | null, colorApplicability: Array<{ __typename?: 'VehicleColoColorApplicability', interiorColorId?: string | null, grade?: string | null }>, trimApplicability?: Array<{ __typename?: 'VehicleColoTrimApplicability', id: string, isApplicable: boolean }> | null } | null };

export type UpdateInteriorColorMutationVariables = Exact<{
  team: Scalars['String'];
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  language: Scalars['String'];
  id: Scalars['ID'];
  revId: Scalars['String'];
  name: Scalars['String'];
  code: Scalars['String'];
  isExtraCost: Scalars['String'];
  gradeApplicability?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  modelApplicability?: InputMaybe<Array<VehicleColoModelApplicabilityInput> | VehicleColoModelApplicabilityInput>;
  acceptChanges?: InputMaybe<Scalars['Boolean']>;
  unlinkFromTMNA?: InputMaybe<Scalars['Boolean']>;
}>;


export type UpdateInteriorColorMutation = { __typename?: 'Mutation', updateVehicleColorInt?: { __typename?: 'VehicleColorInt', id: string, revId: string, name: string, code: string, gradeApplicability?: Array<string> | null, isExtraCost?: string | null, isDeleted?: boolean | null, rejectNotes?: string | null, interiorType?: string | null, interiorTypeId?: string | null, roofTopColor?: string | null, changedAttributes?: Array<string | null> | null, fromTMNA?: boolean | null, modelApplicability: Array<{ __typename?: 'VehicleColoModelApplicability', id: string, colorApplicability?: Array<{ __typename?: 'VehicleColoModelApplicabilityColor', id: string, isApplicable: boolean } | null> | null }> } | null };

export type AddCompareFeatureMutationVariables = Exact<{
  team: Scalars['String'];
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  language: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  parentId?: InputMaybe<Scalars['String']>;
  compareType?: InputMaybe<VehicleCompareType>;
  categoryId: Scalars['String'];
  subCategoryId?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  notes?: InputMaybe<Scalars['String']>;
  isInProgress?: InputMaybe<Scalars['Boolean']>;
  gradeApplicability?: InputMaybe<Array<VehicleFeatureGradeApplicabilityInput> | VehicleFeatureGradeApplicabilityInput>;
}>;


export type AddCompareFeatureMutation = { __typename?: 'Mutation', createVehicleFeatureCompare?: { __typename?: 'VehicleCompareFeature', id: string, revId: string, parentId?: string | null, compareType?: VehicleCompareType | null, categoryId?: string | null, category?: string | null, subCategoryId?: string | null, subCategory?: string | null, description: string, notes?: string | null, rejectNotes?: string | null, isInProgress: boolean, isDeleted?: boolean | null, changedAttributes?: Array<string | null> | null, fromTMNA?: boolean | null, gradeApplicability?: Array<{ __typename?: 'VehicleCompareFeatureGradeApplicability', id: string, highlighted: boolean, text?: string | null, applicability: string } | null> | null } | null };

export type DeleteCompareFeatureMutationVariables = Exact<{
  team: Scalars['String'];
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  language: Scalars['String'];
  id: Scalars['ID'];
}>;


export type DeleteCompareFeatureMutation = { __typename?: 'Mutation', deleteVehicleFeatureCompare?: { __typename?: 'DeleteVehicleFeatureCompareResponse', id: string, message?: string | null } | null };

export type VehicleCompareFeatureGradeApplicabilityFieldsFragment = { __typename?: 'VehicleCompareFeatureGradeApplicability', id: string, highlighted: boolean, text?: string | null, applicability: string };

export type VehicleCompareFeatureFieldsFragment = { __typename?: 'VehicleCompareFeature', id: string, revId: string, parentId?: string | null, compareType?: VehicleCompareType | null, categoryId?: string | null, category?: string | null, subCategoryId?: string | null, subCategory?: string | null, description: string, notes?: string | null, rejectNotes?: string | null, isInProgress: boolean, isDeleted?: boolean | null, changedAttributes?: Array<string | null> | null, fromTMNA?: boolean | null, gradeApplicability?: Array<{ __typename?: 'VehicleCompareFeatureGradeApplicability', id: string, highlighted: boolean, text?: string | null, applicability: string } | null> | null };

export type VehicleCompareDoubleSortItemFieldsFragment = { __typename?: 'VehicleCompareDoubleSortItem', id: string, position: number };

export type VehicleCompareDoubleSortListFieldsFragment = { __typename?: 'VehicleCompareDoubleSortList', id: string, sortList: Array<{ __typename?: 'VehicleCompareDoubleSortItem', id: string, position: number }> };

export type GetChangeLogCompareFeaturesQueryVariables = Exact<{
  team: Scalars['String'];
  seriesId: Scalars['ID'];
  modelYear: Scalars['Int'];
  language: Scalars['String'];
  status?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['Int']>;
}>;


export type GetChangeLogCompareFeaturesQuery = { __typename?: 'Query', vehicleFeaturesCompareChanges?: { __typename?: 'BodyJSON', body: any } | null };

export type GetCompareFeaturesQueryVariables = Exact<{
  team: Scalars['String'];
  seriesId: Scalars['ID'];
  modelYear: Scalars['Int'];
  language: Scalars['String'];
  status?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['Int']>;
}>;


export type GetCompareFeaturesQuery = { __typename?: 'Query', vehicleFeaturesCompare?: { __typename?: 'VehicleFeaturesCompareResponse', compareFeatures?: Array<{ __typename?: 'VehicleCompareFeature', id: string, revId: string, parentId?: string | null, compareType?: VehicleCompareType | null, categoryId?: string | null, category?: string | null, subCategoryId?: string | null, subCategory?: string | null, description: string, notes?: string | null, rejectNotes?: string | null, isInProgress: boolean, isDeleted?: boolean | null, changedAttributes?: Array<string | null> | null, fromTMNA?: boolean | null, gradeApplicability?: Array<{ __typename?: 'VehicleCompareFeatureGradeApplicability', id: string, highlighted: boolean, text?: string | null, applicability: string } | null> | null }> | null, doubleSortList?: Array<{ __typename?: 'VehicleCompareDoubleSortList', id: string, sortList: Array<{ __typename?: 'VehicleCompareDoubleSortItem', id: string, position: number }> }> | null } | null };

export type RevertChangeLogCompareFeaturesMutationVariables = Exact<{
  brand: Scalars['String'];
  team: Scalars['String'];
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  language: Scalars['String'];
  body: Scalars['JSON'];
}>;


export type RevertChangeLogCompareFeaturesMutation = { __typename?: 'Mutation', revertVehicleFeaturesCompareChanges: { __typename?: 'BodyJSON', body: any } };

export type UpdateCompareFeatureMutationVariables = Exact<{
  team: Scalars['String'];
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  language: Scalars['String'];
  id: Scalars['String'];
  revId: Scalars['String'];
  parentId?: InputMaybe<Scalars['String']>;
  compareType?: InputMaybe<VehicleCompareType>;
  categoryId: Scalars['String'];
  subCategoryId?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  notes?: InputMaybe<Scalars['String']>;
  isInProgress?: InputMaybe<Scalars['Boolean']>;
  gradeApplicability?: InputMaybe<Array<VehicleFeatureGradeApplicabilityInput> | VehicleFeatureGradeApplicabilityInput>;
  acceptChanges?: InputMaybe<Scalars['Boolean']>;
  unlinkFromTMNA?: InputMaybe<Scalars['Boolean']>;
}>;


export type UpdateCompareFeatureMutation = { __typename?: 'Mutation', updateVehicleFeatureCompare?: { __typename?: 'VehicleCompareFeature', id: string, revId: string, parentId?: string | null, compareType?: VehicleCompareType | null, categoryId?: string | null, category?: string | null, subCategoryId?: string | null, subCategory?: string | null, description: string, notes?: string | null, rejectNotes?: string | null, isInProgress: boolean, isDeleted?: boolean | null, changedAttributes?: Array<string | null> | null, fromTMNA?: boolean | null, gradeApplicability?: Array<{ __typename?: 'VehicleCompareFeatureGradeApplicability', id: string, highlighted: boolean, text?: string | null, applicability: string } | null> | null } | null };

export type UpdateDoubleSortListMutationVariables = Exact<{
  team: Scalars['String'];
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  language: Scalars['String'];
  doubleSortList: Array<UpdateVehicleFeatureHighlightSortListDoubleSortItemInput> | UpdateVehicleFeatureHighlightSortListDoubleSortItemInput;
}>;


export type UpdateDoubleSortListMutation = { __typename?: 'Mutation', updateVehicleFeatureHighlightSortList?: { __typename?: 'UpdateVehicleFeatureHighlightSortListResponse', doubleSortList?: Array<{ __typename?: 'VehicleCompareDoubleSortList', id: string, sortList: Array<{ __typename?: 'VehicleCompareDoubleSortItem', id: string, position: number }> }> | null } | null };

export type DeleteDraftMutationVariables = Exact<{
  brand: Scalars['String'];
  team: Scalars['String'];
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  language: Scalars['String'];
}>;


export type DeleteDraftMutation = { __typename?: 'Mutation', deleteVehicleDocument?: { __typename?: 'DeleteVehicleDocumentResponse', message?: string | null } | null };

export type DuplicateVehicleDataMutationVariables = Exact<{
  brand: Scalars['String'];
  team: Scalars['String'];
  seriesId: Scalars['String'];
  fromModelYear: Scalars['Int'];
  toModelYear: Scalars['Int'];
  language: Scalars['String'];
}>;


export type DuplicateVehicleDataMutation = { __typename?: 'Mutation', duplicateVehicleDocument?: { __typename?: 'VehicleDocument', status: string, lastPublishedVersion?: number | null, lastPublishedDate?: string | null, lastModifiedDate?: string | null, lastModifiedBy?: string | null, version?: number | null, createdDate: string, seriesId: string, modelYear: number, isPendingVDReview: boolean, isPendingCLReview: boolean, isSubmitted: boolean, sourceVersion?: number | null, sourceLang?: string | null, lastSyncDate?: string | null, vdStatus?: { __typename?: 'StatusItem', currentStatus?: CurrentStatus | null, modifiedDate?: string | null, modifiedBy?: string | null } | null, hasSourceUpdates?: Array<{ __typename?: 'SourceUpdate', lang: string, hasUpdates: boolean }> | null } | null };

export type EditVehicleDataMutationVariables = Exact<{
  brand: Scalars['String'];
  team: Scalars['String'];
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  language: Scalars['String'];
}>;


export type EditVehicleDataMutation = { __typename?: 'Mutation', updateVehicleDocument?: { __typename?: 'VehicleDocument', status: string, lastPublishedVersion?: number | null, lastPublishedDate?: string | null, lastModifiedDate?: string | null, lastModifiedBy?: string | null, version?: number | null, createdDate: string, seriesId: string, modelYear: number, isPendingVDReview: boolean, isPendingCLReview: boolean, isSubmitted: boolean, sourceVersion?: number | null, sourceLang?: string | null, lastSyncDate?: string | null, vdStatus?: { __typename?: 'StatusItem', currentStatus?: CurrentStatus | null, modifiedDate?: string | null, modifiedBy?: string | null } | null, hasSourceUpdates?: Array<{ __typename?: 'SourceUpdate', lang: string, hasUpdates: boolean }> | null } | null };

export type StatusItemFieldsFragment = { __typename?: 'StatusItem', currentStatus?: CurrentStatus | null, modifiedDate?: string | null, modifiedBy?: string | null };

export type SourceUpdateFieldsFragment = { __typename?: 'SourceUpdate', lang: string, hasUpdates: boolean };

export type VersionFieldsSpanishFragment = { __typename?: 'SeriesModelYearVersionSpanish', year: number, isDraft: boolean, publishDownstreamVersion?: number | null, publishDownstreamDate?: string | null, previewDownstreamDate?: string | null, previewDownstreamVersion?: number | null, skippedLivePublish?: boolean | null, englishVersion: number, englishPublishedDate: string, spanishVersion?: number | null, spanishPublishedDate?: string | null, key: string, sourceVersion?: number | null, sourceLang?: string | null, outOfSync?: boolean | null, vdStatus?: { __typename?: 'StatusItem', currentStatus?: CurrentStatus | null, modifiedDate?: string | null, modifiedBy?: string | null } | null, hasSourceUpdates?: Array<{ __typename?: 'SourceUpdate', lang: string, hasUpdates: boolean }> | null };

export type VersionFieldsFragment = { __typename?: 'SeriesModelYearVersion', year: number, isDraft: boolean, publishDownstreamVersion?: number | null, publishDownstreamDate?: string | null, previewDownstreamDate?: string | null, previewDownstreamVersion?: number | null, skippedLivePublish?: boolean | null, datePublished: string, createdDate?: string | null, version?: number | null, isPendingVDReview?: boolean | null, isPendingCLReview?: boolean | null, isSubmitted?: boolean | null, vdStatus?: { __typename?: 'StatusItem', currentStatus?: CurrentStatus | null, modifiedDate?: string | null, modifiedBy?: string | null } | null };

export type DashboardFieldsFragment = { __typename?: 'VehicleSeries', seriesId: string, name: string, years?: Array<{ __typename?: 'SeriesModelYear', year: number, versions?: Array<{ __typename?: 'SeriesModelYearVersion', year: number, isDraft: boolean, publishDownstreamVersion?: number | null, publishDownstreamDate?: string | null, previewDownstreamDate?: string | null, previewDownstreamVersion?: number | null, skippedLivePublish?: boolean | null, datePublished: string, createdDate?: string | null, version?: number | null, isPendingVDReview?: boolean | null, isPendingCLReview?: boolean | null, isSubmitted?: boolean | null, vdStatus?: { __typename?: 'StatusItem', currentStatus?: CurrentStatus | null, modifiedDate?: string | null, modifiedBy?: string | null } | null } | { __typename?: 'SeriesModelYearVersionSpanish', year: number, isDraft: boolean, publishDownstreamVersion?: number | null, publishDownstreamDate?: string | null, previewDownstreamDate?: string | null, previewDownstreamVersion?: number | null, skippedLivePublish?: boolean | null, englishVersion: number, englishPublishedDate: string, spanishVersion?: number | null, spanishPublishedDate?: string | null, key: string, sourceVersion?: number | null, sourceLang?: string | null, outOfSync?: boolean | null, vdStatus?: { __typename?: 'StatusItem', currentStatus?: CurrentStatus | null, modifiedDate?: string | null, modifiedBy?: string | null } | null, hasSourceUpdates?: Array<{ __typename?: 'SourceUpdate', lang: string, hasUpdates: boolean }> | null }> | null } | null> | null };

export type VehicleDocumentFieldsFragment = { __typename?: 'VehicleDocument', status: string, lastPublishedVersion?: number | null, lastPublishedDate?: string | null, lastModifiedDate?: string | null, lastModifiedBy?: string | null, version?: number | null, createdDate: string, seriesId: string, modelYear: number, isPendingVDReview: boolean, isPendingCLReview: boolean, isSubmitted: boolean, sourceVersion?: number | null, sourceLang?: string | null, lastSyncDate?: string | null, vdStatus?: { __typename?: 'StatusItem', currentStatus?: CurrentStatus | null, modifiedDate?: string | null, modifiedBy?: string | null } | null, hasSourceUpdates?: Array<{ __typename?: 'SourceUpdate', lang: string, hasUpdates: boolean }> | null };

export type GetDashboardQueryVariables = Exact<{
  brand: Scalars['String'];
  team: Scalars['String'];
  lang: Scalars['String'];
}>;


export type GetDashboardQuery = { __typename?: 'Query', dashboard?: { __typename?: 'DashboardResponse', series?: Array<{ __typename?: 'VehicleSeries', seriesId: string, name: string, years?: Array<{ __typename?: 'SeriesModelYear', year: number, versions?: Array<{ __typename?: 'SeriesModelYearVersion', year: number, isDraft: boolean, publishDownstreamVersion?: number | null, publishDownstreamDate?: string | null, previewDownstreamDate?: string | null, previewDownstreamVersion?: number | null, skippedLivePublish?: boolean | null, datePublished: string, createdDate?: string | null, version?: number | null, isPendingVDReview?: boolean | null, isPendingCLReview?: boolean | null, isSubmitted?: boolean | null, vdStatus?: { __typename?: 'StatusItem', currentStatus?: CurrentStatus | null, modifiedDate?: string | null, modifiedBy?: string | null } | null } | { __typename?: 'SeriesModelYearVersionSpanish', year: number, isDraft: boolean, publishDownstreamVersion?: number | null, publishDownstreamDate?: string | null, previewDownstreamDate?: string | null, previewDownstreamVersion?: number | null, skippedLivePublish?: boolean | null, englishVersion: number, englishPublishedDate: string, spanishVersion?: number | null, spanishPublishedDate?: string | null, key: string, sourceVersion?: number | null, sourceLang?: string | null, outOfSync?: boolean | null, vdStatus?: { __typename?: 'StatusItem', currentStatus?: CurrentStatus | null, modifiedDate?: string | null, modifiedBy?: string | null } | null, hasSourceUpdates?: Array<{ __typename?: 'SourceUpdate', lang: string, hasUpdates: boolean }> | null }> | null } | null> | null }> | null } | null };

export type GetVehicleDataQueryVariables = Exact<{
  team: Scalars['String'];
  seriesId: Scalars['ID'];
  modelYear: Scalars['Int'];
  language: Scalars['String'];
  status?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['Int']>;
}>;


export type GetVehicleDataQuery = { __typename?: 'Query', vehicleDocument?: { __typename?: 'VehicleDocument', status: string, lastPublishedVersion?: number | null, lastPublishedDate?: string | null, lastModifiedDate?: string | null, lastModifiedBy?: string | null, version?: number | null, createdDate: string, seriesId: string, modelYear: number, isPendingVDReview: boolean, isPendingCLReview: boolean, isSubmitted: boolean, sourceVersion?: number | null, sourceLang?: string | null, lastSyncDate?: string | null, vdStatus?: { __typename?: 'StatusItem', currentStatus?: CurrentStatus | null, modifiedDate?: string | null, modifiedBy?: string | null } | null, hasSourceUpdates?: Array<{ __typename?: 'SourceUpdate', lang: string, hasUpdates: boolean }> | null } | null };

export type PublishDraftMutationVariables = Exact<{
  brand: Scalars['String'];
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  language: Scalars['String'];
  publishType: Scalars['String'];
  republish: Scalars['Boolean'];
}>;


export type PublishDraftMutation = { __typename?: 'Mutation', publishVehicleDocument?: string | null };

export type ResetDraftMutationVariables = Exact<{
  team: Scalars['String'];
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  language: Scalars['String'];
}>;


export type ResetDraftMutation = { __typename?: 'Mutation', resetVehicleDocumentDraft?: string | null };

export type ResetDraftCountQueryVariables = Exact<{
  team: Scalars['String'];
  seriesId: Scalars['ID'];
  modelYear: Scalars['Int'];
}>;


export type ResetDraftCountQuery = { __typename?: 'Query', vehicleDocumentResetDraftCount: number };

export type SubmitDraftMutationVariables = Exact<{
  brand: Scalars['String'];
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  language: Scalars['String'];
}>;


export type SubmitDraftMutation = { __typename?: 'Mutation', certifyVehicleDocument?: string | null };

export type SyncUpdatesMutationVariables = Exact<{
  team: Scalars['String'];
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
}>;


export type SyncUpdatesMutation = { __typename?: 'Mutation', vehicleSync?: { __typename?: 'BodyJSON', body: any } | null };

export type VehicleCompareQueryVariables = Exact<{
  team: Scalars['String'];
  seriesId: Scalars['ID'];
  modelYear: Scalars['Int'];
  entityType: Scalars['String'];
  itemId: Scalars['String'];
  parentSeriesId?: InputMaybe<Scalars['String']>;
}>;


export type VehicleCompareQuery = { __typename?: 'Query', vehicleCompare?: { __typename?: 'BodyJSON', body: any } | null };

export type VehicleVersionsQueryVariables = Exact<{
  team: Scalars['String'];
  seriesId: Scalars['ID'];
  modelYear: Scalars['Int'];
  language: Scalars['String'];
}>;


export type VehicleVersionsQuery = { __typename?: 'Query', vehicleVersions?: Array<{ __typename?: 'VehicleVersion', seriesId: string, modelYear: number, version: string, publishedBy?: string | null, publishedDate?: string | null, lastResetDate?: string | null, isSubmitted?: boolean | null }> | null };

export type GetDisclaimerTokensQueryVariables = Exact<{
  brand: Scalars['String'];
}>;


export type GetDisclaimerTokensQuery = { __typename?: 'Query', disclaimersTokens: Array<string | null> };

export type AddFeatureMutationVariables = Exact<{
  team: Scalars['String'];
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  language: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  categoryId: Scalars['String'];
  subCategoryId: Scalars['String'];
  comLangId?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  shortDescription?: InputMaybe<Scalars['String']>;
  link?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  isInProgress?: InputMaybe<Scalars['Boolean']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']>;
  modelApplicability?: InputMaybe<Array<VehicleFeatureModelApplicabilityInput> | VehicleFeatureModelApplicabilityInput>;
}>;


export type AddFeatureMutation = { __typename?: 'Mutation', createVehicleFeature?: { __typename?: 'VehicleFeature', id: string, revId: string, comLangId?: string | null, categoryId?: string | null, category?: string | null, subCategoryId?: string | null, subCategory?: string | null, description: string, shortDescription?: string | null, notes?: string | null, rejectNotes?: string | null, link?: string | null, isInProgress: boolean, isHighlighted: boolean, isDeleted: boolean, changedAttributes?: Array<string> | null, fromTMNA?: boolean | null, longDescription?: string | null, tooltip?: string | null, gradeApplicability?: Array<{ __typename?: 'GradeApplicability', id: string, highlighted: boolean, applicabilityText?: Array<{ __typename?: 'GradeApplicabilityText', id: string, text: string, availability: string }> | null }> | null, modelApplicability?: Array<{ __typename?: 'ModelApplicability', id: string, text?: string | null }> | null, splits?: Array<{ __typename?: 'FeatureSplit', id: string, splitNumber: number, description: string, shortDescription?: string | null, longDescription?: string | null, notes?: string | null, isHighlighted: boolean, isDeleted: boolean }> | null } | null };

export type DeleteFeatureMutationVariables = Exact<{
  team: Scalars['String'];
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  language: Scalars['String'];
  id: Scalars['ID'];
}>;


export type DeleteFeatureMutation = { __typename?: 'Mutation', deleteVehicleFeature?: { __typename?: 'DeleteVehicleFeatureResponse', id: string, message?: string | null } | null };

export type VehicleFeatureFieldsFragment = { __typename?: 'VehicleFeature', id: string, revId: string, comLangId?: string | null, categoryId?: string | null, category?: string | null, subCategoryId?: string | null, subCategory?: string | null, description: string, shortDescription?: string | null, notes?: string | null, rejectNotes?: string | null, link?: string | null, isInProgress: boolean, isHighlighted: boolean, isDeleted: boolean, changedAttributes?: Array<string> | null, fromTMNA?: boolean | null, longDescription?: string | null, tooltip?: string | null, gradeApplicability?: Array<{ __typename?: 'GradeApplicability', id: string, highlighted: boolean, applicabilityText?: Array<{ __typename?: 'GradeApplicabilityText', id: string, text: string, availability: string }> | null }> | null, modelApplicability?: Array<{ __typename?: 'ModelApplicability', id: string, text?: string | null }> | null, splits?: Array<{ __typename?: 'FeatureSplit', id: string, splitNumber: number, description: string, shortDescription?: string | null, longDescription?: string | null, notes?: string | null, isHighlighted: boolean, isDeleted: boolean }> | null };

export type GradeApplicabilityFieldsFragment = { __typename?: 'GradeApplicability', id: string, highlighted: boolean, applicabilityText?: Array<{ __typename?: 'GradeApplicabilityText', id: string, text: string, availability: string }> | null };

export type GradeApplicabilityTextFieldsFragment = { __typename?: 'GradeApplicabilityText', id: string, text: string, availability: string };

export type ModelApplicabilityFieldsFragment = { __typename?: 'ModelApplicability', id: string, text?: string | null };

export type FeatureSplitFieldsFragment = { __typename?: 'FeatureSplit', id: string, splitNumber: number, description: string, shortDescription?: string | null, longDescription?: string | null, notes?: string | null, isHighlighted: boolean, isDeleted: boolean };

export type GetChangeLogFeaturesQueryVariables = Exact<{
  team: Scalars['String'];
  seriesId: Scalars['ID'];
  modelYear: Scalars['Int'];
  language: Scalars['String'];
  status?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['Int']>;
}>;


export type GetChangeLogFeaturesQuery = { __typename?: 'Query', vehicleFeaturesChanges?: { __typename?: 'BodyJSON', body: any } | null };

export type GetFeaturesQueryVariables = Exact<{
  team: Scalars['String'];
  seriesId: Scalars['ID'];
  modelYear: Scalars['Int'];
  language: Scalars['String'];
  status?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['Int']>;
}>;


export type GetFeaturesQuery = { __typename?: 'Query', vehicleFeatures?: { __typename?: 'VehicleFeaturesResponse', vehicleFeatures?: Array<{ __typename?: 'VehicleFeature', id: string, revId: string, comLangId?: string | null, categoryId?: string | null, category?: string | null, subCategoryId?: string | null, subCategory?: string | null, description: string, shortDescription?: string | null, notes?: string | null, rejectNotes?: string | null, link?: string | null, isInProgress: boolean, isHighlighted: boolean, isDeleted: boolean, changedAttributes?: Array<string> | null, fromTMNA?: boolean | null, longDescription?: string | null, tooltip?: string | null, gradeApplicability?: Array<{ __typename?: 'GradeApplicability', id: string, highlighted: boolean, applicabilityText?: Array<{ __typename?: 'GradeApplicabilityText', id: string, text: string, availability: string }> | null }> | null, modelApplicability?: Array<{ __typename?: 'ModelApplicability', id: string, text?: string | null }> | null, splits?: Array<{ __typename?: 'FeatureSplit', id: string, splitNumber: number, description: string, shortDescription?: string | null, longDescription?: string | null, notes?: string | null, isHighlighted: boolean, isDeleted: boolean }> | null }> | null } | null };

export type RevertChangeLogFeaturesMutationVariables = Exact<{
  brand: Scalars['String'];
  team: Scalars['String'];
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  language: Scalars['String'];
  body: Scalars['JSON'];
}>;


export type RevertChangeLogFeaturesMutation = { __typename?: 'Mutation', revertVehicleFeaturesChanges: { __typename?: 'BodyJSON', body: any } };

export type UpdateFeatureMutationVariables = Exact<{
  team: Scalars['String'];
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  language: Scalars['String'];
  id: Scalars['ID'];
  revId: Scalars['String'];
  categoryId: Scalars['String'];
  subCategoryId: Scalars['String'];
  comLangId?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  shortDescription?: InputMaybe<Scalars['String']>;
  acceptChanges?: InputMaybe<Scalars['Boolean']>;
  link?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  isInProgress?: InputMaybe<Scalars['Boolean']>;
  isHighlighted?: InputMaybe<Scalars['Boolean']>;
  modelApplicability?: InputMaybe<Array<VehicleFeatureModelApplicabilityInput> | VehicleFeatureModelApplicabilityInput>;
  unlinkFromTMNA?: InputMaybe<Scalars['Boolean']>;
}>;


export type UpdateFeatureMutation = { __typename?: 'Mutation', updateVehicleFeature?: { __typename?: 'VehicleFeature', id: string, revId: string, comLangId?: string | null, categoryId?: string | null, category?: string | null, subCategoryId?: string | null, subCategory?: string | null, description: string, shortDescription?: string | null, notes?: string | null, rejectNotes?: string | null, link?: string | null, isInProgress: boolean, isHighlighted: boolean, isDeleted: boolean, changedAttributes?: Array<string> | null, fromTMNA?: boolean | null, longDescription?: string | null, tooltip?: string | null, gradeApplicability?: Array<{ __typename?: 'GradeApplicability', id: string, highlighted: boolean, applicabilityText?: Array<{ __typename?: 'GradeApplicabilityText', id: string, text: string, availability: string }> | null }> | null, modelApplicability?: Array<{ __typename?: 'ModelApplicability', id: string, text?: string | null }> | null, splits?: Array<{ __typename?: 'FeatureSplit', id: string, splitNumber: number, description: string, shortDescription?: string | null, longDescription?: string | null, notes?: string | null, isHighlighted: boolean, isDeleted: boolean }> | null } | null };

export type FuelTypeFieldsFragment = { __typename?: 'FuelType', id: string, type: string };

export type GetFuelTypesQueryVariables = Exact<{
  brand: Scalars['String'];
}>;


export type GetFuelTypesQuery = { __typename?: 'Query', fuelTypes?: { __typename?: 'FuelTypesResponse', fuelTypes?: Array<{ __typename?: 'FuelType', id: string, type: string }> | null } | null };

export type AddGradesMutationVariables = Exact<{
  team: Scalars['String'];
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  language: Scalars['String'];
  gradeName: Scalars['String'];
  gradeId?: InputMaybe<Scalars['String']>;
}>;


export type AddGradesMutation = { __typename?: 'Mutation', createSeriesGrade?: { __typename?: 'SeriesGradesResponse', seriesGrades?: Array<{ __typename?: 'SeriesGrade', id: string, name: string, isDeleted: boolean, isRequired?: boolean | null }> | null } | null };

export type SeriesGradeFieldsFragment = { __typename?: 'SeriesGrade', id: string, name: string, isDeleted: boolean, isRequired?: boolean | null };

export type GetGradesQueryVariables = Exact<{
  team: Scalars['String'];
  seriesId: Scalars['ID'];
  modelYear: Scalars['Int'];
  language: Scalars['String'];
  status?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['Int']>;
  includeAll?: InputMaybe<Scalars['Boolean']>;
}>;


export type GetGradesQuery = { __typename?: 'Query', seriesGrades?: { __typename?: 'SeriesGradesResponse', seriesGrades?: Array<{ __typename?: 'SeriesGrade', id: string, name: string, isDeleted: boolean, isRequired?: boolean | null }> | null } | null };

export type UpdateGradeMutationVariables = Exact<{
  team: Scalars['String'];
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  language: Scalars['String'];
  gradeName: Scalars['String'];
  gradeId: Scalars['String'];
}>;


export type UpdateGradeMutation = { __typename?: 'Mutation', updateSeriesGrade?: { __typename?: 'SeriesGrade', id: string, name: string, isDeleted: boolean, isRequired?: boolean | null } | null };

export type DeleteModelMutationVariables = Exact<{
  team: Scalars['String'];
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  language: Scalars['String'];
  id: Scalars['ID'];
}>;


export type DeleteModelMutation = { __typename?: 'Mutation', deleteModel?: { __typename?: 'DeleteModelResponse', id: string, message?: string | null } | null };

export type ModelFieldsFragment = { __typename?: 'Model', id: string, code: string, revId: string, grade: string, gradeValue?: string | null, drive: string, engine: string, horsepower: string, transmission: string, isHybrid: boolean, isDeleted: boolean, bed?: string | null, cab?: string | null, seating?: string | null, katashiki?: string | null, sortOrder?: number | null, fuelType: string, isNotPublishable: boolean, description: string, trimTitle: string, rejectNotes?: string | null, goLiveDate?: string | null, changedAttributes?: Array<string> | null, tdprCode?: string | null, isTDPR?: boolean | null, isUSVI?: boolean | null, fromTMNA?: boolean | null };

export type GetChangeLogModelsQueryVariables = Exact<{
  team: Scalars['String'];
  seriesId: Scalars['ID'];
  modelYear: Scalars['Int'];
  language: Scalars['String'];
  status?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['Int']>;
}>;


export type GetChangeLogModelsQuery = { __typename?: 'Query', modelsChanges?: { __typename?: 'BodyJSON', body: any } | null };

export type GetModelsQueryVariables = Exact<{
  team: Scalars['String'];
  seriesId: Scalars['ID'];
  modelYear: Scalars['Int'];
  language: Scalars['String'];
  status?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['Int']>;
  includeAll?: InputMaybe<Scalars['Boolean']>;
}>;


export type GetModelsQuery = { __typename?: 'Query', models?: { __typename?: 'ModelsResponse', models?: Array<{ __typename?: 'Model', id: string, code: string, revId: string, grade: string, gradeValue?: string | null, drive: string, engine: string, horsepower: string, transmission: string, isHybrid: boolean, isDeleted: boolean, bed?: string | null, cab?: string | null, seating?: string | null, katashiki?: string | null, sortOrder?: number | null, fuelType: string, isNotPublishable: boolean, description: string, trimTitle: string, rejectNotes?: string | null, goLiveDate?: string | null, changedAttributes?: Array<string> | null, tdprCode?: string | null, isTDPR?: boolean | null, isUSVI?: boolean | null, fromTMNA?: boolean | null }> | null } | null };

export type RevertChangeLogModelsMutationVariables = Exact<{
  brand: Scalars['String'];
  team: Scalars['String'];
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  language: Scalars['String'];
  body: Scalars['JSON'];
}>;


export type RevertChangeLogModelsMutation = { __typename?: 'Mutation', revertModelsChanges: { __typename?: 'BodyJSON', body: any } };

export type SortModelsMutationVariables = Exact<{
  brand: Scalars['String'];
  team: Scalars['String'];
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  sortList: Array<SortListItemInput> | SortListItemInput;
}>;


export type SortModelsMutation = { __typename?: 'Mutation', updateModelSort: Array<{ __typename?: 'SortListItem', id: string, revId: string, sortOrder: number }> };

export type UpdateModelMutationVariables = Exact<{
  brand: Scalars['String'];
  team: Scalars['String'];
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  language: Scalars['String'];
  id: Scalars['ID'];
  revId: Scalars['ID'];
  code: Scalars['String'];
  grade: Scalars['String'];
  drive: Scalars['String'];
  engine: Scalars['String'];
  horsepower: Scalars['String'];
  transmission: Scalars['String'];
  isHybrid: Scalars['Boolean'];
  fuelType: Scalars['String'];
  isNotPublishable: Scalars['Boolean'];
  bed?: InputMaybe<Scalars['String']>;
  cab?: InputMaybe<Scalars['String']>;
  seating?: InputMaybe<Scalars['String']>;
  katashiki?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  trimTitle: Scalars['String'];
  goLiveDate?: InputMaybe<Scalars['String']>;
  acceptChanges?: InputMaybe<Scalars['Boolean']>;
  tdprCode?: InputMaybe<Scalars['String']>;
  isTDPR?: InputMaybe<Scalars['Boolean']>;
  isUSVI?: InputMaybe<Scalars['Boolean']>;
  unlinkFromTMNA?: InputMaybe<Scalars['Boolean']>;
}>;


export type UpdateModelMutation = { __typename?: 'Mutation', updateModel?: { __typename?: 'Model', id: string, code: string, revId: string, grade: string, gradeValue?: string | null, drive: string, engine: string, horsepower: string, transmission: string, isHybrid: boolean, isDeleted: boolean, bed?: string | null, cab?: string | null, seating?: string | null, katashiki?: string | null, sortOrder?: number | null, fuelType: string, isNotPublishable: boolean, description: string, trimTitle: string, rejectNotes?: string | null, goLiveDate?: string | null, changedAttributes?: Array<string> | null, tdprCode?: string | null, isTDPR?: boolean | null, isUSVI?: boolean | null, fromTMNA?: boolean | null } | null };

export type AddOptionMutationVariables = Exact<{
  team: Scalars['String'];
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  language: Scalars['String'];
  id: Scalars['String'];
  categoryId: Scalars['String'];
  name: Scalars['String'];
  code: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  isExtraCost?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  link?: InputMaybe<Scalars['String']>;
  isInProgress?: InputMaybe<Scalars['Boolean']>;
  modelApplicability?: InputMaybe<Array<VehicleOptionModelApplicabilityInput> | VehicleOptionModelApplicabilityInput>;
}>;


export type AddOptionMutation = { __typename?: 'Mutation', createVehicleOption?: { __typename?: 'VehicleOption', id: string, revId: string, categoryId?: string | null, category?: string | null, name: string, code: string, description?: string | null, isExtraCost?: string | null, notes?: string | null, rejectNotes?: string | null, link?: string | null, isInProgress: boolean, isDeleted: boolean, changedAttributes?: Array<string | null> | null, fromTMNA?: boolean | null, modelApplicability?: Array<{ __typename?: 'ModelApplicability', id: string, text?: string | null }> | null } | null };

export type DeleteOptionMutationVariables = Exact<{
  team: Scalars['String'];
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  language: Scalars['String'];
  id: Scalars['ID'];
}>;


export type DeleteOptionMutation = { __typename?: 'Mutation', deleteVehicleOption?: { __typename?: 'DeleteVehicleOptionResponse', id: string, message?: string | null } | null };

export type VehicleOptionFieldsFragment = { __typename?: 'VehicleOption', id: string, revId: string, categoryId?: string | null, category?: string | null, name: string, code: string, description?: string | null, isExtraCost?: string | null, notes?: string | null, rejectNotes?: string | null, link?: string | null, isInProgress: boolean, isDeleted: boolean, changedAttributes?: Array<string | null> | null, fromTMNA?: boolean | null, modelApplicability?: Array<{ __typename?: 'ModelApplicability', id: string, text?: string | null }> | null };

export type GetOptionCategoriesByLangQueryVariables = Exact<{
  team: Scalars['String'];
  seriesId: Scalars['ID'];
  modelYear: Scalars['Int'];
  language: Scalars['String'];
  status?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['Int']>;
  includeAll?: InputMaybe<Scalars['Boolean']>;
}>;


export type GetOptionCategoriesByLangQuery = { __typename?: 'Query', vehicleOptionsCategories?: { __typename?: 'VehicleOptionsCategoriesResponse', vehicleOptionsCategories?: Array<{ __typename?: 'VehicleOptionsCategory', id: string, name: string }> | null } | null };

export type GetChangeLogOptionsQueryVariables = Exact<{
  team: Scalars['String'];
  seriesId: Scalars['ID'];
  modelYear: Scalars['Int'];
  language: Scalars['String'];
  status?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['Int']>;
}>;


export type GetChangeLogOptionsQuery = { __typename?: 'Query', vehicleOptionsChanges?: { __typename?: 'BodyJSON', body: any } | null };

export type GetOptionsQueryVariables = Exact<{
  team: Scalars['String'];
  seriesId: Scalars['ID'];
  modelYear: Scalars['Int'];
  language: Scalars['String'];
  status?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['Int']>;
}>;


export type GetOptionsQuery = { __typename?: 'Query', vehicleOptions?: { __typename?: 'VehicleOptionsResponse', vehicleOptions?: Array<{ __typename?: 'VehicleOption', id: string, revId: string, categoryId?: string | null, category?: string | null, name: string, code: string, description?: string | null, isExtraCost?: string | null, notes?: string | null, rejectNotes?: string | null, link?: string | null, isInProgress: boolean, isDeleted: boolean, changedAttributes?: Array<string | null> | null, fromTMNA?: boolean | null, modelApplicability?: Array<{ __typename?: 'ModelApplicability', id: string, text?: string | null }> | null }> | null } | null };

export type RevertChangeLogOptionsMutationVariables = Exact<{
  brand: Scalars['String'];
  team: Scalars['String'];
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  language: Scalars['String'];
  body: Scalars['JSON'];
}>;


export type RevertChangeLogOptionsMutation = { __typename?: 'Mutation', revertVehicleOptionsChanges: { __typename?: 'BodyJSON', body: any } };

export type UpdateOptionMutationVariables = Exact<{
  team: Scalars['String'];
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  language: Scalars['String'];
  id: Scalars['String'];
  revId: Scalars['String'];
  categoryId: Scalars['String'];
  name: Scalars['String'];
  code: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  isExtraCost?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  link?: InputMaybe<Scalars['String']>;
  isInProgress?: InputMaybe<Scalars['Boolean']>;
  modelApplicability?: InputMaybe<Array<VehicleOptionModelApplicabilityInput> | VehicleOptionModelApplicabilityInput>;
  acceptChanges?: InputMaybe<Scalars['Boolean']>;
  unlinkFromTMNA?: InputMaybe<Scalars['Boolean']>;
}>;


export type UpdateOptionMutation = { __typename?: 'Mutation', updateVehicleOption?: { __typename?: 'VehicleOption', id: string, revId: string, categoryId?: string | null, category?: string | null, name: string, code: string, description?: string | null, isExtraCost?: string | null, notes?: string | null, rejectNotes?: string | null, link?: string | null, isInProgress: boolean, isDeleted: boolean, changedAttributes?: Array<string | null> | null, fromTMNA?: boolean | null, modelApplicability?: Array<{ __typename?: 'ModelApplicability', id: string, text?: string | null }> | null } | null };

export type GetSeriesQueryVariables = Exact<{
  brand: Scalars['String'];
}>;


export type GetSeriesQuery = { __typename?: 'Query', series?: Array<{ __typename?: 'SeriesItem', revId: string, name: string, id: string, sourceSeriesId?: string | null, sourceBrand?: string | null, group: string }> | null };

export type GetSeriesCategoriesQueryVariables = Exact<{
  brand: Scalars['String'];
  getLatestVersion?: InputMaybe<Scalars['Boolean']>;
}>;


export type GetSeriesCategoriesQuery = { __typename?: 'Query', seriesCategories?: { __typename?: 'SeriesCategoriesResponse', seriesCategories?: Array<{ __typename?: 'SeriesCategory', name: string, revId: string, id: string, seriesId: string, nameES?: string | null, series?: Array<{ __typename?: 'SeriesCategorySeries', seriesName: string, seriesYear: string, isPublishedToDownstream: boolean }> | null }> | null } | null };

export type GetUserSeriesQueryVariables = Exact<{
  brand: Scalars['String'];
  objectId: Scalars['ID'];
}>;


export type GetUserSeriesQuery = { __typename?: 'Query', seriesSelections?: Array<{ __typename?: 'SeriesItem', revId: string, name: string, id: string, sourceSeriesId?: string | null, sourceBrand?: string | null, group: string }> | null };

export type UpdateUserSeriesMutationVariables = Exact<{
  brand?: InputMaybe<Scalars['String']>;
  objectId: Scalars['ID'];
  seriesSelections?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
}>;


export type UpdateUserSeriesMutation = { __typename?: 'Mutation', updateSeriesSelections?: Array<string | null> | null };

export type SeriesSettingFieldsFragment = { __typename?: 'SeriesSetting', id: string, revId: string, name: string, fuelTypes: Array<string>, estimatedMileage?: string | null, seating?: string | null, seriesCategories: Array<string>, mpge?: string | null, isPublishable: boolean, isDeleted?: boolean | null, changedAttributes?: Array<string> | null, fromTMNA?: boolean | null, horsepower?: string | null, range?: string | null, startingMSRP?: string | null, seriesId: string, seriesNumberId?: number | null, subSeries?: Array<{ __typename?: 'SeriesSubSetting', id: string, revId: string, name: string, fuelTypes: Array<string>, estimatedMileage?: string | null, seating?: string | null, seriesCategories: Array<string>, mpge?: string | null, isPublishable: boolean, isDeleted?: boolean | null, changedAttributes?: Array<string> | null, fromTMNA?: boolean | null, horsepower?: string | null, range?: string | null, startingMSRP?: string | null, seriesId: string, seriesNumberId?: number | null, parentId: string }> | null };

export type SeriesSubSettingFieldsFragment = { __typename?: 'SeriesSubSetting', id: string, revId: string, name: string, fuelTypes: Array<string>, estimatedMileage?: string | null, seating?: string | null, seriesCategories: Array<string>, mpge?: string | null, isPublishable: boolean, isDeleted?: boolean | null, changedAttributes?: Array<string> | null, fromTMNA?: boolean | null, horsepower?: string | null, range?: string | null, startingMSRP?: string | null, seriesId: string, seriesNumberId?: number | null, parentId: string };

export type GetSeriesSettingsQueryVariables = Exact<{
  team: Scalars['String'];
  seriesId: Scalars['ID'];
  modelYear: Scalars['Int'];
  lang: Scalars['String'];
  status?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['Int']>;
  includeAll?: InputMaybe<Scalars['Boolean']>;
}>;


export type GetSeriesSettingsQuery = { __typename?: 'Query', seriesSettings?: { __typename?: 'SeriesSettingsResponse', seriesSettings?: Array<{ __typename?: 'SeriesSetting', id: string, revId: string, name: string, fuelTypes: Array<string>, estimatedMileage?: string | null, seating?: string | null, seriesCategories: Array<string>, mpge?: string | null, isPublishable: boolean, isDeleted?: boolean | null, changedAttributes?: Array<string> | null, fromTMNA?: boolean | null, horsepower?: string | null, range?: string | null, startingMSRP?: string | null, seriesId: string, seriesNumberId?: number | null, subSeries?: Array<{ __typename?: 'SeriesSubSetting', id: string, revId: string, name: string, fuelTypes: Array<string>, estimatedMileage?: string | null, seating?: string | null, seriesCategories: Array<string>, mpge?: string | null, isPublishable: boolean, isDeleted?: boolean | null, changedAttributes?: Array<string> | null, fromTMNA?: boolean | null, horsepower?: string | null, range?: string | null, startingMSRP?: string | null, seriesId: string, seriesNumberId?: number | null, parentId: string }> | null }> | null } | null };

export type UpdateSeriesSettingMutationVariables = Exact<{
  acceptChanges?: InputMaybe<Scalars['Boolean']>;
  brand: Scalars['String'];
  estimatedMileage?: InputMaybe<Scalars['String']>;
  fuelTypes: Array<Scalars['ID']> | Scalars['ID'];
  id: Scalars['ID'];
  isDeleted: Scalars['Boolean'];
  isPublishable?: InputMaybe<Scalars['Boolean']>;
  language: Scalars['String'];
  modelYear: Scalars['Int'];
  mpge?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  parentId?: InputMaybe<Scalars['String']>;
  parentRevId?: InputMaybe<Scalars['String']>;
  range?: InputMaybe<Scalars['String']>;
  revId: Scalars['ID'];
  seating: Scalars['String'];
  seriesCategories: Array<Scalars['ID']> | Scalars['ID'];
  seriesId: Scalars['String'];
  team: Scalars['String'];
  unlinkFromTMNA?: InputMaybe<Scalars['Boolean']>;
}>;


export type UpdateSeriesSettingMutation = { __typename?: 'Mutation', updateSeriesSetting?: { __typename: 'SeriesSetting', id: string, revId: string, name: string, fuelTypes: Array<string>, estimatedMileage?: string | null, seating?: string | null, seriesCategories: Array<string>, mpge?: string | null, isPublishable: boolean, isDeleted?: boolean | null, changedAttributes?: Array<string> | null, fromTMNA?: boolean | null, horsepower?: string | null, range?: string | null, startingMSRP?: string | null, seriesId: string, seriesNumberId?: number | null, subSeries?: Array<{ __typename?: 'SeriesSubSetting', id: string, revId: string, name: string, fuelTypes: Array<string>, estimatedMileage?: string | null, seating?: string | null, seriesCategories: Array<string>, mpge?: string | null, isPublishable: boolean, isDeleted?: boolean | null, changedAttributes?: Array<string> | null, fromTMNA?: boolean | null, horsepower?: string | null, range?: string | null, startingMSRP?: string | null, seriesId: string, seriesNumberId?: number | null, parentId: string }> | null } | { __typename: 'SeriesSubSetting', id: string, revId: string, name: string, fuelTypes: Array<string>, estimatedMileage?: string | null, seating?: string | null, seriesCategories: Array<string>, mpge?: string | null, isPublishable: boolean, isDeleted?: boolean | null, changedAttributes?: Array<string> | null, fromTMNA?: boolean | null, horsepower?: string | null, range?: string | null, startingMSRP?: string | null, seriesId: string, seriesNumberId?: number | null, parentId: string } | null };

export type GetSortListQueryVariables = Exact<{
  team: Scalars['String'];
  seriesId: Scalars['ID'];
  modelYear: Scalars['Int'];
  dataType: Scalars['String'];
  status?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['Int']>;
}>;


export type GetSortListQuery = { __typename?: 'Query', sortList?: { __typename?: 'SortList', sortList?: Array<{ __typename?: 'SortItem', id: string, position: number }> | null } | null };

export type UpdateSortListMutationVariables = Exact<{
  team: Scalars['String'];
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  dataType: Scalars['String'];
  sortList: Array<InputMaybe<SortItemInput>> | InputMaybe<SortItemInput>;
}>;


export type UpdateSortListMutation = { __typename?: 'Mutation', updateSortList?: { __typename?: 'SortList', sortList?: Array<{ __typename?: 'SortItem', id: string, position: number }> | null } | null };

export type AddSpecTypesMutationVariables = Exact<{
  team: Scalars['String'];
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  specTypes: Array<VehicleSpecSpecTypeInput> | VehicleSpecSpecTypeInput;
  id?: InputMaybe<Scalars['String']>;
}>;


export type AddSpecTypesMutation = { __typename?: 'Mutation', createVehicleSpecSpecType?: { __typename?: 'CreateVehicleSpecSpecType', vehicleSpecsLanguageSpecTypes?: Array<{ __typename?: 'VehicleSpecLanguageSpecType', language: string, vehicleSpecsSpecTypes?: Array<{ __typename?: 'VehicleSpecSpecType', id: string, name: string, isRequired?: boolean | null }> | null }> | null } | null };

export type AddVehicleSpecMutationVariables = Exact<{
  team: Scalars['String'];
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  language: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  categoryId: Scalars['String'];
  specTypeId: Scalars['String'];
  comLangId?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  shortDescription?: InputMaybe<Scalars['String']>;
  link?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  isInProgress?: InputMaybe<Scalars['Boolean']>;
  modelApplicability?: InputMaybe<Array<VehicleSpecModelApplicabilityInput> | VehicleSpecModelApplicabilityInput>;
}>;


export type AddVehicleSpecMutation = { __typename?: 'Mutation', createVehicleSpec?: { __typename?: 'VehicleSpec', id: string, revId: string, comLangId?: string | null, categoryId?: string | null, category?: string | null, specTypeId?: string | null, specType?: string | null, description: string, notes?: string | null, rejectNotes?: string | null, link?: string | null, isInProgress?: boolean | null, isDeleted: boolean, isRequired?: boolean | null, compareFeatureId?: string | null, changedAttributes?: Array<string> | null, isTDPR?: boolean | null, isUSVI?: boolean | null, fromTMNA?: boolean | null, modelApplicability?: Array<{ __typename?: 'ModelApplicability', id: string, text?: string | null }> | null } | null };

export type DeleteVehicleSpecMutationVariables = Exact<{
  team: Scalars['String'];
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  language: Scalars['String'];
  id: Scalars['ID'];
}>;


export type DeleteVehicleSpecMutation = { __typename?: 'Mutation', deleteVehicleSpec?: { __typename?: 'DeleteVehicleSpecResponse', id: string, message?: string | null } | null };

export type VehicleSpecFieldsFragment = { __typename?: 'VehicleSpec', id: string, revId: string, comLangId?: string | null, categoryId?: string | null, category?: string | null, specTypeId?: string | null, specType?: string | null, description: string, notes?: string | null, rejectNotes?: string | null, link?: string | null, isInProgress?: boolean | null, isDeleted: boolean, isRequired?: boolean | null, compareFeatureId?: string | null, changedAttributes?: Array<string> | null, isTDPR?: boolean | null, isUSVI?: boolean | null, fromTMNA?: boolean | null, modelApplicability?: Array<{ __typename?: 'ModelApplicability', id: string, text?: string | null }> | null };

export type VehicleSpecSpecTypeFieldsFragment = { __typename?: 'VehicleSpecSpecType', id: string, name: string, isRequired?: boolean | null };

export type VehicleSpecLanguageSpecTypeFieldsFragment = { __typename?: 'VehicleSpecLanguageSpecType', language: string, vehicleSpecsSpecTypes?: Array<{ __typename?: 'VehicleSpecSpecType', id: string, name: string, isRequired?: boolean | null }> | null };

export type GetSpecCategoriesByLangQueryVariables = Exact<{
  team: Scalars['String'];
  seriesId: Scalars['ID'];
  modelYear: Scalars['Int'];
  language: Scalars['String'];
  status?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['Int']>;
  includeAll?: InputMaybe<Scalars['Boolean']>;
}>;


export type GetSpecCategoriesByLangQuery = { __typename?: 'Query', vehicleSpecsCategories?: { __typename?: 'VehicleSpecsCategoriesResponse', vehicleSpecsCategories?: Array<{ __typename?: 'VehicleSpecCategory', id: string, name: string, isRequired?: boolean | null }> | null } | null };

export type GetChangeLogSpecsQueryVariables = Exact<{
  team: Scalars['String'];
  seriesId: Scalars['ID'];
  modelYear: Scalars['Int'];
  language: Scalars['String'];
  status?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['Int']>;
}>;


export type GetChangeLogSpecsQuery = { __typename?: 'Query', vehicleSpecsChanges?: { __typename?: 'BodyJSON', body: any } | null };

export type GetSpecTypesByLangQueryVariables = Exact<{
  team: Scalars['String'];
  seriesId: Scalars['ID'];
  modelYear: Scalars['Int'];
  language: Scalars['String'];
  status?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['Int']>;
  includeAll?: InputMaybe<Scalars['Boolean']>;
}>;


export type GetSpecTypesByLangQuery = { __typename?: 'Query', vehicleSpecsSpecTypes?: { __typename?: 'VehicleSpecsSpecTypesResponse', vehicleSpecsSpecTypes?: Array<{ __typename?: 'VehicleSpecSpecType', id: string, name: string, isRequired?: boolean | null }> | null } | null };

export type GetVehicleSpecsQueryVariables = Exact<{
  team: Scalars['String'];
  seriesId: Scalars['ID'];
  modelYear: Scalars['Int'];
  language: Scalars['String'];
  status?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['Int']>;
}>;


export type GetVehicleSpecsQuery = { __typename?: 'Query', vehicleSpecs?: { __typename?: 'VehicleSpecsResponse', vehicleSpecs?: Array<{ __typename?: 'VehicleSpec', id: string, revId: string, comLangId?: string | null, categoryId?: string | null, category?: string | null, specTypeId?: string | null, specType?: string | null, description: string, notes?: string | null, rejectNotes?: string | null, link?: string | null, isInProgress?: boolean | null, isDeleted: boolean, isRequired?: boolean | null, compareFeatureId?: string | null, changedAttributes?: Array<string> | null, isTDPR?: boolean | null, isUSVI?: boolean | null, fromTMNA?: boolean | null, modelApplicability?: Array<{ __typename?: 'ModelApplicability', id: string, text?: string | null }> | null }> | null } | null };

export type RevertChangeLogSpecsMutationVariables = Exact<{
  brand: Scalars['String'];
  team: Scalars['String'];
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  language: Scalars['String'];
  body: Scalars['JSON'];
}>;


export type RevertChangeLogSpecsMutation = { __typename?: 'Mutation', revertVehicleSpecsChanges: { __typename?: 'BodyJSON', body: any } };

export type UpdateSpecTypeMutationVariables = Exact<{
  team: Scalars['String'];
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  language: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
}>;


export type UpdateSpecTypeMutation = { __typename?: 'Mutation', updateVehicleSpecSpecType?: { __typename?: 'VehicleSpecSpecType', id: string, name: string, isRequired?: boolean | null } | null };

export type UpdateVehicleSpecMutationVariables = Exact<{
  team: Scalars['String'];
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  language: Scalars['String'];
  id: Scalars['ID'];
  revId: Scalars['String'];
  categoryId: Scalars['String'];
  specTypeId: Scalars['String'];
  comLangId?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  shortDescription?: InputMaybe<Scalars['String']>;
  acceptChanges?: InputMaybe<Scalars['Boolean']>;
  link?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  isInProgress?: InputMaybe<Scalars['Boolean']>;
  modelApplicability?: InputMaybe<Array<VehicleFeatureModelApplicabilityInput> | VehicleFeatureModelApplicabilityInput>;
  unlinkFromTMNA?: InputMaybe<Scalars['Boolean']>;
}>;


export type UpdateVehicleSpecMutation = { __typename?: 'Mutation', updateVehicleSpec?: { __typename?: 'VehicleSpec', id: string, revId: string, comLangId?: string | null, categoryId?: string | null, category?: string | null, specTypeId?: string | null, specType?: string | null, description: string, notes?: string | null, rejectNotes?: string | null, link?: string | null, isInProgress?: boolean | null, isDeleted: boolean, isRequired?: boolean | null, compareFeatureId?: string | null, changedAttributes?: Array<string> | null, isTDPR?: boolean | null, isUSVI?: boolean | null, fromTMNA?: boolean | null, modelApplicability?: Array<{ __typename?: 'ModelApplicability', id: string, text?: string | null }> | null } | null };

export type AddSubCategoriesMutationVariables = Exact<{
  team: Scalars['String'];
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  subCategories: Array<VehicleFeatureSubCategoryInput> | VehicleFeatureSubCategoryInput;
  id?: InputMaybe<Scalars['String']>;
}>;


export type AddSubCategoriesMutation = { __typename?: 'Mutation', createVehicleFeatureSubCategory?: { __typename?: 'CreateVehicleFeatureSubCategoryResponse', vehicleFeaturesLanguageSubCategories?: Array<{ __typename?: 'VehicleFeatureLanguageSubCategory', language: string, vehicleFeaturesSubCategories?: Array<{ __typename?: 'VehicleFeaturesSubCategory', id: string, name: string }> | null }> | null } | null };

export type VehicleFeaturesSubCategoryFieldsFragment = { __typename?: 'VehicleFeaturesSubCategory', id: string, name: string };

export type VehicleLanguageFeatureSubCategoryFieldsFragment = { __typename?: 'VehicleFeatureLanguageSubCategory', language: string, vehicleFeaturesSubCategories?: Array<{ __typename?: 'VehicleFeaturesSubCategory', id: string, name: string }> | null };

export type GetSubCategoriesByLangQueryVariables = Exact<{
  team: Scalars['String'];
  seriesId: Scalars['ID'];
  modelYear: Scalars['Int'];
  language: Scalars['String'];
  status?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['Int']>;
  includeAll?: InputMaybe<Scalars['Boolean']>;
}>;


export type GetSubCategoriesByLangQuery = { __typename?: 'Query', vehicleFeaturesSubCategories?: { __typename?: 'VehicleFeaturesSubCategoriesResponse', vehicleFeaturesSubCategories?: Array<{ __typename?: 'VehicleFeaturesSubCategory', id: string, name: string }> | null } | null };

export type UpdateSubCategoryMutationVariables = Exact<{
  team: Scalars['String'];
  seriesId: Scalars['String'];
  modelYear: Scalars['Int'];
  language: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
}>;


export type UpdateSubCategoryMutation = { __typename?: 'Mutation', updateVehicleFeatureSubCategory?: { __typename?: 'UpdateVehicleFeatureSubCategoryResponse', id: string, name: string } | null };

export const VehicleFeaturesCategoryFieldsFragmentDoc = gql`
    fragment VehicleFeaturesCategoryFields on VehicleFeaturesCategory {
  id
  name
}
    `;
export const VehicleLanguageFeatureCategoryFieldsFragmentDoc = gql`
    fragment VehicleLanguageFeatureCategoryFields on VehicleFeatureLanguageCategory {
  language
  vehicleFeaturesCategories {
    ...VehicleFeaturesCategoryFields
  }
}
    ${VehicleFeaturesCategoryFieldsFragmentDoc}`;
export const VehicleSpecCategoryFieldsFragmentDoc = gql`
    fragment VehicleSpecCategoryFields on VehicleSpecCategory {
  id
  name
  isRequired
  isDeleted
}
    `;
export const VehicleSpecLanguageCategoryFieldsFragmentDoc = gql`
    fragment VehicleSpecLanguageCategoryFields on VehicleSpecLanguageCategory {
  language
  vehicleSpecsCategories {
    ...VehicleSpecCategoryFields
  }
}
    ${VehicleSpecCategoryFieldsFragmentDoc}`;
export const VehicleOptionsCategoryFieldsFragmentDoc = gql`
    fragment VehicleOptionsCategoryFields on VehicleOptionsCategory {
  id
  name
}
    `;
export const VehicleOptionLanguageCategoryFieldsFragmentDoc = gql`
    fragment VehicleOptionLanguageCategoryFields on VehicleOptionLanguageCategory {
  language
  vehicleOptionsCategories {
    ...VehicleOptionsCategoryFields
  }
}
    ${VehicleOptionsCategoryFieldsFragmentDoc}`;
export const VehicleColoColorApplicabilityFieldsFragmentDoc = gql`
    fragment VehicleColoColorApplicabilityFields on VehicleColoColorApplicability {
  interiorColorId
  grade
}
    `;
export const VehicleColoTrimApplicabilityFieldsFragmentDoc = gql`
    fragment VehicleColoTrimApplicabilityFields on VehicleColoTrimApplicability {
  id
  isApplicable
}
    `;
export const VehicleColorExtFieldsFragmentDoc = gql`
    fragment VehicleColorExtFields on VehicleColorExt {
  id
  revId
  code
  hexCode
  name
  isExtraCost
  isDeleted
  isInProgress
  notes
  rejectNotes
  colorApplicability {
    ...VehicleColoColorApplicabilityFields
  }
  trimApplicability {
    ...VehicleColoTrimApplicabilityFields
  }
  changedAttributes
  fromTMNA
}
    ${VehicleColoColorApplicabilityFieldsFragmentDoc}
${VehicleColoTrimApplicabilityFieldsFragmentDoc}`;
export const VehicleColoModelApplicabilityColorFieldsFragmentDoc = gql`
    fragment VehicleColoModelApplicabilityColorFields on VehicleColoModelApplicabilityColor {
  id
  isApplicable
}
    `;
export const VehicleColoModelApplicabilityFieldsFragmentDoc = gql`
    fragment VehicleColoModelApplicabilityFields on VehicleColoModelApplicability {
  id
  colorApplicability {
    ...VehicleColoModelApplicabilityColorFields
  }
}
    ${VehicleColoModelApplicabilityColorFieldsFragmentDoc}`;
export const VehicleColorIntFieldsFragmentDoc = gql`
    fragment VehicleColorIntFields on VehicleColorInt {
  id
  revId
  name
  code
  gradeApplicability
  isExtraCost
  isDeleted
  rejectNotes
  modelApplicability {
    ...VehicleColoModelApplicabilityFields
  }
  interiorType
  interiorTypeId
  roofTopColor
  changedAttributes
  fromTMNA
}
    ${VehicleColoModelApplicabilityFieldsFragmentDoc}`;
export const VehicleCompareFeatureGradeApplicabilityFieldsFragmentDoc = gql`
    fragment VehicleCompareFeatureGradeApplicabilityFields on VehicleCompareFeatureGradeApplicability {
  id
  highlighted
  text
  applicability
}
    `;
export const VehicleCompareFeatureFieldsFragmentDoc = gql`
    fragment VehicleCompareFeatureFields on VehicleCompareFeature {
  id
  revId
  parentId
  compareType
  categoryId
  category
  subCategoryId
  subCategory
  description
  notes
  rejectNotes
  isInProgress
  gradeApplicability {
    ...VehicleCompareFeatureGradeApplicabilityFields
  }
  isDeleted
  changedAttributes
  fromTMNA
}
    ${VehicleCompareFeatureGradeApplicabilityFieldsFragmentDoc}`;
export const VehicleCompareDoubleSortItemFieldsFragmentDoc = gql`
    fragment VehicleCompareDoubleSortItemFields on VehicleCompareDoubleSortItem {
  id
  position
}
    `;
export const VehicleCompareDoubleSortListFieldsFragmentDoc = gql`
    fragment VehicleCompareDoubleSortListFields on VehicleCompareDoubleSortList {
  id
  sortList {
    ...VehicleCompareDoubleSortItemFields
  }
}
    ${VehicleCompareDoubleSortItemFieldsFragmentDoc}`;
export const StatusItemFieldsFragmentDoc = gql`
    fragment StatusItemFields on StatusItem {
  currentStatus
  modifiedDate
  modifiedBy
}
    `;
export const SourceUpdateFieldsFragmentDoc = gql`
    fragment SourceUpdateFields on SourceUpdate {
  lang
  hasUpdates
}
    `;
export const VersionFieldsSpanishFragmentDoc = gql`
    fragment VersionFieldsSpanish on SeriesModelYearVersionSpanish {
  year
  isDraft
  publishDownstreamVersion
  publishDownstreamDate
  previewDownstreamDate
  previewDownstreamVersion
  skippedLivePublish
  vdStatus {
    ...StatusItemFields
  }
  englishVersion
  englishPublishedDate
  spanishVersion
  spanishPublishedDate
  key
  sourceVersion
  sourceLang
  outOfSync
  hasSourceUpdates {
    ...SourceUpdateFields
  }
}
    ${StatusItemFieldsFragmentDoc}
${SourceUpdateFieldsFragmentDoc}`;
export const VersionFieldsFragmentDoc = gql`
    fragment VersionFields on SeriesModelYearVersion {
  year
  isDraft
  publishDownstreamVersion
  publishDownstreamDate
  previewDownstreamDate
  previewDownstreamVersion
  skippedLivePublish
  vdStatus {
    ...StatusItemFields
  }
  datePublished
  createdDate
  version
  isPendingVDReview
  isPendingCLReview
  isSubmitted
}
    ${StatusItemFieldsFragmentDoc}`;
export const DashboardFieldsFragmentDoc = gql`
    fragment DashboardFields on VehicleSeries {
  seriesId
  name
  years {
    year
    versions {
      ...VersionFieldsSpanish
      ...VersionFields
    }
  }
}
    ${VersionFieldsSpanishFragmentDoc}
${VersionFieldsFragmentDoc}`;
export const VehicleDocumentFieldsFragmentDoc = gql`
    fragment VehicleDocumentFields on VehicleDocument {
  status
  lastPublishedVersion
  lastPublishedDate
  lastModifiedDate
  lastModifiedBy
  version
  createdDate
  seriesId
  modelYear
  isPendingVDReview
  isPendingCLReview
  isSubmitted
  vdStatus {
    ...StatusItemFields
  }
  sourceVersion
  sourceLang
  lastSyncDate
  hasSourceUpdates {
    ...SourceUpdateFields
  }
}
    ${StatusItemFieldsFragmentDoc}
${SourceUpdateFieldsFragmentDoc}`;
export const GradeApplicabilityTextFieldsFragmentDoc = gql`
    fragment GradeApplicabilityTextFields on GradeApplicabilityText {
  id
  text
  availability
}
    `;
export const GradeApplicabilityFieldsFragmentDoc = gql`
    fragment GradeApplicabilityFields on GradeApplicability {
  id
  highlighted
  applicabilityText {
    ...GradeApplicabilityTextFields
  }
}
    ${GradeApplicabilityTextFieldsFragmentDoc}`;
export const ModelApplicabilityFieldsFragmentDoc = gql`
    fragment ModelApplicabilityFields on ModelApplicability {
  id
  text
}
    `;
export const FeatureSplitFieldsFragmentDoc = gql`
    fragment FeatureSplitFields on FeatureSplit {
  id
  splitNumber
  description
  shortDescription
  longDescription
  notes
  isHighlighted
  isDeleted
}
    `;
export const VehicleFeatureFieldsFragmentDoc = gql`
    fragment VehicleFeatureFields on VehicleFeature {
  id
  revId
  comLangId
  categoryId
  category
  subCategoryId
  subCategory
  description
  shortDescription
  notes
  rejectNotes
  link
  isInProgress
  isHighlighted
  isDeleted
  gradeApplicability {
    ...GradeApplicabilityFields
  }
  modelApplicability {
    ...ModelApplicabilityFields
  }
  splits {
    ...FeatureSplitFields
  }
  changedAttributes
  fromTMNA
  longDescription
  tooltip
}
    ${GradeApplicabilityFieldsFragmentDoc}
${ModelApplicabilityFieldsFragmentDoc}
${FeatureSplitFieldsFragmentDoc}`;
export const FuelTypeFieldsFragmentDoc = gql`
    fragment FuelTypeFields on FuelType {
  id
  type
}
    `;
export const SeriesGradeFieldsFragmentDoc = gql`
    fragment SeriesGradeFields on SeriesGrade {
  id
  name
  isDeleted
  isRequired
}
    `;
export const ModelFieldsFragmentDoc = gql`
    fragment ModelFields on Model {
  id
  code
  revId
  grade
  gradeValue
  drive
  engine
  horsepower
  transmission
  isHybrid
  isDeleted
  bed
  cab
  seating
  katashiki
  sortOrder
  fuelType
  isNotPublishable
  description
  trimTitle
  rejectNotes
  goLiveDate
  changedAttributes
  tdprCode
  isTDPR
  isUSVI
  fromTMNA
}
    `;
export const VehicleOptionFieldsFragmentDoc = gql`
    fragment VehicleOptionFields on VehicleOption {
  id
  revId
  categoryId
  category
  name
  code
  description
  isExtraCost
  notes
  rejectNotes
  link
  isInProgress
  modelApplicability {
    ...ModelApplicabilityFields
  }
  isDeleted
  changedAttributes
  fromTMNA
}
    ${ModelApplicabilityFieldsFragmentDoc}`;
export const SeriesSubSettingFieldsFragmentDoc = gql`
    fragment SeriesSubSettingFields on SeriesSubSetting {
  id
  revId
  name
  fuelTypes
  estimatedMileage
  seating
  seriesCategories
  mpge
  isPublishable
  isDeleted
  changedAttributes
  fromTMNA
  horsepower
  range
  startingMSRP
  seriesId
  seriesNumberId
  parentId
}
    `;
export const SeriesSettingFieldsFragmentDoc = gql`
    fragment SeriesSettingFields on SeriesSetting {
  id
  revId
  name
  fuelTypes
  estimatedMileage
  seating
  seriesCategories
  mpge
  isPublishable
  isDeleted
  changedAttributes
  fromTMNA
  horsepower
  range
  startingMSRP
  seriesId
  seriesNumberId
  subSeries {
    ...SeriesSubSettingFields
  }
}
    ${SeriesSubSettingFieldsFragmentDoc}`;
export const VehicleSpecFieldsFragmentDoc = gql`
    fragment VehicleSpecFields on VehicleSpec {
  id
  revId
  comLangId
  categoryId
  category
  specTypeId
  specType
  description
  notes
  rejectNotes
  link
  isInProgress
  modelApplicability {
    ...ModelApplicabilityFields
  }
  isDeleted
  isRequired
  compareFeatureId
  changedAttributes
  isTDPR
  isUSVI
  fromTMNA
}
    ${ModelApplicabilityFieldsFragmentDoc}`;
export const VehicleSpecSpecTypeFieldsFragmentDoc = gql`
    fragment VehicleSpecSpecTypeFields on VehicleSpecSpecType {
  id
  name
  isRequired
}
    `;
export const VehicleSpecLanguageSpecTypeFieldsFragmentDoc = gql`
    fragment VehicleSpecLanguageSpecTypeFields on VehicleSpecLanguageSpecType {
  language
  vehicleSpecsSpecTypes {
    ...VehicleSpecSpecTypeFields
  }
}
    ${VehicleSpecSpecTypeFieldsFragmentDoc}`;
export const VehicleFeaturesSubCategoryFieldsFragmentDoc = gql`
    fragment VehicleFeaturesSubCategoryFields on VehicleFeaturesSubCategory {
  id
  name
}
    `;
export const VehicleLanguageFeatureSubCategoryFieldsFragmentDoc = gql`
    fragment VehicleLanguageFeatureSubCategoryFields on VehicleFeatureLanguageSubCategory {
  language
  vehicleFeaturesSubCategories {
    ...VehicleFeaturesSubCategoryFields
  }
}
    ${VehicleFeaturesSubCategoryFieldsFragmentDoc}`;
export const AddCategoriesFeatureDocument = gql`
    mutation addCategoriesFeature($team: String!, $seriesId: String!, $modelYear: Int!, $categories: [VehicleFeatureCategoryInput!]!, $id: String) {
  createVehicleFeatureCategory(
    input: {team: $team, seriesId: $seriesId, modelYear: $modelYear, categories: $categories, id: $id}
  ) {
    vehicleFeaturesLanguageCategories {
      ...VehicleLanguageFeatureCategoryFields
    }
  }
}
    ${VehicleLanguageFeatureCategoryFieldsFragmentDoc}`;
export type AddCategoriesFeatureMutationFn = Apollo.MutationFunction<AddCategoriesFeatureMutation, AddCategoriesFeatureMutationVariables>;

/**
 * __useAddCategoriesFeatureMutation__
 *
 * To run a mutation, you first call `useAddCategoriesFeatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCategoriesFeatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCategoriesFeatureMutation, { data, loading, error }] = useAddCategoriesFeatureMutation({
 *   variables: {
 *      team: // value for 'team'
 *      seriesId: // value for 'seriesId'
 *      modelYear: // value for 'modelYear'
 *      categories: // value for 'categories'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAddCategoriesFeatureMutation(baseOptions?: Apollo.MutationHookOptions<AddCategoriesFeatureMutation, AddCategoriesFeatureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCategoriesFeatureMutation, AddCategoriesFeatureMutationVariables>(AddCategoriesFeatureDocument, options);
      }
export type AddCategoriesFeatureMutationHookResult = ReturnType<typeof useAddCategoriesFeatureMutation>;
export type AddCategoriesFeatureMutationResult = Apollo.MutationResult<AddCategoriesFeatureMutation>;
export type AddCategoriesFeatureMutationOptions = Apollo.BaseMutationOptions<AddCategoriesFeatureMutation, AddCategoriesFeatureMutationVariables>;
export const AddCategoriesOptionDocument = gql`
    mutation addCategoriesOption($team: String!, $seriesId: String!, $modelYear: Int!, $categories: [VehicleOptionCategoryInput!]!, $id: String) {
  createVehicleOptionCategory(
    input: {team: $team, seriesId: $seriesId, modelYear: $modelYear, categories: $categories, id: $id}
  ) {
    vehicleOptionsLanguageCategories {
      ...VehicleOptionLanguageCategoryFields
    }
  }
}
    ${VehicleOptionLanguageCategoryFieldsFragmentDoc}`;
export type AddCategoriesOptionMutationFn = Apollo.MutationFunction<AddCategoriesOptionMutation, AddCategoriesOptionMutationVariables>;

/**
 * __useAddCategoriesOptionMutation__
 *
 * To run a mutation, you first call `useAddCategoriesOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCategoriesOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCategoriesOptionMutation, { data, loading, error }] = useAddCategoriesOptionMutation({
 *   variables: {
 *      team: // value for 'team'
 *      seriesId: // value for 'seriesId'
 *      modelYear: // value for 'modelYear'
 *      categories: // value for 'categories'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAddCategoriesOptionMutation(baseOptions?: Apollo.MutationHookOptions<AddCategoriesOptionMutation, AddCategoriesOptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCategoriesOptionMutation, AddCategoriesOptionMutationVariables>(AddCategoriesOptionDocument, options);
      }
export type AddCategoriesOptionMutationHookResult = ReturnType<typeof useAddCategoriesOptionMutation>;
export type AddCategoriesOptionMutationResult = Apollo.MutationResult<AddCategoriesOptionMutation>;
export type AddCategoriesOptionMutationOptions = Apollo.BaseMutationOptions<AddCategoriesOptionMutation, AddCategoriesOptionMutationVariables>;
export const AddCategoriesSpecDocument = gql`
    mutation addCategoriesSpec($team: String!, $seriesId: String!, $modelYear: Int!, $categories: [VehicleSpecCategoryInput!]!, $id: String) {
  createVehicleSpecCategory(
    input: {team: $team, seriesId: $seriesId, modelYear: $modelYear, categories: $categories, id: $id}
  ) {
    vehicleSpecsLanguageCategories {
      ...VehicleSpecLanguageCategoryFields
    }
  }
}
    ${VehicleSpecLanguageCategoryFieldsFragmentDoc}`;
export type AddCategoriesSpecMutationFn = Apollo.MutationFunction<AddCategoriesSpecMutation, AddCategoriesSpecMutationVariables>;

/**
 * __useAddCategoriesSpecMutation__
 *
 * To run a mutation, you first call `useAddCategoriesSpecMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCategoriesSpecMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCategoriesSpecMutation, { data, loading, error }] = useAddCategoriesSpecMutation({
 *   variables: {
 *      team: // value for 'team'
 *      seriesId: // value for 'seriesId'
 *      modelYear: // value for 'modelYear'
 *      categories: // value for 'categories'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAddCategoriesSpecMutation(baseOptions?: Apollo.MutationHookOptions<AddCategoriesSpecMutation, AddCategoriesSpecMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCategoriesSpecMutation, AddCategoriesSpecMutationVariables>(AddCategoriesSpecDocument, options);
      }
export type AddCategoriesSpecMutationHookResult = ReturnType<typeof useAddCategoriesSpecMutation>;
export type AddCategoriesSpecMutationResult = Apollo.MutationResult<AddCategoriesSpecMutation>;
export type AddCategoriesSpecMutationOptions = Apollo.BaseMutationOptions<AddCategoriesSpecMutation, AddCategoriesSpecMutationVariables>;
export const GetCategoriesByLangFeatureDocument = gql`
    query getCategoriesByLangFeature($team: String!, $seriesId: ID!, $modelYear: Int!, $language: String!, $status: String, $version: Int, $includeAll: Boolean) {
  vehicleFeaturesCategories(
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    language: $language
    status: $status
    version: $version
    includeAll: $includeAll
  ) {
    vehicleFeaturesCategories {
      ...VehicleFeaturesCategoryFields
    }
  }
}
    ${VehicleFeaturesCategoryFieldsFragmentDoc}`;

/**
 * __useGetCategoriesByLangFeatureQuery__
 *
 * To run a query within a React component, call `useGetCategoriesByLangFeatureQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCategoriesByLangFeatureQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategoriesByLangFeatureQuery({
 *   variables: {
 *      team: // value for 'team'
 *      seriesId: // value for 'seriesId'
 *      modelYear: // value for 'modelYear'
 *      language: // value for 'language'
 *      status: // value for 'status'
 *      version: // value for 'version'
 *      includeAll: // value for 'includeAll'
 *   },
 * });
 */
export function useGetCategoriesByLangFeatureQuery(baseOptions: Apollo.QueryHookOptions<GetCategoriesByLangFeatureQuery, GetCategoriesByLangFeatureQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCategoriesByLangFeatureQuery, GetCategoriesByLangFeatureQueryVariables>(GetCategoriesByLangFeatureDocument, options);
      }
export function useGetCategoriesByLangFeatureLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCategoriesByLangFeatureQuery, GetCategoriesByLangFeatureQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCategoriesByLangFeatureQuery, GetCategoriesByLangFeatureQueryVariables>(GetCategoriesByLangFeatureDocument, options);
        }
export type GetCategoriesByLangFeatureQueryHookResult = ReturnType<typeof useGetCategoriesByLangFeatureQuery>;
export type GetCategoriesByLangFeatureLazyQueryHookResult = ReturnType<typeof useGetCategoriesByLangFeatureLazyQuery>;
export type GetCategoriesByLangFeatureQueryResult = Apollo.QueryResult<GetCategoriesByLangFeatureQuery, GetCategoriesByLangFeatureQueryVariables>;
export const UpdateCategoryFeatureDocument = gql`
    mutation updateCategoryFeature($team: String!, $seriesId: String!, $modelYear: Int!, $language: String!, $id: ID!, $name: String!) {
  updateVehicleFeatureCategory(
    input: {team: $team, seriesId: $seriesId, modelYear: $modelYear, language: $language, id: $id, name: $name}
  ) {
    id
    name
  }
}
    `;
export type UpdateCategoryFeatureMutationFn = Apollo.MutationFunction<UpdateCategoryFeatureMutation, UpdateCategoryFeatureMutationVariables>;

/**
 * __useUpdateCategoryFeatureMutation__
 *
 * To run a mutation, you first call `useUpdateCategoryFeatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCategoryFeatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCategoryFeatureMutation, { data, loading, error }] = useUpdateCategoryFeatureMutation({
 *   variables: {
 *      team: // value for 'team'
 *      seriesId: // value for 'seriesId'
 *      modelYear: // value for 'modelYear'
 *      language: // value for 'language'
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateCategoryFeatureMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCategoryFeatureMutation, UpdateCategoryFeatureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCategoryFeatureMutation, UpdateCategoryFeatureMutationVariables>(UpdateCategoryFeatureDocument, options);
      }
export type UpdateCategoryFeatureMutationHookResult = ReturnType<typeof useUpdateCategoryFeatureMutation>;
export type UpdateCategoryFeatureMutationResult = Apollo.MutationResult<UpdateCategoryFeatureMutation>;
export type UpdateCategoryFeatureMutationOptions = Apollo.BaseMutationOptions<UpdateCategoryFeatureMutation, UpdateCategoryFeatureMutationVariables>;
export const UpdateCategoryOptionDocument = gql`
    mutation updateCategoryOption($team: String!, $seriesId: String!, $modelYear: Int!, $language: String!, $id: ID!, $name: String!) {
  updateVehicleOptionCategory(
    input: {team: $team, seriesId: $seriesId, modelYear: $modelYear, language: $language, id: $id, name: $name}
  ) {
    id
    name
  }
}
    `;
export type UpdateCategoryOptionMutationFn = Apollo.MutationFunction<UpdateCategoryOptionMutation, UpdateCategoryOptionMutationVariables>;

/**
 * __useUpdateCategoryOptionMutation__
 *
 * To run a mutation, you first call `useUpdateCategoryOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCategoryOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCategoryOptionMutation, { data, loading, error }] = useUpdateCategoryOptionMutation({
 *   variables: {
 *      team: // value for 'team'
 *      seriesId: // value for 'seriesId'
 *      modelYear: // value for 'modelYear'
 *      language: // value for 'language'
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateCategoryOptionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCategoryOptionMutation, UpdateCategoryOptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCategoryOptionMutation, UpdateCategoryOptionMutationVariables>(UpdateCategoryOptionDocument, options);
      }
export type UpdateCategoryOptionMutationHookResult = ReturnType<typeof useUpdateCategoryOptionMutation>;
export type UpdateCategoryOptionMutationResult = Apollo.MutationResult<UpdateCategoryOptionMutation>;
export type UpdateCategoryOptionMutationOptions = Apollo.BaseMutationOptions<UpdateCategoryOptionMutation, UpdateCategoryOptionMutationVariables>;
export const UpdateCategorySpecDocument = gql`
    mutation updateCategorySpec($team: String!, $seriesId: String!, $modelYear: Int!, $language: String!, $id: ID!, $name: String!) {
  updateVehicleSpecCategory(
    input: {team: $team, seriesId: $seriesId, modelYear: $modelYear, language: $language, id: $id, name: $name}
  ) {
    id
    name
    isRequired
    isDeleted
  }
}
    `;
export type UpdateCategorySpecMutationFn = Apollo.MutationFunction<UpdateCategorySpecMutation, UpdateCategorySpecMutationVariables>;

/**
 * __useUpdateCategorySpecMutation__
 *
 * To run a mutation, you first call `useUpdateCategorySpecMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCategorySpecMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCategorySpecMutation, { data, loading, error }] = useUpdateCategorySpecMutation({
 *   variables: {
 *      team: // value for 'team'
 *      seriesId: // value for 'seriesId'
 *      modelYear: // value for 'modelYear'
 *      language: // value for 'language'
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateCategorySpecMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCategorySpecMutation, UpdateCategorySpecMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCategorySpecMutation, UpdateCategorySpecMutationVariables>(UpdateCategorySpecDocument, options);
      }
export type UpdateCategorySpecMutationHookResult = ReturnType<typeof useUpdateCategorySpecMutation>;
export type UpdateCategorySpecMutationResult = Apollo.MutationResult<UpdateCategorySpecMutation>;
export type UpdateCategorySpecMutationOptions = Apollo.BaseMutationOptions<UpdateCategorySpecMutation, UpdateCategorySpecMutationVariables>;
export const AddExteriorColorDocument = gql`
    mutation addExteriorColor($team: String!, $seriesId: String!, $modelYear: Int!, $language: String!, $id: ID!, $name: String!, $code: String!, $hexCode: String, $notes: String, $isExtraCost: String!, $isInProgress: Boolean!, $colorApplicability: [VehicleColoColorApplicabilityInput!]) {
  createVehicleColorExt(
    input: {team: $team, seriesId: $seriesId, modelYear: $modelYear, language: $language, id: $id, name: $name, code: $code, hexCode: $hexCode, notes: $notes, isExtraCost: $isExtraCost, isInProgress: $isInProgress, colorApplicability: $colorApplicability}
  ) {
    ...VehicleColorExtFields
  }
}
    ${VehicleColorExtFieldsFragmentDoc}`;
export type AddExteriorColorMutationFn = Apollo.MutationFunction<AddExteriorColorMutation, AddExteriorColorMutationVariables>;

/**
 * __useAddExteriorColorMutation__
 *
 * To run a mutation, you first call `useAddExteriorColorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddExteriorColorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addExteriorColorMutation, { data, loading, error }] = useAddExteriorColorMutation({
 *   variables: {
 *      team: // value for 'team'
 *      seriesId: // value for 'seriesId'
 *      modelYear: // value for 'modelYear'
 *      language: // value for 'language'
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      code: // value for 'code'
 *      hexCode: // value for 'hexCode'
 *      notes: // value for 'notes'
 *      isExtraCost: // value for 'isExtraCost'
 *      isInProgress: // value for 'isInProgress'
 *      colorApplicability: // value for 'colorApplicability'
 *   },
 * });
 */
export function useAddExteriorColorMutation(baseOptions?: Apollo.MutationHookOptions<AddExteriorColorMutation, AddExteriorColorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddExteriorColorMutation, AddExteriorColorMutationVariables>(AddExteriorColorDocument, options);
      }
export type AddExteriorColorMutationHookResult = ReturnType<typeof useAddExteriorColorMutation>;
export type AddExteriorColorMutationResult = Apollo.MutationResult<AddExteriorColorMutation>;
export type AddExteriorColorMutationOptions = Apollo.BaseMutationOptions<AddExteriorColorMutation, AddExteriorColorMutationVariables>;
export const AddInteriorColorDocument = gql`
    mutation addInteriorColor($team: String!, $seriesId: String!, $modelYear: Int!, $language: String!, $id: ID!, $name: String!, $code: String!, $isExtraCost: String!, $gradeApplicability: [String], $modelApplicability: [VehicleColoModelApplicabilityInput!]) {
  createVehicleColorInt(
    input: {team: $team, seriesId: $seriesId, modelYear: $modelYear, language: $language, id: $id, name: $name, code: $code, isExtraCost: $isExtraCost, gradeApplicability: $gradeApplicability, modelApplicability: $modelApplicability}
  ) {
    ...VehicleColorIntFields
  }
}
    ${VehicleColorIntFieldsFragmentDoc}`;
export type AddInteriorColorMutationFn = Apollo.MutationFunction<AddInteriorColorMutation, AddInteriorColorMutationVariables>;

/**
 * __useAddInteriorColorMutation__
 *
 * To run a mutation, you first call `useAddInteriorColorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddInteriorColorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addInteriorColorMutation, { data, loading, error }] = useAddInteriorColorMutation({
 *   variables: {
 *      team: // value for 'team'
 *      seriesId: // value for 'seriesId'
 *      modelYear: // value for 'modelYear'
 *      language: // value for 'language'
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      code: // value for 'code'
 *      isExtraCost: // value for 'isExtraCost'
 *      gradeApplicability: // value for 'gradeApplicability'
 *      modelApplicability: // value for 'modelApplicability'
 *   },
 * });
 */
export function useAddInteriorColorMutation(baseOptions?: Apollo.MutationHookOptions<AddInteriorColorMutation, AddInteriorColorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddInteriorColorMutation, AddInteriorColorMutationVariables>(AddInteriorColorDocument, options);
      }
export type AddInteriorColorMutationHookResult = ReturnType<typeof useAddInteriorColorMutation>;
export type AddInteriorColorMutationResult = Apollo.MutationResult<AddInteriorColorMutation>;
export type AddInteriorColorMutationOptions = Apollo.BaseMutationOptions<AddInteriorColorMutation, AddInteriorColorMutationVariables>;
export const DeleteExteriorColorDocument = gql`
    mutation deleteExteriorColor($team: String!, $seriesId: String!, $modelYear: Int!, $language: String!, $id: ID!) {
  deleteVehicleColorExt(
    input: {team: $team, seriesId: $seriesId, modelYear: $modelYear, language: $language, id: $id}
  ) {
    id
    message
  }
}
    `;
export type DeleteExteriorColorMutationFn = Apollo.MutationFunction<DeleteExteriorColorMutation, DeleteExteriorColorMutationVariables>;

/**
 * __useDeleteExteriorColorMutation__
 *
 * To run a mutation, you first call `useDeleteExteriorColorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteExteriorColorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteExteriorColorMutation, { data, loading, error }] = useDeleteExteriorColorMutation({
 *   variables: {
 *      team: // value for 'team'
 *      seriesId: // value for 'seriesId'
 *      modelYear: // value for 'modelYear'
 *      language: // value for 'language'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteExteriorColorMutation(baseOptions?: Apollo.MutationHookOptions<DeleteExteriorColorMutation, DeleteExteriorColorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteExteriorColorMutation, DeleteExteriorColorMutationVariables>(DeleteExteriorColorDocument, options);
      }
export type DeleteExteriorColorMutationHookResult = ReturnType<typeof useDeleteExteriorColorMutation>;
export type DeleteExteriorColorMutationResult = Apollo.MutationResult<DeleteExteriorColorMutation>;
export type DeleteExteriorColorMutationOptions = Apollo.BaseMutationOptions<DeleteExteriorColorMutation, DeleteExteriorColorMutationVariables>;
export const DeleteInteriorColorDocument = gql`
    mutation deleteInteriorColor($team: String!, $seriesId: String!, $modelYear: Int!, $language: String!, $id: ID!) {
  deleteVehicleColorInt(
    input: {team: $team, seriesId: $seriesId, modelYear: $modelYear, language: $language, id: $id}
  ) {
    id
    message
  }
}
    `;
export type DeleteInteriorColorMutationFn = Apollo.MutationFunction<DeleteInteriorColorMutation, DeleteInteriorColorMutationVariables>;

/**
 * __useDeleteInteriorColorMutation__
 *
 * To run a mutation, you first call `useDeleteInteriorColorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInteriorColorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInteriorColorMutation, { data, loading, error }] = useDeleteInteriorColorMutation({
 *   variables: {
 *      team: // value for 'team'
 *      seriesId: // value for 'seriesId'
 *      modelYear: // value for 'modelYear'
 *      language: // value for 'language'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteInteriorColorMutation(baseOptions?: Apollo.MutationHookOptions<DeleteInteriorColorMutation, DeleteInteriorColorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteInteriorColorMutation, DeleteInteriorColorMutationVariables>(DeleteInteriorColorDocument, options);
      }
export type DeleteInteriorColorMutationHookResult = ReturnType<typeof useDeleteInteriorColorMutation>;
export type DeleteInteriorColorMutationResult = Apollo.MutationResult<DeleteInteriorColorMutation>;
export type DeleteInteriorColorMutationOptions = Apollo.BaseMutationOptions<DeleteInteriorColorMutation, DeleteInteriorColorMutationVariables>;
export const GetChangeLogColorsDocument = gql`
    query getChangeLogColors($team: String!, $seriesId: ID!, $modelYear: Int!, $language: String!, $status: String, $version: Int) {
  vehicleColorsChanges(
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    language: $language
    status: $status
    version: $version
  ) {
    body
  }
}
    `;

/**
 * __useGetChangeLogColorsQuery__
 *
 * To run a query within a React component, call `useGetChangeLogColorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChangeLogColorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChangeLogColorsQuery({
 *   variables: {
 *      team: // value for 'team'
 *      seriesId: // value for 'seriesId'
 *      modelYear: // value for 'modelYear'
 *      language: // value for 'language'
 *      status: // value for 'status'
 *      version: // value for 'version'
 *   },
 * });
 */
export function useGetChangeLogColorsQuery(baseOptions: Apollo.QueryHookOptions<GetChangeLogColorsQuery, GetChangeLogColorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetChangeLogColorsQuery, GetChangeLogColorsQueryVariables>(GetChangeLogColorsDocument, options);
      }
export function useGetChangeLogColorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChangeLogColorsQuery, GetChangeLogColorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetChangeLogColorsQuery, GetChangeLogColorsQueryVariables>(GetChangeLogColorsDocument, options);
        }
export type GetChangeLogColorsQueryHookResult = ReturnType<typeof useGetChangeLogColorsQuery>;
export type GetChangeLogColorsLazyQueryHookResult = ReturnType<typeof useGetChangeLogColorsLazyQuery>;
export type GetChangeLogColorsQueryResult = Apollo.QueryResult<GetChangeLogColorsQuery, GetChangeLogColorsQueryVariables>;
export const GetExteriorColorsDocument = gql`
    query getExteriorColors($team: String!, $seriesId: ID!, $modelYear: Int!, $language: String!, $status: String, $version: Int) {
  vehicleColorsExt(
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    language: $language
    status: $status
    version: $version
  ) {
    vehicleColorsExt {
      ...VehicleColorExtFields
    }
  }
}
    ${VehicleColorExtFieldsFragmentDoc}`;

/**
 * __useGetExteriorColorsQuery__
 *
 * To run a query within a React component, call `useGetExteriorColorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExteriorColorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExteriorColorsQuery({
 *   variables: {
 *      team: // value for 'team'
 *      seriesId: // value for 'seriesId'
 *      modelYear: // value for 'modelYear'
 *      language: // value for 'language'
 *      status: // value for 'status'
 *      version: // value for 'version'
 *   },
 * });
 */
export function useGetExteriorColorsQuery(baseOptions: Apollo.QueryHookOptions<GetExteriorColorsQuery, GetExteriorColorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetExteriorColorsQuery, GetExteriorColorsQueryVariables>(GetExteriorColorsDocument, options);
      }
export function useGetExteriorColorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetExteriorColorsQuery, GetExteriorColorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetExteriorColorsQuery, GetExteriorColorsQueryVariables>(GetExteriorColorsDocument, options);
        }
export type GetExteriorColorsQueryHookResult = ReturnType<typeof useGetExteriorColorsQuery>;
export type GetExteriorColorsLazyQueryHookResult = ReturnType<typeof useGetExteriorColorsLazyQuery>;
export type GetExteriorColorsQueryResult = Apollo.QueryResult<GetExteriorColorsQuery, GetExteriorColorsQueryVariables>;
export const GetInteriorColorsDocument = gql`
    query getInteriorColors($team: String!, $seriesId: ID!, $modelYear: Int!, $language: String!, $status: String, $version: Int, $includeAll: Boolean) {
  vehicleColorsInt(
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    language: $language
    status: $status
    version: $version
    includeAll: $includeAll
  ) {
    vehicleColorsInt {
      ...VehicleColorIntFields
    }
  }
}
    ${VehicleColorIntFieldsFragmentDoc}`;

/**
 * __useGetInteriorColorsQuery__
 *
 * To run a query within a React component, call `useGetInteriorColorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInteriorColorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInteriorColorsQuery({
 *   variables: {
 *      team: // value for 'team'
 *      seriesId: // value for 'seriesId'
 *      modelYear: // value for 'modelYear'
 *      language: // value for 'language'
 *      status: // value for 'status'
 *      version: // value for 'version'
 *      includeAll: // value for 'includeAll'
 *   },
 * });
 */
export function useGetInteriorColorsQuery(baseOptions: Apollo.QueryHookOptions<GetInteriorColorsQuery, GetInteriorColorsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInteriorColorsQuery, GetInteriorColorsQueryVariables>(GetInteriorColorsDocument, options);
      }
export function useGetInteriorColorsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInteriorColorsQuery, GetInteriorColorsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInteriorColorsQuery, GetInteriorColorsQueryVariables>(GetInteriorColorsDocument, options);
        }
export type GetInteriorColorsQueryHookResult = ReturnType<typeof useGetInteriorColorsQuery>;
export type GetInteriorColorsLazyQueryHookResult = ReturnType<typeof useGetInteriorColorsLazyQuery>;
export type GetInteriorColorsQueryResult = Apollo.QueryResult<GetInteriorColorsQuery, GetInteriorColorsQueryVariables>;
export const RevertChangeLogColorsDocument = gql`
    mutation revertChangeLogColors($brand: String!, $team: String!, $seriesId: String!, $modelYear: Int!, $language: String!, $body: JSON!) {
  revertVehicleColorsChanges(
    input: {brand: $brand, team: $team, seriesId: $seriesId, modelYear: $modelYear, language: $language, body: $body}
  ) {
    body
  }
}
    `;
export type RevertChangeLogColorsMutationFn = Apollo.MutationFunction<RevertChangeLogColorsMutation, RevertChangeLogColorsMutationVariables>;

/**
 * __useRevertChangeLogColorsMutation__
 *
 * To run a mutation, you first call `useRevertChangeLogColorsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevertChangeLogColorsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revertChangeLogColorsMutation, { data, loading, error }] = useRevertChangeLogColorsMutation({
 *   variables: {
 *      brand: // value for 'brand'
 *      team: // value for 'team'
 *      seriesId: // value for 'seriesId'
 *      modelYear: // value for 'modelYear'
 *      language: // value for 'language'
 *      body: // value for 'body'
 *   },
 * });
 */
export function useRevertChangeLogColorsMutation(baseOptions?: Apollo.MutationHookOptions<RevertChangeLogColorsMutation, RevertChangeLogColorsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RevertChangeLogColorsMutation, RevertChangeLogColorsMutationVariables>(RevertChangeLogColorsDocument, options);
      }
export type RevertChangeLogColorsMutationHookResult = ReturnType<typeof useRevertChangeLogColorsMutation>;
export type RevertChangeLogColorsMutationResult = Apollo.MutationResult<RevertChangeLogColorsMutation>;
export type RevertChangeLogColorsMutationOptions = Apollo.BaseMutationOptions<RevertChangeLogColorsMutation, RevertChangeLogColorsMutationVariables>;
export const UpdateExteriorColorDocument = gql`
    mutation updateExteriorColor($team: String!, $seriesId: String!, $modelYear: Int!, $language: String!, $id: ID!, $revId: String!, $name: String!, $code: String!, $hexCode: String, $notes: String, $isExtraCost: String!, $isInProgress: Boolean!, $colorApplicability: [VehicleColoColorApplicabilityInput!], $acceptChanges: Boolean, $unlinkFromTMNA: Boolean) {
  updateVehicleColorExt(
    input: {team: $team, seriesId: $seriesId, modelYear: $modelYear, language: $language, id: $id, revId: $revId, name: $name, code: $code, hexCode: $hexCode, notes: $notes, isExtraCost: $isExtraCost, isInProgress: $isInProgress, colorApplicability: $colorApplicability, acceptChanges: $acceptChanges, unlinkFromTMNA: $unlinkFromTMNA}
  ) {
    ...VehicleColorExtFields
  }
}
    ${VehicleColorExtFieldsFragmentDoc}`;
export type UpdateExteriorColorMutationFn = Apollo.MutationFunction<UpdateExteriorColorMutation, UpdateExteriorColorMutationVariables>;

/**
 * __useUpdateExteriorColorMutation__
 *
 * To run a mutation, you first call `useUpdateExteriorColorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateExteriorColorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateExteriorColorMutation, { data, loading, error }] = useUpdateExteriorColorMutation({
 *   variables: {
 *      team: // value for 'team'
 *      seriesId: // value for 'seriesId'
 *      modelYear: // value for 'modelYear'
 *      language: // value for 'language'
 *      id: // value for 'id'
 *      revId: // value for 'revId'
 *      name: // value for 'name'
 *      code: // value for 'code'
 *      hexCode: // value for 'hexCode'
 *      notes: // value for 'notes'
 *      isExtraCost: // value for 'isExtraCost'
 *      isInProgress: // value for 'isInProgress'
 *      colorApplicability: // value for 'colorApplicability'
 *      acceptChanges: // value for 'acceptChanges'
 *      unlinkFromTMNA: // value for 'unlinkFromTMNA'
 *   },
 * });
 */
export function useUpdateExteriorColorMutation(baseOptions?: Apollo.MutationHookOptions<UpdateExteriorColorMutation, UpdateExteriorColorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateExteriorColorMutation, UpdateExteriorColorMutationVariables>(UpdateExteriorColorDocument, options);
      }
export type UpdateExteriorColorMutationHookResult = ReturnType<typeof useUpdateExteriorColorMutation>;
export type UpdateExteriorColorMutationResult = Apollo.MutationResult<UpdateExteriorColorMutation>;
export type UpdateExteriorColorMutationOptions = Apollo.BaseMutationOptions<UpdateExteriorColorMutation, UpdateExteriorColorMutationVariables>;
export const UpdateInteriorColorDocument = gql`
    mutation updateInteriorColor($team: String!, $seriesId: String!, $modelYear: Int!, $language: String!, $id: ID!, $revId: String!, $name: String!, $code: String!, $isExtraCost: String!, $gradeApplicability: [String], $modelApplicability: [VehicleColoModelApplicabilityInput!], $acceptChanges: Boolean, $unlinkFromTMNA: Boolean) {
  updateVehicleColorInt(
    input: {team: $team, seriesId: $seriesId, modelYear: $modelYear, language: $language, id: $id, revId: $revId, name: $name, code: $code, isExtraCost: $isExtraCost, gradeApplicability: $gradeApplicability, modelApplicability: $modelApplicability, acceptChanges: $acceptChanges, unlinkFromTMNA: $unlinkFromTMNA}
  ) {
    ...VehicleColorIntFields
  }
}
    ${VehicleColorIntFieldsFragmentDoc}`;
export type UpdateInteriorColorMutationFn = Apollo.MutationFunction<UpdateInteriorColorMutation, UpdateInteriorColorMutationVariables>;

/**
 * __useUpdateInteriorColorMutation__
 *
 * To run a mutation, you first call `useUpdateInteriorColorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInteriorColorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInteriorColorMutation, { data, loading, error }] = useUpdateInteriorColorMutation({
 *   variables: {
 *      team: // value for 'team'
 *      seriesId: // value for 'seriesId'
 *      modelYear: // value for 'modelYear'
 *      language: // value for 'language'
 *      id: // value for 'id'
 *      revId: // value for 'revId'
 *      name: // value for 'name'
 *      code: // value for 'code'
 *      isExtraCost: // value for 'isExtraCost'
 *      gradeApplicability: // value for 'gradeApplicability'
 *      modelApplicability: // value for 'modelApplicability'
 *      acceptChanges: // value for 'acceptChanges'
 *      unlinkFromTMNA: // value for 'unlinkFromTMNA'
 *   },
 * });
 */
export function useUpdateInteriorColorMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInteriorColorMutation, UpdateInteriorColorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInteriorColorMutation, UpdateInteriorColorMutationVariables>(UpdateInteriorColorDocument, options);
      }
export type UpdateInteriorColorMutationHookResult = ReturnType<typeof useUpdateInteriorColorMutation>;
export type UpdateInteriorColorMutationResult = Apollo.MutationResult<UpdateInteriorColorMutation>;
export type UpdateInteriorColorMutationOptions = Apollo.BaseMutationOptions<UpdateInteriorColorMutation, UpdateInteriorColorMutationVariables>;
export const AddCompareFeatureDocument = gql`
    mutation addCompareFeature($team: String!, $seriesId: String!, $modelYear: Int!, $language: String!, $id: String, $parentId: String, $compareType: VehicleCompareType, $categoryId: String!, $subCategoryId: String, $description: String!, $notes: String, $isInProgress: Boolean, $gradeApplicability: [VehicleFeatureGradeApplicabilityInput!]) {
  createVehicleFeatureCompare(
    input: {team: $team, seriesId: $seriesId, modelYear: $modelYear, language: $language, id: $id, parentId: $parentId, compareType: $compareType, categoryId: $categoryId, subCategoryId: $subCategoryId, description: $description, notes: $notes, isInProgress: $isInProgress, gradeApplicability: $gradeApplicability}
  ) {
    ...VehicleCompareFeatureFields
  }
}
    ${VehicleCompareFeatureFieldsFragmentDoc}`;
export type AddCompareFeatureMutationFn = Apollo.MutationFunction<AddCompareFeatureMutation, AddCompareFeatureMutationVariables>;

/**
 * __useAddCompareFeatureMutation__
 *
 * To run a mutation, you first call `useAddCompareFeatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCompareFeatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCompareFeatureMutation, { data, loading, error }] = useAddCompareFeatureMutation({
 *   variables: {
 *      team: // value for 'team'
 *      seriesId: // value for 'seriesId'
 *      modelYear: // value for 'modelYear'
 *      language: // value for 'language'
 *      id: // value for 'id'
 *      parentId: // value for 'parentId'
 *      compareType: // value for 'compareType'
 *      categoryId: // value for 'categoryId'
 *      subCategoryId: // value for 'subCategoryId'
 *      description: // value for 'description'
 *      notes: // value for 'notes'
 *      isInProgress: // value for 'isInProgress'
 *      gradeApplicability: // value for 'gradeApplicability'
 *   },
 * });
 */
export function useAddCompareFeatureMutation(baseOptions?: Apollo.MutationHookOptions<AddCompareFeatureMutation, AddCompareFeatureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCompareFeatureMutation, AddCompareFeatureMutationVariables>(AddCompareFeatureDocument, options);
      }
export type AddCompareFeatureMutationHookResult = ReturnType<typeof useAddCompareFeatureMutation>;
export type AddCompareFeatureMutationResult = Apollo.MutationResult<AddCompareFeatureMutation>;
export type AddCompareFeatureMutationOptions = Apollo.BaseMutationOptions<AddCompareFeatureMutation, AddCompareFeatureMutationVariables>;
export const DeleteCompareFeatureDocument = gql`
    mutation deleteCompareFeature($team: String!, $seriesId: String!, $modelYear: Int!, $language: String!, $id: ID!) {
  deleteVehicleFeatureCompare(
    input: {team: $team, seriesId: $seriesId, modelYear: $modelYear, language: $language, id: $id}
  ) {
    id
    message
  }
}
    `;
export type DeleteCompareFeatureMutationFn = Apollo.MutationFunction<DeleteCompareFeatureMutation, DeleteCompareFeatureMutationVariables>;

/**
 * __useDeleteCompareFeatureMutation__
 *
 * To run a mutation, you first call `useDeleteCompareFeatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompareFeatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompareFeatureMutation, { data, loading, error }] = useDeleteCompareFeatureMutation({
 *   variables: {
 *      team: // value for 'team'
 *      seriesId: // value for 'seriesId'
 *      modelYear: // value for 'modelYear'
 *      language: // value for 'language'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCompareFeatureMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCompareFeatureMutation, DeleteCompareFeatureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCompareFeatureMutation, DeleteCompareFeatureMutationVariables>(DeleteCompareFeatureDocument, options);
      }
export type DeleteCompareFeatureMutationHookResult = ReturnType<typeof useDeleteCompareFeatureMutation>;
export type DeleteCompareFeatureMutationResult = Apollo.MutationResult<DeleteCompareFeatureMutation>;
export type DeleteCompareFeatureMutationOptions = Apollo.BaseMutationOptions<DeleteCompareFeatureMutation, DeleteCompareFeatureMutationVariables>;
export const GetChangeLogCompareFeaturesDocument = gql`
    query getChangeLogCompareFeatures($team: String!, $seriesId: ID!, $modelYear: Int!, $language: String!, $status: String, $version: Int) {
  vehicleFeaturesCompareChanges(
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    language: $language
    status: $status
    version: $version
  ) {
    body
  }
}
    `;

/**
 * __useGetChangeLogCompareFeaturesQuery__
 *
 * To run a query within a React component, call `useGetChangeLogCompareFeaturesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChangeLogCompareFeaturesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChangeLogCompareFeaturesQuery({
 *   variables: {
 *      team: // value for 'team'
 *      seriesId: // value for 'seriesId'
 *      modelYear: // value for 'modelYear'
 *      language: // value for 'language'
 *      status: // value for 'status'
 *      version: // value for 'version'
 *   },
 * });
 */
export function useGetChangeLogCompareFeaturesQuery(baseOptions: Apollo.QueryHookOptions<GetChangeLogCompareFeaturesQuery, GetChangeLogCompareFeaturesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetChangeLogCompareFeaturesQuery, GetChangeLogCompareFeaturesQueryVariables>(GetChangeLogCompareFeaturesDocument, options);
      }
export function useGetChangeLogCompareFeaturesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChangeLogCompareFeaturesQuery, GetChangeLogCompareFeaturesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetChangeLogCompareFeaturesQuery, GetChangeLogCompareFeaturesQueryVariables>(GetChangeLogCompareFeaturesDocument, options);
        }
export type GetChangeLogCompareFeaturesQueryHookResult = ReturnType<typeof useGetChangeLogCompareFeaturesQuery>;
export type GetChangeLogCompareFeaturesLazyQueryHookResult = ReturnType<typeof useGetChangeLogCompareFeaturesLazyQuery>;
export type GetChangeLogCompareFeaturesQueryResult = Apollo.QueryResult<GetChangeLogCompareFeaturesQuery, GetChangeLogCompareFeaturesQueryVariables>;
export const GetCompareFeaturesDocument = gql`
    query getCompareFeatures($team: String!, $seriesId: ID!, $modelYear: Int!, $language: String!, $status: String, $version: Int) {
  vehicleFeaturesCompare(
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    language: $language
    status: $status
    version: $version
  ) {
    compareFeatures {
      ...VehicleCompareFeatureFields
    }
    doubleSortList {
      ...VehicleCompareDoubleSortListFields
    }
  }
}
    ${VehicleCompareFeatureFieldsFragmentDoc}
${VehicleCompareDoubleSortListFieldsFragmentDoc}`;

/**
 * __useGetCompareFeaturesQuery__
 *
 * To run a query within a React component, call `useGetCompareFeaturesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCompareFeaturesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCompareFeaturesQuery({
 *   variables: {
 *      team: // value for 'team'
 *      seriesId: // value for 'seriesId'
 *      modelYear: // value for 'modelYear'
 *      language: // value for 'language'
 *      status: // value for 'status'
 *      version: // value for 'version'
 *   },
 * });
 */
export function useGetCompareFeaturesQuery(baseOptions: Apollo.QueryHookOptions<GetCompareFeaturesQuery, GetCompareFeaturesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCompareFeaturesQuery, GetCompareFeaturesQueryVariables>(GetCompareFeaturesDocument, options);
      }
export function useGetCompareFeaturesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCompareFeaturesQuery, GetCompareFeaturesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCompareFeaturesQuery, GetCompareFeaturesQueryVariables>(GetCompareFeaturesDocument, options);
        }
export type GetCompareFeaturesQueryHookResult = ReturnType<typeof useGetCompareFeaturesQuery>;
export type GetCompareFeaturesLazyQueryHookResult = ReturnType<typeof useGetCompareFeaturesLazyQuery>;
export type GetCompareFeaturesQueryResult = Apollo.QueryResult<GetCompareFeaturesQuery, GetCompareFeaturesQueryVariables>;
export const RevertChangeLogCompareFeaturesDocument = gql`
    mutation revertChangeLogCompareFeatures($brand: String!, $team: String!, $seriesId: String!, $modelYear: Int!, $language: String!, $body: JSON!) {
  revertVehicleFeaturesCompareChanges(
    input: {brand: $brand, team: $team, seriesId: $seriesId, modelYear: $modelYear, language: $language, body: $body}
  ) {
    body
  }
}
    `;
export type RevertChangeLogCompareFeaturesMutationFn = Apollo.MutationFunction<RevertChangeLogCompareFeaturesMutation, RevertChangeLogCompareFeaturesMutationVariables>;

/**
 * __useRevertChangeLogCompareFeaturesMutation__
 *
 * To run a mutation, you first call `useRevertChangeLogCompareFeaturesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevertChangeLogCompareFeaturesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revertChangeLogCompareFeaturesMutation, { data, loading, error }] = useRevertChangeLogCompareFeaturesMutation({
 *   variables: {
 *      brand: // value for 'brand'
 *      team: // value for 'team'
 *      seriesId: // value for 'seriesId'
 *      modelYear: // value for 'modelYear'
 *      language: // value for 'language'
 *      body: // value for 'body'
 *   },
 * });
 */
export function useRevertChangeLogCompareFeaturesMutation(baseOptions?: Apollo.MutationHookOptions<RevertChangeLogCompareFeaturesMutation, RevertChangeLogCompareFeaturesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RevertChangeLogCompareFeaturesMutation, RevertChangeLogCompareFeaturesMutationVariables>(RevertChangeLogCompareFeaturesDocument, options);
      }
export type RevertChangeLogCompareFeaturesMutationHookResult = ReturnType<typeof useRevertChangeLogCompareFeaturesMutation>;
export type RevertChangeLogCompareFeaturesMutationResult = Apollo.MutationResult<RevertChangeLogCompareFeaturesMutation>;
export type RevertChangeLogCompareFeaturesMutationOptions = Apollo.BaseMutationOptions<RevertChangeLogCompareFeaturesMutation, RevertChangeLogCompareFeaturesMutationVariables>;
export const UpdateCompareFeatureDocument = gql`
    mutation updateCompareFeature($team: String!, $seriesId: String!, $modelYear: Int!, $language: String!, $id: String!, $revId: String!, $parentId: String, $compareType: VehicleCompareType, $categoryId: String!, $subCategoryId: String, $description: String!, $notes: String, $isInProgress: Boolean, $gradeApplicability: [VehicleFeatureGradeApplicabilityInput!], $acceptChanges: Boolean, $unlinkFromTMNA: Boolean) {
  updateVehicleFeatureCompare(
    input: {team: $team, seriesId: $seriesId, modelYear: $modelYear, language: $language, id: $id, revId: $revId, parentId: $parentId, compareType: $compareType, categoryId: $categoryId, subCategoryId: $subCategoryId, description: $description, notes: $notes, isInProgress: $isInProgress, gradeApplicability: $gradeApplicability, acceptChanges: $acceptChanges, unlinkFromTMNA: $unlinkFromTMNA}
  ) {
    ...VehicleCompareFeatureFields
  }
}
    ${VehicleCompareFeatureFieldsFragmentDoc}`;
export type UpdateCompareFeatureMutationFn = Apollo.MutationFunction<UpdateCompareFeatureMutation, UpdateCompareFeatureMutationVariables>;

/**
 * __useUpdateCompareFeatureMutation__
 *
 * To run a mutation, you first call `useUpdateCompareFeatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompareFeatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompareFeatureMutation, { data, loading, error }] = useUpdateCompareFeatureMutation({
 *   variables: {
 *      team: // value for 'team'
 *      seriesId: // value for 'seriesId'
 *      modelYear: // value for 'modelYear'
 *      language: // value for 'language'
 *      id: // value for 'id'
 *      revId: // value for 'revId'
 *      parentId: // value for 'parentId'
 *      compareType: // value for 'compareType'
 *      categoryId: // value for 'categoryId'
 *      subCategoryId: // value for 'subCategoryId'
 *      description: // value for 'description'
 *      notes: // value for 'notes'
 *      isInProgress: // value for 'isInProgress'
 *      gradeApplicability: // value for 'gradeApplicability'
 *      acceptChanges: // value for 'acceptChanges'
 *      unlinkFromTMNA: // value for 'unlinkFromTMNA'
 *   },
 * });
 */
export function useUpdateCompareFeatureMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompareFeatureMutation, UpdateCompareFeatureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCompareFeatureMutation, UpdateCompareFeatureMutationVariables>(UpdateCompareFeatureDocument, options);
      }
export type UpdateCompareFeatureMutationHookResult = ReturnType<typeof useUpdateCompareFeatureMutation>;
export type UpdateCompareFeatureMutationResult = Apollo.MutationResult<UpdateCompareFeatureMutation>;
export type UpdateCompareFeatureMutationOptions = Apollo.BaseMutationOptions<UpdateCompareFeatureMutation, UpdateCompareFeatureMutationVariables>;
export const UpdateDoubleSortListDocument = gql`
    mutation updateDoubleSortList($team: String!, $seriesId: String!, $modelYear: Int!, $language: String!, $doubleSortList: [UpdateVehicleFeatureHighlightSortListDoubleSortItemInput!]!) {
  updateVehicleFeatureHighlightSortList(
    input: {team: $team, seriesId: $seriesId, modelYear: $modelYear, language: $language, doubleSortList: $doubleSortList}
  ) {
    doubleSortList {
      id
      sortList {
        id
        position
      }
    }
  }
}
    `;
export type UpdateDoubleSortListMutationFn = Apollo.MutationFunction<UpdateDoubleSortListMutation, UpdateDoubleSortListMutationVariables>;

/**
 * __useUpdateDoubleSortListMutation__
 *
 * To run a mutation, you first call `useUpdateDoubleSortListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDoubleSortListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDoubleSortListMutation, { data, loading, error }] = useUpdateDoubleSortListMutation({
 *   variables: {
 *      team: // value for 'team'
 *      seriesId: // value for 'seriesId'
 *      modelYear: // value for 'modelYear'
 *      language: // value for 'language'
 *      doubleSortList: // value for 'doubleSortList'
 *   },
 * });
 */
export function useUpdateDoubleSortListMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDoubleSortListMutation, UpdateDoubleSortListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDoubleSortListMutation, UpdateDoubleSortListMutationVariables>(UpdateDoubleSortListDocument, options);
      }
export type UpdateDoubleSortListMutationHookResult = ReturnType<typeof useUpdateDoubleSortListMutation>;
export type UpdateDoubleSortListMutationResult = Apollo.MutationResult<UpdateDoubleSortListMutation>;
export type UpdateDoubleSortListMutationOptions = Apollo.BaseMutationOptions<UpdateDoubleSortListMutation, UpdateDoubleSortListMutationVariables>;
export const DeleteDraftDocument = gql`
    mutation deleteDraft($brand: String!, $team: String!, $seriesId: String!, $modelYear: Int!, $language: String!) {
  deleteVehicleDocument(
    input: {brand: $brand, team: $team, seriesId: $seriesId, modelYear: $modelYear, language: $language}
  ) {
    message
  }
}
    `;
export type DeleteDraftMutationFn = Apollo.MutationFunction<DeleteDraftMutation, DeleteDraftMutationVariables>;

/**
 * __useDeleteDraftMutation__
 *
 * To run a mutation, you first call `useDeleteDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDraftMutation, { data, loading, error }] = useDeleteDraftMutation({
 *   variables: {
 *      brand: // value for 'brand'
 *      team: // value for 'team'
 *      seriesId: // value for 'seriesId'
 *      modelYear: // value for 'modelYear'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useDeleteDraftMutation(baseOptions?: Apollo.MutationHookOptions<DeleteDraftMutation, DeleteDraftMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteDraftMutation, DeleteDraftMutationVariables>(DeleteDraftDocument, options);
      }
export type DeleteDraftMutationHookResult = ReturnType<typeof useDeleteDraftMutation>;
export type DeleteDraftMutationResult = Apollo.MutationResult<DeleteDraftMutation>;
export type DeleteDraftMutationOptions = Apollo.BaseMutationOptions<DeleteDraftMutation, DeleteDraftMutationVariables>;
export const DuplicateVehicleDataDocument = gql`
    mutation duplicateVehicleData($brand: String!, $team: String!, $seriesId: String!, $fromModelYear: Int!, $toModelYear: Int!, $language: String!) {
  duplicateVehicleDocument(
    input: {brand: $brand, team: $team, seriesId: $seriesId, fromModelYear: $fromModelYear, toModelYear: $toModelYear, language: $language}
  ) {
    ...VehicleDocumentFields
  }
}
    ${VehicleDocumentFieldsFragmentDoc}`;
export type DuplicateVehicleDataMutationFn = Apollo.MutationFunction<DuplicateVehicleDataMutation, DuplicateVehicleDataMutationVariables>;

/**
 * __useDuplicateVehicleDataMutation__
 *
 * To run a mutation, you first call `useDuplicateVehicleDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDuplicateVehicleDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [duplicateVehicleDataMutation, { data, loading, error }] = useDuplicateVehicleDataMutation({
 *   variables: {
 *      brand: // value for 'brand'
 *      team: // value for 'team'
 *      seriesId: // value for 'seriesId'
 *      fromModelYear: // value for 'fromModelYear'
 *      toModelYear: // value for 'toModelYear'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useDuplicateVehicleDataMutation(baseOptions?: Apollo.MutationHookOptions<DuplicateVehicleDataMutation, DuplicateVehicleDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DuplicateVehicleDataMutation, DuplicateVehicleDataMutationVariables>(DuplicateVehicleDataDocument, options);
      }
export type DuplicateVehicleDataMutationHookResult = ReturnType<typeof useDuplicateVehicleDataMutation>;
export type DuplicateVehicleDataMutationResult = Apollo.MutationResult<DuplicateVehicleDataMutation>;
export type DuplicateVehicleDataMutationOptions = Apollo.BaseMutationOptions<DuplicateVehicleDataMutation, DuplicateVehicleDataMutationVariables>;
export const EditVehicleDataDocument = gql`
    mutation editVehicleData($brand: String!, $team: String!, $seriesId: String!, $modelYear: Int!, $language: String!) {
  updateVehicleDocument(
    input: {brand: $brand, team: $team, seriesId: $seriesId, modelYear: $modelYear, language: $language}
  ) {
    ...VehicleDocumentFields
  }
}
    ${VehicleDocumentFieldsFragmentDoc}`;
export type EditVehicleDataMutationFn = Apollo.MutationFunction<EditVehicleDataMutation, EditVehicleDataMutationVariables>;

/**
 * __useEditVehicleDataMutation__
 *
 * To run a mutation, you first call `useEditVehicleDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditVehicleDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editVehicleDataMutation, { data, loading, error }] = useEditVehicleDataMutation({
 *   variables: {
 *      brand: // value for 'brand'
 *      team: // value for 'team'
 *      seriesId: // value for 'seriesId'
 *      modelYear: // value for 'modelYear'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useEditVehicleDataMutation(baseOptions?: Apollo.MutationHookOptions<EditVehicleDataMutation, EditVehicleDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditVehicleDataMutation, EditVehicleDataMutationVariables>(EditVehicleDataDocument, options);
      }
export type EditVehicleDataMutationHookResult = ReturnType<typeof useEditVehicleDataMutation>;
export type EditVehicleDataMutationResult = Apollo.MutationResult<EditVehicleDataMutation>;
export type EditVehicleDataMutationOptions = Apollo.BaseMutationOptions<EditVehicleDataMutation, EditVehicleDataMutationVariables>;
export const GetDashboardDocument = gql`
    query getDashboard($brand: String!, $team: String!, $lang: String!) {
  dashboard(brand: $brand, team: $team, lang: $lang) {
    series {
      ...DashboardFields
    }
  }
}
    ${DashboardFieldsFragmentDoc}`;

/**
 * __useGetDashboardQuery__
 *
 * To run a query within a React component, call `useGetDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDashboardQuery({
 *   variables: {
 *      brand: // value for 'brand'
 *      team: // value for 'team'
 *      lang: // value for 'lang'
 *   },
 * });
 */
export function useGetDashboardQuery(baseOptions: Apollo.QueryHookOptions<GetDashboardQuery, GetDashboardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDashboardQuery, GetDashboardQueryVariables>(GetDashboardDocument, options);
      }
export function useGetDashboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDashboardQuery, GetDashboardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDashboardQuery, GetDashboardQueryVariables>(GetDashboardDocument, options);
        }
export type GetDashboardQueryHookResult = ReturnType<typeof useGetDashboardQuery>;
export type GetDashboardLazyQueryHookResult = ReturnType<typeof useGetDashboardLazyQuery>;
export type GetDashboardQueryResult = Apollo.QueryResult<GetDashboardQuery, GetDashboardQueryVariables>;
export const GetVehicleDataDocument = gql`
    query getVehicleData($team: String!, $seriesId: ID!, $modelYear: Int!, $language: String!, $status: String, $version: Int) {
  vehicleDocument(
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    language: $language
    status: $status
    version: $version
  ) {
    ...VehicleDocumentFields
  }
}
    ${VehicleDocumentFieldsFragmentDoc}`;

/**
 * __useGetVehicleDataQuery__
 *
 * To run a query within a React component, call `useGetVehicleDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVehicleDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVehicleDataQuery({
 *   variables: {
 *      team: // value for 'team'
 *      seriesId: // value for 'seriesId'
 *      modelYear: // value for 'modelYear'
 *      language: // value for 'language'
 *      status: // value for 'status'
 *      version: // value for 'version'
 *   },
 * });
 */
export function useGetVehicleDataQuery(baseOptions: Apollo.QueryHookOptions<GetVehicleDataQuery, GetVehicleDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVehicleDataQuery, GetVehicleDataQueryVariables>(GetVehicleDataDocument, options);
      }
export function useGetVehicleDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVehicleDataQuery, GetVehicleDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVehicleDataQuery, GetVehicleDataQueryVariables>(GetVehicleDataDocument, options);
        }
export type GetVehicleDataQueryHookResult = ReturnType<typeof useGetVehicleDataQuery>;
export type GetVehicleDataLazyQueryHookResult = ReturnType<typeof useGetVehicleDataLazyQuery>;
export type GetVehicleDataQueryResult = Apollo.QueryResult<GetVehicleDataQuery, GetVehicleDataQueryVariables>;
export const PublishDraftDocument = gql`
    mutation publishDraft($brand: String!, $seriesId: String!, $modelYear: Int!, $language: String!, $publishType: String!, $republish: Boolean!) {
  publishVehicleDocument(
    input: {brand: $brand, seriesId: $seriesId, modelYear: $modelYear, language: $language, publishType: $publishType, republish: $republish}
  )
}
    `;
export type PublishDraftMutationFn = Apollo.MutationFunction<PublishDraftMutation, PublishDraftMutationVariables>;

/**
 * __usePublishDraftMutation__
 *
 * To run a mutation, you first call `usePublishDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishDraftMutation, { data, loading, error }] = usePublishDraftMutation({
 *   variables: {
 *      brand: // value for 'brand'
 *      seriesId: // value for 'seriesId'
 *      modelYear: // value for 'modelYear'
 *      language: // value for 'language'
 *      publishType: // value for 'publishType'
 *      republish: // value for 'republish'
 *   },
 * });
 */
export function usePublishDraftMutation(baseOptions?: Apollo.MutationHookOptions<PublishDraftMutation, PublishDraftMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PublishDraftMutation, PublishDraftMutationVariables>(PublishDraftDocument, options);
      }
export type PublishDraftMutationHookResult = ReturnType<typeof usePublishDraftMutation>;
export type PublishDraftMutationResult = Apollo.MutationResult<PublishDraftMutation>;
export type PublishDraftMutationOptions = Apollo.BaseMutationOptions<PublishDraftMutation, PublishDraftMutationVariables>;
export const ResetDraftDocument = gql`
    mutation resetDraft($team: String!, $seriesId: String!, $modelYear: Int!, $language: String!) {
  resetVehicleDocumentDraft(
    input: {team: $team, seriesId: $seriesId, modelYear: $modelYear, language: $language}
  )
}
    `;
export type ResetDraftMutationFn = Apollo.MutationFunction<ResetDraftMutation, ResetDraftMutationVariables>;

/**
 * __useResetDraftMutation__
 *
 * To run a mutation, you first call `useResetDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetDraftMutation, { data, loading, error }] = useResetDraftMutation({
 *   variables: {
 *      team: // value for 'team'
 *      seriesId: // value for 'seriesId'
 *      modelYear: // value for 'modelYear'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useResetDraftMutation(baseOptions?: Apollo.MutationHookOptions<ResetDraftMutation, ResetDraftMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetDraftMutation, ResetDraftMutationVariables>(ResetDraftDocument, options);
      }
export type ResetDraftMutationHookResult = ReturnType<typeof useResetDraftMutation>;
export type ResetDraftMutationResult = Apollo.MutationResult<ResetDraftMutation>;
export type ResetDraftMutationOptions = Apollo.BaseMutationOptions<ResetDraftMutation, ResetDraftMutationVariables>;
export const ResetDraftCountDocument = gql`
    query resetDraftCount($team: String!, $seriesId: ID!, $modelYear: Int!) {
  vehicleDocumentResetDraftCount(
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
  )
}
    `;

/**
 * __useResetDraftCountQuery__
 *
 * To run a query within a React component, call `useResetDraftCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useResetDraftCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResetDraftCountQuery({
 *   variables: {
 *      team: // value for 'team'
 *      seriesId: // value for 'seriesId'
 *      modelYear: // value for 'modelYear'
 *   },
 * });
 */
export function useResetDraftCountQuery(baseOptions: Apollo.QueryHookOptions<ResetDraftCountQuery, ResetDraftCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ResetDraftCountQuery, ResetDraftCountQueryVariables>(ResetDraftCountDocument, options);
      }
export function useResetDraftCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ResetDraftCountQuery, ResetDraftCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ResetDraftCountQuery, ResetDraftCountQueryVariables>(ResetDraftCountDocument, options);
        }
export type ResetDraftCountQueryHookResult = ReturnType<typeof useResetDraftCountQuery>;
export type ResetDraftCountLazyQueryHookResult = ReturnType<typeof useResetDraftCountLazyQuery>;
export type ResetDraftCountQueryResult = Apollo.QueryResult<ResetDraftCountQuery, ResetDraftCountQueryVariables>;
export const SubmitDraftDocument = gql`
    mutation submitDraft($brand: String!, $seriesId: String!, $modelYear: Int!, $language: String!) {
  certifyVehicleDocument(
    input: {brand: $brand, seriesId: $seriesId, modelYear: $modelYear, language: $language}
  )
}
    `;
export type SubmitDraftMutationFn = Apollo.MutationFunction<SubmitDraftMutation, SubmitDraftMutationVariables>;

/**
 * __useSubmitDraftMutation__
 *
 * To run a mutation, you first call `useSubmitDraftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitDraftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitDraftMutation, { data, loading, error }] = useSubmitDraftMutation({
 *   variables: {
 *      brand: // value for 'brand'
 *      seriesId: // value for 'seriesId'
 *      modelYear: // value for 'modelYear'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useSubmitDraftMutation(baseOptions?: Apollo.MutationHookOptions<SubmitDraftMutation, SubmitDraftMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitDraftMutation, SubmitDraftMutationVariables>(SubmitDraftDocument, options);
      }
export type SubmitDraftMutationHookResult = ReturnType<typeof useSubmitDraftMutation>;
export type SubmitDraftMutationResult = Apollo.MutationResult<SubmitDraftMutation>;
export type SubmitDraftMutationOptions = Apollo.BaseMutationOptions<SubmitDraftMutation, SubmitDraftMutationVariables>;
export const SyncUpdatesDocument = gql`
    mutation syncUpdates($team: String!, $seriesId: String!, $modelYear: Int!) {
  vehicleSync(input: {team: $team, seriesId: $seriesId, modelYear: $modelYear}) {
    body
  }
}
    `;
export type SyncUpdatesMutationFn = Apollo.MutationFunction<SyncUpdatesMutation, SyncUpdatesMutationVariables>;

/**
 * __useSyncUpdatesMutation__
 *
 * To run a mutation, you first call `useSyncUpdatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSyncUpdatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [syncUpdatesMutation, { data, loading, error }] = useSyncUpdatesMutation({
 *   variables: {
 *      team: // value for 'team'
 *      seriesId: // value for 'seriesId'
 *      modelYear: // value for 'modelYear'
 *   },
 * });
 */
export function useSyncUpdatesMutation(baseOptions?: Apollo.MutationHookOptions<SyncUpdatesMutation, SyncUpdatesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SyncUpdatesMutation, SyncUpdatesMutationVariables>(SyncUpdatesDocument, options);
      }
export type SyncUpdatesMutationHookResult = ReturnType<typeof useSyncUpdatesMutation>;
export type SyncUpdatesMutationResult = Apollo.MutationResult<SyncUpdatesMutation>;
export type SyncUpdatesMutationOptions = Apollo.BaseMutationOptions<SyncUpdatesMutation, SyncUpdatesMutationVariables>;
export const VehicleCompareDocument = gql`
    query vehicleCompare($team: String!, $seriesId: ID!, $modelYear: Int!, $entityType: String!, $itemId: String!, $parentSeriesId: String) {
  vehicleCompare(
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    entityType: $entityType
    itemId: $itemId
    parentSeriesId: $parentSeriesId
  ) {
    body
  }
}
    `;

/**
 * __useVehicleCompareQuery__
 *
 * To run a query within a React component, call `useVehicleCompareQuery` and pass it any options that fit your needs.
 * When your component renders, `useVehicleCompareQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVehicleCompareQuery({
 *   variables: {
 *      team: // value for 'team'
 *      seriesId: // value for 'seriesId'
 *      modelYear: // value for 'modelYear'
 *      entityType: // value for 'entityType'
 *      itemId: // value for 'itemId'
 *      parentSeriesId: // value for 'parentSeriesId'
 *   },
 * });
 */
export function useVehicleCompareQuery(baseOptions: Apollo.QueryHookOptions<VehicleCompareQuery, VehicleCompareQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VehicleCompareQuery, VehicleCompareQueryVariables>(VehicleCompareDocument, options);
      }
export function useVehicleCompareLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VehicleCompareQuery, VehicleCompareQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VehicleCompareQuery, VehicleCompareQueryVariables>(VehicleCompareDocument, options);
        }
export type VehicleCompareQueryHookResult = ReturnType<typeof useVehicleCompareQuery>;
export type VehicleCompareLazyQueryHookResult = ReturnType<typeof useVehicleCompareLazyQuery>;
export type VehicleCompareQueryResult = Apollo.QueryResult<VehicleCompareQuery, VehicleCompareQueryVariables>;
export const VehicleVersionsDocument = gql`
    query vehicleVersions($team: String!, $seriesId: ID!, $modelYear: Int!, $language: String!) {
  vehicleVersions(
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    language: $language
  ) {
    seriesId
    modelYear
    version
    publishedBy
    publishedDate
    lastResetDate
    isSubmitted
  }
}
    `;

/**
 * __useVehicleVersionsQuery__
 *
 * To run a query within a React component, call `useVehicleVersionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVehicleVersionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVehicleVersionsQuery({
 *   variables: {
 *      team: // value for 'team'
 *      seriesId: // value for 'seriesId'
 *      modelYear: // value for 'modelYear'
 *      language: // value for 'language'
 *   },
 * });
 */
export function useVehicleVersionsQuery(baseOptions: Apollo.QueryHookOptions<VehicleVersionsQuery, VehicleVersionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VehicleVersionsQuery, VehicleVersionsQueryVariables>(VehicleVersionsDocument, options);
      }
export function useVehicleVersionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VehicleVersionsQuery, VehicleVersionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VehicleVersionsQuery, VehicleVersionsQueryVariables>(VehicleVersionsDocument, options);
        }
export type VehicleVersionsQueryHookResult = ReturnType<typeof useVehicleVersionsQuery>;
export type VehicleVersionsLazyQueryHookResult = ReturnType<typeof useVehicleVersionsLazyQuery>;
export type VehicleVersionsQueryResult = Apollo.QueryResult<VehicleVersionsQuery, VehicleVersionsQueryVariables>;
export const GetDisclaimerTokensDocument = gql`
    query getDisclaimerTokens($brand: String!) {
  disclaimersTokens(brand: $brand)
}
    `;

/**
 * __useGetDisclaimerTokensQuery__
 *
 * To run a query within a React component, call `useGetDisclaimerTokensQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDisclaimerTokensQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDisclaimerTokensQuery({
 *   variables: {
 *      brand: // value for 'brand'
 *   },
 * });
 */
export function useGetDisclaimerTokensQuery(baseOptions: Apollo.QueryHookOptions<GetDisclaimerTokensQuery, GetDisclaimerTokensQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDisclaimerTokensQuery, GetDisclaimerTokensQueryVariables>(GetDisclaimerTokensDocument, options);
      }
export function useGetDisclaimerTokensLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDisclaimerTokensQuery, GetDisclaimerTokensQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDisclaimerTokensQuery, GetDisclaimerTokensQueryVariables>(GetDisclaimerTokensDocument, options);
        }
export type GetDisclaimerTokensQueryHookResult = ReturnType<typeof useGetDisclaimerTokensQuery>;
export type GetDisclaimerTokensLazyQueryHookResult = ReturnType<typeof useGetDisclaimerTokensLazyQuery>;
export type GetDisclaimerTokensQueryResult = Apollo.QueryResult<GetDisclaimerTokensQuery, GetDisclaimerTokensQueryVariables>;
export const AddFeatureDocument = gql`
    mutation addFeature($team: String!, $seriesId: String!, $modelYear: Int!, $language: String!, $id: String, $categoryId: String!, $subCategoryId: String!, $comLangId: String, $description: String!, $shortDescription: String, $link: String, $notes: String, $isInProgress: Boolean, $isHighlighted: Boolean, $modelApplicability: [VehicleFeatureModelApplicabilityInput!]) {
  createVehicleFeature(
    input: {team: $team, seriesId: $seriesId, modelYear: $modelYear, language: $language, id: $id, categoryId: $categoryId, subCategoryId: $subCategoryId, comLangId: $comLangId, description: $description, shortDescription: $shortDescription, link: $link, notes: $notes, isInProgress: $isInProgress, isHighlighted: $isHighlighted, modelApplicability: $modelApplicability}
  ) {
    ...VehicleFeatureFields
  }
}
    ${VehicleFeatureFieldsFragmentDoc}`;
export type AddFeatureMutationFn = Apollo.MutationFunction<AddFeatureMutation, AddFeatureMutationVariables>;

/**
 * __useAddFeatureMutation__
 *
 * To run a mutation, you first call `useAddFeatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddFeatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addFeatureMutation, { data, loading, error }] = useAddFeatureMutation({
 *   variables: {
 *      team: // value for 'team'
 *      seriesId: // value for 'seriesId'
 *      modelYear: // value for 'modelYear'
 *      language: // value for 'language'
 *      id: // value for 'id'
 *      categoryId: // value for 'categoryId'
 *      subCategoryId: // value for 'subCategoryId'
 *      comLangId: // value for 'comLangId'
 *      description: // value for 'description'
 *      shortDescription: // value for 'shortDescription'
 *      link: // value for 'link'
 *      notes: // value for 'notes'
 *      isInProgress: // value for 'isInProgress'
 *      isHighlighted: // value for 'isHighlighted'
 *      modelApplicability: // value for 'modelApplicability'
 *   },
 * });
 */
export function useAddFeatureMutation(baseOptions?: Apollo.MutationHookOptions<AddFeatureMutation, AddFeatureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddFeatureMutation, AddFeatureMutationVariables>(AddFeatureDocument, options);
      }
export type AddFeatureMutationHookResult = ReturnType<typeof useAddFeatureMutation>;
export type AddFeatureMutationResult = Apollo.MutationResult<AddFeatureMutation>;
export type AddFeatureMutationOptions = Apollo.BaseMutationOptions<AddFeatureMutation, AddFeatureMutationVariables>;
export const DeleteFeatureDocument = gql`
    mutation deleteFeature($team: String!, $seriesId: String!, $modelYear: Int!, $language: String!, $id: ID!) {
  deleteVehicleFeature(
    input: {team: $team, seriesId: $seriesId, modelYear: $modelYear, language: $language, id: $id}
  ) {
    id
    message
  }
}
    `;
export type DeleteFeatureMutationFn = Apollo.MutationFunction<DeleteFeatureMutation, DeleteFeatureMutationVariables>;

/**
 * __useDeleteFeatureMutation__
 *
 * To run a mutation, you first call `useDeleteFeatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFeatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFeatureMutation, { data, loading, error }] = useDeleteFeatureMutation({
 *   variables: {
 *      team: // value for 'team'
 *      seriesId: // value for 'seriesId'
 *      modelYear: // value for 'modelYear'
 *      language: // value for 'language'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteFeatureMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFeatureMutation, DeleteFeatureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFeatureMutation, DeleteFeatureMutationVariables>(DeleteFeatureDocument, options);
      }
export type DeleteFeatureMutationHookResult = ReturnType<typeof useDeleteFeatureMutation>;
export type DeleteFeatureMutationResult = Apollo.MutationResult<DeleteFeatureMutation>;
export type DeleteFeatureMutationOptions = Apollo.BaseMutationOptions<DeleteFeatureMutation, DeleteFeatureMutationVariables>;
export const GetChangeLogFeaturesDocument = gql`
    query getChangeLogFeatures($team: String!, $seriesId: ID!, $modelYear: Int!, $language: String!, $status: String, $version: Int) {
  vehicleFeaturesChanges(
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    language: $language
    status: $status
    version: $version
  ) {
    body
  }
}
    `;

/**
 * __useGetChangeLogFeaturesQuery__
 *
 * To run a query within a React component, call `useGetChangeLogFeaturesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChangeLogFeaturesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChangeLogFeaturesQuery({
 *   variables: {
 *      team: // value for 'team'
 *      seriesId: // value for 'seriesId'
 *      modelYear: // value for 'modelYear'
 *      language: // value for 'language'
 *      status: // value for 'status'
 *      version: // value for 'version'
 *   },
 * });
 */
export function useGetChangeLogFeaturesQuery(baseOptions: Apollo.QueryHookOptions<GetChangeLogFeaturesQuery, GetChangeLogFeaturesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetChangeLogFeaturesQuery, GetChangeLogFeaturesQueryVariables>(GetChangeLogFeaturesDocument, options);
      }
export function useGetChangeLogFeaturesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChangeLogFeaturesQuery, GetChangeLogFeaturesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetChangeLogFeaturesQuery, GetChangeLogFeaturesQueryVariables>(GetChangeLogFeaturesDocument, options);
        }
export type GetChangeLogFeaturesQueryHookResult = ReturnType<typeof useGetChangeLogFeaturesQuery>;
export type GetChangeLogFeaturesLazyQueryHookResult = ReturnType<typeof useGetChangeLogFeaturesLazyQuery>;
export type GetChangeLogFeaturesQueryResult = Apollo.QueryResult<GetChangeLogFeaturesQuery, GetChangeLogFeaturesQueryVariables>;
export const GetFeaturesDocument = gql`
    query getFeatures($team: String!, $seriesId: ID!, $modelYear: Int!, $language: String!, $status: String, $version: Int) {
  vehicleFeatures(
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    language: $language
    status: $status
    version: $version
  ) {
    vehicleFeatures {
      ...VehicleFeatureFields
    }
  }
}
    ${VehicleFeatureFieldsFragmentDoc}`;

/**
 * __useGetFeaturesQuery__
 *
 * To run a query within a React component, call `useGetFeaturesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeaturesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeaturesQuery({
 *   variables: {
 *      team: // value for 'team'
 *      seriesId: // value for 'seriesId'
 *      modelYear: // value for 'modelYear'
 *      language: // value for 'language'
 *      status: // value for 'status'
 *      version: // value for 'version'
 *   },
 * });
 */
export function useGetFeaturesQuery(baseOptions: Apollo.QueryHookOptions<GetFeaturesQuery, GetFeaturesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFeaturesQuery, GetFeaturesQueryVariables>(GetFeaturesDocument, options);
      }
export function useGetFeaturesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFeaturesQuery, GetFeaturesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFeaturesQuery, GetFeaturesQueryVariables>(GetFeaturesDocument, options);
        }
export type GetFeaturesQueryHookResult = ReturnType<typeof useGetFeaturesQuery>;
export type GetFeaturesLazyQueryHookResult = ReturnType<typeof useGetFeaturesLazyQuery>;
export type GetFeaturesQueryResult = Apollo.QueryResult<GetFeaturesQuery, GetFeaturesQueryVariables>;
export const RevertChangeLogFeaturesDocument = gql`
    mutation revertChangeLogFeatures($brand: String!, $team: String!, $seriesId: String!, $modelYear: Int!, $language: String!, $body: JSON!) {
  revertVehicleFeaturesChanges(
    input: {brand: $brand, team: $team, seriesId: $seriesId, modelYear: $modelYear, language: $language, body: $body}
  ) {
    body
  }
}
    `;
export type RevertChangeLogFeaturesMutationFn = Apollo.MutationFunction<RevertChangeLogFeaturesMutation, RevertChangeLogFeaturesMutationVariables>;

/**
 * __useRevertChangeLogFeaturesMutation__
 *
 * To run a mutation, you first call `useRevertChangeLogFeaturesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevertChangeLogFeaturesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revertChangeLogFeaturesMutation, { data, loading, error }] = useRevertChangeLogFeaturesMutation({
 *   variables: {
 *      brand: // value for 'brand'
 *      team: // value for 'team'
 *      seriesId: // value for 'seriesId'
 *      modelYear: // value for 'modelYear'
 *      language: // value for 'language'
 *      body: // value for 'body'
 *   },
 * });
 */
export function useRevertChangeLogFeaturesMutation(baseOptions?: Apollo.MutationHookOptions<RevertChangeLogFeaturesMutation, RevertChangeLogFeaturesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RevertChangeLogFeaturesMutation, RevertChangeLogFeaturesMutationVariables>(RevertChangeLogFeaturesDocument, options);
      }
export type RevertChangeLogFeaturesMutationHookResult = ReturnType<typeof useRevertChangeLogFeaturesMutation>;
export type RevertChangeLogFeaturesMutationResult = Apollo.MutationResult<RevertChangeLogFeaturesMutation>;
export type RevertChangeLogFeaturesMutationOptions = Apollo.BaseMutationOptions<RevertChangeLogFeaturesMutation, RevertChangeLogFeaturesMutationVariables>;
export const UpdateFeatureDocument = gql`
    mutation updateFeature($team: String!, $seriesId: String!, $modelYear: Int!, $language: String!, $id: ID!, $revId: String!, $categoryId: String!, $subCategoryId: String!, $comLangId: String, $description: String!, $shortDescription: String, $acceptChanges: Boolean, $link: String, $notes: String, $isInProgress: Boolean, $isHighlighted: Boolean, $modelApplicability: [VehicleFeatureModelApplicabilityInput!], $unlinkFromTMNA: Boolean) {
  updateVehicleFeature(
    input: {team: $team, seriesId: $seriesId, modelYear: $modelYear, language: $language, id: $id, revId: $revId, categoryId: $categoryId, subCategoryId: $subCategoryId, comLangId: $comLangId, description: $description, shortDescription: $shortDescription, acceptChanges: $acceptChanges, link: $link, notes: $notes, isInProgress: $isInProgress, isHighlighted: $isHighlighted, modelApplicability: $modelApplicability, unlinkFromTMNA: $unlinkFromTMNA}
  ) {
    ...VehicleFeatureFields
  }
}
    ${VehicleFeatureFieldsFragmentDoc}`;
export type UpdateFeatureMutationFn = Apollo.MutationFunction<UpdateFeatureMutation, UpdateFeatureMutationVariables>;

/**
 * __useUpdateFeatureMutation__
 *
 * To run a mutation, you first call `useUpdateFeatureMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateFeatureMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateFeatureMutation, { data, loading, error }] = useUpdateFeatureMutation({
 *   variables: {
 *      team: // value for 'team'
 *      seriesId: // value for 'seriesId'
 *      modelYear: // value for 'modelYear'
 *      language: // value for 'language'
 *      id: // value for 'id'
 *      revId: // value for 'revId'
 *      categoryId: // value for 'categoryId'
 *      subCategoryId: // value for 'subCategoryId'
 *      comLangId: // value for 'comLangId'
 *      description: // value for 'description'
 *      shortDescription: // value for 'shortDescription'
 *      acceptChanges: // value for 'acceptChanges'
 *      link: // value for 'link'
 *      notes: // value for 'notes'
 *      isInProgress: // value for 'isInProgress'
 *      isHighlighted: // value for 'isHighlighted'
 *      modelApplicability: // value for 'modelApplicability'
 *      unlinkFromTMNA: // value for 'unlinkFromTMNA'
 *   },
 * });
 */
export function useUpdateFeatureMutation(baseOptions?: Apollo.MutationHookOptions<UpdateFeatureMutation, UpdateFeatureMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateFeatureMutation, UpdateFeatureMutationVariables>(UpdateFeatureDocument, options);
      }
export type UpdateFeatureMutationHookResult = ReturnType<typeof useUpdateFeatureMutation>;
export type UpdateFeatureMutationResult = Apollo.MutationResult<UpdateFeatureMutation>;
export type UpdateFeatureMutationOptions = Apollo.BaseMutationOptions<UpdateFeatureMutation, UpdateFeatureMutationVariables>;
export const GetFuelTypesDocument = gql`
    query getFuelTypes($brand: String!) {
  fuelTypes(brand: $brand) {
    fuelTypes {
      ...FuelTypeFields
    }
  }
}
    ${FuelTypeFieldsFragmentDoc}`;

/**
 * __useGetFuelTypesQuery__
 *
 * To run a query within a React component, call `useGetFuelTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFuelTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFuelTypesQuery({
 *   variables: {
 *      brand: // value for 'brand'
 *   },
 * });
 */
export function useGetFuelTypesQuery(baseOptions: Apollo.QueryHookOptions<GetFuelTypesQuery, GetFuelTypesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFuelTypesQuery, GetFuelTypesQueryVariables>(GetFuelTypesDocument, options);
      }
export function useGetFuelTypesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFuelTypesQuery, GetFuelTypesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFuelTypesQuery, GetFuelTypesQueryVariables>(GetFuelTypesDocument, options);
        }
export type GetFuelTypesQueryHookResult = ReturnType<typeof useGetFuelTypesQuery>;
export type GetFuelTypesLazyQueryHookResult = ReturnType<typeof useGetFuelTypesLazyQuery>;
export type GetFuelTypesQueryResult = Apollo.QueryResult<GetFuelTypesQuery, GetFuelTypesQueryVariables>;
export const AddGradesDocument = gql`
    mutation addGrades($team: String!, $seriesId: String!, $modelYear: Int!, $language: String!, $gradeName: String!, $gradeId: String) {
  createSeriesGrade(
    input: {team: $team, seriesId: $seriesId, modelYear: $modelYear, language: $language, gradeName: $gradeName, gradeId: $gradeId}
  ) {
    seriesGrades {
      ...SeriesGradeFields
    }
  }
}
    ${SeriesGradeFieldsFragmentDoc}`;
export type AddGradesMutationFn = Apollo.MutationFunction<AddGradesMutation, AddGradesMutationVariables>;

/**
 * __useAddGradesMutation__
 *
 * To run a mutation, you first call `useAddGradesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddGradesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addGradesMutation, { data, loading, error }] = useAddGradesMutation({
 *   variables: {
 *      team: // value for 'team'
 *      seriesId: // value for 'seriesId'
 *      modelYear: // value for 'modelYear'
 *      language: // value for 'language'
 *      gradeName: // value for 'gradeName'
 *      gradeId: // value for 'gradeId'
 *   },
 * });
 */
export function useAddGradesMutation(baseOptions?: Apollo.MutationHookOptions<AddGradesMutation, AddGradesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddGradesMutation, AddGradesMutationVariables>(AddGradesDocument, options);
      }
export type AddGradesMutationHookResult = ReturnType<typeof useAddGradesMutation>;
export type AddGradesMutationResult = Apollo.MutationResult<AddGradesMutation>;
export type AddGradesMutationOptions = Apollo.BaseMutationOptions<AddGradesMutation, AddGradesMutationVariables>;
export const GetGradesDocument = gql`
    query getGrades($team: String!, $seriesId: ID!, $modelYear: Int!, $language: String!, $status: String, $version: Int, $includeAll: Boolean) {
  seriesGrades(
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    language: $language
    status: $status
    version: $version
    includeAll: $includeAll
  ) {
    seriesGrades {
      ...SeriesGradeFields
    }
  }
}
    ${SeriesGradeFieldsFragmentDoc}`;

/**
 * __useGetGradesQuery__
 *
 * To run a query within a React component, call `useGetGradesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGradesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGradesQuery({
 *   variables: {
 *      team: // value for 'team'
 *      seriesId: // value for 'seriesId'
 *      modelYear: // value for 'modelYear'
 *      language: // value for 'language'
 *      status: // value for 'status'
 *      version: // value for 'version'
 *      includeAll: // value for 'includeAll'
 *   },
 * });
 */
export function useGetGradesQuery(baseOptions: Apollo.QueryHookOptions<GetGradesQuery, GetGradesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGradesQuery, GetGradesQueryVariables>(GetGradesDocument, options);
      }
export function useGetGradesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGradesQuery, GetGradesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGradesQuery, GetGradesQueryVariables>(GetGradesDocument, options);
        }
export type GetGradesQueryHookResult = ReturnType<typeof useGetGradesQuery>;
export type GetGradesLazyQueryHookResult = ReturnType<typeof useGetGradesLazyQuery>;
export type GetGradesQueryResult = Apollo.QueryResult<GetGradesQuery, GetGradesQueryVariables>;
export const UpdateGradeDocument = gql`
    mutation updateGrade($team: String!, $seriesId: String!, $modelYear: Int!, $language: String!, $gradeName: String!, $gradeId: String!) {
  updateSeriesGrade(
    input: {team: $team, seriesId: $seriesId, modelYear: $modelYear, language: $language, gradeName: $gradeName, gradeId: $gradeId}
  ) {
    ...SeriesGradeFields
  }
}
    ${SeriesGradeFieldsFragmentDoc}`;
export type UpdateGradeMutationFn = Apollo.MutationFunction<UpdateGradeMutation, UpdateGradeMutationVariables>;

/**
 * __useUpdateGradeMutation__
 *
 * To run a mutation, you first call `useUpdateGradeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGradeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGradeMutation, { data, loading, error }] = useUpdateGradeMutation({
 *   variables: {
 *      team: // value for 'team'
 *      seriesId: // value for 'seriesId'
 *      modelYear: // value for 'modelYear'
 *      language: // value for 'language'
 *      gradeName: // value for 'gradeName'
 *      gradeId: // value for 'gradeId'
 *   },
 * });
 */
export function useUpdateGradeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateGradeMutation, UpdateGradeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateGradeMutation, UpdateGradeMutationVariables>(UpdateGradeDocument, options);
      }
export type UpdateGradeMutationHookResult = ReturnType<typeof useUpdateGradeMutation>;
export type UpdateGradeMutationResult = Apollo.MutationResult<UpdateGradeMutation>;
export type UpdateGradeMutationOptions = Apollo.BaseMutationOptions<UpdateGradeMutation, UpdateGradeMutationVariables>;
export const DeleteModelDocument = gql`
    mutation deleteModel($team: String!, $seriesId: String!, $modelYear: Int!, $language: String!, $id: ID!) {
  deleteModel(
    input: {team: $team, seriesId: $seriesId, modelYear: $modelYear, language: $language, id: $id}
  ) {
    id
    message
  }
}
    `;
export type DeleteModelMutationFn = Apollo.MutationFunction<DeleteModelMutation, DeleteModelMutationVariables>;

/**
 * __useDeleteModelMutation__
 *
 * To run a mutation, you first call `useDeleteModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteModelMutation, { data, loading, error }] = useDeleteModelMutation({
 *   variables: {
 *      team: // value for 'team'
 *      seriesId: // value for 'seriesId'
 *      modelYear: // value for 'modelYear'
 *      language: // value for 'language'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteModelMutation(baseOptions?: Apollo.MutationHookOptions<DeleteModelMutation, DeleteModelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteModelMutation, DeleteModelMutationVariables>(DeleteModelDocument, options);
      }
export type DeleteModelMutationHookResult = ReturnType<typeof useDeleteModelMutation>;
export type DeleteModelMutationResult = Apollo.MutationResult<DeleteModelMutation>;
export type DeleteModelMutationOptions = Apollo.BaseMutationOptions<DeleteModelMutation, DeleteModelMutationVariables>;
export const GetChangeLogModelsDocument = gql`
    query getChangeLogModels($team: String!, $seriesId: ID!, $modelYear: Int!, $language: String!, $status: String, $version: Int) {
  modelsChanges(
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    language: $language
    status: $status
    version: $version
  ) {
    body
  }
}
    `;

/**
 * __useGetChangeLogModelsQuery__
 *
 * To run a query within a React component, call `useGetChangeLogModelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChangeLogModelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChangeLogModelsQuery({
 *   variables: {
 *      team: // value for 'team'
 *      seriesId: // value for 'seriesId'
 *      modelYear: // value for 'modelYear'
 *      language: // value for 'language'
 *      status: // value for 'status'
 *      version: // value for 'version'
 *   },
 * });
 */
export function useGetChangeLogModelsQuery(baseOptions: Apollo.QueryHookOptions<GetChangeLogModelsQuery, GetChangeLogModelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetChangeLogModelsQuery, GetChangeLogModelsQueryVariables>(GetChangeLogModelsDocument, options);
      }
export function useGetChangeLogModelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChangeLogModelsQuery, GetChangeLogModelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetChangeLogModelsQuery, GetChangeLogModelsQueryVariables>(GetChangeLogModelsDocument, options);
        }
export type GetChangeLogModelsQueryHookResult = ReturnType<typeof useGetChangeLogModelsQuery>;
export type GetChangeLogModelsLazyQueryHookResult = ReturnType<typeof useGetChangeLogModelsLazyQuery>;
export type GetChangeLogModelsQueryResult = Apollo.QueryResult<GetChangeLogModelsQuery, GetChangeLogModelsQueryVariables>;
export const GetModelsDocument = gql`
    query getModels($team: String!, $seriesId: ID!, $modelYear: Int!, $language: String!, $status: String, $version: Int, $includeAll: Boolean) {
  models(
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    language: $language
    status: $status
    version: $version
    includeAll: $includeAll
  ) {
    models {
      ...ModelFields
    }
  }
}
    ${ModelFieldsFragmentDoc}`;

/**
 * __useGetModelsQuery__
 *
 * To run a query within a React component, call `useGetModelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetModelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetModelsQuery({
 *   variables: {
 *      team: // value for 'team'
 *      seriesId: // value for 'seriesId'
 *      modelYear: // value for 'modelYear'
 *      language: // value for 'language'
 *      status: // value for 'status'
 *      version: // value for 'version'
 *      includeAll: // value for 'includeAll'
 *   },
 * });
 */
export function useGetModelsQuery(baseOptions: Apollo.QueryHookOptions<GetModelsQuery, GetModelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetModelsQuery, GetModelsQueryVariables>(GetModelsDocument, options);
      }
export function useGetModelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetModelsQuery, GetModelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetModelsQuery, GetModelsQueryVariables>(GetModelsDocument, options);
        }
export type GetModelsQueryHookResult = ReturnType<typeof useGetModelsQuery>;
export type GetModelsLazyQueryHookResult = ReturnType<typeof useGetModelsLazyQuery>;
export type GetModelsQueryResult = Apollo.QueryResult<GetModelsQuery, GetModelsQueryVariables>;
export const RevertChangeLogModelsDocument = gql`
    mutation revertChangeLogModels($brand: String!, $team: String!, $seriesId: String!, $modelYear: Int!, $language: String!, $body: JSON!) {
  revertModelsChanges(
    input: {brand: $brand, team: $team, seriesId: $seriesId, modelYear: $modelYear, language: $language, body: $body}
  ) {
    body
  }
}
    `;
export type RevertChangeLogModelsMutationFn = Apollo.MutationFunction<RevertChangeLogModelsMutation, RevertChangeLogModelsMutationVariables>;

/**
 * __useRevertChangeLogModelsMutation__
 *
 * To run a mutation, you first call `useRevertChangeLogModelsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevertChangeLogModelsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revertChangeLogModelsMutation, { data, loading, error }] = useRevertChangeLogModelsMutation({
 *   variables: {
 *      brand: // value for 'brand'
 *      team: // value for 'team'
 *      seriesId: // value for 'seriesId'
 *      modelYear: // value for 'modelYear'
 *      language: // value for 'language'
 *      body: // value for 'body'
 *   },
 * });
 */
export function useRevertChangeLogModelsMutation(baseOptions?: Apollo.MutationHookOptions<RevertChangeLogModelsMutation, RevertChangeLogModelsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RevertChangeLogModelsMutation, RevertChangeLogModelsMutationVariables>(RevertChangeLogModelsDocument, options);
      }
export type RevertChangeLogModelsMutationHookResult = ReturnType<typeof useRevertChangeLogModelsMutation>;
export type RevertChangeLogModelsMutationResult = Apollo.MutationResult<RevertChangeLogModelsMutation>;
export type RevertChangeLogModelsMutationOptions = Apollo.BaseMutationOptions<RevertChangeLogModelsMutation, RevertChangeLogModelsMutationVariables>;
export const SortModelsDocument = gql`
    mutation sortModels($brand: String!, $team: String!, $seriesId: String!, $modelYear: Int!, $sortList: [SortListItemInput!]!) {
  updateModelSort(
    input: {brand: $brand, team: $team, seriesId: $seriesId, modelYear: $modelYear, sortList: $sortList}
  ) {
    id
    revId
    sortOrder
  }
}
    `;
export type SortModelsMutationFn = Apollo.MutationFunction<SortModelsMutation, SortModelsMutationVariables>;

/**
 * __useSortModelsMutation__
 *
 * To run a mutation, you first call `useSortModelsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSortModelsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sortModelsMutation, { data, loading, error }] = useSortModelsMutation({
 *   variables: {
 *      brand: // value for 'brand'
 *      team: // value for 'team'
 *      seriesId: // value for 'seriesId'
 *      modelYear: // value for 'modelYear'
 *      sortList: // value for 'sortList'
 *   },
 * });
 */
export function useSortModelsMutation(baseOptions?: Apollo.MutationHookOptions<SortModelsMutation, SortModelsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SortModelsMutation, SortModelsMutationVariables>(SortModelsDocument, options);
      }
export type SortModelsMutationHookResult = ReturnType<typeof useSortModelsMutation>;
export type SortModelsMutationResult = Apollo.MutationResult<SortModelsMutation>;
export type SortModelsMutationOptions = Apollo.BaseMutationOptions<SortModelsMutation, SortModelsMutationVariables>;
export const UpdateModelDocument = gql`
    mutation updateModel($brand: String!, $team: String!, $seriesId: String!, $modelYear: Int!, $language: String!, $id: ID!, $revId: ID!, $code: String!, $grade: String!, $drive: String!, $engine: String!, $horsepower: String!, $transmission: String!, $isHybrid: Boolean!, $fuelType: String!, $isNotPublishable: Boolean!, $bed: String, $cab: String, $seating: String, $katashiki: String, $description: String!, $trimTitle: String!, $goLiveDate: String, $acceptChanges: Boolean, $tdprCode: String, $isTDPR: Boolean, $isUSVI: Boolean, $unlinkFromTMNA: Boolean) {
  updateModel(
    input: {brand: $brand, team: $team, seriesId: $seriesId, modelYear: $modelYear, language: $language, id: $id, revId: $revId, code: $code, grade: $grade, drive: $drive, engine: $engine, horsepower: $horsepower, transmission: $transmission, isHybrid: $isHybrid, fuelType: $fuelType, isNotPublishable: $isNotPublishable, bed: $bed, cab: $cab, seating: $seating, katashiki: $katashiki, description: $description, trimTitle: $trimTitle, goLiveDate: $goLiveDate, acceptChanges: $acceptChanges, tdprCode: $tdprCode, isTDPR: $isTDPR, isUSVI: $isUSVI, unlinkFromTMNA: $unlinkFromTMNA}
  ) {
    ...ModelFields
  }
}
    ${ModelFieldsFragmentDoc}`;
export type UpdateModelMutationFn = Apollo.MutationFunction<UpdateModelMutation, UpdateModelMutationVariables>;

/**
 * __useUpdateModelMutation__
 *
 * To run a mutation, you first call `useUpdateModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateModelMutation, { data, loading, error }] = useUpdateModelMutation({
 *   variables: {
 *      brand: // value for 'brand'
 *      team: // value for 'team'
 *      seriesId: // value for 'seriesId'
 *      modelYear: // value for 'modelYear'
 *      language: // value for 'language'
 *      id: // value for 'id'
 *      revId: // value for 'revId'
 *      code: // value for 'code'
 *      grade: // value for 'grade'
 *      drive: // value for 'drive'
 *      engine: // value for 'engine'
 *      horsepower: // value for 'horsepower'
 *      transmission: // value for 'transmission'
 *      isHybrid: // value for 'isHybrid'
 *      fuelType: // value for 'fuelType'
 *      isNotPublishable: // value for 'isNotPublishable'
 *      bed: // value for 'bed'
 *      cab: // value for 'cab'
 *      seating: // value for 'seating'
 *      katashiki: // value for 'katashiki'
 *      description: // value for 'description'
 *      trimTitle: // value for 'trimTitle'
 *      goLiveDate: // value for 'goLiveDate'
 *      acceptChanges: // value for 'acceptChanges'
 *      tdprCode: // value for 'tdprCode'
 *      isTDPR: // value for 'isTDPR'
 *      isUSVI: // value for 'isUSVI'
 *      unlinkFromTMNA: // value for 'unlinkFromTMNA'
 *   },
 * });
 */
export function useUpdateModelMutation(baseOptions?: Apollo.MutationHookOptions<UpdateModelMutation, UpdateModelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateModelMutation, UpdateModelMutationVariables>(UpdateModelDocument, options);
      }
export type UpdateModelMutationHookResult = ReturnType<typeof useUpdateModelMutation>;
export type UpdateModelMutationResult = Apollo.MutationResult<UpdateModelMutation>;
export type UpdateModelMutationOptions = Apollo.BaseMutationOptions<UpdateModelMutation, UpdateModelMutationVariables>;
export const AddOptionDocument = gql`
    mutation addOption($team: String!, $seriesId: String!, $modelYear: Int!, $language: String!, $id: String!, $categoryId: String!, $name: String!, $code: String!, $description: String, $isExtraCost: String, $notes: String, $link: String, $isInProgress: Boolean, $modelApplicability: [VehicleOptionModelApplicabilityInput!]) {
  createVehicleOption(
    input: {team: $team, seriesId: $seriesId, modelYear: $modelYear, language: $language, id: $id, categoryId: $categoryId, name: $name, code: $code, description: $description, isExtraCost: $isExtraCost, notes: $notes, link: $link, isInProgress: $isInProgress, modelApplicability: $modelApplicability}
  ) {
    ...VehicleOptionFields
  }
}
    ${VehicleOptionFieldsFragmentDoc}`;
export type AddOptionMutationFn = Apollo.MutationFunction<AddOptionMutation, AddOptionMutationVariables>;

/**
 * __useAddOptionMutation__
 *
 * To run a mutation, you first call `useAddOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOptionMutation, { data, loading, error }] = useAddOptionMutation({
 *   variables: {
 *      team: // value for 'team'
 *      seriesId: // value for 'seriesId'
 *      modelYear: // value for 'modelYear'
 *      language: // value for 'language'
 *      id: // value for 'id'
 *      categoryId: // value for 'categoryId'
 *      name: // value for 'name'
 *      code: // value for 'code'
 *      description: // value for 'description'
 *      isExtraCost: // value for 'isExtraCost'
 *      notes: // value for 'notes'
 *      link: // value for 'link'
 *      isInProgress: // value for 'isInProgress'
 *      modelApplicability: // value for 'modelApplicability'
 *   },
 * });
 */
export function useAddOptionMutation(baseOptions?: Apollo.MutationHookOptions<AddOptionMutation, AddOptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddOptionMutation, AddOptionMutationVariables>(AddOptionDocument, options);
      }
export type AddOptionMutationHookResult = ReturnType<typeof useAddOptionMutation>;
export type AddOptionMutationResult = Apollo.MutationResult<AddOptionMutation>;
export type AddOptionMutationOptions = Apollo.BaseMutationOptions<AddOptionMutation, AddOptionMutationVariables>;
export const DeleteOptionDocument = gql`
    mutation deleteOption($team: String!, $seriesId: String!, $modelYear: Int!, $language: String!, $id: ID!) {
  deleteVehicleOption(
    input: {team: $team, seriesId: $seriesId, modelYear: $modelYear, language: $language, id: $id}
  ) {
    id
    message
  }
}
    `;
export type DeleteOptionMutationFn = Apollo.MutationFunction<DeleteOptionMutation, DeleteOptionMutationVariables>;

/**
 * __useDeleteOptionMutation__
 *
 * To run a mutation, you first call `useDeleteOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOptionMutation, { data, loading, error }] = useDeleteOptionMutation({
 *   variables: {
 *      team: // value for 'team'
 *      seriesId: // value for 'seriesId'
 *      modelYear: // value for 'modelYear'
 *      language: // value for 'language'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteOptionMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOptionMutation, DeleteOptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOptionMutation, DeleteOptionMutationVariables>(DeleteOptionDocument, options);
      }
export type DeleteOptionMutationHookResult = ReturnType<typeof useDeleteOptionMutation>;
export type DeleteOptionMutationResult = Apollo.MutationResult<DeleteOptionMutation>;
export type DeleteOptionMutationOptions = Apollo.BaseMutationOptions<DeleteOptionMutation, DeleteOptionMutationVariables>;
export const GetOptionCategoriesByLangDocument = gql`
    query getOptionCategoriesByLang($team: String!, $seriesId: ID!, $modelYear: Int!, $language: String!, $status: String, $version: Int, $includeAll: Boolean) {
  vehicleOptionsCategories(
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    language: $language
    status: $status
    version: $version
    includeAll: $includeAll
  ) {
    vehicleOptionsCategories {
      id
      name
    }
  }
}
    `;

/**
 * __useGetOptionCategoriesByLangQuery__
 *
 * To run a query within a React component, call `useGetOptionCategoriesByLangQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOptionCategoriesByLangQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOptionCategoriesByLangQuery({
 *   variables: {
 *      team: // value for 'team'
 *      seriesId: // value for 'seriesId'
 *      modelYear: // value for 'modelYear'
 *      language: // value for 'language'
 *      status: // value for 'status'
 *      version: // value for 'version'
 *      includeAll: // value for 'includeAll'
 *   },
 * });
 */
export function useGetOptionCategoriesByLangQuery(baseOptions: Apollo.QueryHookOptions<GetOptionCategoriesByLangQuery, GetOptionCategoriesByLangQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOptionCategoriesByLangQuery, GetOptionCategoriesByLangQueryVariables>(GetOptionCategoriesByLangDocument, options);
      }
export function useGetOptionCategoriesByLangLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOptionCategoriesByLangQuery, GetOptionCategoriesByLangQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOptionCategoriesByLangQuery, GetOptionCategoriesByLangQueryVariables>(GetOptionCategoriesByLangDocument, options);
        }
export type GetOptionCategoriesByLangQueryHookResult = ReturnType<typeof useGetOptionCategoriesByLangQuery>;
export type GetOptionCategoriesByLangLazyQueryHookResult = ReturnType<typeof useGetOptionCategoriesByLangLazyQuery>;
export type GetOptionCategoriesByLangQueryResult = Apollo.QueryResult<GetOptionCategoriesByLangQuery, GetOptionCategoriesByLangQueryVariables>;
export const GetChangeLogOptionsDocument = gql`
    query getChangeLogOptions($team: String!, $seriesId: ID!, $modelYear: Int!, $language: String!, $status: String, $version: Int) {
  vehicleOptionsChanges(
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    language: $language
    status: $status
    version: $version
  ) {
    body
  }
}
    `;

/**
 * __useGetChangeLogOptionsQuery__
 *
 * To run a query within a React component, call `useGetChangeLogOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChangeLogOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChangeLogOptionsQuery({
 *   variables: {
 *      team: // value for 'team'
 *      seriesId: // value for 'seriesId'
 *      modelYear: // value for 'modelYear'
 *      language: // value for 'language'
 *      status: // value for 'status'
 *      version: // value for 'version'
 *   },
 * });
 */
export function useGetChangeLogOptionsQuery(baseOptions: Apollo.QueryHookOptions<GetChangeLogOptionsQuery, GetChangeLogOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetChangeLogOptionsQuery, GetChangeLogOptionsQueryVariables>(GetChangeLogOptionsDocument, options);
      }
export function useGetChangeLogOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChangeLogOptionsQuery, GetChangeLogOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetChangeLogOptionsQuery, GetChangeLogOptionsQueryVariables>(GetChangeLogOptionsDocument, options);
        }
export type GetChangeLogOptionsQueryHookResult = ReturnType<typeof useGetChangeLogOptionsQuery>;
export type GetChangeLogOptionsLazyQueryHookResult = ReturnType<typeof useGetChangeLogOptionsLazyQuery>;
export type GetChangeLogOptionsQueryResult = Apollo.QueryResult<GetChangeLogOptionsQuery, GetChangeLogOptionsQueryVariables>;
export const GetOptionsDocument = gql`
    query getOptions($team: String!, $seriesId: ID!, $modelYear: Int!, $language: String!, $status: String, $version: Int) {
  vehicleOptions(
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    language: $language
    status: $status
    version: $version
  ) {
    vehicleOptions {
      ...VehicleOptionFields
    }
  }
}
    ${VehicleOptionFieldsFragmentDoc}`;

/**
 * __useGetOptionsQuery__
 *
 * To run a query within a React component, call `useGetOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOptionsQuery({
 *   variables: {
 *      team: // value for 'team'
 *      seriesId: // value for 'seriesId'
 *      modelYear: // value for 'modelYear'
 *      language: // value for 'language'
 *      status: // value for 'status'
 *      version: // value for 'version'
 *   },
 * });
 */
export function useGetOptionsQuery(baseOptions: Apollo.QueryHookOptions<GetOptionsQuery, GetOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOptionsQuery, GetOptionsQueryVariables>(GetOptionsDocument, options);
      }
export function useGetOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOptionsQuery, GetOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOptionsQuery, GetOptionsQueryVariables>(GetOptionsDocument, options);
        }
export type GetOptionsQueryHookResult = ReturnType<typeof useGetOptionsQuery>;
export type GetOptionsLazyQueryHookResult = ReturnType<typeof useGetOptionsLazyQuery>;
export type GetOptionsQueryResult = Apollo.QueryResult<GetOptionsQuery, GetOptionsQueryVariables>;
export const RevertChangeLogOptionsDocument = gql`
    mutation revertChangeLogOptions($brand: String!, $team: String!, $seriesId: String!, $modelYear: Int!, $language: String!, $body: JSON!) {
  revertVehicleOptionsChanges(
    input: {brand: $brand, team: $team, seriesId: $seriesId, modelYear: $modelYear, language: $language, body: $body}
  ) {
    body
  }
}
    `;
export type RevertChangeLogOptionsMutationFn = Apollo.MutationFunction<RevertChangeLogOptionsMutation, RevertChangeLogOptionsMutationVariables>;

/**
 * __useRevertChangeLogOptionsMutation__
 *
 * To run a mutation, you first call `useRevertChangeLogOptionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevertChangeLogOptionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revertChangeLogOptionsMutation, { data, loading, error }] = useRevertChangeLogOptionsMutation({
 *   variables: {
 *      brand: // value for 'brand'
 *      team: // value for 'team'
 *      seriesId: // value for 'seriesId'
 *      modelYear: // value for 'modelYear'
 *      language: // value for 'language'
 *      body: // value for 'body'
 *   },
 * });
 */
export function useRevertChangeLogOptionsMutation(baseOptions?: Apollo.MutationHookOptions<RevertChangeLogOptionsMutation, RevertChangeLogOptionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RevertChangeLogOptionsMutation, RevertChangeLogOptionsMutationVariables>(RevertChangeLogOptionsDocument, options);
      }
export type RevertChangeLogOptionsMutationHookResult = ReturnType<typeof useRevertChangeLogOptionsMutation>;
export type RevertChangeLogOptionsMutationResult = Apollo.MutationResult<RevertChangeLogOptionsMutation>;
export type RevertChangeLogOptionsMutationOptions = Apollo.BaseMutationOptions<RevertChangeLogOptionsMutation, RevertChangeLogOptionsMutationVariables>;
export const UpdateOptionDocument = gql`
    mutation updateOption($team: String!, $seriesId: String!, $modelYear: Int!, $language: String!, $id: String!, $revId: String!, $categoryId: String!, $name: String!, $code: String!, $description: String, $isExtraCost: String, $notes: String, $link: String, $isInProgress: Boolean, $modelApplicability: [VehicleOptionModelApplicabilityInput!], $acceptChanges: Boolean, $unlinkFromTMNA: Boolean) {
  updateVehicleOption(
    input: {team: $team, seriesId: $seriesId, modelYear: $modelYear, language: $language, id: $id, revId: $revId, categoryId: $categoryId, name: $name, code: $code, description: $description, isExtraCost: $isExtraCost, notes: $notes, link: $link, isInProgress: $isInProgress, modelApplicability: $modelApplicability, acceptChanges: $acceptChanges, unlinkFromTMNA: $unlinkFromTMNA}
  ) {
    ...VehicleOptionFields
  }
}
    ${VehicleOptionFieldsFragmentDoc}`;
export type UpdateOptionMutationFn = Apollo.MutationFunction<UpdateOptionMutation, UpdateOptionMutationVariables>;

/**
 * __useUpdateOptionMutation__
 *
 * To run a mutation, you first call `useUpdateOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOptionMutation, { data, loading, error }] = useUpdateOptionMutation({
 *   variables: {
 *      team: // value for 'team'
 *      seriesId: // value for 'seriesId'
 *      modelYear: // value for 'modelYear'
 *      language: // value for 'language'
 *      id: // value for 'id'
 *      revId: // value for 'revId'
 *      categoryId: // value for 'categoryId'
 *      name: // value for 'name'
 *      code: // value for 'code'
 *      description: // value for 'description'
 *      isExtraCost: // value for 'isExtraCost'
 *      notes: // value for 'notes'
 *      link: // value for 'link'
 *      isInProgress: // value for 'isInProgress'
 *      modelApplicability: // value for 'modelApplicability'
 *      acceptChanges: // value for 'acceptChanges'
 *      unlinkFromTMNA: // value for 'unlinkFromTMNA'
 *   },
 * });
 */
export function useUpdateOptionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOptionMutation, UpdateOptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOptionMutation, UpdateOptionMutationVariables>(UpdateOptionDocument, options);
      }
export type UpdateOptionMutationHookResult = ReturnType<typeof useUpdateOptionMutation>;
export type UpdateOptionMutationResult = Apollo.MutationResult<UpdateOptionMutation>;
export type UpdateOptionMutationOptions = Apollo.BaseMutationOptions<UpdateOptionMutation, UpdateOptionMutationVariables>;
export const GetSeriesDocument = gql`
    query getSeries($brand: String!) {
  series(brand: $brand) {
    revId
    name
    id
    sourceSeriesId
    sourceBrand
    group
  }
}
    `;

/**
 * __useGetSeriesQuery__
 *
 * To run a query within a React component, call `useGetSeriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSeriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSeriesQuery({
 *   variables: {
 *      brand: // value for 'brand'
 *   },
 * });
 */
export function useGetSeriesQuery(baseOptions: Apollo.QueryHookOptions<GetSeriesQuery, GetSeriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSeriesQuery, GetSeriesQueryVariables>(GetSeriesDocument, options);
      }
export function useGetSeriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSeriesQuery, GetSeriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSeriesQuery, GetSeriesQueryVariables>(GetSeriesDocument, options);
        }
export type GetSeriesQueryHookResult = ReturnType<typeof useGetSeriesQuery>;
export type GetSeriesLazyQueryHookResult = ReturnType<typeof useGetSeriesLazyQuery>;
export type GetSeriesQueryResult = Apollo.QueryResult<GetSeriesQuery, GetSeriesQueryVariables>;
export const GetSeriesCategoriesDocument = gql`
    query getSeriesCategories($brand: String!, $getLatestVersion: Boolean) {
  seriesCategories(brand: $brand, getLatestVersion: $getLatestVersion) {
    seriesCategories {
      name
      revId
      id
      seriesId
      series {
        seriesName
        seriesYear
        isPublishedToDownstream
      }
      nameES
    }
  }
}
    `;

/**
 * __useGetSeriesCategoriesQuery__
 *
 * To run a query within a React component, call `useGetSeriesCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSeriesCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSeriesCategoriesQuery({
 *   variables: {
 *      brand: // value for 'brand'
 *      getLatestVersion: // value for 'getLatestVersion'
 *   },
 * });
 */
export function useGetSeriesCategoriesQuery(baseOptions: Apollo.QueryHookOptions<GetSeriesCategoriesQuery, GetSeriesCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSeriesCategoriesQuery, GetSeriesCategoriesQueryVariables>(GetSeriesCategoriesDocument, options);
      }
export function useGetSeriesCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSeriesCategoriesQuery, GetSeriesCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSeriesCategoriesQuery, GetSeriesCategoriesQueryVariables>(GetSeriesCategoriesDocument, options);
        }
export type GetSeriesCategoriesQueryHookResult = ReturnType<typeof useGetSeriesCategoriesQuery>;
export type GetSeriesCategoriesLazyQueryHookResult = ReturnType<typeof useGetSeriesCategoriesLazyQuery>;
export type GetSeriesCategoriesQueryResult = Apollo.QueryResult<GetSeriesCategoriesQuery, GetSeriesCategoriesQueryVariables>;
export const GetUserSeriesDocument = gql`
    query getUserSeries($brand: String!, $objectId: ID!) {
  seriesSelections(brand: $brand, objectId: $objectId) {
    revId
    name
    id
    sourceSeriesId
    sourceBrand
    group
  }
}
    `;

/**
 * __useGetUserSeriesQuery__
 *
 * To run a query within a React component, call `useGetUserSeriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserSeriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserSeriesQuery({
 *   variables: {
 *      brand: // value for 'brand'
 *      objectId: // value for 'objectId'
 *   },
 * });
 */
export function useGetUserSeriesQuery(baseOptions: Apollo.QueryHookOptions<GetUserSeriesQuery, GetUserSeriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserSeriesQuery, GetUserSeriesQueryVariables>(GetUserSeriesDocument, options);
      }
export function useGetUserSeriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserSeriesQuery, GetUserSeriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserSeriesQuery, GetUserSeriesQueryVariables>(GetUserSeriesDocument, options);
        }
export type GetUserSeriesQueryHookResult = ReturnType<typeof useGetUserSeriesQuery>;
export type GetUserSeriesLazyQueryHookResult = ReturnType<typeof useGetUserSeriesLazyQuery>;
export type GetUserSeriesQueryResult = Apollo.QueryResult<GetUserSeriesQuery, GetUserSeriesQueryVariables>;
export const UpdateUserSeriesDocument = gql`
    mutation updateUserSeries($brand: String, $objectId: ID!, $seriesSelections: [String]) {
  updateSeriesSelections(
    input: {brand: $brand, objectId: $objectId, seriesSelections: $seriesSelections}
  )
}
    `;
export type UpdateUserSeriesMutationFn = Apollo.MutationFunction<UpdateUserSeriesMutation, UpdateUserSeriesMutationVariables>;

/**
 * __useUpdateUserSeriesMutation__
 *
 * To run a mutation, you first call `useUpdateUserSeriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserSeriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserSeriesMutation, { data, loading, error }] = useUpdateUserSeriesMutation({
 *   variables: {
 *      brand: // value for 'brand'
 *      objectId: // value for 'objectId'
 *      seriesSelections: // value for 'seriesSelections'
 *   },
 * });
 */
export function useUpdateUserSeriesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserSeriesMutation, UpdateUserSeriesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserSeriesMutation, UpdateUserSeriesMutationVariables>(UpdateUserSeriesDocument, options);
      }
export type UpdateUserSeriesMutationHookResult = ReturnType<typeof useUpdateUserSeriesMutation>;
export type UpdateUserSeriesMutationResult = Apollo.MutationResult<UpdateUserSeriesMutation>;
export type UpdateUserSeriesMutationOptions = Apollo.BaseMutationOptions<UpdateUserSeriesMutation, UpdateUserSeriesMutationVariables>;
export const GetSeriesSettingsDocument = gql`
    query getSeriesSettings($team: String!, $seriesId: ID!, $modelYear: Int!, $lang: String!, $status: String, $version: Int, $includeAll: Boolean) {
  seriesSettings(
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    language: $lang
    status: $status
    version: $version
    includeAll: $includeAll
  ) {
    seriesSettings {
      ...SeriesSettingFields
    }
  }
}
    ${SeriesSettingFieldsFragmentDoc}`;

/**
 * __useGetSeriesSettingsQuery__
 *
 * To run a query within a React component, call `useGetSeriesSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSeriesSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSeriesSettingsQuery({
 *   variables: {
 *      team: // value for 'team'
 *      seriesId: // value for 'seriesId'
 *      modelYear: // value for 'modelYear'
 *      lang: // value for 'lang'
 *      status: // value for 'status'
 *      version: // value for 'version'
 *      includeAll: // value for 'includeAll'
 *   },
 * });
 */
export function useGetSeriesSettingsQuery(baseOptions: Apollo.QueryHookOptions<GetSeriesSettingsQuery, GetSeriesSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSeriesSettingsQuery, GetSeriesSettingsQueryVariables>(GetSeriesSettingsDocument, options);
      }
export function useGetSeriesSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSeriesSettingsQuery, GetSeriesSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSeriesSettingsQuery, GetSeriesSettingsQueryVariables>(GetSeriesSettingsDocument, options);
        }
export type GetSeriesSettingsQueryHookResult = ReturnType<typeof useGetSeriesSettingsQuery>;
export type GetSeriesSettingsLazyQueryHookResult = ReturnType<typeof useGetSeriesSettingsLazyQuery>;
export type GetSeriesSettingsQueryResult = Apollo.QueryResult<GetSeriesSettingsQuery, GetSeriesSettingsQueryVariables>;
export const UpdateSeriesSettingDocument = gql`
    mutation updateSeriesSetting($acceptChanges: Boolean, $brand: String!, $estimatedMileage: String, $fuelTypes: [ID!]!, $id: ID!, $isDeleted: Boolean!, $isPublishable: Boolean, $language: String!, $modelYear: Int!, $mpge: String, $name: String!, $parentId: String, $parentRevId: String, $range: String, $revId: ID!, $seating: String!, $seriesCategories: [ID!]!, $seriesId: String!, $team: String!, $unlinkFromTMNA: Boolean) {
  updateSeriesSetting(
    input: {acceptChanges: $acceptChanges, brand: $brand, estimatedMileage: $estimatedMileage, fuelTypes: $fuelTypes, id: $id, isDeleted: $isDeleted, isPublishable: $isPublishable, language: $language, modelYear: $modelYear, mpge: $mpge, name: $name, parentId: $parentId, parentRevId: $parentRevId, range: $range, revId: $revId, seating: $seating, seriesCategories: $seriesCategories, seriesId: $seriesId, team: $team, unlinkFromTMNA: $unlinkFromTMNA}
  ) {
    __typename
    ... on SeriesSetting {
      ...SeriesSettingFields
    }
    ... on SeriesSubSetting {
      ...SeriesSubSettingFields
    }
  }
}
    ${SeriesSettingFieldsFragmentDoc}
${SeriesSubSettingFieldsFragmentDoc}`;
export type UpdateSeriesSettingMutationFn = Apollo.MutationFunction<UpdateSeriesSettingMutation, UpdateSeriesSettingMutationVariables>;

/**
 * __useUpdateSeriesSettingMutation__
 *
 * To run a mutation, you first call `useUpdateSeriesSettingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSeriesSettingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSeriesSettingMutation, { data, loading, error }] = useUpdateSeriesSettingMutation({
 *   variables: {
 *      acceptChanges: // value for 'acceptChanges'
 *      brand: // value for 'brand'
 *      estimatedMileage: // value for 'estimatedMileage'
 *      fuelTypes: // value for 'fuelTypes'
 *      id: // value for 'id'
 *      isDeleted: // value for 'isDeleted'
 *      isPublishable: // value for 'isPublishable'
 *      language: // value for 'language'
 *      modelYear: // value for 'modelYear'
 *      mpge: // value for 'mpge'
 *      name: // value for 'name'
 *      parentId: // value for 'parentId'
 *      parentRevId: // value for 'parentRevId'
 *      range: // value for 'range'
 *      revId: // value for 'revId'
 *      seating: // value for 'seating'
 *      seriesCategories: // value for 'seriesCategories'
 *      seriesId: // value for 'seriesId'
 *      team: // value for 'team'
 *      unlinkFromTMNA: // value for 'unlinkFromTMNA'
 *   },
 * });
 */
export function useUpdateSeriesSettingMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSeriesSettingMutation, UpdateSeriesSettingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSeriesSettingMutation, UpdateSeriesSettingMutationVariables>(UpdateSeriesSettingDocument, options);
      }
export type UpdateSeriesSettingMutationHookResult = ReturnType<typeof useUpdateSeriesSettingMutation>;
export type UpdateSeriesSettingMutationResult = Apollo.MutationResult<UpdateSeriesSettingMutation>;
export type UpdateSeriesSettingMutationOptions = Apollo.BaseMutationOptions<UpdateSeriesSettingMutation, UpdateSeriesSettingMutationVariables>;
export const GetSortListDocument = gql`
    query getSortList($team: String!, $seriesId: ID!, $modelYear: Int!, $dataType: String!, $status: String, $version: Int) {
  sortList(
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    dataType: $dataType
    status: $status
    version: $version
  ) {
    sortList {
      id
      position
    }
  }
}
    `;

/**
 * __useGetSortListQuery__
 *
 * To run a query within a React component, call `useGetSortListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSortListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSortListQuery({
 *   variables: {
 *      team: // value for 'team'
 *      seriesId: // value for 'seriesId'
 *      modelYear: // value for 'modelYear'
 *      dataType: // value for 'dataType'
 *      status: // value for 'status'
 *      version: // value for 'version'
 *   },
 * });
 */
export function useGetSortListQuery(baseOptions: Apollo.QueryHookOptions<GetSortListQuery, GetSortListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSortListQuery, GetSortListQueryVariables>(GetSortListDocument, options);
      }
export function useGetSortListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSortListQuery, GetSortListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSortListQuery, GetSortListQueryVariables>(GetSortListDocument, options);
        }
export type GetSortListQueryHookResult = ReturnType<typeof useGetSortListQuery>;
export type GetSortListLazyQueryHookResult = ReturnType<typeof useGetSortListLazyQuery>;
export type GetSortListQueryResult = Apollo.QueryResult<GetSortListQuery, GetSortListQueryVariables>;
export const UpdateSortListDocument = gql`
    mutation updateSortList($team: String!, $seriesId: String!, $modelYear: Int!, $dataType: String!, $sortList: [SortItemInput]!) {
  updateSortList(
    input: {team: $team, seriesId: $seriesId, modelYear: $modelYear, dataType: $dataType, sortList: $sortList}
  ) {
    sortList {
      id
      position
    }
  }
}
    `;
export type UpdateSortListMutationFn = Apollo.MutationFunction<UpdateSortListMutation, UpdateSortListMutationVariables>;

/**
 * __useUpdateSortListMutation__
 *
 * To run a mutation, you first call `useUpdateSortListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSortListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSortListMutation, { data, loading, error }] = useUpdateSortListMutation({
 *   variables: {
 *      team: // value for 'team'
 *      seriesId: // value for 'seriesId'
 *      modelYear: // value for 'modelYear'
 *      dataType: // value for 'dataType'
 *      sortList: // value for 'sortList'
 *   },
 * });
 */
export function useUpdateSortListMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSortListMutation, UpdateSortListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSortListMutation, UpdateSortListMutationVariables>(UpdateSortListDocument, options);
      }
export type UpdateSortListMutationHookResult = ReturnType<typeof useUpdateSortListMutation>;
export type UpdateSortListMutationResult = Apollo.MutationResult<UpdateSortListMutation>;
export type UpdateSortListMutationOptions = Apollo.BaseMutationOptions<UpdateSortListMutation, UpdateSortListMutationVariables>;
export const AddSpecTypesDocument = gql`
    mutation addSpecTypes($team: String!, $seriesId: String!, $modelYear: Int!, $specTypes: [VehicleSpecSpecTypeInput!]!, $id: String) {
  createVehicleSpecSpecType(
    input: {team: $team, seriesId: $seriesId, modelYear: $modelYear, specTypes: $specTypes, id: $id}
  ) {
    vehicleSpecsLanguageSpecTypes {
      ...VehicleSpecLanguageSpecTypeFields
    }
  }
}
    ${VehicleSpecLanguageSpecTypeFieldsFragmentDoc}`;
export type AddSpecTypesMutationFn = Apollo.MutationFunction<AddSpecTypesMutation, AddSpecTypesMutationVariables>;

/**
 * __useAddSpecTypesMutation__
 *
 * To run a mutation, you first call `useAddSpecTypesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSpecTypesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSpecTypesMutation, { data, loading, error }] = useAddSpecTypesMutation({
 *   variables: {
 *      team: // value for 'team'
 *      seriesId: // value for 'seriesId'
 *      modelYear: // value for 'modelYear'
 *      specTypes: // value for 'specTypes'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAddSpecTypesMutation(baseOptions?: Apollo.MutationHookOptions<AddSpecTypesMutation, AddSpecTypesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddSpecTypesMutation, AddSpecTypesMutationVariables>(AddSpecTypesDocument, options);
      }
export type AddSpecTypesMutationHookResult = ReturnType<typeof useAddSpecTypesMutation>;
export type AddSpecTypesMutationResult = Apollo.MutationResult<AddSpecTypesMutation>;
export type AddSpecTypesMutationOptions = Apollo.BaseMutationOptions<AddSpecTypesMutation, AddSpecTypesMutationVariables>;
export const AddVehicleSpecDocument = gql`
    mutation addVehicleSpec($team: String!, $seriesId: String!, $modelYear: Int!, $language: String!, $id: String, $categoryId: String!, $specTypeId: String!, $comLangId: String, $description: String!, $shortDescription: String, $link: String, $notes: String, $isInProgress: Boolean, $modelApplicability: [VehicleSpecModelApplicabilityInput!]) {
  createVehicleSpec(
    input: {team: $team, seriesId: $seriesId, modelYear: $modelYear, language: $language, id: $id, categoryId: $categoryId, specTypeId: $specTypeId, comLangId: $comLangId, description: $description, shortDescription: $shortDescription, link: $link, notes: $notes, isInProgress: $isInProgress, modelApplicability: $modelApplicability}
  ) {
    ...VehicleSpecFields
  }
}
    ${VehicleSpecFieldsFragmentDoc}`;
export type AddVehicleSpecMutationFn = Apollo.MutationFunction<AddVehicleSpecMutation, AddVehicleSpecMutationVariables>;

/**
 * __useAddVehicleSpecMutation__
 *
 * To run a mutation, you first call `useAddVehicleSpecMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddVehicleSpecMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addVehicleSpecMutation, { data, loading, error }] = useAddVehicleSpecMutation({
 *   variables: {
 *      team: // value for 'team'
 *      seriesId: // value for 'seriesId'
 *      modelYear: // value for 'modelYear'
 *      language: // value for 'language'
 *      id: // value for 'id'
 *      categoryId: // value for 'categoryId'
 *      specTypeId: // value for 'specTypeId'
 *      comLangId: // value for 'comLangId'
 *      description: // value for 'description'
 *      shortDescription: // value for 'shortDescription'
 *      link: // value for 'link'
 *      notes: // value for 'notes'
 *      isInProgress: // value for 'isInProgress'
 *      modelApplicability: // value for 'modelApplicability'
 *   },
 * });
 */
export function useAddVehicleSpecMutation(baseOptions?: Apollo.MutationHookOptions<AddVehicleSpecMutation, AddVehicleSpecMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddVehicleSpecMutation, AddVehicleSpecMutationVariables>(AddVehicleSpecDocument, options);
      }
export type AddVehicleSpecMutationHookResult = ReturnType<typeof useAddVehicleSpecMutation>;
export type AddVehicleSpecMutationResult = Apollo.MutationResult<AddVehicleSpecMutation>;
export type AddVehicleSpecMutationOptions = Apollo.BaseMutationOptions<AddVehicleSpecMutation, AddVehicleSpecMutationVariables>;
export const DeleteVehicleSpecDocument = gql`
    mutation deleteVehicleSpec($team: String!, $seriesId: String!, $modelYear: Int!, $language: String!, $id: ID!) {
  deleteVehicleSpec(
    input: {team: $team, seriesId: $seriesId, modelYear: $modelYear, language: $language, id: $id}
  ) {
    id
    message
  }
}
    `;
export type DeleteVehicleSpecMutationFn = Apollo.MutationFunction<DeleteVehicleSpecMutation, DeleteVehicleSpecMutationVariables>;

/**
 * __useDeleteVehicleSpecMutation__
 *
 * To run a mutation, you first call `useDeleteVehicleSpecMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVehicleSpecMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVehicleSpecMutation, { data, loading, error }] = useDeleteVehicleSpecMutation({
 *   variables: {
 *      team: // value for 'team'
 *      seriesId: // value for 'seriesId'
 *      modelYear: // value for 'modelYear'
 *      language: // value for 'language'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteVehicleSpecMutation(baseOptions?: Apollo.MutationHookOptions<DeleteVehicleSpecMutation, DeleteVehicleSpecMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteVehicleSpecMutation, DeleteVehicleSpecMutationVariables>(DeleteVehicleSpecDocument, options);
      }
export type DeleteVehicleSpecMutationHookResult = ReturnType<typeof useDeleteVehicleSpecMutation>;
export type DeleteVehicleSpecMutationResult = Apollo.MutationResult<DeleteVehicleSpecMutation>;
export type DeleteVehicleSpecMutationOptions = Apollo.BaseMutationOptions<DeleteVehicleSpecMutation, DeleteVehicleSpecMutationVariables>;
export const GetSpecCategoriesByLangDocument = gql`
    query getSpecCategoriesByLang($team: String!, $seriesId: ID!, $modelYear: Int!, $language: String!, $status: String, $version: Int, $includeAll: Boolean) {
  vehicleSpecsCategories(
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    language: $language
    status: $status
    version: $version
    includeAll: $includeAll
  ) {
    vehicleSpecsCategories {
      id
      name
      isRequired
    }
  }
}
    `;

/**
 * __useGetSpecCategoriesByLangQuery__
 *
 * To run a query within a React component, call `useGetSpecCategoriesByLangQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSpecCategoriesByLangQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSpecCategoriesByLangQuery({
 *   variables: {
 *      team: // value for 'team'
 *      seriesId: // value for 'seriesId'
 *      modelYear: // value for 'modelYear'
 *      language: // value for 'language'
 *      status: // value for 'status'
 *      version: // value for 'version'
 *      includeAll: // value for 'includeAll'
 *   },
 * });
 */
export function useGetSpecCategoriesByLangQuery(baseOptions: Apollo.QueryHookOptions<GetSpecCategoriesByLangQuery, GetSpecCategoriesByLangQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSpecCategoriesByLangQuery, GetSpecCategoriesByLangQueryVariables>(GetSpecCategoriesByLangDocument, options);
      }
export function useGetSpecCategoriesByLangLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSpecCategoriesByLangQuery, GetSpecCategoriesByLangQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSpecCategoriesByLangQuery, GetSpecCategoriesByLangQueryVariables>(GetSpecCategoriesByLangDocument, options);
        }
export type GetSpecCategoriesByLangQueryHookResult = ReturnType<typeof useGetSpecCategoriesByLangQuery>;
export type GetSpecCategoriesByLangLazyQueryHookResult = ReturnType<typeof useGetSpecCategoriesByLangLazyQuery>;
export type GetSpecCategoriesByLangQueryResult = Apollo.QueryResult<GetSpecCategoriesByLangQuery, GetSpecCategoriesByLangQueryVariables>;
export const GetChangeLogSpecsDocument = gql`
    query getChangeLogSpecs($team: String!, $seriesId: ID!, $modelYear: Int!, $language: String!, $status: String, $version: Int) {
  vehicleSpecsChanges(
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    language: $language
    status: $status
    version: $version
  ) {
    body
  }
}
    `;

/**
 * __useGetChangeLogSpecsQuery__
 *
 * To run a query within a React component, call `useGetChangeLogSpecsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChangeLogSpecsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChangeLogSpecsQuery({
 *   variables: {
 *      team: // value for 'team'
 *      seriesId: // value for 'seriesId'
 *      modelYear: // value for 'modelYear'
 *      language: // value for 'language'
 *      status: // value for 'status'
 *      version: // value for 'version'
 *   },
 * });
 */
export function useGetChangeLogSpecsQuery(baseOptions: Apollo.QueryHookOptions<GetChangeLogSpecsQuery, GetChangeLogSpecsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetChangeLogSpecsQuery, GetChangeLogSpecsQueryVariables>(GetChangeLogSpecsDocument, options);
      }
export function useGetChangeLogSpecsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChangeLogSpecsQuery, GetChangeLogSpecsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetChangeLogSpecsQuery, GetChangeLogSpecsQueryVariables>(GetChangeLogSpecsDocument, options);
        }
export type GetChangeLogSpecsQueryHookResult = ReturnType<typeof useGetChangeLogSpecsQuery>;
export type GetChangeLogSpecsLazyQueryHookResult = ReturnType<typeof useGetChangeLogSpecsLazyQuery>;
export type GetChangeLogSpecsQueryResult = Apollo.QueryResult<GetChangeLogSpecsQuery, GetChangeLogSpecsQueryVariables>;
export const GetSpecTypesByLangDocument = gql`
    query getSpecTypesByLang($team: String!, $seriesId: ID!, $modelYear: Int!, $language: String!, $status: String, $version: Int, $includeAll: Boolean) {
  vehicleSpecsSpecTypes(
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    language: $language
    status: $status
    version: $version
    includeAll: $includeAll
  ) {
    vehicleSpecsSpecTypes {
      id
      name
      isRequired
    }
  }
}
    `;

/**
 * __useGetSpecTypesByLangQuery__
 *
 * To run a query within a React component, call `useGetSpecTypesByLangQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSpecTypesByLangQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSpecTypesByLangQuery({
 *   variables: {
 *      team: // value for 'team'
 *      seriesId: // value for 'seriesId'
 *      modelYear: // value for 'modelYear'
 *      language: // value for 'language'
 *      status: // value for 'status'
 *      version: // value for 'version'
 *      includeAll: // value for 'includeAll'
 *   },
 * });
 */
export function useGetSpecTypesByLangQuery(baseOptions: Apollo.QueryHookOptions<GetSpecTypesByLangQuery, GetSpecTypesByLangQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSpecTypesByLangQuery, GetSpecTypesByLangQueryVariables>(GetSpecTypesByLangDocument, options);
      }
export function useGetSpecTypesByLangLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSpecTypesByLangQuery, GetSpecTypesByLangQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSpecTypesByLangQuery, GetSpecTypesByLangQueryVariables>(GetSpecTypesByLangDocument, options);
        }
export type GetSpecTypesByLangQueryHookResult = ReturnType<typeof useGetSpecTypesByLangQuery>;
export type GetSpecTypesByLangLazyQueryHookResult = ReturnType<typeof useGetSpecTypesByLangLazyQuery>;
export type GetSpecTypesByLangQueryResult = Apollo.QueryResult<GetSpecTypesByLangQuery, GetSpecTypesByLangQueryVariables>;
export const GetVehicleSpecsDocument = gql`
    query getVehicleSpecs($team: String!, $seriesId: ID!, $modelYear: Int!, $language: String!, $status: String, $version: Int) {
  vehicleSpecs(
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    language: $language
    status: $status
    version: $version
  ) {
    vehicleSpecs {
      ...VehicleSpecFields
    }
  }
}
    ${VehicleSpecFieldsFragmentDoc}`;

/**
 * __useGetVehicleSpecsQuery__
 *
 * To run a query within a React component, call `useGetVehicleSpecsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVehicleSpecsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVehicleSpecsQuery({
 *   variables: {
 *      team: // value for 'team'
 *      seriesId: // value for 'seriesId'
 *      modelYear: // value for 'modelYear'
 *      language: // value for 'language'
 *      status: // value for 'status'
 *      version: // value for 'version'
 *   },
 * });
 */
export function useGetVehicleSpecsQuery(baseOptions: Apollo.QueryHookOptions<GetVehicleSpecsQuery, GetVehicleSpecsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetVehicleSpecsQuery, GetVehicleSpecsQueryVariables>(GetVehicleSpecsDocument, options);
      }
export function useGetVehicleSpecsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetVehicleSpecsQuery, GetVehicleSpecsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetVehicleSpecsQuery, GetVehicleSpecsQueryVariables>(GetVehicleSpecsDocument, options);
        }
export type GetVehicleSpecsQueryHookResult = ReturnType<typeof useGetVehicleSpecsQuery>;
export type GetVehicleSpecsLazyQueryHookResult = ReturnType<typeof useGetVehicleSpecsLazyQuery>;
export type GetVehicleSpecsQueryResult = Apollo.QueryResult<GetVehicleSpecsQuery, GetVehicleSpecsQueryVariables>;
export const RevertChangeLogSpecsDocument = gql`
    mutation revertChangeLogSpecs($brand: String!, $team: String!, $seriesId: String!, $modelYear: Int!, $language: String!, $body: JSON!) {
  revertVehicleSpecsChanges(
    input: {brand: $brand, team: $team, seriesId: $seriesId, modelYear: $modelYear, language: $language, body: $body}
  ) {
    body
  }
}
    `;
export type RevertChangeLogSpecsMutationFn = Apollo.MutationFunction<RevertChangeLogSpecsMutation, RevertChangeLogSpecsMutationVariables>;

/**
 * __useRevertChangeLogSpecsMutation__
 *
 * To run a mutation, you first call `useRevertChangeLogSpecsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevertChangeLogSpecsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revertChangeLogSpecsMutation, { data, loading, error }] = useRevertChangeLogSpecsMutation({
 *   variables: {
 *      brand: // value for 'brand'
 *      team: // value for 'team'
 *      seriesId: // value for 'seriesId'
 *      modelYear: // value for 'modelYear'
 *      language: // value for 'language'
 *      body: // value for 'body'
 *   },
 * });
 */
export function useRevertChangeLogSpecsMutation(baseOptions?: Apollo.MutationHookOptions<RevertChangeLogSpecsMutation, RevertChangeLogSpecsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RevertChangeLogSpecsMutation, RevertChangeLogSpecsMutationVariables>(RevertChangeLogSpecsDocument, options);
      }
export type RevertChangeLogSpecsMutationHookResult = ReturnType<typeof useRevertChangeLogSpecsMutation>;
export type RevertChangeLogSpecsMutationResult = Apollo.MutationResult<RevertChangeLogSpecsMutation>;
export type RevertChangeLogSpecsMutationOptions = Apollo.BaseMutationOptions<RevertChangeLogSpecsMutation, RevertChangeLogSpecsMutationVariables>;
export const UpdateSpecTypeDocument = gql`
    mutation updateSpecType($team: String!, $seriesId: String!, $modelYear: Int!, $language: String!, $id: ID!, $name: String!) {
  updateVehicleSpecSpecType(
    input: {team: $team, seriesId: $seriesId, modelYear: $modelYear, language: $language, id: $id, name: $name}
  ) {
    id
    name
    isRequired
  }
}
    `;
export type UpdateSpecTypeMutationFn = Apollo.MutationFunction<UpdateSpecTypeMutation, UpdateSpecTypeMutationVariables>;

/**
 * __useUpdateSpecTypeMutation__
 *
 * To run a mutation, you first call `useUpdateSpecTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSpecTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSpecTypeMutation, { data, loading, error }] = useUpdateSpecTypeMutation({
 *   variables: {
 *      team: // value for 'team'
 *      seriesId: // value for 'seriesId'
 *      modelYear: // value for 'modelYear'
 *      language: // value for 'language'
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateSpecTypeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSpecTypeMutation, UpdateSpecTypeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSpecTypeMutation, UpdateSpecTypeMutationVariables>(UpdateSpecTypeDocument, options);
      }
export type UpdateSpecTypeMutationHookResult = ReturnType<typeof useUpdateSpecTypeMutation>;
export type UpdateSpecTypeMutationResult = Apollo.MutationResult<UpdateSpecTypeMutation>;
export type UpdateSpecTypeMutationOptions = Apollo.BaseMutationOptions<UpdateSpecTypeMutation, UpdateSpecTypeMutationVariables>;
export const UpdateVehicleSpecDocument = gql`
    mutation updateVehicleSpec($team: String!, $seriesId: String!, $modelYear: Int!, $language: String!, $id: ID!, $revId: String!, $categoryId: String!, $specTypeId: String!, $comLangId: String, $description: String!, $shortDescription: String, $acceptChanges: Boolean, $link: String, $notes: String, $isInProgress: Boolean, $modelApplicability: [VehicleFeatureModelApplicabilityInput!], $unlinkFromTMNA: Boolean) {
  updateVehicleSpec(
    input: {team: $team, seriesId: $seriesId, modelYear: $modelYear, language: $language, id: $id, revId: $revId, categoryId: $categoryId, specTypeId: $specTypeId, comLangId: $comLangId, description: $description, shortDescription: $shortDescription, acceptChanges: $acceptChanges, link: $link, notes: $notes, isInProgress: $isInProgress, modelApplicability: $modelApplicability, unlinkFromTMNA: $unlinkFromTMNA}
  ) {
    ...VehicleSpecFields
  }
}
    ${VehicleSpecFieldsFragmentDoc}`;
export type UpdateVehicleSpecMutationFn = Apollo.MutationFunction<UpdateVehicleSpecMutation, UpdateVehicleSpecMutationVariables>;

/**
 * __useUpdateVehicleSpecMutation__
 *
 * To run a mutation, you first call `useUpdateVehicleSpecMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVehicleSpecMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVehicleSpecMutation, { data, loading, error }] = useUpdateVehicleSpecMutation({
 *   variables: {
 *      team: // value for 'team'
 *      seriesId: // value for 'seriesId'
 *      modelYear: // value for 'modelYear'
 *      language: // value for 'language'
 *      id: // value for 'id'
 *      revId: // value for 'revId'
 *      categoryId: // value for 'categoryId'
 *      specTypeId: // value for 'specTypeId'
 *      comLangId: // value for 'comLangId'
 *      description: // value for 'description'
 *      shortDescription: // value for 'shortDescription'
 *      acceptChanges: // value for 'acceptChanges'
 *      link: // value for 'link'
 *      notes: // value for 'notes'
 *      isInProgress: // value for 'isInProgress'
 *      modelApplicability: // value for 'modelApplicability'
 *      unlinkFromTMNA: // value for 'unlinkFromTMNA'
 *   },
 * });
 */
export function useUpdateVehicleSpecMutation(baseOptions?: Apollo.MutationHookOptions<UpdateVehicleSpecMutation, UpdateVehicleSpecMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateVehicleSpecMutation, UpdateVehicleSpecMutationVariables>(UpdateVehicleSpecDocument, options);
      }
export type UpdateVehicleSpecMutationHookResult = ReturnType<typeof useUpdateVehicleSpecMutation>;
export type UpdateVehicleSpecMutationResult = Apollo.MutationResult<UpdateVehicleSpecMutation>;
export type UpdateVehicleSpecMutationOptions = Apollo.BaseMutationOptions<UpdateVehicleSpecMutation, UpdateVehicleSpecMutationVariables>;
export const AddSubCategoriesDocument = gql`
    mutation addSubCategories($team: String!, $seriesId: String!, $modelYear: Int!, $subCategories: [VehicleFeatureSubCategoryInput!]!, $id: String) {
  createVehicleFeatureSubCategory(
    input: {team: $team, seriesId: $seriesId, modelYear: $modelYear, subCategories: $subCategories, id: $id}
  ) {
    vehicleFeaturesLanguageSubCategories {
      ...VehicleLanguageFeatureSubCategoryFields
    }
  }
}
    ${VehicleLanguageFeatureSubCategoryFieldsFragmentDoc}`;
export type AddSubCategoriesMutationFn = Apollo.MutationFunction<AddSubCategoriesMutation, AddSubCategoriesMutationVariables>;

/**
 * __useAddSubCategoriesMutation__
 *
 * To run a mutation, you first call `useAddSubCategoriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSubCategoriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSubCategoriesMutation, { data, loading, error }] = useAddSubCategoriesMutation({
 *   variables: {
 *      team: // value for 'team'
 *      seriesId: // value for 'seriesId'
 *      modelYear: // value for 'modelYear'
 *      subCategories: // value for 'subCategories'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAddSubCategoriesMutation(baseOptions?: Apollo.MutationHookOptions<AddSubCategoriesMutation, AddSubCategoriesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddSubCategoriesMutation, AddSubCategoriesMutationVariables>(AddSubCategoriesDocument, options);
      }
export type AddSubCategoriesMutationHookResult = ReturnType<typeof useAddSubCategoriesMutation>;
export type AddSubCategoriesMutationResult = Apollo.MutationResult<AddSubCategoriesMutation>;
export type AddSubCategoriesMutationOptions = Apollo.BaseMutationOptions<AddSubCategoriesMutation, AddSubCategoriesMutationVariables>;
export const GetSubCategoriesByLangDocument = gql`
    query getSubCategoriesByLang($team: String!, $seriesId: ID!, $modelYear: Int!, $language: String!, $status: String, $version: Int, $includeAll: Boolean) {
  vehicleFeaturesSubCategories(
    team: $team
    seriesId: $seriesId
    modelYear: $modelYear
    language: $language
    status: $status
    version: $version
    includeAll: $includeAll
  ) {
    vehicleFeaturesSubCategories {
      ...VehicleFeaturesSubCategoryFields
    }
  }
}
    ${VehicleFeaturesSubCategoryFieldsFragmentDoc}`;

/**
 * __useGetSubCategoriesByLangQuery__
 *
 * To run a query within a React component, call `useGetSubCategoriesByLangQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubCategoriesByLangQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubCategoriesByLangQuery({
 *   variables: {
 *      team: // value for 'team'
 *      seriesId: // value for 'seriesId'
 *      modelYear: // value for 'modelYear'
 *      language: // value for 'language'
 *      status: // value for 'status'
 *      version: // value for 'version'
 *      includeAll: // value for 'includeAll'
 *   },
 * });
 */
export function useGetSubCategoriesByLangQuery(baseOptions: Apollo.QueryHookOptions<GetSubCategoriesByLangQuery, GetSubCategoriesByLangQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSubCategoriesByLangQuery, GetSubCategoriesByLangQueryVariables>(GetSubCategoriesByLangDocument, options);
      }
export function useGetSubCategoriesByLangLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSubCategoriesByLangQuery, GetSubCategoriesByLangQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSubCategoriesByLangQuery, GetSubCategoriesByLangQueryVariables>(GetSubCategoriesByLangDocument, options);
        }
export type GetSubCategoriesByLangQueryHookResult = ReturnType<typeof useGetSubCategoriesByLangQuery>;
export type GetSubCategoriesByLangLazyQueryHookResult = ReturnType<typeof useGetSubCategoriesByLangLazyQuery>;
export type GetSubCategoriesByLangQueryResult = Apollo.QueryResult<GetSubCategoriesByLangQuery, GetSubCategoriesByLangQueryVariables>;
export const UpdateSubCategoryDocument = gql`
    mutation updateSubCategory($team: String!, $seriesId: String!, $modelYear: Int!, $language: String!, $id: ID!, $name: String!) {
  updateVehicleFeatureSubCategory(
    input: {team: $team, seriesId: $seriesId, modelYear: $modelYear, language: $language, id: $id, name: $name}
  ) {
    id
    name
  }
}
    `;
export type UpdateSubCategoryMutationFn = Apollo.MutationFunction<UpdateSubCategoryMutation, UpdateSubCategoryMutationVariables>;

/**
 * __useUpdateSubCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateSubCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubCategoryMutation, { data, loading, error }] = useUpdateSubCategoryMutation({
 *   variables: {
 *      team: // value for 'team'
 *      seriesId: // value for 'seriesId'
 *      modelYear: // value for 'modelYear'
 *      language: // value for 'language'
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateSubCategoryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSubCategoryMutation, UpdateSubCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSubCategoryMutation, UpdateSubCategoryMutationVariables>(UpdateSubCategoryDocument, options);
      }
export type UpdateSubCategoryMutationHookResult = ReturnType<typeof useUpdateSubCategoryMutation>;
export type UpdateSubCategoryMutationResult = Apollo.MutationResult<UpdateSubCategoryMutation>;
export type UpdateSubCategoryMutationOptions = Apollo.BaseMutationOptions<UpdateSubCategoryMutation, UpdateSubCategoryMutationVariables>;