import { observable, makeObservable } from 'mobx';

class DraftStore {
  selectedTab = 'Features';
  submitted = false;

  constructor() {
    makeObservable(this, {
      selectedTab: observable,
      submitted: observable
    });
  }
}

export default DraftStore;
