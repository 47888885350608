import apolloClient from '../apolloClient';
import { STATUS_DRAFT } from '../constants/vehicleData/VDConstants';
import {
  AddCategoriesSpecDocument,
  AddCategoriesSpecMutation,
  AddCategoriesSpecMutationVariables,
  AddSpecTypesDocument,
  AddSpecTypesMutation,
  AddSpecTypesMutationVariables,
  AddVehicleSpecDocument,
  AddVehicleSpecMutation,
  AddVehicleSpecMutationVariables,
  DeleteVehicleSpecDocument,
  DeleteVehicleSpecMutation,
  DeleteVehicleSpecMutationVariables,
  GetChangeLogSpecsDocument,
  GetChangeLogSpecsQuery,
  GetChangeLogSpecsQueryVariables,
  GetSpecCategoriesByLangDocument,
  GetSpecCategoriesByLangQuery,
  GetSpecCategoriesByLangQueryVariables,
  GetSpecTypesByLangDocument,
  GetSpecTypesByLangQuery,
  GetSpecTypesByLangQueryVariables,
  GetVehicleSpecsDocument,
  GetVehicleSpecsQuery,
  GetVehicleSpecsQueryVariables,
  RevertChangeLogSpecsDocument,
  RevertChangeLogSpecsMutation,
  RevertChangeLogSpecsMutationVariables,
  UpdateCategorySpecDocument,
  UpdateCategorySpecMutation,
  UpdateCategorySpecMutationVariables,
  UpdateSpecTypeDocument,
  UpdateSpecTypeMutation,
  UpdateSpecTypeMutationVariables,
  UpdateVehicleSpecDocument,
  UpdateVehicleSpecMutation,
  UpdateVehicleSpecMutationVariables,
  VehicleSpec as VehicleSpecGQL,
  VehicleSpecsResponse,
} from '../gql/generated';
import { RefItemLanguageMap } from '../models/category.model';
import { ChangeLogRequest, ChangeLogResponse } from '../models/changeLog.model';
import { RefItemResponse } from '../models/refItem.model';
import { SpecRequest, SpecResponse, SpecsReviewRequest, SpecsReviewResponse } from '../models/specs.model';
import { VehicleTeam } from '../models/vehicleData.model';
import { vehicleSpecsResponseToSpecResponse } from '../utils/specsUtils';
import { getStatusAndVersionGqlReq } from '../utils/vehicleDataUtils';
import API from '../webservices/api';

const RESOURCE_PATH = '/vehicle-specs';

const VEHICLE_SPECS_PATH = '/specs';
export const getVehicleSpecs = (brand: string, team: VehicleTeam, seriesId: string, modelYear: string, language: string, version?: string) =>
  apolloClient
    .query<GetVehicleSpecsQuery, GetVehicleSpecsQueryVariables>({
      query: GetVehicleSpecsDocument,
      fetchPolicy: 'no-cache',
      variables: {
        team,
        seriesId,
        modelYear: Number(modelYear),
        language,
        ...getStatusAndVersionGqlReq(version),
      },
    })
    .then(res => ({
      ...res,
      data: vehicleSpecsResponseToSpecResponse(res.data.vehicleSpecs as VehicleSpecsResponse),
    }));

export const addVehicleSpec = (brand: string, team: VehicleTeam, seriesId: string, modelYear: string, language: string, payload: SpecRequest) =>
  apolloClient
    .mutate<AddVehicleSpecMutation, AddVehicleSpecMutationVariables>({
      mutation: AddVehicleSpecDocument,
      fetchPolicy: 'no-cache',
      variables: {
        team,
        seriesId,
        modelYear: Number(modelYear),
        language,
        ...payload,
        specTypeId: payload.specTypeId as string,
        modelApplicability: Object.entries(payload.modelApplicability).map(([key, value]) => ({ id: key, text: value })),
      },
    })
    .then(res => {
      const vehicleSpec: VehicleSpecsResponse = {
        vehicleSpecs: [res.data?.createVehicleSpec as VehicleSpecGQL],
      };

      return {
        ...res,
        data: vehicleSpecsResponseToSpecResponse(vehicleSpec)[0],
      };
    });

export const updateVehicleSpec = (
  brand: string,
  team: VehicleTeam,
  seriesId: string,
  modelYear: string,
  payload: SpecRequest,
  language: string,
  acceptChanges: boolean = false,
  unlinkFromTMNA: boolean = false,
) =>
  apolloClient
    .mutate<UpdateVehicleSpecMutation, UpdateVehicleSpecMutationVariables>({
      mutation: UpdateVehicleSpecDocument,
      fetchPolicy: 'no-cache',
      variables: {
        team,
        seriesId,
        modelYear: Number(modelYear),
        language,
        ...payload,
        specTypeId: payload.specTypeId as string,
        modelApplicability: Object.entries(payload.modelApplicability).map(([key, value]) => ({ id: key, text: value })),
        acceptChanges: acceptChanges || unlinkFromTMNA,
        unlinkFromTMNA,
      },
    })
    .then(res => {
      const vehicleSpec: VehicleSpecsResponse = {
        vehicleSpecs: [res.data?.updateVehicleSpec as VehicleSpecGQL],
      };

      return {
        ...res,
        data: vehicleSpecsResponseToSpecResponse(vehicleSpec)[0],
      };
    });

export const deleteVehicleSpec = (brand: string, team: VehicleTeam, seriesId: string, modelYear: string, language: string, specId: string) =>
  apolloClient.mutate<DeleteVehicleSpecMutation, DeleteVehicleSpecMutationVariables>({
    mutation: DeleteVehicleSpecDocument,
    fetchPolicy: 'no-cache',
    variables: {
      team,
      seriesId,
      modelYear: Number(modelYear),
      language,
      id: specId,
    },
  });

export const importFromCommonLanguage = (brand: string, team: VehicleTeam, series: string, year: string, payload: string[]) => {
  return API.post<SpecResponse[]>(`${RESOURCE_PATH}${VEHICLE_SPECS_PATH}/${brand}/${team}/${series}/${year}/import`, { commonLangIds: payload });
};

// ChangeLog
export const getChangeLog = (brand: string, team: VehicleTeam, seriesId: string, modelYear: string, language: string, version: string) =>
  apolloClient
    .query<GetChangeLogSpecsQuery, GetChangeLogSpecsQueryVariables>({
      query: GetChangeLogSpecsDocument,
      fetchPolicy: 'no-cache',
      variables: {
        team,
        seriesId,
        modelYear: Number(modelYear),
        language,
        ...getStatusAndVersionGqlReq(version),
      },
    })
    .then(res => ({
      ...res,
      data: JSON.parse(res.data.vehicleSpecsChanges?.body) as ChangeLogResponse[],
    }));

export const revertChange = (brand: string, team: VehicleTeam, seriesId: string, modelYear: string, language: string, payload: ChangeLogRequest) =>
  apolloClient
    .mutate<RevertChangeLogSpecsMutation, RevertChangeLogSpecsMutationVariables>({
      mutation: RevertChangeLogSpecsDocument,
      fetchPolicy: 'no-cache',
      variables: {
        brand,
        team,
        seriesId,
        modelYear: Number(modelYear),
        language,
        body: payload,
      },
    })
    .then(res => ({
      ...res,
      data: JSON.parse(res.data?.revertVehicleSpecsChanges?.body),
    }));

// Categories
export const getCategoriesByLang = (brand: string, team: VehicleTeam, seriesId: string, modelYear: string, language: string, version?: string, includeAll?: boolean) =>
  apolloClient
    .query<GetSpecCategoriesByLangQuery, GetSpecCategoriesByLangQueryVariables>({
      query: GetSpecCategoriesByLangDocument,
      fetchPolicy: 'no-cache',
      variables: {
        team,
        seriesId,
        modelYear: Number(modelYear),
        language,
        includeAll,
        ...getStatusAndVersionGqlReq(version),
      },
    })
    .then(res => {
      const { data } = res;
      const specCategories = data.vehicleSpecsCategories?.vehicleSpecsCategories ?? [];
      const sCats = specCategories.map(({ id, name, isRequired }) => ({
        id,
        object: { id, name, isRequired },
      }));

      return {
        ...res,
        data: sCats,
      };
    });

export const addCategories = (brand: string, team: VehicleTeam, seriesId: string, modelYear: string, payload: { [lang: string]: string }, id?: string) =>
  apolloClient
    .mutate<AddCategoriesSpecMutation, AddCategoriesSpecMutationVariables>({
      mutation: AddCategoriesSpecDocument,
      fetchPolicy: 'no-cache',
      variables: {
        team,
        seriesId,
        modelYear: Number(modelYear),
        categories: Object.entries(payload).map(([key, value]) => ({
          language: key,
          name: value,
        })),
        id,
      },
    })
    .then(res => {
      const data: RefItemLanguageMap = {};
      res.data?.createVehicleSpecCategory?.vehicleSpecsLanguageCategories?.forEach(({ language, vehicleSpecsCategories }) => {
        data[language] =
          vehicleSpecsCategories?.map(cat => ({
            id: cat.id,
            object: {
              id: cat.id,
              isDeleted: cat.isDeleted as boolean,
              isRequired: cat.isRequired as boolean,
              name: cat.name,
            },
          })) ?? [];
      });

      return {
        ...res,
        data,
      };
    });

export const updateCategory = (brand: string, team: VehicleTeam, seriesId: string, modelYear: string, language: string, id: string, name: string) =>
  apolloClient.mutate<UpdateCategorySpecMutation, UpdateCategorySpecMutationVariables>({
    mutation: UpdateCategorySpecDocument,
    fetchPolicy: 'no-cache',
    variables: {
      team,
      seriesId,
      modelYear: Number(modelYear),
      language,
      id,
      name,
    },
  });

// Spec Types
export const getSpecTypesByLang = (brand: string, team: VehicleTeam, seriesId: string, modelYear: string, language: string, version?: string, includeAll?: boolean) =>
  apolloClient
    .query<GetSpecTypesByLangQuery, GetSpecTypesByLangQueryVariables>({
      query: GetSpecTypesByLangDocument,
      fetchPolicy: 'no-cache',
      variables: {
        team,
        seriesId,
        modelYear: Number(modelYear),
        language,
        includeAll,
        ...getStatusAndVersionGqlReq(version),
      },
    })
    .then(res => {
      const { data } = res;
      const specTypes = data.vehicleSpecsSpecTypes?.vehicleSpecsSpecTypes ?? [];
      const sTypes = specTypes.map(({ id, name, isRequired }) => ({
        id,
        object: { id, name, isRequired },
      }));

      return {
        ...res,
        data: sTypes,
      };
    });

export const addSpecTypes = (brand: string, team: VehicleTeam, seriesId: string, modelYear: string, payload: { [lang: string]: string }, id?: string) =>
  apolloClient
    .mutate<AddSpecTypesMutation, AddSpecTypesMutationVariables>({
      mutation: AddSpecTypesDocument,
      fetchPolicy: 'no-cache',
      variables: {
        team,
        seriesId,
        modelYear: Number(modelYear),
        specTypes: Object.entries(payload).map(([key, value]) => ({
          language: key,
          name: value,
        })),
        id,
      },
    })
    .then(res => {
      const data: RefItemLanguageMap = {};
      res.data?.createVehicleSpecSpecType?.vehicleSpecsLanguageSpecTypes?.forEach(({ language, vehicleSpecsSpecTypes }) => {
        data[language] =
          vehicleSpecsSpecTypes?.map(specType => ({
            id: specType.id,
            object: {
              id: specType.id,
              isDeleted: false,
              isRequired: specType.isRequired as boolean,
              name: specType.name,
            },
          })) ?? [];
      });

      return {
        ...res,
        data,
      };
    });

export const updateSpecType = (brand: string, team: VehicleTeam, seriesId: string, modelYear: string, language: string, id: string, name: string) =>
  apolloClient.mutate<UpdateSpecTypeMutation, UpdateSpecTypeMutationVariables>({
    mutation: UpdateSpecTypeDocument,
    fetchPolicy: 'no-cache',
    variables: {
      team,
      seriesId,
      modelYear: Number(modelYear),
      language,
      id,
      name,
    },
  });

// Review
const REVIEW_PATH = '/review';
const CATEGORIES_PATH = '/categories';
export const getReviewCategories = (brand: string, team: VehicleTeam, series: string, year: string, version?: string) => {
  const params = !version || version === 'DRAFT' ? STATUS_DRAFT : `version=${version}`;
  return API.get<RefItemResponse[]>(`${RESOURCE_PATH}${REVIEW_PATH}${CATEGORIES_PATH}/${brand}/${team}/${series}/${year}?${params}`);
};

const SPEC_TYPES_PATH = '/spec-types';
export const getReviewSpecTypes = (brand: string, team: VehicleTeam, series: string, year: string, version?: string) => {
  const params = !version || version === 'DRAFT' ? STATUS_DRAFT : `version=${version}`;
  return API.get<RefItemResponse[]>(`${RESOURCE_PATH}${REVIEW_PATH}${SPEC_TYPES_PATH}/${brand}/${team}/${series}/${year}?${params}`);
};

export const getReviewSpec = (brand: string, team: VehicleTeam, series: string, year: string, version?: string) => {
  const params = !version || version === 'DRAFT' ? STATUS_DRAFT : `version=${version}`;
  return API.get<SpecsReviewResponse[]>(`${RESOURCE_PATH}${REVIEW_PATH}${VEHICLE_SPECS_PATH}/${brand}/${team}/${series}/${year}?${params}`);
};

export const updateReviewSpec = (brand: string, team: VehicleTeam, series: string, year: string, version: string, payload: SpecsReviewRequest) => {
  const params = !version || version === 'DRAFT' ? STATUS_DRAFT : `version=${version}`;
  return API.put<SpecsReviewRequest>(`${RESOURCE_PATH}${REVIEW_PATH}${VEHICLE_SPECS_PATH}/${brand}/${team}/${series}/${year}?${params}`, payload);
};

// Review Common Language
const REVIEW_CL_PATH = '/review-cl';
export const getReviewCLSpec = (brand: string, team: VehicleTeam, series: string, year: string, version?: string) => {
  const params = !version || version === 'DRAFT' ? STATUS_DRAFT : `version=${version}`;
  return API.get<SpecsReviewResponse[]>(`${RESOURCE_PATH}${REVIEW_CL_PATH}${VEHICLE_SPECS_PATH}/${brand}/${team}/${series}/${year}?${params}`);
};

export const updateReviewCLSpec = (brand: string, team: VehicleTeam, series: string, year: string, version: string, payload: SpecsReviewRequest) => {
  const params = !version || version === 'DRAFT' ? STATUS_DRAFT : `version=${version}`;
  return API.put<SpecsReviewRequest>(`${RESOURCE_PATH}${REVIEW_CL_PATH}${VEHICLE_SPECS_PATH}/${brand}/${team}/${series}/${year}?${params}`, payload);
};
