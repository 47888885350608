import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { trackPromise } from 'react-promise-tracker';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import { ActionBarDivider, IconTextButton, Modal, Spinner, TwoTableWrapper, useDebounce } from 'vapi-ui-common';
import SortButton from '../../../../components/sortModule/SortButton';
import { langNameMap } from '../../../../constants/vehicleData/VDConstants';
import useStores from '../../../../hooks/useStores';
import { AppliedChangesResponse, ChangeLogTypes } from '../../../../models/changeLog.model';
import { ColorItem, ColorItemLangMap, InteriorColorItem, InteriorColorLangItemMap, InteriorColorLangMap, InteriorItem } from '../../../../models/colors.model';
import { VDSortableEntity } from '../../../../models/sort.model';
import { Language } from '../../../../models/user.model';
import { VehicleTeam } from '../../../../models/vehicleData.model';
import ActionBarVehicleData from '../../../../routes/vehicleData/components/ActionBarVehicleData';
import LeftTable from '../../../../routes/vehicleData/components/LeftTable';
import { ProductDataControllerProps } from '../../../../routes/vehicleData/models/controllers.model';
import { interiorColorItemXForm } from '../../../../utils/colorUtils';
import { tokensXForm } from '../../../../utils/disclaimersUtils';
import { handleErrorResponse } from '../../../../utils/errorHandlingUtils';
import getLangActionBarButtons from '../../../../utils/getLangActionBarButtons';
import { getSortPayload } from '../../../../utils/sortUtils';
import { syncSpanishUpdates, updateSortList } from '../../../../webservices/vehicleAdminApi';
import { addExteriorColor, addInteriorColor, deleteExteriorColor, deleteInteriorColor, updateExteriorColor, updateInteriorColor } from '../../../../webservices/vehicleColorsApi';
import AddEditInteriorColorModal from '../../components/AddEditInteriorColor';
import SyncTMNAChangesModal from '../../components/SyncTMNAChangesModal/SyncTMNAChangesModal';
import ColorsTabHeaderRow from '../colors/components/ColorsTabHeaderRow';
import ColorAccessibilityLangTable from './components/ColorAccessibilityLangTable';
import ColorsFilters from './components/ColorsFilters';
import ColorsFormRowsContainer from './components/ColorsFormRow/ColorsFormRowsContainer';

const ColorsController = ({ readOnly, team, seriesId, year, version, vehicleModels, versionInfo, isPublished, reloadDraft, sourceVersion }: ProductDataControllerProps) => {
  const {
    colorsStore,
    userStore: { brand },
    teamStore,
    vehicleModelsStore,
    disclaimersStore: { tokens },
  } = useStores();

  const disclaimerTokens = tokensXForm(tokens);

  const { debounce } = useDebounce({ delay: 2000 });
  const [isLoaded, setIsLoaded] = useState(false);
  const [addIntColor, setaddIntColor] = useState(false);
  const [editInteriorColor, seteditInteriorColor] = useState(false);
  const [interiorColor, setSelectedInteriorColorItem] = useState<InteriorColorLangItemMap>();
  const [lastUpdated, setLastUpdated] = useState(new Date());
  const [sortMode, setSortMode] = useState(false);
  const [showSyncChangesModal, setShowSyncChangesModal] = useState(false);
  const [syncChangesExteriorColor, setSyncChangesExteriorColor] = useState<ColorItemLangMap | undefined>(undefined);
  const [syncChangesInteriorColor, setSyncChangesInteriorColor] = useState<InteriorColorLangItemMap | undefined>(undefined);

  useEffect(() => {
    setIsLoaded(false);
    colorsStore.reset();

    (async () => {
      try {
        await colorsStore.fetchData(brand, team, seriesId, year, vehicleModelsStore.sortedGrades, teamStore.team.langPermissions, versionInfo);
      } catch (e) {
        console.log(e);
        toast.error('Error loading colors data');
      }
      setIsLoaded(true);
    })();
  }, [colorsStore, brand, team, seriesId, year, version, vehicleModelsStore, lastUpdated, versionInfo, teamStore]);

  const updateExteriorColorItem = async (color: ColorItem, lang: string, intItem?: InteriorItem, acceptChanges: boolean = false, unlinkFromTMNA: boolean = false) => {
    try {
      if (intItem) {
        const colorApplicability = color.colorApplicability.filter(item => {
          return item.interiorColorId === intItem.interiorItem.id && item.grade === intItem.grade.id;
        });
        if (intItem.checked && colorApplicability.length === 0) {
          color.colorApplicability.push({
            interiorColorId: intItem.interiorItem.id,
            grade: intItem.grade.id,
          });
        } else if (!intItem.checked && colorApplicability.length) {
          const ind = color.colorApplicability.indexOf(colorApplicability[0]);
          color.colorApplicability.splice(ind, 1);
        }
      }
      debounce(async () => {
        const response = await trackPromise(updateExteriorColor(brand, team, seriesId, year, lang, color.payload, acceptChanges, unlinkFromTMNA));
        color.revId = response.data.revId;
        if (acceptChanges || unlinkFromTMNA) {
          color.changedAttributes = [];
        }
        if (unlinkFromTMNA) {
          color.fromTMNA = false;
        }
        toast.success(`${langNameMap[lang]} Exterior color updated successfully`);
      }, color.uid);
    } catch (e) {
      handleErrorResponse(e, 'Color failed update');
    }
  };

  const addExteriorColorItem = async (color: ColorItem, lang: string) => {
    if (color.isValid(teamStore.team.isHexCodeRequired)) {
      try {
        debounce(async () => {
          const response = await trackPromise(addExteriorColor(brand, team, seriesId, year, lang, color.payload));
          color.id = response.data.id;
          color.revId = response.data.revId;
          toast.success(`${langNameMap[lang]} Exterior color added successfully`);
        }, color.uid);
      } catch (e) {
        handleErrorResponse(e, 'Exterior color failed add');
      }
    } else {
      showFormFieldError();
    }
  };

  const saveExteriorColorLangMap = async (
    colorLangMap: ColorItemLangMap,
    intItem?: InteriorItem,
    lang?: string,
    acceptChanges: boolean = false,
    unlinkFromTMNA: boolean = false,
  ) => {
    if (lang && !colorsStore.langWriteMap[lang as Language]?.canEdit) {
      toast.error(`You do not have permissions to update ${langNameMap[lang]} colors.`);
      return;
    }
    const langs = lang ? [lang] : colorsStore.editableLangs;
    const promises: Promise<any>[] = [];
    let numValid = 0;
    for (const lang of colorsStore.editableLangs) {
      // check to make sure all colors either have a revid or are valid
      const color = colorLangMap.langs[lang];
      if (!color.revId && !color.isValid(teamStore.team.isHexCodeRequired)) {
        showFormFieldError();
        return;
      } else if (!color.revId && !langs.includes(lang)) {
        // see features controller for reasoning
        langs.push(lang);
      }
    }
    for (const lang of langs) {
      const color = colorLangMap.langs[lang];
      if (color.isValid(teamStore.team.isHexCodeRequired)) {
        numValid++;
        if (color.revId) {
          promises.push(updateExteriorColorItem(color, lang, intItem, acceptChanges, unlinkFromTMNA));
        } else {
          promises.push(addExteriorColorItem(color, lang));
        }
      }
    }
    if (!numValid) {
      showFormFieldError();
    } else {
      await Promise.all(promises);
    }
  };

  const deleteExteriorColorMap = async (colorMap: ColorItemLangMap) => {
    try {
      const uid = colorMap.langs[colorsStore.defaultLang].uid;
      for (const lang of colorsStore.editableLangs) {
        const color = colorMap.langs[lang];
        if (color.revId) {
          await trackPromise(deleteExteriorColor(brand, team, seriesId, year, lang, color.id));
        }
      }
      colorsStore.deleteItem(uid);
      toast.success('Exterior color deleted successfully');
    } catch (e) {
      handleErrorResponse(e, 'Error deleting interior color');
    }
  };

  const updateInteriorColorItem = async (color: InteriorColorItem, lang: string, acceptChanges: boolean = false, unlinkFromTMNA: boolean = false) => {
    try {
      const {
        data: { id, revId },
      } = await updateInteriorColor(brand, team, seriesId, year, lang, color.payload, acceptChanges, unlinkFromTMNA);
      color.id = id;
      color.revId = revId;
      if (acceptChanges || unlinkFromTMNA) {
        color.changedAttributes = [];
      }
      if (unlinkFromTMNA) {
        color.fromTMNA = false;
      }
      setLastUpdated(new Date());
      setIsLoaded(false);
      toast.success(`${langNameMap[lang]} interior color updated successfully`);
    } catch (e) {
      handleErrorResponse(e, 'Interior color failed update');
    }
  };

  const addInteriorColorItem = async (color: InteriorColorItem, lang: string) => {
    try {
      const {
        data: { id, revId },
      } = await addInteriorColor(brand, team, seriesId, year, lang, color.payload);
      color.id = id;
      color.revId = revId;
      setLastUpdated(new Date());
      setIsLoaded(false);
    } catch (e) {
      handleErrorResponse(e, `Error adding interior color: ${e}`);
    }
  };

  const saveInteriorColorItemMap = async (colorMap: InteriorColorLangItemMap, addIntColor: boolean, acceptChanges: boolean = false, unlinkFromTMNA: boolean = false) => {
    const promises: Promise<any>[] = [];
    let numValid = 0;
    for (const lang of colorsStore.editableLangs) {
      const color = colorMap.langs[lang];
      if (!color.revId && !color.isValid) {
        showFormFieldError();
        return;
      }
    }
    for (const lang of colorsStore.editableLangs) {
      const color = colorMap.langs[lang];
      if (color.isValid) {
        numValid++;
        if (!color.revId || addIntColor) {
          promises.push(addInteriorColorItem(color, lang));
        } else {
          promises.push(updateInteriorColorItem(color, lang, acceptChanges, unlinkFromTMNA));
        }
      }
    }
    if (!numValid) {
      showFormFieldError();
    } else {
      await Promise.all(promises);
    }
  };

  const deleteInteriorColorItem = async (colorMap: InteriorColorLangItemMap) => {
    try {
      const promises: Promise<any>[] = [];
      colorsStore.editableLangs.forEach(lang => {
        const color = colorMap.langs[lang];
        if (color.revId) {
          promises.push(deleteInteriorColor(brand, team, seriesId, year, lang, color.id));
        }
      });

      if (promises.length) {
        await Promise.all(promises);
        setLastUpdated(new Date());
        setIsLoaded(false);
      }
      toast.success('Interior color deleted successfully');
    } catch (e) {
      handleErrorResponse(e, 'Error deleting interior color');
    }
  };

  const selectedInteriorColorMap = (colorMap: InteriorColorLangMap) => {
    if (!readOnly) {
      seteditInteriorColor(true);
      const interiorColorItemMap: InteriorColorLangItemMap = { langs: {} };
      colorsStore.allLangs.forEach(lang => {
        interiorColorItemMap.langs[lang] = interiorColorItemXForm(colorMap.langs[lang]);
      });
      setSelectedInteriorColorItem(interiorColorItemMap);
      return colorMap;
    }
    return undefined;
  };

  const addEmptyExteriorColorItem = () => {
    colorsStore.addItem();
    showFormFieldError();
  };

  const addEmptyInteriorColorItem = () => {
    setaddIntColor(true);
    seteditInteriorColor(true);
    const interiorColorItemMap: InteriorColorLangItemMap = { langs: {} };
    const id = uuidv4();
    colorsStore.allLangs.forEach(lang => {
      const item = new InteriorColorItem();
      item.id = id;
      interiorColorItemMap.langs[lang] = item;
    });
    setSelectedInteriorColorItem(interiorColorItemMap);
  };

  const syncUpdates = async () => {
    setIsLoaded(false);
    try {
      await syncSpanishUpdates(brand, team, seriesId, year);
      toast.success('Sync successful');
      if (reloadDraft) {
        setIsLoaded(true);
        reloadDraft();
      }
    } catch (e) {
      handleErrorResponse(e, 'Error syncing spanish data');
    }
    setIsLoaded(true);
  };

  const compareExteriorColor = (color: ColorItemLangMap) => {
    setSyncChangesExteriorColor(color);
    setSyncChangesInteriorColor(undefined);
    setShowSyncChangesModal(true);
  };

  const compareInteriorColor = (color: InteriorColorLangItemMap) => {
    setSyncChangesExteriorColor(undefined);
    setSyncChangesInteriorColor(color);
    setShowSyncChangesModal(true);
  };

  const applyChanges = (response: AppliedChangesResponse) => {
    if (syncChangesExteriorColor) {
      applyExteriorChanges(response);
    } else if (syncChangesInteriorColor) {
      applyInteriorChanges(response);
    }
  };

  const applyExteriorChanges = (response: AppliedChangesResponse) => {
    const changeLogTypes: ChangeLogTypes[] = Object.keys(response.applied) as ChangeLogTypes[];
    changeLogTypes.forEach(changeType => {
      const langMap = response.applied[changeType];
      if (langMap) {
        Object.entries(langMap).forEach(([lang, after]) => {
          const color = syncChangesExteriorColor!.langs[lang];
          if (color) {
            switch (changeType) {
              case ChangeLogTypes.EXT_COLOR_NAME:
                color.name = after;
                break;
              case ChangeLogTypes.EXT_COLOR_CODE:
                color.code = after;
                break;
              case ChangeLogTypes.EXT_COLOR_HEX_CODE:
                color.hexCode = after;
                break;
              case ChangeLogTypes.EXT_COLOR_IN_PROGRESS:
                color.isInProgress = after.toString() === 'true';
                break;
              case ChangeLogTypes.EXT_COLOR_EXTRA_COST:
                color.isExtraCost = after;
                break;
              case ChangeLogTypes.EXT_COLOR_APPLICABILITY:
                color.colorApplicability = after;
                Object.values(color.interiorApplicability).forEach(intItems => {
                  intItems.forEach(item => {
                    item.checked = false;
                  });
                });
                color.colorApplicability.forEach(app => {
                  color.interiorApplicability[app.grade].forEach(itm => {
                    if (itm.interiorItem.id === app.interiorColorId && itm.grade.id === app.grade) {
                      itm.checked = true;
                    }
                  });
                });
                break;
              default:
                break;
            }
          }
        });
      }
    });
    saveExteriorColorLangMap(syncChangesExteriorColor!, undefined, undefined, true);
  };

  const applyInteriorChanges = (response: AppliedChangesResponse) => {
    const changeLogTypes: ChangeLogTypes[] = Object.keys(response.applied) as ChangeLogTypes[];
    changeLogTypes.forEach(changeType => {
      const langMap = response.applied[changeType];
      if (langMap) {
        Object.entries(langMap).forEach(([lang, after]) => {
          const color = syncChangesInteriorColor!.langs[lang];
          if (color) {
            switch (changeType) {
              case ChangeLogTypes.INT_COLOR_NAME:
                color.name = after;
                break;
              case ChangeLogTypes.INT_COLOR_CODE:
                color.code = after;
                break;
              case ChangeLogTypes.INT_COLOR_EXTRA_COST:
                color.isExtraCost = after;
                break;
              case ChangeLogTypes.INT_COLOR_MODEL_APPLICABILITY:
                color.modelApplicability = after;
                break;
              default:
                break;
            }
          }
        });
      }
    });
    saveInteriorColorItemMap(syncChangesInteriorColor!, false, true);
  };

  const deleteColor = () => {
    if (syncChangesExteriorColor) {
      deleteExteriorColorMap(syncChangesExteriorColor);
      setSyncChangesExteriorColor(undefined);
    } else if (syncChangesInteriorColor) {
      deleteInteriorColorItem(syncChangesInteriorColor);
      setSyncChangesInteriorColor(undefined);
    }
  };

  const unlinkColorFromTMNA = () => {
    if (syncChangesExteriorColor) {
      saveExteriorColorLangMap(syncChangesExteriorColor, undefined, undefined, false, true);
      setSyncChangesExteriorColor(undefined);
    } else if (syncChangesInteriorColor) {
      saveInteriorColorItemMap(syncChangesInteriorColor, false, false, true);
      setSyncChangesInteriorColor(undefined);
    }
  };

  const getActionBarButtons = (showActionButtons: boolean) => {
    const actionBarButtons: React.ReactNode[] = [];
    if (showActionButtons && teamStore.team.allowAddDeleteData) {
      actionBarButtons.push(
        <IconTextButton icon="plus" text="Add Exterior Color" onClick={() => addEmptyExteriorColorItem()} />,
        <IconTextButton icon="plus" text="Add Interior Color" onClick={() => addEmptyInteriorColorItem()} />,
      );

      actionBarButtons.push(
        sortMode ? (
          <SortButton toggled onClick={onStopSorting}>
            Stop Sorting
          </SortButton>
        ) : (
          <SortButton
            onClick={() => {
              colorsStore.resetFilters();
              setSortMode(true);
            }}
          >
            Sort
          </SortButton>
        ),
      );
    }

    const langButtons = getLangActionBarButtons(
      {
        allLangs: colorsStore.allLangs,
        selectedLangsMap: colorsStore.selectedLangsMap,
        updateSelectedLangs: colorsStore.updateSelectedLangs,
        showActionButtons,
      },
      {
        canSyncUpdates: teamStore.team.canSyncUpdates,
        seriesId,
        year,
        syncUpdates,
      },
    );
    actionBarButtons.push(...langButtons);

    return (
      <>
        {actionBarButtons.map((button, index) => (
          <React.Fragment key={index}>
            <ActionBarDivider />
            {button}
          </React.Fragment>
        ))}
      </>
    );
  };

  const onStopSorting = async () => {
    setSortMode(false);
    try {
      const colorItems = colorsStore.getDefaultColorItems(colorsStore.filteredColorItemLangMaps);
      const colorsPayload = getSortPayload(colorItems);
      await trackPromise(updateSortList(brand, team, seriesId, year, VDSortableEntity.COLORS_EXTERIOR, colorsPayload));
      setIsLoaded(false);
      await colorsStore.fetchData(brand, team, seriesId, year, vehicleModelsStore.sortedGrades, teamStore.team.langPermissions, versionInfo);
      setIsLoaded(true);
    } catch (e) {
      handleErrorResponse(e, 'Error updating feature sort');
    }
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return undefined;
    }

    const [removed] = colorsStore.filteredColorItemLangMaps.splice(result.source.index, 1);
    colorsStore.filteredColorItemLangMaps.splice(result.destination.index, 0, removed);
    colorsStore.filteredColorItemLangMaps.forEach((item, index: number) => {
      Object.values(item.langs).forEach(colorItem => {
        colorItem.sortOrder = index + 1;
      });
    });
    return colorsStore.filteredColorItemLangMaps;
  };

  const showFormFieldError = () => {
    toast.error('Please finish filling out all items of the new color.');
  };

  const syncColor = () => syncChangesExteriorColor ?? syncChangesInteriorColor ?? undefined;

  return !isLoaded ? (
    <Spinner />
  ) : (
    <>
      <ActionBarVehicleData
        readOnly={readOnly}
        searchText={colorsStore.searchText}
        onSearchTextChange={text => colorsStore.onFilter(() => (colorsStore.searchText = text))}
        renderButtons={getActionBarButtons(!readOnly)}
        renderFilter={onClose => (
          <ColorsFilters
            onClose={onClose}
            isInProgressFilter={colorsStore.isInProgressFilter}
            setIsInProgressFilter={value => colorsStore.onFilter(() => (colorsStore.isInProgressFilter = value))}
            isSyncUpdateFilter={colorsStore.isSyncUpdateFilter}
            setIsSyncUpdateFilter={value => colorsStore.onFilter(() => (colorsStore.isSyncUpdateFilter = value))}
            isExtraCostColorFilter={colorsStore.isExtraCostColorFilter}
            setIsExtraCostColorFilter={value => colorsStore.onFilter(() => (colorsStore.isExtraCostColorFilter = value))}
            isReviewNotesFilter={colorsStore.isReviewNotesFilter}
            setIsReviewNotesFilter={
              team === VehicleTeam.AGENCY_TEAM && version == null ? value => colorsStore.onFilter(() => (colorsStore.isReviewNotesFilter = value)) : undefined
            }
            isPublished={isPublished}
          />
        )}
      />
      <TwoTableWrapper>
        <DragDropContext onDragEnd={onDragEnd}>
          <LeftTable>
            <ColorsTabHeaderRow
              showHexCode={teamStore.team.allowHexCode}
              readOnly={readOnly || !teamStore.team.allowAddDeleteData}
              onSort={colorsStore.onSort}
              sortMode={sortMode}
              languages={colorsStore.selectedLangs()}
            />
            <ColorsFormRowsContainer
              colorMaps={colorsStore.filteredColorItemLangMaps}
              sortMode={sortMode}
              saveExteriorColorLangMap={saveExteriorColorLangMap}
              deleteColorItem={colorMap => deleteExteriorColorMap(colorMap)}
              showHexCode={teamStore.team.allowHexCode}
              readOnly={readOnly}
              brand={brand}
              compareExteriorColor={compareExteriorColor}
            />
          </LeftTable>
        </DragDropContext>
        <ColorAccessibilityLangTable
          filteredColors={colorsStore.filteredColorItemLangMaps}
          gradeItems={colorsStore.colorGradeLangItems}
          selectedInteriorColorMap={selectedInteriorColorMap}
          saveExteriorColorLangMap={saveExteriorColorLangMap}
          readOnly={readOnly}
          vehicleModels={vehicleModels}
          defaultLang={colorsStore.defaultLang}
          hasEnglishWritePerms={colorsStore.hasEnglishWritePerms}
          langPermissions={colorsStore.langWriteMap}
          allLangs={colorsStore.allLangs}
        />
      </TwoTableWrapper>
      <Modal
        size="sm"
        open={addIntColor || editInteriorColor}
        onClose={() => {
          setaddIntColor(false);
          seteditInteriorColor(false);
        }}
      >
        <AddEditInteriorColorModal
          onSaveMap={saveInteriorColorItemMap}
          onDeleteMap={deleteInteriorColorItem}
          close={() => {
            setaddIntColor(false);
            seteditInteriorColor(false);
          }}
          addIntColor={addIntColor}
          selectedInteriorColorMap={interiorColor}
          vehicleModels={vehicleModelsStore.vehicleModels}
          grades={vehicleModelsStore.grades}
          allLangs={colorsStore.allLangs}
          editableLangs={colorsStore.editableLangs}
          defaultLang={colorsStore.defaultLang}
          hasEnglishWritePerms={colorsStore.hasEnglishWritePerms}
          readOnly={readOnly}
          allowDisclaimerTokens={teamStore.team.allowDisclaimerTokens}
          disclaimerTokens={disclaimerTokens}
          compareInteriorColor={compareInteriorColor}
          brand={brand}
          showAcceptChanges={!!teamStore.team.showAcceptChanges}
        />
      </Modal>
      <Modal open={showSyncChangesModal} size="auto" onClose={() => setShowSyncChangesModal(false)}>
        <SyncTMNAChangesModal
          brand={brand}
          team={team}
          seriesId={seriesId}
          year={year}
          itemId={syncColor()?.langs[colorsStore.defaultLang].id || ''}
          entityType={syncChangesExteriorColor ? 'exteriorColors' : 'interiorColors'}
          isNew={!!syncColor()?.langs[colorsStore.defaultLang].changedAttributes?.includes('new')}
          isDelete={!!syncColor()?.langs[colorsStore.defaultLang].changedAttributes?.includes('delete')}
          close={(response, shouldDelete, unlinkFromTMNA) => {
            setShowSyncChangesModal(false);
            if (response) {
              applyChanges(response);
            } else if (shouldDelete) {
              deleteColor();
            } else if (unlinkFromTMNA) {
              unlinkColorFromTMNA();
            }
          }}
          grades={vehicleModelsStore.grades}
          colorGradeLangItems={colorsStore.colorGradeLangItems}
          vehicleModels={syncChangesInteriorColor ? vehicleModels : undefined}
          sourceVersion={sourceVersion}
        />
      </Modal>
    </>
  );
};

export default observer(ColorsController);
