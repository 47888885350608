import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import Spinner from '../../../../components/Spinner';
import useStores from '../../../../hooks/useStores';
import { ColorsReviewResponse } from '../../../../models/colors.model';
import { ProductDataControllerProps } from '../../../../routes/vehicleData/models/controllers.model';
import {
  colorGradeXform,
  colorsExteriorReviewItemXForm,
  colorsInteriorReviewItemXForm,
} from '../../../../utils/colorUtils';
import {
  getReviewExteriorColors,
  getReviewInteriorColors,
} from '../../../../webservices/vehicleColorsApi';
import ColorsExteriorReview from './ColorsExteriorReview';
import ColorsInteriorReview from './ColorsInteriorReview';

const ColorsReview = ({
  team,
  seriesId,
  year,
  version,
  versionInfo,
  vehicleModels,
  changeLogLink = '',
}: ProductDataControllerProps) => {
  const {
    colorsReviewStore,
    colorsExteriorReviewStore,
    colorsInteriorReviewStore,
    teamStore,
    vehicleModelsStore,
    userStore: { brand },
  } = useStores();

  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    colorsExteriorReviewStore.reset();
    colorsInteriorReviewStore.reset();
    colorsReviewStore.reset();

    (async () => {
      try {
        const responses = await Promise.all([
          getReviewInteriorColors<ColorsReviewResponse>(
            brand,
            teamStore.team.param,
            seriesId,
            year,
            version
          ),
          getReviewExteriorColors<ColorsReviewResponse>(
            brand,
            teamStore.team.param,
            seriesId,
            year,
            version
          ),
        ]);

        colorsReviewStore.colorGradeItems = colorGradeXform(
          responses[0].data,
          vehicleModels,
          vehicleModelsStore.grades
        );
        const { map: interiorMap } = colorsInteriorReviewItemXForm(
          responses[0].data,
          colorsReviewStore.colorGradeItems,
          vehicleModelsStore.grades,
          vehicleModelsStore.vehicleModels
        );
        colorsInteriorReviewStore.map = interiorMap;

        const { map: exteriorMap } = colorsExteriorReviewItemXForm(
          responses[1].data,
          colorsReviewStore.colorGradeItems,
          vehicleModelsStore.grades
        );
        colorsExteriorReviewStore.map = exteriorMap;
      } catch (e) {
        console.log(e);
        toast.error('Error loading colors review data');
      }
      setIsLoaded(true);
    })();
  }, [
    colorsReviewStore,
    colorsExteriorReviewStore,
    colorsInteriorReviewStore,
    brand,
    vehicleModels,
    seriesId,
    teamStore,
    version,
    year,
    vehicleModelsStore,
  ]);

  return !isLoaded ? (
    <Spinner />
  ) : (
    <>
      {colorsReviewStore.selectedTab === 'Exterior' && (
        <ColorsExteriorReview
          team={team}
          seriesId={seriesId}
          year={year}
          version={version}
          versionInfo={versionInfo}
          vehicleModels={vehicleModels}
          changeLogLink={changeLogLink}
        />
      )}
      {colorsReviewStore.selectedTab === 'Interior' && (
        <ColorsInteriorReview
          team={team}
          seriesId={seriesId}
          year={year}
          version={version}
          versionInfo={versionInfo}
          vehicleModels={vehicleModels}
          changeLogLink={changeLogLink}
        />
      )}
    </>
  );
};

export default observer(ColorsReview);
