import cx from 'clsx';
import React, { LegacyRef, useCallback, useEffect, useRef } from 'react';
import styles from './tableRow.module.scss';

export interface TableRowProps extends TableRowBaseProps {
  onFillRowHeightChange?(height: number, border: number): void;
  rowHeight?: string | number;
}

export const TableRow: React.FC<TableRowProps> = ({ onFillRowHeightChange, rowHeight, ...rest }) => {
  const ref = useRef<HTMLTableRowElement>(null);

  const getHTMLProps = useCallback((htmlTableRowElement: HTMLTableRowElement) => {
    const RADIX = 10;
    const nodeStyle = window.getComputedStyle(htmlTableRowElement);

    const borderTop = Number.parseInt(nodeStyle.getPropertyValue('border-top-width'), RADIX);
    const borderBottom = Number.parseInt(nodeStyle.getPropertyValue('border-bottom-width'), RADIX);
    const height = htmlTableRowElement.getBoundingClientRect().height;

    return {
      height,
      border: borderTop + borderBottom,
    };
  }, []);

  useEffect(() => {
    if (onFillRowHeightChange) {
      setTimeout(() => {
        if (ref && ref.current) {
          // This is not being executed sometimes because onFillRowHeightChange becomes undefined at somepoint when ref changes
          // for this reason i ended creating the onInnerRefChange method and onRefChange
          const { height, border } = getHTMLProps(ref.current);
          onFillRowHeightChange(height, border);
        }
      }, 100);
    }
  }, [onFillRowHeightChange, getHTMLProps]);

  const onInnerRefChange = useCallback(
    (htmlTableRowElement: HTMLTableRowElement) => {
      if (!onFillRowHeightChange || !htmlTableRowElement) return;

      const { height, border } = getHTMLProps(htmlTableRowElement);
      onFillRowHeightChange(height, border);
    },
    [onFillRowHeightChange, getHTMLProps],
  );

  return (
    <TableRowBase
      innerRef={ref}
      onInnerRefChange={onInnerRefChange}
      style={{
        height: `${rowHeight}px`,
      }}
      {...rest}
    />
  );
};

export interface TableRowBaseProps extends React.HTMLAttributes<HTMLTableRowElement> {
  zebra?: boolean;
  hoverShadow?: boolean;
  className?: string;
  innerRef?: LegacyRef<HTMLTableRowElement>;
  onInnerRefChange?(htmlTableRowElement: HTMLTableRowElement): void;
}

export const TableRowBase = ({ zebra, hoverShadow, className, innerRef, onInnerRefChange, ...rest }: TableRowBaseProps) => {
  const onRefChange = useCallback(
    (htmlTableRowElement: HTMLTableRowElement) => {
      if (onInnerRefChange) {
        onInnerRefChange(htmlTableRowElement);
      }

      if (typeof innerRef === 'function') {
        innerRef(htmlTableRowElement);
      } else if (innerRef) {
        // @ts-ignore
        innerRef.current = htmlTableRowElement;
      }
    },
    [innerRef, onInnerRefChange],
  );

  return <tr ref={onRefChange} className={cx(styles.tr, zebra && styles.zebra, hoverShadow && styles.hoverShadow, className)} {...rest} />;
};
