import { v4 as uuidv4 } from 'uuid';
import { ModelsResponse } from '../gql/generated';
import { ChangeLogTypes } from '../models/changeLog.model';
import { IDValueType, KeyValueType } from '../models/common.model';
import { FuelTypeResponse } from '../models/fuelType.model';
import { OptionItem, OptionModel, OptionResponse } from '../models/options.model';
import { RefItem, RefItemResponse } from '../models/refItem.model';
import { BRAND_TDPR, Brand } from '../models/user.model';
import {
  ModelReviewType,
  ModelsChangeTypeMap,
  ModelsReviewItem,
  ModelsReviewMap,
  ModelsReviewResponse,
  VehicleModel,
  VehicleModelItem,
  VehicleModelLocalStorageItemType,
  VehicleModelToyota,
} from '../models/vehicleModel.model';
import { refItemsXForm } from './refItemUtils';

export const modelsResponseToModel = (modelsResponse: ModelsResponse): VehicleModel<VehicleModelToyota>[] =>
  modelsResponse.models?.map(model => ({
    id: model.id,
    revId: model.revId,
    sortOrder: model.sortOrder as number,
    code: model.code,
    grade: model.grade as any,
    drive: model.drive,
    engine: model.engine,
    description: model.description,
    transmission: model.transmission,
    isHybrid: model.isHybrid,
    fuelType: model.fuelType as any,
    isNotPublishable: model.isNotPublishable,
    trimTitle: model.trimTitle,
    bed: model.bed as string,
    cab: model.cab as string,
    seating: model.seating as string,
    goLiveDate: model.goLiveDate as string,
    changedAttributes: model.changedAttributes as string[],
    tdprCode: model.tdprCode as string,
    isTDPR: model.isTDPR as boolean,
    isUSVI: model.isUSVI as boolean,
    fromTMNA: model.fromTMNA as boolean,
    katashiki: model.katashiki,
    horsepower: model.horsepower,
    gradeValue: model.gradeValue,
    isDeleted: model.isDeleted,
    rejectNotes: model.rejectNotes,
  })) ?? [];

export const transformResponseModels = (
  brand: Brand,
  modelsResponse: VehicleModel<VehicleModelToyota>[] | undefined,
  gradesResponse: RefItemResponse[] | undefined,
  fuelTypesResponse: FuelTypeResponse,
  localStorageValue: VehicleModelLocalStorageItemType,
) => {
  const grades = refItemsXForm(gradesResponse ?? []);
  const fuelTypes = Object.values(fuelTypesResponse.fuelTypes).map(item => new IDValueType(item.id, item.type));
  const vehicleModels = modelsResponse?.map(item => new VehicleModelItem<VehicleModelToyota>(brand, item, grades, fuelTypes)) ?? [];

  const sortedGradeMap: {
    [id: string]: string;
  } = {};
  const sortedGrades: RefItem[] = [];

  vehicleModels.forEach(item => {
    if (localStorageValue && localStorageValue[item.id]) {
      item.show = localStorageValue[item.id].show;
    }

    const grade = item.getVal('grade');
    const gradeId = grade.id;
    if (!sortedGradeMap[gradeId]) {
      sortedGradeMap[gradeId] = gradeId;
      sortedGrades.push(grade);
    }
  });

  return { grades, vehicleModels, fuelTypes, sortedGrades };
};

export const optionItemXForm = (item: OptionResponse, vehicleModels: VehicleModelItem<VehicleModelToyota>[], categories: IDValueType[], index: number) => {
  const models: OptionModel[] = vehicleModels.map(mdl => ({
    id: mdl.id,
    setting: item.modelApplicability ? item.modelApplicability[mdl.id] : '',
  }));

  const category = categories.filter(cat => cat.id === item.categoryId);

  const optionItem = new OptionItem(item);
  optionItem.category = category.length ? category[0] : optionItem.category;
  optionItem.models = models;
  optionItem.sortOrder = index + 1;

  return optionItem;
};

export const isModelValid = (langVM: VehicleModelItem<VehicleModelToyota>, newModelVM: any, brand: string) => {
  let isValid = true;

  langVM.iterateModelProps((modelPropKey, modelProp) => {
    if (!isValid || modelProp.optional) {
      return;
    }

    const thisRequiredField = newModelVM[modelPropKey];
    if (thisRequiredField === undefined || thisRequiredField === '' || thisRequiredField === null) {
      isValid = false;
    }
  });

  if (brand === BRAND_TDPR && (newModelVM.isTDPR || newModelVM.isUSVI) && !newModelVM.tdprCode) {
    isValid = false;
  }

  return isValid;
};

export const modelsReviewXform = (items: ModelsReviewResponse[], fuelTypesMap: KeyValueType<string>) => {
  const map: ModelsReviewMap = {};
  const reviewItems: ModelsReviewItem[] = [];

  items.forEach(item => {
    let isApplied = true;
    let rejectNotes = '';
    Object.entries(item.changes).forEach(([key, value]) => {
      if (!map[item.id]) {
        map[item.id] = {
          // ReviewChangeTypeMap fields
          id: item.id,
          revId: item.revId,
          isApplied: isApplied,
          isNew: false,
          isDeleted: item.isDeleted,
          rejectNotes: rejectNotes,
          notes: '',
          isInProgress: false,
          // new fields specfic to models
          isNotPublishable: item.isNotPublishable,
          code: {
            before: '',
            after: item.code,
            hasChanged: false,
          },
          goLiveDate: {
            before: '',
            after: item.goLiveDate ? new Date(item.goLiveDate).toLocaleDateString() : '',
            hasChanged: false,
          },
          grade: {
            before: '',
            after: item.gradeValue,
            hasChanged: false,
          },
          bed: {
            before: '',
            after: item.bed || '',
            hasChanged: false,
          },
          cab: {
            before: '',
            after: item.cab || '',
            hasChanged: false,
          },
          fuelType: {
            before: '',
            after: fuelTypesMap[item.fuelType] || item.fuelType,
            hasChanged: false,
          },
          trimTitle: {
            before: '',
            after: item.trimTitle,
            hasChanged: false,
          },
          description: {
            before: '',
            after: item.description,
            hasChanged: false,
          },
          drive: {
            before: '',
            after: item.drive,
            hasChanged: false,
          },
          engine: {
            before: '',
            after: item.engine,
            hasChanged: false,
          },
          transmission: {
            before: '',
            after: item.transmission,
            hasChanged: false,
          },
          horsepower: {
            before: '',
            after: item.horsepower,
            hasChanged: false,
          },
          katashiki: {
            before: '',
            after: item.katashiki,
            hasChanged: false,
          },
        } as ModelsChangeTypeMap;
      }

      Object.entries(item.changes).forEach(([key, change]) => {
        isApplied = isApplied && change.isApplied;
        rejectNotes = change.rejectNotes || rejectNotes;

        const changeLogKey = getKeyFromChangeLogType(change.changeType);

        if (changeLogKey === 'deleted') {
          map[item.id].isDeleted = true;
          map[item.id].code.before = map[item.id].code.after;
          map[item.id].code.after = '';
          map[item.id].code.hasChanged = true;

          map[item.id].isDeleted = true;
          map[item.id].goLiveDate.before = map[item.id].goLiveDate.after;
          map[item.id].goLiveDate.after = '';
          map[item.id].goLiveDate.hasChanged = true;

          map[item.id].isDeleted = true;
          map[item.id].grade.before = map[item.id].grade.after;
          map[item.id].grade.after = '';
          map[item.id].grade.hasChanged = true;

          map[item.id].isDeleted = true;
          map[item.id].fuelType.before = map[item.id].fuelType.after;
          map[item.id].fuelType.after = '';
          map[item.id].fuelType.hasChanged = true;

          map[item.id].isDeleted = true;
          map[item.id].bed.before = map[item.id].bed.after;
          map[item.id].bed.after = '';
          map[item.id].bed.hasChanged = true;

          map[item.id].isDeleted = true;
          map[item.id].cab.before = map[item.id].cab.after;
          map[item.id].cab.after = '';
          map[item.id].cab.hasChanged = true;

          map[item.id].isDeleted = true;
          map[item.id].trimTitle.before = map[item.id].trimTitle.after;
          map[item.id].trimTitle.after = '';
          map[item.id].trimTitle.hasChanged = true;

          // check if this is RTE
          map[item.id].isDeleted = true;
          map[item.id].description.before = map[item.id].description.after;
          map[item.id].description.after = '';
          map[item.id].description.hasChanged = true;

          map[item.id].isDeleted = true;
          map[item.id].grade.before = map[item.id].grade.after;
          map[item.id].grade.after = '';
          map[item.id].grade.hasChanged = true;

          map[item.id].isDeleted = true;
          map[item.id].drive.before = map[item.id].drive.after;
          map[item.id].drive.after = '';
          map[item.id].drive.hasChanged = true;

          map[item.id].isDeleted = true;
          map[item.id].engine.before = map[item.id].grade.after;
          map[item.id].engine.after = '';
          map[item.id].engine.hasChanged = true;

          map[item.id].isDeleted = true;
          map[item.id].transmission.before = map[item.id].transmission.after;
          map[item.id].transmission.after = '';
          map[item.id].transmission.hasChanged = true;

          map[item.id].isDeleted = true;
          map[item.id].horsepower.before = map[item.id].horsepower.after;
          map[item.id].horsepower.after = '';
          map[item.id].horsepower.hasChanged = true;

          map[item.id].isDeleted = true;
          map[item.id].katashiki.before = map[item.id].katashiki.after;
          map[item.id].katashiki.after = '';
          map[item.id].katashiki.hasChanged = true;

          map[item.id].isDeleted = true;
          map[item.id].grade.before = map[item.id].grade.after;
          map[item.id].grade.after = '';
          map[item.id].grade.hasChanged = true;
        } else if (changeLogKey === 'added') {
          map[item.id].code.hasChanged = true;
          map[item.id].goLiveDate.hasChanged = true;
          map[item.id].grade.hasChanged = true;
          map[item.id].fuelType.hasChanged = true;
          map[item.id].bed.hasChanged = true;
          map[item.id].cab.hasChanged = true;
          map[item.id].trimTitle.hasChanged = true;
          map[item.id].description.hasChanged = true;
          map[item.id].drive.hasChanged = true;
          map[item.id].engine.hasChanged = true;
          map[item.id].transmission.hasChanged = true;
          map[item.id].horsepower.hasChanged = true;
          map[item.id].katashiki.hasChanged = true;
          map[item.id].isNew = true;
        } else if (changeLogKey) {
          const before = change.before as string;
          const after = change.after as string;
          map[item.id][changeLogKey].before = before;
          map[item.id][changeLogKey].after = after;
          map[item.id][changeLogKey].hasChanged = true;
          if (changeLogKey === 'fuelType') {
            if (fuelTypesMap[before]) {
              map[item.id][changeLogKey].before = fuelTypesMap[before];
            }
            if (fuelTypesMap[after]) {
              map[item.id][changeLogKey].after = fuelTypesMap[after];
            }
          } else if (changeLogKey === 'goLiveDate') {
            map[item.id].goLiveDate.before = before ? new Date(before).toLocaleDateString() : '';
            map[item.id].goLiveDate.after = after ? new Date(after).toLocaleDateString() : '';
          }
        }
      });
      map[item.id].isApplied = isApplied;
      map[item.id].rejectNotes = rejectNotes;

      reviewItems.push({
        uid: uuidv4(),
        ...value,
        id: item.id,
        revId: item.revId,
        category: item.code,
        changeTypeId: key,
        rejectNotes: value?.rejectNotes ?? '', // the server sends reject notes as null if empty
        description: item.description,
      } as ModelsReviewItem);
    });
  });
  return { map, items: reviewItems };
};

const getKeyFromChangeLogType = (type: ChangeLogTypes): ModelReviewType | undefined => {
  switch (type) {
    case ChangeLogTypes.CODE:
      return 'code';
    case ChangeLogTypes.GO_LIVE_DATE:
      return 'goLiveDate';
    case ChangeLogTypes.GRADE:
      return 'grade';
    case ChangeLogTypes.FUEL_TYPE:
      return 'fuelType';
    case ChangeLogTypes.BED:
      return 'bed';
    case ChangeLogTypes.CAB:
      return 'cab';
    case ChangeLogTypes.TRIM_TITLE:
      return 'trimTitle';
    case ChangeLogTypes.DESCRIPTION:
      return 'description';
    case ChangeLogTypes.DRIVE:
      return 'drive';
    case ChangeLogTypes.ENGINE:
      return 'engine';
    case ChangeLogTypes.TRANSMISSION:
      return 'transmission';
    case ChangeLogTypes.HORSEPOWER:
      return 'horsepower';
    case ChangeLogTypes.KATASHIKI:
      return 'katashiki';
    case ChangeLogTypes.MODEL_ADDED:
      return 'added';
    case ChangeLogTypes.MODEL_DELETED:
      return 'deleted';
  }
  return undefined;
};
