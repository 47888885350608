/* eslint-disable react-hooks/exhaustive-deps */
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { trackPromise } from 'react-promise-tracker';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import { ActionBarDivider, Modal } from 'vapi-ui-common';
import IconTextButton from '../../../../components/IconTextButton';
import Spinner from '../../../../components/Spinner';
import { TableRow, TwoTableWrapper } from '../../../../components/Table';
import SortButton from '../../../../components/sortModule/SortButton';
import SortDropdown from '../../../../components/sortModule/SortDropdown';
import SortModal from '../../../../components/sortModule/SortModal';
import { langNameMap } from '../../../../constants/vehicleData/VDConstants';
import useDebounce from '../../../../hooks/useDebounce';
import useStores from '../../../../hooks/useStores';
import { CategoriesMap, CategoryLangMap } from '../../../../models/category.model';
import { AppliedChangesResponse, ChangeLogTypes } from '../../../../models/changeLog.model';
import { IDValueType, KeyValueType } from '../../../../models/common.model';
import { ApplicabilityTextLangMap } from '../../../../models/compareFeatures.model';
import { OptionItem, OptionLangMap, OptionSettings } from '../../../../models/options.model';
import { VDSortableEntity } from '../../../../models/sort.model';
import { Language } from '../../../../models/user.model';
import { VehicleTeam } from '../../../../models/vehicleData.model';
import ActionBarVehicleData from '../../../../routes/vehicleData/components/ActionBarVehicleData';
import LeftTable from '../../../../routes/vehicleData/components/LeftTable';
import ModelTable from '../../../../routes/vehicleData/components/ModelTable';
import SettingsCell from '../../../../routes/vehicleData/components/ModelTable/components/SettingsCell';
import { ProductDataControllerProps } from '../../../../routes/vehicleData/models/controllers.model';
import { tokensXForm } from '../../../../utils/disclaimersUtils';
import { handleErrorResponse } from '../../../../utils/errorHandlingUtils';
import getLangActionBarButtons from '../../../../utils/getLangActionBarButtons';
import { getSortPayload, getSortedCopy } from '../../../../utils/sortUtils';
import { syncSpanishUpdates, updateSortList } from '../../../../webservices/vehicleAdminApi';
import { addCategories, addOption, deleteOption, updateCategory, updateOption } from '../../../../webservices/vehicleOptionsApi';
import SyncTMNAChangesModal from '../../components/SyncTMNAChangesModal/SyncTMNAChangesModal';
import ProductTeamFilters from './components/Filters';
import OptionsHeaderRow from './components/OptionsHeaderRow';
import OptionsRowContainer from './components/OptionsRow/OptionsRowContainer';

const OptionsController = ({ readOnly, team, seriesId, year, version, versionInfo, vehicleModels, isPublished, reloadDraft, sourceVersion }: ProductDataControllerProps) => {
  const {
    optionsStore,
    teamStore,
    userStore: { brand },
    disclaimersStore: { tokens },
  } = useStores();

  const { debounce } = useDebounce({ delay: 2000 });
  const [isLoaded, setIsLoaded] = useState(false);
  const [sortMode, setSortMode] = useState(false);
  const [sortCategoryModal, setSortCategoryModal] = useState(false);
  const [showSyncChangesModal, setShowSyncChangesModal] = useState(false);
  const [syncChangesOption, setSyncChangesOption] = useState<OptionLangMap | undefined>(undefined);

  const disclaimerTokens = tokensXForm(tokens);

  useEffect(() => {
    setIsLoaded(false);
    optionsStore.reset();

    (async () => {
      try {
        await optionsStore.fetchData(brand, team, seriesId, year, vehicleModels, teamStore.team.langPermissions, versionInfo);
      } catch (e) {
        console.log(e);
        toast.error('Error loading options data');
      }
      setIsLoaded(true);
    })();
  }, [brand, optionsStore, seriesId, team, vehicleModels, year, version]);

  const addEmptyOptionItem = () => {
    optionsStore.addItem(vehicleModels);
    showFormFieldError();
  };

  const updateOptionItem = async (option: OptionItem, lang: string, acceptChanges: boolean = false, unlinkFromTMNA: boolean = false) => {
    if (option.isValid()) {
      try {
        debounce(async () => {
          const response = await trackPromise(updateOption(brand, team, seriesId, year, option.getPayload(), lang, acceptChanges, unlinkFromTMNA));
          updateRevId(option, response.data.revId);

          if (acceptChanges || unlinkFromTMNA) {
            option.changedAttributes = [];
            option.changedModelIds = [];
          }

          if (unlinkFromTMNA) {
            option.fromTMNA = false;
          }

          toast.success(`${langNameMap[lang]} Option updated successfully`);
        }, option.uid);
      } catch (e) {
        handleErrorResponse(e, 'Option failed update');
      }
    } else {
      showFormFieldError();
    }
  };

  const addOptionItem = async (option: OptionItem, lang: string) => {
    if (option.isValid()) {
      try {
        debounce(async () => {
          const response = await trackPromise(addOption(brand, team, seriesId, year, option.getPayload(), lang));
          option.id = response.data.id;
          updateRevId(option, response.data.revId);
          toast.success('Option added successfully');
        }, option.uid);
      } catch (e) {
        handleErrorResponse(e, 'Option failed add');
      }
    } else {
      showFormFieldError();
    }
  };

  const saveOptionLangMap = async (optionLangMap: OptionLangMap, lang: string = '', acceptChanges: boolean = false, unlinkFromTMNA: boolean = false) => {
    if (lang && !optionsStore.langWriteMap[lang as Language]?.canEdit) {
      toast.error(`You do not have permissions to update ${langNameMap[lang]} options.`);
      return;
    }
    const langs = lang ? [lang] : optionsStore.editableLangs;
    const promises: Promise<any>[] = [];
    let numValid = 0;
    for (const lang of langs) {
      const option = optionLangMap.langs[lang];
      if (option.isValid()) {
        numValid++;
        if (option.revId) {
          promises.push(updateOptionItem(option, lang, acceptChanges, unlinkFromTMNA));
        } else {
          promises.push(addOptionItem(option, lang));
        }
      }
    }
    if (!numValid) {
      showFormFieldError();
    } else {
      await Promise.all(promises);
    }
  };

  const deleteOptionLangMap = async (optionLangMap: OptionLangMap) => {
    try {
      const uid = optionLangMap.langs[optionsStore.defaultLang].uid;
      let optionId = '';
      // only delete the options that the user has write permissions for
      for (const lang of optionsStore.editableLangs) {
        const option = optionLangMap.langs[lang];
        optionId = option.id;
        if (option.revId) {
          await trackPromise(deleteOption(brand, team, seriesId, year, lang, optionId));
        }
      }
      optionsStore.deleteItem(uid);
      toast.success('Option deleted sucessfully');
    } catch (e) {
      handleErrorResponse(e, 'Error deleting Option');
    }
  };

  const copyOptionLangMap = async (optionLangMap: OptionLangMap) => {
    try {
      const copiedOptionLangMap = optionsStore.copyMap(JSON.parse(JSON.stringify(optionLangMap)), vehicleModels);
      const optionId = uuidv4();
      let isValid = true;
      optionsStore.allLangs.forEach(lang => {
        copiedOptionLangMap.langs[lang].id = optionId;
        if (!copiedOptionLangMap.langs[lang].isValid()) {
          isValid = false;
        }
      });

      if (isValid) {
        for (const lang of optionsStore.allLangs) {
          const copiedOption = copiedOptionLangMap.langs[lang];
          if (optionsStore.langWriteMap[lang]?.canEdit) {
            const response = await trackPromise(addOption(brand, team, seriesId, year, copiedOption.getPayload(), lang));
            updateRevId(copiedOption, response.data.revId);
          }
        }
        const sortPayload = getSortPayload(optionsStore.optionLangMaps.map(langMap => langMap.langs[optionsStore.editableLangs[0]]));
        await trackPromise(updateSortList(brand, team, seriesId, year, VDSortableEntity.OPTIONS, sortPayload));
        toast.success('Option copied successfully');
      } else {
        showFormFieldError();
      }
    } catch (e) {
      handleErrorResponse(e, 'Option failed copy');
    }
  };

  const addCategoryItem = async (payload: { [lang: string]: string }, id?: string) => {
    try {
      const response = await trackPromise(addCategories(brand, team, seriesId, year, payload, id));
      const categoriesMap: CategoriesMap = JSON.parse(JSON.stringify(optionsStore.categoriesMap));
      Object.keys(response.data).forEach(lang => {
        optionsStore.updateCategoriesLangMap(lang as Language, categoriesMap, response.data[lang], optionsStore.categoriesSortList);
      });
      optionsStore.fillOutCategoriesMap(categoriesMap);
      optionsStore.categoriesMap = categoriesMap;
      toast.success('Category added successfully');
    } catch (e) {
      handleErrorResponse(e, 'Error adding category');
    }
  };

  const updateCategoryItem = async (categoryLangMap: CategoryLangMap, payload: { [lang: string]: string }) => {
    try {
      for (const lang of optionsStore.editableLangs) {
        if (payload[lang]) {
          await trackPromise(updateCategory(brand, team, seriesId, year, categoryLangMap[lang].id, payload[lang], lang));
        }
      }
      optionsStore.editableLangs.forEach(lang => {
        if (payload[lang]) {
          categoryLangMap[lang].value = payload[lang];
        }
      });
      const catMap = optionsStore.categoriesMap;
      for (const lang of optionsStore.editableLangs) {
        if (payload[lang]) {
          catMap.categories[categoryLangMap[lang].id] = categoryLangMap;
          break;
        }
      }
      optionsStore.categoriesMap = catMap;
      toast.success('Category updated successfully');
    } catch (e) {
      handleErrorResponse(e, 'Error editing category');
    }
  };

  const onSaveSortCategories = async (list: IDValueType<string>[], dataType: VDSortableEntity) => {
    try {
      const catPayload = getSortPayload(list);
      await trackPromise(updateSortList(brand, team, seriesId, year, dataType, catPayload));

      let optionsPayload;
      if (dataType === VDSortableEntity.OPTIONS_CATEGORIES) {
        const categories = dataType === VDSortableEntity.OPTIONS_CATEGORIES ? list : optionsStore.getDefaultCategories(optionsStore.categoriesMap);

        const optionsCopy = getSortedCopy<OptionItem>(optionsStore.getDefaultOptions(optionsStore.optionLangMaps), item => item.category.id, categories);
        optionsPayload = getSortPayload(optionsCopy);
      }

      if (optionsPayload) {
        await trackPromise(updateSortList(brand, team, seriesId, year, VDSortableEntity.OPTIONS, optionsPayload));
        setIsLoaded(false);
        optionsStore.resetFilters();
        await trackPromise(optionsStore.fetchData(brand, team, seriesId, year, vehicleModels, teamStore.team.langPermissions, versionInfo));
      }
    } catch (e) {
      handleErrorResponse(e, 'Error updating category sort');
    }
    setIsLoaded(true);
  };

  const onStopSorting = async () => {
    setSortMode(false);
    try {
      const optionItems = optionsStore.getDefaultOptions(optionsStore.filteredOptionLangMaps);
      const sortPayload = getSortPayload(optionItems);
      await trackPromise(updateSortList(brand, team, seriesId, year, VDSortableEntity.OPTIONS, sortPayload));
      setIsLoaded(false);
      await trackPromise(optionsStore.fetchData(brand, team, seriesId, year, vehicleModels, teamStore.team.langPermissions, versionInfo));
      setIsLoaded(true);
    } catch (e) {
      handleErrorResponse(e, 'Error updating option sort');
    }
  };

  const updateRevId = (option: OptionItem, revId: string) => {
    option.revId = revId;
  };

  const syncUpdates = async () => {
    setIsLoaded(false);
    try {
      await syncSpanishUpdates(brand, team, seriesId, year);
      toast.success('Sync successful');
      if (reloadDraft) {
        setIsLoaded(true);
        reloadDraft();
      }
    } catch (e) {
      handleErrorResponse(e, 'Error syncing spanish data');
      setIsLoaded(true);
    }
  };

  const getActionBarButtons = (showActionButtons: boolean) => {
    const actionBarButtons: React.ReactNode[] = [];
    if (showActionButtons && teamStore.team.allowAddDeleteData) {
      actionBarButtons.push(<IconTextButton icon="plus" text="Add Option" onClick={() => addEmptyOptionItem()} />);
      actionBarButtons.push(
        sortMode ? (
          <SortButton toggled onClick={onStopSorting}>
            Stop Sorting
          </SortButton>
        ) : (
          <>
            <SortDropdown
              buttonText="Sort"
              list={['Rows', 'Categories']}
              onSelect={value => {
                switch (value) {
                  case 'Rows': {
                    optionsStore.resetFilters();
                    setSortMode(true);
                    break;
                  }
                  case 'Categories': {
                    setSortCategoryModal(true);
                    break;
                  }
                }
              }}
            />
            <SortModal
              open={sortCategoryModal}
              onClose={() => setSortCategoryModal(false)}
              onSave={list => onSaveSortCategories(list, VDSortableEntity.OPTIONS_CATEGORIES)}
              idValueList={optionsStore.getDefaultCategories(optionsStore.categoriesMap)}
              headerText="Sort Categories"
            />
          </>
        ),
      );
    }

    const langButtons = getLangActionBarButtons(
      {
        allLangs: optionsStore.allLangs,
        selectedLangsMap: optionsStore.selectedLangsMap,
        updateSelectedLangs: optionsStore.updateSelectedLangs,
        showActionButtons,
      },
      {
        canSyncUpdates: teamStore.team.canSyncUpdates,
        seriesId,
        year,
        syncUpdates,
      },
    );
    actionBarButtons.push(...langButtons);

    return (
      <>
        {actionBarButtons.map((button, index) => (
          <React.Fragment key={index}>
            <ActionBarDivider />
            {button}
          </React.Fragment>
        ))}
      </>
    );
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return undefined;
    }

    const [removed] = optionsStore.filteredOptionLangMaps.splice(result.source.index, 1);
    optionsStore.filteredOptionLangMaps.splice(result.destination.index, 0, removed);
    optionsStore.filteredOptionLangMaps.forEach((item, index: number) => {
      Object.values(item.langs).forEach(optionItem => {
        optionItem.sortOrder = index + 1;
      });
    });
    return optionsStore.filteredOptionLangMaps;
  };

  const showFormFieldError = () => {
    toast.error('Please finish filling out all items of the new option.');
  };

  const compareFeatureLangMap = (option: OptionLangMap) => {
    setSyncChangesOption(option);
    setShowSyncChangesModal(true);
  };

  const applyChanges = (response: AppliedChangesResponse) => {
    if (!syncChangesOption) {
      return;
    }

    const changeLogTypes: ChangeLogTypes[] = Object.keys(response.applied) as ChangeLogTypes[];
    changeLogTypes.forEach(changeType => {
      const langMap = response.applied[changeType];
      if (langMap) {
        Object.entries(langMap).forEach(([lang, after]) => {
          const option = syncChangesOption.langs[lang];
          if (option) {
            switch (changeType) {
              case ChangeLogTypes.LINK:
                option.link = after;
                break;
              case ChangeLogTypes.DESCRIPTION:
                option.description = after;
                break;
              case ChangeLogTypes.NOTES:
                option.notes = after;
                break;
              case ChangeLogTypes.IN_PROGRESS:
                option.isInProgress = after.toString() === 'true';
                break;
              case ChangeLogTypes.MODEL_APPLICABILITY:
                const modApp = after as KeyValueType<string>;
                Object.values(option.models).forEach(model => {
                  if (modApp[model.id]) {
                    model.setting = modApp[model.id] as OptionSettings;
                  } else {
                    model.setting = OptionSettings.UNDEFINED;
                  }
                });
                break;
              case ChangeLogTypes.NAME:
                option.name = after;
                break;
              case ChangeLogTypes.CATEGORY:
                option.category = after;
                break;
              case ChangeLogTypes.CODE:
                option.code = after;
                break;
              case ChangeLogTypes.OPTION_EXTRA_COST:
                option.isExtraCost = after;
                break;
              default:
                break;
            }
          }
        });
      }
    });

    saveOptionLangMap(syncChangesOption, undefined, true);
  };

  return !isLoaded ? (
    <Spinner />
  ) : (
    <>
      <ActionBarVehicleData
        readOnly={readOnly}
        toggleViewModelCodes={() => (optionsStore.viewModelCodes = !optionsStore.viewModelCodes)}
        viewModelCodes={optionsStore.viewModelCodes}
        searchText={optionsStore.searchText}
        onSearchTextChange={text => optionsStore.onFilter(() => (optionsStore.searchText = text))}
        renderButtons={getActionBarButtons(!readOnly)}
        renderFilter={onClose => (
          <ProductTeamFilters
            onClose={onClose}
            categories={optionsStore.getDefaultCategories(optionsStore.categoriesMap).map(cat => cat.value)}
            categoryFilters={optionsStore.categoryFilters}
            setCategoryFilters={categoryFilters => optionsStore.onFilter(() => (optionsStore.categoryFilters = categoryFilters))}
            isInProgressFilter={optionsStore.isInProgressFilter}
            setIsInProgressFilter={value => optionsStore.onFilter(() => (optionsStore.isInProgressFilter = value))}
            isSyncUpdateFilter={optionsStore.isSyncUpdateFilter}
            setIsSyncUpdateFilter={value => optionsStore.onFilter(() => (optionsStore.isSyncUpdateFilter = value))}
            isReviewNotesFilter={optionsStore.isReviewNotesFilter}
            setIsReviewNotesFilter={
              team === VehicleTeam.AGENCY_TEAM && version == null ? value => optionsStore.onFilter(() => (optionsStore.isReviewNotesFilter = value)) : undefined
            }
            isPublished={isPublished}
          />
        )}
      />
      <TwoTableWrapper>
        <DragDropContext onDragEnd={onDragEnd}>
          <LeftTable>
            <OptionsHeaderRow
              viewModelCodes={optionsStore.viewModelCodes}
              allowLinks={teamStore.team.allowLinks}
              readOnly={readOnly}
              onSort={optionsStore.onSort}
              sortMode={sortMode}
            />
            <OptionsRowContainer
              optionLangMaps={optionsStore.filteredOptionLangMaps}
              sortMode={sortMode}
              readOnly={readOnly}
              saveOptionLangMap={saveOptionLangMap}
              deleteOptionLangMap={deleteOptionLangMap}
              copyOptionLangMap={copyOptionLangMap}
              addCategoryItem={addCategoryItem}
              updateCategoryItem={updateCategoryItem}
              showLink={teamStore.team.allowLinks}
              allowDisclaimerTokens={teamStore.team.allowDisclaimerTokens}
              disclaimerTokens={disclaimerTokens}
              compareFeature={compareFeatureLangMap}
            />
          </LeftTable>
        </DragDropContext>
        <ModelTable
          showFeatureSplits={false}
          viewModelCodes={optionsStore.viewModelCodes}
          models={vehicleModels}
          headerStyle={{ top: 0 }}
          renderRows={() => (
            <>
              {optionsStore.filteredOptionLangMaps.map((optionLangMap, idx) => {
                const defaultOption = optionLangMap.langs[optionsStore.defaultLang];
                const changedModelIds: string[] = [];
                for (const lang of optionsStore.editableLangs) {
                  changedModelIds.push(...optionLangMap.langs[lang].changedModelIds);
                }
                return (
                  <React.Fragment key={defaultOption.uid}>
                    <TableRow rowHeight={optionsStore.getOptionsRowHeight(optionLangMap)}>
                      {vehicleModels.map((model, index) => {
                        if (model.show && defaultOption.models[index]) {
                          const syncValueChanged = changedModelIds.includes(model.id);
                          let defaultSetting = '';
                          let setEditableText = false;
                          const applicabilityTextMap: ApplicabilityTextLangMap = { text: {} };
                          for (const lang of optionsStore.allLangs) {
                            const canEdit = optionsStore.editableLangs.includes(lang);
                            const text = optionLangMap.langs[lang].models[index].setting;
                            if (canEdit && text && !setEditableText) {
                              defaultSetting = text;
                              setEditableText = true;
                            } else if (!canEdit && !defaultSetting) {
                              defaultSetting = text;
                            }
                            applicabilityTextMap.text[lang] = {
                              text,
                              canEdit,
                            };
                            if (syncValueChanged && !canEdit) {
                              applicabilityTextMap.text[lang]!.syncValueChange = syncValueChanged;
                            }
                          }
                          const modelData = {
                            id: defaultOption.models[index].id,
                            applicabilityTextMap,
                            setting: defaultSetting,
                          };

                          return (
                            <SettingsCell
                              key={`${defaultOption.uid}${model.uid}${defaultSetting}`}
                              disableApplicabilityText={optionsStore.allLangs.length <= 1 || readOnly}
                              disableInput={!optionsStore.editableLangs.includes(Language.EN)}
                              disabled={readOnly}
                              model={modelData}
                              syncValueChanged={changedModelIds.includes(model.id)}
                              oddRow={idx % 2 === 1}
                              rowSpan={defaultOption.splitCount + 1}
                              allowTokens={teamStore.team.allowDisclaimerTokens}
                              disclaimerTokens={disclaimerTokens}
                              onUpdate={value => {
                                let shouldSave = false;
                                optionsStore.editableLangs.forEach(lang => {
                                  const setting = optionLangMap.langs[lang].models[index].setting || '';
                                  shouldSave = shouldSave || setting !== value;
                                  optionLangMap.langs[lang].models[index].setting = value;
                                });
                                if (shouldSave) {
                                  saveOptionLangMap(optionLangMap);
                                }
                              }}
                              onUpdateApplicabilityText={applicabilityTextMap => {
                                let shouldSave = false;
                                const langs = Object.keys(applicabilityTextMap.text) as Language[];
                                langs.forEach(lang => {
                                  const textMap = applicabilityTextMap.text[lang]!;
                                  if (textMap.canEdit) {
                                    const option = optionLangMap.langs[lang];
                                    const curTxt = option.models[index].setting;
                                    if (curTxt !== textMap.text) {
                                      shouldSave = true;
                                      option.models[index].setting = textMap.text;
                                    }
                                  }
                                });
                                if (shouldSave) {
                                  saveOptionLangMap(optionLangMap);
                                }
                              }}
                              allowFreeFormText
                            />
                          );
                        }
                        return <></>;
                      })}
                    </TableRow>
                  </React.Fragment>
                );
              })}
            </>
          )}
        />
      </TwoTableWrapper>
      <Modal open={showSyncChangesModal} size="auto" onClose={() => setShowSyncChangesModal(false)}>
        <SyncTMNAChangesModal
          brand={brand}
          team={team}
          seriesId={seriesId}
          year={year}
          itemId={syncChangesOption ? syncChangesOption.langs[optionsStore.defaultLang].id : ''}
          entityType={'options'}
          isNew={!!syncChangesOption?.langs[optionsStore.defaultLang].changedAttributes?.includes('new')}
          isDelete={!!syncChangesOption?.langs[optionsStore.defaultLang].changedAttributes?.includes('delete')}
          close={(response, shouldDelete, unlinkFromTMNA) => {
            setShowSyncChangesModal(false);
            if (syncChangesOption) {
              if (response) {
                applyChanges(response);
              } else if (shouldDelete) {
                deleteOptionLangMap(syncChangesOption);
              } else if (unlinkFromTMNA) {
                saveOptionLangMap(syncChangesOption, undefined, false, true);
              }
            }
          }}
          vehicleModels={vehicleModels}
          sourceVersion={sourceVersion}
        />
      </Modal>
    </>
  );
};

export default observer(OptionsController);
