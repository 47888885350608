import { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { AppliedChangesResponse, ChangeLogTypes } from '../models/changeLog.model';
import { ColorGradeItem, ColorGradeLangItem, InteriorColorResponse } from '../models/colors.model';
import { IDValueType, KeyValueType } from '../models/common.model';
import { FuelTypeItemResponse } from '../models/fuelType.model';
import { RefItem } from '../models/refItem.model';
import { ReviewSyncItem, TMNASyncReviewResponse } from '../models/review.model';
import { Language } from '../models/user.model';
import { VehicleModelItem, VehicleModelToyota } from '../models/vehicleModel.model';
import { AppliedMap, ChangesMap, EntityType } from '../routes/vehicleData/components/SyncTMNAChangesModal/SyncTMNAChangesModal';
import { changeLogModelApplicabilityItemMapper, changeLogVehicleModelMapper } from '../utils/changeLogUtils';
import { reviewColorApplicabilityMapper } from '../utils/colorUtils';
import { getFuelTypeNameByIdUtil } from '../utils/seriesSettingsUtils';
import { compareItem } from '../webservices/vehicleAdminApi';

type Props = {
  isNew: boolean;
  isDelete: boolean;
  sourceVersion?: number;
  brand: string;
  team: string;
  seriesId: string;
  year: string;
  entityType: EntityType;
  itemId: string;
  parentSeriesId?: string;
  colorGradeLangItems?: ColorGradeLangItem[];
  vehicleModels?: VehicleModelItem<VehicleModelToyota>[];
  fuelTypes?: IDValueType[];
  grades?: RefItem[];
  fuelTypeList?: FuelTypeItemResponse[];
  close: (response?: AppliedChangesResponse, shouldDelete?: boolean, unlinkFromTMNA?: boolean) => void;
};

const fillFuelTypeMap = (value: any, fuelTypeList: FuelTypeItemResponse[]) => {
  const fuelTypeMap: KeyValueType<boolean> = {};

  if (value) {
    for (const fuelType of Object.keys(value)) {
      if (value[fuelType]) {
        fuelTypeMap[getFuelTypeNameByIdUtil(fuelTypeList, fuelType)] = true;
      }
    }
  }

  return fuelTypeMap;
};

const seriesFuelTypesMapper = (item: ReviewSyncItem, changeLogType: ChangeLogTypes, fuelTypeList: FuelTypeItemResponse[]) => {
  if (item.changes.changeType === changeLogType) {
    const beforeMap = fillFuelTypeMap(item.changes.before, fuelTypeList);
    const afterMap = fillFuelTypeMap(item.changes.after, fuelTypeList);
    const beforeArr: string[] = [];
    const beforeFuelTypes = Object.keys(beforeMap);

    for (const fuelType of beforeFuelTypes) {
      if (!afterMap[fuelType]) {
        beforeArr.push(fuelType);
      } else {
        delete beforeMap[fuelType];
        delete afterMap[fuelType];
      }
    }

    item.changes.extColorAppBefore = beforeArr;
    item.changes.extColorAppAfter = Object.keys(afterMap);
  }
};

const useSyncTMNAChanges = ({
  isNew,
  isDelete,
  sourceVersion,
  brand,
  team,
  seriesId,
  year,
  entityType,
  itemId,
  parentSeriesId,
  colorGradeLangItems,
  vehicleModels,
  fuelTypes,
  grades,
  fuelTypeList = [],
  close,
}: Props) => {
  const changesMap = useRef<ChangesMap>({ changes: {} });
  const querying = useRef(false);
  const [syncItems, setSyncItems] = useState<ReviewSyncItem[]>([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [numberChecked, setNumberChecked] = useState(0);
  const [appliedMap, setAppliedMap] = useState<AppliedMap>({ applied: {} });

  const load = useCallback(async () => {
    try {
      let data: TMNASyncReviewResponse = { langs: {} };

      if (sourceVersion) {
        const res = await compareItem(brand, team, seriesId, year, entityType, itemId, parentSeriesId);
        data = res.data;
      }

      const items: ReviewSyncItem[] = [];
      const appMap: AppliedMap = { applied: {} };
      const cMap: ChangesMap = { changes: {} };

      Object.entries(data.langs).forEach(([lang, response]) => {
        let colorGradeItems: ColorGradeItem[] = [];

        if (colorGradeLangItems && colorGradeLangItems.length) {
          colorGradeItems = colorGradeLangItems.map(gradeItem => {
            const item = new ColorGradeItem(gradeItem.grade);

            gradeItem.interiorItems.forEach(interiorItem => {
              const res = JSON.parse(JSON.stringify(interiorItem.langs[lang])) as InteriorColorResponse;
              let newName: any = res.name;

              try {
                newName = JSON.parse(newName);
                if (newName.text) {
                  res.name = newName.text;
                }
              } catch {}

              item.interiorItems.push(res);
            });
            return item;
          });
        }

        if (response) {
          Object.entries(response.changes).forEach(([changeTypeId, change]) => {
            const item = new ReviewSyncItem(lang as Language, response, change, changeTypeId);

            if (vehicleModels && vehicleModels.length) {
              changeLogModelApplicabilityItemMapper(vehicleModels, item.changes);
            }

            if (fuelTypes && fuelTypes.length) {
              changeLogVehicleModelMapper(fuelTypes, [item.changes], ChangeLogTypes.FUEL_TYPE);
            }

            if (entityType === 'exteriorColors' && grades && grades.length && colorGradeItems && colorGradeItems.length) {
              reviewColorApplicabilityMapper(item, colorGradeItems, ChangeLogTypes.EXT_COLOR_APPLICABILITY, grades);
            }

            if (entityType === 'series') {
              seriesFuelTypesMapper(item, ChangeLogTypes.FUEL_TYPES, fuelTypeList);
            }

            if (!appMap.applied[item.changes.changeType]) {
              appMap.applied[item.changes.changeType] = {};
            }

            appMap.applied[item.changes.changeType]![lang] = false;

            if (!cMap.changes[change.changeType]) {
              cMap.changes[change.changeType] = {};
            }

            cMap.changes[change.changeType]![lang] = item;
          });
        }
      });

      // group them by change type
      Object.values(cMap.changes).forEach(langMap => {
        if (langMap) {
          Object.values(langMap).forEach(item => {
            items.push(item);
          });
        }
      });

      changesMap.current = cMap;
      setSyncItems(items);
      setAppliedMap(appMap);
      setIsLoaded(true);
    } catch (e) {
      console.log(e);
      toast.error('Error loading data');
      close();
    }
  }, [
    sourceVersion,
    brand,
    team,
    seriesId,
    year,
    entityType,
    itemId,
    parentSeriesId,
    colorGradeLangItems,
    vehicleModels,
    fuelTypes,
    grades,
    fuelTypeList,
    close,
    setSyncItems,
    setAppliedMap,
    setIsLoaded,
  ]);

  useEffect(() => {
    if ((isNew || isDelete) && !isLoaded) {
      setIsLoaded(true);
    }

    if (querying.current || isNew || isDelete) {
      return;
    }

    querying.current = true;

    load();
  }, [isLoaded, isNew, isDelete, load, setIsLoaded]);

  useEffect(() => {
    let numChecked = 0;

    Object.values(appliedMap.applied).forEach(langMap => {
      if (langMap) {
        numChecked += Object.values(langMap).filter(value => value).length;
      }
    });

    setNumberChecked(numChecked);
  }, [appliedMap.applied, setNumberChecked]);

  return { syncItems, numberChecked, isLoaded, changesMap, appliedMap, setAppliedMap };
};

export default useSyncTMNAChanges;
