import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { toast } from 'react-toastify';
import ChangeLogInner from '../../../../components/ChangeLogInner';
import useStores from '../../../../hooks/useStores';
import { ChangeLogItem, ChangeLogLangMap, ChangeLogResponse, ChangeLogTypes, SYNCED_CHANGE_TYPES_MAP } from '../../../../models/changeLog.model';
import { KeyValueType } from '../../../../models/common.model';
import { Language } from '../../../../models/user.model';
import { changeLogColorApplicabilityMapper, changeLogHandleDeleteTypes, changeLogModelApplicabilityMapper } from '../../../../utils/changeLogUtils';
import { getChangeLog, getInteriorColors, revertChange } from '../../../../webservices/vehicleColorsApi';

interface ColorsChangeLogProps {
  seriesId: string;
  year: string;
  version: string;
  readOnly?: boolean;
  loadData?: () => Promise<void>;
}

const ColorsChangeLogController = (props: ColorsChangeLogProps) => {
  const { seriesId, year, version, readOnly } = props;

  const {
    userStore: { brand },
    teamStore,
    changeLogStore,
    vehicleModelsStore,
  } = useStores();

  const loadData = async () => {
    let versionNum = version;
    if (version && isNaN(Number(version))) {
      versionNum = '';
    }

    const selectedLangs: KeyValueType<boolean> = {};
    teamStore.team.changeLogLanguages.forEach(lang => {
      selectedLangs[lang] = true;
    });
    changeLogStore.selectedLangsMap = selectedLangs;

    const promises: Promise<any>[] = [];
    for (const lang of teamStore.team.changeLogLanguages) {
      promises.push(
        getChangeLog(brand, teamStore.team.param, seriesId, year, lang, versionNum),
        getInteriorColors(brand, teamStore.team.param, seriesId, year, lang, versionNum, true), // fetch the deleted records as well
      );
    }

    const responses = await Promise.all(promises);
    let index = 0;
    const changeLogLangMap: ChangeLogLangMap = {};

    for (const lang of teamStore.team.changeLogLanguages) {
      const baseIndex = index * 2;
      const logs = (responses[baseIndex].data as ChangeLogResponse[]).map(item => {
        const changeLogItem = new ChangeLogItem(item, !readOnly, lang);

        if (SYNCED_CHANGE_TYPES_MAP[changeLogItem.changeType] && teamStore.team.changeLogLanguages.length > 1) {
          changeLogItem.canRevert = false;
        }
        return changeLogItem;
      });
      changeLogColorApplicabilityMapper(responses[baseIndex + 1].data, logs, ChangeLogTypes.EXT_COLOR_APPLICABILITY, vehicleModelsStore.grades);
      changeLogHandleDeleteTypes(logs, ChangeLogTypes.INT_COLOR_DELETED);
      changeLogHandleDeleteTypes(logs, ChangeLogTypes.EXT_COLOR_DELETED);
      changeLogModelApplicabilityMapper(vehicleModelsStore.vehicleModels, logs);
      changeLogLangMap[lang] = logs;

      index += 1;
    }

    changeLogStore.changeLogLangMap = changeLogLangMap;
  };

  return <ColorsChangeLog {...props} loadData={loadData} />;
};

const ColorsChangeLog = observer(({ seriesId, year, readOnly, version, loadData = async () => {} }: ColorsChangeLogProps) => {
  const {
    userStore: { brand },
    teamStore,
    changeLogStore,
    colorsStore,
  } = useStores();

  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    changeLogStore.reset();

    (async () => {
      setIsLoaded(false);

      try {
        await loadData();
      } catch (e) {
        toast.error('Error loading colors change log');
      }

      setIsLoaded(true);
    })();
  }, [loadData, brand, changeLogStore, readOnly, seriesId, teamStore, year, colorsStore, version]);

  const handleOnRevert = async (item: ChangeLogItem) => {
    setIsLoaded(false);
    try {
      const lang = item.language ?? Language.EN;
      await trackPromise(revertChange(brand, teamStore.team.param, seriesId, year, lang, item.payload));
      await loadData();

      toast.success('Change log reverted');
    } catch {
      toast.error('Error reverting change log');
    }
    setIsLoaded(true);
  };
  return <ChangeLogInner module={'Colors'} isLoaded={isLoaded} handleOnRevert={handleOnRevert} />;
});

export default observer(ColorsChangeLogController);
