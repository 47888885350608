import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { toast } from 'react-toastify';
import { ActionBar, ActionBarSection, Modal } from 'vapi-ui-common';
import Spinner from '../../../../components/Spinner';
import SyncUpdatesPopover from '../../../../components/SyncUpdatesPopover';
import { langNameMap } from '../../../../constants/vehicleData/VDConstants';
import useSeriesSettings from '../../../../hooks/useSeriesSettings';
import useStores from '../../../../hooks/useStores';
import { AppliedChangesResponse, ChangeLogTypes } from '../../../../models/changeLog.model';
import { SeriesSettingsItem, SeriesSettingsLangMap } from '../../../../models/seriesSettings.model';
import { Brand } from '../../../../models/user.model';
import { ProductDataControllerProps } from '../../../../routes/vehicleData/models/controllers.model';
import { handleErrorResponse } from '../../../../utils/errorHandlingUtils';
import { syncSpanishUpdates } from '../../../../webservices/vehicleAdminApi';
import { updateSeriesSetting } from '../../../../webservices/vehicleModelsApi';
import SyncTMNAChangesModal from '../../components/SyncTMNAChangesModal/SyncTMNAChangesModal';
import SeriesSettingsTable from './components/SeriesSettingsTable';

const SeriesSettingsController = ({ team, readOnly, seriesId, year, versionInfo, reloadDraft, isPublished, sourceVersion }: ProductDataControllerProps) => {
  const {
    userStore: { brand },
    seriesSettingsStore,
    teamStore,
  } = useStores();

  const { isLoaded, setIsLoaded } = useSeriesSettings(brand, seriesId, team, year, versionInfo);
  const [showSyncChangesModal, setShowSyncChangesModal] = useState(false);
  const [syncChangesSeriesSettings, setSyncChangesSeriesSettings] = useState<SeriesSettingsLangMap | undefined>(undefined);

  const addSeriesSettingItem = async (seriesSetting: SeriesSettingsItem, lang: string) => {
    /* let response; */
    try {
      /* 
        response = await trackPromise(
          addSeriesSetting(brand, team, seriesId, year, lang, seriesSetting.getPayloadToyota())
        );
      seriesSetting.revId = response.data.revId;
      seriesSetting.id = response.data.id; */

      toast.success(`Added ${langNameMap[lang]} series setting successfully`);
    } catch (e) {
      handleErrorResponse(e, 'Error adding series setting');
    }
  };

  const updateSeriesSettingItem = async (seriesSetting: SeriesSettingsItem, lang: string, acceptChanges: boolean, unlinkFromTMNA: boolean = false) => {
    let response;
    try {
      response = await trackPromise(updateSeriesSetting(brand, team, seriesId, year, lang, seriesSetting.getPayloadToyota(), acceptChanges, unlinkFromTMNA));

      if (unlinkFromTMNA || acceptChanges) {
        seriesSetting.changedAttributes = [];
      }
      if (unlinkFromTMNA) {
        seriesSetting.fromTMNA = false;
      }
      seriesSetting.revId = response.data.revId;
      seriesSetting.id = response.data.id;
      for (const langMap of seriesSettingsStore.seriesSettingsLangMaps) {
        if (langMap[lang].parentId === seriesSetting.id) {
          langMap[lang].parentRevId = seriesSetting.revId;
        }
      }
      toast.success(`Updated ${langNameMap[lang]} series setting successfully`);
    } catch (e) {
      handleErrorResponse(e, 'Error updating series setting');
    }
  };

  const saveSeriesSettingsLangMap = async (seriesSettingsLangMap: SeriesSettingsLangMap, acceptChanges: boolean = false, unlinkFromTMNA: boolean = false) => {
    const promises: Promise<any>[] = [];
    let isAdding = false;
    for (const lang of Object.keys(seriesSettingsLangMap)) {
      const settings = seriesSettingsLangMap[lang];
      if (settings.revId) {
        promises.push(updateSeriesSettingItem(settings, lang, acceptChanges, unlinkFromTMNA));
      } else {
        isAdding = true;
        promises.push(addSeriesSettingItem(settings, lang));
      }
    }
    await Promise.all(promises);
    if (isAdding) {
      const seriesSettingsMap = seriesSettingsStore.getSeriesSettingsMap(seriesSettingsStore.seriesSettingsLangMaps);
      const id = seriesSettingsLangMap[seriesSettingsStore.defaultLang].id;
      if (!seriesSettingsMap.series[id]) {
        seriesSettingsStore.seriesSettingsLangMaps = [seriesSettingsLangMap, ...seriesSettingsStore.seriesSettingsLangMaps];
      }
    }
  };

  const deleteSeriesSettingsLangMap = async (seriesSettingsLangMap: SeriesSettingsLangMap) => {
    try {
      const deletedId = seriesSettingsLangMap[seriesSettingsStore.defaultLang].id;
      /* for (const lang of Object.keys(seriesSettingsLangMap)) {
        const settings = seriesSettingsLangMap[lang];
        await trackPromise(deleteSeriesSetting(brand, team, seriesId, year, lang, settings));
      } */
      seriesSettingsStore.seriesSettingsLangMaps = seriesSettingsStore.seriesSettingsLangMaps.filter(
        langMap =>
          langMap[seriesSettingsStore.defaultLang].id !== deletedId &&
          (!langMap[seriesSettingsStore.defaultLang].isSubSeries || langMap[seriesSettingsStore.defaultLang].parentId !== deletedId),
      );
      toast.success('Deleted series setting successfully');
    } catch (e) {
      handleErrorResponse(e, 'Error deleting series setting');
    }
  };

  const addSubSeriesSettingItem = async (seriesSetting: SeriesSettingsItem, lang: string) => {
    /* let response; */
    try {
      /* 
        response = await trackPromise(
          addSeriesSetting(
            brand,
            team,
            seriesId,
            year,
            lang,
            seriesSetting.getSubSeriesPayloadToyota()
          )
        );

      seriesSetting.revId = response.data.revId;
      seriesSetting.id = response.data.id; */

      toast.success(`Added ${langNameMap[lang]} sub series setting successfully`);
    } catch (e) {
      handleErrorResponse(e, 'Error adding series setting');
    }
  };

  const updateSubSeriesSettingItem = async (seriesSetting: SeriesSettingsItem, lang: string, acceptChanges: boolean, unlinkFromTMNA: boolean = false) => {
    let response;
    try {
      response = await trackPromise(updateSeriesSetting(brand, team, seriesId, year, lang, seriesSetting.getSubSeriesPayloadToyota(), acceptChanges, unlinkFromTMNA));

      if (acceptChanges || unlinkFromTMNA) {
        seriesSetting.changedAttributes = [];
      }
      if (unlinkFromTMNA) {
        seriesSetting.fromTMNA = false;
      }
      seriesSetting.revId = response.data.revId;
      seriesSetting.id = response.data.id;

      toast.success(`Updated ${langNameMap[lang]} sub series setting successfully`);
    } catch (e) {
      handleErrorResponse(e, 'Error updating sub series setting');
    }
  };

  const saveSubSeriesSettingsLangMap = async (seriesSettingsLangMap: SeriesSettingsLangMap, acceptChanges: boolean = false, unlinkFromTMNA: boolean = false) => {
    const promises: Promise<any>[] = [];
    for (const lang of Object.keys(seriesSettingsLangMap)) {
      const settings = seriesSettingsLangMap[lang];
      if (settings.revId) {
        promises.push(updateSubSeriesSettingItem(settings, lang, acceptChanges, unlinkFromTMNA));
      } else {
        promises.push(addSubSeriesSettingItem(settings, lang));
      }
    }
    await Promise.all(promises);
  };

  const deleteSubSeriesSettingsMap = async (seriesSettingsLangMap: SeriesSettingsLangMap) => {
    try {
      /* for (const lang of Object.keys(seriesSettingsLangMap)) {
        const settings = seriesSettingsLangMap[lang];
        await trackPromise(deleteSubSeriesSetting(brand, team, seriesId, year, lang, settings));
      } */
      seriesSettingsStore.seriesSettingsLangMaps = seriesSettingsStore.seriesSettingsLangMaps.filter(
        langMap => langMap[seriesSettingsStore.defaultLang].id !== seriesSettingsLangMap[seriesSettingsStore.defaultLang].id,
      );

      toast.success('Deleted sub series setting successfully');
    } catch (e) {
      handleErrorResponse(e, 'Error deleting sub series setting');
    }
  };

  const syncUpdates = async () => {
    setIsLoaded(false);
    try {
      await syncSpanishUpdates(brand, team, seriesId, year);
      toast.success('Sync successful');
      if (reloadDraft) {
        setIsLoaded(true);
        reloadDraft();
      }
    } catch (e) {
      handleErrorResponse(e, 'Error syncing spanish data');
      setIsLoaded(true);
    }
  };

  const compareSettings = (settings: SeriesSettingsLangMap) => {
    setSyncChangesSeriesSettings(settings);
    setShowSyncChangesModal(true);
  };

  const applyChanges = (response: AppliedChangesResponse) => {
    if (!syncChangesSeriesSettings) {
      return;
    }
    const changeLogTypes: ChangeLogTypes[] = Object.keys(response.applied) as ChangeLogTypes[];
    changeLogTypes.forEach(changeType => {
      const langMap = response.applied[changeType];
      if (langMap) {
        Object.entries(langMap).forEach(([lang, after]) => {
          const seriesSettings = syncChangesSeriesSettings[lang];
          if (seriesSettings) {
            switch (changeType) {
              case ChangeLogTypes.NAME:
                seriesSettings.name = after;
                break;
              case ChangeLogTypes.ESTIMATED_MILEAGE:
                seriesSettings.estimatedMileage = after;
                break;
              case ChangeLogTypes.MPGE:
                seriesSettings.mpge = after;
                break;
              case ChangeLogTypes.RANGE:
                seriesSettings.range = after;
                break;
              case ChangeLogTypes.SEATING:
                seriesSettings.seating = after;
                break;
              case ChangeLogTypes.FUEL_TYPES:
                seriesSettings.fuelTypes = after;
                break;
              default:
                break;
            }
          }
        });
      }
    });
    seriesSettingsStore.allLangs.forEach(lang => {
      syncChangesSeriesSettings[lang].changedAttributes = [];
    });
    if (syncChangesSeriesSettings[seriesSettingsStore.defaultLang].isSubSeries) {
      saveSubSeriesSettingsLangMap(syncChangesSeriesSettings, true);
    } else {
      saveSeriesSettingsLangMap(syncChangesSeriesSettings, true);
    }
  };

  const getActionBarButtons = () => {
    const actionBarButtons: React.ReactNode[] = [];

    if (!isPublished && teamStore.team.canSyncUpdates) {
      actionBarButtons.push(<SyncUpdatesPopover seriesId={seriesId} year={year} syncUpdates={syncUpdates} align="left" />);
    }

    return (
      <>
        {actionBarButtons.map((button, index) => (
          <React.Fragment key={index}>{button}</React.Fragment>
        ))}
      </>
    );
  };
  return !isLoaded ? (
    <Spinner />
  ) : (
    <>
      <ActionBar>
        <ActionBarSection>{getActionBarButtons()}</ActionBarSection>
      </ActionBar>
      <SeriesSettingsTable
        fuelTypesList={seriesSettingsStore.fuelTypesList}
        seriesCategoriesList={seriesSettingsStore.seriesCategoriesList}
        brand={brand as Brand}
        canAddNewSeriesSettings={teamStore.team.canAddNewSeriesSettings}
        readOnly={readOnly}
        onSaveSeriesSetting={saveSeriesSettingsLangMap}
        onDeleteSeriesSetting={deleteSeriesSettingsLangMap}
        onSaveSubSeriesSetting={saveSubSeriesSettingsLangMap}
        onDeleteSubSeriesSetting={deleteSubSeriesSettingsMap}
        seriesSettingsLangMaps={seriesSettingsStore.seriesSettingsLangMaps}
        compareSettings={compareSettings}
      />
      <Modal open={showSyncChangesModal} size="auto" onClose={() => setShowSyncChangesModal(false)}>
        <SyncTMNAChangesModal
          brand={brand}
          team={team}
          seriesId={seriesId}
          year={year}
          itemId={syncChangesSeriesSettings ? syncChangesSeriesSettings[seriesSettingsStore.defaultLang].id : ''}
          entityType={'series'}
          isNew={!!(syncChangesSeriesSettings && syncChangesSeriesSettings[seriesSettingsStore.defaultLang].changedAttributes?.includes('new'))}
          isDelete={!!(syncChangesSeriesSettings && syncChangesSeriesSettings[seriesSettingsStore.defaultLang].changedAttributes?.includes('delete'))}
          close={(response, shouldDelete, unlinkFromTMNA) => {
            setShowSyncChangesModal(false);
            if (syncChangesSeriesSettings) {
              if (response) {
                applyChanges(response);
              } else if (shouldDelete) {
                if (syncChangesSeriesSettings[seriesSettingsStore.defaultLang].isSubSeries) {
                  deleteSubSeriesSettingsMap(syncChangesSeriesSettings);
                } else {
                  deleteSeriesSettingsLangMap(syncChangesSeriesSettings);
                }
              } else if (unlinkFromTMNA) {
                if (syncChangesSeriesSettings[seriesSettingsStore.defaultLang].isSubSeries) {
                  saveSubSeriesSettingsLangMap(syncChangesSeriesSettings, false, true);
                } else {
                  saveSeriesSettingsLangMap(syncChangesSeriesSettings, false, true);
                }
              }
            }
          }}
          parentSeriesId={
            syncChangesSeriesSettings && syncChangesSeriesSettings[seriesSettingsStore.defaultLang].isSubSeries
              ? syncChangesSeriesSettings[seriesSettingsStore.defaultLang].parentId
              : ''
          }
          fuelTypeList={seriesSettingsStore.fuelTypesList}
          sourceVersion={sourceVersion}
        />
      </Modal>
    </>
  );
};

export default observer(SeriesSettingsController);
