import { processRteForChangeLog } from 'vapi-ui-common';
import { FeatureSplit as FeatureSplitGQL, GradeApplicability as GradeApplicabilityGQL, ModelApplicability, VehicleFeaturesResponse } from '../gql/generated';
import { ChangeLogTypes } from '../models/changeLog.model';
import { IDValueType, KeyValueType } from '../models/common.model';
import { CompareFeatureItem } from '../models/compareFeatures.model';
import {
  FeatureItem,
  FeatureModel,
  FeatureResponse,
  FeatureReviewType,
  FeatureSettings,
  FeatureSplitItem,
  FeatureSplitResponse,
  FeaturesChangeTypeMap,
  FeaturesReviewItem,
  FeaturesReviewMap,
  FeaturesReviewResponse,
  GradeApplicability,
  GradeApplicabilityItem,
  GradeApplicabilityText,
} from '../models/features.model';
import { VehicleModelItem, VehicleModelToyota } from '../models/vehicleModel.model';
import { changeLogModelApplicabilityItemMapper } from './changeLogUtils';
import { getDefaultChangeTypeMap, handleOtherChangesForReview } from './reviewUtils';

export const modelApplicabilityGQLToModelApplicability = (modelApplicability: ModelApplicability[]) =>
  modelApplicability.reduce(
    (map, mApplicability) => ({
      ...map,
      [mApplicability.id]: mApplicability.text,
    }),
    {},
  ) ?? {};

const gradeApplicabilityGQLToGradeApplicability = (gradeApplicability: GradeApplicabilityGQL[]): GradeApplicability =>
  gradeApplicability.reduce((map, gApplicability) => {
    const applicabilityText: GradeApplicabilityText =
      gApplicability.applicabilityText?.reduce(
        (map, aText) => ({
          ...map,
          [aText.id]: { text: aText.text, availability: aText.availability },
        }),
        {},
      ) ?? {};

    const applicabiliyItem: GradeApplicabilityItem = {
      highlighted: gApplicability.highlighted,
      isComparable: false, // gApplicability.isComparable,
      applicabilityText,
    };

    return {
      ...map,
      [gApplicability.id]: applicabiliyItem,
    };
  }, {}) ?? {};

const featureSplitGQLToFeatureSplitResponse = (featureSplitGQL: FeatureSplitGQL[], featureId: string, featureRevId: string): FeatureSplitResponse[] =>
  featureSplitGQL.map(fSplit => ({
    id: fSplit.id,
    featureId,
    featureRevId,
    notes: fSplit.notes as string,
    description: fSplit.description,
    shortDescription: fSplit.shortDescription as string,
    longDescription: fSplit.longDescription as string,
    isHighlighted: fSplit.isHighlighted,
  })) ?? [];

export const vehicleFeaturesResponseToFeatureResponse = (vehicleFeaturesResponse?: VehicleFeaturesResponse): FeatureResponse[] =>
  vehicleFeaturesResponse?.vehicleFeatures?.map(feature => ({
    id: feature.id,
    revId: feature.revId,
    modelApplicability: modelApplicabilityGQLToModelApplicability(feature.modelApplicability ?? []),
    gradeApplicability: gradeApplicabilityGQLToGradeApplicability(feature.gradeApplicability ?? []),
    categoryId: feature.categoryId as string,
    subCategoryId: feature.subCategoryId as string,
    description: feature.description,
    shortDescription: feature.shortDescription as string,
    longDescription: feature.longDescription as string,
    isInProgress: feature.isInProgress,
    isHighlighted: feature.isHighlighted,
    notes: feature.notes as string,
    comLangId: feature.comLangId as string,
    splits: featureSplitGQLToFeatureSplitResponse(feature.splits ?? [], feature.id, feature.revId),
    changedAttributes: feature.changedAttributes ?? [],
    fromTMNA: feature.fromTMNA as boolean,
    category: feature.category,
    subCategory: feature.subCategory,
    link: feature.link as string,
    isDeleted: feature.isDeleted,
    tooltip: feature.tooltip,
  })) ?? [];

export const featuresXForm = (
  data: FeatureResponse[],
  vehicleModels: VehicleModelItem<VehicleModelToyota>[],
  categories: IDValueType[],
  subCategories: IDValueType[],
  switchShortLongDescription: boolean,
  compareFeatureMap: KeyValueType<CompareFeatureItem>,
) => {
  return data.map((item, index) => featureItemXForm(item, vehicleModels, categories, subCategories, switchShortLongDescription, index, compareFeatureMap));
};

export const mapEmptyFeatureModels = (vehicleModels: VehicleModelItem<VehicleModelToyota>[]): KeyValueType<FeatureModel> => {
  const map: KeyValueType<FeatureModel> = {};
  vehicleModels.forEach(mdl => {
    map[mdl.id] = {
      id: mdl.id,
      setting: FeatureSettings.UNDEFINED,
    };
  });
  return map;
};

export const featureItemXForm = (
  item: FeatureResponse,
  vehicleModels: VehicleModelItem<VehicleModelToyota>[],
  categories: IDValueType[],
  subCategories: IDValueType[],
  switchShortLongDescription: boolean,
  index: number,
  compareFeatureMap: KeyValueType<CompareFeatureItem>,
) => {
  const modelsMap: KeyValueType<FeatureModel> = {};
  vehicleModels.forEach(mdl => {
    modelsMap[mdl.id] = {
      id: mdl.id,
      setting: item.modelApplicability ? item.modelApplicability[mdl.id] : FeatureSettings.UNDEFINED,
    };
  });

  const category = categories.filter(cat => cat.id === item.categoryId);
  const subCategory = subCategories.filter(cat => cat.id === item.subCategoryId);

  const featureItem = new FeatureItem(item);
  featureItem.switchShortLongDescription = switchShortLongDescription;
  featureItem.category = category.length ? category[0] : featureItem.category;
  featureItem.subCategory = subCategory.length ? subCategory[0] : featureItem.subCategory;
  featureItem.modelsMap = modelsMap;
  featureItem.sortOrder = index + 1;

  // Initialize splits if any
  featureItem.splits = [];
  if (item.splits && item.splits.length) {
    item.splits.forEach((split, idx) => {
      const splitItem: FeatureSplitItem = new FeatureSplitItem(split);
      splitItem.name = `Split ${idx + 1}`;
      splitItem.featureId = featureItem.id;
      splitItem.featureRevId = featureItem.revId;
      featureItem.splits.push(splitItem);
    });
  }

  if (compareFeatureMap[featureItem.id]) {
    featureItem.compareFeatureId = compareFeatureMap[featureItem.id].id;
  }

  return featureItem;
};

export const featuresReviewXForm = (
  items: FeaturesReviewResponse[],
  vehicleModels: VehicleModelItem<VehicleModelToyota>[],
  categories: IDValueType[],
  subCategories: IDValueType[],
) => {
  const map: FeaturesReviewMap = {};
  const featureItems: FeaturesReviewItem[] = [];

  items.forEach(item => {
    let category = categories.filter(cat => cat.id === item.categoryId);
    if (!category) {
      const itemCategory: string = item.category ? item.category : '';
      category = [new IDValueType(itemCategory, itemCategory)];
    }
    let subCategory = subCategories.filter(cat => cat.id === item.subCategoryId);
    if (!subCategory) {
      const itemSubCategory: string = item.subCategory ? item.subCategory : '';
      subCategory = [new IDValueType(itemSubCategory, itemSubCategory)];
    }
    const models: FeatureModel[] = vehicleModels.map(mdl => ({
      id: mdl.id,
      setting: item.modelApplicability ? item.modelApplicability[mdl.id] : FeatureSettings.UNDEFINED,
    }));

    let isApplied = true;
    let rejectNotes = '';
    const app: KeyValueType<FeatureSettings> = {};
    vehicleModels.forEach(model => {
      app[model.id] = item.modelApplicability ? item.modelApplicability[model.id] : FeatureSettings.UNDEFINED;
    });

    if (!map[item.id]) {
      map[item.id] = getDefaultChangeTypeMap(item, app) as FeaturesChangeTypeMap;
    }

    map[item.id].category = {
      before: '',
      after: category[0]?.value ?? '',
      hasChanged: false,
    };
    map[item.id].subCategory = {
      before: '',
      after: subCategory[0]?.value ?? '',
      hasChanged: false,
    };

    Object.entries(item.changes).forEach(([key, change]) => {
      const featureItem = new FeaturesReviewItem(item, change);
      featureItem.category = category.length ? category[0] : featureItem.category;
      featureItem.subCategory = subCategory.length ? subCategory[0] : featureItem.subCategory;
      featureItem.models = models;
      featureItem.changeTypeId = key;

      switch (change.changeType) {
        case ChangeLogTypes.SUB_CATEGORY:
        case ChangeLogTypes.CATEGORY: {
          featureItem.changes.beforeValue = change.before as string;
          featureItem.changes.afterValue = change.after as string;
          break;
        }
      }

      if (featureItem.otherChanges) {
        handleOtherChangesForReview(featureItem.otherChanges, [
          { type: ChangeLogTypes.CATEGORY, refItems: categories },
          { type: ChangeLogTypes.SUB_CATEGORY, refItems: subCategories },
        ]);
        featureItem.otherChanges.forEach(ocItem => changeLogModelApplicabilityItemMapper(vehicleModels, ocItem.changes));
      }

      // map model applicability changes
      changeLogModelApplicabilityItemMapper(vehicleModels, featureItem.changes);
      featureItems.push(featureItem);

      isApplied = isApplied && change.isApplied;
      rejectNotes = change.rejectNotes || rejectNotes;
      const changeLogKey = getKeyFromChangeLogType(change.changeType);
      if (changeLogKey === 'deleted') {
        map[item.id].isDeleted = true;
        map[item.id].category.before = map[item.id].category.after;
        map[item.id].category.after = '';
        map[item.id].category.hasChanged = true;

        map[item.id].subCategory.before = map[item.id].subCategory.after;
        map[item.id].subCategory.after = '';
        map[item.id].subCategory.hasChanged = true;

        map[item.id].description.before = map[item.id].description.after;
        map[item.id].description.after = '';
        map[item.id].description.hasChanged = true;

        map[item.id].modelApplicability.before = map[item.id].modelApplicability.after;
        map[item.id].modelApplicability.after = {};
        map[item.id].modelApplicability.hasChanged = true;
      } else if (changeLogKey === 'added') {
        map[item.id].category.hasChanged = true;
        map[item.id].subCategory.hasChanged = true;
        map[item.id].description.hasChanged = true;
        map[item.id].modelApplicability.hasChanged = true;
        map[item.id].isNew = true;
      } else if (changeLogKey === 'category' || changeLogKey === 'subCategory' || changeLogKey === 'description' || changeLogKey === 'modelApplicability') {
        if (changeLogKey === 'description') {
          map[item.id][changeLogKey].before = processRteForChangeLog(featureItem.changes.before);
          map[item.id][changeLogKey].after = processRteForChangeLog(featureItem.changes.after);
        } else if (changeLogKey === 'modelApplicability') {
          const beforeModelApp: KeyValueType<FeatureSettings> = {};
          const afterModelApp: KeyValueType<FeatureSettings> = {};
          vehicleModels.forEach(mdl => {
            const id = mdl.id;
            if (id) {
              const before = change.before ? (change.before as KeyValueType<string>) : {};
              const beforeVal = before && before[id] ? (before[id] as FeatureSettings.UNDEFINED) : FeatureSettings.UNDEFINED;
              beforeModelApp[id] = beforeVal;

              const after = change.after ? (change.after as KeyValueType<string>) : {};
              const afterVal = after && after[id] ? (after[id] as FeatureSettings.UNDEFINED) : FeatureSettings.UNDEFINED;
              afterModelApp[id] = afterVal;
            }
          });
          map[item.id][changeLogKey].before = beforeModelApp;
          map[item.id][changeLogKey].after = afterModelApp;
        } else {
          map[item.id][changeLogKey].before = featureItem.changes.before;
          map[item.id][changeLogKey].after = featureItem.changes.after;
        }
        map[item.id][changeLogKey].hasChanged = true;
      }
    });
    map[item.id].isApplied = isApplied;
    map[item.id].rejectNotes = rejectNotes;
  });

  return { map, items: featureItems };
};

const getKeyFromChangeLogType = (type: ChangeLogTypes): FeatureReviewType | undefined => {
  switch (type) {
    case ChangeLogTypes.CATEGORY:
      return 'category';
    case ChangeLogTypes.SUB_CATEGORY:
      return 'subCategory';
    case ChangeLogTypes.DESCRIPTION:
      return 'description';
    case ChangeLogTypes.MODEL_APPLICABILITY:
      return 'modelApplicability';
    case ChangeLogTypes.FEATURE_ADDED:
      return 'added';
    case ChangeLogTypes.FEATURE_DELETED:
      return 'deleted';
  }
  return undefined;
};
