import { observer } from "mobx-react-lite";
import React from "react";
import { toast } from "react-toastify";
import { ActionBar } from "vapi-ui-common";
import { TableTabs } from "../../../../components/Table/components/TableTabs";
import { uiBlock } from "../../../../components/UiBlocker/uiBlock";
import useStores from "../../../../hooks/useStores";
import { InteriorColorsChangeTypeMap } from "../../../../models/colors.model";
import ActionBarFiltersSection from "../../../../routes/vehicleData/components/ActionBarFiltersSection";
import { ProductDataControllerProps } from "../../../../routes/vehicleData/models/controllers.model";
import ColorsFilters from "../../../../routes/vehicleData/tabModules/colors/components/ColorsFilters";
import { handleErrorResponse } from "../../../../utils/errorHandlingUtils";
/* import { updateReviewInteriorColors } from '../../../../webservices/vehicleColorsApi'; */
import ColorsInteriorReviewTable from "./ColorsInteriorReviewTable";

const ColorsInteriorReview = ({
  seriesId,
  year,
  version = "",
}: ProductDataControllerProps) => {
  const {
    colorsReviewStore,
    colorsInteriorReviewStore,
    teamStore,
    /* userStore: { brand }, */
  } = useStores();

  const handleOnMapApplyChange = async (
    map: InteriorColorsChangeTypeMap,
    value: boolean
  ) => {
    map.isApplied = value;
    saveMap(map);
  };

  const handleOnMapNotesChange = async (
    map: InteriorColorsChangeTypeMap,
    value: string
  ) => {
    map.rejectNotes = value;
    saveMap(map);
  };

  const saveMap = async (map: InteriorColorsChangeTypeMap) => {
    try {
      if (colorsInteriorReviewStore.isMapValid(map, teamStore.team.param)) {
        uiBlock.start();
        /* const response = await updateReviewInteriorColors(
          brand,
          teamStore.team.param,
          seriesId,
          year,
          version,
          colorsInteriorReviewStore.getMapPayload(map, teamStore.team.param)
        );
        map.revId = response.data.revId; */
        toast.success("Successfully updated feature");
      } else {
        toast.error("Please fill in the required fields for the feature");
      }
    } catch (e) {
      handleErrorResponse(e, "Error updating feature");
    }

    uiBlock.stop();
  };

  return (
    <>
      <ActionBar>
        <ActionBarFiltersSection
          readOnly
          searchText={colorsInteriorReviewStore.searchText}
          onSearchTextChange={(text) =>
            (colorsInteriorReviewStore.searchText = text)
          }
          buttons={[]}
          renderFilter={(onClose) => (
            <ColorsFilters
              onClose={onClose}
              isExtraCostColorFilter={
                colorsInteriorReviewStore.isExtraCostColorFilter
              }
              setIsExtraCostColorFilter={(value) =>
                (colorsInteriorReviewStore.isExtraCostColorFilter = value)
              }
            />
          )}
        />
      </ActionBar>
      <TableTabs
        style={{ zIndex: 1 }}
        tabs={colorsReviewStore.tabs}
        currentTab={colorsReviewStore.selectedTab}
        onSelectTab={(value) => (colorsReviewStore.selectedTab = value)}
      />
      <ColorsInteriorReviewTable
        handleOnApplyChange={handleOnMapApplyChange}
        handleOnNotesChange={handleOnMapNotesChange}
      />
    </>
  );
};

export default observer(ColorsInteriorReview);
