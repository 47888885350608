import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { Button, Dropdown, DropdownList, ModalBody, ModalFooter, ModalHeader } from 'vapi-ui-common';
import Input from '../../../../../../components/Input';
import { DashboardDetailYear } from '../../../../../../models/vehicleData.model';
import styles from './addModelYearModal.module.scss';

interface AddModelYearModalProps {
  close: () => void;
  modelYearItem: DashboardDetailYear[];
  seriesName?: string;
  seriesId: string;
  onDuplicateItem: (seriesId: string, fromModelYear: number, toModelYear: number) => void;
}

const AddModelYearModal = observer(({ close, modelYearItem, seriesName, seriesId, onDuplicateItem }: AddModelYearModalProps) => {
  const modelYears: string[] = [];
  modelYearItem.forEach(item => {
    if (!item.isDraft) {
      modelYears.push(item.year.toString());
    }
  });
  const [currentModelYear, setCurrentModelYear] = useState('');
  const [duplicateFromModelYear, setDuplicateFromModelYear] = useState(modelYears[0] || '');

  return (
    <>
      <ModalHeader onClose={close}>Add Model to {seriesName}</ModalHeader>
      <ModalBody>
        <div className={styles.dropdownWrapper}>
          <label htmlFor="duplicateModelYear" className={styles.modelYearLabel}>
            Duplicate Model Year
          </label>
          <Dropdown className={styles.modelYearDropdown}>
            <DropdownList list={modelYears} value={duplicateFromModelYear} onSelect={(item: string) => setDuplicateFromModelYear(item)} />
          </Dropdown>
        </div>

        <div>
          <label htmlFor="YearTitle" className={styles.modelYearLabel}>
            Year Title
          </label>
          <Input className={styles.yearTitle} onChange={e => setCurrentModelYear(e.currentTarget.value.trim())} />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button variant="transparent" onClick={() => close()}>
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            if (Number(currentModelYear)) {
              close();
              onDuplicateItem(seriesId, Number(duplicateFromModelYear), Number(currentModelYear));
            }
          }}
        >
          Save
        </Button>
      </ModalFooter>
    </>
  );
});

export default AddModelYearModal;
