import React from 'react';
import { HeaderCell, HeaderRow, Thead } from '../../components/Table';

interface ChangeLogHeaderRowProps {
  onSort: (field: string) => void;
}

const ChangeLogHeaderRow = ({ onSort }: ChangeLogHeaderRowProps) => {
  return (
    <Thead>
      <HeaderRow>
        <HeaderCell>Description</HeaderCell>
        <HeaderCell onClick={() => onSort('changeType')}>Change Type</HeaderCell>
        <HeaderCell>Changes</HeaderCell>
        <HeaderCell onClick={() => onSort('modifiedBy')}>Modified By</HeaderCell>
        <HeaderCell onClick={() => onSort('modifiedDate')}>Modified Date</HeaderCell>
        <HeaderCell>Notes</HeaderCell>
        <HeaderCell />
      </HeaderRow>
    </Thead>
  );
};

export default ChangeLogHeaderRow;
