import cx from 'clsx';
import { observer } from 'mobx-react-lite';
import React from 'react';
import inputStyles from '../../../../../../components/Input/input.module.scss';
import { HeaderRow, LeftTableTH, Thead } from '../../../../../../components/Table';
import useStores from '../../../../../../hooks/useStores';
import styles from './optionsHeaderRow.module.scss';

type OptionsHeaderRowProps = {
  viewModelCodes: boolean;
  allowLinks: boolean;
  readOnly?: boolean;
  onSort?: (field: string) => void;
  sortMode: boolean;
};
const OptionsHeaderRow = ({ allowLinks, onSort = () => {}, sortMode, readOnly }: OptionsHeaderRowProps) => {
  const {
    optionsStore,
    tableSizeStore: { tableRowHeight },
  } = useStores();

  const selectedLangs = optionsStore.allLangs.filter(lang => optionsStore.selectedLangsMap[lang]);

  return (
    <Thead>
      <HeaderRow
        style={{
          height: `${tableRowHeight}px`,
        }}
      >
        <LeftTableTH className={cx(styles.leftTableTH)}>&nbsp;</LeftTableTH>

        {sortMode && <LeftTableTH className={cx(styles.leftTableTH, inputStyles.smallInputHeader)}>#</LeftTableTH>}

        {!readOnly && <LeftTableTH></LeftTableTH>}

        <LeftTableTH className={cx(styles.leftTableTH)} onClick={() => onSort('category.value')}>
          Category
        </LeftTableTH>

        {selectedLangs.map(lang => (
          <LeftTableTH key={`HeaderRow-Name-${lang}`} className={cx(styles.leftTableTH)} onClick={() => onSort('name')}>
            {`${lang} Name`}
          </LeftTableTH>
        ))}

        {allowLinks && <LeftTableTH className={cx(styles.leftTableTH)}></LeftTableTH>}

        {selectedLangs.map((lang, index) => (
          <LeftTableTH
            key={`HeaderRow-Description-${lang}`}
            className={cx(styles.leftTableTH)}
            colSpan={index === selectedLangs.length - 1 ? 2 : 1}
            onClick={() => onSort('description')}
          >
            {`${lang} Description`}
          </LeftTableTH>
        ))}

        <LeftTableTH className={cx(styles.leftTableTH)} onClick={() => onSort('code')}>
          Code
        </LeftTableTH>
        <LeftTableTH className={cx(styles.leftTableTH)} onClick={() => onSort('isExtraCost')}>
          MSRP
        </LeftTableTH>
        <LeftTableTH className={cx(styles.leftTableTH)}>&nbsp;</LeftTableTH>
      </HeaderRow>
    </Thead>
  );
};

export default observer(OptionsHeaderRow);
