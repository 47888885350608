import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ActionBar } from "vapi-ui-common";
import { ChangeLogChanges } from "../../../../components/ChangeLogTable";
import ChangeLogList from "../../../../components/ChangeLogTable/ChangeLogList";
import Spinner from "../../../../components/Spinner";
import {
  HeaderCell,
  HeaderRow,
  ModelCodeHeaderRow,
  TableCell,
  TableRow,
  Thead,
  TwoTableWrapper,
} from "../../../../components/Table";
import { uiBlock } from "../../../../components/UiBlocker/uiBlock";
import useStores from "../../../../hooks/useStores";
import { FeaturesReviewItem } from "../../../../models/features.model";
import ActionBarFiltersSection from "../../../../routes/vehicleData/components/ActionBarFiltersSection";
import LeftTable from "../../../../routes/vehicleData/components/LeftTable";
import ModelTable from "../../../../routes/vehicleData/components/ModelTable";
import SettingsCell from "../../../../routes/vehicleData/components/ModelTable/components/SettingsCell";
import {
  AcceptApplyCell,
  AcceptApplyHeaderCell,
} from "../../../../routes/vehicleData/components/tableCells/AcceptApplyCell";
import RichTextCell from "../../../../routes/vehicleData/components/tableCells/RichTextCell";
import { ProductDataControllerProps } from "../../../../routes/vehicleData/models/controllers.model";
import FeatureFilters from "../../../../routes/vehicleData/tabModules/features/components/FeatureFilters";
import { handleErrorResponse } from "../../../../utils/errorHandlingUtils";
import { featuresReviewXForm } from "../../../../utils/featuresUtils";
/* import { updateRevIdById } from "../../../../utils/reviewUtils"; */
import { getReviewCLFeatures } from "../../../../webservices/vehicleFeaturesApi";

const FeaturesCLReview = ({
  seriesId,
  year,
  version = "",
  vehicleModels,
}: ProductDataControllerProps) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const {
    featuresReviewCLStore,
    teamStore,
    userStore: { brand },
    tableSizeStore: { tableRowHeight },
  } = useStores();

  useEffect(() => {
    (async () => {
      try {
        const response = await getReviewCLFeatures(
          brand,
          teamStore.team.param,
          seriesId,
          year,
          version
        );
        const { items } = featuresReviewXForm(
          response.data,
          vehicleModels,
          [],
          []
        );
        featuresReviewCLStore.reviewItems = items;
      } catch (err) {
        console.log(err);
        toast.error("Error loading Features common language review data");
      }
      setIsLoaded(true);
    })();
  }, [
    featuresReviewCLStore,
    brand,
    seriesId,
    teamStore,
    version,
    year,
    vehicleModels,
  ]);

  const handleOnApplyChange = async (
    item: FeaturesReviewItem,
    value: boolean
  ) => {
    item.isApplied = value;
    saveItem(item);
  };

  const saveItem = async (reviewItem: FeaturesReviewItem) => {
    try {
      if (reviewItem.isValid()) {
        uiBlock.start();
        /* const response = await updateReviewCLFeature(
          brand,
          teamStore.team.param,
          seriesId,
          year,
          version,
          reviewItem.getPayload()
        );
        const { id, revId } = response.data;
        updateRevIdById(id, revId, featuresReviewCLStore.reviewItems); */
        toast.success("Successfully updated feature");
      } else {
        toast.error("Please fill in the required fields for the feature");
      }
    } catch (e) {
      handleErrorResponse(e, "Error updating feature");
    }

    uiBlock.stop();
  };

  return !isLoaded ? (
    <Spinner />
  ) : (
    <>
      <ActionBar>
        <ActionBarFiltersSection
          readOnly
          searchText={featuresReviewCLStore.searchText}
          onSearchTextChange={(text) =>
            (featuresReviewCLStore.searchText = text)
          }
          toggleViewModelCodes={() =>
            (featuresReviewCLStore.viewModelCodes =
              !featuresReviewCLStore.viewModelCodes)
          }
          viewModelCodes={featuresReviewCLStore.viewModelCodes}
          buttons={[]}
          renderFilter={(onClose) => (
            <FeatureFilters
              onClose={onClose}
              categories={featuresReviewCLStore.categories.map(
                (item) => item.value
              )}
              categoryFilters={featuresReviewCLStore.categoryFilters}
              setCategoryFilters={(categoryFilters) =>
                (featuresReviewCLStore.categoryFilters = categoryFilters)
              }
              isInProgressFilter={featuresReviewCLStore.isInProgressFilter}
              isHighlightedFilter={featuresReviewCLStore.isHighlightedFilter}
              setIsInProgressFilter={(value) =>
                (featuresReviewCLStore.isInProgressFilter = value)
              }
              setIsHighlightedFilter={(value) =>
                (featuresReviewCLStore.isHighlightedFilter = value)
              }
            />
          )}
        />
      </ActionBar>
      <TwoTableWrapper>
        <LeftTable>
          <Thead>
            <HeaderRow style={{ height: `${tableRowHeight}px` }}>
              <HeaderCell
                onClick={() => featuresReviewCLStore.setSort("category.value")}
              >
                Category
              </HeaderCell>
              <HeaderCell
                onClick={() => featuresReviewCLStore.setSort("description")}
              >
                Description
              </HeaderCell>
              <HeaderCell colType="changes" border>
                Changes
              </HeaderCell>
              <AcceptApplyHeaderCell hideAccept />
            </HeaderRow>
            {featuresReviewCLStore.viewModelCodes && (
              <ModelCodeHeaderRow>
                <th></th>
              </ModelCodeHeaderRow>
            )}
          </Thead>
          <tbody>
            {featuresReviewCLStore.filteredReviewItems.map((reviewItem) => (
              <TableRow
                key={reviewItem.uid}
                onFillRowHeightChange={(height) => {
                  reviewItem.rowHeight = height;
                }}
              >
                <TableCell colType="dropdown">
                  {reviewItem.category.value}
                </TableCell>
                <RichTextCell
                  colType="description"
                  disabled
                  value={reviewItem.description}
                />
                <TableCell colType="changes" border>
                  <ChangeLogList>
                    <li>
                      <ChangeLogChanges changeItem={reviewItem.changes} long />
                      {reviewItem.otherChanges.map((otherChange) => (
                        <React.Fragment key={otherChange.uid}>
                          <p />
                          <div>{otherChange.changeTypeId}</div>
                          <ChangeLogChanges
                            changeItem={otherChange.changes}
                            long
                          />
                        </React.Fragment>
                      ))}
                    </li>
                  </ChangeLogList>
                </TableCell>
                <AcceptApplyCell
                  id={reviewItem.uid}
                  applyChecked={reviewItem.isApplied}
                  acceptChecked={reviewItem.isAccepted}
                  onApplyChecked={(value) =>
                    handleOnApplyChange(reviewItem, value)
                  }
                  notes={reviewItem.rejectNotes}
                  onNotesChange={() => {}}
                  isNewChange={reviewItem.isNewChange}
                />
              </TableRow>
            ))}
          </tbody>
        </LeftTable>
        <ModelTable
          showFeatureSplits={teamStore.team.allowSplits}
          viewModelCodes={featuresReviewCLStore.viewModelCodes}
          models={vehicleModels}
          renderRows={() => (
            <>
              {featuresReviewCLStore.filteredReviewItems.map(
                (reviewItem, idx) => (
                  <React.Fragment key={reviewItem.uid}>
                    <TableRow rowHeight={reviewItem.rowHeight}>
                      {vehicleModels.map(
                        (model, index) =>
                          model.show &&
                          reviewItem.models[index] && (
                            <SettingsCell
                              disabled
                              key={reviewItem.models[index].id}
                              model={reviewItem.models[index]}
                              oddRow={idx % 2 === 1}
                              rowSpan={1}
                              onUpdate={() => {}}
                            />
                          )
                      )}
                    </TableRow>
                  </React.Fragment>
                )
              )}
            </>
          )}
        />
      </TwoTableWrapper>
    </>
  );
};

export default observer(FeaturesCLReview);
