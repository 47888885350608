import { observable, makeObservable } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import { ChangeLogTypes } from './changeLog.model';
import { KeyValueType } from './common.model';
import { ReviewChangeMap, ReviewChangeResponse, ReviewChangeTypeMap } from './review.model';

export type BnPItemsResponse = {
  cabs: BnPItemsResponseItem;
  transmission: BnPItemsResponseItem;
  engine: BnPItemsResponseItem;
  grade: BnPItemsResponseItem;
  beds: BnPItemsResponseItem;
  drive: BnPItemsResponseItem;
  seats: BnPItemsResponseItem;
};

export type BnPItemsResponseItem = {
  notes: string;
  sortOrder: number;
  label: string;
  isInProgress: boolean;
  categories: { [categoryId: string]: BnpCategory };
};

export type BnpCategory = null | {
  title?: string;
  copy?: string;
  description?: string;
  changedAttributes?: string[];
  key?: string;
  applicability?: { [modelId: string]: string };
  splits?: KeyValueType<BnpCategorySplit>;
  categoryValue?: string;
  rejectNotes?: string;
};

export type BnpCategorySplit = {
  isDeleted?: boolean;
  description?: string;
  splitNumber: number;
  id: string;
};

export type BnPNameRequest = {
  name: string;
  notes: string;
  isInProgress: boolean;
};

export type BnPDescriptionRequest = {
  name: string;
  category: string;
  description: string;
  splitId?: string;
};

export type BnPGradeDetailRequest = {
  name: string;
  category: string;
  title: string;
  copy: string;
};

export type BnPApplicabilityRequest = {
  name: string;
  category: string;
  applicability: KeyValueType<string>;
};

export type BnPAddSplitRequest = {
  name: string;
  category: string;
  applicability: string[];
};

export type BnPDeleteSplitRequest = {
  name: string;
  category: string;
  splitId: string;
};

export class BnPCategories {
  uid = uuidv4();
  categoryItemMap: BnPCategoryItemMap = {};
  items: BnPCategoryItem[] = [];

  name = '';
  label = '';
  sortOrder = -1;
  isInProgress = false;
  notes = '';
  langMaps: BnPCategoryItemLangMap[] = [];

  constructor(name: string, response: BnPItemsResponseItem, items: BnPCategoryItem[]) {
    makeObservable(this, {
      name: observable,
      label: observable,
      sortOrder: observable,
      isInProgress: observable,
      notes: observable,
      langMaps: observable
    });

    this.name = name;
    this.label = response.label;
    this.items = items;
    this.notes = response.notes || '';
    this.sortOrder = response.sortOrder;
    this.isInProgress = response.isInProgress;
  }
}

export interface BnPCategoriesMap {
  [category: string]: BnPCategories;
}

export class BnPCategoryItem {
  name = '';
  label = '';
  categoryId = '';
  categoryValue = '';
  description = '';
  title = '';
  copy = '';
  changedAttributes: string[] = [];
  applicability: KeyValueType<string> = {};
  splits: BnpCategorySplit[] = [];
  acceptChanges: boolean = false;
  splitsMap: KeyValueType<BnpCategorySplit> = {};
  rejectNotes: string = '';

  constructor(
    name: string,
    label: string,
    categoryId: string,
    categoryValue: string,
    description?: string,
    title?: string,
    copy?: string,
    changedAttributes?: string[],
    applicability?: KeyValueType<string>,
    splitsMap?: KeyValueType<BnpCategorySplit>,
    rejectNotes?: string
  ) {
    makeObservable(this, {
      name: observable,
      label: observable,
      categoryId: observable,
      categoryValue: observable,
      description: observable,
      title: observable,
      copy: observable,
      changedAttributes: observable,
      applicability: observable,
      splits: observable,
      acceptChanges: observable,
      splitsMap: observable,
      rejectNotes: observable
    });

    this.name = name;
    this.label = label;
    this.categoryId = categoryId;
    this.categoryValue = categoryValue;
    this.description = description || '';
    this.title = title || '';
    this.copy = copy || '';
    this.changedAttributes = changedAttributes || [];
    this.applicability = applicability || {};
    this.setSplits(splitsMap || {});
    this.rejectNotes = rejectNotes || '';
  }

  setSplits = (splitsMap: KeyValueType<BnpCategorySplit>) => {
    this.splitsMap = splitsMap;
    this.splits = Object.values(splitsMap).sort((a, b) => a.splitNumber - b.splitNumber);
  };

  getDescriptionPayload = (split?: BnpCategorySplit): BnPDescriptionRequest => {
    return {
      name: this.name,
      category: this.categoryId,
      description: split ? split.description ?? '' : this.description,
      splitId: split?.id,
    };
  };

  getGradeDetailPayload = (): BnPGradeDetailRequest => {
    return {
      name: this.name,
      category: this.categoryId,
      title: this.title,
      copy: this.copy,
    };
  };

  getApplicabilityPayload = (): BnPApplicabilityRequest => {
    return {
      name: this.name,
      category: this.categoryId,
      applicability: this.applicability,
    };
  };
}

export interface BnPCategoryItemMap {
  [categoryId: string]: BnPCategoryItemLangMap;
}

export interface BnPCategoryItemLangMap {
  [lang: string]: BnPCategoryItem;
}

export interface BnPReviewRequest {
  id: string;
  revId: string;
  changeType: ChangeLogTypes;
  isAccepted: boolean;
  isApplied: boolean;
  rejectNotes: string;
}

export interface BnpCategoryReviewResponse {
  itemKey: string;
  categoryKey: string;
  categoryName: string;
  title?: string;
  copy?: string;
  description?: string;
  applicability?: { [modelId: string]: string };
  splits?: KeyValueType<BnpCategorySplit>;
  changes: KeyValueType<ReviewChangeResponse>;
  revId: string;
  splitChanges: KeyValueType<KeyValueType<ReviewChangeResponse>>; // map of {[splitId: string]: {[changeType: string]: ReviewChangeResponse}}
}

export interface BnPItemsReviewResponse {
  label: string;
  sortOrder?: number;
  notes?: string;
  isInProgress: boolean;
  categories: { [categoryKey: string]: BnpCategoryReviewResponse };
}

export interface BnPReviewItem {
  itemKey: string;
  sortOrder: number;
  categories: KeyValueType<BnPChangeTypeMap>;
}

export interface BnPReviewMap {
  [itemKey: string]: KeyValueType<BnPChangeTypeMap>;
}

export type BnpCategorySplitReview = {
  description: ReviewChangeMap<string>;
  splitNumber: number;
  isNew: boolean;
  isDeleted: boolean;
  id: string;
};

export interface BnPChangeTypeMap extends ReviewChangeTypeMap {
  itemKey: string;
  categoryKey: string;
  categoryName: string;
  itemLabel: string; // get from BnPItemsReviewResponse.label
  title: ReviewChangeMap<string>; // only applicable to grade bnp
  copy: ReviewChangeMap<string>; // only applicable to grade bnp
  description: ReviewChangeMap<string>; // applicable to all except grade bnp
  applicability: ReviewChangeMap<KeyValueType<string>>; // applicable to all except grade bnp, map of modelId to splitId
  // this data structure needs to be finished to accomodate the split changes
  splits: KeyValueType<BnpCategorySplitReview>;
  hasApplicabilityChange: boolean; // if applicability has changed then this flag will tell us whether we want to show the default category row (i.e. if this is true then the default category's applicability has changed)
}

export type BnPReviewType =
  | 'title'
  | 'copy'
  | 'description'
  | 'applicability'
  | 'added'
  | 'splitAdd' // before => undefined; after => id of split that was added; bnpSplitId => split.id
  | 'splitDescription'; // before => prev description; after => current description; bnpSplitId => split.id
