import { action, observable, makeObservable } from 'mobx';
import { ColorGradeItem } from '../../../models/colors.model';

class ColorsReviewStore {
  tabs = ['Exterior', 'Interior'];
  selectedTab = 'Exterior';
  colorGradeItems: ColorGradeItem[] = [];

  constructor() {
    makeObservable(this, {
      tabs: observable,
      selectedTab: observable,
      colorGradeItems: observable,
      reset: action
    });
  }

  reset() {
    this.tabs = ['Exterior', 'Interior'];
    this.selectedTab = 'Exterior';
    this.colorGradeItems = [];
  }
}

export default ColorsReviewStore;
