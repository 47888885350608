import apolloClient from '../apolloClient';
import {
  GetFuelTypesDocument,
  GetFuelTypesQuery,
  GetFuelTypesQueryVariables,
  GetSeriesCategoriesDocument,
  GetSeriesCategoriesQuery,
  GetSeriesCategoriesQueryVariables,
  GetSeriesDocument,
  GetSeriesQuery,
  GetSeriesQueryVariables,
  GetUserSeriesDocument,
  GetUserSeriesQuery,
  GetUserSeriesQueryVariables,
  UpdateUserSeriesDocument,
  UpdateUserSeriesMutation,
  UpdateUserSeriesMutationVariables,
} from '../gql/generated';
import { SeriesCategoryRequest } from '../models/seriesCategories.model';
import { Series, SeriesInfo, UserSeries } from '../models/vehicleData.model';
import { seriesItemToSeriesInfo } from '../utils/vehicleDataUtils';
import API from '../webservices/api';

const RESOURCE_PATH = '/admin';

export const getPermissions = (data: { token: string; objectId: string }) => {
  return API.post(`/permissions`, data);
};

// Series Categories
//

const SERIES_CATEGORIES_PATH = '/seriesCategories'; // SeriesCategoryResponse
export const getSeriesCategories = (brand: string, getLatestVersion: boolean = false) =>
  apolloClient
    .query<GetSeriesCategoriesQuery, GetSeriesCategoriesQueryVariables>({
      query: GetSeriesCategoriesDocument,
      fetchPolicy: 'no-cache',
      variables: {
        brand,
        getLatestVersion,
      },
    })
    .then(res => ({ ...res, data: res.data.seriesCategories?.seriesCategories ?? [] }));

export const addSeriesCategory = (brand: string, payload: SeriesCategoryRequest) => {
  return API.post<SeriesCategoryRequest>(`${RESOURCE_PATH}${SERIES_CATEGORIES_PATH}/${brand}`, payload);
};

export const updateSeriesCategory = (brand: string, payload: SeriesCategoryRequest) => {
  return API.put<SeriesCategoryRequest>(`${RESOURCE_PATH}${SERIES_CATEGORIES_PATH}/${brand}`, payload);
};

export const deleteSeriesCategory = (brand: string, id: string) => {
  return API.delete(`${RESOURCE_PATH}${SERIES_CATEGORIES_PATH}/${brand}/${id}`);
};

export const publishSeriesCategory = (brand: string) => {
  return API.post(`${RESOURCE_PATH}${SERIES_CATEGORIES_PATH}/${brand}/publish`);
};

const SERIES_PATH = '/series';

export const getSeries = (brand: string) =>
  apolloClient
    .query<GetSeriesQuery, GetSeriesQueryVariables>({
      query: GetSeriesDocument,
      fetchPolicy: 'no-cache',
      variables: {
        brand,
      },
    })
    .then(res => {
      const { data } = res;
      const series: Series =
        data.series?.reduce(
          (list, serie) => ({
            ...list,
            [serie.id]: seriesItemToSeriesInfo(serie),
          }),
          {},
        ) ?? {};
      return { ...res, data: { ...data, series } };
    });

export const addSeries = (brand: string, payload: { name: string; group: string }) => {
  return API.post(`${RESOURCE_PATH}${SERIES_PATH}/${brand}`, payload);
};

export const updateSeries = (brand: string, payload: SeriesInfo) => {
  return API.put(`${RESOURCE_PATH}${SERIES_PATH}/${brand}`, payload);
};

// Series Selections
//
export const getUserSeries = (brand: string, objectId: string) =>
  apolloClient
    .query<GetUserSeriesQuery, GetUserSeriesQueryVariables>({
      query: GetUserSeriesDocument,
      fetchPolicy: 'no-cache',
      variables: {
        brand,
        objectId,
      },
    })
    .then(res => {
      const { data } = res;
      const seriesSelections: UserSeries[] = data.seriesSelections?.map(serie => seriesItemToSeriesInfo(serie)) ?? [];
      return { ...res, data: { ...data, seriesSelections } };
    });

export const updateUserSeries = (brand: string, objectId: string, payload: string[]) =>
  apolloClient.mutate<UpdateUserSeriesMutation, UpdateUserSeriesMutationVariables>({
    mutation: UpdateUserSeriesDocument,
    fetchPolicy: 'no-cache',
    variables: {
      brand,
      objectId,
      seriesSelections: payload,
    },
  });

// Fuel Types
//
export const getFuelTypes = (brand: string) =>
  apolloClient
    .query<GetFuelTypesQuery, GetFuelTypesQueryVariables>({
      query: GetFuelTypesDocument,
      fetchPolicy: 'no-cache',
      variables: {
        brand,
      },
    })
    .then(res => {
      const { data } = res;
      const fuelTypes = data.fuelTypes?.fuelTypes ?? [];

      return {
        ...res,
        data: { ...data, fuelTypes },
      };
    });

export const getPreSignedUrl = (brand: string, seriesId: string, modelYear: number, key: string) => {
  return API.post(`${RESOURCE_PATH}/s3Objects/${brand}/signed-url/upload`, {
    seriesId,
    modelYear,
    key,
  });
};
