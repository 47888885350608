import cx from 'clsx';
import React from 'react';
import styles from './spinner.module.scss';

type SpinnerProps = {
  short?: boolean;
};

const Spinner = ({ short }: SpinnerProps) => {
  return (
    <section data-testid="spinner" className={cx(styles.spinner, short && styles.short)}>
      <div className={styles.ldsRing}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </section>
  );
};

export default Spinner;
