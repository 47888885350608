import React, { useEffect, useState } from 'react';
import { Dropdown, DropdownButton, DropdownContent } from 'vapi-ui-common';
import Checkbox from '../../../../../../../../../components/Checkbox';
import useComponentVisible from '../../../../../../../../../hooks/useComponentVisible';
import styles from '../../../../../seriesSettings.module.scss';

interface FuelTypeSelectionComponentProps {
  catChecklist: any[];
  selectItem: any;
}

const SeriesTypeSelectionComponent = ({
  catChecklist,
  selectItem,
}: FuelTypeSelectionComponentProps) => {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const [catLabel, setCatLabel] = useState('');

  // Responsive label name based off categories selected
  useEffect(
    () =>
      setCatLabel(
        catChecklist
          .filter(item => item.selected === true)
          .map(item => item.name)
          .join(', ')
      ),
    [selectItem, catChecklist]
  );

  return (
    <Dropdown className={styles.dropdown}>
      <DropdownButton className={styles.dropdownButton} onClick={() => setIsComponentVisible(true)}>
        {catLabel}
      </DropdownButton>
      <DropdownContent open={isComponentVisible} ref={ref}>
        {catChecklist.map(item => (
          <li key={item.name}>
            <Checkbox
              id={`chboxCat${item.name}`}
              checked={item.selected}
              onChange={e => selectItem(item, e.currentTarget.checked)}
            >
              {item.name}
            </Checkbox>
          </li>
        ))}
      </DropdownContent>
    </Dropdown>
  );
};

export default SeriesTypeSelectionComponent;
