/* eslint-disable react-hooks/exhaustive-deps */
import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef, useState } from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { trackPromise } from 'react-promise-tracker';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import { ActionBarDivider, Modal } from 'vapi-ui-common';
import IconTextButton from '../../../../components/IconTextButton';
import Spinner from '../../../../components/Spinner';
import { TableRow, TwoTableWrapper } from '../../../../components/Table';
import SortButton from '../../../../components/sortModule/SortButton';
import SortDropdown from '../../../../components/sortModule/SortDropdown';
import SortModal from '../../../../components/sortModule/SortModal';
import { langNameMap } from '../../../../constants/vehicleData/VDConstants';
import useDebounce from '../../../../hooks/useDebounce';
import useStores from '../../../../hooks/useStores';
import { RefItemLangMap, RefItemsMap } from '../../../../models/category.model';
import { AppliedChangesResponse, ChangeLogTypes } from '../../../../models/changeLog.model';
import { IDValueType, KeyValueType } from '../../../../models/common.model';
import { ApplicabilityTextLangMap, CompareType } from '../../../../models/compareFeatures.model';
import { RefItem } from '../../../../models/refItem.model';
import { VDSortableEntity } from '../../../../models/sort.model';
import { SpecItem, SpecSettings, SpecsLangMap } from '../../../../models/specs.model';
import { Language } from '../../../../models/user.model';
import { VehicleTeam } from '../../../../models/vehicleData.model';
import ActionBarVehicleData from '../../../../routes/vehicleData/components/ActionBarVehicleData';
import LeftTable from '../../../../routes/vehicleData/components/LeftTable';
import ModelTable from '../../../../routes/vehicleData/components/ModelTable';
import SettingsCell from '../../../../routes/vehicleData/components/ModelTable/components/SettingsCell';
import { ProductDataControllerProps } from '../../../../routes/vehicleData/models/controllers.model';
import { compareFeatureItemXForm } from '../../../../utils/compareFeaturesUtils';
import { tokensXForm } from '../../../../utils/disclaimersUtils';
import { handleErrorResponse } from '../../../../utils/errorHandlingUtils';
import getLangActionBarButtons from '../../../../utils/getLangActionBarButtons';
import { getSortPayload, getSortedCopy } from '../../../../utils/sortUtils';
import { syncSpanishUpdates, updateSortList } from '../../../../webservices/vehicleAdminApi';
import { addCompareFeatureFromParent, deleteCompareFeature, updateCompareFeature } from '../../../../webservices/vehicleCompareFeaturesApi';
import { addCategories, addSpecTypes, addVehicleSpec, deleteVehicleSpec, updateCategory, updateSpecType, updateVehicleSpec } from '../../../../webservices/vehicleSpecsApi';
import SyncTMNAChangesModal from '../../components/SyncTMNAChangesModal/SyncTMNAChangesModal';
import SpecsFilters from './components/SpecsFilters';
import SpecRowsContainer from './components/SpecsFormRow/SpecRowsContainer';
import SpecsHeaderRow from './components/SpecsHeaderRow';

const SpecsController = ({ readOnly, team, seriesId, year, version, versionInfo, vehicleModels, grades, isPublished, reloadDraft, sourceVersion }: ProductDataControllerProps) => {
  const {
    commonLanguageStore,
    specsStore,
    teamStore,
    userStore: { brand },
    disclaimersStore: { tokens },
  } = useStores();

  const { debounce } = useDebounce({ delay: 2000 });
  const updatingCompareFeatureLink = useRef(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [sortMode, setSortMode] = useState(false);
  const [sortCategoryModal, setSortCategoryModal] = useState(false);
  const [sortSpecTypeModal, setSortSpecTypeModal] = useState(false);
  const [showSyncChangesModal, setShowSyncChangesModal] = useState(false);
  const [syncChangesSpec, setSyncChangesSpec] = useState<SpecsLangMap | undefined>(undefined);

  const disclaimerTokens = tokensXForm(tokens);

  useEffect(() => {
    setIsLoaded(false);
    specsStore.reset();

    (async () => {
      try {
        await specsStore.fetchData(brand, team, seriesId, year, vehicleModels, grades || [], teamStore.team.langPermissions, versionInfo);
      } catch (e: any) {
        toast.error('Error loading specs data');
        console.log(e.message);
      }
      setIsLoaded(true);
    })();
  }, [brand, specsStore, seriesId, team, vehicleModels, year, versionInfo, commonLanguageStore, grades]);

  const updateSpecItem = async (spec: SpecItem, compareChangeMessageRequest: boolean, language: string, acceptChanges: boolean = false, unlinkFromTMNA: boolean = false) => {
    if (spec.isValid()) {
      try {
        debounce(async () => {
          const response = await trackPromise(updateVehicleSpec(brand, team, seriesId, year, spec.getPayload(), language, acceptChanges, unlinkFromTMNA));
          spec.revId = response.data.revId;
          if (acceptChanges || unlinkFromTMNA) {
            spec.changedAttributes = [];
            spec.changedModelIds = [];
          }
          if (unlinkFromTMNA) {
            spec.fromTMNA = false;
          }
          if (spec.compareFeatureId) {
            const compareFeature = specsStore.compareFeaturesMap.compareFeatures[spec.id].langs[language];
            if (spec.specType.id) {
              compareFeature.subCategory.id = spec.specType.id;
            }
            compareFeature.category.id = spec.category.id;
            const compareRes = await trackPromise(updateCompareFeature(brand, team, seriesId, year, language, compareFeature.getPayload()));
            compareFeature.revId = compareRes.data.revId;
          }
          toast.success(`${langNameMap[language]} Spec updated successfully`);
          if (compareChangeMessageRequest && spec.compareFeatureId && spec.id) {
            toast.success('Changes have been updated on the Compare View Page');
          }
        }, spec.uid);
      } catch (e) {
        handleErrorResponse(e, 'Spec failed update');
      }
    }
  };

  const addSpecItem = async (spec: SpecItem, language: string) => {
    if (spec.isValid()) {
      try {
        debounce(async () => {
          const response = await trackPromise(addVehicleSpec(brand, team, seriesId, year, language, spec.getPayload()));
          spec.id = response.data.id;
          spec.revId = response.data.revId;
          toast.success('Spec added successfully');
        }, spec.uid);
      } catch (e) {
        handleErrorResponse(e, 'Spec failed add');
      }
    } else {
      showFormFieldError();
    }
  };

  const saveSpecLangMap = async (
    specLangMap: SpecsLangMap,
    compareChangeMessageRequest: boolean = false,
    lang: string = '',
    acceptChanges: boolean = false,
    unlinkFromTMNA: boolean = false,
  ) => {
    if (lang && !specsStore.langWriteMap[lang as Language]?.canEdit) {
      toast.error(`You do not have permissions to update ${langNameMap[lang]} specs.`);
      return;
    }
    const langs = lang ? [lang] : specsStore.editableLangs;
    const promises: Promise<any>[] = [];
    let numValid = 0;
    for (const lang of specsStore.editableLangs) {
      // check to make sure all specs either have a revid or are valid
      const spec = specLangMap.langs[lang];
      if (!spec.revId && !spec.isValid()) {
        showFormFieldError();
        return;
      } else if (!spec.revId && !langs.includes(lang)) {
        // see features controller for reasoning
        langs.push(lang);
      }
    }
    for (const lang of langs) {
      const spec = specLangMap.langs[lang];
      if (spec.isValid()) {
        numValid++;
        if (spec.revId) {
          promises.push(updateSpecItem(spec, compareChangeMessageRequest, lang, acceptChanges, unlinkFromTMNA));
        } else {
          promises.push(addSpecItem(spec, lang));
        }
      }
    }
    if (!numValid) {
      showFormFieldError();
    } else {
      await Promise.all(promises);
    }
  };

  const deleteSpecLangMap = async (specLangMap: SpecsLangMap) => {
    try {
      const uid = specLangMap.langs[specsStore.allLangs[0]].uid;
      let specId = '';
      let hasCompareFeature = false;

      // only delete the features that the user has write permissions for
      for (const language of specsStore.editableLangs) {
        const spec = specLangMap.langs[language];
        specId = spec.id;
        if (spec.revId) {
          await trackPromise(deleteVehicleSpec(brand, team, seriesId, year, language, spec.id));
        }
        if (spec.compareFeatureId) {
          await trackPromise(deleteCompareFeature(brand, team, seriesId, year, language, spec.compareFeatureId));
          hasCompareFeature = true;
        }
      }
      specsStore.deleteItem(uid);
      if (hasCompareFeature) {
        delete specsStore.compareFeaturesMap.compareFeatures[specId];
        toast.success('Changes have been updated on the Compare View Page');
      }
      toast.success('Spec deleted sucessfully');
    } catch (e) {
      handleErrorResponse(e, 'Error deleting spec');
    }
  };

  const copySpecLangMap = async (specLangMap: SpecsLangMap) => {
    try {
      const copiedSpecLangMap = specsStore.copyMap(JSON.parse(JSON.stringify(specLangMap)), vehicleModels);
      const specId = uuidv4();
      let isValid = true;
      specsStore.allLangs.forEach(lang => {
        copiedSpecLangMap.langs[lang].id = specId;
        if (!copiedSpecLangMap.langs[lang].isValid()) {
          isValid = false;
        }
      });

      if (isValid) {
        for (const lang of specsStore.allLangs) {
          const copiedSpec = copiedSpecLangMap.langs[lang];
          if (specsStore.langWriteMap[lang]?.canEdit) {
            // if the user has write permissions for this language then we should create the feature on the backend too
            const response = await trackPromise(addVehicleSpec(brand, team, seriesId, year, lang, copiedSpec.getPayload()));
            copiedSpec.revId = response.data.revId;
          }
        }
        const sortPayload = getSortPayload(specsStore.specLangMaps.map(langMap => langMap.langs[specsStore.editableLangs[0]]));
        await trackPromise(updateSortList(brand, team, seriesId, year, VDSortableEntity.SPECS, sortPayload));
        toast.success('Spec copied successfully');
      } else {
        showFormFieldError();
      }
    } catch (e) {
      handleErrorResponse(e, 'Spec failed copy');
    }
  };

  const addEmptySpecItem = () => {
    specsStore.addItem(vehicleModels);
    showFormFieldError();
  };

  const addCategoryItem = async (payload: { [lang: string]: string }, id?: string) => {
    try {
      const response = await trackPromise(addCategories(brand, team, seriesId, year, payload, id));
      const refItemsMap: RefItemsMap = JSON.parse(JSON.stringify(specsStore.categoriesMap));
      Object.keys(response.data).forEach(lang => {
        specsStore.updateCategoriesLangMap(lang as Language, refItemsMap, response.data[lang], specsStore.categoriesSortList);
      });
      specsStore.fillOutCategoriesMap(refItemsMap);
      specsStore.categoriesMap = refItemsMap;
      toast.success('Category added successfully');
    } catch (e) {
      handleErrorResponse(e, 'Error adding category');
    }
  };

  const updateCategoryItem = async (refItemLangMap: RefItemLangMap, payload: { [lang: string]: string }) => {
    try {
      for (const lang of specsStore.editableLangs) {
        if (payload[lang]) {
          await trackPromise(updateCategory(brand, team, seriesId, year, lang, refItemLangMap[lang].id, payload[lang]));
        }
      }
      specsStore.editableLangs.forEach(lang => {
        if (payload[lang]) {
          refItemLangMap[lang].value = payload[lang];
        }
      });
      const catMap = specsStore.categoriesMap;
      for (const lang of specsStore.editableLangs) {
        if (payload[lang]) {
          catMap.categories[refItemLangMap[lang].id] = refItemLangMap;
          break;
        }
      }
      specsStore.categoriesMap = catMap;
      toast.success('Category updated successfully');
    } catch (e) {
      handleErrorResponse(e, 'Error editing category');
    }
  };

  const addSpecTypeItem = async (payload: { [lang: string]: string }, id?: string) => {
    try {
      const response = await trackPromise(addSpecTypes(brand, team, seriesId, year, payload, id));
      const specTypeMap: RefItemsMap = JSON.parse(JSON.stringify(specsStore.specTypeMap));
      Object.keys(response.data).forEach(lang => {
        specsStore.updateCategoriesLangMap(lang as Language, specTypeMap, response.data[lang], specsStore.specTypesSortList);
      });
      specsStore.fillOutCategoriesMap(specTypeMap);
      specsStore.specTypeMap = specTypeMap;
      toast.success('SpecType added successfully');
    } catch (e) {
      handleErrorResponse(e, 'Error adding specType');
    }
  };

  const updateSpecTypeItem = async (specTypeLangMap: RefItemLangMap, payload: { [lang: string]: string }) => {
    try {
      for (const lang of specsStore.editableLangs) {
        if (payload[lang] && specsStore.langWriteMap[lang]?.canEdit) {
          await trackPromise(updateSpecType(brand, team, seriesId, year, lang, specTypeLangMap[lang].id, payload[lang]));
        }
      }
      specsStore.editableLangs.forEach(lang => {
        if (payload[lang]) {
          specTypeLangMap[lang].value = payload[lang];
        }
      });
      const specTypeMap = specsStore.specTypeMap;
      for (const lang of specsStore.editableLangs) {
        if (payload[lang]) {
          specTypeMap.categories[specTypeLangMap[lang].id] = specTypeLangMap;
          break;
        }
      }
      specsStore.specTypeMap = specTypeMap;
      toast.success('SpecType updated successfully');
    } catch (e) {
      handleErrorResponse(e, 'Error editing specType');
    }
  };

  const showFormFieldError = () => {
    toast.error('Please finish filling out all items of the new spec.');
  };

  const onSaveSortCategories = async (list: IDValueType[], dataType: VDSortableEntity) => {
    try {
      const catPayload = getSortPayload(list);
      await trackPromise(updateSortList(brand, team, seriesId, year, dataType, catPayload));

      let featuresPayload;
      if (dataType === VDSortableEntity.SPECS_CATEGORIES || dataType === VDSortableEntity.SPECS_SPECTYPE) {
        const categories = dataType === VDSortableEntity.SPECS_CATEGORIES ? list : specsStore.getDefaultCategories(specsStore.categoriesMap);
        const specTypes = dataType === VDSortableEntity.SPECS_SPECTYPE ? list : specsStore.getDefaultCategories(specsStore.specTypeMap);

        const specsCopy = getSortedCopy<SpecItem>(specsStore.getDefaultSpecs(specsStore.specLangMaps), item => item.specType.id, specTypes);
        const specsCopy2 = getSortedCopy<SpecItem>(specsCopy, item => item.category.id, categories);
        featuresPayload = getSortPayload(specsCopy2);
      }

      if (featuresPayload) {
        await trackPromise(updateSortList(brand, team, seriesId, year, VDSortableEntity.SPECS, featuresPayload));
        setIsLoaded(false);
        specsStore.resetFilters();
        await trackPromise(specsStore.fetchData(brand, team, seriesId, year, vehicleModels, grades || [], teamStore.team.langPermissions, versionInfo));
      }
    } catch (e) {
      handleErrorResponse(e, 'Error updating category sort');
    }
    setIsLoaded(true);
  };

  const onStopSorting = async () => {
    setSortMode(false);
    try {
      const featureItems = specsStore.getDefaultSpecs(specsStore.filteredSpecLangMaps);
      const sortPayload = getSortPayload(featureItems);
      await trackPromise(updateSortList(brand, team, seriesId, year, VDSortableEntity.SPECS, sortPayload));
      setIsLoaded(false);
      await trackPromise(specsStore.fetchData(brand, team, seriesId, year, vehicleModels, grades || [], teamStore.team.langPermissions, versionInfo));
      setIsLoaded(true);
    } catch (e) {
      handleErrorResponse(e, 'Error updating spec sort');
    }
  };

  const handleCompareFeatureHighlighted = async (specLangMap: SpecsLangMap) => {
    if (!updatingCompareFeatureLink.current) {
      for (const lang of specsStore.editableLangs) {
        const spec = specLangMap.langs[lang];
        if (!spec.isValid() || !spec.revId) {
          toast.error('Please finish filling out all items of the new spec.');
          return;
        }
      }

      updatingCompareFeatureLink.current = true;
      const compareFeatureId = uuidv4();
      let hasFailed = false;
      const hasCompareFeature = !!specLangMap.langs[specsStore.defaultLang].compareFeatureId;
      for (const lang of specsStore.editableLangs) {
        try {
          const spec = specLangMap.langs[lang];
          if (!spec.compareFeatureId && !hasCompareFeature) {
            const compareFeatureResponse = await trackPromise(
              addCompareFeatureFromParent(
                brand,
                team,
                seriesId,
                year,
                lang,
                spec.id,
                CompareType.Spec,
                spec.description,
                spec.category.id,
                spec.specType.id || '',
                grades || [],
                compareFeatureId,
              ),
            );
            if (!specsStore.compareFeaturesMap.compareFeatures[spec.id]) {
              specsStore.compareFeaturesMap.compareFeatures[spec.id] = {
                langs: {},
                data: compareFeatureResponse.data,
              };
            }
            const cats: RefItem[] = specsStore.getCategoriesForLang(lang, specsStore.categoriesMap);
            const specTypes: RefItem[] = specsStore.getCategoriesForLang(lang, specsStore.specTypeMap);
            specsStore.compareFeaturesMap.compareFeatures[spec.id].langs[lang] = compareFeatureItemXForm(compareFeatureResponse.data, grades || [], cats, specTypes, [], [], 0);
            spec.compareFeatureId = specsStore.compareFeaturesMap.compareFeatures[spec.id].langs[lang].id;
          } else if (spec.compareFeatureId && hasCompareFeature) {
            const compareFeature = specsStore.compareFeaturesMap.compareFeatures[spec.id].langs[lang];
            compareFeature.parentId = '';
            await trackPromise(updateCompareFeature(brand, team, seriesId, year, lang, compareFeature.getPayload()));
            delete specsStore.compareFeaturesMap.compareFeatures[spec.id].langs[lang];
            spec.compareFeatureId = '';
            if (!Object.keys(specsStore.compareFeaturesMap.compareFeatures[spec.id].langs).length) {
              delete specsStore.compareFeaturesMap.compareFeatures[spec.id];
            }
          }
        } catch (e) {
          hasFailed = true;
          handleErrorResponse(e, 'Spec failed update');
          break;
        }
      }
      if (!hasFailed) {
        toast.success('Spec updated successfully');
        toast.success('Changes have also been updated on the Compare View page');
      }
      updatingCompareFeatureLink.current = false;
    }
  };

  const syncUpdates = async () => {
    setIsLoaded(false);
    try {
      await syncSpanishUpdates(brand, team, seriesId, year);
      toast.success('Sync successful');
      if (reloadDraft) {
        setIsLoaded(true);
        reloadDraft();
      }
    } catch (e) {
      handleErrorResponse(e, 'Error syncing spanish data');
      setIsLoaded(true);
    }
  };

  const compareSpec = (spec: SpecsLangMap) => {
    setSyncChangesSpec(spec);
    setShowSyncChangesModal(true);
  };

  const applyChanges = (response: AppliedChangesResponse) => {
    if (!syncChangesSpec) {
      return;
    }
    const changeLogTypes: ChangeLogTypes[] = Object.keys(response.applied) as ChangeLogTypes[];
    changeLogTypes.forEach(changeType => {
      const langMap = response.applied[changeType];
      if (langMap) {
        Object.entries(langMap).forEach(([lang, after]) => {
          const spec = syncChangesSpec.langs[lang];
          if (spec) {
            switch (changeType) {
              case ChangeLogTypes.LINK:
                spec.link = after;
                break;
              case ChangeLogTypes.DESCRIPTION:
                spec.description = after;
                break;
              case ChangeLogTypes.NOTES:
                spec.notes = after;
                break;
              case ChangeLogTypes.IN_PROGRESS:
                spec.isInProgress = after.toString() === 'true';
                break;
              case ChangeLogTypes.MODEL_APPLICABILITY:
                const modApp = after as KeyValueType<string>;
                Object.values(spec.modelsMap).forEach(model => {
                  if (modApp[model.id]) {
                    model.setting = modApp[model.id] as SpecSettings;
                  } else {
                    model.setting = SpecSettings.UNDEFINED;
                  }
                });
                break;
              default:
                break;
            }
          }
        });
      }
    });
    saveSpecLangMap(syncChangesSpec, false, undefined, true);
  };

  const getActionBarButtons = (showActionButtons: boolean) => {
    const actionBarButtons: React.ReactNode[] = [];

    if (showActionButtons && teamStore.team.allowAddDeleteData) {
      actionBarButtons.push(<IconTextButton icon="plus" text="Add Spec" onClick={() => addEmptySpecItem()} />);
      actionBarButtons.push(
        sortMode ? (
          <SortButton toggled onClick={onStopSorting}>
            Stop Sorting
          </SortButton>
        ) : (
          <>
            <SortDropdown
              buttonText="Sort"
              list={teamStore.team.allowSpecType ? ['Rows', 'Categories', 'Spec Types'] : ['Rows', 'Categories']}
              onSelect={value => {
                switch (value) {
                  case 'Rows': {
                    specsStore.resetFilters();
                    setSortMode(true);
                    break;
                  }
                  case 'Categories': {
                    setSortCategoryModal(true);
                    break;
                  }
                  case 'Spec Types': {
                    setSortSpecTypeModal(true);
                    break;
                  }
                }
              }}
            />
            <SortModal
              open={sortCategoryModal}
              onClose={() => setSortCategoryModal(false)}
              onSave={list => onSaveSortCategories(list, VDSortableEntity.SPECS_CATEGORIES)}
              idValueList={specsStore.getDefaultCategories(specsStore.categoriesMap)}
              headerText="Sort Categories"
            />
            <SortModal
              open={sortSpecTypeModal}
              onClose={() => setSortSpecTypeModal(false)}
              onSave={list => onSaveSortCategories(list, VDSortableEntity.SPECS_SPECTYPE)}
              idValueList={specsStore.getDefaultCategories(specsStore.specTypeMap)}
              headerText="Sort Spec Types"
            />
          </>
        ),
      );
    }

    const langButtons = getLangActionBarButtons(
      {
        allLangs: specsStore.allLangs,
        selectedLangsMap: specsStore.selectedLangsMap,
        updateSelectedLangs: specsStore.updateSelectedLangs,
        showActionButtons,
      },
      {
        canSyncUpdates: teamStore.team.canSyncUpdates,
        seriesId,
        year,
        syncUpdates,
      },
    );
    actionBarButtons.push(...langButtons);

    return (
      <>
        {actionBarButtons.map((button, index) => (
          <React.Fragment key={index}>
            <ActionBarDivider />
            {button}
          </React.Fragment>
        ))}
      </>
    );
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return undefined;
    }

    const [removed] = specsStore.filteredSpecLangMaps.splice(result.source.index, 1);
    specsStore.filteredSpecLangMaps.splice(result.destination.index, 0, removed);
    specsStore.filteredSpecLangMaps.forEach((item, index: number) => {
      Object.values(item.langs).forEach(specItem => {
        specItem.sortOrder = index + 1;
      });
    });
    return specsStore.filteredSpecLangMaps;
  };

  const visibleModels = () => {
    return vehicleModels;
  };

  return !isLoaded ? (
    <Spinner />
  ) : (
    <>
      <ActionBarVehicleData
        readOnly={readOnly}
        toggleViewModelCodes={() => (specsStore.viewModelCodes = !specsStore.viewModelCodes)}
        viewModelCodes={specsStore.viewModelCodes}
        searchText={specsStore.searchText}
        onSearchTextChange={text => specsStore.onFilter(() => (specsStore.searchText = text))}
        renderButtons={getActionBarButtons(!readOnly)}
        renderFilter={onClose => (
          <SpecsFilters
            onClose={onClose}
            categories={specsStore.getDefaultCategories(specsStore.categoriesMap).map(cat => cat.value)}
            categoryFilters={specsStore.categoryFilters}
            setCategoryFilters={categoryFilters => specsStore.onFilter(() => (specsStore.categoryFilters = categoryFilters))}
            specTypes={specsStore.getDefaultCategories(specsStore.specTypeMap).map(spec => spec.value)}
            specTypesFilters={specsStore.specTypeFilters}
            setSpecTypesFilters={specTypeFilters => specsStore.onFilter(() => (specsStore.specTypeFilters = specTypeFilters))}
            isInProgressFilter={specsStore.isInProgressFilter}
            setIsInProgressFilter={value => specsStore.onFilter(() => (specsStore.isInProgressFilter = value))}
            isSyncUpdateFilter={specsStore.isSyncUpdateFilter}
            setIsSyncUpdateFilter={value => specsStore.onFilter(() => (specsStore.isSyncUpdateFilter = value))}
            brand={brand}
            isReviewNotesFilter={specsStore.isReviewNotesFilter}
            setIsReviewNotesFilter={team === VehicleTeam.AGENCY_TEAM && version == null ? value => specsStore.onFilter(() => (specsStore.isReviewNotesFilter = value)) : undefined}
            isPublished={isPublished}
          />
        )}
      />
      <TwoTableWrapper>
        <DragDropContext onDragEnd={onDragEnd}>
          <LeftTable>
            <SpecsHeaderRow
              viewModelCodes={specsStore.viewModelCodes}
              allowLinks={teamStore.team.allowLinks}
              readOnly={readOnly}
              onSort={specsStore.onSort}
              showSpecTypes={teamStore.team.allowSpecType}
              showSpecLinkModal={teamStore.team.allowShowSpecLinkModal}
              sortMode={sortMode}
              languages={specsStore.allLangs.filter(lang => specsStore.selectedLangsMap[lang])}
              team={teamStore.team.name}
              brand={brand}
            />
            <SpecRowsContainer
              specLangMaps={specsStore.filteredSpecLangMaps}
              saveSpecLangMap={saveSpecLangMap}
              deleteSpecLangMap={deleteSpecLangMap}
              copySpecLangMap={copySpecLangMap}
              addCategoryItem={addCategoryItem}
              updateCategoryItem={updateCategoryItem}
              addSpecTypeItem={addSpecTypeItem}
              updateSpecTypeItem={updateSpecTypeItem}
              handleCompareFeatureHighlighted={handleCompareFeatureHighlighted}
              showLink={teamStore.team.allowLinks}
              readOnly={readOnly}
              sortMode={sortMode}
              showSpecLinkModal={teamStore.team.allowShowSpecLinkModal}
              showSpecType={teamStore.team.allowSpecType}
              allowDisclaimerTokens={teamStore.team.allowDisclaimerTokens}
              disclaimerTokens={disclaimerTokens}
              brand={brand}
              compareSpec={compareSpec}
            />
          </LeftTable>
        </DragDropContext>
        <ModelTable
          viewModelCodes={specsStore.viewModelCodes}
          models={visibleModels()}
          headerStyle={{ top: 0 }}
          renderRows={() => (
            <>
              {specsStore.filteredSpecLangMaps.map((specLangMap, idx) => {
                const defaultSpec = specLangMap.langs[specsStore.modelApplicabilityLang];
                let changedModelIds: string[] = [];
                for (const lang of specsStore.editableLangs) {
                  changedModelIds.push(...specLangMap.langs[lang].changedModelIds);
                }

                return (
                  <React.Fragment key={defaultSpec.uid}>
                    <TableRow rowHeight={specsStore.getRowHeight(specLangMap)}>
                      {visibleModels().map(model => {
                        if (model.show && defaultSpec.modelsMap[model.id]) {
                          const syncValueChanged = changedModelIds.includes(model.id);
                          let defaultSetting = '';
                          let setEditableText = false;
                          const applicabilityTextMap: ApplicabilityTextLangMap = { text: {} };
                          for (const lang of specsStore.allLangs) {
                            const canEdit = specsStore.editableLangs.includes(lang);
                            const text = specLangMap.langs[lang].modelsMap[model.id].setting;
                            if (canEdit && text && !setEditableText) {
                              defaultSetting = text;
                              setEditableText = true;
                            } else if (!canEdit && !defaultSetting) {
                              defaultSetting = text;
                            }
                            applicabilityTextMap.text[lang] = {
                              text,
                              canEdit,
                            };
                            if (syncValueChanged && !canEdit) {
                              applicabilityTextMap.text[lang]!.syncValueChange = syncValueChanged;
                            }
                          }
                          const modelData = {
                            id: defaultSpec.modelsMap[model.id].id,
                            applicabilityTextMap,
                            setting: defaultSetting,
                          };

                          return (
                            <SettingsCell
                              disableApplicabilityText={specsStore.allLangs.length <= 1 || readOnly}
                              disableInput={!specsStore.editableLangs.includes(Language.EN)}
                              allowDisclaimerTokensInGradeApplicability={teamStore.team.allowDisclaimerTokensInGradeApplicability}
                              key={`${defaultSpec.uid}${model.uid}${defaultSetting}`}
                              disabled={readOnly}
                              model={modelData}
                              syncValueChanged={changedModelIds.includes(model.id)}
                              oddRow={idx % 2 === 1}
                              onUpdate={value => {
                                let shouldSave = false;
                                specsStore.editableLangs.forEach(lang => {
                                  const setting = specLangMap.langs[lang].modelsMap[model.id].setting || '';
                                  shouldSave = shouldSave || setting !== value;
                                  specLangMap.langs[lang].modelsMap[model.id].setting = value as string as SpecSettings;
                                });
                                if (shouldSave) {
                                  saveSpecLangMap(specLangMap);
                                }
                              }}
                              onUpdateApplicabilityText={applicabilityTextMap => {
                                let shouldSave = false;
                                const langs = Object.keys(applicabilityTextMap.text) as Language[];
                                langs.forEach(lang => {
                                  const textMap = applicabilityTextMap.text[lang]!;
                                  if (textMap.canEdit) {
                                    const spec = specLangMap.langs[lang];
                                    const curTxt = spec.modelsMap[model.id].setting;
                                    if (curTxt !== textMap.text) {
                                      shouldSave = true;
                                      spec.modelsMap[model.id].setting = textMap.text as SpecSettings;
                                    }
                                  }
                                });
                                if (shouldSave) {
                                  saveSpecLangMap(specLangMap);
                                }
                              }}
                              allowTokens={teamStore.team.useLargeSpecApplicability}
                              disclaimerTokens={disclaimerTokens}
                              rowSpan={1}
                              isRequired={defaultSpec.isRequired}
                              invalid={defaultSpec.isRequired && !defaultSpec.modelsMap[model.id].setting}
                              lexusAgencySpecs={teamStore.team.param === VehicleTeam.AGENCY_TEAM && brand === 'lexus'}
                              allowFreeFormText
                            />
                          );
                        }
                        return <></>;
                      })}
                    </TableRow>
                  </React.Fragment>
                );
              })}
            </>
          )}
        />
      </TwoTableWrapper>
      <Modal open={showSyncChangesModal} size="auto" onClose={() => setShowSyncChangesModal(false)}>
        <SyncTMNAChangesModal
          brand={brand}
          team={team}
          seriesId={seriesId}
          year={year}
          itemId={syncChangesSpec ? syncChangesSpec.langs[specsStore.defaultLang].id : ''}
          entityType={'specs'}
          isNew={!!syncChangesSpec?.langs[specsStore.defaultLang].changedAttributes?.includes('new')}
          isDelete={!!syncChangesSpec?.langs[specsStore.defaultLang].changedAttributes?.includes('delete')}
          close={(response, shouldDelete, unlinkFromTMNA) => {
            setShowSyncChangesModal(false);
            if (syncChangesSpec) {
              if (response) {
                applyChanges(response);
              } else if (shouldDelete) {
                deleteSpecLangMap(syncChangesSpec);
              } else if (unlinkFromTMNA) {
                saveSpecLangMap(syncChangesSpec, false, undefined, false, true);
              }
            }
          }}
          vehicleModels={vehicleModels}
          sourceVersion={sourceVersion}
        />
      </Modal>
    </>
  );
};

export default observer(SpecsController);
