import { faFileAlt } from "@fortawesome/free-regular-svg-icons";
import {
  faChevronCircleLeft,
  faChevronCircleRight,
  faExclamationCircle,
  faSignOutAlt,
  faUserFriends,
} from "@fortawesome/free-solid-svg-icons";
import cx from "clsx";
import React, { ReactNode, useRef, useState } from "react";
import ResizeDetector from "react-resize-detector";
import { NavLink } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { logout } from "../../authConfig";
import useStores from "../../hooks/useStores";
import {
  AppRoute,
  DisclaimerRoutes,
  RestOfRoutes,
  SeriesCategoriesRoutes,
  VehicleDataRoutes,
} from "../../models/routes.model";
import { BRAND_LEXUS, BRAND_TOYOTA } from "../../models/user.model";
import styles from "./sideMenu.module.scss";
import SideMenuIcon from "./SideMenuIcon";

const isSmallText = (path: string) => {
  switch (path) {
    case "/decks":
    case "/settings":
    case "/help": {
      return styles.smallText;
    }
  }
  return undefined;
};

type RouteBuilderProps = {
  brand?: string;
  routes: AppRoute[];
  canViewDraft?: boolean;
  accessible?: boolean;
};
const hideRouteByBrand = (path: string, brand?: string) => {
  let hide: boolean;
  switch (path) {
    case "/content-decks":
      hide = brand !== BRAND_LEXUS;
      break;
    case "/reports":
      hide = brand !== BRAND_TOYOTA;
      break;
    default:
      hide = false;
      break;
  }
  return hide;
};

const DefaultRouteBuilder = ({
  brand,
  routes,
  canViewDraft,
  accessible = false,
}: RouteBuilderProps) =>
  !accessible ? (
    <></>
  ) : (
    <>
      {routes.map((route, index) => {
        const hideRouteLink = route.hide || hideRouteByBrand(route.path, brand);
        if (hideRouteLink) {
          return null;
        }
        return (
          <li key={index} className={isSmallText(route.path)}>
            <NavLink to={route.path}>{route.text}</NavLink>
            {route.childRoutes && (
              <ul>
                {route.childRoutes.map(
                  (childRoute) =>
                    !childRoute.hide &&
                    (childRoute.text !== "Draft" || canViewDraft) && (
                      <li key={uuidv4()}>
                        <NavLink to={childRoute.path}>
                          {childRoute.text}
                        </NavLink>
                      </li>
                    )
                )}
              </ul>
            )}
          </li>
        );
      })}
    </>
  );

const testVDActive = (pathname: string, team: string) => {
  return pathname.includes("vehicleData") && pathname.includes(team);
};

interface VehicleDataRouteBuilderProps extends RouteBuilderProps {
  teamParam: string;
  text: string;
}

const VehicleDataRouteBuilder = ({
  routes,
  teamParam,
  text,
  accessible = false,
}: VehicleDataRouteBuilderProps) =>
  !accessible ? (
    <></>
  ) : (
    <>
      {routes.map(
        (route) =>
          !route.hide && (
            <li key={uuidv4()} className={isSmallText(route.path)}>
              <NavLink
                to={route.path.replace(":team", teamParam)}
                isActive={(match, location) =>
                  testVDActive(location.pathname, teamParam)
                }
              >
                {text}
              </NavLink>
            </li>
          )
      )}
    </>
  );

type SideMenuProps = {
  children: ReactNode;
};

const SideMenu = ({ children }: SideMenuProps) => {
  const {
    userStore: { modules, brand },
    resizeDetectorStore,
  } = useStores();

  const ref = useRef<HTMLElement>(null);

  const [collapsed, setCollapsed] = useState(
    localStorage.getItem("sidemenu.collapsed") === "collapsed"
  );

  const handleOnCollapsed = () => {
    const val = !collapsed;
    setCollapsed(val);
    localStorage.setItem("sidemenu.collapsed", val ? "collapsed" : "");
  };

  const onResize = (width?: number) => {
    width && resizeDetectorStore.setValues(width, window.innerWidth);
  };

  return (
    <section
      className={cx({
        [styles.navContainer]: true,
        [styles.collapsed]: collapsed,
      })}
    >
      <nav
        className={cx({
          [styles.sideMenu]: true,
          [styles.collapsed]: collapsed,
        })}
      >
        {collapsed ? (
          <section>
            <h1 className={styles.titleCollapsed}>V</h1>
            <section className={cx(styles.listContainer, styles.collapsed)}>
              <ul>
                {modules.AgencyTeam.canView && (
                  <NavLink
                    to={"/vehicleData/agency-team"}
                    isActive={(match, location) =>
                      testVDActive(location.pathname, "agency-team")
                    }
                  >
                    <SideMenuIcon icon={faUserFriends} />
                  </NavLink>
                )}
                {modules.Disclaimers.canView && (
                  <NavLink to={"/disclaimers/published"}>
                    <SideMenuIcon icon={faExclamationCircle} />
                  </NavLink>
                )}
                <NavLink to={"/reports"}>
                  <SideMenuIcon icon={faFileAlt} />
                </NavLink>
              </ul>
            </section>
          </section>
        ) : (
          <section>
            <h1 className={styles.title}>VAPI</h1>
            <section className={styles.listContainer}>
              <ul>
                <VehicleDataRouteBuilder
                  accessible={modules.AgencyTeam.canView}
                  routes={VehicleDataRoutes}
                  teamParam="agency-team"
                  text="Engage"
                />

                <DefaultRouteBuilder
                  accessible={modules.Disclaimers.canView}
                  routes={DisclaimerRoutes}
                  canViewDraft={modules.Disclaimers.canViewDraft}
                />

                <DefaultRouteBuilder
                  accessible={modules.AgencyTeam.canView}
                  routes={SeriesCategoriesRoutes}
                />

                <DefaultRouteBuilder
                  accessible={true}
                  brand={brand}
                  routes={RestOfRoutes}
                />
              </ul>
            </section>
          </section>
        )}
        <footer>
          <div
            className={cx(
              styles.footerContainer,
              collapsed && styles.collapsed
            )}
          >
            <div>
              <SideMenuIcon
                title="Logout"
                icon={faSignOutAlt}
                onClick={() => logout()}
              >
                {!collapsed && "Logout"}
              </SideMenuIcon>
            </div>
          </div>
          <section
            className={cx(styles.collapseToggle, collapsed && styles.collapsed)}
          >
            <SideMenuIcon
              icon={collapsed ? faChevronCircleRight : faChevronCircleLeft}
              onClick={() => handleOnCollapsed()}
            />
          </section>
        </footer>
      </nav>
      <main ref={ref} className={styles.mainContent}>
        {children}
        <ResizeDetector
          handleWidth
          onResize={(width) => onResize(width)}
          targetDomEl={ref === null ? undefined : (ref.current as HTMLElement)}
        />
      </main>
    </section>
  );
};

export default SideMenu;
