import cx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { ReactNode, useState } from 'react';
import { Modal } from 'vapi-ui-common';
import IconTextButton from '../../../../../../components/IconTextButton';
import { DashboardDetailYear } from '../../../../../../models/vehicleData.model';
import AddModelYearModal from '../AddModelYearModal';
import RemoveSeriesModal from '../RemoveSeriesModal';
import styles from './vehicleTable.module.scss';

interface VehicleTableProps {
  series?: string;
  seriesId: string;
  data: DashboardDetailYear[];
  useNewStatus: boolean;
  renderRows: () => ReactNode;
  onDuplicateItem: (seriesId: string, fromModelYear: number, toModelYear: number) => void;
  onRemoveSeries: (seriesId: string) => void;
  canAddYear?: boolean;
  canRemoveYear?: boolean;
  isSpanish?: boolean;
}

const VehicleTable = observer(
  ({ series, seriesId, data, useNewStatus, renderRows, onDuplicateItem, onRemoveSeries, canAddYear, canRemoveYear = true, isSpanish }: VehicleTableProps) => {
    const [removeSeries, setRemoveSeries] = useState(false);
    const [addModel, setaddModel] = useState(false);

    return (
      <table className={styles.table}>
        <thead>
          <tr>
            <td>
              <button className={cx(styles.cta, styles.seriesName)}>{series}</button>
            </td>
            <td>
              <button className={styles.cta}>Date published</button>
            </td>
            <td>
              <button className={styles.cta}>Version</button>
            </td>
            <td>{isSpanish ? 'ES' : ''}</td>
            <td>{isSpanish ? 'EN' : ''}</td>
            {isSpanish && process.env.REACT_APP_MULTI_LANG === 'true' && <td />}
            {isSpanish && process.env.REACT_APP_MULTI_LANG === 'true' && <td className={styles.statusColumn} />}
            <td className={styles.actionBtns}>
              {canAddYear && <IconTextButton icon="plus" text="Add Model Year" className={styles.addModelYearCta} onClick={() => setaddModel(true)} />}
              <Modal className={styles.addModelYearModal} open={addModel} onClose={() => setaddModel(false)}>
                <AddModelYearModal close={() => setaddModel(false)} seriesName={series} seriesId={seriesId} modelYearItem={data} onDuplicateItem={onDuplicateItem} />
              </Modal>
              {canRemoveYear && <IconTextButton icon="remove" text="Remove from Dashboard" className={styles.removeCta} onClick={() => setRemoveSeries(true)} />}
              <Modal open={removeSeries} onClose={() => setRemoveSeries(false)}>
                <RemoveSeriesModal close={() => setRemoveSeries(false)} seriesName={series} onSubmit={() => onRemoveSeries(seriesId)} />
              </Modal>
            </td>
          </tr>
        </thead>

        <tbody className={styles.body}>{renderRows()}</tbody>
      </table>
    );
  },
);

export default VehicleTable;
