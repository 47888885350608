import { faCompressArrowsAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'clsx';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Input } from 'vapi-ui-common';
import IconTextButton from '../../../../../../../../components/IconTextButton';
import useStores from '../../../../../../../../hooks/useStores';
import { VehicleModelItem, VehicleModelToyota } from '../../../../../../../../models/vehicleModel.model';
import styles from '../../../../modelsModal.module.scss';
import Label from '../Label';

interface TrimTitleProps {
  vmId: string;
  seriesName: string;
  model: VehicleModelItem<VehicleModelToyota>;
}

const TrimTitle = observer(({ vmId, seriesName, model }: TrimTitleProps) => {
  const {
    modelTabStore: { languages: checkboxLanguages, readOnly, getTrimTitle, setTrimTitle, getGrade, getActiveLanguagesAmount, getLangVehicleModel },
    userStore: { langPermissions },
    teamStore: {
      team: { languages: teamLanguages },
    },
  } = useStores();

  const handleTrimTitleGenerator = () => {
    teamLanguages.forEach(language => {
      if (!langPermissions[language]?.canEdit) return;
      const grade = getGrade(vmId, language);
      const trimTitle = `${seriesName} ${grade.value ? grade.value : ''}`;
      setTrimTitle(vmId, language, trimTitle);
    });
  };

  return (
    <div className={cx(getActiveLanguagesAmount() > 1 ? styles.longer : '')}>
      <Label htmlFor={`amm-trim-title-${vmId}`}>Trim Title</Label>
      {!readOnly && (
        <IconTextButton title="Populate trim title using Series Name, Grade" icon="random" text="" className={styles.populate} onClick={handleTrimTitleGenerator}>
          <FontAwesomeIcon icon={faCompressArrowsAlt} />
        </IconTextButton>
      )}
      <div className={cx(styles.content)}>
        {teamLanguages.map(language => {
          if (checkboxLanguages[language].selected && langPermissions[language]?.canView) {
            let changedAttributes: string[] = [];
            if (langPermissions[language]?.canEdit) {
              changedAttributes = getLangVehicleModel(model, language).getVal('changedAttributes') ?? [];
            }
            return (
              <Input
                required
                id={`amm-trim-title-${vmId}-${language}`}
                key={`amm-trim-title-${vmId}-${language}`}
                value={getTrimTitle(vmId, language)}
                disabled={!langPermissions[language]?.canEdit || readOnly}
                onChange={e => setTrimTitle(vmId, language, e.currentTarget.value)}
                placeholder={checkboxLanguages[language].description}
                className={changedAttributes.includes('trimTitle') ? styles.errorText : ''}
              />
            );
          }

          return null;
        })}
      </div>
    </div>
  );
});

export default TrimTitle;
