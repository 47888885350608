import { action, observable, makeObservable } from 'mobx';
import { isSortReverse, sortBy } from '../utils';
import { filterDisclaimers } from '../utils/disclaimersUtils';
import { DisclaimersItem, DisclaimersVehicle } from '../models/disclaimers.model';

export class DisclaimersStore {
  data: DisclaimersItem[] = [];
  searchText = '';
  isReverseSort = false;
  sortField = 'id';
  filteredData: DisclaimersItem[] = [];
  vehicleModels: DisclaimersVehicle[] = [];
  versions: string[] = [];
  vehicleFilters: string[] = [];
  tokens: string[] = [];

  isFetching = false;
  isLoaded = false;

  setData = (data: DisclaimersItem[]) => {
    this.data = data;
    this.filteredData = data;
  };

  addItem = () => {
    const newItem = new DisclaimersItem();
    this.data = [newItem, ...this.data];
    this.filteredData = [newItem, ...this.filteredData];
  };

  deleteItem = (dItem: DisclaimersItem) => {
    this.data = this.data.filter(item => item.uid !== dItem.uid);
    this.filteredData = this.filteredData.filter(item => item.uid !== dItem.uid);
  };

  setFilters = (vehicleFilters: string[]) => {
    this.vehicleFilters = vehicleFilters;
    this.filterData();
  };

  setSort = (sortField: string) => {
    this.isReverseSort = isSortReverse(this.sortField, sortField, this.isReverseSort);
    this.sortField = sortField;
    this.filterData();
  };

  setIsFetching = (isFetching: boolean) => {
    this.isFetching = isFetching;
  };
  setIsLoaded = (isLoaded: boolean) => {
    this.isLoaded = isLoaded;
  };

  filterData = () => {
    const sorted = this.data
      .slice()
      .sort(sortBy(this.sortField, this.isReverseSort, this.sortField === 'lastPublishedDate'));
    this.filteredData = filterDisclaimers(this.searchText, this.vehicleFilters, sorted);
  };

  constructor() {
    makeObservable(this, {
      filteredData: observable,
      vehicleModels: observable,
      versions: observable,
      vehicleFilters: observable,
      tokens: observable,
      reset: action
    });
  }

  reset() {
    this.data = [];
    this.searchText = '';
    this.isReverseSort = false;
    this.sortField = 'id';
    this.filteredData = [];
    this.vehicleModels = [];
    this.versions = [];
    this.vehicleFilters = [];
    this.tokens = [];
  }
}
