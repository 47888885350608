import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { Button, ModalBody, ModalFooter, ModalHeader } from 'vapi-ui-common';

interface IEditGoLiveDateModal {
  close: () => void;
  onSubmit: (goLiveDate: string) => void;
  currentGoLiveDate: Date | null;
}

const EditGoLiveDateModal = observer(
  ({ close, onSubmit, currentGoLiveDate }: IEditGoLiveDateModal) => {
    const [goLiveDate, setGoLiveDate] = useState<Date>(currentGoLiveDate ?? new Date());

    return (
      <>
        <ModalHeader onClose={close}>Set Go Live Date</ModalHeader>
        <ModalBody>
          <Calendar value={goLiveDate} onChange={setGoLiveDate} minDate={new Date()} />
        </ModalBody>
        <ModalFooter>
          <Button
            variant="transparent"
            onClick={() => {
              close();
            }}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              close();
              onSubmit(goLiveDate.toLocaleDateString());
            }}
          >
            Confirm
          </Button>
        </ModalFooter>
      </>
    );
  }
);

export default EditGoLiveDateModal;
