import { action, observable, makeObservable } from 'mobx';
import { ReviewChangeRequest, ReviewChangeTypeMap } from '../../../models/review.model';
import { VehicleTeam } from '../../../models/vehicleData.model';

export default class BaseReviewStore {
  categoryFilters: string[] = [];
  searchText = '';
  isInProgressFilter = false;
  isHighlightedFilter = false;
  viewModelCodes = false;
  showLongDescription = true;
  showShortDescription = false;
  reverse = true;
  sortField = 'modifiedDate';
  isNewChangeFilter = true;

  setSort = (sortField: string) => {
    this.reverse = this.sortField !== sortField ? false : !this.reverse;
    this.sortField = sortField;
  };

  constructor() {
    makeObservable(this, {
      categoryFilters: observable,
      searchText: observable,
      isInProgressFilter: observable,
      isHighlightedFilter: observable,
      viewModelCodes: observable,
      showLongDescription: observable,
      showShortDescription: observable,
      reverse: observable,
      sortField: observable,
      isNewChangeFilter: observable,
      reset: action
    });
  }

  getMapPayload(map: ReviewChangeTypeMap, team: VehicleTeam): ReviewChangeRequest {
    return {
      id: map.id,
      revId: map.revId,
      isAccepted: map.isApplied || team === VehicleTeam.AGENCY_TEAM,
      isApplied: map.isApplied,
      changeTypeId: '',
      rejectNotes: map.rejectNotes,
    };
  }

  isMapValid = (map: ReviewChangeTypeMap, team: VehicleTeam): boolean => {
    return team === VehicleTeam.AGENCY_TEAM || map.isApplied || !!map.rejectNotes;
  };

  reset() {
    this.categoryFilters = [];
    this.searchText = '';
    this.isInProgressFilter = false;
    this.isHighlightedFilter = false;
    this.viewModelCodes = false;
    this.showLongDescription = true;
    this.showShortDescription = false;
    this.reverse = true;
    this.sortField = 'modifiedDate';
    this.isNewChangeFilter = true;
  }
}
