import apolloClient from '../apolloClient';
import { GetDisclaimerTokensDocument, GetDisclaimerTokensQuery, GetDisclaimerTokensQueryVariables } from '../gql/generated';
import { DisclaimersChangeLogResponse, DisclaimersRequest, DisclaimersResponse } from '../models/disclaimers.model';
import { VersionInfo } from '../models/spanishDisclaimers.model';
import API from '../webservices/api';

const RESOURCE_PATH = '/disclaimers';

// Disclaimers
//
const DISCLAIMERS_PATH = '/disclaimers';
export const getDisclaimers = (brand: string, status: string) => {
  return API.get<DisclaimersResponse[]>(`${RESOURCE_PATH}${DISCLAIMERS_PATH}/${brand}?status=${status}`);
};

export const addDisclaimer = (brand: string, data: DisclaimersRequest) => {
  return API.post<DisclaimersResponse>(`${RESOURCE_PATH}${DISCLAIMERS_PATH}/${brand}`, data);
};

export const updateDisclaimer = (brand: string, data: DisclaimersRequest) => {
  return API.put<DisclaimersResponse>(`${RESOURCE_PATH}${DISCLAIMERS_PATH}/${brand}`, data);
};

export const deleteDisclaimer = (brand: string, id: string, revId: string) => {
  return API.delete(`${RESOURCE_PATH}${DISCLAIMERS_PATH}/${brand}`, { data: { id, revId } });
};

export const archiveDisclaimer = (brand: string, data: { id: string; revId: string }) => {
  return API.post(`${RESOURCE_PATH}${DISCLAIMERS_PATH}/${brand}/archive`, data);
};

// Change Log
//
const CHANGE_LOG_PATH = '/change-log';
export const getChangeLog = (brand: string, version: string) => {
  const params = version.toUpperCase() === 'DRAFT' ? `status=${version.toUpperCase()}` : `version=${version}`;
  return API.get<DisclaimersChangeLogResponse>(`${RESOURCE_PATH}${CHANGE_LOG_PATH}/${brand}?${params}`);
};

export const revertChange = (
  brand: string,
  data: {
    id: string;
    revId: string;
    before: string;
    after: string;
    changeType: string;
  },
) => {
  return API.post(`${RESOURCE_PATH}${CHANGE_LOG_PATH}/${brand}/revert`, data);
};

// Versions
//
export const getDisclaimerVersions = (brand: string) => {
  return API.get<string[]>(`${RESOURCE_PATH}/versions/${brand}`);
};

export const getVersionInfo = (brand: string) => {
  return API.get<VersionInfo>(`${RESOURCE_PATH}/versionInfo/${brand}`);
};

// Draft
//
export const resetDraft = (brand: string) => {
  return API.post(`${RESOURCE_PATH}/draft/${brand}/reset`);
};

export const publishDraft = (brand: string) => {
  return API.post(`${RESOURCE_PATH}/draft/${brand}/publish`);
};

// Tokens
export const getDisclaimerTokens = (brand: string) =>
  apolloClient
    .query<GetDisclaimerTokensQuery, GetDisclaimerTokensQueryVariables>({
      query: GetDisclaimerTokensDocument,
      fetchPolicy: 'no-cache',
      variables: {
        brand,
      },
    })
    .then(res => ({ ...res, data: res.data.disclaimersTokens as string[] }));

export const uploadDisclaimersDocument = (brand: string, lang: string, resultJSON: object) => {
  return API.post(`${RESOURCE_PATH}/upload/${brand}/${lang}`, resultJSON);
};

export const getDisclaimersDocumentLink = (brand: string, version: number, lang: string) => {
  return API.post(`${RESOURCE_PATH}/download/${brand}/${version}/${lang}`);
};

export const publishDisclaimers = (brand: string, lang: string) => {
  return API.post(`${RESOURCE_PATH}/downstream/${brand}/${lang}/publish`);
};
