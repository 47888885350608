import React from 'react';
import Checkbox from '../components/Checkbox/Checkbox';
import SyncUpdatesPopover from '../components/SyncUpdatesPopover/SyncUpdatesPopover';
import { langNameMap } from '../constants/vehicleData/VDConstants';
import { KeyValueType } from '../models/common.model';
import { Language } from '../models/user.model';

const getLangActionBarButtons = (
  checkboxProps: {
    allLangs: Language[];
    selectedLangsMap: KeyValueType<boolean>;
    updateSelectedLangs: (lang: string, isSelected: boolean) => void;
    showActionButtons: boolean;
  },
  syncUpdatesProps: {
    canSyncUpdates: boolean;
    seriesId: string;
    year: string;
    syncUpdates: () => void;
  },
) => {
  const { allLangs, selectedLangsMap, updateSelectedLangs, showActionButtons } = checkboxProps;
  const { canSyncUpdates, seriesId, year, syncUpdates } = syncUpdatesProps;
  const actionBarButtons: React.ReactNode[] = [];
  if (allLangs.length > 1) {
    for (const lang of allLangs) {
      actionBarButtons.push(
        <Checkbox
          key={`${lang}-select-checkbox`}
          id={`${lang}-select-checkbox`}
          checked={!!selectedLangsMap[lang]}
          onChange={e => updateSelectedLangs(lang, e.currentTarget.checked)}
        >
          <span>{langNameMap[lang]}</span>
        </Checkbox>,
      );
    }
  }

  if (showActionButtons && canSyncUpdates) {
    actionBarButtons.push(<SyncUpdatesPopover seriesId={seriesId} year={year} syncUpdates={syncUpdates} />);
  }

  return actionBarButtons;
};

export default getLangActionBarButtons;
