import { ChangeLogTypes } from '../models/changeLog.model';
import { GradeApplicability } from '../models/features.model';
import { RefItem } from '../models/refItem.model';
import { VehicleModelItem, VehicleModelToyota } from '../models/vehicleModel.model';

export const gradeApplicabilityXform = (vehicleModels: VehicleModelItem<VehicleModelToyota>[], gradeApplicability: GradeApplicability) => {
  const gradeApp: GradeApplicability = {};

  // get unique set of grade Ids
  const gradeSet = new Set<RefItem>();
  vehicleModels.forEach(model => {
    const grade: RefItem = model.getVal('grade');
    gradeSet.add(grade);
  });

  gradeSet.forEach(({ id }) => {
    if (gradeApplicability && gradeApplicability[id]) {
      gradeApp[id] = JSON.parse(JSON.stringify(gradeApplicability[id]));
    } else {
      gradeApp[id] = {
        highlighted: false,
        isComparable: false,
        applicabilityText: {},
      };
    }
  });

  return gradeApp;
};

export const filterOutUnsuableChanges = (change: any) => {
  let item = change;

  //handle single change on review
  if (change.changes && typeof change.changes === 'object' && change.changes.changeType) {
    item = change.changes;
  }
  const before = item?.before;
  const after = item?.after;

  return (
    item.changeType !== ChangeLogTypes.GRADE_APPLICABILITY ||
    // if it matches this: { after:{} before: undefined}, then don't show it
    (!(typeof after == 'object' && Object.keys(after).length === 0 && typeof before === 'undefined') &&
      // also check for {after: undefined: before: {}} b/c that exists for some reason
      !(before && Object.keys(before).length === 0 && typeof before === 'object' && typeof after === 'undefined') &&
      // also {after: {}, before: {}}
      !(typeof before === 'object' && Object.keys(before).length === 0 && typeof after === 'object' && Object.keys(after).length === 0))
  );
};
