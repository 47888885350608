import { computed, makeObservable, observable, override } from "mobx";
import { convertToRichTextObject } from "vapi-ui-common";
import { ModelsReviewMap } from "../../../models/vehicleModel.model";
import { sortBy, toLowerCase } from "../../../utils";
import BaseReviewStore from "./baseReviewStore";

class ModelsReviewStore extends BaseReviewStore {
  filterOutCodeRed = false;
  map: ModelsReviewMap = {};

  constructor() {
    // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
    super();

    makeObservable(this, {
      filterOutCodeRed: observable,
      map: observable,
      filteredReviewItems: computed,
      reset: override,
    });
  }

  get filteredReviewItems() {
    const items = Object.values(this.map)
      .slice()
      .sort(
        sortBy(this.sortField, this.reverse, this.sortField === "modifiedDate")
      );
    const lowerSearchText = toLowerCase(this.searchText);
    return items.filter((model) => {
      if (lowerSearchText) {
        const valuesToCheck: string[] = [
          convertToRichTextObject(model.description.before).text,
          convertToRichTextObject(model.description.after).text,
          model.rejectNotes,
        ];
        for (let val of valuesToCheck) {
          if (toLowerCase(val).includes(lowerSearchText)) {
            return true;
          }
        }
      }

      return !lowerSearchText;
    });
  }

  reset() {
    super.reset();
    this.filterOutCodeRed = false;
    this.map = {};
  }
}
const modelsReviewStore = new ModelsReviewStore();
export default modelsReviewStore;
