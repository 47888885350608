import cx from 'clsx';
import React, { ButtonHTMLAttributes } from 'react';
import { HeaderButton } from '../..';
import { TableColType } from '../TableCell/tableTypes';
import styles from './headerCell.module.scss';

interface HeaderCellProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  cellClassName?: string;
  border?: boolean;
  colType?: TableColType;
  rowSpan?: number;
  colSpan?: number;
  align?: 'left' | 'center' | 'right';
  dataTestId?: string;
}

export const HeaderCell: React.FC<HeaderCellProps> = ({
  onClick,
  cellClassName,
  border,
  children,
  colType,
  rowSpan,
  colSpan,
  align,
  dataTestId,
  ...rest
}) => (
  <td
    rowSpan={rowSpan}
    colSpan={colSpan}
    className={cx(
      styles.headerCell,
      cellClassName,
      align && styles[align],
      border && styles.border,
      colType && styles[`colType-${colType}`]
    )}
    data-testid={dataTestId}
  >
    {onClick && (
      <HeaderButton onClick={onClick} {...rest}>
        {children}
      </HeaderButton>
    )}
    {!onClick && children}
  </td>
);
