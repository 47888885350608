import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { DragDropContext, Droppable, DropResult } from "react-beautiful-dnd";
import { v4 as uuidv4 } from "uuid";
import { Table } from "../../../../../../components/Table";
import useStores from "../../../../../../hooks/useStores";
import {
  SeriesSettingsItem,
  SeriesSettingsLangMap,
} from "../../../../../../models/seriesSettings.model";
import { Brand } from "../../../../../../models/user.model";
import { SeriesSettingVMProps } from "../../SeriesSettingsProps";
import SeriesSettingsHeaderRow from "../SeriesSettingsHeaderRow";
import SeriesSettingsEditPanel from "./SeriesSettingsEditPanel";
import SeriesSettingsRow from "./SeriesSettingsRow";

const defaultSeriesSettingsLangMap = (brand: Brand, languages: string[]) => {
  const seriesSettingsLangMap: SeriesSettingsLangMap = {};
  const id = uuidv4();
  languages.forEach((lang) => {
    seriesSettingsLangMap[lang] = new SeriesSettingsItem(brand);
    seriesSettingsLangMap[lang].id = id;
  });
  return seriesSettingsLangMap;
};

const SeriesSettingsTable = ({
  brand,
  readOnly,
  fuelTypesList,
  seriesCategoriesList,
  onSaveSeriesSetting,
  onDeleteSeriesSetting,
  onSaveSubSeriesSetting,
  onDeleteSubSeriesSetting,
  canAddNewSeriesSettings,
  seriesSettingsLangMaps,
  compareSettings,
}: SeriesSettingVMProps) => {
  const { seriesSettingsStore } = useStores();
  const [showAddSeriesSetting, setShowAddSeriesSetting] = useState(false);
  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return undefined;
    }

    const [removed] = seriesSettingsLangMaps.splice(result.source.index, 1);
    seriesSettingsLangMaps.splice(result.destination.index, 0, removed);
    seriesSettingsLangMaps.forEach((langMap, ind) => {
      Object.values(langMap).forEach((settings) => {
        settings.sortOrder = ind;
      });
    });
    seriesSettingsStore.seriesSettingsLangMaps = seriesSettingsLangMaps;
    return seriesSettingsLangMaps;
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Table fullWidth>
        <SeriesSettingsHeaderRow
          brand={brand}
          toggleAddSeries={(value) => setShowAddSeriesSetting(value)}
          readOnly={readOnly}
          canAddNewSeriesSettings={canAddNewSeriesSettings}
        />
        <Droppable droppableId="seriesSettingDroppable">
          {(provided) => (
            <tbody ref={provided.innerRef} {...provided.droppableProps}>
              {showAddSeriesSetting && (
                <SeriesSettingsEditPanel
                  brand={brand}
                  seriesCategoriesList={seriesCategoriesList}
                  fuelTypesList={fuelTypesList}
                  onClose={() => setShowAddSeriesSetting(false)}
                  saveSeriesSetting={onSaveSeriesSetting}
                  seriesSettingsLangMap={defaultSeriesSettingsLangMap(
                    brand,
                    seriesSettingsStore.allLangs
                  )}
                  defaultLang={seriesSettingsStore.defaultLang}
                  canAddNewSeriesSettings={canAddNewSeriesSettings}
                />
              )}

              {seriesSettingsLangMaps.map((seriesSettingsLangMap, index) => {
                const seriesSetting =
                  seriesSettingsLangMap[seriesSettingsStore.defaultLang];
                return (
                  <SeriesSettingsRow
                    brand={brand}
                    key={seriesSetting.uid}
                    index={index}
                    readOnly={readOnly}
                    fuelTypesList={fuelTypesList}
                    saveSeriesSetting={onSaveSeriesSetting}
                    onDelete={onDeleteSeriesSetting}
                    saveSubSeriesSetting={onSaveSubSeriesSetting}
                    onDeleteSubSeriesSetting={onDeleteSubSeriesSetting}
                    seriesCategoriesList={seriesCategoriesList}
                    canDeleteSetting={false}
                    seriesSettingsLangMap={seriesSettingsLangMap}
                    defaultLang={seriesSettingsStore.defaultLang}
                    languages={seriesSettingsStore.allLangs}
                    compareSettings={compareSettings}
                    canAddNewSeriesSettings={canAddNewSeriesSettings}
                  />
                );
              })}
              {provided.placeholder}
            </tbody>
          )}
        </Droppable>
      </Table>
    </DragDropContext>
  );
};

export default observer(SeriesSettingsTable);
