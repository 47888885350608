import React, { HTMLAttributes } from 'react';
import styles from './stickyHeaderSection.module.scss';

interface stickyProps extends HTMLAttributes<HTMLDivElement>{
  hasFilters?: boolean;
}
const StickyHeaderSection = (props: stickyProps) => {
  if (props.hasFilters) {
    return <div className={styles.stickyHeaderSectionWithFilters} {...props} />;
  } else {
    return <div className={styles.stickyHeaderSection} {...props} />;  
  }
  
};

export default StickyHeaderSection;
