import React, { useEffect, useState } from 'react';
import {
  Button,
  FilterTab,
  FilterTabColumn,
  FilterTabColumnList,
  FilterTabColumns,
  FilterTabFooter,
} from 'vapi-ui-common';
import CategoryFiltersColumn from '../../../../../../components/CategoryFiltersColumn';
import Checkbox from '../../../../../../components/Checkbox';
import useChecklist from '../../../../../../hooks/useChecklist';
import useStores from '../../../../../../hooks/useStores';
import { VehicleTeam } from '../../../../../../models/vehicleData.model';

type Props = {
  onClose: () => void;
  categories: string[];
  categoryFilters: string[];
  setCategoryFilters: (categoryFilters: string[]) => void;
  specTypes: string[];
  specTypesFilters: string[];
  setSpecTypesFilters: (specTypesFilters: string[]) => void;
  isInProgressFilter: boolean;
  setIsInProgressFilter: (value: boolean) => void;
  isSyncUpdateFilter?: boolean;
  setIsSyncUpdateFilter?: (value: boolean) => void;
  brand: string;
  isReviewNotesFilter?: boolean;
  setIsReviewNotesFilter?: (value: boolean) => void;
  isPublished?: boolean;
};
const Filters: React.FC<Props> = ({
  onClose,
  categories,
  categoryFilters,
  specTypes,
  specTypesFilters,
  setSpecTypesFilters,
  setCategoryFilters,
  isInProgressFilter,
  setIsInProgressFilter,
  isSyncUpdateFilter,
  setIsSyncUpdateFilter,
  brand,
  isReviewNotesFilter,
  setIsReviewNotesFilter,
  isPublished,
}) => {
  const { checklist, setChecklist, isAllSelected, selectAll, selectItem } = useChecklist();

  const {
    checklist: specTypeChecklist,
    setChecklist: setSpecTypeChecklist,
    isAllSelected: specTypeIsAllSelected,
    selectAll: specTypeSelectAll,
    selectItem: specTypeSelectItem,
  } = useChecklist();

  const {
    teamStore: {
      team: { name },
    },
  } = useStores();

  const [isInProgress, setIsInProgress] = useState(isInProgressFilter);
  const [isSyncUpdate, setIsSyncUpdate] = useState(isSyncUpdateFilter || false);
  const [isReviewNotes, setIsReviewNotes] = useState(isReviewNotesFilter || false);

  useEffect(() => {
    (() => {
      setChecklist(
        categories.map((cat: string) => ({
          name: cat,
          selected: categoryFilters.indexOf(cat) !== -1,
        }))
      );
    })();
  }, [categories, setChecklist, categoryFilters]);

  useEffect(() => {
    (() => {
      setSpecTypeChecklist(
        specTypes.map((cat: string) => ({
          name: cat,
          selected: specTypesFilters.indexOf(cat) !== -1,
        }))
      );
    })();
  }, [specTypes, setSpecTypeChecklist, specTypesFilters]);

  const applyFilters = () => {
    const selectedCategoryFilters: string[] = [];
    const selectedSpecTypeFilters: string[] = [];

    checklist.forEach(item => {
      if (item.selected) {
        selectedCategoryFilters.push(item.name);
      }
    });

    specTypeChecklist.forEach(item => {
      if (item.selected) {
        selectedSpecTypeFilters.push(item.name);
      }
    });

    setIsInProgressFilter(isInProgress);
    setIsSyncUpdateFilter && setIsSyncUpdateFilter(isSyncUpdate);
    setSpecTypesFilters(selectedSpecTypeFilters);
    setCategoryFilters(selectedCategoryFilters);
    setIsReviewNotesFilter && setIsReviewNotesFilter(isReviewNotes);
    onClose();
  };

  return (
    <FilterTab>
      <FilterTabColumns>
        <CategoryFiltersColumn
          isAllSelected={isAllSelected}
          selectAll={selectAll}
          checklist={checklist}
          selectItem={selectItem}
        />

        {/* Spec Types */}
        <FilterTabColumn>
          {brand !== 'lexus' && (
            <Checkbox
              id="AllSpecTypeCategories"
              checked={specTypeIsAllSelected}
              onChange={e => specTypeSelectAll(e.currentTarget.checked)}
            >
              All Spec Types
            </Checkbox>
          )}
          <FilterTabColumnList>
            {specTypeChecklist.map(item => (
              <li key={item.name}>
                <Checkbox
                  id={`chboxSpec${item.name}`}
                  checked={item.selected}
                  onChange={e => specTypeSelectItem(item, e.currentTarget.checked)}
                >
                  {item.name}
                </Checkbox>
              </li>
            ))}
          </FilterTabColumnList>
        </FilterTabColumn>

        <FilterTabColumn>
          <Checkbox
            id="InProgress"
            defaultChecked={isInProgress}
            onChange={e => setIsInProgress(e.currentTarget.checked)}
          >
            In Progress
          </Checkbox>
        </FilterTabColumn>
        {setIsReviewNotesFilter && (
          <FilterTabColumn>
            <Checkbox
              id="ReviewNotes"
              defaultChecked={isReviewNotes}
              onChange={e => setIsReviewNotes(e.currentTarget.checked)}
            >
              Review Notes
            </Checkbox>
          </FilterTabColumn>
        )}
        {VehicleTeam.AGENCY_SPANISH === name && setIsSyncUpdateFilter && !isPublished && (
          <FilterTabColumn>
            <Checkbox
              id="SyncUpdates"
              checked={isSyncUpdate}
              onChange={e => setIsSyncUpdate(e.currentTarget.checked)}
            >
              Sync Updates
            </Checkbox>
          </FilterTabColumn>
        )}
      </FilterTabColumns>
      <FilterTabFooter>
        <Button variant="primary" onClick={() => applyFilters()}>
          Apply Filters
        </Button>
      </FilterTabFooter>
    </FilterTab>
  );
};

export default Filters;
