import { AxiosResponse } from 'axios';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { toast } from 'react-toastify';
import { ActionBar, ActionBarDivider, ActionBarSection, SearchInput, Spinner } from 'vapi-ui-common';
import { HeaderCell, Table, TableRow, Thead } from '../../../../components/Table';
import { langNameMap } from '../../../../constants/vehicleData/VDConstants';
import useStores from '../../../../hooks/useStores';
import { BnPCategories, BnPCategoryItem, BnpCategorySplit } from '../../../../models/buildAndPrice.model';
import { Language } from '../../../../models/user.model';
import { ProductDataControllerProps } from '../../../../routes/vehicleData/models/controllers.model';
import { handleErrorResponse } from '../../../../utils/errorHandlingUtils';
import getLangActionBarButtons from '../../../../utils/getLangActionBarButtons';
import { syncSpanishUpdates } from '../../../../webservices/vehicleAdminApi';
import { updateDescription, updateGradeDetail, updateName } from '../../../../webservices/vehicleBnPApi';
import BuildAndPriceRow from './components/BuildAndPriceRow';

const BnPController = ({ readOnly, team, seriesId, year, version, vehicleModels, versionInfo, reloadDraft }: ProductDataControllerProps) => {
  const {
    userStore: { brand },
    bnpStore,
    vehicleModelsStore,
    teamStore,
  } = useStores();
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    bnpStore.reset();
    setIsLoaded(false);
    (async () => {
      try {
        await bnpStore.fetchData(brand, team, seriesId, year, vehicleModelsStore.grades, teamStore.team.langPermissions, versionInfo);
      } catch {
        toast.error('Error loading Build and Price data');
      }
      setIsLoaded(true);
    })();
  }, [bnpStore, team, brand, seriesId, year, version, vehicleModels, vehicleModelsStore, teamStore, versionInfo]);

  const handleUpdateDescription = async ({
    item,
    lang,
    acceptChanges = false,
    split,
  }: {
    item: BnPCategoryItem;
    lang: string;
    acceptChanges?: boolean;
    split?: BnpCategorySplit;
  }) => {
    try {
      if (bnpStore.langWriteMap[lang as Language]?.canEdit) {
        await trackPromise(updateDescription(brand, team, seriesId, year, lang, item.getDescriptionPayload(split), acceptChanges));

        if (acceptChanges) {
          item.changedAttributes = [];
        }
        toast.success(`${langNameMap[lang]} description updated successfully`);
      }
    } catch {
      toast.error('Error saving description');
    }
  };

  const handleUpdateGradeDetail = async (item: BnPCategoryItem, lang: string) => {
    try {
      if (bnpStore.langWriteMap[lang as Language]?.canEdit) {
        await trackPromise(updateGradeDetail(brand, team, seriesId, year, lang, item.getGradeDetailPayload()));
        toast.success(`${langNameMap[lang]} grade detail updated successfully`);
      }
    } catch {
      toast.error('Error saving grade detail');
    }
  };

  const handleUpdateName = async (categories: BnPCategories) => {
    try {
      const promises: Promise<AxiosResponse<any>>[] = [];
      for (const lang of bnpStore.editableLangs) {
        promises.push(
          updateName(brand, team, seriesId, year, lang, {
            name: categories.name,
            isInProgress: categories.isInProgress,
            notes: categories.notes,
          }),
        );
      }
      await Promise.all(promises);
      toast.success('Description updated successfully');
    } catch {
      toast.error('Error saving description');
    }
  };

  const syncUpdates = async () => {
    setIsLoaded(false);
    try {
      await syncSpanishUpdates(brand, team, seriesId, year);
      toast.success('Sync successful');
      if (reloadDraft) {
        setIsLoaded(true);
        reloadDraft();
      }
    } catch (e) {
      handleErrorResponse(e, 'Error syncing spanish data');
    }
    setIsLoaded(true);
  };

  const getLangCheckboxes = (showActionButtons: boolean) => {
    const actionBarButtons: React.ReactNode[] = [];
    if (bnpStore.allLangs.length <= 1) {
      return <></>;
    }

    const langButtons = getLangActionBarButtons(
      {
        allLangs: bnpStore.allLangs,
        selectedLangsMap: bnpStore.selectedLangsMap,
        updateSelectedLangs: bnpStore.updateSelectedLangs,
        showActionButtons,
      },
      {
        canSyncUpdates: teamStore.team.canSyncUpdates,
        seriesId,
        year,
        syncUpdates,
      },
    );
    actionBarButtons.push(...langButtons);

    return (
      <>
        {actionBarButtons.map((button, index) => (
          <React.Fragment key={index}>
            <ActionBarDivider />
            {button}
          </React.Fragment>
        ))}
      </>
    );
  };

  const selectedLangs = bnpStore.allLangs.filter(lang => bnpStore.selectedLangsMap[lang]);

  return !isLoaded ? (
    <Spinner />
  ) : (
    <>
      <ActionBar>
        <ActionBarSection>
          <SearchInput value={bnpStore.searchText} onSearch={value => (bnpStore.searchText = value)} />
          {getLangCheckboxes(!readOnly)}
        </ActionBarSection>
      </ActionBar>

      <Table fullWidth fixed>
        <Thead>
          <TableRow>
            <HeaderCell colType="bpName">Name</HeaderCell>
            <HeaderCell colType="bpCategory">Category</HeaderCell>

            {selectedLangs.map(lang => (
              <HeaderCell key={`HeaderCell-Description-${lang}`} dataTestId={`HeaderCell-Description-${lang}`}>{`${lang} Description`}</HeaderCell>
            ))}

            <HeaderCell>Applicable Models</HeaderCell>
          </TableRow>
        </Thead>

        <tbody>
          {bnpStore.filteredData?.map(categories => (
            <BuildAndPriceRow
              readOnly={readOnly}
              key={categories.uid}
              categories={categories}
              onUpdateDescription={handleUpdateDescription}
              onUpdateGradeDetail={handleUpdateGradeDetail}
              onUpdateName={handleUpdateName}
              selectedLangs={selectedLangs}
            />
          ))}
        </tbody>
      </Table>
    </>
  );
};

export default observer(BnPController);
