import { makeObservable, observable } from 'mobx';
import { Language, LanguagePermissions } from '../../models/user.model';
import { VDTab, VehicleTeam } from '../../models/vehicleData.model';

type Tab = {
  name: VDTab;
  readOnly?: boolean;
};

type Team = {
  pageTitle: string;
  param: VehicleTeam;
  name: VehicleTeam;
  allowShowSpecLinkModal: boolean;
  tabs: Tab[];
  reviewTabs: Tab[];
  reviewCLTabs: Tab[];
  reviewTeamParam: VehicleTeam;
  reviewTeamTitle: string;
  allowBnP: boolean;
  allowSplits: boolean;
  allowCommonLanguageData: boolean;
  allowDescriptionToggles: boolean;
  allowFeatureTooltips: boolean;
  allowOptionTooltips: boolean;
  allowSpecType: boolean;
  allowLinks: boolean;
  allowHexCode: boolean;
  allowCompareFeatures: boolean;
  allowAddDeleteModels: boolean;
  allowCopyModels: boolean;
  allowDeleteCopiedModels: boolean;
  allowAddDeleteSeries: boolean;
  allowEditSereisName: boolean;
  allowDisclaimerTokens: boolean;
  allowPublish: boolean;
  allowSubmit: boolean;
  allowCodeRed: boolean;
  allowEditGoLiveDate: boolean;
  allowPreviewPublishSeries: boolean;
  switchShortLongDescription: boolean;
  useOptionsPackages: boolean;
  useColorsGroups: boolean;
  useNewDashboardStatus: boolean;
  useLargeSpecApplicability: boolean;
  isHexCodeRequired: boolean;
  allowDisclaimerTokensInGradeApplicability: boolean;
  allowGradeApplicability: boolean;
  languages: Language[];
  defaultSeriesSettingsLanguage: Language;
  seriesSettingsLanguages: Language[];
  canAddNewSeriesSettings: boolean;
  langPermissions: LanguagePermissions;
  allowAddDeleteData: boolean;
  showModelDetailsForPublish: boolean;
  defaultLanguage: Language;
  changeLogLanguages: Language[];
  submitActionLabel?: 'Submit' | 'Certify';
  canAddFromDropdown: boolean;
  canSyncUpdates: boolean;
  showAcceptChanges?: boolean;
};

export const EngageTeam: Team = {
  pageTitle: 'Engage',
  param: VehicleTeam.AGENCY_TEAM,
  name: VehicleTeam.AGENCY_TEAM,
  allowShowSpecLinkModal: true,
  tabs: [
    { name: VDTab.SERIES_SETTINGS },
    { name: VDTab.MODELS },
    { name: VDTab.FEATURES },
    { name: VDTab.SPECS },
    { name: VDTab.OPTIONS },
    /* { name: VDTab.BNP, readOnly: true }, */
    { name: VDTab.COLORS },
    { name: VDTab.COMPARE_FEATURES },
  ],
  reviewTabs: [
    { name: VDTab.SERIES_SETTINGS },
    { name: VDTab.MODELS },
    { name: VDTab.FEATURES },
    { name: VDTab.SPECS },
    { name: VDTab.OPTIONS },
    /* { name: VDTab.BNP }, */
    { name: VDTab.COLORS },
    { name: VDTab.COMPARE_FEATURES },
  ],
  reviewCLTabs: [{ name: VDTab.FEATURES }, { name: VDTab.SPECS }],
  reviewTeamParam: VehicleTeam.AGENCY_TEAM,
  reviewTeamTitle: 'Agency',
  allowBnP: true,
  allowSplits: false,
  allowCompareFeatures: true,
  allowCommonLanguageData: false,
  allowDescriptionToggles: false,
  allowFeatureTooltips: false,
  allowOptionTooltips: false,
  allowSpecType: true,
  allowLinks: true,
  allowHexCode: true,
  allowAddDeleteModels: false,
  allowCopyModels: false,
  allowDeleteCopiedModels: false,
  allowAddDeleteSeries: true,
  allowEditSereisName: false,
  allowDisclaimerTokens: true,
  allowPublish: false,
  allowSubmit: true,
  allowCodeRed: false,
  allowEditGoLiveDate: true,
  allowPreviewPublishSeries: true,
  switchShortLongDescription: false,
  useOptionsPackages: false,
  useColorsGroups: false,
  useLargeSpecApplicability: false,
  useNewDashboardStatus: true,
  isHexCodeRequired: true,
  allowDisclaimerTokensInGradeApplicability: false,
  allowGradeApplicability: false,
  languages: [Language.EN],
  defaultSeriesSettingsLanguage: Language.EN,
  seriesSettingsLanguages: [Language.EN],
  canAddNewSeriesSettings: false,
  langPermissions: {},
  allowAddDeleteData: true,
  showModelDetailsForPublish: false,
  defaultLanguage: Language.EN,
  changeLogLanguages: [Language.EN],
  canAddFromDropdown: true,
  canSyncUpdates: true,
  submitActionLabel: 'Certify',
};

export class TeamStore {
  team = {} as Team;

  setTeam = (team: VehicleTeam, brand: string, langPermissions: LanguagePermissions) => {
    this.team = EngageTeam;

    // update langPermissions
    this.team.languages.forEach(language => {
      this.team.langPermissions[language] = langPermissions[language];
    });
    // reset EN for AgencySpanish
    if (team === VehicleTeam.AGENCY_SPANISH) {
      this.team.langPermissions[Language.EN] = { canView: true };
    }
  };

  constructor() {
    makeObservable(this, {
      team: observable,
    });
  }
}
