import cx from 'clsx';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Input } from 'vapi-ui-common';
import useStores from '../../../../../../../../hooks/useStores';
import { VehicleModelItem, VehicleModelToyota } from '../../../../../../../../models/vehicleModel.model';
import styles from '../../../../modelsModal.module.scss';
import Label from '../Label';

interface TrimDescriptionProps {
  vmId: string;
  model: VehicleModelItem<VehicleModelToyota>;
}

const TrimDescription = observer(({ vmId, model }: TrimDescriptionProps) => {
  const {
    modelTabStore: { languages: checkboxLanguages, readOnly, getDescription, setDescription, getActiveLanguagesAmount, getLangVehicleModel },
    userStore: { langPermissions },
    teamStore: {
      team: { languages: teamLanguages },
    },
  } = useStores();

  return (
    <div className={cx(getActiveLanguagesAmount() > 1 ? styles.longer : '')}>
      <Label htmlFor={`amm-description-${vmId}`}>Trim Description</Label>
      <div className={cx(styles.content)}>
        {teamLanguages.map(language => {
          if (checkboxLanguages[language].selected && langPermissions[language]?.canView) {
            let changedAttributes: string[] = [];
            if (langPermissions[language]?.canEdit) {
              changedAttributes = getLangVehicleModel(model, language).getVal('changedAttributes') ?? [];
            }
            return (
              <Input
                required
                id={`amm-description-${vmId}-${language}`}
                key={`amm-description-${vmId}-${language}`}
                value={getDescription(vmId, language)}
                disabled={!langPermissions[language]?.canEdit || readOnly}
                onChange={e => setDescription(vmId, language, e.currentTarget.value)}
                placeholder={checkboxLanguages[language].description}
                className={changedAttributes.includes('description') ? styles.errorText : ''}
              />
            );
          }

          return null;
        })}
      </div>
    </div>
  );
});

export default TrimDescription;
