import { observer } from 'mobx-react-lite';
import React, { ReactNode, useState } from 'react';
import {
  ConfirmModal,
  convertToRichTextObject,
  DateComponent,
  MultiLineRTEditor,
} from 'vapi-ui-common';
import IconTextButton from '../../components/IconTextButton';
import Notes from '../../components/Notes';
import Popover from '../../components/Popover';
import { DescriptionText, TableCell, TableRow } from '../../components/Table';
import { ChangeLogItem } from '../../models/changeLog.model';
import ChangeLogChanges from './ChangeLogChanges';

interface ChangeLogRowProps {
  item: ChangeLogItem;
  module: string;
  onRevert: (item: ChangeLogItem) => void;
  customDescription?: ReactNode;
}

const ChangeLogRow = ({ item, module, onRevert, customDescription }: ChangeLogRowProps) => {
  const [openRevertModal, setOpenRevertModal] = useState(false);
  const descriptionText = convertToRichTextObject(item.description).text;
  const nameText = convertToRichTextObject(item.name).text;

  const revertChangeText = () => {
    if (!descriptionText && !nameText) {
      return 'Are you sure you want to revert the change?';
    }
    return `Are you sure you want to revert the change for "${
      descriptionText ? descriptionText : nameText
    }"?`;
  };

  return (
    <TableRow zebra key={item.changeLogId}>
      <TableCell large>
        {customDescription ? (
          <DescriptionText size="lg">{customDescription}</DescriptionText>
        ) : (
          <MultiLineRTEditor
            value={
              item.description ? item.description : item.name ? item.name : item.shortDescription
            }
            disabled
          />
        )}
      </TableCell>
      <TableCell large>
        {item.changeType.includes('Parent ID') ? 'Compare Feature' : item.changeType}
      </TableCell>
      <TableCell large>
        <DescriptionText size="lg">
          <ChangeLogChanges changeItem={item} />
        </DescriptionText>
      </TableCell>
      <TableCell large>{item.modifiedBy}</TableCell>
      <TableCell large>
        <DateComponent format="MM/DD/YYYY hh:mm:ss A">{item.modifiedDate}</DateComponent>
      </TableCell>
      <TableCell large>
        {item.notes && (
          <Popover
            toggleElement={<IconTextButton smallIcon icon="circle" text="Notes" />}
            popoverElement={<Notes readOnly notes={item.notes} />}
            align="right"
          />
        )}
      </TableCell>
      <TableCell large>
        {item.canRevert && (
          <IconTextButton icon="undo" text="Revert" onClick={() => setOpenRevertModal(true)} />
        )}
        <ConfirmModal
          open={openRevertModal}
          headerText={`Revert ${module}`}
          bodyText={revertChangeText()}
          onClose={() => setOpenRevertModal(false)}
          onConfirm={() => onRevert(item)}
        />
      </TableCell>
    </TableRow>
  );
};

export default observer(ChangeLogRow);
