import React, { CSSProperties } from 'react';
import { TableTab } from './TableTab';
import styles from './tableTab.module.scss';

interface TableTabsProps {
  tabs: string[];
  currentTab: string;
  onSelectTab: (value: string) => void;
  style?: CSSProperties;
}

export const TableTabs = ({ tabs, currentTab, onSelectTab, style = {} }: TableTabsProps) => {
  return (
    <section className={styles.tableTabs} style={style}>
      {tabs.map(tab => (
        <TableTab key={tab} tab={tab} isSelected={currentTab === tab} onSelectTab={onSelectTab} />
      ))}
    </section>
  );
};
