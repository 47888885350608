import { processRteForChangeLog } from 'vapi-ui-common';
import { VehicleSpecsResponse } from '../gql/generated';
import { ChangeLogTypes } from '../models/changeLog.model';
import { KeyValueType } from '../models/common.model';
import { CompareFeatureItem } from '../models/compareFeatures.model';
import { RefItem } from '../models/refItem.model';
import { ReviewChangeResponse } from '../models/review.model';
import { SpecItem, SpecResponse, SpecReviewType, SpecsChangeTypeMap, SpecSettings, SpecsModel, SpecsReviewItem, SpecsReviewMap, SpecsReviewResponse } from '../models/specs.model';
import { VehicleModelItem, VehicleModelToyota } from '../models/vehicleModel.model';
import { changeLogModelApplicabilityItemMapper } from './changeLogUtils';
import { modelApplicabilityGQLToModelApplicability } from './featuresUtils';
import { getDefaultChangeTypeMap, handleOtherChangesForReview } from './reviewUtils';

export const vehicleSpecsResponseToSpecResponse = (vehicleSpecsResponse?: VehicleSpecsResponse): SpecResponse[] =>
  vehicleSpecsResponse?.vehicleSpecs?.map(spec => ({
    id: spec.id,
    revId: spec.revId,
    comLangId: spec.comLangId as string,
    categoryId: spec.categoryId as string,
    category: spec.category,
    specTypeId: spec.specTypeId as string,
    specType: spec.specType,
    description: spec.description,
    notes: spec.notes as string,
    rejectNotes: spec.rejectNotes,
    link: spec.link,
    isInProgress: spec.isInProgress as boolean,
    modelApplicability: modelApplicabilityGQLToModelApplicability(spec.modelApplicability ?? []),
    isDeleted: spec.isDeleted,
    isRequired: spec.isRequired,
    compareFeatureId: spec.compareFeatureId,
    changedAttributes: spec.changedAttributes ?? [],
    isTDPR: spec.isTDPR as boolean,
    isUSVI: spec.isUSVI as boolean,
    fromTMNA: spec.fromTMNA as boolean,
  })) ?? [];

// Specs Vehicle Data
export const specsXForm = (
  data: SpecResponse[],
  vehicleModels: VehicleModelItem<VehicleModelToyota>[],
  categories: RefItem[],
  specTypes: RefItem[],
  compareFeatureMap: KeyValueType<CompareFeatureItem>,
) => {
  return data.map((item, index) => specItemXForm(item, vehicleModels, categories, specTypes, index, compareFeatureMap));
};

export const specItemXForm = (
  item: SpecResponse,
  vehicleModels: VehicleModelItem<VehicleModelToyota>[],
  categories: RefItem[],
  specTypes: RefItem[],
  index: number,
  compareFeatureMap: KeyValueType<CompareFeatureItem>,
) => {
  const modelsMap: KeyValueType<SpecsModel> = {};
  vehicleModels.forEach(mdl => {
    modelsMap[mdl.id] = {
      id: mdl.id,
      setting: item.modelApplicability ? item.modelApplicability[mdl.id] : SpecSettings.UNDEFINED,
    };
  });

  const category = categories.filter(cat => cat.id === item.categoryId);
  const specType = specTypes.filter(type => type.id === item.specTypeId);
  const specItem = new SpecItem(item);
  specItem.category = category.length ? category[0] : specItem.category;
  specItem.specType = specType.length ? specType[0] : specItem.specType;
  specItem.modelsMap = modelsMap;
  specItem.sortOrder = index + 1;
  if (compareFeatureMap[specItem.id]) {
    specItem.compareFeatureId = compareFeatureMap[specItem.id].id;
  }
  return specItem;
};

const getItemCategory = (categories: RefItem[], item: SpecsReviewResponse) => {
  const category = categories.filter(cat => cat.id === item.categoryId);

  if (!category) {
    const itemCategory: string = getItemValueOrDefault(item, 'category');
    const refItem = new RefItem();
    refItem.id = itemCategory;
    refItem.value = itemCategory;
    return [refItem];
  }

  return category;
};

const getItemSpecType = (specTypes: RefItem[], item: SpecsReviewResponse) => {
  const specType = specTypes.filter(cat => cat.id === item.specTypeId);

  if (!specType) {
    const itemSpecType: string = getItemValueOrDefault(item, 'specType');
    const refItem = new RefItem();
    refItem.id = itemSpecType;
    refItem.value = itemSpecType;
    return [refItem];
  }

  return specType;
};

const vmToSpecModels = (vehicleModels: VehicleModelItem<VehicleModelToyota>[], item: SpecsReviewResponse) => {
  return vehicleModels.map(mdl => ({
    id: mdl.id,
    setting: item.modelApplicability ? item.modelApplicability[mdl.id] : SpecSettings.UNDEFINED,
  }));
};

const vmToModelApplicabilityMap = (vehicleModels: VehicleModelItem<VehicleModelToyota>[], item: SpecsReviewResponse) => {
  const app: KeyValueType<SpecSettings> = {};

  vehicleModels.forEach(model => {
    app[model.id] = item.modelApplicability ? item.modelApplicability[model.id] : SpecSettings.UNDEFINED;
  });

  return app;
};

const handleSpecItemChanges = (
  vehicleModels: VehicleModelItem<VehicleModelToyota>[],
  categories: RefItem[],
  specTypes: RefItem[],
  specItem: SpecsReviewItem,
  change: ReviewChangeResponse,
) => {
  switch (change.changeType) {
    case ChangeLogTypes.SPEC_TYPE:
    case ChangeLogTypes.CATEGORY: {
      specItem.changes.beforeValue = change.before as string;
      specItem.changes.afterValue = change.after as string;
      break;
    }
  }

  if (specItem.otherChanges) {
    handleOtherChangesForReview(specItem.otherChanges, [
      { type: ChangeLogTypes.CATEGORY, refItems: categories },
      { type: ChangeLogTypes.SPEC_TYPE, refItems: specTypes },
    ]);

    specItem.otherChanges.forEach(ocItem => changeLogModelApplicabilityItemMapper(vehicleModels, ocItem.changes));
  }

  // map model applicability changes
  changeLogModelApplicabilityItemMapper(vehicleModels, specItem.changes);
};

const addItemToReviewMap = (map: SpecsReviewMap, item: SpecsReviewResponse, vehicleModels: VehicleModelItem<VehicleModelToyota>[], category: RefItem, specType: RefItem) => {
  const app: KeyValueType<SpecSettings> = vmToModelApplicabilityMap(vehicleModels, item);

  if (!map[item.id]) {
    map[item.id] = getDefaultChangeTypeMap(item, app) as SpecsChangeTypeMap;
  }

  map[item.id].category = {
    before: '',
    after: category?.value ?? '',
    hasChanged: false,
  };

  map[item.id].specType = {
    before: '',
    after: specType?.value ?? '',
    hasChanged: false,
  };
};

const setIsDeletedChangeLogReviewInfo = (changeLogKey: SpecReviewType | undefined, map: SpecsReviewMap, item: SpecsReviewResponse) => {
  if (changeLogKey === 'deleted') {
    map[item.id].isDeleted = true;
    map[item.id].category.before = map[item.id].category.after;
    map[item.id].category.after = '';
    map[item.id].category.hasChanged = true;

    map[item.id].specType.before = map[item.id].specType.after;
    map[item.id].specType.after = '';
    map[item.id].specType.hasChanged = true;

    map[item.id].description.before = map[item.id].description.after;
    map[item.id].description.after = '';
    map[item.id].description.hasChanged = true;

    map[item.id].modelApplicability.before = map[item.id].modelApplicability.after;
    map[item.id].modelApplicability.after = {};
    map[item.id].modelApplicability.hasChanged = true;
  }
};

const setIsNewChangeLogReviewInfo = (changeLogKey: SpecReviewType | undefined, map: SpecsReviewMap, item: SpecsReviewResponse) => {
  if (changeLogKey === 'added') {
    map[item.id].category.hasChanged = true;
    map[item.id].specType.hasChanged = true;
    map[item.id].description.hasChanged = true;
    map[item.id].modelApplicability.hasChanged = true;
    map[item.id].isNew = true;
  }
};

const setChangeLogReviewInfo = (
  changeLogKey: SpecReviewType | undefined,
  map: SpecsReviewMap,
  item: SpecsReviewResponse,
  change: ReviewChangeResponse,
  specItem: SpecsReviewItem,
  vehicleModels: VehicleModelItem<VehicleModelToyota>[],
) => {
  if (changeLogKey === 'category' || changeLogKey === 'specType' || changeLogKey === 'description' || changeLogKey === 'modelApplicability') {
    if (changeLogKey === 'description') {
      map[item.id][changeLogKey].before = processRteForChangeLog(specItem.changes.before);
      map[item.id][changeLogKey].after = processRteForChangeLog(specItem.changes.after);
    } else if (changeLogKey === 'modelApplicability') {
      const beforeModelApp: KeyValueType<SpecSettings> = {};
      const afterModelApp: KeyValueType<SpecSettings> = {};
      vehicleModels.forEach(mdl => {
        const id = mdl.id;
        if (id) {
          const before = change.before ? (change.before as KeyValueType<string>) : {};
          const beforeVal = before && before[id] ? (before[id] as SpecSettings.UNDEFINED) : SpecSettings.UNDEFINED;
          beforeModelApp[id] = beforeVal;

          const after = change.after ? (change.after as KeyValueType<string>) : {};
          const afterVal = after && after[id] ? (after[id] as SpecSettings.UNDEFINED) : SpecSettings.UNDEFINED;
          afterModelApp[id] = afterVal;
        }
      });
      map[item.id][changeLogKey].before = beforeModelApp;
      map[item.id][changeLogKey].after = afterModelApp;
    } else {
      map[item.id][changeLogKey].before = specItem.changes.before;
      map[item.id][changeLogKey].after = specItem.changes.after;
    }
    map[item.id][changeLogKey].hasChanged = true;
  }
};

export const specsReviewXForm = (items: SpecsReviewResponse[], vehicleModels: VehicleModelItem<VehicleModelToyota>[], categories: RefItem[], specTypes: RefItem[]) => {
  const map: SpecsReviewMap = {};
  const specItems: SpecsReviewItem[] = [];

  items.forEach(item => {
    const category = getItemCategory(categories, item);
    const specType = getItemSpecType(specTypes, item);
    const models: SpecsModel[] = vmToSpecModels(vehicleModels, item);

    addItemToReviewMap(map, item, vehicleModels, category[0], specType[0]);

    let isApplied = true;
    let rejectNotes = '';

    Object.entries(item.changes).forEach(([key, change]) => {
      const specItem = new SpecsReviewItem({
        spec: item,
        change,
        category: category[0],
        specType: specType[0],
        models,
        changeTypeId: key,
      });

      handleSpecItemChanges(vehicleModels, categories, specTypes, specItem, change);
      specItems.push(specItem);

      isApplied = isApplied && change.isApplied;
      rejectNotes = change.rejectNotes || rejectNotes;

      const changeLogKey = getKeyFromChangeLogType(change.changeType);
      setIsDeletedChangeLogReviewInfo(changeLogKey, map, item);
      setIsNewChangeLogReviewInfo(changeLogKey, map, item);
      setChangeLogReviewInfo(changeLogKey, map, item, change, specItem, vehicleModels);
    });

    map[item.id].isApplied = isApplied;
    map[item.id].rejectNotes = rejectNotes;
  });

  return { map, items: specItems };
};

const getKeyFromChangeLogType = (type: ChangeLogTypes): SpecReviewType | undefined => {
  switch (type) {
    case ChangeLogTypes.CATEGORY:
      return 'category';
    case ChangeLogTypes.SPEC_TYPE:
      return 'specType';
    case ChangeLogTypes.DESCRIPTION:
      return 'description';
    case ChangeLogTypes.MODEL_APPLICABILITY:
      return 'modelApplicability';
    case ChangeLogTypes.SPEC_ADDED:
      return 'added';
    case ChangeLogTypes.SPEC_DELETED:
      return 'deleted';
  }
  return undefined;
};

const getItemValueOrDefault = (item: SpecsReviewResponse, key: 'category' | 'specType') => {
  const val = item[key];
  return val ? val : '';
};

export const mapEmptySpecModels = (vehicleModels: VehicleModelItem<VehicleModelToyota>[]): KeyValueType<SpecsModel> => {
  const map: KeyValueType<SpecsModel> = {};
  vehicleModels.forEach(mdl => {
    map[mdl.id] = {
      id: mdl.id,
      setting: SpecSettings.UNDEFINED,
    };
  });
  return map;
};
