import { VDStatus as VDStatusVDConstants, bnpToModelKeyMap } from '../constants/vehicleData/VDConstants';
import { GetDashboardQuery, SeriesItem, SeriesModelYearVersionResponse } from '../gql/generated';
import { ModelApplicability, ModelApplicabilityMap, ModelApplicabilityMetadata, ModelApplicabilityMetadataMap } from '../hooks/useModelApplicability';
import { BnPCategories, BnPCategoriesMap, BnPCategoryItem, BnPItemsResponse, BnPItemsResponseItem } from '../models/buildAndPrice.model';
import { IDValueType, KeyValueType } from '../models/common.model';
import { RefItem } from '../models/refItem.model';
import { BRAND_LEXUS, Language, UserVDPermissions } from '../models/user.model';
import {
  DashboardDetailYear,
  Series,
  SeriesCategories,
  SeriesInfo,
  SeriesInfoItem,
  VDStatus,
  VehicleCategories,
  VehicleDataVersionInfo,
  VehicleTeam,
} from '../models/vehicleData.model';
import { ModelLangMap, VehicleModelItem, VehicleModelToyota } from '../models/vehicleModel.model';

const { CROSSOVERS, LARGE_SEDANS_VANS, SEDANS_SPORTS_CARS, TRUCKS_SUVS } = SeriesCategories;

export const getStatusAndVersionGqlReq = (version?: string) => {
  let status;
  let mVersion;

  if (version === 'DRAFT' || !version) {
    status = VDStatusVDConstants.DRAFT.toUpperCase();
  } else if (version === 'ARCHIVE') {
    status = VDStatusVDConstants.ARCHIVE.toUpperCase();
  } else if (version === 'PUBLISHED') {
    status = VDStatusVDConstants.PUBLISHED.toUpperCase();
  } else {
    mVersion = Number(version);
  }

  return {
    status,
    version: mVersion,
  };
};

export const seriesItemToSeriesInfo = (seriesItem: SeriesItem): SeriesInfo => ({
  id: seriesItem.id,
  revId: seriesItem.revId,
  name: seriesItem.name,
  group: seriesItem.group as any,
  sourceSeriesId: seriesItem.sourceSeriesId ?? undefined,
  sourceBrand: seriesItem.sourceBrand ?? undefined,
});

const seriesModelYearVersionToDashboardDetailYear = (version: SeriesModelYearVersionResponse): DashboardDetailYear => {
  const modelYear: DashboardDetailYear = {
    year: version.year,
    isDraft: version.isDraft,
    publishDownstreamVersion: version.publishDownstreamVersion ?? undefined,
    publishDownstreamDate: version.publishDownstreamDate ?? undefined,
    previewDownstreamDate: version.previewDownstreamDate ?? undefined,
    previewDownstreamVersion: (version.previewDownstreamVersion as any) ?? undefined,
    skippedLivePublish: version.skippedLivePublish ?? undefined,

    datePublished: '',
    version: -1,
  };

  if (version.vdStatus) {
    modelYear.vdStatus = {
      currentStatus: version.vdStatus?.currentStatus as any,
      modifiedDate: version.vdStatus?.modifiedDate ?? '',
      modifiedUser: version.vdStatus?.modifiedBy ?? '',
    };
  }

  if (version.__typename === 'SeriesModelYearVersion') {
    modelYear.datePublished = version.datePublished;
    modelYear.createdDate = version.createdDate ?? undefined;
    modelYear.version = version.version ?? -1;
    modelYear.isPendingVDReview = version.isPendingVDReview ?? undefined;
    modelYear.isPendingCLReview = version.isPendingCLReview ?? undefined;
    modelYear.isSubmitted = version.isSubmitted ?? undefined;
  }

  if (version.__typename === 'SeriesModelYearVersionSpanish') {
    modelYear.englishVersion = version.englishVersion;
    modelYear.englishPublishedDate = version.englishPublishedDate;
    modelYear.spanishVersion = version.spanishVersion ?? undefined;
    modelYear.spanishPublishedDate = version.spanishPublishedDate ?? undefined;
    modelYear.key = version.key;
    modelYear.sourceVersion = version.sourceVersion ?? undefined;
    modelYear.sourceLang = version.sourceLang ?? undefined;
    modelYear.outOfSync = version.outOfSync ?? undefined;
    modelYear.hasSourceUpdates = {};
  }

  return modelYear;
};

export const dashboardXForm = (getDashboardQuery: GetDashboardQuery, series: Series, teamModule: UserVDPermissions, team?: VehicleTeam) => {
  const seriesInfoList: SeriesInfoItem[] = [];
  const data = getDashboardQuery.dashboard?.series ?? [];

  data.forEach(({ seriesId, name, years }) => {
    const item: SeriesInfoItem = {
      id: seriesId,
      seriesName: name,
      permissions: {},
      modelYears: [],
    };

    // set permissions
    const seriesPermissions = teamModule.series[series[seriesId].group];
    if (seriesPermissions) {
      item.permissions.canEdit = seriesPermissions.canEdit;
    }

    years?.forEach(yearDetail => {
      const versions = yearDetail?.versions ?? [];
      versions.forEach(version => {
        const modelYear: DashboardDetailYear = seriesModelYearVersionToDashboardDetailYear(version);
        // add notes
        if (modelYear.isPendingCLReview || modelYear.isPendingVDReview) {
          modelYear.notes = 'A review is pending';
        } else if (modelYear.isSubmitted) {
          // modelYear.notes = 'Draft is submitted for review';
        }
        if (team === VehicleTeam.AGENCY_TEAM && !modelYear.publishDownstreamDate && modelYear.vdStatus && modelYear.vdStatus.currentStatus === VDStatus.APPROVED) {
          modelYear.datePublished = '';
        }
        if (modelYear.hasSourceUpdates && (modelYear.hasSourceUpdates.en || modelYear.hasSourceUpdates.EN)) {
          if (modelYear.englishPublishedDate) {
            modelYear.notes = `Approved English Version - ${new Date(modelYear.englishPublishedDate).toLocaleDateString()}`;
          } else {
            modelYear.notes = 'Approved English Version';
          }
        }

        item.modelYears.push(modelYear);
      });
    });
    seriesInfoList.push(item);
  });

  return seriesInfoList;
};

export const getEditableVehicleGroups = (teamModule: UserVDPermissions, brand: string) => {
  const groups = [];
  if (teamModule.series[LARGE_SEDANS_VANS].canEdit) {
    groups.push(VehicleCategories.LARGE_SEDANS_VANS);
  }
  if (teamModule.series[SEDANS_SPORTS_CARS].canEdit) {
    groups.push(VehicleCategories.SEDANS_SPORTS_CARS);
  }
  if (teamModule.series[CROSSOVERS].canEdit) {
    groups.push(VehicleCategories.CROSSOVERS);
  }
  if (teamModule.series[TRUCKS_SUVS].canEdit) {
    groups.push(VehicleCategories.TRUCKS_SUVS);
  }

  return groups;
};

export const mapEmptyModels = (vehicleModels: VehicleModelItem<VehicleModelToyota>[]) => {
  return vehicleModels.map(mdl => ({
    id: mdl.id,
    setting: '',
  }));
};

export const mapEmptyValueModels = (vehicleModels: VehicleModelItem<VehicleModelToyota>[]) => {
  return vehicleModels.map(mdl => new IDValueType(mdl.id, ''));
};

export const bnpItemMapXForm = (bnpItemsResponse: BnPItemsResponse, categoriesMap: BnPCategoriesMap, modelLangMap: ModelLangMap, lang: string, grades: RefItem[]) => {
  const categories = bnpItemXForm(bnpItemsResponse, grades);
  categories.forEach(cat => {
    if (!categoriesMap[cat.name]) {
      // if the bnp category (e.g. drive, transmission...) does not exist in the category map yet then add it
      categoriesMap[cat.name] = cat;
    }
    const langMap = modelLangMap[cat.name]; // e.g. if cat.name === transmission then langMap could be { 'FWD###ES###AWD': {EN: FWD, ES: AWD} }
    Object.keys(langMap).forEach(fullCategoryId => {
      let defaultCategoryItem: BnPCategoryItem | undefined;
      if (!categoriesMap[cat.name].categoryItemMap[fullCategoryId]) {
        categoriesMap[cat.name].categoryItemMap[fullCategoryId] = {};
      } else {
        const catItemLangMap = categoriesMap[cat.name].categoryItemMap[fullCategoryId];
        let defaultLang = !!catItemLangMap[Language.EN] ? Language.EN : (Object.keys(catItemLangMap)[0] as Language); // most likely will always be english; however if the english categoryItem does not exist then use the first language that appears
        defaultCategoryItem = catItemLangMap[defaultLang]; // set the default categoryItem equal to the categoryItem for the defaultLang
      }
      // go through all the category items and try to find the item with the categoryId equal to value listed in the langMap
      const categoryItem: BnPCategoryItem | undefined = cat.items.find(catItem => catItem.categoryId === (lang === Language.EN ? langMap[fullCategoryId][lang] : fullCategoryId));

      if (categoryItem) {
        // if you found the item then add it to the category item map
        categoriesMap[cat.name].categoryItemMap[fullCategoryId][lang] = categoryItem;
      } else {
        // else create a new category item with a value equal to the current english value
        categoriesMap[cat.name].categoryItemMap[fullCategoryId][lang] = new BnPCategoryItem(
          cat.name,
          cat.label,
          fullCategoryId,
          grades.find(grade => grade.id === fullCategoryId)?.value || fullCategoryId,
          '',
          '',
          '',
          [],
          defaultCategoryItem?.applicability,
          defaultCategoryItem?.splitsMap,
        );
      }
    });
  });
  return categoriesMap;
};

const bnpItemXForm = (bnpItemsResponse: BnPItemsResponse, grades: RefItem[]): BnPCategories[] => {
  const categories: BnPCategories[] = [];

  Object.keys(bnpItemsResponse).forEach(name => {
    switch (name) {
      case 'cabs':
        return bnpResponseHelper(categories, 'cabs', bnpItemsResponse.cabs);
      case 'beds':
        return bnpResponseHelper(categories, 'beds', bnpItemsResponse.beds);
      case 'drive':
        return bnpResponseHelper(categories, 'drive', bnpItemsResponse.drive);
      case 'engine':
        return bnpResponseHelper(categories, 'engine', bnpItemsResponse.engine);
      case 'grade':
        return bnpResponseHelper(categories, 'grade', bnpItemsResponse.grade, grades);
      case 'seats':
        return bnpResponseHelper(categories, 'seats', bnpItemsResponse.seats);
      case 'transmission':
        return bnpResponseHelper(categories, 'transmission', bnpItemsResponse.transmission);
      default:
        return;
    }
  });

  categories.sort((a, b) => {
    return a.sortOrder - b.sortOrder;
  });

  return categories;
};

const xformPropDetail = (item: BnPItemsResponseItem, id: string, prop: string): string | string[] | null => {
  let detail: any = null;
  Object.entries(item.categories).forEach(catEntry => {
    const [catKey, val] = catEntry;
    val &&
      catKey &&
      catKey === id &&
      Object.entries(val).forEach(entry => {
        const [key, value] = entry;
        if (key === prop) {
          detail = value;
        }
      });
  });

  return detail;
};

const bnpResponseHelper = (categories: BnPCategories[], bnpType: string, item: BnPItemsResponseItem, grades?: RefItem[]) => {
  const items = Object.keys(item.categories).map(categoryId => {
    const category = item.categories[categoryId];
    const categoryValue = category?.categoryValue || categoryId;
    return new BnPCategoryItem(
      bnpType,
      item.label,
      categoryId,
      grades ? grades.find(grade => grade.id === categoryId)?.value || categoryValue : categoryValue,
      xformPropDetail(item, categoryId, 'description') as string,
      xformPropDetail(item, categoryId, 'title') as string,
      xformPropDetail(item, categoryId, 'copy') as string,
      xformPropDetail(item, categoryId, 'changedAttributes') as string[], // why is xformpropdetail used????
      category?.applicability,
      category?.splits,
      category?.rejectNotes,
    );
  });

  if (items.length > 0) {
    categories.push(new BnPCategories(bnpType, item, items));
  }
};

export const getSortModels = (vehicleModels: VehicleModelItem<VehicleModelToyota>[]) =>
  vehicleModels.map(({ id, revId, sortOrder }) => ({
    id,
    revId,
    sortOrder,
  }));

export const getSeriesCategories = (brand: string) => {
  if (brand === BRAND_LEXUS) {
    return {
      SEDANS_SPORTS_CARS,
      TRUCKS_SUVS,
    };
  }
  return SeriesCategories;
};

export const getValidSeriesCategories = (brand: string) => {
  if (brand === BRAND_LEXUS) {
    return [SEDANS_SPORTS_CARS, TRUCKS_SUVS];
  }
  return [CROSSOVERS, LARGE_SEDANS_VANS, SEDANS_SPORTS_CARS, TRUCKS_SUVS];
};

export const processVDVersion = (version?: string) => {
  return !version ? undefined : version.toLowerCase() === 'draft' ? undefined : version;
};

export const getParamsForVersionInfo = (team: VehicleTeam, languages: Language[], dashboardRowItem: DashboardDetailYear) => {
  // Update this later to use the response from BE
  const versionInfo: VehicleDataVersionInfo = {};
  if (team === VehicleTeam.AGENCY_SPANISH) {
    versionInfo[Language.EN] = dashboardRowItem.sourceVersion;
    versionInfo[Language.ES] = dashboardRowItem.isDraft ? 'DRAFT' : dashboardRowItem.spanishVersion;
  } else {
    // at this point for all the other teams: Product Team, Agency Team
    languages.forEach(lang => {
      versionInfo[lang] = dashboardRowItem.isDraft ? 'DRAFT' : dashboardRowItem.version;
    });
  }

  let versionInfoParams: string = '';
  if (Object.keys(versionInfo)) {
    versionInfoParams = Object.entries(versionInfo)
      .map(([lang, version]) => `${lang}:${version}`)
      .join('|');
  }

  return { versionInfoParams, versionInfo };
};

export const getVersionInfoFromParams = (params: string): VehicleDataVersionInfo => {
  const versionInfo: VehicleDataVersionInfo = {};
  // expecting params to be in format EN:10|ES:5
  const languages: string[] = params.split('|');

  for (const lang of languages) {
    const langSplit = lang.split(':');
    versionInfo[langSplit[0] as Language] = langSplit[1];
  }

  return versionInfo;
};

export const filterVersionInfoByLang = (languages: Language[], versionInfo: VehicleDataVersionInfo): VehicleDataVersionInfo => {
  const filteredVersionInfo: VehicleDataVersionInfo = {};

  for (const lang of languages) {
    filteredVersionInfo[lang] = versionInfo[lang];
  }

  return filteredVersionInfo;
};

const getDefaultModelApplicabilityMetadata = (modelApplicability: ModelApplicability): ModelApplicabilityMetadata => {
  return {
    modelApplicability,
    includedModels: [],
    excludedModels: [],
  };
};

/**
 * This transforms a ModelApplicabilityMap to a list of ModelApplicabilityMetadata. For each category value per bnp item (e.g. FWD is the category value and the bnp item is transmission), this collects all models that have FWD as their transmission.
 * @param modelApplicabilityMap
 * @param category
 * @param vehicleModels
 * @param splitIds id of split that we should check; optional field that is only used in useSplitModelApplicability
 * @param checkModelValue if you want to filter out what models to include if not using splitIds to check (only used for bnp review)
 * @returns ModelApplicabilityMetadataMap
 */
export const getModelApplicabilityMetadataMap = (
  modelApplicabilityMap: ModelApplicabilityMap,
  category: {
    applicability: KeyValueType<string>;
    categoryValue: string;
    name: string;
  },
  vehicleModels: VehicleModelItem<VehicleModelToyota>[],
  splitIds: string[] = [],
  checkModelValue: boolean = false,
): ModelApplicabilityMetadataMap => {
  const modelApplicabilityMetadata: ModelApplicabilityMetadataMap = {};
  Object.entries(modelApplicabilityMap).forEach(([gradeId, value]) => {
    modelApplicabilityMetadata[gradeId] = getDefaultModelApplicabilityMetadata(value);
  });

  const modelKey = bnpToModelKeyMap[category.name];
  const categoryValue = category.categoryValue;

  let models = vehicleModels.filter(mdl => !!category.applicability[mdl.id]);

  for (const model of models) {
    const gradeId = model.getVal('grade').id;
    if (!modelApplicabilityMetadata[gradeId]) {
      // if the model's grade doesnt exist in the model applicability map then we skip the model
      continue;
    }

    if (
      (!checkModelValue || model.getVal(modelKey) === categoryValue) &&
      (!splitIds.length || !category.applicability[model.id] || splitIds.includes(category.applicability[model.id]))
    ) {
      // if we are skipping category applicability check OR the model's applicability doesnt exist OR its applicability is included in the specified splitIds then add the model to the included models for this grade
      modelApplicabilityMetadata[gradeId].includedModels.push(modelApplicabilityMap[gradeId].models[model.id]);
    } else {
      modelApplicabilityMetadata[gradeId].excludedModels.push(modelApplicabilityMap[gradeId].models[model.id]);
    }
  }
  // now delete any entries that do not have any included models
  const gradeIds: string[] = Object.keys(modelApplicabilityMetadata);
  for (const gradeId of gradeIds) {
    if (!modelApplicabilityMetadata[gradeId].includedModels.length) {
      delete modelApplicabilityMetadata[gradeId];
    } else {
      modelApplicabilityMetadata[gradeId].modelApplicability.models = {};
      for (const model of modelApplicabilityMetadata[gradeId].includedModels) {
        modelApplicabilityMetadata[gradeId].modelApplicability.models[model.model.id] = model;
      }
    }
  }
  return modelApplicabilityMetadata;
};
