import { action, makeObservable, observable } from 'mobx';
import { ColorsReviewResponse } from '../../../models/colors.model';
import { OptionsReviewResponse } from '../../../models/options.model';
import { ReviewProps } from '../../../models/review.model';
import { BRAND_TOYOTA } from '../../../models/user.model';
import { getReviewExteriorColors, getReviewInteriorColors } from '../../../webservices/vehicleColorsApi';
import { getReviewCompareFeatures } from '../../../webservices/vehicleCompareFeaturesApi';
import { getReviewFeatures } from '../../../webservices/vehicleFeaturesApi';
import { getReviewModels2, getReviewSeriesSettings } from '../../../webservices/vehicleModelsApi';
import { getReviewOptions } from '../../../webservices/vehicleOptionsApi';
import { getReviewSpec } from '../../../webservices/vehicleSpecsApi';

class ReviewStore {
  selectedTab = 'Features';
  hideAccept = false;

  constructor() {
    makeObservable(this, {
      selectedTab: observable,
      hideAccept: observable,
      fetchData: action,
    });
  }

  async fetchData(props: ReviewProps) {
    const { brand, team, series, year, version } = props;
    const responses = await Promise.all([
      getReviewModels2(brand, team, series, year, version),
      getReviewFeatures(brand, team, series, year, version),
      getReviewSpec(brand, team, series, year, version),
      getReviewExteriorColors<ColorsReviewResponse>(brand, team, series, year, version),
      getReviewInteriorColors<ColorsReviewResponse>(brand, team, series, year, version),
      getReviewCompareFeatures(brand, team, series, year, version),
      getReviewSeriesSettings(brand, team, series, year, version),
    ]);

    const optionsResponse = await Promise.all([getReviewOptions<OptionsReviewResponse>(brand, team, series, year, version)]);

    return {
      models: responses[0].data,
      features: responses[1].data,
      specs: responses[2].data,
      options: brand === BRAND_TOYOTA ? optionsResponse[0].data : [],
      extColors: responses[3].data,
      intColors: responses[4].data,
      compareFeatures: responses[5].data,
      seriesSettings: responses[6].data,
    };
  }
}

export default ReviewStore;
