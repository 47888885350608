import { useEffect, useRef, useState } from 'react';

const useComponentVisible = (initialIsVisible: boolean) => {
  const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible);
  const ref: any = useRef(null);
  let internalDrag = false;

  const handleMouseDown = (event: Event) => {
    internalDrag = ref.current && ref.current.contains(event.target);
  };

  const handleClickOutside = (event: Event) => {
    if (ref.current && !ref.current.contains(event.target) && !internalDrag) {
      setIsComponentVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleMouseDown, true);
    document.addEventListener('mouseup', handleClickOutside, true);
    return () => {
      document.removeEventListener('mousedown', handleMouseDown, true);
      document.removeEventListener('mouseup', handleClickOutside, true);
    };
  });

  return { ref, isComponentVisible, setIsComponentVisible };
};

export default useComponentVisible;
