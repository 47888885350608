import { computed, makeObservable, observable, override } from "mobx";
import { convertToRichTextObject } from "vapi-ui-common";
import { IDValueType } from "../../../models/common.model";
import { FeaturesReviewItem } from "../../../models/features.model";
import { sortBy, toLowerCase } from "../../../utils";
import BaseReviewStore from "./baseReviewStore";

class FeaturesReviewCLStore extends BaseReviewStore {
  reviewItems: FeaturesReviewItem[] = [];
  categories: IDValueType[] = [];
  subCategories: IDValueType[] = [];
  subCategoryFilters: string[] = [];

  constructor() {
    // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
    super();

    makeObservable(this, {
      reviewItems: observable,
      categories: observable,
      subCategories: observable,
      subCategoryFilters: observable,
      filteredReviewItems: computed,
      reset: override,
    });
  }

  get filteredReviewItems() {
    const reviewItems = this.reviewItems
      .slice()
      .sort(
        sortBy(this.sortField, this.reverse, this.sortField === "modifiedDate")
      );
    const lowerSearchText = toLowerCase(this.searchText);
    return reviewItems.filter(
      (item) =>
        (!lowerSearchText ||
          toLowerCase(item.category.value).includes(lowerSearchText) ||
          toLowerCase(item.subCategory.value).includes(lowerSearchText) ||
          toLowerCase(convertToRichTextObject(item.description).text).includes(
            lowerSearchText
          ) ||
          toLowerCase(item.rejectNotes).includes(lowerSearchText)) &&
        (!this.categoryFilters.length ||
          this.categoryFilters.includes(item.category.value)) &&
        (!this.subCategoryFilters.length ||
          this.subCategoryFilters.includes(item.subCategory.value)) &&
        (!this.isInProgressFilter || item.isInProgress) &&
        (!this.isHighlightedFilter || item.isHighlighted)
    );
  }

  reset() {
    this.reviewItems = [];
  }
}

export default FeaturesReviewCLStore;
