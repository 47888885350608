import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { useLocation, useParams } from 'react-router';
import { NavLink, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, PublishModal } from 'vapi-ui-common';
import Header from '../../../../components/Header';
import HeaderNotificationButton from '../../../../components/Header/HeaderNotificationButton';
import HeaderNotificationMenu from '../../../../components/Header/HeaderNotificationMenu';
import IconTextButton from '../../../../components/IconTextButton';
import ResetModal from '../../../../components/ResetModal';
import SecondaryHeader from '../../../../components/SecondaryHeader';
import Spinner from '../../../../components/Spinner';
import { TabPanel } from '../../../../components/Tabs/Tabs';
import Wayfinding from '../../../../components/Wayfinding';
import { VD_PUBLISH_TYPE } from '../../../../constants/vehicleData/VDConstants';
import useQuery from '../../../../hooks/useQuery';
import useStores from '../../../../hooks/useStores';
import { Language, UserPermissions } from '../../../../models/user.model';
import { AdminVehicleData, VDTab, VehicleDataVersionInfo, VehicleTeam } from '../../../../models/vehicleData.model';
import PendingReview from '../../../../routes/vehicleData/components/PendingReview';
import ColorsController from '../../../../routes/vehicleData/tabModules/colors/ColorsController';
import FeaturesController from '../../../../routes/vehicleData/tabModules/features/FeaturesController';
import OptionsController from '../../../../routes/vehicleData/tabModules/options/OptionsController';
import { handleErrorResponse } from '../../../../utils/errorHandlingUtils';
import { getVersionInfoFromParams } from '../../../../utils/vehicleDataUtils';
import { getSeries } from '../../../../webservices/adminApi';
import { getDisclaimerTokens } from '../../../../webservices/disclaimersApi';
import { getChangesCount, getVehicleData, publishDraft, resetDraft, submitDraft } from '../../../../webservices/vehicleAdminApi';
import BnPController from '../../tabModules/bp';
import CompareFeaturesController from '../../tabModules/compareFeatures';
import ModelsController from '../../tabModules/models/ModelsController';
import SeriesSettingsController from '../../tabModules/seriesSettings/SeriesSettingsController';
import SpecsController from '../../tabModules/specs';

const Draft = () => {
  const { disclaimersStore, draftStore, vehicleModelsStore, vehicleSeriesInfoStore, teamStore, userStore } = useStores();

  const history = useHistory();
  const location = useLocation();
  const { brand, objectId } = userStore;
  const [isLoaded, setIsLoaded] = useState(false);
  const [oldTeam, setOldTeam] = useState('');
  const [teamParam, setTeamParam] = useState('' as VehicleTeam);
  const [readOnly, setReadOnly] = useState(true);
  const [vehicleDataVersionInfo, setVehicleDataVersionInfo] = useState<VehicleDataVersionInfo>({} as VehicleDataVersionInfo);
  const [adminVehicleData, setAdminVehicleData] = useState<AdminVehicleData>({} as AdminVehicleData);
  const { team, seriesId, year, versionInfo } = useParams<{
    team: VehicleTeam;
    seriesId: string;
    year: string;
    versionInfo: string;
  }>();
  const [permissions, setPermissions] = useState<UserPermissions>({} as UserPermissions);
  const selectTab = VDTab.SERIES_SETTINGS;
  const query = useQuery();
  const tab = query.get('tab') || selectTab;

  const changeLogLink = `/vehicleData/changeLog/${team}/${seriesId}/${year}/${versionInfo}?tab=${encodeURIComponent(draftStore.selectedTab)}&return=draft`;

  const loadData = useCallback(
    async (vdVersionInfo: VehicleDataVersionInfo) => {
      try {
        const responses = await Promise.all([
          getSeries(brand),
          getVehicleData(brand, teamStore.team.param, seriesId, year, vdVersionInfo[teamStore.team.defaultLanguage]?.toString(), teamStore.team.defaultLanguage),
          vehicleModelsStore.fetchData({
            brand,
            team: teamStore.team.param,
            series: seriesId,
            year,
            versionInfo: vdVersionInfo,
            languagePermissions: teamStore.team.langPermissions,
            defaultLanguage: teamStore.team.defaultLanguage,
          }),
        ]);
        // series
        const series = responses[0].data.series[seriesId];
        if (series) {
          vehicleSeriesInfoStore.seriesId = seriesId;
          vehicleSeriesInfoStore.seriesName = series.name;
          vehicleSeriesInfoStore.seriesGroup = series.group;
          vehicleSeriesInfoStore.year = year;

          // check if user can edit a language
          let canEditLanguage = false;
          teamStore.team.languages.forEach(lang => {
            if (teamStore.team.langPermissions[lang]?.canEdit) {
              canEditLanguage = true;
            }
          });

          // set readOnly permissions by group
          if (canEditLanguage) {
            setReadOnly(!userStore.teamModule.series[vehicleSeriesInfoStore.seriesGroup].canEdit);
          }
          // set the permissions by group
          setPermissions(userStore.teamModule.series[vehicleSeriesInfoStore.seriesGroup]);
        }

        // vehicle data
        setAdminVehicleData(responses[1].data);
        // update the read only if data is submitted
        if (responses[1].data.isSubmitted) {
          setReadOnly(true);
        }

        // fetch the disclaimer tokens
        if (teamStore.team.allowDisclaimerTokens) {
          const tokensResponse = await getDisclaimerTokens(brand);
          disclaimersStore.tokens = tokensResponse.data;
        }
      } catch (e: any) {
        console.log(e);
        if (!e.response || !e.response.data.message.includes('exist')) {
          toast.error('Failed loading draft data');
        }
      }
    },
    [vehicleModelsStore, vehicleSeriesInfoStore, teamStore, disclaimersStore, brand, seriesId, year, userStore],
  );

  // load route specific data
  useEffect(() => {
    setIsLoaded(false);
    teamStore.setTeam(team as VehicleTeam, brand, userStore.langPermissions);
    userStore.setTeamModule(team as VehicleTeam);
    setTeamParam(teamStore.team.param);
    const vdVersionInfo = getVersionInfoFromParams(versionInfo);
    setVehicleDataVersionInfo(vdVersionInfo);

    (async () => {
      await loadData(vdVersionInfo);

      setIsLoaded(true);
    })();
  }, [vehicleModelsStore, vehicleSeriesInfoStore, teamStore, disclaimersStore, brand, objectId, seriesId, year, draftStore, team, userStore, versionInfo, loadData]);

  // if user navigates to/from different draftPage (e.g PDT Draft to ADT Draft)
  // switch selectedTab back to Features. Otherwise tab selection in PDT Draft
  // will impact on ADT draft, and etc...
  useEffect(() => {
    if (oldTeam !== team) {
      draftStore.selectedTab = VDTab.SERIES_SETTINGS;
      draftStore.submitted = false;
      setOldTeam(team);
    }
  }, [oldTeam, team, draftStore]);

  useEffect(() => {
    if (tab) {
      draftStore.selectedTab = decodeURIComponent(tab);
    }
  }, [tab, draftStore]);

  const moduleTitle = teamStore.team.pageTitle;
  const wayfindingTo = `/vehicleData/${teamStore.team.name}`;
  const reviewHref = `/vehicleData/review/${teamStore.team.name}/${vehicleSeriesInfoStore.seriesId}/${vehicleSeriesInfoStore.year}/DRAFT`;
  const tabs = teamStore.team.tabs;

  const [showPublishModal, setShowPublishModal] = useState(false);
  const [showSubmitModal, setShowSubmitModal] = useState(false);
  const [openResetModal, setOpenResetModal] = useState(false);
  const [updateCount, setUpdateCount] = useState(0);

  const reloadDraft = async (): Promise<void> => {
    setIsLoaded(false);
    await loadData(vehicleDataVersionInfo);
    setIsLoaded(true);
  };

  const handlePublishData = async () => {
    try {
      const lang = team === VehicleTeam.AGENCY_SPANISH ? 'ES' : 'EN';
      await trackPromise(
        publishDraft(brand, teamParam, {
          seriesId,
          modelYear: parseInt(year),
          lang,
          publishType: VD_PUBLISH_TYPE.PUBLISH,
        }),
      );
      toast.success('Your draft has been successfully published.');
      history.push(`/vehicleData/${teamStore.team.name}`);
    } catch (e) {
      handleErrorResponse(e, 'Error publishing vehicle data');
    }
    draftStore.submitted = true;
  };

  const handleSubmitData = async () => {
    try {
      setShowSubmitModal(false);
      const langs: Language[] = [];
      for (const lang of teamStore.team.languages) {
        if (teamStore.team.langPermissions[lang]?.canEdit) {
          langs.push(lang);
        }
      }
      if (langs.length) {
        await trackPromise(
          submitDraft(brand, teamParam, {
            seriesId,
            modelYear: parseInt(year),
            langs,
          }),
        );
        const action = teamStore.team.submitActionLabel === 'Certify' ? 'certified' : 'submitted';
        toast.success(`Your draft has been successfully ${action}.`);
        history.push(`/vehicleData/${teamStore.team.name}`);
      }
    } catch (e) {
      handleErrorResponse(e, 'Error submitting vehicle data');
    }
    draftStore.submitted = true;
  };

  const handleResetDraft = async () => {
    try {
      setIsLoaded(false);
      const langs: Language[] = [];
      for (const lang of teamStore.team.languages) {
        if (teamStore.team.langPermissions[lang]?.canEdit) {
          langs.push(lang);
        }
      }
      if (langs.length) {
        await trackPromise(resetDraft(brand, teamParam, seriesId, parseInt(year), { langs }));
      }
      await vehicleModelsStore.fetchData({
        brand,
        team: teamStore.team.param,
        series: seriesId,
        year,
        versionInfo: vehicleDataVersionInfo,
        languagePermissions: teamStore.team.langPermissions,
        defaultLanguage: teamStore.team.defaultLanguage,
      });

      toast.success('Your draft has been successfully reset.');
    } catch (e) {
      handleErrorResponse(e, 'Error resetting vehicle data');
    }
    setIsLoaded(true);
  };

  const handleOpenResetModal = async () => {
    try {
      const response = await trackPromise(getChangesCount(brand, teamParam, seriesId, parseInt(year)));
      setUpdateCount(response.data);
      setOpenResetModal(true);
    } catch (e) {
      handleErrorResponse(e, 'Error fetching change count');
    }
  };

  return !isLoaded ? (
    <Spinner />
  ) : (
    <>
      <Header moduleTitle={moduleTitle} moduleSubTitle="Draft" versionNumber={adminVehicleData.version}>
        {permissions.canReview && (adminVehicleData.isPendingCLReview || adminVehicleData.isPendingVDReview) && (
          <HeaderNotificationButton>
            <HeaderNotificationMenu>
              <PendingReview
                adminVehicleData={adminVehicleData}
                reviewHref={reviewHref}
                reviewTeamParam={teamStore.team.reviewTeamParam}
                reviewTeamTitle={teamStore.team.reviewTeamTitle}
              />
            </HeaderNotificationMenu>
          </HeaderNotificationButton>
        )}
      </Header>
      <Wayfinding year={year} seriesName={vehicleSeriesInfoStore.seriesName} to={wayfindingTo} showModels />

      <SecondaryHeader
        tabs={tabs.map(tab => tab.name)}
        selectedTab={draftStore.selectedTab}
        setSelectedTab={tab => {
          draftStore.selectedTab = tab;
          const optionsTabParam = tab === 'Options' && teamStore.team.useOptionsPackages ? '&optionsTab=packages' : '';
          const encodedTab = encodeURIComponent(tab);
          const url = `${location.pathname}?team=${team}&tab=${encodedTab}${optionsTabParam}`;

          history.push(url);
        }}
        renderButtons={() => (
          <>
            {draftStore.selectedTab !== VDTab.SERIES_SETTINGS && (
              <NavLink to={changeLogLink}>
                <Button variant="transparent">Change Log</Button>
              </NavLink>
            )}
            {!readOnly && team !== VehicleTeam.AGENCY_SPANISH && (
              <>
                <IconTextButton icon="undo" text="Reset Draft" onClick={() => handleOpenResetModal()} />
                <Modal open={openResetModal} onClose={() => setOpenResetModal(false)}>
                  <ResetModal
                    changeLogLocation={changeLogLink}
                    resetDraft={handleResetDraft}
                    pageName={teamStore.team.pageTitle}
                    updateCount={updateCount}
                    close={() => setOpenResetModal(false)}
                  />
                </Modal>
              </>
            )}
            {!readOnly && teamStore.team.allowPublish && (
              <>
                <Button variant="primary" onClick={() => setShowPublishModal(true)}>
                  Publish
                </Button>
                <Modal open={showPublishModal} onClose={() => setShowPublishModal(false)}>
                  <PublishModal close={() => setShowPublishModal(false)} publishData={handlePublishData} />
                </Modal>
              </>
            )}
            {!readOnly && teamStore.team.allowSubmit && (
              <>
                <Button variant="primary" onClick={() => setShowSubmitModal(true)}>
                  {teamStore.team.submitActionLabel}
                </Button>
                <Modal open={showSubmitModal} onClose={() => setShowSubmitModal(false)}>
                  <ModalHeader onClose={() => setShowSubmitModal(false)}>{teamStore.team.submitActionLabel} Draft</ModalHeader>
                  <ModalBody>Are you sure you want to {teamStore.team.submitActionLabel} this draft?</ModalBody>
                  <ModalFooter>
                    <Button variant="transparent" onClick={() => setShowSubmitModal(false)}>
                      Cancel
                    </Button>
                    <Button variant="primary" onClick={() => handleSubmitData()}>
                      {teamStore.team.submitActionLabel}
                    </Button>
                  </ModalFooter>
                </Modal>
              </>
            )}
          </>
        )}
      />
      <>
        <TabPanel tab={VDTab.SERIES_SETTINGS} selected={draftStore.selectedTab === VDTab.SERIES_SETTINGS}>
          <SeriesSettingsController
            readOnly={tabs.find(tab => tab.name === VDTab.SERIES_SETTINGS)?.readOnly ?? readOnly}
            team={teamStore.team.param}
            seriesId={seriesId}
            year={year}
            vehicleModels={vehicleModelsStore.vehicleModels}
            changeLogLink={changeLogLink}
            versionInfo={vehicleDataVersionInfo}
            reloadDraft={reloadDraft}
            sourceVersion={adminVehicleData.sourceVersion}
          />
        </TabPanel>
        <TabPanel tab={VDTab.MODELS} selected={draftStore.selectedTab === VDTab.MODELS}>
          <ModelsController
            readOnly={readOnly}
            team={teamStore.team.param}
            seriesId={seriesId}
            year={year}
            vehicleModels={vehicleModelsStore.vehicleModels}
            changeLogLink={changeLogLink}
            versionInfo={vehicleDataVersionInfo}
            reloadDraft={reloadDraft}
            sourceVersion={adminVehicleData.sourceVersion}
          />
        </TabPanel>
        <TabPanel tab={VDTab.FEATURES} selected={draftStore.selectedTab === VDTab.FEATURES}>
          <FeaturesController
            readOnly={readOnly}
            team={teamStore.team.param}
            seriesId={seriesId}
            year={year}
            vehicleModels={vehicleModelsStore.vehicleModels}
            changeLogLink={changeLogLink}
            grades={vehicleModelsStore.grades}
            versionInfo={vehicleDataVersionInfo}
            reloadDraft={reloadDraft}
            sourceVersion={adminVehicleData.sourceVersion}
          />
        </TabPanel>
        <TabPanel tab={VDTab.OPTIONS} selected={draftStore.selectedTab === VDTab.OPTIONS}>
          <OptionsController
            readOnly={readOnly}
            team={teamStore.team.param}
            seriesId={seriesId}
            year={year}
            vehicleModels={vehicleModelsStore.vehicleModels}
            changeLogLink={changeLogLink}
            versionInfo={vehicleDataVersionInfo}
            reloadDraft={reloadDraft}
            sourceVersion={adminVehicleData.sourceVersion}
          />
        </TabPanel>
        <TabPanel tab={VDTab.SPECS} selected={draftStore.selectedTab === VDTab.SPECS}>
          <SpecsController
            readOnly={readOnly}
            team={teamStore.team.param}
            seriesId={seriesId}
            year={year}
            vehicleModels={vehicleModelsStore.vehicleModels}
            changeLogLink={changeLogLink}
            submitted={draftStore.submitted}
            grades={vehicleModelsStore.grades}
            versionInfo={vehicleDataVersionInfo}
            reloadDraft={reloadDraft}
            sourceVersion={adminVehicleData.sourceVersion}
          />
        </TabPanel>
        {teamStore.team.allowBnP && (
          <TabPanel tab={VDTab.BNP} selected={draftStore.selectedTab === VDTab.BNP}>
            <BnPController
              readOnly={tabs.find(tab => tab.name === VDTab.BNP)?.readOnly ?? readOnly}
              team={teamStore.team.param}
              seriesId={seriesId}
              year={year}
              vehicleModels={vehicleModelsStore.vehicleModels}
              changeLogLink={changeLogLink}
              versionInfo={vehicleDataVersionInfo}
              reloadDraft={reloadDraft}
              sourceVersion={adminVehicleData.sourceVersion}
            />
          </TabPanel>
        )}
        <TabPanel tab={VDTab.COLORS} selected={draftStore.selectedTab === VDTab.COLORS}>
          <ColorsController
            readOnly={readOnly}
            team={teamStore.team.param}
            seriesId={seriesId}
            year={year}
            vehicleModels={vehicleModelsStore.vehicleModels}
            changeLogLink={changeLogLink}
            versionInfo={vehicleDataVersionInfo}
            reloadDraft={reloadDraft}
            sourceVersion={adminVehicleData.sourceVersion}
          />
        </TabPanel>
        {teamStore.team.allowCompareFeatures && (
          <TabPanel tab={VDTab.COMPARE_FEATURES} selected={draftStore.selectedTab === VDTab.COMPARE_FEATURES}>
            <CompareFeaturesController
              readOnly={readOnly}
              team={teamStore.team.param}
              seriesId={seriesId}
              year={year}
              grades={vehicleModelsStore.sortedGrades}
              vehicleModels={vehicleModelsStore.vehicleModels}
              changeLogLink={changeLogLink}
              versionInfo={vehicleDataVersionInfo}
              reloadDraft={reloadDraft}
              sourceVersion={adminVehicleData.sourceVersion}
            />
          </TabPanel>
        )}
      </>
    </>
  );
};

export default observer(Draft);
