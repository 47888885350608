import cx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Button, Checkbox, Dropdown, DropdownButton, DropdownList, Input } from 'vapi-ui-common';
import DropdownEdit from '../../../../../../../../components/DropdownEdit';
import DropdownEditItem from '../../../../../../../../components/DropdownEdit/DropdownEditItem';
import IconTextButton from '../../../../../../../../components/IconTextButton';
import { TableRow } from '../../../../../../../../components/Table';
import useComponentVisible from '../../../../../../../../hooks/useComponentVisible';
import useStores from '../../../../../../../../hooks/useStores';
import { IDValueType } from '../../../../../../../../models/common.model';
import { RefItem } from '../../../../../../../../models/refItem.model';
import { Language } from '../../../../../../../../models/user.model';
import { SeriesCategories } from '../../../../../../../../models/vehicleData.model';
import { VehicleModelItem, VehicleModelToyota } from '../../../../../../../../models/vehicleModel.model';
import { addWBR } from '../../../../../../../../utils';
import { isModelValid } from '../../../../../../../../utils/modelsUtils';
import SyncTdPRButton from '../../../../../../components/SyncTdPRButton/SyncTdPRButton';
import styles from '../../../../modelsModal.module.scss';
import HiddenField from '../HiddenField';
import Label from '../Label';
import Bed from './Bed';
import Cab from './Cab';
import Description from './Description';
import Drive from './Drive';
import Engine from './Engine';
import Transmission from './Transmission';
import TrimTitle from './TrimTitle';

interface ModelsEditPanelProps {
  model: VehicleModelItem<VehicleModelToyota>;
  category: SeriesCategories;
  seriesName: string;
  grades: RefItem[];
  fuelTypes: IDValueType[];
  onClose: () => void;
  isCopy?: boolean;
  onAddGrade?: (grade: string) => void;
  onUpdateGrade?: (gradeId: string, gradeValue: string) => void;
  onSave?: (model: VehicleModelItem<VehicleModelToyota>, language: Language) => void;
  rowClass?: string;
  readOnly?: boolean;
  brand: string;
  compareModel: (model: VehicleModelItem<VehicleModelToyota>) => void;
}

const ModelsEditPanelToyota = ({
  category,
  seriesName,
  grades,
  fuelTypes,
  model,
  onClose,
  onAddGrade,
  onUpdateGrade = () => {},
  onSave = () => {},
  rowClass,
  readOnly = false,
  brand,
  compareModel,
}: ModelsEditPanelProps) => {
  const {
    modelTabStore: { resetEditVehicleModel, onEdit, getLangVehicleModel, getEditVehicleModel, setGrade },
    vehicleModelsStore: { defaultLanguage },
    userStore: { langPermissions, isTdpr },
    teamStore: {
      team: { languages: teamLanguages, showAcceptChanges },
    },
  } = useStores();

  onEdit(model);
  const [modelVm, setModelVm] = useState(getEditVehicleModel(model, defaultLanguage));
  const defaultModel = getLangVehicleModel(model, defaultLanguage);
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const [katashikiArray, setKatashikiArray] = useState<string[]>(modelVm.katashiki?.split('|') || ['']);

  const handleOnClose = () => {
    resetEditVehicleModel(model.id);
    onClose();
  };

  const handleOnSave = () => {
    const katashikisString = katashikiArray
      .map(katashiki => katashiki?.trim())
      .filter(katashiki => katashiki !== '')
      .join('|');
    let hasError = false;

    teamLanguages.forEach(lang => {
      if (langPermissions[lang]?.canEdit) {
        const { grade, fuelType, horsepower, seating, acceptChanges } = modelVm;
        const { trimTitle, description, drive, engine, transmission, bed, cab } = getEditVehicleModel(model, lang);

        const newModelVm = {
          ...getEditVehicleModel(model, lang),
          katashiki: katashikisString,
          // code: code?.trim(),
          grade,
          fuelType,
          horsepower: horsepower?.trim(),
          seating: seating?.trim(),
          acceptChanges,
          trimTitle: trimTitle?.trim(),
          description: description?.trim(),
          drive: drive?.trim(),
          engine: engine?.trim(),
          transmission: transmission?.trim(),
          bed: bed?.trim(),
          cab: cab?.trim(),
        };

        if (isTdpr) {
          newModelVm.tdprCode = modelVm.tdprCode?.trim();
          newModelVm.isTDPR = modelVm.isTDPR;
          newModelVm.isUSVI = modelVm.isUSVI;
        }

        const langVM = getLangVehicleModel(model, lang);
        if (isModelValid(langVM, newModelVm, brand)) {
          langVM.updateDynamicProps(newModelVm);
          onSave(langVM, lang);
        } else {
          hasError = true;
          toast.error(`Please fill in the required fields - ${lang}`);
        }
      }
    });

    if (!hasError) {
      handleOnClose();
    }
  };

  const handleKatashikiChange = (index: number, newValue: string) => {
    const newKatashikiArray = [...katashikiArray];
    newKatashikiArray[index] = newValue;

    setKatashikiArray(newKatashikiArray);
  };

  const removeKatashiki = (index: number) => {
    setKatashikiArray(katashikiArray.filter((k, i) => i !== index));
  };

  const changedAttributes = () => {
    const changed: string[] = [];
    for (const lang of teamLanguages) {
      if (!!langPermissions[lang]?.canEdit) {
        const changedAttributes = getLangVehicleModel(model, lang).getVal('changedAttributes') ?? [];
        changed.push(...changedAttributes);
      }
    }
    return changed;
  };

  const isAcceptChangesVisible = teamLanguages.includes(Language.ES) && getLangVehicleModel(model, Language.ES).getVal('changedAttributes')?.length > 0;
  const showSync = !!defaultModel.getVal('fromTMNA') && !readOnly;

  return (
    <TableRow zebra hoverShadow className={rowClass}>
      <td colSpan={7}>
        <section className={styles.addModel}>
          {showSync && (
            <div className={cx(styles.row, styles.syncTdPRRow)}>
              <SyncTdPRButton
                id={model.id}
                changedAttributes={changedAttributes()}
                onClick={() => {
                  compareModel(model);
                  handleOnClose();
                }}
              />
            </div>
          )}
          <div className={styles.row}>
            <div className={styles.short}>
              <Label htmlFor={`amm-code-${model.uid}`}>TMNA Model Code</Label>
              <div>
                <Input
                  required
                  id={`amm-code-${model.uid}`}
                  value={modelVm.code}
                  disabled
                  onChange={e => setModelVm({ ...modelVm, code: e.currentTarget.value })}
                  placeholder="English"
                />
              </div>
            </div>
            {isTdpr && (
              <div className={styles.short}>
                <Label htmlFor={`amm-tdpr-code-${model.uid}`}>TDPR/USVI Model Code</Label>
                <div>
                  <Input
                    required
                    id={`amm-tdpr-code-${model.uid}`}
                    value={modelVm.tdprCode}
                    disabled={!langPermissions[Language.EN]?.canEdit || readOnly}
                    onChange={e => {
                      setModelVm({ ...modelVm, tdprCode: e.currentTarget.value });
                    }}
                  />
                </div>
              </div>
            )}

            {isTdpr && (
              <div className={cx([styles.short])}>
                <Label htmlFor={`amm-is-tdpr-${model.id}`}>TDPR</Label>
                <Checkbox
                  required={!modelVm.isTDPR && !modelVm.isUSVI}
                  id={`amm-is-tdpr-${model.id}`}
                  checked={modelVm.isTDPR || false}
                  onChange={() => {
                    setModelVm({
                      ...modelVm,
                      isTDPR: !modelVm.isTDPR,
                    });
                  }}
                  disabled={!langPermissions[Language.ES]?.canEdit || readOnly}
                ></Checkbox>
              </div>
            )}

            {isTdpr && (
              <div className={cx([styles.short])}>
                <Label htmlFor={`amm-is-USVI-${model.id}`}>USVI</Label>
                <Checkbox
                  required={!modelVm.isTDPR && !modelVm.isUSVI}
                  id={`amm-is-USVI-${model.id}`}
                  checked={modelVm.isUSVI || false}
                  onChange={() => {
                    setModelVm({
                      ...modelVm,
                      isUSVI: !modelVm.isUSVI,
                    });
                  }}
                  disabled={!langPermissions[Language.ES]?.canEdit || readOnly}
                ></Checkbox>
              </div>
            )}

            <div className={styles.short}>
              <Label htmlFor={`amm-grade-${model.uid}`}>Grade</Label>
              <Dropdown>
                <DropdownButton
                  error={!modelVm.grade.value}
                  className={styles.dropdown}
                  disabled={!langPermissions[Language.EN]?.canEdit || readOnly}
                  onClick={() => setIsComponentVisible(true)}
                >
                  {addWBR(modelVm.grade.value)}
                </DropdownButton>
                <DropdownEdit
                  ref={ref}
                  open={isComponentVisible}
                  addBtnText={'Grade'}
                  onAdd={onAddGrade}
                  renderList={() => (
                    <>
                      {grades.map(item => (
                        <DropdownEditItem
                          key={item.uid}
                          value={item.value}
                          isSelected={false}
                          onEdit={(from, to) => {
                            onUpdateGrade(item.id, to);
                          }}
                          onClose={() => setIsComponentVisible(false)}
                          onSelect={() => {
                            setModelVm({ ...modelVm, grade: item });
                            setGrade({ vmId: model.id, language: defaultLanguage, grade: item });
                          }}
                        />
                      ))}
                    </>
                  )}
                />
              </Dropdown>
            </div>
            <TrimTitle vmId={model.id} seriesName={seriesName} model={model} />
            <Description vmId={model.id} model={model} />
          </div>

          <div className={styles.row}>
            <Drive vmId={model.id} model={model} />

            <Engine vmId={model.id} model={model} />

            <Transmission vmId={model.id} model={model} />

            <div className={cx([styles.verySmall])}>
              <Label htmlFor={`amm-horsepower-${model.uid}`}>Horsepower</Label>
              <Input
                required
                id={`amm-horsepower-${model.uid}`}
                value={modelVm.horsepower}
                disabled={!langPermissions[Language.EN]?.canEdit || readOnly}
                onChange={e => setModelVm({ ...modelVm, horsepower: e.currentTarget.value })}
                placeholder="English"
              />
            </div>

            {katashikiArray.map((katashiki: string, index: number) => (
              <div className={cx([styles.short, styles.katashikiContainer])} key={index}>
                <Label htmlFor={`amm-katashiki-${model.uid}`} optional>
                  Katashiki {index ? index + 1 : null}
                </Label>
                {index ? (
                  <IconTextButton
                    className={styles.katashikiDelete}
                    smallIcon
                    icon="remove"
                    text=""
                    onClick={() => removeKatashiki(index)}
                    disabled={!langPermissions[Language.EN]?.canEdit}
                  />
                ) : null}
                <Input
                  id={`amm-katashiki-${model.uid}-${index}`}
                  value={katashiki}
                  disabled={!langPermissions[Language.EN]?.canEdit || readOnly}
                  onChange={e => handleKatashikiChange(index, e.target.value)}
                  placeholder="English"
                />
              </div>
            ))}

            {langPermissions[Language.EN]?.canEdit && !readOnly && (
              <div className={cx([styles.verySmall, styles.ctas])}>
                <IconTextButton
                  smallIcon
                  icon="plus"
                  text="Katashiki"
                  disabled={!langPermissions[Language.EN]?.canEdit || readOnly}
                  onClick={() => setKatashikiArray(katashikiArray => [...katashikiArray, ''])}
                />
              </div>
            )}

            <div className={cx([styles.short])}>
              <Label htmlFor={`amm-fueltype-${model.uid}`}>Fuel Type</Label>
              <DropdownList
                required={!modelVm.fuelType.value}
                btnClass={styles.dropdown}
                value={modelVm.fuelType.value}
                list={fuelTypes.map(ft => ft.value)}
                disabled={!langPermissions[Language.EN]?.canEdit || readOnly}
                onSelect={value => {
                  setModelVm({
                    ...modelVm,
                    fuelType: fuelTypes.find(item => item.value === value),
                  });
                }}
              />
            </div>

            {showAcceptChanges && isAcceptChangesVisible && (
              <div className={cx([styles.short])}>
                <Label htmlFor={`amm-acceptChanges-${model.id}`}>Accept Changes</Label>
                <Checkbox
                  id={`amm-acceptChanges-${model.id}`}
                  checked={modelVm.acceptChanges || false}
                  onChange={() => {
                    setModelVm({
                      ...modelVm,
                      acceptChanges: !modelVm.acceptChanges,
                    });
                  }}
                  disabled={!langPermissions[Language.ES]?.canEdit || readOnly}
                ></Checkbox>
              </div>
            )}
          </div>

          <div className={styles.row}>
            <Bed vmId={model.id} category={category} model={model} />
            <Cab vmId={model.id} category={category} model={model} />

            {category === SeriesCategories.LARGE_SEDANS_VANS && (
              <div>
                <Label htmlFor={`amm-seating-${model.uid}`} optional>
                  Seating
                </Label>
                <Input
                  id={`amm-seating-${model.uid}`}
                  value={modelVm.seating ?? ''}
                  disabled={!langPermissions[Language.EN]?.canEdit || readOnly}
                  onChange={e => setModelVm({ ...modelVm, seating: e.currentTarget.value })}
                  placeholder="English"
                />
              </div>
            )}

            {(category === SeriesCategories.SEDANS_SPORTS_CARS || category === SeriesCategories.CROSSOVERS) && <HiddenField />}

            {!readOnly && (
              <div className={cx([styles.ctas, styles.right])}>
                <Button variant="transparent" onClick={handleOnClose}>
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  onClick={() => {
                    handleOnSave();
                  }}
                >
                  Save Model
                </Button>
              </div>
            )}
          </div>
        </section>
      </td>
    </TableRow>
  );
};

export default observer(ModelsEditPanelToyota);
