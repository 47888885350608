import { ApolloProvider } from "@apollo/client";
import { InteractionType } from "@azure/msal-browser";
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import { configure } from "mobx";
import React, { HTMLAttributes, useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import apolloClient from "./apolloClient";
import App from "./App";
import { getToken, getUser, msalInstance } from "./authConfig";
import useStores from "./hooks/useStores";
import "./index.scss";
import { UserInfo } from "./models/user.model";
import { getPermissions } from "./webservices/adminApi";

configure({
  enforceActions: "never",
});

const getUserInfo = async () => {
  const token = await getToken();
  const user = await getUser();
  const objectId = user?.localAccountId ?? "";
  const response = await getPermissions({ token, objectId });

  return { ...response.data, objectId } as UserInfo;
};

const UserInfoComponent = ({ children }: HTMLAttributes<HTMLDivElement>) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const { userStore } = useStores();

  useEffect(() => {
    getUserInfo().then((userInfo) => {
      userStore.setUserInfo(userInfo);
      setIsLoaded(true);
    });
  }, [userStore]);

  return <>{isLoaded && children}</>;
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <MsalProvider instance={msalInstance}>
    <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
      <ApolloProvider client={apolloClient}>
        <UserInfoComponent>
          <App />
        </UserInfoComponent>
      </ApolloProvider>
    </MsalAuthenticationTemplate>
  </MsalProvider>
);
