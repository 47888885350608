import cx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { CSSProperties, ReactNode } from 'react';
import { ModelCodeHeaderRow } from '../../../../components/Table';
import useStores from '../../../../hooks/useStores';
import { VehicleModelItem, VehicleModelToyota } from '../../../../models/vehicleModel.model';
import StickyTable from './StickyTable';
import styles from './modelTable.module.scss';

type ModelTableProps = {
  showFeatureSplits?: boolean;
  viewModelCodes: boolean;
  models: VehicleModelItem<VehicleModelToyota>[];
  renderRows: () => ReactNode;
  headerStyle?: CSSProperties;
  onTableHeaderHeightChange?: (height: number) => void;
};
const ModelTable: React.FC<ModelTableProps> = ({ showFeatureSplits = false, viewModelCodes, models, renderRows, headerStyle = {}, onTableHeaderHeightChange }) => {
  const { userStore } = useStores();

  const renderHeaders = () => (
    <>
      <tr>
        {models
          .filter(item => item.show)
          .map(model => {
            return (
              <th className={styles.modelHeader} key={model.uid} style={headerStyle}>
                <div className={styles.grade}>{model.getVal('grade').value}</div>
                <div className={styles.title}>{model.getVal('description')}</div>
              </th>
            );
          })}
      </tr>
      {viewModelCodes && (
        <ModelCodeHeaderRow>
          {models
            .filter(item => item.show)
            .map(model => (
              <th className={cx(styles.modelCodeCell)} key={model.uid}>
                {userStore.isTdpr ? model.getVal('tdprCode') : model.getVal('code')}
              </th>
            ))}
        </ModelCodeHeaderRow>
      )}
    </>
  );

  return <StickyTable renderHeaders={renderHeaders} renderRows={renderRows} onTableHeaderHeightChange={onTableHeaderHeightChange} />;
};

export default observer(ModelTable);
