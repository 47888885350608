import { faPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cx from 'clsx';
import React from 'react';
import {
  Button,
  convertToRichTextObject,
  MultiLineRTEditor,
  SuggestionType,
  TableCell,
  TokenizedTextArea,
} from 'vapi-ui-common';
import Input from '../../../../../../components/Input';
import { ApplicabilityTextLangMap } from '../../../../../../models/compareFeatures.model';
import { FeatureSettings } from '../../../../../../models/features.model';
import { LanguagePermissions } from '../../../../../../models/user.model';
import ApplicabilityTextPopover from '../../../../tabModules/compareFeatures/components/ApplicabilityTextPopover/ApplicabilityTextPopover';
import styles from './settingsCell.module.scss';

// only accepts a specific set of allowable characters in text area
// onBlur makes sure value is reset to last acceptable value
// if left in an intermediate state
const useInputCharacterWhitelist = (
  initialVal: string,
  onUpdateValue: any,
  allowFreeFormText?: boolean
) => {
  const [tempValue, setTempValue] = React.useState(initialVal);
  const [value, setValue] = React.useState(tempValue);

  // if initial value changes, due to say reseting draft
  React.useEffect(() => {
    setTempValue(initialVal);
    setValue(initialVal);
  }, [initialVal]);

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    let _value = event.target.value;

    if (allowFreeFormText) {
      setTempValue(_value);
      return;
    }

    // if value is "- ", "--" or " -", that means the value was UNDEFINED, and delete key was pressed.
    if (_value === '- ' || _value === '--' || _value === ' -') {
      _value = '';
    }

    // if value is not one of allowable types, do nothing
    const valueLC = _value.toLowerCase();

    const valueUP = valueLC.toUpperCase();
    setTempValue(valueUP);

    // if value is one of allowed types,
    // and not intermediate type empty string,
    // set value
    if (valueLC !== '' && valueUP !== value) {
      setValue(valueUP);
      onUpdateValue(valueUP);
    }
  };

  const onBlurHandler = (event: React.FocusEvent<HTMLInputElement>) => {
    // if tempValue is empty string,
    // reset to previously set acceptable version
    if (tempValue === '') {
      setTempValue(value);
    }

    return value;
  };

  return [tempValue, onChangeHandler, onBlurHandler] as const;
};

type SettingsCellProps = {
  model: {
    id: string;
    setting: string;
    highlighted?: boolean;
    defaultText?: string;
    value?: string;
    highlightSortOrder?: number;
    featureId?: string;
    applicabilityTextMap?: ApplicabilityTextLangMap;
  };
  oddRow: boolean;
  rowSpan: number;
  onUpdate: (value: FeatureSettings) => void;
  disabled?: boolean;
  disableInput?: boolean;
  disableHighlight?: boolean;
  disableApplicabilityText?: boolean;
  useChangeLogStyle?: boolean;
  allowTokens?: boolean;
  disclaimerTokens?: SuggestionType[];
  isRequired?: boolean;
  invalid?: boolean;
  allowDisclaimerTokensInGradeApplicability?: boolean;
  lexusAgencySpecs?: boolean;
  onClickHighlight?: () => void;
  onUpdateApplicabilityText?: (applicabilityTextMap: ApplicabilityTextLangMap) => void;
  languagePermissions?: LanguagePermissions;
  syncValueChanged?: boolean;
  allowFreeFormText?: boolean;
};

const SettingsCell: React.FC<SettingsCellProps> = ({
  model,
  oddRow,
  rowSpan,
  onUpdate,
  disabled,
  disableInput,
  disableHighlight,
  disableApplicabilityText,
  useChangeLogStyle = false,
  allowTokens,
  disclaimerTokens = [] as SuggestionType[],
  isRequired,
  invalid,
  allowDisclaimerTokensInGradeApplicability,
  lexusAgencySpecs = false,
  onClickHighlight,
  onUpdateApplicabilityText,
  syncValueChanged,
  allowFreeFormText,
}) => {
  const { setting, highlighted, defaultText, value, applicabilityTextMap } = model;
  const [tempValue, onChangeHandler] = useInputCharacterWhitelist(
    setting,
    () => {},
    allowFreeFormText
  );

  const renderContent = () => {
    if (allowDisclaimerTokensInGradeApplicability) {
      return (
        <TableCell smallText className={cx(styles.tokenContainer, styles.tokenDisplay)}>
          <MultiLineRTEditor
            disableToolbar
            value={tempValue}
            onBlur={value => {
              onUpdate(convertToRichTextObject(value)?.text as FeatureSettings);
            }}
            suggestionTypes={disclaimerTokens}
            editorStyles={lexusAgencySpecs ? styles.lexusAgencySpecsStyle : undefined}
          />
        </TableCell>
      );
    } else if (
      !allowDisclaimerTokensInGradeApplicability &&
      !onUpdateApplicabilityText &&
      allowTokens
    ) {
      return (
        <TokenizedTextArea
          disabled={disabled}
          className={styles.tokenContainer}
          displayClassName={styles.tokenDisplay}
          value={tempValue}
          onBlurCallback={value => onUpdate(value as FeatureSettings)}
          size="small"
          allowEmpty={true}
        />
      );
    } else {
      return (
        <Input
          id="settings input"
          type="text"
          disabled={disabled || disableInput}
          onBlur={e => {
            let value = e.currentTarget.value.trim();

            if (allowFreeFormText) {
              switch (value.toUpperCase()) {
                case FeatureSettings.OPTIONAL:
                case FeatureSettings.PACKAGE:
                case FeatureSettings.STANDARD:
                  value = value.toUpperCase();
                  break;
              }
            } else {
              value = value.toUpperCase();
            }

            onUpdate(value as FeatureSettings);
          }}
          className={cx(
            styles.setting,
            { [styles[tempValue]]: true, [styles.capitalLetter]: !allowFreeFormText },
            useChangeLogStyle && styles.changeLogStyle
          )}
          defaultValue={tempValue}
          placeholder="--"
          onChange={onChangeHandler}
        />
      );
    }
  };

  const getModifiedApplicabilityText = (text: String | undefined) => {
    if (!tempValue) {
      return null;
    }

    return (
      <div>
        {text} <FontAwesomeIcon className={styles.iconPen} icon={faPen} />
      </div>
    );
  };

  const isHighlightToggled = !!highlighted;
  const isCompareFeature =
    onClickHighlight !== undefined && onUpdateApplicabilityText !== undefined;

  return (
    <td
      key={model.id}
      className={cx(styles.td, {
        [styles.compareFeatureColumn]: isCompareFeature,
        [styles.oddRowColor]: oddRow,
        [styles.invalid]: invalid,
        [styles.syncValueChanged]: syncValueChanged,
      })}
      rowSpan={rowSpan}
    >
      {isRequired && <span className={styles.isRequired}>*</span>}
      {onClickHighlight && (
        <div className={styles.compareFeatureButtonWrapper}>
          <Button
            className={isHighlightToggled ? styles.highlightedOn : styles.highlightedOff}
            disabled={!!disableHighlight}
            onClick={() => {
              if (!disabled && tempValue) {
                onClickHighlight();
              }
            }}
          >
            Highlight
          </Button>
        </div>
      )}
      {renderContent()}

      {onUpdateApplicabilityText && !disableApplicabilityText && (
        <div>
          <ApplicabilityTextPopover
            readOnly={disabled || !tempValue}
            handleUpdateItem={onUpdateApplicabilityText}
            defaultApplicabilityText={
              !disabled ? <>{getModifiedApplicabilityText(defaultText)}</> : defaultText || ''
            }
            grade={value || ''}
            applicabilityTextMap={applicabilityTextMap || { text: {} }}
          />
        </div>
      )}
    </td>
  );
};

export default SettingsCell;
