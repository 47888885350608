import React, { HTMLAttributes, ReactNode } from 'react';
import { rteToHtml } from 'vapi-ui-common';
import Checkbox from '../../../../../../components/Checkbox';
import { CheckboxProps } from '../../../../../../components/Checkbox/Checkbox';
import IconTextButton from '../../../../../../components/IconTextButton';
import NumberFormat from '../../../../../../components/NumberFormat';
import { Table, TableCell, TableProps } from '../../../../../../components/Table';
import { TableCellProps } from '../../../../../../components/Table/components/TableCell/TableCell';
import {
  TableRow,
  TableRowProps,
} from '../../../../../../components/Table/components/TableRow/TableRow';
import TooltipPortal from '../../../../../../components/TooltipPortal';
import styles from './colorsInteriorTable.module.scss';

export const ColorsInteriorTable = (props: TableProps) => (
  <Table className={styles.table} {...props} />
);

export const ColorsInteriorTrimHeaderRow = (props: HTMLAttributes<HTMLDivElement>) => (
  <div className={styles.trimHeaderRow} {...props} />
);

export const ColorsInteriorHeaderCell = (props: TableCellProps) => (
  <td className={styles.headerCell} {...props} />
);

export const ColorsInteriorTrimHeaderName = (props: TableCellProps) => (
  <div className={styles.trimHeaderNameCell} {...props} />
);

export const ColorsInteriorCellSpacer = (props: TableCellProps) => (
  <td className={styles.cellSpacer} {...props} />
);

interface ColorsInteriorHeaderCellProps extends TableCellProps {
  interiorName?: string;
  interiorCode?: string;
  modelCodes?: ReactNode;
  interiorItemsLength?: number;
  rejectNotes?: string;
  isExtraCost?: string;
  onClick?: () => void;
  leftInititalPosition?: number;
  showRedText?: boolean;
}

export const ColorsInteriorHeaderDetail = ({
  interiorName,
  interiorCode,
  rejectNotes,
  isExtraCost = '',
  onClick,
  showRedText = false,
  ...rest
}: ColorsInteriorHeaderCellProps) => {
  return (
    <div className={styles.headerDetail} {...rest} onClick={onClick}>
      <div className={styles.interiorName} style={{ color: showRedText ? 'red' : undefined }}>
        {rteToHtml(interiorName)}
      </div>
      <div className={styles.modelCode} style={{ color: showRedText ? 'red' : undefined }}>
        {interiorCode}
      </div>
      <div className={styles.msrp} style={{ color: showRedText ? 'red' : undefined }}>
        <NumberFormat value={isExtraCost} />
      </div>
      <section className={styles.status}>
        {rejectNotes && <IconTextButton icon="purpleCircle" smallIcon text="" disabled />}
      </section>
    </div>
  );
};

export const ColorsInteriorHeaderRow = (props: HTMLAttributes<HTMLDivElement>) => (
  <div className={styles.headerRow} {...props} />
);

export const ColorsInteriorBodyRow = (props: TableRowProps) => (
  <TableRow className={styles.bodyRow} {...props} />
);

export const CheckboxCell = ({ id, checked, onChange, disabled }: CheckboxProps) => (
  <TableCell colType="applicability" center>
    <Checkbox
      id={id}
      className={styles.checkboxCell}
      checked={checked}
      onChange={onChange}
      disabled={disabled}
    />
  </TableCell>
);

export const ColorsInteriorModelCodeRow = ({
  modelCodes,
  interiorItemsLength,
  leftInititalPosition,
  ...rest
}: ColorsInteriorHeaderCellProps) => {
  const codes = modelCodes && modelCodes.toString().replace(/,/g, '');
  const codesLength = Number(interiorItemsLength) * 28;
  const shortList =
    codes && codes.length > codesLength ? `${codes.substring(0, codesLength)}...` : codes;

  return (
    <div className={styles.modelCodesWrapper} {...rest}>
      <TooltipPortal
        leftInititalPosition={leftInititalPosition}
        toggleElement={<div className={styles.modelCodesToggle}>{shortList}</div>}
        popoverElement={<div className={styles.modelCodesPopover}>{codes}</div>}
      />
    </div>
  );
};
