import cx from 'clsx';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { trackPromise } from 'react-promise-tracker';
import { toast } from 'react-toastify';
import { ActionBar, ActionBarDivider, Modal, Spinner } from 'vapi-ui-common';
import Checkbox from '../../../../components/Checkbox/Checkbox';
import SyncUpdatesPopover from '../../../../components/SyncUpdatesPopover/SyncUpdatesPopover';
import { langNameMap } from '../../../../constants/vehicleData/VDConstants';
import useSeriesSettings from '../../../../hooks/useSeriesSettings';
import useStores from '../../../../hooks/useStores';
import { AppliedChangesResponse, ChangeLogTypes } from '../../../../models/changeLog.model';
import { KeyValueType } from '../../../../models/common.model';
import { Language } from '../../../../models/user.model';
import { SortModel, VehicleTeam } from '../../../../models/vehicleData.model';
import { VehicleModelItem, VehicleModelToyota } from '../../../../models/vehicleModel.model';
import { handleErrorResponse } from '../../../../utils/errorHandlingUtils';
import { refItemsXForm } from '../../../../utils/refItemUtils';
import { syncSpanishUpdates } from '../../../../webservices/vehicleAdminApi';
import { addGrades, deleteModel, sortModels, updateGrade, updateModel } from '../../../../webservices/vehicleModelsApi';
import ActionBarFiltersSection from '../../components/ActionBarFiltersSection';
import SyncTMNAChangesModal from '../../components/SyncTMNAChangesModal/SyncTMNAChangesModal';
import { ProductDataControllerProps } from '../../models/controllers.model';
import ModelsFilters from './components/Filters';
import ModalModalTable from './components/ModelsModalTable';
import styles from './modelsModal.module.scss';

const ModelsController = ({ readOnly, seriesId, year, version, versionInfo, isPublished, reloadDraft, sourceVersion }: ProductDataControllerProps) => {
  const {
    userStore: { brand, langPermissions },
    teamStore: {
      team: { param, allowAddDeleteModels, allowEditGoLiveDate, languages: teamLangs, canAddFromDropdown, canSyncUpdates },
    },
    vehicleSeriesInfoStore: { seriesGroup, seriesName },
    vehicleModelsStore,
    seriesSettingsStore,
    modelTabStore: { setVehicleModels, setReadOnly, setTeamLanguages, languages: modelTabLangs, setLanguageSelected, getLangVehicleModel },
  } = useStores();
  const { isLoaded: seriesLoaded } = useSeriesSettings(brand, seriesId, param, year, versionInfo);

  const [models, setModels] = useState<VehicleModelItem<VehicleModelToyota>[]>(vehicleModelsStore.filteredVehicleModels);
  const [isSyncing, setIsSyncing] = useState(false);
  const [showSyncChangesModal, setShowSyncChangesModal] = useState(false);
  const [syncChangesModel, setSyncChangesModel] = useState<VehicleModelItem<VehicleModelToyota> | undefined>(undefined);

  useEffect(() => {
    setTeamLanguages(teamLangs);
  }, [teamLangs, setTeamLanguages]);

  useEffect(() => {
    setReadOnly(readOnly);
  }, [readOnly, setReadOnly]);

  useEffect(() => {
    setVehicleModels(vehicleModelsStore.dataLang);
  }, [vehicleModelsStore.dataLang, setVehicleModels]);

  useEffect(() => {
    const sortedModels = vehicleModelsStore.filteredVehicleModels.slice().sort((a, b) => {
      return a.sortOrder - b.sortOrder;
    });
    setModels(sortedModels);
  }, [setModels, vehicleModelsStore.filteredVehicleModels]);

  const handleUpdateVehicleModel = async (vehicleModel: VehicleModelItem<VehicleModelToyota>, language?: Language, unlinkFromTMNA: boolean = false) => {
    try {
      const response = await trackPromise(updateModel(brand, param, seriesId, year, vehicleModel.getPayload(brand), language, unlinkFromTMNA));
      if (language) {
        getLangVehicleModel(vehicleModel, language).revId = response.data.revId;
      } else {
        vehicleModel.revId = response.data.revId;
      }

      if (!!vehicleModel.getPayload(brand).acceptChanges) {
        getLangVehicleModel(vehicleModel, Language.ES).updateDynamicProps({
          changedAttributes: [],
        });
      }
      vehicleModelsStore.vehicleModels.forEach(item => {
        if (vehicleModel.id === item.id) {
          if (language) {
            getLangVehicleModel(item, language).revId = getLangVehicleModel(vehicleModel, language).revId;
          } else {
            item.revId = vehicleModel.revId;
          }
        }
      });

      if (language) {
        if (unlinkFromTMNA) {
          getLangVehicleModel(vehicleModel, language).updateDynamicProps({
            fromTMNA: false,
            changedAttributes: [],
          });
        }
        vehicleModelsStore.setModelForLang(vehicleModel, language);
      } else {
        vehicleModelsStore.setModels(vehicleModel);
      }
      toast.success(`Updated ${language ? `${langNameMap[language]} ` : ''}model successfully`);
    } catch (e) {
      handleErrorResponse(e, 'Error updating model');
    }
  };

  const handleAddVehicleModel = async (vehicleModel: VehicleModelItem<VehicleModelToyota>) => {
    /*  // this function can only handle adding a model for english; however b/c tdpr cant add models this works fine (as it can only be used by tmna english)
    try {
      const response = await trackPromise(
        addModel(brand, param, seriesId, year, vehicleModel.getPayload(brand))
      );
      vehicleModel.revId = response.data.revId;
      vehicleModel.id = response.data.id;
      const vehicleModels = [vehicleModel, ...models];
      await handleOnSortModels(getSortModels(vehicleModels), vehicleModels);

      toast.success('Added model successfully');
    } catch (e) {
      handleErrorResponse(e, 'Error adding model');
    } */
  };

  const handleDeleteVehicleModel = async (vehicleModel: VehicleModelItem<VehicleModelToyota>) => {
    try {
      const promises: Promise<any>[] = [];
      teamLangs.forEach(lang => {
        if (langPermissions[lang]?.canEdit) {
          promises.push(trackPromise(deleteModel(brand, param, seriesId, year, lang, vehicleModel.id)));
        }
      });

      await Promise.all(promises);
      vehicleModelsStore.setModels(models.filter(item => item.id !== vehicleModel.id));
      toast.success('Deleted model successfully');
    } catch (e) {
      handleErrorResponse(e, 'Error deleting model');
    }
  };

  const handleAddGrade = async (grade: string) => {
    const payload: KeyValueType<string> = {};
    teamLangs.forEach(lang => {
      if (langPermissions[lang]?.canEdit) {
        payload[lang] = grade;
      }
    });
    const response = await trackPromise(addGrades(brand, param, seriesId, year, payload));
    vehicleModelsStore.grades = refItemsXForm(response.data.EN);
    try {
      toast.success('Added grade successfully');
    } catch (e) {
      handleErrorResponse(e, 'Error adding grade');
    }
  };

  const handleUpdateGrade = async (gradeId: string, gradeValue: string) => {
    try {
      const promises: Promise<any>[] = [];
      teamLangs.forEach(lang => {
        if (langPermissions[lang]?.canEdit) {
          promises.push(trackPromise(updateGrade(brand, param, seriesId, year, lang, gradeId, gradeValue)));
        }
      });
      await Promise.all(promises);
      vehicleModelsStore.grades = vehicleModelsStore.grades.map(grade => {
        if (grade.id === gradeId) {
          grade.value = gradeValue;
        }
        return grade;
      });
      toast.success('Update grade successfully');
    } catch (e) {
      handleErrorResponse(e, 'Error updating grade');
    }
  };

  const handleSetModels = (models: VehicleModelItem<VehicleModelToyota>[]) => setModels(models);

  const handleOnSortModels = async (sortedModels: SortModel[], vehicleModelsToUpdate?: VehicleModelItem<VehicleModelToyota>[]) => {
    try {
      const vehicleModels = vehicleModelsToUpdate || models;
      const response = await trackPromise(sortModels(brand, param, seriesId, year, sortedModels));
      response.data.forEach(responseItem => {
        vehicleModels.forEach(item => {
          if (responseItem.id === item.id) {
            item.sortOrder = responseItem.sortOrder;
            item.revId = responseItem.revId;
          }
        });
      });

      vehicleModelsStore.setModels(vehicleModels);
    } catch (e) {
      handleErrorResponse(e, 'Error sorting models');
    }
  };

  const handleOnShow = () => {
    vehicleModelsStore.setLocalStorage(param);
  };

  const syncUpdates = async () => {
    setIsSyncing(true);
    try {
      await syncSpanishUpdates(brand, param, seriesId, year);
      if (reloadDraft) {
        setIsSyncing(false);
        reloadDraft();
      }
      toast.success('Sync Successful');
    } catch (e) {
      handleErrorResponse(e, 'Error with Sync');
      setIsSyncing(false);
    }
  };

  const compareModel = (vehicleModel: VehicleModelItem<VehicleModelToyota>) => {
    setSyncChangesModel(vehicleModel);
    setShowSyncChangesModal(true);
  };

  const applyChanges = async (response: AppliedChangesResponse) => {
    if (!syncChangesModel) {
      return;
    }
    const updateMap: {
      [lang: string]: {
        [fieldToUpdate: string]: any;
      };
    } = {};
    const changeLogTypes: ChangeLogTypes[] = Object.keys(response.applied) as ChangeLogTypes[];
    changeLogTypes.forEach(changeType => {
      const langMap = response.applied[changeType];
      if (langMap) {
        Object.entries(langMap).forEach(([lang, after]) => {
          if (!updateMap[lang]) {
            updateMap[lang] = {};
          }
          const modelVM = getLangVehicleModel(syncChangesModel, lang as Language);
          if (modelVM) {
            switch (changeType) {
              case ChangeLogTypes.CODE:
                updateMap[lang].code = after;
                break;
              case ChangeLogTypes.GO_LIVE_DATE:
                updateMap[lang].goLiveDate = after;
                break;
              case ChangeLogTypes.DRIVE:
                updateMap[lang].drive = after;
                break;
              case ChangeLogTypes.TRIM_TITLE:
                updateMap[lang].trimTitle = after;
                break;
              case ChangeLogTypes.DESCRIPTION:
                updateMap[lang].description = after;
                break;
              case ChangeLogTypes.ENGINE:
                updateMap[lang].engine = after;
                break;
              case ChangeLogTypes.TRANSMISSION:
                updateMap[lang].transmission = after;
                break;
              case ChangeLogTypes.BED:
                updateMap[lang].bed = after;
                break;
              case ChangeLogTypes.CAB:
                updateMap[lang].cab = after;
                break;
              case ChangeLogTypes.SEATING:
                updateMap[lang].seating = after;
                break;
              case ChangeLogTypes.KATASHIKI:
                updateMap[lang].katashiki = after;
                break;
              case ChangeLogTypes.HORSEPOWER:
                updateMap[lang].horsepower = after;
                break;
              case ChangeLogTypes.FUEL_TYPE:
                const newFuelType = vehicleModelsStore.fuelTypes.find(fuelType => fuelType.id === after);
                if (newFuelType) {
                  updateMap[lang].fuelType = newFuelType;
                }
                break;
              default:
                break;
            }
          }
        });
      }
    });

    const promises: Promise<any>[] = [];
    for (const lang of teamLangs) {
      if (langPermissions[lang]?.canEdit) {
        const modelVM = getLangVehicleModel(syncChangesModel, lang);
        let uMap: { [key: string]: any } = {
          changedAttributes: [],
          acceptChanges: true,
        };
        if (updateMap[lang] && Object.keys(updateMap[lang]).length) {
          uMap = {
            ...uMap,
            ...updateMap[lang],
          };
        }
        modelVM.updateDynamicProps(uMap);
        promises.push(handleUpdateVehicleModel(modelVM, lang));
      }
    }

    await Promise.all(promises);
    for (const lang of teamLangs) {
      if (langPermissions[lang]?.canEdit) {
        getLangVehicleModel(syncChangesModel, lang).updateDynamicProps({
          changedAttributes: [],
        });
      }
    }
  };

  const getActionBarButtons = (showActionButtons: boolean) => {
    const actionBarButtons: React.ReactNode[] = [];

    if (teamLangs.length > 1) {
      for (const lang of teamLangs) {
        actionBarButtons.push(
          <Checkbox id={`${lang}-select-checkbox`} checked={modelTabLangs[lang].selected} onChange={e => setLanguageSelected(lang, e.currentTarget.checked)}>
            <span>{langNameMap[lang]}</span>
          </Checkbox>,
        );
      }
    }

    if (showActionButtons && canSyncUpdates) {
      actionBarButtons.push(<SyncUpdatesPopover seriesId={seriesId} year={year} syncUpdates={syncUpdates} align="left" />);
    }

    return (
      <>
        {actionBarButtons.map((button, index) => (
          <React.Fragment key={index}>
            <ActionBarDivider />
            {button}
          </React.Fragment>
        ))}
      </>
    );
  };

  return (
    <>
      <div className={cx(styles.actionBar)}>
        <ActionBar>
          <ActionBarFiltersSection
            renderButtons={getActionBarButtons(!readOnly)}
            renderFilter={onClose => {
              if (param === VehicleTeam.AGENCY_TEAM && version == null) {
                return (
                  <ModelsFilters
                    onClose={onClose}
                    isReviewNotesFilter={vehicleModelsStore.isReviewNotesFilter}
                    setIsReviewNotesFilter={value => vehicleModelsStore.onFilter(() => (vehicleModelsStore.isReviewNotesFilter = value))}
                    isSyncUpdateFilter={vehicleModelsStore.isSyncUpdateFilter}
                    setIsSyncUpdateFilter={value => vehicleModelsStore.onFilter(() => (vehicleModelsStore.isSyncUpdateFilter = value))}
                    isPublished={isPublished}
                  />
                );
              } else {
                return <></>;
              }
            }}
          />
          {/* <LanguageCheckbox /> */}
        </ActionBar>
      </div>

      {!seriesLoaded || isSyncing ? (
        <Spinner />
      ) : (
        <ModalModalTable
          readOnly={readOnly}
          brand={brand}
          seriesGroup={seriesGroup}
          seriesName={seriesName}
          seriesId={seriesId}
          year={year}
          version={version}
          onShow={handleOnShow}
          onAddModel={handleAddVehicleModel}
          onDeleteModel={handleDeleteVehicleModel}
          onUpdateModel={handleUpdateVehicleModel}
          onSortModels={handleOnSortModels}
          vehicleModels={models}
          grades={vehicleModelsStore.grades}
          fuelTypes={vehicleModelsStore.fuelTypes}
          setModels={handleSetModels}
          onAddGrade={handleAddGrade}
          onUpdateGrade={handleUpdateGrade}
          canAddDelete={allowAddDeleteModels}
          seriesSettings={seriesSettingsStore.getDefaultSeriesSettings(seriesSettingsStore.seriesSettingsLangMaps)}
          canEditGoLiveDate={allowEditGoLiveDate}
          canAddFromDropdown={canAddFromDropdown}
          compareModel={compareModel}
          editableLanguages={teamLangs.filter(lang => !!langPermissions[lang]?.canEdit)}
        />
      )}
      <Modal open={showSyncChangesModal} size="auto" onClose={() => setShowSyncChangesModal(false)}>
        <SyncTMNAChangesModal
          brand={brand}
          team={param}
          seriesId={seriesId}
          year={year}
          itemId={syncChangesModel ? syncChangesModel.id : ''}
          entityType={'models'}
          isNew={!!syncChangesModel?.getVal('changedAttributes')?.includes('new')}
          isDelete={!!syncChangesModel?.getVal('changedAttributes')?.includes('delete')}
          close={(response, shouldDelete, unlinkFromTMNA) => {
            setShowSyncChangesModal(false);
            if (syncChangesModel) {
              if (response) {
                applyChanges(response);
              } else if (shouldDelete) {
                handleDeleteVehicleModel(syncChangesModel);
              } else if (unlinkFromTMNA) {
                teamLangs.forEach(lang => {
                  if (langPermissions[lang]?.canEdit) {
                    handleUpdateVehicleModel(syncChangesModel, lang, true);
                  }
                });
              }
            }
          }}
          fuelTypes={vehicleModelsStore.fuelTypes}
          sourceVersion={sourceVersion}
        />
      </Modal>
    </>
  );
};

export default observer(ModelsController);
