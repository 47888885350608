import { processRteForChangeLog } from 'vapi-ui-common';
import {
  VehicleCompareDoubleSortList as VehicleCompareDoubleSortListGQL,
  VehicleCompareFeature as VehicleCompareFeatureGQL,
  VehicleCompareFeatureGradeApplicability,
  VehicleFeaturesCompareResponse,
} from '../gql/generated';
import { ChangeLogTypes } from '../models/changeLog.model';
import { IDValueType } from '../models/common.model';
import {
  CompareFeatureDocumentResponse,
  CompareFeatureItem,
  CompareFeatureResponse,
  CompareFeatureReviewType,
  CompareFeaturesChangeTypeMap,
  CompareFeaturesReviewItem,
  CompareFeaturesReviewMap,
  CompareFeaturesReviewResponse,
  CompareGradeApplicability,
  CompareGradeApplicabilityMap,
  CompareType,
  HighlightSortList,
  IDoubleSortList,
} from '../models/compareFeatures.model';
import { FeatureSettings } from '../models/features.model';
import { RefItem } from '../models/refItem.model';
import { ISortList } from '../models/sort.model';
import { handleOtherChangesForReview } from './reviewUtils';

const gradeApplicabilityGQLToGradeApplicability = (gradeApplicability?: VehicleCompareFeatureGradeApplicability[]): CompareGradeApplicabilityMap =>
  gradeApplicability?.reduce((map, gApplicability) => {
    const applicabiliyItem: CompareGradeApplicability = {
      highlighted: gApplicability.highlighted,
      applicability: gApplicability.applicability as FeatureSettings,
      text: gApplicability.text,
    };

    return {
      ...map,
      [gApplicability.id]: applicabiliyItem,
    };
  }, {}) ?? {};

export const vehicleCompareFeatureGQLToCompareFeatureResponse = (vehicleCompareFeatureGQL: VehicleCompareFeatureGQL): CompareFeatureResponse => ({
  categoryId: vehicleCompareFeatureGQL.categoryId as string,
  compareType: vehicleCompareFeatureGQL.compareType as any,
  description: vehicleCompareFeatureGQL.description,
  gradeApplicability: gradeApplicabilityGQLToGradeApplicability(vehicleCompareFeatureGQL.gradeApplicability as VehicleCompareFeatureGradeApplicability[]),
  id: vehicleCompareFeatureGQL.id,
  isInProgress: vehicleCompareFeatureGQL.isInProgress,
  notes: vehicleCompareFeatureGQL.notes as string,
  parentId: vehicleCompareFeatureGQL.parentId as string,
  revId: vehicleCompareFeatureGQL.revId,
  subCategoryId: vehicleCompareFeatureGQL.subCategoryId as string,
  fromTMNA: !!vehicleCompareFeatureGQL.fromTMNA,
  changedAttributes: (vehicleCompareFeatureGQL.changedAttributes as string[]) ?? [],
});

export const vehicleCompareDoubleSortListGQLToHighlightSortList = (list?: VehicleCompareDoubleSortListGQL[]): IDoubleSortList => ({
  doubleSortList:
    list?.reduce(
      (map, listItem) => ({
        ...map,
        [listItem.id]: {
          sortList: listItem.sortList.reduce(
            (sList, sItem) => ({
              ...sList,
              [sItem.id]: sItem.position,
            }),
            {},
          ),
        },
      }),
      {},
    ) ?? {},
});

export const vehicleFeaturesCompareResponseToFeatureResponse = (vehicleFeaturesCompareResponse?: VehicleFeaturesCompareResponse): CompareFeatureDocumentResponse => {
  const compareFeaturesGQL = vehicleFeaturesCompareResponse?.compareFeatures ?? [];
  const compareFeatures: CompareFeatureResponse[] = compareFeaturesGQL.map(feature => vehicleCompareFeatureGQLToCompareFeatureResponse(feature)) ?? [];

  return { compareFeatures, ...vehicleCompareDoubleSortListGQLToHighlightSortList(vehicleFeaturesCompareResponse?.doubleSortList as VehicleCompareDoubleSortListGQL[]) };
};

export const compareFeaturesXForm = (
  data: CompareFeatureResponse[],
  grades: RefItem[],
  featureCategories: IDValueType[],
  featureSubCategories: IDValueType[],
  specCategories: IDValueType[],
  specTypes: IDValueType[],
) => {
  return data.map((item, index) => {
    return compareFeatureItemXForm(item, grades, featureCategories, featureSubCategories, specCategories, specTypes, index);
  });
};

export const compareFeatureItemXForm = (
  item: CompareFeatureResponse,
  grades: RefItem[],
  featureCategories: IDValueType[],
  featureSubCategories: IDValueType[],
  specCategories: IDValueType[],
  specTypes: IDValueType[],
  index: number,
) => {
  grades.forEach(grade => {
    if (!item.gradeApplicability) {
      item.gradeApplicability = {};
    }
    if (!item.gradeApplicability[grade.id]) {
      item.gradeApplicability[grade.id] = {
        highlighted: false,
        text: '',
        applicability: FeatureSettings.UNDEFINED,
      };
    }
  });
  const compareFeatureItem = new CompareFeatureItem(item);
  const categories = compareFeatureItem.compareType === CompareType.Feature ? featureCategories : specCategories;
  const subCategories = compareFeatureItem.compareType === CompareType.Feature ? featureSubCategories : specTypes;
  const category = categories.filter(cat => cat.id === item.categoryId);
  const subCategory = subCategories.filter(cat => cat.id === item.subCategoryId);

  compareFeatureItem.category = category.length ? category[0] : compareFeatureItem.category;
  compareFeatureItem.subCategory = subCategory.length ? subCategory[0] : compareFeatureItem.subCategory;
  compareFeatureItem.sortOrder = index + 1;

  return compareFeatureItem;
};

export const highlightSortListTransform = (highlightSortList: HighlightSortList, compareFeatures: CompareFeatureItem[], grades: RefItem[]) => {
  const newSortList = Object.assign({}, highlightSortList);
  // add any sort lists that should exist but dont
  grades.forEach(grade => {
    if (!newSortList[grade.id] || !newSortList[grade.id].sortList) {
      newSortList[grade.id] = { sortList: {} } as ISortList;
    }
  });
  let compareFeaturesMap: { [id: string]: string } = {};

  // add/remove any compare features based on their highlighted status
  compareFeatures.forEach(compareFeature => {
    compareFeaturesMap[compareFeature.id] = compareFeature.id;
    Object.keys(compareFeature.gradeApplicability).forEach(gradeId => {
      const sortList = newSortList[gradeId];
      if (compareFeature.gradeApplicability[gradeId].highlighted) {
        if (!sortList.sortList[compareFeature.id]) {
          sortList.sortList[compareFeature.id] = Object.keys(sortList.sortList).length + 1;
        }
      } else if (sortList.sortList[compareFeature.id]) {
        delete newSortList[gradeId].sortList[compareFeature.id];
      }
    });
  });

  // reorder and give new sort numbers
  Object.keys(newSortList).forEach(gradeId => {
    const keys = Object.keys(newSortList[gradeId].sortList);
    const sortedArray: { id: string; value: number }[] = [];
    keys.forEach(key => {
      if (compareFeaturesMap[key]) {
        sortedArray.push({
          id: key,
          value: newSortList[gradeId].sortList[key],
        });
      }
    });
    sortedArray.sort((a, b) => a.value - b.value);
    newSortList[gradeId].sortList = {};
    sortedArray.forEach((entry, index) => {
      newSortList[gradeId].sortList[entry.id] = index + 1;
    });
  });

  return newSortList;
};

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const compareFeaturesReviewItemXForm = (
  items: CompareFeaturesReviewResponse[],
  categories: IDValueType[],
  subCategories: IDValueType[],
  grades: RefItem[],
): CompareFeaturesReviewMap => {
  const map: CompareFeaturesReviewMap = {};

  items.forEach(item => {
    let category = categories.filter(cat => cat.id === item.categoryId);
    if (!category.length) {
      const itemCategory: string = item.category ? item.category : '';
      category = [new IDValueType(itemCategory, itemCategory)];
    }
    let subCategory = subCategories.filter(cat => cat.id === item.subCategoryId);
    if (!subCategory.length) {
      const itemSubCategory: string = item.subCategory ? item.subCategory : '';
      subCategory = [new IDValueType(itemSubCategory, itemSubCategory)];
    }
    if (!map[item.id]) {
      map[item.id] = {
        id: item.id,
        revId: item.revId,
        isApplied: item.isApplied,
        isNew: false,
        isDeleted: false,
        rejectNotes: item.rejectNotes || '',
        notes: item.notes || '',
        isInProgress: item.isInProgress,
        category: {
          before: '',
          after: category[0].value,
          hasChanged: false,
        },
        subCategory: {
          before: '',
          after: subCategory[0].value,
          hasChanged: false,
        },
        description: {
          before: '',
          after: processRteForChangeLog(item.description),
          hasChanged: false,
        },
        gradeApplicability: {
          before: {},
          after: item.gradeApplicability || {},
          hasChanged: false,
        },
      } as CompareFeaturesChangeTypeMap;
    }

    Object.entries(item.changes).forEach(([key, change]) => {
      const compareFeatureItem = new CompareFeaturesReviewItem(item, change);
      compareFeatureItem.category = category.length ? category[0] : compareFeatureItem.category;
      compareFeatureItem.subCategory = subCategory.length ? subCategory[0] : compareFeatureItem.subCategory;
      compareFeatureItem.changeTypeId = key;

      switch (change.changeType) {
        case ChangeLogTypes.SUB_CATEGORY:
        case ChangeLogTypes.CATEGORY: {
          compareFeatureItem.changes.beforeValue = change.before as string;
          compareFeatureItem.changes.afterValue = change.after as string;
          break;
        }
      }

      if (compareFeatureItem.otherChanges) {
        handleOtherChangesForReview(compareFeatureItem.otherChanges, [
          { type: ChangeLogTypes.CATEGORY, refItems: categories },
          { type: ChangeLogTypes.SUB_CATEGORY, refItems: subCategories },
        ]);
      }

      const changeLogKey = getKeyFromChangeLogType(change.changeType);
      if (changeLogKey === 'deleted') {
        map[item.id].isDeleted = true;
        map[item.id].category.before = map[item.id].category.after;
        map[item.id].category.after = '';
        map[item.id].category.hasChanged = true;

        map[item.id].subCategory.before = map[item.id].subCategory.after;
        map[item.id].subCategory.after = '';
        map[item.id].subCategory.hasChanged = true;

        map[item.id].description.before = map[item.id].description.after;
        map[item.id].description.after = '';
        map[item.id].description.hasChanged = true;

        map[item.id].gradeApplicability.before = map[item.id].gradeApplicability.after;
        map[item.id].gradeApplicability.after = {};
        map[item.id].gradeApplicability.hasChanged = true;
      } else if (changeLogKey === 'added') {
        map[item.id].category.hasChanged = true;
        map[item.id].subCategory.hasChanged = true;
        map[item.id].description.hasChanged = true;
        map[item.id].gradeApplicability.hasChanged = true;
        map[item.id].isNew = true;
      } else if (changeLogKey === 'category' || changeLogKey === 'subCategory' || changeLogKey === 'description' || changeLogKey === 'gradeApplicability') {
        if (changeLogKey === 'description') {
          map[item.id][changeLogKey].before = processRteForChangeLog(compareFeatureItem.changes.before);
          map[item.id][changeLogKey].after = processRteForChangeLog(compareFeatureItem.changes.after);
        } else if (changeLogKey === 'gradeApplicability') {
          map[item.id][changeLogKey].before = JSON.parse(JSON.stringify(change.before));
          map[item.id][changeLogKey].after = JSON.parse(JSON.stringify(change.after));
        } else {
          map[item.id][changeLogKey].before = compareFeatureItem.changes.before;
          map[item.id][changeLogKey].after = compareFeatureItem.changes.after;
        }
        map[item.id][changeLogKey].hasChanged = true;
      }
    });
  });

  return map;
};

const getKeyFromChangeLogType = (type: ChangeLogTypes): CompareFeatureReviewType | undefined => {
  switch (type) {
    case ChangeLogTypes.CATEGORY:
      return 'category';
    case ChangeLogTypes.SUB_CATEGORY:
      return 'subCategory';
    case ChangeLogTypes.DESCRIPTION:
      return 'description';
    case ChangeLogTypes.GRADE_APPLICABILITY:
      return 'gradeApplicability';
    case ChangeLogTypes.COMPARE_FEATURE_ADDED:
      return 'added';
    case ChangeLogTypes.COMPARE_FEATURE_DELETED:
      return 'deleted';
  }
  return undefined;
};
