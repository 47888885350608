import { action, makeObservable, observable, override } from "mobx";
import { CategoryResponse } from "../../../models/category.model";
import { IDValueType } from "../../../models/common.model";
import {
  CompareFeaturesReviewMap,
  CompareFeaturesReviewResponse,
} from "../../../models/compareFeatures.model";
import {
  RefItem,
  RefItemObject,
  RefItemResponse,
} from "../../../models/refItem.model";
import { categoriesXForm } from "../../../utils/categoryUtils";
import { compareFeaturesReviewItemXForm } from "../../../utils/compareFeaturesUtils";
import { refItemsXForm } from "../../../utils/refItemUtils";
import BaseReviewStore from "./baseReviewStore";

class CompareFeaturesReviewStore extends BaseReviewStore {
  grades: RefItem[] = [];
  categories: IDValueType[] = [];
  subCategories: IDValueType[] = [];
  map: CompareFeaturesReviewMap = {};

  constructor() {
    // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
    super();

    makeObservable(this, {
      categories: observable,
      subCategories: observable,
      map: observable,
      setMap: action,
      setCategories: action,
      setSubCategories: action,
      reset: override,
    });
  }

  setMap(items: CompareFeaturesReviewResponse[]) {
    this.map = compareFeaturesReviewItemXForm(
      items,
      this.categories,
      this.subCategories,
      this.grades
    );
  }

  setCategories(
    response: CategoryResponse[],
    specResponse: RefItemResponse<RefItemObject>[]
  ) {
    const featureCategories = categoriesXForm(response);
    const specCategories = refItemsXForm(specResponse).map((cat) => {
      return new IDValueType(cat.id, cat.value);
    });
    this.categories = [...featureCategories, ...specCategories];
  }

  setSubCategories(
    response: CategoryResponse[],
    specResponse: RefItemResponse<RefItemObject>[]
  ) {
    const featureSubCategories = categoriesXForm(response);
    const specTypes = refItemsXForm(specResponse).map((cat) => {
      return new IDValueType(cat.id, cat.value);
    });
    this.subCategories = [...featureSubCategories, ...specTypes];
  }

  reset() {
    this.map = {};
    this.categories = [];
    this.subCategories = [];
  }
}

export default CompareFeaturesReviewStore;
