import { observer } from 'mobx-react-lite';
import React from 'react';
import inputStyles from '../../../../../../components/Input/input.module.scss';
import { HeaderRow, LeftTableTH, Thead } from '../../../../../../components/Table';
import useStores from '../../../../../../hooks/useStores';
import { VehicleTeam } from '../../../../../../models/vehicleData.model';

type Props = {
  allowLinks: boolean;
  showSpecTypes: boolean;
  viewModelCodes: boolean;
  showSpecLinkModal: boolean;
  readOnly?: boolean;
  onSort?: (field: string) => void;
  sortMode: boolean;
  languages: string[];
  team: string;
  brand: string;
};

const SpecsHeaderRow: React.FC<Props> = ({ allowLinks, showSpecTypes, showSpecLinkModal, readOnly = false, onSort = () => {}, sortMode, languages, team, brand }) => {
  const {
    tableSizeStore: { tableRowHeight },
    specsStore,
    teamStore,
  } = useStores();

  const getDescriptionHeaders = () => {
    if (!languages.length) {
      return <LeftTableTH onClick={() => onSort('description')}>Description</LeftTableTH>;
    }
    return languages.map(lang => {
      return <LeftTableTH style={{ padding: 0 }} key={`LeftTableTH-${lang}-Description`}>{`${lang} Description`}</LeftTableTH>;
    });
  };

  return (
    <Thead>
      <HeaderRow
        style={{
          height: `${tableRowHeight}px`,
        }}
      >
        {specsStore.hasChangedAttributes() && teamStore.team.showAcceptChanges && <LeftTableTH>Accept Changes</LeftTableTH>}
        {!readOnly && team !== VehicleTeam.AGENCY_SPANISH && <LeftTableTH></LeftTableTH>}
        {sortMode && <LeftTableTH className={inputStyles.smallInputHeader}>#</LeftTableTH>}
        <LeftTableTH onClick={() => onSort('category.value')}>Category</LeftTableTH>
        {showSpecTypes && <LeftTableTH onClick={() => onSort('specType.value')}>Spec Type</LeftTableTH>}
        {allowLinks && <LeftTableTH></LeftTableTH>}
        {getDescriptionHeaders()}
        <LeftTableTH></LeftTableTH>
      </HeaderRow>
    </Thead>
  );
};

export default observer(SpecsHeaderRow);
