import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef, useState } from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { trackPromise } from 'react-promise-tracker';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import { ActionBar, ActionBarDivider, Modal } from 'vapi-ui-common';
import IconTextButton from '../../../../components/IconTextButton';
import Spinner from '../../../../components/Spinner';
import { TableRow, TwoTableWrapper } from '../../../../components/Table';
import SortButton from '../../../../components/sortModule/SortButton';
import SortDropdown from '../../../../components/sortModule/SortDropdown/SortDropdown';
import SortModal from '../../../../components/sortModule/SortModal/SortModal';
import { langNameMap } from '../../../../constants/vehicleData/VDConstants';
import useDebounce from '../../../../hooks/useDebounce';
import useStores from '../../../../hooks/useStores';
import { CategoriesMap, CategoryLangMap } from '../../../../models/category.model';
import { AppliedChangesResponse, ChangeLogTypes } from '../../../../models/changeLog.model';
import { IDValueType, KeyValueType } from '../../../../models/common.model';
import { ApplicabilityTextLangMap, CompareType } from '../../../../models/compareFeatures.model';
import { FeatureItem, FeatureLangMap, FeatureSettings } from '../../../../models/features.model';
import { VDSortableEntity } from '../../../../models/sort.model';
import { BRAND_TDPR, BRAND_TOYOTA, Language } from '../../../../models/user.model';
import { VehicleTeam } from '../../../../models/vehicleData.model';
import ActionBarFiltersSection from '../../../../routes/vehicleData/components/ActionBarFiltersSection';
import ModelTable from '../../../../routes/vehicleData/components/ModelTable';
import SettingsCell from '../../../../routes/vehicleData/components/ModelTable/components/SettingsCell';
import { ProductDataControllerProps } from '../../../../routes/vehicleData/models/controllers.model';
import { compareFeatureItemXForm } from '../../../../utils/compareFeaturesUtils';
import { tokensXForm } from '../../../../utils/disclaimersUtils';
import { handleErrorResponse } from '../../../../utils/errorHandlingUtils';
import getLangActionBarButtons from '../../../../utils/getLangActionBarButtons';
import { getSortPayload, getSortedCopy } from '../../../../utils/sortUtils';
import { syncSpanishUpdates, updateSortList } from '../../../../webservices/vehicleAdminApi';
import { addCompareFeatureFromParent, deleteCompareFeature, updateCompareFeature } from '../../../../webservices/vehicleCompareFeaturesApi';
import { addCategories, addFeature, addSubCategories, deleteFeature, updateCategory, updateFeature, updateSubCategory } from '../../../../webservices/vehicleFeaturesApi';
import LeftTable from '../../components/LeftTable';
import SyncTMNAChangesModal from '../../components/SyncTMNAChangesModal/SyncTMNAChangesModal';
import FeatureFilters from './components/FeatureFilters';
import FeatureHeaderRow from './components/FeatureHeaderRow';
import FeatureRowsContainer from './components/FeatureRow/FeatureRowsContainer';

const FeaturesController = ({
  team,
  readOnly,
  seriesId,
  year,
  version,
  versionInfo,
  vehicleModels,
  grades,
  isPublished,
  reloadDraft,
  sourceVersion,
}: ProductDataControllerProps) => {
  const {
    commonLanguageStore,
    featuresStore,
    teamStore,
    userStore: { brand },
    disclaimersStore: { tokens },
  } = useStores();

  const { debounce } = useDebounce({ delay: 2000 });
  const updatingCompareFeatureLink = useRef(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [sortMode, setSortMode] = useState(false);
  const [sortCategoryModal, setSortCategoryModal] = useState(false);
  const [sortSubCategoryModal, setSortSubCategoryModal] = useState(false);
  const [showSyncChangesModal, setShowSyncChangesModal] = useState(false);
  const [syncChangesFeature, setSyncChangesSpec] = useState<FeatureLangMap | undefined>(undefined);

  const disclaimerTokens = tokensXForm(tokens);

  useEffect(() => {
    setIsLoaded(false);
    featuresStore.reset();
    featuresStore.allowCompareFeatures = teamStore.team.allowCompareFeatures;

    (async () => {
      try {
        await featuresStore.fetchData(
          brand,
          team,
          seriesId,
          year,
          vehicleModels,
          teamStore.team.switchShortLongDescription,
          grades || [],
          teamStore.team.langPermissions,
          versionInfo,
        );
      } catch (e: any) {
        toast.error('Error loading features data');
        console.log(e.message);
      }
      setIsLoaded(true);
    })();
  }, [brand, teamStore, featuresStore, seriesId, team, vehicleModels, year, versionInfo, commonLanguageStore, grades]);

  const addEmptyFeatureLangMap = () => {
    featuresStore.addItem(vehicleModels, teamStore.team.switchShortLongDescription);
    showFormFieldError();
  };

  const updateFeatureItem = async (feature: FeatureItem, compareChangeMessageRequest: boolean, lang: string, acceptChanges: boolean = false, unlinkFromTMNA: boolean = false) => {
    try {
      debounce(async () => {
        const response = await trackPromise(updateFeature(brand, team, seriesId, year, feature.getPayload(), lang, acceptChanges, unlinkFromTMNA));
        updateRevId(feature, response.data.revId);
        if (acceptChanges || unlinkFromTMNA) {
          feature.changedAttributes = [];
          feature.changedModelIds = [];
        }
        if (unlinkFromTMNA) {
          feature.fromTMNA = false;
        }
        if (feature.compareFeatureId) {
          const compareFeature = featuresStore.compareFeaturesMap.compareFeatures[feature.id].langs[lang];
          if (feature.subCategory.id) {
            compareFeature.subCategory.id = feature.subCategory.id;
          }
          compareFeature.category.id = feature.category.id;
          const compareRes = await trackPromise(updateCompareFeature(brand, team, seriesId, year, lang, compareFeature.getPayload()));
          compareFeature.revId = compareRes.data.revId;
        }
        toast.success(`${langNameMap[lang]} Feature updated successfully`);
        if (compareChangeMessageRequest && feature.compareFeatureId && feature.id) {
          toast.success('Changes have been updated on the Compare View Page');
        }
      }, feature.uid);
    } catch (e) {
      handleErrorResponse(e, 'Feature failed update');
    }
  };

  const addFeatureItem = async (feature: FeatureItem, lang: string) => {
    try {
      debounce(async () => {
        const response = await trackPromise(addFeature(brand, team, seriesId, year, feature.getPayload(), lang));
        feature.id = response.data.id;
        updateRevId(feature, response.data.revId);
        toast.success('Feature added successfully');
      }, feature.uid);
    } catch (e) {
      handleErrorResponse(e, 'Feature failed add');
    }
  };

  const saveFeatureLangMap = async (
    featureLangMap: FeatureLangMap,
    compareChangeMessageRequest: boolean = false,
    lang: string = '',
    acceptChanges: boolean = false,
    unlinkFromTMNA: boolean = false,
  ) => {
    if (lang && !featuresStore.langWriteMap[lang as Language]?.canEdit) {
      toast.error(`You do not have permissions to update ${langNameMap[lang]} features.`);
      return;
    }
    const langs = lang ? [lang] : featuresStore.editableLangs;
    const promises: Promise<any>[] = [];
    let numValid = 0;
    for (const lang of featuresStore.editableLangs) {
      // check to make sure all features either have a revid or are valid
      const feature = featureLangMap.langs[lang];
      if (!feature.revId && !feature.isValid(team)) {
        showFormFieldError();
        return;
      } else if (!feature.revId && !langs.includes(lang)) {
        // if a feature doesnt have a rev id (i.e a new feature) and is valid then it will be saved
        // this is to avoid issues that arise when saving a feature description (which specifies which lang to save)
        // e.g. you fill out all the necessary fields for english but not spanish and an error comes up.  then you fill out the spanish description which triggers a spanish save but not an english one
        langs.push(lang);
      }
    }
    for (const lang of langs) {
      const feature = featureLangMap.langs[lang];
      if (feature.isValid(team)) {
        numValid++;
        if (feature.revId) {
          promises.push(updateFeatureItem(feature, compareChangeMessageRequest, lang, acceptChanges, unlinkFromTMNA));
        } else {
          promises.push(addFeatureItem(feature, lang));
        }
      }
    }
    if (!numValid) {
      showFormFieldError();
    } else {
      await Promise.all(promises);
    }
  };

  const deleteFeatureLangMap = async (featureLangMap: FeatureLangMap) => {
    try {
      const uid = featureLangMap.langs[featuresStore.defaultLang].uid;
      let featureId = '';
      let hasCompareFeature = false;

      // only delete the features that the user has write permissions for
      for (const lang of featuresStore.editableLangs) {
        const feature = featureLangMap.langs[lang];
        featureId = feature.id;
        if (feature.revId) {
          await trackPromise(deleteFeature(brand, team, seriesId, year, lang, feature.id));
        }
        if (feature.compareFeatureId) {
          await trackPromise(deleteCompareFeature(brand, team, seriesId, year, lang, feature.compareFeatureId));
          hasCompareFeature = true;
        }
      }
      featuresStore.deleteItem(uid);
      if (hasCompareFeature) {
        delete featuresStore.compareFeaturesMap.compareFeatures[featureId];
        toast.success('Changes have been updated on the Compare View Page');
      }
      toast.success('Feature deleted sucessfully');
    } catch (e) {
      handleErrorResponse(e, 'Error deleting feature');
    }
  };

  const copyFeatureLangMap = async (featureLangMap: FeatureLangMap) => {
    try {
      const copiedFeatureLangMap = featuresStore.copyMap(JSON.parse(JSON.stringify(featureLangMap)), vehicleModels);
      const featureId = uuidv4();
      let isValid = true;
      featuresStore.allLangs.forEach(lang => {
        copiedFeatureLangMap.langs[lang].id = featureId;
        if (!copiedFeatureLangMap.langs[lang].isValid(team)) {
          isValid = false;
        }
      });

      if (isValid) {
        for (const lang of featuresStore.allLangs) {
          const copiedFeature = copiedFeatureLangMap.langs[lang];
          if (featuresStore.langWriteMap[lang]?.canEdit) {
            // if the user has write permissions for this language then we should create the feature on the backend too
            const response = await trackPromise(addFeature(brand, team, seriesId, year, copiedFeature.getPayload(), lang));
            updateRevId(copiedFeature, response.data.revId);
          }
        }
        const sortPayload = getSortPayload(featuresStore.featureLangMaps.map(langMap => langMap.langs[featuresStore.editableLangs[0]]));
        await trackPromise(updateSortList(brand, team, seriesId, year, VDSortableEntity.FEATURES, sortPayload));
        toast.success('Feature copied successfully');
      } else {
        showFormFieldError();
      }
    } catch (e) {
      handleErrorResponse(e, 'Feature failed copy');
    }
  };

  const addCategoryItem = async (payload: { [lang: string]: string }, id?: string) => {
    try {
      const response = await trackPromise(addCategories(brand, team, seriesId, year, payload, id));
      const categoriesMap: CategoriesMap = JSON.parse(JSON.stringify(featuresStore.categoriesMap));
      Object.keys(response.data).forEach(lang => {
        featuresStore.updateCategoriesLangMap(lang as Language, categoriesMap, response.data[lang], featuresStore.categoriesSortList);
      });
      featuresStore.fillOutCategoriesMap(categoriesMap);
      featuresStore.categoriesMap = categoriesMap;
      toast.success('Category added successfully');
    } catch (e) {
      handleErrorResponse(e, 'Error adding category');
    }
  };

  const updateCategoryItem = async (categoryLangMap: CategoryLangMap, payload: { [lang: string]: string }) => {
    try {
      for (const lang of featuresStore.editableLangs) {
        if (payload[lang]) {
          await trackPromise(updateCategory(brand, team, seriesId, year, categoryLangMap[lang].id, payload[lang], lang));
        }
      }
      featuresStore.editableLangs.forEach(lang => {
        if (payload[lang]) {
          categoryLangMap[lang].value = payload[lang];
        }
      });
      const catMap = featuresStore.categoriesMap;
      for (const lang of featuresStore.editableLangs) {
        if (payload[lang]) {
          catMap.categories[categoryLangMap[lang].id] = categoryLangMap;
          break;
        }
      }
      featuresStore.categoriesMap = catMap;
      toast.success('Category updated successfully');
    } catch (e) {
      handleErrorResponse(e, 'Error editing category');
    }
  };

  const addSubCategoryItem = async (payload: { [lang: string]: string }, id?: string) => {
    try {
      const response = await trackPromise(addSubCategories(brand, team, seriesId, year, payload, id));
      const subCategoriesMap: CategoriesMap = JSON.parse(JSON.stringify(featuresStore.subCategoriesMap));
      Object.keys(response.data).forEach(lang => {
        featuresStore.updateCategoriesLangMap(lang as Language, subCategoriesMap, response.data[lang], featuresStore.subCategoriesSortList);
      });
      featuresStore.fillOutCategoriesMap(subCategoriesMap);
      featuresStore.subCategoriesMap = subCategoriesMap;
      toast.success('Subcategory updated successfully');
    } catch (e) {
      handleErrorResponse(e, 'Error adding subcategory');
    }
  };

  const updateSubCategoryItem = async (subCategoryLangMap: CategoryLangMap, payload: { [lang: string]: string }) => {
    try {
      for (const lang of featuresStore.editableLangs) {
        if (payload[lang] && featuresStore.langWriteMap[lang]?.canEdit) {
          await trackPromise(updateSubCategory(brand, team, seriesId, year, subCategoryLangMap[lang].id, payload[lang], lang));
        }
      }
      featuresStore.editableLangs.forEach(lang => {
        if (payload[lang]) {
          subCategoryLangMap[lang].value = payload[lang];
        }
      });
      const subCatMap = featuresStore.subCategoriesMap;
      for (const lang of featuresStore.editableLangs) {
        if (payload[lang]) {
          subCatMap.categories[subCategoryLangMap[lang].id] = subCategoryLangMap;
          break;
        }
      }
      featuresStore.subCategoriesMap = subCatMap;
      toast.success('Subcategory updated successfully');
    } catch (e) {
      handleErrorResponse(e, 'Error editing subcategory');
    }
  };

  const updateRevId = (feature: FeatureItem, revId: string) => {
    feature.revId = revId;
  };

  const onSaveSortCategories = async (list: IDValueType<string>[], dataType: VDSortableEntity) => {
    try {
      const catPayload = getSortPayload(list);
      await trackPromise(updateSortList(brand, team, seriesId, year, dataType, catPayload));

      let featuresPayload;
      if (dataType === VDSortableEntity.FEATURES_CATEGORIES || dataType === VDSortableEntity.FEATURES_SUBCATEGORIES) {
        const categories = dataType === VDSortableEntity.FEATURES_CATEGORIES ? list : featuresStore.getDefaultCategories(featuresStore.categoriesMap);
        const subCategories = dataType === VDSortableEntity.FEATURES_SUBCATEGORIES ? list : featuresStore.getDefaultCategories(featuresStore.subCategoriesMap);

        const featuresCopy = getSortedCopy<FeatureItem>(featuresStore.getDefaultFeatures(featuresStore.featureLangMaps), item => item.subCategory.id, subCategories);
        const featuresCopy2 = getSortedCopy<FeatureItem>(featuresCopy, item => item.category.id, categories);
        featuresPayload = getSortPayload(featuresCopy2);
      }

      if (featuresPayload) {
        await trackPromise(updateSortList(brand, team, seriesId, year, VDSortableEntity.FEATURES, featuresPayload));
        setIsLoaded(false);
        featuresStore.resetFilters();
        await trackPromise(
          featuresStore.fetchData(brand, team, seriesId, year, vehicleModels, teamStore.team.switchShortLongDescription, grades || [], teamStore.team.langPermissions, versionInfo),
        );
      }
    } catch (e) {
      handleErrorResponse(e, 'Error updating category sort');
    }
    setIsLoaded(true);
  };

  // todo: figure out sorting
  const onStopSorting = async () => {
    setSortMode(false);
    try {
      const featureItems = featuresStore.getDefaultFeatures(featuresStore.filteredFeatureLangMaps);
      const sortPayload = getSortPayload(featureItems);
      await trackPromise(updateSortList(brand, team, seriesId, year, VDSortableEntity.FEATURES, sortPayload));
      setIsLoaded(false);
      await trackPromise(
        featuresStore.fetchData(brand, team, seriesId, year, vehicleModels, teamStore.team.switchShortLongDescription, grades || [], teamStore.team.langPermissions, versionInfo),
      );
      setIsLoaded(true);
    } catch (e) {
      handleErrorResponse(e, 'Error updating feature sort');
    }
  };

  const handleCompareFeatureHighlighted = async (featureLangMap: FeatureLangMap) => {
    if (!updatingCompareFeatureLink.current) {
      for (const lang of featuresStore.editableLangs) {
        const feature = featureLangMap.langs[lang];
        if (!feature.isValid(team) || !feature.revId) {
          toast.error('Please finish filling out all items of the new feature.');
          return;
        }
      }

      updatingCompareFeatureLink.current = true;
      const compareFeatureId = uuidv4();
      let hasFailed = false;
      const hasCompareFeature = !!featureLangMap.langs[featuresStore.defaultLang].compareFeatureId;
      for (const lang of featuresStore.editableLangs) {
        try {
          const feature = featureLangMap.langs[lang];
          if (!feature.compareFeatureId && !hasCompareFeature) {
            const compareFeatureResponse = await trackPromise(
              addCompareFeatureFromParent(
                brand,
                team,
                seriesId,
                year,
                lang,
                feature.id,
                CompareType.Feature,
                feature.description,
                feature.category.id,
                feature.subCategory.id || '',
                grades || [],
                compareFeatureId,
              ),
            );
            if (!featuresStore.compareFeaturesMap.compareFeatures[feature.id]) {
              featuresStore.compareFeaturesMap.compareFeatures[feature.id] = {
                langs: {},
                data: compareFeatureResponse.data,
              };
            }
            const cats: IDValueType<string>[] = featuresStore.getCategoriesForLang(lang, featuresStore.categoriesMap);
            const subCats: IDValueType<string>[] = featuresStore.getCategoriesForLang(lang, featuresStore.subCategoriesMap);
            featuresStore.compareFeaturesMap.compareFeatures[feature.id].langs[lang] = compareFeatureItemXForm(compareFeatureResponse.data, grades || [], cats, subCats, [], [], 0);
            feature.compareFeatureId = featuresStore.compareFeaturesMap.compareFeatures[feature.id].langs[lang].id;
          } else if (feature.compareFeatureId && hasCompareFeature) {
            const compareFeature = featuresStore.compareFeaturesMap.compareFeatures[feature.id].langs[lang];
            compareFeature.parentId = '';
            await trackPromise(updateCompareFeature(brand, team, seriesId, year, lang, compareFeature.getPayload()));
            delete featuresStore.compareFeaturesMap.compareFeatures[feature.id].langs[lang];
            feature.compareFeatureId = '';
            if (!Object.keys(featuresStore.compareFeaturesMap.compareFeatures[feature.id].langs).length) {
              delete featuresStore.compareFeaturesMap.compareFeatures[feature.id];
            }
          }
        } catch (e) {
          hasFailed = true;
          handleErrorResponse(e, 'Feature failed update');
          break;
        }
      }
      if (!hasFailed) {
        toast.success('Feature updated successfully');
        toast.success('Changes have also been updated on the Compare View page');
      }
      updatingCompareFeatureLink.current = false;
    }
  };

  const syncUpdates = async () => {
    setIsLoaded(false);
    try {
      await syncSpanishUpdates(brand, team, seriesId, year);
      toast.success('Sync successful');
      if (reloadDraft) {
        setIsLoaded(true);
        reloadDraft();
      }
    } catch (e) {
      handleErrorResponse(e, 'Error syncing spanish data');
    }
    setIsLoaded(true);
  };

  const compareFeatureLangMap = (feature: FeatureLangMap) => {
    setSyncChangesSpec(feature);
    setShowSyncChangesModal(true);
  };

  const applyChanges = (response: AppliedChangesResponse) => {
    if (!syncChangesFeature) {
      return;
    }
    const changeLogTypes: ChangeLogTypes[] = Object.keys(response.applied) as ChangeLogTypes[];
    changeLogTypes.forEach(changeType => {
      const langMap = response.applied[changeType];
      if (langMap) {
        Object.entries(langMap).forEach(([lang, after]) => {
          const feature = syncChangesFeature.langs[lang];
          if (feature) {
            switch (changeType) {
              case ChangeLogTypes.LINK:
                feature.link = after;
                break;
              case ChangeLogTypes.DESCRIPTION:
                feature.description = after;
                break;
              case ChangeLogTypes.NOTES:
                feature.notes = after;
                break;
              case ChangeLogTypes.IN_PROGRESS:
                feature.isInProgress = after.toString() === 'true';
                break;
              case ChangeLogTypes.MODEL_APPLICABILITY:
                const modApp = after as KeyValueType<string>;
                Object.values(feature.modelsMap).forEach(model => {
                  if (modApp[model.id]) {
                    model.setting = modApp[model.id] as FeatureSettings;
                  } else {
                    model.setting = FeatureSettings.UNDEFINED;
                  }
                });
                break;
              default:
                break;
            }
          }
        });
      }
    });
    saveFeatureLangMap(syncChangesFeature, false, undefined, true);
  };

  const getActionBarButtons = (showActionButtons: boolean) => {
    const actionBarButtons: React.ReactNode[] = [];
    if (showActionButtons && teamStore.team.allowAddDeleteData) {
      actionBarButtons.push(<IconTextButton icon="plus" text="Add Feature" onClick={() => addEmptyFeatureLangMap()} />);
      actionBarButtons.push(
        sortMode ? (
          <SortButton toggled onClick={onStopSorting}>
            Stop Sorting
          </SortButton>
        ) : (
          <>
            <SortDropdown
              buttonText="Sort"
              list={['Rows', 'Categories', 'Sub Categories']}
              onSelect={value => {
                switch (value) {
                  case 'Rows': {
                    featuresStore.resetFilters();
                    setSortMode(true);
                    break;
                  }
                  case 'Categories': {
                    setSortCategoryModal(true);
                    break;
                  }
                  case 'Sub Categories': {
                    setSortSubCategoryModal(true);
                    break;
                  }
                }
              }}
            />
            <SortModal
              open={sortCategoryModal}
              onClose={() => setSortCategoryModal(false)}
              onSave={list => onSaveSortCategories(list, VDSortableEntity.FEATURES_CATEGORIES)}
              idValueList={featuresStore.getDefaultCategories(featuresStore.categoriesMap)}
              headerText="Sort Categories"
            />
            <SortModal
              open={sortSubCategoryModal}
              onClose={() => setSortSubCategoryModal(false)}
              onSave={list => onSaveSortCategories(list, VDSortableEntity.FEATURES_SUBCATEGORIES)}
              idValueList={featuresStore.getDefaultCategories(featuresStore.subCategoriesMap)}
              headerText="Sort Sub Categories"
            />
          </>
        ),
      );
    }

    const langButtons = getLangActionBarButtons(
      {
        allLangs: featuresStore.allLangs,
        selectedLangsMap: featuresStore.selectedLangsMap,
        updateSelectedLangs: featuresStore.updateSelectedLangs,
        showActionButtons,
      },
      {
        canSyncUpdates: teamStore.team.canSyncUpdates,
        seriesId,
        year,
        syncUpdates,
      },
    );
    actionBarButtons.push(...langButtons);

    return (
      <>
        {actionBarButtons.map((button, index) => (
          <React.Fragment key={index}>
            <ActionBarDivider />
            {button}
          </React.Fragment>
        ))}
      </>
    );
  };

  const showFormFieldError = () => {
    toast.error('Please finish filling out all items of the new feature.');
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return undefined;
    }

    const [removed] = featuresStore.filteredFeatureLangMaps.splice(result.source.index, 1);
    featuresStore.filteredFeatureLangMaps.splice(result.destination.index, 0, removed);
    featuresStore.filteredFeatureLangMaps.forEach((item, index: number) => {
      Object.values(item.langs).forEach(featureItem => {
        featureItem.sortOrder = index + 1;
      });
    });
    return featuresStore.filteredFeatureLangMaps;
  };

  const visibleModels = () => {
    return vehicleModels;
  };

  return !isLoaded ? (
    <Spinner />
  ) : (
    <>
      <ActionBar>
        <ActionBarFiltersSection
          readOnly={readOnly}
          toggleViewModelCodes={() => (featuresStore.viewModelCodes = !featuresStore.viewModelCodes)}
          viewModelCodes={featuresStore.viewModelCodes}
          searchText={featuresStore.searchText}
          onSearchTextChange={text => featuresStore.onFilter(() => (featuresStore.searchText = text))}
          renderButtons={getActionBarButtons(!readOnly)}
          renderFilter={onClose => (
            <FeatureFilters
              onClose={onClose}
              categories={featuresStore.getDefaultCategories(featuresStore.categoriesMap).map(cat => cat.value)}
              categoryFilters={featuresStore.categoryFilters}
              setCategoryFilters={categoryFilters => featuresStore.onFilter(() => (featuresStore.categoryFilters = categoryFilters))}
              isInProgressFilter={featuresStore.isInProgressFilter}
              setIsInProgressFilter={value => featuresStore.onFilter(() => (featuresStore.isInProgressFilter = value))}
              isSyncUpdateFilter={featuresStore.isSyncUpdateFilter}
              setIsSyncUpdateFilter={value => featuresStore.onFilter(() => (featuresStore.isSyncUpdateFilter = value))}
              isHighlightedFilter={featuresStore.isHighlightedFilter}
              setIsHighlightedFilter={
                brand === BRAND_TOYOTA || brand === BRAND_TDPR ? undefined : value => featuresStore.onFilter(() => (featuresStore.isHighlightedFilter = value))
              }
              isReviewNotesFilter={featuresStore.isReviewNotesFilter}
              setIsReviewNotesFilter={
                team === VehicleTeam.AGENCY_TEAM && version == null ? value => featuresStore.onFilter(() => (featuresStore.isReviewNotesFilter = value)) : undefined
              }
              isPublished={isPublished}
            />
          )}
        />
      </ActionBar>
      <TwoTableWrapper>
        <DragDropContext onDragEnd={onDragEnd}>
          <LeftTable>
            <FeatureHeaderRow
              showDescriptionToggles={false}
              allowLinks={false}
              switchShortLongDescription={teamStore.team.switchShortLongDescription}
              viewModelCodes={featuresStore.viewModelCodes}
              showSpecLinkModal={teamStore.team.allowShowSpecLinkModal}
              readOnly={readOnly}
              onSort={featuresStore.onSort}
              sortMode={sortMode}
              languages={featuresStore.allLangs.filter(lang => featuresStore.selectedLangsMap[lang])}
              team={teamStore.team.name}
            />
            <FeatureRowsContainer
              featureLangMaps={featuresStore.filteredFeatureLangMaps}
              readOnly={readOnly}
              sortMode={sortMode}
              saveFeatureLangMap={saveFeatureLangMap}
              deleteFeatureLangMap={deleteFeatureLangMap}
              copyFeatureLangMap={copyFeatureLangMap}
              addCategoryItem={addCategoryItem}
              updateCategoryItem={updateCategoryItem}
              addSubCategoryItem={addSubCategoryItem}
              updateSubCategoryItem={updateSubCategoryItem}
              handleCompareFeatureHighlighted={handleCompareFeatureHighlighted}
              showRequiredDescription={featuresStore.showRequiredDescription}
              showOptionalDescription={featuresStore.showOptionalDescription}
              showGradeApplicability={teamStore.team.allowCompareFeatures}
              showLink={false}
              showSpecLinkModal={teamStore.team.allowShowSpecLinkModal}
              showTooltip={teamStore.team.allowFeatureTooltips}
              switchShortLongDescription={teamStore.team.switchShortLongDescription}
              allowDisclaimerTokens={teamStore.team.allowDisclaimerTokens}
              disclaimerTokens={disclaimerTokens}
              brand={brand}
              compareFeature={compareFeatureLangMap}
            />
          </LeftTable>
        </DragDropContext>
        <ModelTable
          showFeatureSplits={teamStore.team.allowSplits}
          viewModelCodes={featuresStore.viewModelCodes}
          models={visibleModels()}
          headerStyle={{ top: 0 }}
          renderRows={() => (
            <>
              {featuresStore.filteredFeatureLangMaps.map((featureLangMap, idx) => {
                const defaultFeature = featureLangMap.langs[featuresStore.modelApplicabilityLang];
                let changedModelIds: string[] = [];
                for (const lang of featuresStore.editableLangs) {
                  changedModelIds.push(...featureLangMap.langs[lang].changedModelIds);
                }
                return (
                  <React.Fragment key={defaultFeature.uid}>
                    <TableRow rowHeight={featuresStore.getRowHeight(featureLangMap)}>
                      {visibleModels().map(model => {
                        if (model.show && defaultFeature.modelsMap[model.id]) {
                          const syncValueChanged = changedModelIds.includes(model.id);
                          let defaultSetting = '';
                          let setEditableText = false;
                          const applicabilityTextMap: ApplicabilityTextLangMap = { text: {} };
                          for (const lang of featuresStore.allLangs) {
                            const canEdit = featuresStore.editableLangs.includes(lang);
                            const text = featureLangMap.langs[lang].modelsMap[model.id].setting;
                            if (canEdit && text && !setEditableText) {
                              defaultSetting = text;
                              setEditableText = true;
                            } else if (!canEdit && !defaultSetting) {
                              defaultSetting = text;
                            }
                            applicabilityTextMap.text[lang] = {
                              text,
                              canEdit,
                            };
                            if (syncValueChanged && !canEdit) {
                              applicabilityTextMap.text[lang]!.syncValueChange = syncValueChanged;
                            }
                          }
                          const modelData = {
                            id: defaultFeature.modelsMap[model.id].id,
                            applicabilityTextMap,
                            setting: defaultSetting,
                          };

                          return (
                            <SettingsCell
                              key={`${defaultFeature.uid}${model.uid}${defaultFeature.modelsMap[model.id].setting}`}
                              disabled={readOnly || !featuresStore.fullEditPermissions}
                              model={modelData}
                              oddRow={idx % 2 === 1}
                              rowSpan={defaultFeature.splitCount + 1}
                              syncValueChanged={changedModelIds.includes(model.id)}
                              onUpdate={value => {
                                let shouldSave = false;
                                featuresStore.editableLangs.forEach(lang => {
                                  const setting = featureLangMap.langs[lang].modelsMap[model.id].setting || '';
                                  shouldSave = shouldSave || setting !== value;
                                  featureLangMap.langs[lang].modelsMap[model.id].setting = value;
                                });
                                if (shouldSave) {
                                  saveFeatureLangMap(featureLangMap);
                                }
                              }}
                              allowFreeFormText
                            />
                          );
                        }
                        return <></>;
                      })}
                    </TableRow>
                  </React.Fragment>
                );
              })}
            </>
          )}
        />
      </TwoTableWrapper>
      <Modal open={showSyncChangesModal} size="auto" onClose={() => setShowSyncChangesModal(false)}>
        <SyncTMNAChangesModal
          brand={brand}
          team={team}
          seriesId={seriesId}
          year={year}
          itemId={syncChangesFeature ? syncChangesFeature.langs[featuresStore.defaultLang].id : ''}
          entityType={'features'}
          isNew={!!syncChangesFeature?.langs[featuresStore.defaultLang].changedAttributes?.includes('new')}
          isDelete={!!syncChangesFeature?.langs[featuresStore.defaultLang].changedAttributes?.includes('delete')}
          close={(response, shouldDelete, unlinkFromTMNA) => {
            setShowSyncChangesModal(false);
            if (syncChangesFeature) {
              if (response) {
                applyChanges(response);
              } else if (shouldDelete) {
                deleteFeatureLangMap(syncChangesFeature);
              } else if (unlinkFromTMNA) {
                saveFeatureLangMap(syncChangesFeature, false, undefined, false, true);
              }
            }
          }}
          vehicleModels={vehicleModels}
          sourceVersion={sourceVersion}
        />
      </Modal>
    </>
  );
};

export default observer(FeaturesController);
