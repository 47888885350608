import { makeObservable, observable } from 'mobx';
import { v4 as uuidv4 } from 'uuid';
import { ChangeLogBase } from './changeLog.model';
import { IDValueType, KeyValueType } from './common.model';
import { RefItem } from './refItem.model';
import { ReviewChangeMap, ReviewChangeRequest, ReviewChangeResponse, ReviewChangeTypeMap } from './review.model';
import { BRAND_ENGAGE, BRAND_TDPR, Brand } from './user.model';

type VehicleModelPropType = 'string' | 'boolean' | 'refItem' | 'idValueType' | 'computed' | 'array';
type VehicleModelPropValue = any;
type DynamicValue = (propsObject: VehicleModelPropsMap) => string;

interface VehicleModelProp {
  type: VehicleModelPropType;
  optional: boolean;
  getterFn?: DynamicValue;
  value: VehicleModelPropValue;
  displayName?: string;
  editPanelRow?: number;
  editPanelOrder?: number;
}

interface VehicleModelPropsMap {
  [key: string]: VehicleModelProp;
}

export const ToyotaModelPropsMap: () => VehicleModelPropsMap = () => ({
  code: {
    type: 'string',
    optional: false,
    value: '',
  },
  grade: {
    type: 'refItem',
    optional: false,
    value: '',
  },
  fuelType: {
    type: 'idValueType',
    optional: false,
    value: '',
  },
  isNotPublishable: {
    type: 'boolean',
    optional: false,
    value: false,
  },
  seating: {
    type: 'string',
    optional: true,
    value: '',
  },
  bed: {
    type: 'string',
    optional: true,
    value: '',
  },
  cab: {
    type: 'string',
    optional: true,
    value: '',
  },
  isHybrid: {
    type: 'boolean',
    optional: true,
    value: false,
  },
  drive: {
    type: 'string',
    optional: false,
    value: '',
  },
  engine: {
    type: 'string',
    optional: false,
    value: '',
  },
  transmission: {
    type: 'string',
    optional: false,
    value: '',
  },
  katashiki: {
    type: 'string',
    optional: true,
    value: '',
  },
  horsepower: {
    type: 'string',
    optional: false,
    value: '',
  },
  trimTitle: {
    type: 'string',
    optional: false,
    value: '',
  },
  description: {
    type: 'string',
    optional: false,
    value: '',
  },
  rejectNotes: {
    type: 'string',
    optional: true,
    value: '',
  },
  goLiveDate: {
    type: 'string',
    optional: true,
    value: '',
  },
  changedAttributes: { type: 'array', optional: true, value: [] },
  tdprCode: {
    type: 'string',
    optional: true,
    value: '',
  },
  isTDPR: {
    type: 'boolean',
    optional: true,
    value: false,
  },
  isUSVI: {
    type: 'boolean',
    optional: true,
    value: false,
  },
  fromTMNA: { type: 'boolean', optional: true, value: false },

  // SET THESE OR MEMOIZE
  tableHeaderInfo: {
    type: 'computed',
    optional: true,
    getterFn: ({ description }) => description.value,
    value: '',
  },
  modalRowInfo: {
    type: 'computed',
    optional: true,
    getterFn: ({ description }) => description.value,
    value: '',
  },
});

const vehicleModelPropsConfig = {
  [BRAND_ENGAGE]: ToyotaModelPropsMap,
};

type BaseKeysMap = {
  [K in keyof VehicleModelBase]: true;
} & {
  uid: true;
  isDeleted: true;
  brand: true;
};

const baseKeysMap: BaseKeysMap = {
  uid: true,
  id: true,
  revId: true,
  show: true,
  sortOrder: true,
  isDeleted: true,
  brand: true,
};

// ADD JSDOCS
export class VehicleModelItem<TVehicleModel> {
  uid = '';
  id = '';
  revId = '';
  show = true;
  modelProps: VehicleModelPropsMap = {};
  sortOrder = 0;
  brand = '';
  acceptChanges: boolean = false;

  constructor(brand: Brand, vehicleModel?: TVehicleModel, grades?: RefItem[], fuelTypes?: IDValueType[]) {
    makeObservable(this, {
      id: observable,
      revId: observable,
      show: observable,
      modelProps: observable,
      sortOrder: observable,
      brand: observable,
      acceptChanges: observable,
    });

    this.uid = uuidv4();
    this.brand = brand;

    // get dynamic props / default values for brand
    // these will be stored in this.modelProps
    const dynamicPropsDefaults = this.handleNewVehicleModel(brand);

    // creating using TVehicleModel object
    const mappedObject = this.mapVehicleModel(dynamicPropsDefaults, vehicleModel);

    Object.assign(this, mappedObject);

    if (vehicleModel && grades) {
      const grade = this.getVal('grade');
      const foundGrade = grades.find(item => item.id === grade);
      if (foundGrade) {
        this.updateDynamicProps({ grade: foundGrade });
      }
    }

    if (vehicleModel && fuelTypes) {
      const fuelType = this.getVal('fuelType');
      const foundFuelType = fuelTypes.find(item => item.id === fuelType);
      if (foundFuelType) {
        this.updateDynamicProps({ fuelType: foundFuelType });
      }
    }
  }

  /* 
    create this.modelProps with brand's default values
  */
  handleNewVehicleModel(brand: Brand) {
    const getDefaultPropsFn = vehicleModelPropsConfig[brand];
    if (!getDefaultPropsFn) {
      console.error(`could not assign props to Vehicle Model: invalid Brand ${brand}`);
      return {};
    }
    return getDefaultPropsFn();
  }

  /* 
    copy all dynamic properties of vehicleModel over to this.modelProps
    copy all other keys to this - this[other_key]
  */
  mapVehicleModel(dynamicPropsDefaultValues: VehicleModelPropsMap, vehicleModel?: TVehicleModel) {
    const mappedObject: any = {
      modelProps: dynamicPropsDefaultValues,
    };
    if (!vehicleModel) {
      return mappedObject;
    }
    const keysToIterate = Object.keys(vehicleModel).filter(key => key !== 'gradeValue'); // ignore keys
    keysToIterate.forEach(key => {
      const isBaseKey = baseKeysMap[key as keyof BaseKeysMap];
      if (isBaseKey) {
        mappedObject[key] = vehicleModel[key as keyof TVehicleModel];
      } else if (mappedObject.modelProps[key]) {
        // safe guard if service sends new fields
        mappedObject.modelProps[key].value = vehicleModel[key as keyof TVehicleModel];
      }
    });
    return mappedObject;
  }

  /* 
    update this.modelProps
  */
  updateDynamicProps(keyValObject: { [key: string]: any }) {
    const keys = Object.keys(keyValObject);
    keys.forEach(key => {
      if (this.modelProps[key]) {
        this.modelProps[key].value = keyValObject[key];
      } else {
        console.log('key is missing from modelProps', key, keyValObject[key]);
        this.modelProps[key] = {
          type: 'string',
          optional: true,
          value: keyValObject[key],
        };
      }
    });
  }

  /*
    Trim string values
  */
  trimValues() {
    Object.values(this.modelProps).forEach(prop => {
      if (prop.value && prop.type === 'string') {
        prop.value = prop.value.trim();
      }
    });
  }

  /* 
    look for value by key name
    start with static properties of this
    then look in dynamic properties of this.modelProps
  */
  getVal(key: string) {
    if (baseKeysMap[key as keyof BaseKeysMap]) {
      const val = this[key as keyof VehicleModelItem<TVehicleModel>];
      if (val === undefined) {
        console.error(`cannot find this[${key}]`);
      }
      return val;
    }
    const val = this.modelProps[key as keyof VehicleModelPropsMap];
    if (val === undefined) {
      console.error(`cannot find this.modelProps[${key}]`);
      return val;
    }
    if (val.type === 'computed' && val.getterFn) {
      return val.getterFn(this.modelProps);
    }
    return val.value;
  }

  /* 
    iterate through dynamic props stored in this.modelProps
  */
  iterateModelProps(cb: (modelPropKey: keyof VehicleModelPropsMap, modelProp: VehicleModelProp, index: number) => any) {
    const keysToIterate = Object.keys(this.modelProps);
    keysToIterate.forEach((modelPropKey, index) => {
      const modelProp = this.modelProps[modelPropKey];
      return cb(modelPropKey, modelProp, index);
    });
  }

  /* 
    make sure there are values for each required key in this.modelProps
  */
  isValid() {
    let isValid = true;
    this.iterateModelProps((modelPropKey, modelProp) => {
      if (!isValid) {
        return;
      }
      if (modelProp.optional === true) {
        return;
      }
      if (!modelProp.value) {
        isValid = false;
        return false;
      }
    });
    return isValid;
  }

  /*
    map this.modelProps to a new object that only has the key values pairs for each dynamic prop
  */
  getModelPropValues() {
    const newObject: any = {};
    this.iterateModelProps((modelPropKey, modelProp) => {
      if (modelProp.type === 'computed' || modelPropKey === 'rejectNotes') {
        return;
      }
      newObject[modelPropKey] = modelProp.value;
    });
    return newObject;
  }

  /*
    create payload for CRUD methods
  */
  getPayload(brand: string): TVehicleModel {
    const { id, revId, show, sortOrder } = this;
    const { grade, gradeValue, fuelType, tdprCode, isUSVI, isTDPR, ...rest } = this.getModelPropValues();
    const res: any = {
      id,
      revId,
      show,
      sortOrder,
      grade: grade.id,
      fuelType: fuelType.id,
      ...rest,
    };
    if (brand === BRAND_TDPR) {
      res.tdprCode = tdprCode;
      res.isUSVI = isUSVI;
      res.isTDPR = isTDPR;
    }
    return res;
  }

  /*
    create a new VehicleModelItem using these values
  */
  makeCopy() {
    const { show } = this;
    const { grade, gradeValue, fuelType, ...rest } = this.getModelPropValues();

    const copy = new VehicleModelItem(this.brand as Brand, { id: '', revId: '', show, ...rest } as TVehicleModel);
    copy.updateDynamicProps({
      grade,
      fuelType,
    });

    return copy;
  }

  isHybrid(): boolean {
    return this.getVal('fuelType').value !== 'Gas';
  }
}

export interface VehicleModelPropsToyota extends VehicleModelBase {
  code: string;
  grade: RefItem;
  drive?: string;
  engine: string;
  description?: string;
  transmission: string;
  isHybrid: boolean;
  fuelType: IDValueType;
  isNotPublishable: boolean;
  trimTitle: string;
  bed?: string;
  cab?: string;
  seating?: string;
  goLiveDate?: string;
  changedAttributes?: string[];
  acceptChanges?: boolean;
  tdprCode?: string;
  isTDPR?: boolean;
  isUSVI?: boolean;
  fromTMNA?: boolean;
  horsepower?: string;
}

interface VehicleModelBase {
  id: string;
  revId: string;
  show?: boolean;
  sortOrder: number;
}

// expect TModelProps to be | VehicleModelPropsToyota
export type VehicleModel<TModelProps> = {
  [K in keyof VehicleModelBase]: VehicleModelBase[K];
} & {
  [K in keyof TModelProps]: TModelProps[K];
};

export type VehicleModelToyota = VehicleModel<VehicleModelPropsToyota>;

interface VehicleModelChangeBase extends VehicleModelBase {
  changeType: string;
  changes: string;
  modifiedBy: string;
  modifiedDate: string;
}

// expect TVehicleModel to be | VehicleModelToyota
export type VehicleModelChangeLog<TVehicleModel> = {
  [K in keyof VehicleModelChangeBase]: VehicleModelChangeBase[K];
} & {
  [K in keyof TVehicleModel]: TVehicleModel[K];
};

export type VehicleModelChangeLogToyota = VehicleModelChangeLog<VehicleModelPropsToyota>;

export type VehicleModelLocalStorageType = {
  [brand: string]: VehicleModelLocalStorageItemType;
};

export type VehicleModelLocalStorageItemType = {
  [id: string]: {
    show: boolean;
  };
};

export interface ModelsReviewItem extends ChangeLogBase {
  uid: string;
  id: string;
  revId: string;
  description: string;
  category: string;
  changeTypeId: string;
  isAccepted: boolean;
  isApplied: boolean;
  isNewChange: boolean;
  rejectNotes: string;
}
export interface ModelsReviewResponse extends VDModel {
  changes: KeyValueType<ReviewChangeResponse>;
}
export interface ModelsReviewRequest extends ReviewChangeRequest {
  changeType: string;
}

export interface VDModel {
  id: string;
  code: string;
  revId: string;
  grade: string;
  gradeValue: string;
  drive: string;
  engine: string;
  horsepower: string;
  transmission: string;
  isHybrid: boolean;
  isDeleted: boolean;
  bed?: string;
  cab?: string;
  seating?: string;
  sortOrder?: number;
  trimTitle: string;
  fuelType: string;
  isNotPublishable: boolean;
  cbuNap?: string;
  description?: string;
  katashiki: string;
  msrp?: string;
  alternativeOffersDescription?: string;
  name?: string;
  packageTrim?: string;
  requiredPackageCode?: string;
  specialEdition?: boolean;
  seriesSettingId?: string;
  changedAttributes?: string[];
  tdprCode?: string;
  isTDPR?: boolean;
  isUSVI?: boolean;
  goLiveDate?: string;
}

export interface LangMap {
  [enValue: string]: { [lang: string]: string };
}

export interface ModelLangMap {
  [bnpType: string]: LangMap;
}

export interface ModelsReviewMap {
  [id: string]: ModelsChangeTypeMap;
}

export interface ModelsChangeTypeMap extends ReviewChangeTypeMap {
  isNotPublishable: boolean;
  code: ReviewChangeMap<string>;
  goLiveDate: ReviewChangeMap<string>;
  grade: ReviewChangeMap<string>;
  fuelType: ReviewChangeMap<string>;
  bed: ReviewChangeMap<string>;
  cab: ReviewChangeMap<string>;
  trimTitle: ReviewChangeMap<string>;
  description: ReviewChangeMap<string>;
  drive: ReviewChangeMap<string>;
  engine: ReviewChangeMap<string>;
  transmission: ReviewChangeMap<string>;
  horsepower: ReviewChangeMap<string>;
  katashiki: ReviewChangeMap<string>;
}

export type ModelReviewType =
  | 'code'
  | 'goLiveDate'
  | 'grade'
  | 'fuelType'
  | 'bed'
  | 'cab'
  | 'trimTitle'
  | 'description'
  | 'drive'
  | 'engine'
  | 'transmission'
  | 'horsepower'
  | 'katashiki'
  | 'added'
  | 'deleted';
